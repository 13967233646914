import { useFormField } from '../providers/FormFieldProvider'
import AddressField from './AddressField'
import { FormQuestionValue } from '../../../types/Forms'

export const FormFieldAddress = () => {
  const {
    field: { value, disabled, input },
    updateValue,
  } = useFormField()

  if (input.type !== 'ADDRESS') return null

  return (
    <AddressField
      answer={(value || {}) as FormQuestionValue}
      onUpdate={(selectedAddress) => {
        updateValue(selectedAddress.value)
      }}
      disabled={disabled}
      inputId="search-select"
    />
  )
}

export default FormFieldAddress
