import { useFormField } from '../providers/FormFieldProvider'
import { valueToString } from './FormFieldDisplayGeneric'

const FormFieldDisplayMultiple = () => {
  const {
    field: {
      id,
      values,
      input: { type },
    },
  } = useFormField()
  return (
    <div key={`form-field-display-${id}`}>
      {values?.map((value) => (
        <div key={`form-field-display-${id}-${value?.value}`} className="flex flex-row gap-1">
          {valueToString(type, value?.value)}
        </div>
      ))}
    </div>
  )
}

export default FormFieldDisplayMultiple
