import { useTranslation } from 'react-i18next'
import React from 'react'
import { WorkflowBlock, WorkflowBlockApprover } from '../../../../../../../../types/Workflow/WorkflowBlock'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../../../../shared/components/atoms/Dialog/Dialog'
import { Avatar, AvatarFallback } from '../../../../../../../../shared/components/atoms/Avatar/Avatar'
import stringToHslColor from '../../../../../../../../shared/utils/stringToHslColor'
import getUserInitials from '../../../../../../../../shared/utils/getUserInitials'
import AvatarGroup from '../../../../../../../../shared/components/atoms/Avatar/AvatarGroup'

type IntakeFlowchartApproversDialogProps = {
  block: WorkflowBlock
}

const IntakeOverviewDialogApprover = ({
  value,
  user,
  type,
}: WorkflowBlockApprover & {
  value?: number | string
}) => (
  <div className="flex items-center justify-between w-full rounded-md py-2 pl-2 pr-4 group hover:bg-gray-100">
    <div className="flex space-x-4 items-center">
      {type === 'USER' && (
        <>
          <Avatar>
            <AvatarFallback
              style={{ backgroundColor: stringToHslColor({ name: getUserInitials(user) }) }}
              className="font-semibold"
            >
              {getUserInitials(user)}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <p
              className={`font-semibold text-gray-700 ${user.first_name || user.last_name ? '' : 'hidden'}`}
            >{`${user.first_name} ${user.last_name}`}</p>
            <p className="text-sm text-secondary-gray">{user.email}</p>
          </div>
        </>
      )}
      {type !== 'USER' && (
        <div className="flex flex-col">
          <p className="font-semibold text-gray-700 lowercase first-letter:uppercase">{value}</p>
        </div>
      )}
    </div>
  </div>
)

const IntakeFlowchartApprovers = ({ block }: IntakeFlowchartApproversDialogProps) => {
  const { t } = useTranslation()

  if (!block) return null

  const dynamicApprovers = block.approvers?.filter((approver) => approver.type === 'AUTHOR')
  const userApprovers = block.approvers?.filter((approver) => approver.type === 'USER')

  // create a string array of initials from approvers
  const approverInitials = block.approvers?.map((approver) =>
    getUserInitials({
      first_name: approver.user.first_name,
      last_name: approver.user.last_name,
      email: approver.user.email,
    })
  )

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button
          type="button"
          className="hover:cursor-help"
          aria-label={t('workflow.approvers_button_label', 'View approvers')}
        >
          <AvatarGroup userInitials={approverInitials} maxAvatars={3} />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>{t('workflow.approvers', 'Approvers')}</DialogTitle>
          <DialogDescription className="text-gray-500 text-sm">
            {t('workflow.approvers_dialog_description', 'All users that can approve this workflow block')}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <div className="grid flex-1 gap-2">
              <div
                className="text-sm text-gray-500 font-bold lowercase first-letter:uppercase"
                hidden={!dynamicApprovers?.length}
              >
                {t('workflow.approver_type_dynamic', 'Dynamic')}
              </div>
              {dynamicApprovers.map((approver: WorkflowBlockApprover) => (
                <IntakeOverviewDialogApprover
                  key={approver.value}
                  type={approver.type}
                  value={approver.value}
                  user={approver.user}
                />
              ))}
              <div
                className="text-sm font-bold text-gray-500 lowercase first-letter:uppercase"
                hidden={!userApprovers?.length}
              >
                {t('workflow.approver_type_users', 'Users')}
              </div>
              {userApprovers.map((approver: WorkflowBlockApprover) => (
                <IntakeOverviewDialogApprover
                  key={approver.value}
                  type={approver.type}
                  user={approver.user}
                  value={approver.user.id}
                />
              ))}
            </div>
          </div>
        </div>
        <DialogFooter className="sm:justify-end" />
      </DialogContent>
    </Dialog>
  )
}

export default IntakeFlowchartApprovers
