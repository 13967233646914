import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import QuestionSliderSettingRow from '../../components/inputs/QuestionSliderSettingRow'

const LabelSettings: React.FC = () => {
  const { t } = useTranslation()
  const {
    question: [question],
    updateQuestion,
  } = useFormContext()

  const labels = question?.__settings?.labels

  if (!labels) return null

  return (
    <>
      {Object.entries(labels)
        .filter(([, value]) => value !== undefined)
        .map(([property, value]) => (
          <QuestionSliderSettingRow
            key={`${question.__uuid}-label-settings-${property}`}
            title={t(`forms.settings.labels.${property}`)}
            description={t(`forms.settings.labels.${property}_description`)}
            onChange={() => updateQuestion({ __settings: { labels: { [property]: !value } } })}
            checked={value}
          />
        ))}
    </>
  )
}

export default LabelSettings
