import { useState } from 'react'
import { useQuery } from 'react-query'
import lodash from 'lodash'
import useDebouncedValue from '../../../core/hooks/useDebouncedValue'
import { ApiSearchType, SearchApiPaginatedResponse } from '../SearchTypes'
import ApiSearchService from '../Services/SearchService'

export type DebounceSearchRequestProps = {
  key: string[]
  query: ApiSearchType
  debounceTime?: number
}

export function useDebounceSearchRequest<T>({ key, query, debounceTime = 500 }: DebounceSearchRequestProps) {
  const [prevSearch, setPrevSearch] = useState<any>()

  const debouncedSearch = useDebouncedValue(query.queryFilters, debounceTime)

  const { data, isLoading, refetch } = useQuery(
    key,
    (): Promise<SearchApiPaginatedResponse<T>> => ApiSearchService<T>(query),
    { enabled: false }
  )

  if (
    debouncedSearch &&
    lodash(debouncedSearch).isEqual(query.queryFilters) &&
    !lodash(debouncedSearch).isEqual(prevSearch)
  ) {
    setPrevSearch(debouncedSearch)
    refetch()
  }

  return {
    data,
    isLoading,
    refetch,
  }
}

export default useDebounceSearchRequest
