import { useQuery, UseQueryResult } from 'react-query'
import { useMemo } from 'react'
import companyQueryStrings from '../../Settings/constants/companyQueryStrings'
import { apiGetCompanyInputs, InputSearchParams } from '../../IntakeConfiguration/services/CompanyInputsService'
import { BasicFormInputTypes, FormQuestionContentType, InputTemplate } from '../../../types/Forms'

export type InputTypesQuery = UseQueryResult<InputTemplate[]> & {
  getDefaultOfType: (type: FormQuestionContentType) => InputTemplate | undefined
  getByUuid: (uuid: string) => InputTemplate | undefined
  basicTypes: FormQuestionContentType[]
}

export const useGetInputTypes = (filters: InputSearchParams = {}): InputTypesQuery => {
  const query = useQuery([companyQueryStrings.company_inputs, filters], () => apiGetCompanyInputs(filters), {
    staleTime: 1000 * 60 * 5, // ms
  })

  return useMemo(
    () => ({
      ...query,
      getDefaultOfType: (type: FormQuestionContentType) => query.data?.find((question) => question.type === type),
      getByUuid: (uuid: string) => query.data?.find((type) => type.uuid === uuid),
      basicTypes: [...BasicFormInputTypes],
    }),
    [query]
  )
}

export default useGetInputTypes
