import { useTranslation } from 'react-i18next'
import { useFormField } from '../providers/FormFieldProvider'
import { FormQuestionValue } from '../../../types/Forms'
import SearchMultiSelect from '../../../shared/components/atoms/SearchSelect/SearchMultiSelect'
import { useUserOptions } from '../../../shared/hooks/queries/useUserOptions'
import { CompanyUserType } from '../../../types/shared/RequisitionDataTypes'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'

export const FormFieldMultiUserSelect = () => {
  const {
    field: { values, disabled },
    setValues,
  } = useFormField()
  const { options, findUser, findOption } = useUserOptions()
  const { t } = useTranslation()

  const handleSelection = (selected: InputSearchSelectItemType[]) => {
    setValues(
      selected
        .map((item) => {
          const selectedUser = findUser(item?.id as number)
          if (!item || !selectedUser) return null
          return {
            __uuid: item.id,
            id: item.id,
            value: selectedUser,
          }
        })
        .filter((item) => item !== null) as FormQuestionValue[]
    )
  }

  return (
    <SearchMultiSelect
      placeholder={t('forms.select_users', 'Select users...')}
      onChange={handleSelection}
      disabled={disabled}
      options={options}
      currentSelection={
        (values
          ?.map((value) => findOption((value.value as CompanyUserType)?.id))
          .filter((option) => option !== undefined) as InputSearchSelectItemType[]) || []
      }
    />
  )
}

export default FormFieldMultiUserSelect
