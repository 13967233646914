import { FormQuestionAllowedFiletypes } from '../../../../../../types/Forms'

export const fileTypeOptions = [
  { id: 'audio/aac', label: 'AAC audio' },
  { id: 'application/x-abiword', label: 'AbiWord document' },
  { id: 'application/x-freearc', label: 'Archive document (multiple files embedded)' },
  { id: 'image/avif', label: 'AVIF image' },
  { id: 'video/x-msvideo', label: 'AVI: Audio Video Interleave' },
  { id: 'application/vnd.amazon.ebook', label: 'Amazon Kindle eBook format' },
  { id: 'application/octet-stream', label: 'Any kind of binary data' },
  { id: 'image/bmp', label: 'Windows OS/2 Bitmap Graphics' },
  { id: 'application/x-bzip', label: 'BZip archive' },
  { id: 'application/x-bzip2', label: 'BZip2 archive' },
  { id: 'application/x-cdf', label: 'CD audio' },
  { id: 'application/x-csh', label: 'C-Shell script' },
  { id: 'text/css', label: 'Cascading Style Sheets (CSS)' },
  { id: 'text/csv', label: 'Comma-separated values (CSV)' },
  { id: 'application/msword', label: 'Microsoft Word' },
  { id: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', label: 'Microsoft Word (OpenXML)' },
  { id: 'application/vnd.ms-fontobject', label: 'MS Embedded OpenType fonts' },
  { id: 'application/epub+zip', label: 'Electronic publication (EPUB)' },
  { id: 'application/gzip', label: 'GZip Compressed Archive' },
  { id: 'image/gif', label: 'Graphics Interchange Format (GIF)' },
  { id: 'text/html', label: 'HyperText Markup Language (HTML)' },
  { id: 'image/vnd.microsoft.icon', label: 'Icon format' },
  { id: 'text/calendar', label: 'iCalendar format' },
  { id: 'application/java-archive', label: 'Java Archive (JAR)' },
  { id: 'image/jpeg', label: 'JPEG images' },
  { id: 'application/json', label: 'JSON format' },
  { id: 'application/ld+json', label: 'JSON-LD format' },
  { id: 'audio/midi, audio/x-midi', label: 'Musical Instrument Digital Interface (MIDI)' },
  { id: 'text/javascript', label: 'JavaScript module' },
  { id: 'audio/mpeg', label: 'MP3 audio' },
  { id: 'video/mp4', label: 'MP4 video' },
  { id: 'video/mpeg', label: 'MPEG Video' },
  { id: 'application/vnd.apple.installer+xml', label: 'Apple Installer Package' },
  { id: 'application/vnd.oasis.opendocument.presentation', label: 'OpenDocument presentation document' },
  { id: 'application/vnd.oasis.opendocument.spreadsheet', label: 'OpenDocument spreadsheet document' },
  { id: 'application/vnd.oasis.opendocument.text', label: 'OpenDocument text document' },
  { id: 'audio/ogg', label: 'OGG audio' },
  { id: 'video/ogg', label: 'OGG video' },
  { id: 'application/ogg', label: 'OGG' },
  { id: 'audio/opus', label: 'Opus audio' },
  { id: 'font/otf', label: 'OpenType font' },
  { id: 'image/png', label: 'Portable Network Graphics' },
  { id: 'application/pdf', label: 'PDF' },
  { id: 'application/x-httpd-php', label: 'Hypertext Preprocessor ' },
  { id: 'application/vnd.ms-powerpoint', label: 'Microsoft PowerPoint' },
  {
    id: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    label: 'Microsoft PowerPoint (OpenXML)',
  },
  { id: 'application/vnd.rar', label: 'RAR archive' },
  { id: 'application/rtf', label: 'Rich Text Format (RTF)' },
  { id: 'application/x-sh', label: 'Bourne shell script' },
  { id: 'image/svg+xml', label: 'Scalable Vector Graphics (SVG)' },
  { id: 'application/x-tar', label: 'Tape Archive (TAR)' },
  { id: 'image/tiff', label: 'Tagged Image File Format (TIFF)' },
  { id: 'video/mp2t', label: 'MPEG transport stream' },
  { id: 'font/ttf', label: 'TrueType Font' },
  { id: 'text/plain', label: 'Text, (generally ASCII or ISO 8859-_n_)' },
  { id: 'application/vnd.visio', label: 'Microsoft Visio' },
  { id: 'audio/wav', label: 'Waveform Audio Format' },
  { id: 'audio/webm', label: 'WEBM audio' },
  { id: 'video/webm', label: 'WEBM video' },
  { id: 'image/webp', label: 'WEBP image' },
  { id: 'font/woff', label: 'Web Open Font Format (WOFF)' },
  { id: 'font/woff2', label: 'Web Open Font Format (WOFF)' },
  { id: 'application/xhtml+xml', label: 'XHTML' },
  { id: 'application/vnd.ms-excel', label: 'Microsoft Excel' },
  { id: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', label: 'Microsoft Excel (OpenXML)' },
  { id: 'application/xml ', label: 'XML' },
  { id: 'application/vnd.mozilla.xul+xml', label: 'XUL' },
  { id: 'application/zip', label: 'ZIP archive' },
  { id: 'application/x-7z-compressed', label: '7 Zip archive' },
] as const

export type FileTypeId = (typeof fileTypeOptions)[number]['id']

export const getFileTypeOptions = (...ids: FileTypeId[]): FormQuestionAllowedFiletypes[] =>
  fileTypeOptions.filter((option) => ids.includes(option.id))

export default [...fileTypeOptions]
