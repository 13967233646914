import { useTranslation } from 'react-i18next'
import Switch from '../../../../../../../shared/components/atoms/buttons/Switch'
import { FormQuestionType, InputTemplate, FormSectionType } from '../../../../../../../types/Forms'
import useGetFormData from '../../../../../hooks/useGetFormData'
import useUpdateForm from '../../../../hooks/useUpdateForm'
import { getMergedDeepOverwritingArrays } from '../../../../../../../shared/utils/objectHelpers'
import useGetInputTypes from '../../../../../hooks/useGetInputTypes'
import uuid from '../../../../../../../core/utils/uuid'
import { questionFromTemplate } from '../../../buttons/AddQuestionButton/useCreateNewQuestion'

interface ILoopFormGroupsSwitch {
  section: FormSectionType
}

const LoopFormGroupsSwitch: React.FC<ILoopFormGroupsSwitch> = ({ section }) => {
  const { t } = useTranslation()
  const { updateForm } = useUpdateForm()
  const { data: formData } = useGetFormData()
  const { getDefaultOfType } = useGetInputTypes()

  const isLooped = section.loop?.__looped
  const handleToggleLoop = async () => {
    const selectBuilderQuestion = getDefaultOfType('SELECT')

    const loopSelectQuestion: FormQuestionType = getMergedDeepOverwritingArrays<FormQuestionType>(
      questionFromTemplate(selectBuilderQuestion || ({} as InputTemplate)),
      {
        options: [
          { __uuid: 'yes', value: 'Yes' },
          { __uuid: 'no', value: 'No' },
        ],
        // todo localization
        label: 'Do you want to add another line item',
        __logic: {
          questionUUID: uuid(),
        },
        __loop: {
          __uuid: uuid('logic-loop'),
          value: { id: 'yes', label: 'Yes' },
          is_loop_trigger: true,
        },
        __uuid: uuid('form-question'),
      }
    )

    if (!formData) return

    const newSections =
      formData?.template?.sections.map((templateSection) => {
        if (templateSection.__uuid !== section.__uuid) return templateSection

        return getMergedDeepOverwritingArrays(templateSection, {
          loop: { __looped: !isLooped },
          fields: isLooped ? templateSection.fields.slice(0, -1) : [...templateSection.fields, loopSelectQuestion],
        })
      }) || []

    updateForm({ template: { sections: newSections } })
  }

  if (section.type !== 'PRODUCT') return null

  return (
    <div className="gap-x-4 flex">
      <Switch disabled={!section.actions.edit} onChange={handleToggleLoop} checked={isLooped} />
      <p className="text-primary-gray text-sm">{t('forms.loop_group')}</p>
    </div>
  )
}

export default LoopFormGroupsSwitch
