import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import dayjs from 'dayjs'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import useGetCompanyForms from '../../hooks/useGetCompanyForms'
import CreateFormButton from '../buttons/CreateFormButton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../../core/components/Table/Table'
import { FormDataType } from '../../../../../types/Forms'
import { FORM_DELETE } from '../../../../../shared/constants/permissions'
import EditDeleteOptions from '../../../../../shared/components/organisms/menus/EditDeleteMenu'
import usePermissions from '../../../../../core/hooks/usePermissions'
import { apiDeleteForm, apiGetForm } from '../../constants/formService'
import usePostCompanyForm from '../../hooks/usePostCompanyForm'
import { dateFormat } from '../../../../../shared/constants/dateFormat'

const CompanyFormsList: React.FC = () => {
  const { data: companyForms, isLoading } = useGetCompanyForms()
  const canDelete = usePermissions([{ slug: FORM_DELETE }])
  const { refetch } = useGetCompanyForms()
  const { mutate: postForm } = usePostCompanyForm()
  const history = useHistory()
  const { t } = useTranslation()
  const handleOnEdit = (companyForm: FormDataType) => {
    history.push(`/forms/${companyForm.__uuid}/content`)
  }

  const handleOpenForm = (companyForm: FormDataType) => {
    history.push(`/forms/${companyForm.__uuid}/content`)
  }

  const handleOnDelete = async (companyForm: FormDataType) => {
    await apiDeleteForm(companyForm.__uuid)
    refetch()
  }

  const handleCopy = async (companyForm: FormDataType) => {
    const fullForm = await apiGetForm(companyForm.__uuid)
    postForm({ ...fullForm, name: fullForm.name.endsWith('(copy)') ? fullForm.name : `${fullForm.name} (copy)` })
  }

  const formatDate = (dateTime?: string) => dayjs(dateTime).format(dateFormat)

  if (isLoading) return <CoreLoadingIcon />

  if (!companyForms?.length) {
    return (
      <div className="flex flex-col items-center justify-center h-full gap-y-10">
        <div className="flex flex-col gap-y-4 items-center">
          <p className="text-lg">{t('forms.no_forms')}</p>
          <p className="text-secondary-gray text-sm">
            You dont have any forms for your organization. Create a new form.
          </p>
        </div>
        <CreateFormButton />
      </div>
    )
  }

  return (
    <Table className="bg-white shadow-rock-gray-light ">
      <TableHeader>
        <TableRow>
          <TableHead className="w-48 font-bold first-letter:uppercase">{t('schema.table_name', 'Name')}</TableHead>
          <TableHead className="w-48 font-bold first-letter:uppercase">
            {t('generic.created_at', 'Created at')}
          </TableHead>
          <TableHead className="w-48 font-bold first-letter:uppercase">
            {t('generic.updated_at', 'Updated at')}
          </TableHead>
          <TableHead className="w-48 font-bold first-letter:uppercase text-right">
            {t('generic.action', 'Action')}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {companyForms?.map((companyForm: FormDataType) => (
          <TableRow
            key={`schema-${companyForm.__uuid}`}
            className="h-16 hover:bg-gray-200/25 text-black-light hover:cursor-pointer"
            onClick={() => handleOpenForm(companyForm)}
          >
            <TableCell className="first-letter:uppercase">{companyForm.name}</TableCell>
            <TableCell className="first-letter:uppercase">{formatDate(companyForm.created_at)}</TableCell>
            <TableCell className="first-letter:uppercase">{formatDate(companyForm.updated_at)}</TableCell>
            {canDelete.get(FORM_DELETE).allowed ? (
              <TableCell>
                <EditDeleteOptions
                  onEdit={() => handleOnEdit(companyForm)}
                  onDelete={() => handleOnDelete(companyForm)}
                  onCopy={() => handleCopy(companyForm)}
                />
              </TableCell>
            ) : null}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default CompanyFormsList
