import { useQuery } from 'react-query'
import { getWorkflowDraft } from '../services'
import useWorkflowIdentification from './useWorkflowIdentification'

export const useGetWorkflowDraft = () => {
  const { uuid } = useWorkflowIdentification()

  return useQuery(['workflow', uuid, 'draft'], () => getWorkflowDraft(uuid))
}

export default useGetWorkflowDraft
