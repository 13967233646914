import { useTranslation } from 'react-i18next'
import SupplierUserType from '../../../../../../../../../../types/Suppliers/SupplierUserType'
import OfferSupplierDetailsModalButton from './OfferSupplierDetailsModalButton'

type OfferSupplierUserDetailsProps = {
  supplierContactPerson?: SupplierUserType
}

const DetailColumn = ({ children, title }: React.PropsWithChildren<{ title?: string }>) => (
  <div className="flex flex-col gap-1">
    {title && <div className="capitalize font-semibold text-sm text-rock-gray">{title}</div>}
    {children}
  </div>
)

export const OfferSupplierUserDetails = ({ supplierContactPerson }: OfferSupplierUserDetailsProps) => {
  const { t } = useTranslation()

  if (!supplierContactPerson) return null

  return (
    <div className="flex flex-row gap-2 border rounded-md border-rock-gray-light py-2 px-3 justify-between items-center">
      <DetailColumn title={t('general.email', 'email')}>
        <div>{supplierContactPerson.email || supplierContactPerson.supplier_branch.email || '-'}</div>
      </DetailColumn>
      <DetailColumn title={t('general.first_name', 'first name')}>
        <div>{supplierContactPerson.first_name || '-'}</div>
      </DetailColumn>
      <DetailColumn title={t('general.last_name', 'last name')}>
        <span>{supplierContactPerson.last_name || '-'}</span>
      </DetailColumn>
      <DetailColumn title={t('supplier.sap_id', 'SAP ID')}>
        <div>
          {supplierContactPerson.supplier_branch.customer_supplier_id || (
            <span className="text-white font-semibold p-1 rounded-md bg-red-400">
              {t('supplier.missing_sap_id', 'No SAP ID')}
            </span>
          )}
        </div>
      </DetailColumn>
      <DetailColumn>
        <OfferSupplierDetailsModalButton branchId={supplierContactPerson.supplier_branch.id} />
      </DetailColumn>
    </div>
  )
}

export default OfferSupplierUserDetails
