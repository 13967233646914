import { Dispatch, useContext, useState, createContext, FC, SetStateAction, useMemo } from 'react'
import { INodeChildren } from '../../types/shared/GeneralTypes'
import { IModal } from '../../types/shared/CoreTypes'

export interface IUtilsContext {
  modal: {
    get: IModal
    set: Dispatch<SetStateAction<IModal>>
    update: (newState: Partial<IModal>) => void
    close: () => void
  }
  isLoading: {
    get: boolean
    set: Dispatch<SetStateAction<boolean>>
  }
  approvalFlowDrawer: {
    get: boolean
    set: Dispatch<SetStateAction<boolean>>
  }
}

const utilsContext = createContext<IUtilsContext>({} as IUtilsContext)

const UtilsProvider: FC<INodeChildren> = ({ children }) => {
  const [modal, setModal] = useState<IModal>({ isOpen: false })
  const [isLoading, setIsLoading] = useState(false)
  const [approvalFlowDrawer, setApprovalFlowDrawer] = useState(false)

  const values = useMemo(
    () => ({
      modal: {
        get: modal,
        set: (modalOrSetter: IModal | ((modal: IModal) => IModal)) => {
          if (!(modalOrSetter as IModal).isOpen) modal.onClose?.()
          setModal(modalOrSetter)
        },
        update: (newState: Partial<IModal>) => setModal({ ...modal, ...newState }),
        close: () => {
          setModal({ isOpen: false })
        },
      },
      isLoading: { get: isLoading, set: setIsLoading },
      approvalFlowDrawer: { get: approvalFlowDrawer, set: setApprovalFlowDrawer },
    }),
    [modal, setModal, isLoading, setIsLoading, approvalFlowDrawer, setApprovalFlowDrawer]
  )

  return <utilsContext.Provider value={values}>{children}</utilsContext.Provider>
}

export default UtilsProvider

export function useUtils() {
  return useContext(utilsContext)
}
