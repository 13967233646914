import { useUtils } from '../../../../../core/providers/UtilsProvider'
import OverlayContent from './OverlayContent'
import Title from './Title'

const OverlayBody = () => {
  const {
    modal: {
      get: { className },
    },
  } = useUtils()

  return (
    <>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      <div
        className={`
        flex flex-col bg-white pt-5 text-left shadow-xl transform transition-all 
        w-full  h-screen   ${className}
      `}
      >
        <Title />
        <OverlayContent />
      </div>
    </>
  )
}

export default OverlayBody
