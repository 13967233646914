import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import SuppliersProvider from '../../modules/Suppliers/providers/SuppliersProvider'
import { INodeChildren } from '../../types/shared/GeneralTypes'
import AbilityProvider from './AbilityProvider'
import FormProvider from './FormProvider'
import LocalStorageProvider from './LocalStorageProvider'
import MessengerProvider from './MessengerProvider'
import UtilsProvider from './UtilsProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
})

const CoreProviders: React.FC<INodeChildren> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <UtilsProvider>
      <LocalStorageProvider>
        <AbilityProvider>
          <MessengerProvider>
            <SuppliersProvider>
              <FormProvider>{children}</FormProvider>
            </SuppliersProvider>
          </MessengerProvider>
        </AbilityProvider>
      </LocalStorageProvider>
    </UtilsProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

export default CoreProviders
