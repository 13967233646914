import TabButton, { TabType } from './TabButton'

interface IITabsNavigation {
  tabs: TabType[]
  currentTab?: string
}

const TabsNavigation: React.FC<IITabsNavigation> = ({ tabs, currentTab }) => (
  <div className="flex bg-white">
    {tabs.map((tab) => (
      <TabButton key={tab.title?.toString()} tab={tab} currentTab={currentTab} />
    ))}
  </div>
)

export default TabsNavigation
