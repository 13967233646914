import { FormQuestionType, FormQuestionValue } from '../../../../types/Forms'
import { escape } from '../../../../core/utils/stringUtils'

export const createValueLabel = (label: string, index = 0) => (index && label ? `${label}-${index}` : label)

export const getIndexedValueLabel = (values: FormQuestionValue[], label: string, index: number) =>
  createValueLabel(label, (values.length || 0) > 1 && label !== '' ? index : 0)

export const getIndexedQuestionValueLabel = (question: FormQuestionType, index: number, label?: string) =>
  getIndexedValueLabel(question.values, label || question.question_label || '', index)

export const getIndexedGenericLabels = (values: FormQuestionValue[], label: string) =>
  values.map((value, index, values) => {
    const isGenericLabel = value.label?.match(`^${label}-?[0-9]*$`)
    if (isGenericLabel) return { ...value, label: getIndexedValueLabel(values, label, index + 1) }

    return value
  })

export const getIndexedGenericOrEmptyLabels = (question: FormQuestionType, newLabel: string) =>
  question.values.map((value, index, values) => {
    const isGenericLabel = value.label?.match(`^${escape(question.question_label || '')}-?[0-9]*$`)

    if (isGenericLabel || !value.label) return { ...value, label: getIndexedValueLabel(values, newLabel, index + 1) }
    return value
  })
