import { sanitize } from 'dompurify'
import { HiOutlineSparkles } from 'react-icons/hi2'
import { useTranslation } from 'react-i18next'
import { HiDotsHorizontal } from 'react-icons/hi'
import FormFieldProvider from '../providers/FormFieldProvider'
import FormFieldInput from '../FormFieldInput'
import { FormFieldDisplay } from '../FormFieldDisplay'
import { FormQuestionValue } from '../../../types/Forms'
import cn from '../../../lib/util'
import { FormFieldProps } from '../types'
import { inputOptionToFieldOption } from '../helpers'

export const FormField = <T extends string = string>({
  readonly,
  field,
  label,
  subtitle,
  error,
  disabled,
  onCopilotButtonClick,
  copilotStreamStatus,

  className,
  ...props
}: FormFieldProps<T> & {
  onCopilotButtonClick?: () => void
  copilotStreamStatus?: 'idle' | 'loading' | 'open' | 'close' | 'error'
}) => {
  const { t } = useTranslation()

  const normalizedField = {
    ...field,
    media: field.media || [],
    error,
    options: field.input.options?.map(inputOptionToFieldOption),
    question: field.question,
    value: Array.isArray(field.value) ? field.value[0] : field.value,
    values: Array.isArray(field.value) ? field.value : ([field.value].filter((v) => !!v) as FormQuestionValue[]),
    disabled,
  }

  return (
    <FormFieldProvider<T> field={normalizedField} {...props}>
      <div className={cn('flex flex-col gap-3', className, error && '!text-red-500')}>
        <div>
          {label && (
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-1 font-semibold">
                <div className="">{label}</div>
              </div>
              {copilotStreamStatus?.includes('loading') ? (
                <div className="flex flex-row text-sky-blue">
                  <div className="text-xs font-medium">
                    {t('copilot.copilot_thinking', 'Lhotse Copilot is thinking')}
                  </div>
                  <HiDotsHorizontal className="ml-2 h-4 w-4 animate-pulse" />
                </div>
              ) : (
                <button
                  type="button"
                  className="group flex items-center shadow text-sky-blue px-2 py-1 rounded transition-all duration-300 ease-in-out hover:px-4 hover:bg-sky-blue hover:text-white"
                  onClick={onCopilotButtonClick}
                >
                  <HiOutlineSparkles className="h-4 w-4" />
                  <div className="overflow-hidden group-hover:w-auto w-0 h-6 text-white transition-width">
                    <span className="pl-2 text-sm inline-block">Lhotse Copilot</span>
                  </div>
                </button>
              )}
            </div>
          )}
          {subtitle && (
            <div
              className="text-nds-secondary-gray my-2 text-sm"
              dangerouslySetInnerHTML={{ __html: sanitize(subtitle, { USE_PROFILES: { html: true } }) }}
            />
          )}
        </div>

        <div className="w-full">
          {!readonly && <FormFieldInput />}
          {readonly && <FormFieldDisplay />}
        </div>
      </div>
    </FormFieldProvider>
  )
}

export default FormField
