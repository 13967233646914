import { FormQuestionContentType } from '../../types/Forms'
import {
  FormFieldAddress,
  FormFieldDate,
  FormFieldMedia,
  FormFieldMultiSelect,
  FormFieldNumber,
  FormFieldSearchSelect,
  FormFieldSelect,
  FormFieldSupplier,
  FormFieldText,
} from './FormFields'
import { useFormField } from './providers/FormFieldProvider'
import ErrorMessage from '../../shared/components/atoms/ErrorMessage'
import FormFieldPrice from './FormFields/FormFieldPrice'
import FormFieldUserSelect from './FormFields/FormFieldUserSelect'
import FormFieldDateRange from './FormFields/FormFieldDateRange'
import FormFieldToggle from './FormFields/FormFieldToggle'
import FormFieldMultiUserSelect from './FormFields/FormFieldMultiUserSelect'

const FieldInputComponents: Partial<Record<FormQuestionContentType, React.FC>> = {
  TEXT: FormFieldText,
  TEXT_AREA: FormFieldText,
  SELECT: FormFieldSelect,
  MULTI_SELECT: FormFieldMultiSelect,
  NUMBER: FormFieldNumber,
  ADDRESS: FormFieldAddress,
  FILE: FormFieldMedia,
  DATE: FormFieldDate,
  DATE_TIME: FormFieldDate,
  DATE_RANGE: FormFieldDateRange,
  PRICE: FormFieldPrice,
  SEARCH_SELECT: FormFieldSearchSelect,
  SUPPLIER: FormFieldSupplier,
  USER: FormFieldUserSelect,
  MULTI_USER: FormFieldMultiUserSelect,
  TOGGLE: FormFieldToggle,
}
export const getFieldInputComponent = (type: FormQuestionContentType) => FieldInputComponents[type] || FormFieldText

export const FormFieldInput = () => {
  const {
    field: {
      error,
      input: { type },
    },
  } = useFormField()
  const InputComponent = getFieldInputComponent(type)

  return (
    <div className="flex flex-col gap-2">
      <InputComponent />
      {error && (
        <div className="w-full">
          <ErrorMessage error={error} />
        </div>
      )}
    </div>
  )
}

export default FormFieldInput
