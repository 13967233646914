import React, { HTMLAttributes } from 'react'
import classnames from 'classnames'
import BackButton from '../atoms/navigation/BackButton'

export type PageHeaderProps = {
  title?: JSX.Element
  children?: React.ReactNode
  sideSection?: React.ReactNode
  className?: HTMLAttributes<HTMLDivElement>['className']
  onBack?: () => void
}

export const PageHeaderWithBasicNavigation = React.forwardRef<HTMLDivElement, PageHeaderProps>(
  ({ title, className, sideSection, onBack, children }, ref) => (
    <div
      className={classnames(
        'flex sticky top-0 flex-col items-start bg-background z-10 w-full pt-8 px-20 pb-12',
        className
      )}
      ref={ref}
    >
      <div className="mb-[10px]">
        <BackButton onBack={onBack} />
      </div>
      <div className="flex flex-row min-h-[2.25rem] justify-between w-full mb-5">
        {title}
        <div className="flex items-start">{sideSection}</div>
      </div>
      {children && <div className="w-fit">{children}</div>}
    </div>
  )
)

PageHeaderWithBasicNavigation.displayName = 'PageHeaderWithBasicNavigation'

export default PageHeaderWithBasicNavigation
