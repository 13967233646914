import useIsLatestWorkflowVersion from './useIsLatestWorkflowVersion'
import useIsLatestPublishedWorkflowVersion from './useIsLatestPublishedWorkflowVersion'

export type WorkflowVersionType = 'draft' | 'published' | 'archived'

export const useWorkflowVersionType = (version: number): WorkflowVersionType => {
  const isLatest = useIsLatestWorkflowVersion(version)
  const isPublished = useIsLatestPublishedWorkflowVersion(version)
  // eslint-disable-next-line no-nested-ternary
  return isPublished ? 'published' : isLatest ? 'draft' : 'archived'
}
