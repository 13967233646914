import TabsNavigation from '../../../shared/components/atoms/Tabs/TabsNavigation'
import useGetSupplierBranchId from '../hooks/useGetSupplierBranchId'
import useGetSupplierDetailsTabs from '../hooks/useGetSupplierDetailsTabs'

const SupplierTabs = () => {
  const supplierBranchId = useGetSupplierBranchId()

  const tabs = useGetSupplierDetailsTabs(supplierBranchId)

  return (
    <div className="px-7 bg-white">
      <div className="border-b border-border-gray">
        <TabsNavigation tabs={tabs} />
      </div>
    </div>
  )
}
export default SupplierTabs
