import SearchSelect from '../../../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionType } from '../../../../../../../../../types/Forms'
import { InputSearchSelectItemType } from '../../../../../../../../../types/shared/InputSearchSelectTypes'
import useGetAddressData from '../../../../../../../../../shared/hooks/useGetAddressData'
import { getOptionFromAddress } from '../../../../../../../formUtils'

type LogicAddressValueProps = {
  question?: FormQuestionType
  value: number | undefined
  onChange: (value: InputSearchSelectItemType) => void
}

const LogicAddressValue: React.FC<LogicAddressValueProps> = ({ value, onChange }) => {
  const { data: addressData } = useGetAddressData()

  const options = addressData?.map(getOptionFromAddress) || []
  const selected = options.find((option) => option.id === value)

  return <SearchSelect className="text-sm" options={options} onChange={onChange} currentSelection={selected} />
}

export default LogicAddressValue
