import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import { FormSectionType } from '../../../../../../types/Forms'
import FormQuestionTypesModal from './FormQuestionTypesModal/FormQuestionTypesModal'
import useGetInputTypes from '../../../../hooks/useGetInputTypes'

interface IAddQuestionButton {
  section: FormSectionType
}

const AddQuestionButton: React.FC<IAddQuestionButton> = ({ section }) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const { data: availableTypes } = useGetInputTypes()

  const openQuestionGroupModal = () => {
    modal.set({
      isOpen: true,
      title: t('forms.add_question'),
      content: <FormQuestionTypesModal section={section} />,
      className: 'max-w-[450px] h-full',
    })
  }

  if (!availableTypes?.length || !section.actions.edit) return null

  return (
    <div>
      <button type="button" onClick={openQuestionGroupModal}>
        + <span className="underline text-sm">{t('forms.add_question_group')}</span>
      </button>
    </div>
  )
}

export default AddQuestionButton
