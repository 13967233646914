import { useQuery } from 'react-query'
import useGetSupplierBranchId from './useGetSupplierBranchId'
import keys from '../../Suppliers/constants/SuppliersQueryKeys'
import { apiGetSupplierBranch } from '../../Suppliers/services/SupplierBranchesService'

export default (paramSupplierBranchId?: number, disabled?: boolean) => {
  const urlSupplierBranchId = useGetSupplierBranchId()

  const supplierBranchId = paramSupplierBranchId || urlSupplierBranchId

  return useQuery([keys.SUPPLIER_BRANCH, supplierBranchId], () => apiGetSupplierBranch(supplierBranchId), {
    enabled: !!supplierBranchId && !disabled,
  })
}
