import type { PartialDeep } from 'type-fest'
import { cloneDeep } from 'lodash'
import { FormSectionType } from '../../../../../../../types/Forms/formTypes'
import useGetFormData from '../../../../../hooks/useGetFormData'
import useUpdateForm from '../../../../hooks/useUpdateForm'
import { mergeDeepOverwritingArrays } from '../../../../../../../shared/utils/objectHelpers'

export default () => {
  const { data: formData } = useGetFormData()
  const { updateForm } = useUpdateForm()

  const byUuid = (uuid: string) => (section: FormSectionType) => section.__uuid === uuid
  const getMutableSections = () => cloneDeep(formData?.template.sections) || []

  const updateSection = (uuid: string, updateData: PartialDeep<FormSectionType>) => {
    if (!formData) return

    const sections = getMutableSections()

    const sectionToUpdate = sections.find(byUuid(uuid))

    if (!sectionToUpdate) return

    mergeDeepOverwritingArrays(sectionToUpdate, updateData)

    updateForm({
      template: {
        sections,
      },
    })
  }

  return { updateSection }
}
