import { useTranslation } from 'react-i18next'
import { CalendarIcon, UsersIcon } from '@heroicons/react/24/outline'
import { WorkflowBlock } from '../../../../../../../../types/Workflow/WorkflowBlock'
import Button from '../../../../../../../../shared/components/atoms/buttons/Button'
import generateUserName from '../../../../../../../../shared/utils/generateUserName'
import { dateTimeFormatter } from '../../../../../../../../shared/constants/dateFormat'
import useBlockStatus from '../hooks/useBlockStatus'
import { WorkflowBlockEntry } from '../../../../../../../Workflow'
import useActiveWorkflowMutation from '../../../../../hooks/useActiveWorkflowMutations'
import CoreLoadingIcon from '../../../../../../../../core/components/CoreLoadingIcon'
import IntakeFlowchartApprovers from './IntakeFlowchartApprovers'
import IntakeFlowchartActionButton from './IntakeFlowchartActionButton'

export const IntakeFlowchartApprovalNodeContentDeadline = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()
  return (
    <WorkflowBlockEntry Icon={CalendarIcon} title={t('intake.flowchart.approvalNodeContent.deadline', 'Deadline')}>
      {block.reminder}
    </WorkflowBlockEntry>
  )
}

export const IntakeFlowchartApprovalNodeContentApprovers = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()

  return (
    <WorkflowBlockEntry
      Icon={UsersIcon}
      title={t('intake.flowchart.approvalNodeContent.approver_counter', 'Approvers')}
    >
      <IntakeFlowchartApprovers block={block} />
    </WorkflowBlockEntry>
  )
}

export const IntakeFlowchartApprovalNodeContent = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()

  const { isApproved, requiresCurrentUserApproval } = useBlockStatus(block)

  const {
    update: { mutate, isLoading: isHandlingWorkflowAction },
  } = useActiveWorkflowMutation()

  const approveBlock = () => {
    mutate({
      action: 'approved',
      workflow_block_uuid: block.uuid,
    })
  }

  const approversWhoApprovedTheBlock = block.approvers.filter((approver) => approver.hasApproved)

  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-full gap-6 ">
        <IntakeFlowchartApprovalNodeContentApprovers block={block} />
        <IntakeFlowchartApprovalNodeContentDeadline block={block} />
      </div>
      {isApproved && approversWhoApprovedTheBlock.length > 0 && (
        <div className="flex flex-col gap-2 w-full">
          <div className="text-sky-blue-light font-semibold">
            {t('intake.flowchart.approvalNodeContent.approved_by', 'Approved by')}
          </div>
          <div>
            {approversWhoApprovedTheBlock.map((approver) => (
              <div key={`block-${block.uuid}-approver-${approver.user.id || approver.user.user_id}`}>
                {t(generateUserName(approver.user))}
                {approver.updated_at && `, ${dateTimeFormatter(approver.updated_at)}`}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-row">
        <IntakeFlowchartActionButton block={block} />
      </div>
    </div>
  )
}

export default IntakeFlowchartApprovalNodeContent
