import { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import useIntakeFormStore from '../../stores/useIntakeFormStore'
import useIntakeFormSections from '../../hooks/useIntakeFormSections'
import SectionRenderer from './SectionRenderer'
import IntakeFormConfirmationSection from './IntakeFormConfirmationSection'

export const IntakeFormSections = forwardRef((props, detailRef: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation()

  const activeSectionIndex = useIntakeFormStore((state) => state.activeSectionIndex)

  const intakeFormSections = useIntakeFormSections()
  const activeFormSection = intakeFormSections?.[activeSectionIndex]

  if (!activeFormSection) return null

  if (activeFormSection.type_name === 'CONFIRMATION') {
    return <IntakeFormConfirmationSection ref={detailRef} />
  }

  return (
    <div ref={detailRef} className="flex flex-col gap-11 text-black-light max-w-3xl mx-auto">
      <SectionRenderer section={activeFormSection} useSectionWrapper={false} />
    </div>
  )
})
IntakeFormSections.displayName = 'IntakeFormSections'
export default IntakeFormSections
