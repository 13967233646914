import { FC } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import TableCell from '../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../../shared/components/atoms/SharedTable/TableRow'
import Menu from '../../../../../../shared/components/organisms/menus/Menu'
import LhotseHomeTableRowStatus from './LhotseHomeTableRowStatus'
import { RequestSearchType } from '../../../../../Search/Types/SearchEntitiesTypes'
import { unixtimeDateFormatter } from '../../../../../../shared/constants/dateFormat'
import useDeleteIntake from '../../../../../Intake/IntakeOverview/hooks/useDeleteIntake'
import useDuplicateIntake from '../../../../../Intake/IntakeOverview/hooks/useDuplicateIntake'

interface ILhotseHomeTableRow {
  request: RequestSearchType
}

const LhotseHomeTableRow: FC<ILhotseHomeTableRow> = ({ request }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { mutate: duplicateIntake } = useDuplicateIntake()
  const { mutate: deleteIntake } = useDeleteIntake()

  const handleRowClick = () => {
    const url = `/requests/${request.intake_uuid}`
    history.push(url)
  }

  const externalIdPresent: boolean = false
  return (
    <TableRow onClick={handleRowClick} className="hover:shadow transition">
      <TableCell>
        <div className="flex flex-col w-20">
          <span className={externalIdPresent ? 'text-xs' : ''}>{request.unique_name_identifier}</span>
          {externalIdPresent ? <span className="uppercase">{externalIdPresent ? 'external Id' : '-'}</span> : null}
        </div>
      </TableCell>
      <TableCell>
        <span className="line-clamp-1 max-w-[170px]">{request.name || request.form_name}</span>
      </TableCell>
      <TableCell>
        <span className="line-clamp-1">{request?.author_full_name}</span>
      </TableCell>
      <TableCell>{unixtimeDateFormatter(request?.created_at)}</TableCell>
      <TableCell>
        <LhotseHomeTableRowStatus {...request} />
      </TableCell>
      <TableCell>
        <Menu
          key={`menu-${request.intake_uuid}`}
          menuItems={[
            { name: t('generic.duplicate', 'Duplicate'), onClick: () => duplicateIntake(request.intake_uuid) },
            {
              name: t('generic.delete', 'Delete'),
              onClick: () => deleteIntake(request.intake_uuid),
              disabled: request.type !== 'submission',
            },
          ]}
        />
      </TableCell>
    </TableRow>
  )
}

export default LhotseHomeTableRow
