import { useMutation } from 'react-query'
import { deleteIntake } from '../services'
import { toastr } from '../../../../shared/components/organisms/toastr'
import useSearchSubmissions from '../../../Search/Hooks/Submissions/useSearchSubmissions'

const useDeleteIntake = () => {
  const { refetch: refetchIntakes } = useSearchSubmissions()

  return useMutation((intakeUuid: string) => deleteIntake(intakeUuid), {
    onSuccess: () => {
      refetchIntakes().then(() => {
        toastr.success('success.item_deleted', 'successRequisitionDeleted')
      })
    },
  })
}

export default useDeleteIntake
