import { PencilIcon, ArrowPathIcon, CheckIcon } from '@heroicons/react/24/outline'
import RoundIconWrapper from '../../misc/RoundIconWrapper'
import { ColorStyle } from '../../../../../types/shared/Styles'
import { WorkflowVersionType } from '../../../hooks/useWorkflowVersionType'

const iconColors: Record<WorkflowVersionType, ColorStyle> = {
  draft: 'black-light-2',
  published: 'sky-blue-light',
  archived: 'black-light-2',
}

export const WorkflowVersionIcon = ({ type }: { type: WorkflowVersionType }) => (
  <RoundIconWrapper color={iconColors[type]} className="group-hover:text-red group-hover:bg-green-light">
    {type === 'draft' && <PencilIcon className="w-4 h-4 text-white" />}
    {type === 'published' && <ArrowPathIcon className="w-4 h-4 text-white" />}
    {type === 'archived' && <CheckIcon className="w-4 h-4 text-white" />}
  </RoundIconWrapper>
)

export default WorkflowVersionIcon
