import { useTranslation } from 'react-i18next'
import ErrorPage from '../ErrorPage'

const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <ErrorPage
      header="404"
      title={t('error.page_not_found.title')}
      description={t('error.page_not_found.description')}
    />
  )
}
export default PageNotFound
