import { useTranslation } from 'react-i18next'
import useGetCurrentUser from './useGetCurrentUser'

export default () => {
  const { i18n } = useTranslation()
  const currentUser = useGetCurrentUser()

  const localStorageLanguage = localStorage.getItem('language')

  if (
    !localStorageLanguage ||
    (localStorageLanguage && currentUser?.data?.language && localStorageLanguage !== currentUser?.data?.language)
  ) {
    const parsedLanguage = currentUser?.data?.language || 'en'

    localStorage.setItem('language', parsedLanguage)
    i18n.changeLanguage(parsedLanguage)
  }
}
