import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../../../../core/providers/UtilsProvider'
import Button from '../../../../../../../../../shared/components/atoms/buttons/Button'
import { OfferItem } from '../../../../../../../../../types/Offering'
import OfferInline from './OfferInline'
import useGetIntakeOffers from '../../../../../../hooks/useGetIntakeOffers'
import useIntakeFormStore from '../../../../../../../IntakeFormResponse/stores/useIntakeFormStore'

type IProductSectionOfferModal = {
  offer: OfferItem
  addOffer?: (offer: OfferItem | Partial<OfferItem>) => Promise<OfferItem>
  updateOffer: (offer: OfferItem) => Promise<void>
  mode: 'create' | 'edit'
}
const OfferModal: React.FC<IProductSectionOfferModal> = ({ offer, addOffer, updateOffer, mode }) => {
  const intakeUuid = useIntakeFormStore((state) => state.intake?.uuid)
  const { data: offers } = useGetIntakeOffers(intakeUuid)

  const [currentOfferItem, setCurrentOfferItem] = useState<OfferItem | Partial<OfferItem>>(offer)
  useEffect(() => {
    setCurrentOfferItem(offer)
  }, [offer.uuid])

  const { t } = useTranslation()
  const { modal } = useUtils()

  const close = () => {
    modal.set({ isOpen: false })
  }

  const updateOfferItem = async (offerItem: OfferItem) => {
    setCurrentOfferItem(offerItem)
  }

  const onDoneClick = async () => {
    if (mode === 'create') {
      addOffer && addOffer(currentOfferItem)
    } else {
      await updateOffer(currentOfferItem as OfferItem)
    }

    close()
  }

  return (
    <div>
      <OfferInline offerItem={currentOfferItem} offers={offers} addOffer={addOffer} updateOffer={updateOfferItem} />

      <div className="flex justify-end w-full gap-4  max-w-3xl mx-auto my-8">
        <Button onClick={close} className="btn-ghost">
          {t('generic.cancel', 'Cancel')}
        </Button>
        <Button onClick={onDoneClick} className="btn-primary">
          {t('generic.ok', 'Done')}
        </Button>
      </div>
    </div>
  )
}

export default OfferModal
