import { WorkflowBlockStatus } from './WorkflowBlockStatus'
import { UserType } from '../shared/AssignmentTypes'
import { FormQuestionContentType, InputTemplate } from '../Forms'
import { WorkflowActionWrapped } from './WorkflowAction'

export type WorkflowBlockActionType = 'APPROVER' | 'APPROVAL' | 'ACTION'
export type WorkflowNodeMetaType = 'GROUP' | 'HELPER'

export type WorkflowNodeType = WorkflowBlockActionType | WorkflowNodeMetaType

export type WorkflowBlockType = {
  id: number
  name: WorkflowNodeType
}

export type WorkflowBlockApprover = {
  value?: number | string
  type: 'USER' | 'AUTHOR'
  created_at?: string
  updated_at?: string
  user: UserType & { user_id: number }
  hasApproved?: boolean
}

export type WorkflowActionTriggerInputType = 'INPUT' | 'NON_INPUT'

export type WorkflowBlockTriggerComparisonOperator =
  | 'IS_EMPTY'
  | 'IS_NOT_EMPTY'
  | 'EQUAL'
  | 'NON_EQUAL'
  | 'BIGGER_THAN'
  | 'LOWER_THAN'
  | 'EQUAL_LOWER_THAN'
  | 'EQUAL_BIGGER_THAN'
  | 'STARTS_WITH'
  | 'ENDS_WITH'

export type WorkflowBlockTrigger = {
  uuid: string
  other?: string | null
  order: number
  name: string
  condition_operator: 'AND' | 'OR'
  comparison_operator: WorkflowBlockTriggerComparisonOperator
  value: string
  trigger_type: WorkflowActionTriggerInputType
  input_reference: string
  input_uuid: string
  input_type: FormQuestionContentType
  input: InputTemplate
}

// FIXME: Type should be merged with input_type when backend has harmonized types.
export type WorkflowActionTriggerSelectionType = Pick<
  WorkflowBlockTrigger,
  'name' | 'comparison_operator' | 'value' | 'trigger_type' | 'input_reference' | 'input_type'
> & { type: FormQuestionContentType }

export const WorkflowBlockReminders = ['12H', '24H', '48H', '72H'] as const

export type WorkflowBlockReminder = (typeof WorkflowBlockReminders)[number]

export type WorkflowBlockStatuses =
  | 'skipped'
  | 'completed'
  | 'ongoing'
  | 'in_queue'
  | 'pending'
  | 'approved'
  | 'failed'
  | 'declined'

export type WorkflowBlockTask = {
  id?: number
  name: string
  uuid?: string
  parent_type?: 'active_workflow_block'
  parent_id?: number
  description: string
  required: 0 | 1
  assignees: Partial<WorkflowBlockApprover>[]
  status?: number
}

export type WorkflowBlock = {
  uuid: string
  name: string
  type: WorkflowBlockActionType
  created_by?: number
  triggers: WorkflowBlockTrigger[]
  approvers: WorkflowBlockApprover[]
  reminder: WorkflowBlockReminder
  tasks: WorkflowBlockTask[]
  workflow_block_type?: WorkflowBlockType
  workflow_block_status?: WorkflowBlockStatus
  status?: WorkflowBlockStatuses
  actions?: WorkflowActionWrapped[]
  current_user_can_approve?: boolean
  options?: { allowApproveUserRequester: boolean }
  status_comment?: string
  has_actions?: boolean
}

export type WorkflowStep = {
  uuid: string
  position: number
  blocks: WorkflowBlock[]
  status?: WorkflowBlockStatuses
}

export type WorkflowStepDraft = Omit<WorkflowStep, 'uuid'> & { uuid?: string | null }
