import { useQuery } from 'react-query'
import { apiGetData } from '../../../core/utils/api/generic'

import { WorkflowActionTriggerSelectionType } from '../../../types/Workflow/WorkflowBlock'
import { Workflow } from '../../../types/Workflow/Workflow'

export default function useWorkflowService() {
  const getWorkflowRuleTriggers = useQuery(['workflowRuleTriggers'], () =>
    apiGetData<WorkflowActionTriggerSelectionType[]>('/flow-rules/triggers')
  )

  const getWorkflows = useQuery(['workflows'], () => apiGetData<Workflow[]>('/workflows'))

  return { getWorkflows, getWorkflowRuleTriggers }
}
