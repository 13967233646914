import { OfferItem } from '../../../../../types/Offering'

export const getOfferTemplate = (defaultValues: Partial<OfferItem>) =>
  ({
    id: -1,
    offer: { custom_id: '' },
    quantity: 1,
    price_per_unit: 0,
    net_shipping_costs: 0,
    total_net_price: 0,
    total_gross_price: 0,
    supplier: '',
    vat: 19,
    is_selected: false,
    delivery_date: '',
    files: null,
    ...defaultValues,
  }) as unknown as OfferItem & { reference_key?: string; offering_uuid?: string }

export default getOfferTemplate
