import { MdAddCircleOutline } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FormSectionType, FormQuestionType } from '../../../../../../../types/Forms'
import useGetQuestionIndex from '../../../../../hooks/useGetQuestionIndex'
import useQuestionLogicSettingState from '../hooks/useQuestionLogicSettingState'
import { getFreshSwitchCase } from '../hooks/useQuestionLogicSwitchMutations'
import getLogicComparisonOptions from './FormLogicConditionInput/LogicOperatorDropdown/getLogicComparisonOptions'
import useGetAllQuestions from '../../../../../hooks/useGetAllQuestions'

interface IAddLogicConditionButton {
  question: FormQuestionType
  section: FormSectionType
}

const AddLogicConditionButton: React.FC<IAddLogicConditionButton> = ({ question, section }) => {
  const { t } = useTranslation()

  const [logic, setLogic] = useQuestionLogicSettingState(question, section)

  const logicOptions = getLogicComparisonOptions(question)
  const allQuestions = useGetAllQuestions()
  const questionIndex = useGetQuestionIndex(question)

  const followingQuestions = allQuestions?.slice(questionIndex)?.map((followingQuestion, index) => ({
    label: followingQuestion.label,
    id: index,
  }))

  if (logic || !logicOptions.length || !followingQuestions?.length || !section.actions.edit) {
    return null
  }

  return (
    <button
      type="button"
      className="flex gap-x-2 items-center text-sm text-primary-gray"
      onClick={() => {
        setLogic({
          ...(logic || {}),
          questionUUID: question.__uuid,
          switch: {
            cases: [getFreshSwitchCase()],
          },
        })
      }}
    >
      <MdAddCircleOutline />
      <p>{t('forms.add_condition')}</p>
    </button>
  )
}

export default AddLogicConditionButton
