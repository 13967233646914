import { useTranslation } from 'react-i18next'
import AuthLayout from '../shared/AuthLayout'
import { useGetUrlSearchParam } from '../../../../core/hooks/useGetUrlParam'
import { FormFieldType } from '../shared/Types'
import useSubmitUserData from './hooks/useSubmitUserData'
import AuthForm, {
  emailValidator,
  firstNameValidator,
  lastNameValidator,
  passwordConfirmationValidator,
  passwordCreationValidator,
} from '../shared/Components/AuthForm'
import useGetInitialValues from './hooks/useGetInitialValues'

const SignUp = () => {
  const token = useGetUrlSearchParam('token')
  const { t } = useTranslation()
  const initialValues = useGetInitialValues()
  const { mutate: submitUserData, isLoading } = useSubmitUserData()
  const form: FormFieldType[] = [
    {
      attribute: 'email',
      name: t('generic.email', 'Email'),
      placeholder: t('generic.email_placeholder', 'Email'),
      value: initialValues.email ?? '',
      type: 'text',
      validator: emailValidator,
    },
    {
      attribute: 'first_name',
      name: t('generic.first_name', 'First name'),
      placeholder: t('generic.first_name_placeholder', 'First name'),
      value: '',
      type: 'text',
      validator: firstNameValidator,
    },
    {
      attribute: 'last_name',
      name: t('generic.last_name', 'Last name'),
      placeholder: t('generic.last_name_placeholder', 'Last name'),
      value: '',
      type: 'text',
      validator: lastNameValidator,
    },
    {
      attribute: 'password',
      name: t('generic.password', 'Password'),
      placeholder: t('generic.password', 'Password'),
      value: '',
      type: 'password',
      validator: passwordCreationValidator,
    },
    {
      attribute: 'password_confirmation',
      name: t('generic.password_confirmation', 'Confirm Password'),
      placeholder: t('generic.password_confirmation', 'Confirm Password'),
      value: '',
      type: 'password',
      validator: passwordConfirmationValidator,
    },
  ]

  const onSubmit = (data: any) => submitUserData({ ...data, token })

  const onError = (data: any) => console.log(data)

  return (
    <AuthLayout title={t('generic.forgot_password', 'Forgot Password')} className="mt-28" showBackButton>
      <AuthForm
        fields={form}
        onSubmit={onSubmit}
        onError={onError}
        isSubmitting={isLoading}
        btnText={t('generic.sign_up', 'Sign up')}
      />
    </AuthLayout>
  )
}
export default SignUp
