import { Dispatch, SetStateAction } from 'react'
import CancelButton from './CancelButton'
import CharacterCount from './CharacterCount'
import SaveButton from './SaveButton'

export interface IFloatingElements {
  onCancel: () => void
  onSave: () => void
  shouldDisplayButtons: boolean
  inputValue: string
  setIsInputActive?: Dispatch<SetStateAction<boolean>>
  disabled?: boolean
  charLimit?: number
  charMin?: number
}

const FloatingElements: React.FC<IFloatingElements> = ({
  disabled,
  onCancel,
  onSave,
  shouldDisplayButtons,
  setIsInputActive,
  inputValue,
  charLimit,
  charMin,
}) => {
  const charError = !!(charLimit && inputValue.length > charLimit) || !!(charMin && inputValue.length < charMin)

  if (!shouldDisplayButtons) return null

  return (
    <div className={`flex flex-col w-full absolute left-0 ${charLimit ? 'top-[32px]' : 'top-[48px]'}`}>
      <CharacterCount charError={charError} inputValue={inputValue} charLimit={charLimit} />
      <div className="flex gap-x-4 justify-end">
        <CancelButton onCancel={onCancel} setIsInputActive={setIsInputActive} />
        <SaveButton charError={charError} onSave={onSave} disabled={disabled} setIsInputActive={setIsInputActive} />
      </div>
    </div>
  )
}

export default FloatingElements
