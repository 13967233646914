// import above from react-icons instead
import {
  HiChartPie,
  HiOutlineChartPie,
  HiOutlineCog6Tooth,
  HiOutlineDocumentText,
  HiOutlineHome,
  HiOutlineRectangleStack,
} from 'react-icons/hi2'
import { CoreNavigationLinkType } from '../../../../types/shared/CoreNavigationLinkType'
import useDashboardUrls from '../../../../modules/Analytics/services/useMetabaseDashboardService'

export default () => {
  const { data: dashboard_configs } = useDashboardUrls()
  const hasMetabaseConfigs = dashboard_configs?.dashboard_urls && dashboard_configs.dashboard_urls.length > 0

  const navigationItems: CoreNavigationLinkType[] = [
    {
      name: 'generic.home',
      sections: ['/', '/intake-flows/create'],
      href: '/',
      icon: () => <HiOutlineHome className="h-6" />,
      current: false,
      type: 'link',
      visible: true,
    },
    {
      name: 'generic.intake-flows',
      sections: ['/intake-flows', '/intake-flows/create', '/intake-flow', '/intake-flow/:id'],
      href: '/intake-flows',
      icon: () => <HiOutlineDocumentText className="h-6" />,
      current: false,
      type: 'link',
      visible: true,
    },
    {
      name: 'generic.analytics',
      type: 'link',
      icon: () => <HiOutlineChartPie className="h-6" />,
      current: false,
      sections: ['/analytics'],
      href: '/analytics',
      visible: hasMetabaseConfigs,
    },
    {
      name: 'generic.supplier_plural',
      sections: ['/suppliers', '/supplier'],
      href: '/suppliers',
      icon: () => <HiOutlineRectangleStack className="h-6" />,
      current: false,
      type: 'link',
      visible: true,
    },
    {
      name: 'generic.company',
      type: 'link',
      icon: () => <HiOutlineCog6Tooth className="h-6" />,
      current: false,
      sections: ['/settings', '/data-manager', '/forms', '/workflows'],
      href: '/settings',
      visible: true,
    },
  ]

  return navigationItems
}
