import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { blurInputOnEnter } from '../../../utils/inputUtils'
import RequiredLabel from '../Labels/RequiredLabel'

export type IInputNumber = {
  className?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  dataCy?: string
  precision?: number
  forceUpdate?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, updatedValue: string) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, updatedValue: string) => void
} & Omit<NumericFormatProps, 'onChange' | 'onBlur'>

const InputNumber: React.FC<IInputNumber> = ({
  className,
  required,
  placeholder,
  disabled,
  dataCy,
  precision,
  forceUpdate,
  suffix,
  onBlur,
  onChange,
  ...numberFormatProps
}) => {
  const { t } = useTranslation()

  const formatValue = (value: string) => value.substring(0, value.length - (suffix?.length || 0))

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e, formatValue(e.currentTarget.value))
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e, formatValue(e.currentTarget.value))
  }

  return (
    <div className="relative flex items-center group w-full">
      {required && <RequiredLabel className="l-0 h-6" />}
      <NumericFormat
        required={required}
        thousandsGroupStyle="thousand"
        disabled={disabled}
        decimalSeparator=","
        thousandSeparator="."
        displayType="input"
        type="text"
        data-cy={dataCy}
        decimalScale={precision ?? 2}
        fixedDecimalScale
        className={`${className} border-gray-300 rounded-md placeholder-secondary-gray
          focus:ring-0 focus:border-gray-300 ${disabled && 'bg-gray-300'}
          text-sm ${forceUpdate}
        `}
        placeholder={t(placeholder || '')}
        onKeyDown={blurInputOnEnter}
        allowNegative
        onChange={handleChange}
        onBlur={handleBlur}
        suffix={suffix}
        {...numberFormatProps}
      />
    </div>
  )
}

export default InputNumber
