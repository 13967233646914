import { MdExpandMore } from 'react-icons/md'
import { MenuItemType } from '../menus/Menu'
import PopoverMenu from './PopoverMenu'

interface IButtonWithDropdown {
  text: string
  options: MenuItemType[]
}

const ButtonWithDropdown: React.FC<IButtonWithDropdown> = ({ text, options }) => (
  <div className="relative overflow-visible w-fit">
    <PopoverMenu menuItems={options}>
      <div
        className="flex items-center rounded-md px-4 py-3 font-medium hover:brightness-10 disabled:hover:brightness-100
        disabled:bg-gray-200 disabled:text-gray-400 focus:outline-none focus:ring
        focus:border-sky-blue bg-sky-blue hover:bg-sky-blue-light transition-colors text-white space-x-2"
      >
        <span>{text}</span>
        <MdExpandMore />
      </div>
    </PopoverMenu>
  </div>
)

export default ButtonWithDropdown
