import { ElementWithRouterType, INodeChildren } from '../../../types/shared/GeneralTypes'
import CoreSidebar from '../Sidebar/CoreSidebar'
import { useLocalStorage } from '../../providers/LocalStorageProvider'

interface props extends INodeChildren {
  topbar?: ElementWithRouterType | (() => JSX.Element | React.ReactElement<any, any> | null)
}

const CoreMainLayout: React.FC<props> = ({ children }) => {
  const [getLocalStorage] = useLocalStorage()
  const minified = getLocalStorage('sidebarMinified', 'false')

  return (
    <div className="flex w-full h-full min-h-screen">
      <div className="flex flex-shrink-0">
        <CoreSidebar />
      </div>

      <div className={`${minified ? 'ml-20' : 'ml-52'} flex min-w-0 flex-1 flex-col`}>
        <main className="flex flex-1 h-full bg-background">{children}</main>
      </div>
    </div>
  )
}

export default CoreMainLayout
