import { FC } from 'react'

interface ISectionWrapper {
  actions?: React.ReactNode
  title?: React.ReactNode
  children: React.ReactNode
  className?: string
}

const SectionWrapper: FC<ISectionWrapper> = ({ actions, title, children, className }) => (
  <div className={`mt-2 w-full space-y-4  bg-white rounded-md border shadow-sm ${className}`}>
    {title && (
      <div className="text-base pl-6 px-4 py-6 font-semibold flex space-x-2 items-center border-b border-gray-200  justify-between">
        <div>{title}</div>
        <div>{actions}</div>
      </div>
    )}
    {children}
  </div>
)

export default SectionWrapper
