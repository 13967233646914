import { MdArrowBackIos } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import useIntakeFormStore from '../../../stores/useIntakeFormStore'

interface IPreviousSectionButton {}

const PreviousSectionButton: React.FC<IPreviousSectionButton> = () => {
  const { t } = useTranslation()

  const activeSectionIndex = useIntakeFormStore((state) => state.activeSectionIndex)
  const setActiveSectionIndex = useIntakeFormStore((state) => state.setActiveSectionIndex)

  return (
    <Button
      className={`btn-primary-ghost ${activeSectionIndex === 0 ? 'invisible' : ''}`}
      onClick={() => setActiveSectionIndex(activeSectionIndex - 1)}
      disabled={activeSectionIndex === 0}
    >
      <MdArrowBackIos />
      <p className="whitespace-nowrap">{t('forms.previous_section', 'Previous section')}</p>
    </Button>
  )
}

export default PreviousSectionButton
