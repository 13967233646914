import { AddressDataType } from '../../types/shared/RequisitionDataTypes'

export const HTMLStringToString = (html: string) => html?.replace(/<[^>]*>?/gm, '')

export const addressToAddressString = (address: AddressDataType): string =>
  `${address?.street}, ${address?.zip} ${address?.city}, ${address?.country?.name}`

export const removeHTMLTags = (html: string) => html?.replace(/(<([^>]+)>)/gi, '')

/** Quote regular expression characters plus an optional character
version: 1109.2015
discuss at: http://phpjs.org/functions/preg_quote
+   original by: booeyOH
+   improved by: Ates Goral (http://magnetiq.com)
+   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
+   bugfixed by: Onno Marsman
+   improved by: Brett Zamir (http://brett-zamir.me)
*     example 1: preg_quote("$40");
*     returns 1: '\$40'
*     example 2: preg_quote("*RRRING* Hello?");
*     returns 2: '\*RRRING\* Hello\?'
*     example 3: preg_quote("\\.+*?[^]$(){}=!<>|:");
*     returns 3: '\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:' */
export const escape = (str: string, delimiter: string = '') =>
  `${str}`.replace(new RegExp(`[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\${delimiter}-]`, 'g'), '\\$&')
