import { ReactNode } from 'react'
import cn from '../../../lib/util'

export type ChipMenuProps = {
  children: (props: any) => ReactNode
  className?: string
}

const ChipMenu = ({ children, className, ...props }: ChipMenuProps) => (
  <div
    className={cn(
      'flex rounded-full border border-rock-gray-light py-1.5 px-2 w-auto self-center space-x-2.5',
      className
    )}
  >
    {children(props)}
  </div>
)

export default ChipMenu
