import { FC, InputHTMLAttributes } from 'react'

interface ICheckbox extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label: string
  id: string
  onChange: (value: boolean) => void
}
const Checkbox: FC<ICheckbox> = ({ label, id, ...rest }) => (
  <div className="relative flex items-start">
    <div className="flex h-5 items-center">
      <input
        aria-describedby="documents"
        type="checkbox"
        id={id}
        {...rest}
        className="h-4 w-4 rounded border-gray-300 text-sky-blue-light disabled:text-mountain-gray "
        onChange={({ target: { checked } }) => rest?.onChange && rest?.onChange(checked)}
      />
    </div>
    <div className="ml-3 text-sm">
      <label htmlFor={id} className="font-sm">
        {label}
      </label>
    </div>
  </div>
)

export default Checkbox
