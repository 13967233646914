import { MdOutlineCancel } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FormQuestionType, FormSectionType } from '../../../../../../../../types/Forms'
import useQuestionLogicSettingState from '../../hooks/useQuestionLogicSettingState'

interface IRemoveConditionButton {
  question: FormQuestionType
  section: FormSectionType
}

const RemoveLogicButton: React.FC<IRemoveConditionButton> = ({ question, section }) => {
  const { t } = useTranslation()
  const [, setLogic] = useQuestionLogicSettingState(question, section)

  if (question.__loop?.is_loop_trigger || !section.actions.edit) return null

  return (
    <button type="button" className=" flex gap-x-2 items-center" onClick={() => setLogic(null)}>
      <MdOutlineCancel />
      <p className="underline">{t('forms.remove_logic')}</p>
    </button>
  )
}

export default RemoveLogicButton
