import { INodeOrStringChildren } from '../../../types/shared/GeneralTypes'

interface IRowCell extends INodeOrStringChildren {
  width: string
  className?: string
}
const RowCell: React.FC<IRowCell> = ({ width, className = '', children }) => (
  <div className={`${className} flex px-4 py-1 items-center ${width}`}>{children}</div>
)

export default RowCell
