import { useTranslation } from 'react-i18next'
import AddButton from '../../../../../../../../shared/components/atoms/buttons/AddButton'

type IProductSectionOffersAddButton = {
  onAddOfferButtonClick: () => void
}

const OffersAddButton: React.FC<IProductSectionOffersAddButton> = ({ onAddOfferButtonClick }) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-end">
      <AddButton onClick={onAddOfferButtonClick} label={t('generic.add-offer', 'Add offer')} />
    </div>
  )
}

export default OffersAddButton
