import LhotseHomeResultTransition from '../LhotseHomeResultTransition'

const LhotseHomeFormSkeleton = () => (
  <LhotseHomeResultTransition>
    <div className="rounded overflow-hidden shadow-sm bg-white">
      <div className="animate-pulse flex space-x-4 w-full">
        <div className="flex-1 space-y-6">
          <div className="h-36 bg-gray-200" />
          <div className="space-y-3 p-2">
            <div className="h-2 bg-gray-200 rounded" />
            <div className="h-2 bg-gray-200 rounded" />
          </div>
        </div>
      </div>
    </div>
  </LhotseHomeResultTransition>
)

export default LhotseHomeFormSkeleton
