import { IntakeFieldType } from '../../../../../types/Intake'
import FormField from '../../../../Forms-v2/FormField'
import { FormFieldSettings } from '../../../../Forms-v2/types'
import cn from '../../../../../lib/util'
import usePrepareIntakeFormField from '../../../IntakeFormResponse/hooks/usePrepareIntakeFormField'

type IntakeFormFieldProps = {
  field: IntakeFieldType
  updateField: (field: FormFieldSettings<string>) => void
  editModeOn?: boolean
  disabled?: boolean
}

export type IntakeFormFieldValuesProps = {}

export const IntakeFormField = ({ field, updateField, editModeOn, disabled }: IntakeFormFieldProps) => {
  const { shouldShowSubtitle, isFieldHidden, readonly, label, errorMessage, handleUpdateField, showErrors } =
    usePrepareIntakeFormField(field, updateField, editModeOn || false)

  return (
    <div className={cn(isFieldHidden ? 'hidden' : undefined)}>
      <FormField
        field={field}
        label={label || undefined}
        subtitle={shouldShowSubtitle && field.subtitle ? field.subtitle : undefined}
        error={showErrors ? errorMessage : undefined}
        readonly={readonly}
        updateField={handleUpdateField}
        disabled={disabled}
      />
    </div>
  )
}

export default IntakeFormField
