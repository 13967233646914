import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { MdDragIndicator } from 'react-icons/md'
import { INodeChildren } from '../../../types/shared/GeneralTypes'
import TableRow from '../atoms/SharedTable/TableRow'
import TableCell from '../atoms/SharedTable/TableCell'

interface ITwoTierTableRow extends INodeChildren {
  onClick: () => any
  title: string | React.ReactNode
  menuElement?: JSX.Element | null
  dragHandleProps?: DraggableProvidedDragHandleProps
}

const TwoTierTableRow: React.FC<ITwoTierTableRow> = ({ onClick, title, children, menuElement, dragHandleProps }) => (
  <TableRow className="flex w-full rounded-md items-center gap-x-4 mt-0 mb-0" flex>
    {dragHandleProps && (
      <div {...dragHandleProps}>
        <MdDragIndicator size={20} className="text-secondary-gray" />
      </div>
    )}
    <button type="button" onClick={onClick} className="text-base text-left flex flex-col w-full">
      <p>{title}</p>
      <div className="text-secondary-gray text-xs flex gap-y-2 items-center gap-x-2">{children}</div>
    </button>
    <div>{menuElement}</div>
  </TableRow>
)

export default TwoTierTableRow
