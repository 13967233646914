/* eslint-disable import/prefer-default-export */
export const deepCopy = (object: any) => JSON.parse(JSON.stringify(object))

export const getValidDate = (date: string): Date | undefined => {
  const dateObject = new Date(date)
  if (dateObject instanceof Date && !Number.isNaN(dateObject.getTime())) {
    return dateObject
  }
  return undefined
}
