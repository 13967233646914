import { useMutation, useQueryClient } from 'react-query'
import type { PartialDeep } from 'type-fest'
import { useFormContext } from '../../../../core/providers/FormProvider'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { apiPutForm, formsQueryKeys } from '../constants/formService'
import { FormDataType } from '../../../../types/Forms'
import useGetFormData from '../../hooks/useGetFormData'
import useGetFormId from './useGetFormId'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'
import useUnpublishFormConfirmation from './useUnpublishFormConfirmation'

export default () => {
  const {
    lastSaved: [, setLastSaved],
  } = useFormContext()
  const { data } = useGetFormData()
  const { modal } = useUtils()
  const client = useQueryClient()

  const formId = useGetFormId()
  const QUERY_KEY = formsQueryKeys.apiGetForm(formId)

  const mutation = useMutation((newData: FormDataType) => apiPutForm(newData, formId), {
    onMutate: async (newData) => {
      await client.cancelQueries(QUERY_KEY)
      const previousFormData = client.getQueryData<FormDataType>(QUERY_KEY)
      client.setQueryData<FormDataType | undefined>(QUERY_KEY, { ...newData, published: false })
      return { previousFormData }
    },
    onSuccess: () => {
      // refetch()
      setLastSaved(new Date())
      modal.set({ isOpen: false })
    },
  })

  const handlePublishedFormUpdate = useUnpublishFormConfirmation()

  const updateForm = (newData: PartialDeep<FormDataType>): Promise<FormDataType | void> => {
    if (!data) return Promise.resolve(undefined)

    const update = () => mutation.mutateAsync(getMergedDeepOverwritingArrays<FormDataType>(data, newData))

    if (data.published) {
      return handlePublishedFormUpdate(update)
    }

    return update()
  }

  return { ...mutation, updateForm }
}
