import { sanitize } from 'dompurify'
import FormFieldProvider from './providers/FormFieldProvider'

import FormFieldInput from './FormFieldInput'
import { FormFieldDisplay } from './FormFieldDisplay'
import { FormQuestionValue } from '../../types/Forms'
import cn from '../../lib/util'
import { FormFieldProps } from './types'
import { inputOptionToFieldOption } from './helpers'

export const FormField = <T extends string = string>({
  readonly,
  field,
  label,
  subtitle,
  error,
  disabled,

  className,
  ...props
}: FormFieldProps<T>) => {
  const normalizedField = {
    ...field,
    media: field.media || [],
    error,
    options: field.input.options?.map(inputOptionToFieldOption),
    question: field.question,
    value: Array.isArray(field.value) ? field.value[0] : field.value,
    values: Array.isArray(field.value) ? field.value : ([field.value].filter((v) => !!v) as FormQuestionValue[]),
    disabled,
  }

  return (
    <FormFieldProvider<T> field={normalizedField} {...props}>
      <div className={cn('flex flex-col gap-3 py-1', className, error && '!text-red-500')}>
        <div>
          {label && (
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-1 font-semibold">
                <div className="">{label}</div>
              </div>
            </div>
          )}
          {subtitle && (
            <div
              className="text-nds-secondary-gray my-2 text-sm"
              dangerouslySetInnerHTML={{ __html: sanitize(subtitle, { USE_PROFILES: { html: true } }) }}
            />
          )}
        </div>

        <div className="w-full">
          {!readonly && <FormFieldInput />}
          {readonly && <FormFieldDisplay />}
        </div>
      </div>
    </FormFieldProvider>
  )
}

export default FormField
