import { WorkflowChartNodeTemplate } from '../../../../WorkflowChart'
import { WorkflowChartBlockNodeProps } from '../../../../../types/Workflow/WorkflowChart'
import { useWorkflow } from '../../../providers/WorkflowProvider'
import WorkflowBlockApproversCounter from './components/WorkflowBlockApproversCounter'
import WorkflowBlockActionsCounter from './components/WorkflowBlockActionsCounter'
import WorkflowBlockDeadline from './components/WorkflowBlockDeadline'
import WorkflowBlockHeadline from './components/WorkflowBlockHeadline'
import blockHasApprovers from '../../../helpers'

export const WorkflowBuilderBlock = (node: WorkflowChartBlockNodeProps) => {
  const { toggleBlockSelection } = useWorkflow()
  const { data: block } = node

  const handleClick = () => {
    toggleBlockSelection(block)
  }

  return (
    <WorkflowChartNodeTemplate node={node} onClick={handleClick}>
      <WorkflowBlockHeadline block={block} />
      <div className="flex flex-col gap-6 text-black-light w-full">
        <div className="flex flex-row justify-between gap-6 w-full">
          {blockHasApprovers(block) && <WorkflowBlockApproversCounter block={block} />}
          {block.type === 'ACTION' && <WorkflowBlockActionsCounter block={block} />}
          <WorkflowBlockDeadline block={block} />
        </div>
      </div>
    </WorkflowChartNodeTemplate>
  )
}

export default WorkflowBuilderBlock
