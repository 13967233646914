import { ButtonHTMLAttributes, HTMLAttributes, useCallback } from 'react'
import classnames from 'classnames'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import { coreColorsType } from '../../../constants/coreColors'
import buttonStylePicker from '../../../utils/buttonStylePicker'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  variant?: 'outlined' | 'solid' | 'ghost' | string
  color?: coreColorsType | string
  type?: 'submit' | 'reset' | 'button' | undefined
  children?: ReactI18NextChild | ReactI18NextChild[]
  loading?: boolean
  dataCy?: string
  onClick?: (e: any) => void
  contentWrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
}

const Button: React.FC<IButton> = ({
  children,
  className,
  color,
  variant,
  loading,
  dataCy,
  onClick,
  type = 'button',
  contentWrapperClassName,
  ...rest
}) => {
  const buttonStylePickerWrapper = useCallback(() => buttonStylePicker(color, variant), [variant, color])
  const [styles, buttonClassNames] = buttonStylePickerWrapper()

  return (
    <button
      data-cy={dataCy}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...rest}
      className={classnames(
        `flex items-center rounded-md px-4 py-2 transition-all disabled:hover:brightness-100
        disabled:!bg-gray-200 disabled:!text-gray-400 focus:outline-none focus:ring focus:border-blue-300`,
        (!className?.includes('btn-primary') || color !== 'sky-blue') && 'hover:brightness-110',
        !rest.disabled && 'filter',
        variant && color && buttonClassNames,
        className
      )}
      style={variant && color ? styles : undefined}
      disabled={rest.disabled}
      onClick={loading ? undefined : onClick}
    >
      <div
        className={classnames(
          'flex w-full justify-center items-center gap-x-2 whitespace-nowrap text-sm text-center',
          contentWrapperClassName
        )}
      >
        {loading ? <CoreLoadingIcon /> : children}
      </div>
    </button>
  )
}
export default Button
