import FormLogic from './FormLogic/FormLogic'
import GeneralSettings from './GeneralSettings/GeneralSettings'
import FormTagSection from './TagSection/FormTagSection'
import FormNavigationTabs from '../../FormNavigationTabs'

interface IFormSettings {}

const FormSettings: React.FC<IFormSettings> = () => (
  <div>
    <FormNavigationTabs />
    <div className="h-max flex flex-col gap-y-4">
      <GeneralSettings />
      <FormTagSection />
      <FormLogic />
    </div>
  </div>
)

export default FormSettings
