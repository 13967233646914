import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../../../../../types/Offering'
import SearchSelect from '../../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { InputSearchSelectItemType } from '../../../../../../../../types/shared/InputSearchSelectTypes'

type OfferSelectionFieldProps = {
  offers?: Offer[]
  selected?: string | null
  onSelectedOfferChange: (value: string) => void
}

const OfferSelectionField: FC<OfferSelectionFieldProps> = ({ offers, selected, onSelectedOfferChange }) => {
  const { t } = useTranslation()

  const onChange = (value: InputSearchSelectItemType) => {
    onSelectedOfferChange(value.value as string)
  }

  const selectOptions: InputSearchSelectItemType[] =
    offers?.map((offer, idx) => ({
      id: idx,
      value: offer.uuid || '',
      label: `${offer.supplier_branch?.name} - Offer ${offer.custom_id || offer.id}`,
    })) || []

  // add "Create new offer" option to the beginning of the list
  selectOptions.unshift({
    id: -1,
    value: 'new-offer',
    label: t('offer.create_new_offer', 'Create new offer'),
  })

  const currentSelected = selectOptions.find((option) => option.value === selected) || selectOptions[0]

  return (
    <div>
      <SearchSelect onChange={onChange} options={selectOptions} currentSelection={currentSelected} />
    </div>
  )
}

export default OfferSelectionField
