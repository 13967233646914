// TODO: Outdated, how to use the tailwind colors
module.exports = {
  'lhotse-orange': '#ee7d40',
  'sky-blue': '#2563EB',
  'sky-blue-light': '#4D86EB',
  'lhotse-gray': '#d1d5db',
  'vibrant-blue': '#0064ff',
  'primary-gray': '#353535',
  'secondary-gray': '#545454',
  'tertiary-gray': '#6f6f6f',
  'border-gray': '#e5e7eb',
  'button-gray': '#f2f2f2',
  'alert-dark': '#af0000',
  'alert-bright': '#e60000',
  background: '#F8F8FB',
  'nds-primary-gray': '#2e2e2e',
  'nds-secondary-gray': '#595959',
  'nds-tertiary-gray': '#8c8c8c',
  'nds-dark-green-accessible': '#385546',
  'nds-accessible-red': '#921919',
  'quaternary-grey': '#d9d9d9',
  black: 'black',
  white: 'white',
  '': '',
}
