import { Tab } from '@headlessui/react'
import TabButton, { TabType } from './TabButton'

type TabsBarProps = {
  tabs: TabType[]
}

const TabsBar = ({ tabs }: TabsBarProps) => (
  <div className="m-1.5">
    <Tab.Group>
      <Tab.List className="flex w-full">
        <div className="flex w-full justify-between">
          <div className="flex ">
            {tabs.map((tab) => (
              <TabButton key={tab.link} tab={tab} />
            ))}
          </div>
        </div>
      </Tab.List>
    </Tab.Group>
  </div>
)

export default TabsBar
