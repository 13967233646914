import { useEffect, useState } from 'react'

export const useReactiveState = <T>(value: T) => {
  const [state, setState] = useState<T>(value)

  useEffect(() => {
    setState(value)
  }, [value])

  return [state, setState] as const
}

export default useReactiveState
