import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import FormField from '../../../Forms-v2/FormField'
import { generateField } from '../../../Forms-v2/helpers'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { useWorkflowMutations } from '../../hooks/mutations'
import useOpenWorkflowBuilder from '../../hooks/useOpenWorkflowBuilder'
import { useValidation } from '../../../../core/hooks/useValidation'

type WorkflowDraft = {
  name: string
}

export const NewWorkflowModalBody = () => {
  const { t } = useTranslation()
  const [workflowDraft, setWorkflowDraft] = useState<WorkflowDraft>({ name: '' })
  const { modal } = useUtils()
  const { create, isMutating } = useWorkflowMutations()
  const openWorkflowBuilder = useOpenWorkflowBuilder()

  const { errors, validate } = useValidation(workflowDraft, { name: { required: true } })

  const handleUpdate = (updatedField: keyof WorkflowDraft, updatedValue: string | undefined) => {
    if (updatedValue === undefined) return

    setWorkflowDraft({ ...workflowDraft, [updatedField]: updatedValue })
  }

  const handleSubmit = async () => {
    if (!validate()) return
    const newWorkflow = await create(workflowDraft)
    modal.set({ isOpen: false })
    openWorkflowBuilder(newWorkflow)
  }

  return (
    <div className="flex flex-col gap-11">
      <FormField
        className="w-full"
        field={generateField({
          id: 'name',
          input: { type: 'TEXT', name: '', placeholder: t('generic.title_placeholder', 'Title here') },
          value: { value: workflowDraft.name },
          error: errors.name,
        })}
        updateField={(updatedField) => handleUpdate(updatedField.id, updatedField.value?.value as string)}
        readonly={false}
      />
      <div>
        <SaveCancelFormButtons
          isLoading={isMutating}
          onCancel={() => modal.set({ isOpen: false })}
          onSave={handleSubmit}
        />
      </div>
    </div>
  )
}

export default NewWorkflowModalBody
