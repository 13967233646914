import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserAvatar from '../../atoms/UserAvatar/UserAvatar'
import SupplierUserType from '../../../../types/Suppliers/SupplierUserType'
import useGetUserMenuItems from '../../../../modules/Suppliers/components/SupplierProfile/hooks/useGetUserMenuItems'
import SupplierUserForm from '../../../../modules/SupplierDetails/SupplierDetailsPage/SupplierUserForm/SupplierUserForm'
import Menu from '../menus/Menu'

interface IUserRow {
  user: SupplierUserType
  editable?: boolean
}

const UserRow: React.FC<IUserRow> = ({ user, editable }) => {
  const [editMode, setEditMode] = useState(false)
  const usersMenuItems = useGetUserMenuItems(setEditMode, user)
  const { t } = useTranslation()

  if (editMode) {
    return (
      <SupplierUserForm user={user} hideForm={() => setEditMode(false)} supplierBranchId={user.supplier_branch.id} />
    )
  }

  return (
    <div className="flex gap-x-4 items-center justify-between bg-whit rounded-lg">
      <div className="flex gap-x-4 items-center">
        <UserAvatar name="fb" size="40" textSizeRatio={2.5} />
        <div className="flex flex-col">
          <div className="flex items-center gap-x-2">
            <p data-cy="user-name">{user?.name}</p>
            {user.main && <p className="text-sm text-sky-blue font-medium">{t('supplier.main_contact')}</p>}
          </div>
          <div className="flex gap-x-2 text-sm text-primary-gray">
            <p>{user?.email}</p>
            {user?.phone_number && (
              <>
                <p>•</p>
                <p className="text-sm">{user?.phone_number}</p>
              </>
            )}
          </div>
        </div>
      </div>
      {editable && <Menu menuItems={usersMenuItems} buttonCy="user-row-menu" />}
    </div>
  )
}

export default UserRow
