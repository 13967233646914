import { Route, Switch } from 'react-router'
import AuthMiddleware from './middleware/AuthMiddleware'
import NonAuthMiddleware from './middleware/NonAuthMiddleware'

import PageNotFound from '../components/ErrorPages/PageNotFound/PageNotFound'
import CoreMainLayout from '../components/layout/CoreMainLayout'
import publicRoutes from './publicRoutes'
import privateRoutes from './privateRoutes'
import SharedModal from '../../shared/components/organisms/modals/Modal/Modal'

const Routes = () => (
  <Switch>
    {publicRoutes.map((r) => (
      <NonAuthMiddleware path={r.path} component={r.component} key={r.path} />
    ))}
    {privateRoutes.map((r) => (
      <Route path={r.path} key={r.path} exact={!!r.exact}>
        <AuthMiddleware>
          <CoreMainLayout>{r.component}</CoreMainLayout>
        </AuthMiddleware>
        <SharedModal />
      </Route>
    ))}
    <Route path="*">
      <PageNotFound />
    </Route>
  </Switch>
)

export default Routes
