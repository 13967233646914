import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../../../../../shared/components/atoms/ErrorMessage'
import InputTextGray from '../../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'

interface ICompanyFormsNameInput {
  companyForm: any
  formTouched: boolean
  handleChange: (v: string) => void
}

const CompanyFormsNameInput: React.FC<ICompanyFormsNameInput> = ({ formTouched, companyForm, handleChange }) => {
  const { t } = useTranslation()
  const isNameError = !companyForm?.name

  useEffect(() => {
    document.getElementById('form-name-input')?.focus()
  }, [])

  return (
    <div className="w-full flex flex-col gap-y-2">
      <p className="text-xs text-secondary-gray">{t('account.form_name_placeholder')}</p>
      <InputTextGray
        placeholder="Enter a name for the form here..."
        onChange={(e) => handleChange(e.target.value)}
        value={companyForm?.name || ''}
        className="border-b border-gray-200"
        fullWidth
        dataCy="form-name-input"
        id="form-name-input"
      />
      {formTouched && isNameError && <ErrorMessage error="generic.required" />}
    </div>
  )
}

export default CompanyFormsNameInput
