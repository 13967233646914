import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { HiDocumentText } from 'react-icons/hi2'
import Table from '../../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../shared/components/atoms/SharedTable/TableHeader'
import LhotseHomeTableRow from './LhotseHomeTableRow/LhotseHomeTableRow'
import { Pagination } from '../../../../../shared/components/atoms/SharedTable/Pagination'
import useUrlParams from '../../../hooks/useUrlParams'
import useSearchSubmissions from '../../../../Search/Hooks/Submissions/useSearchSubmissions'
import { HitsType } from '../../../../Search/SearchTypes'
import { RequestSearchType } from '../../../../Search/Types/SearchEntitiesTypes'

const LhotseHomeTable = () => {
  const { t } = useTranslation()
  const { setParam, getParam } = useUrlParams()

  const { data, isLoading } = useSearchSubmissions({
    query: getParam('query') ?? '',
    filter_by: getParam('filter_by'),
  })
  const [totalPages, setTotalPages] = useState(0)
  const query = getParam('query')
  const changePageHandler = (selected: number) => setParam({ page: selected.toString() })

  useEffect(() => {
    if (data && data.last_page > 0 && data.last_page !== totalPages) {
      setTotalPages(data.last_page)
    }
    if (query) {
      changePageHandler(1)
    }
  }, [data?.last_page, query])
  const getCurrentPage = (): number => Number(getParam('page') && getParam('page') !== '' ? getParam('page') : 1) - 1
  const hasResults = data?.data.hits.length !== 0

  return (
    <>
      <TableContainer>
        {hasResults && (
          <TableHead>
            <TableHeader>{t('generic.name', 'Name')}</TableHeader>
            <TableHeader>{t('generic.form-name', 'Form')}</TableHeader>
            <TableHeader>{t('generic.requestor', 'Requestor')}</TableHeader>
            <TableHeader>{t('generic.created-at', 'Created at')}</TableHeader>
            <TableHeader>{t('generic.status', 'Status')}</TableHeader>
            <TableHeader />
          </TableHead>
        )}

        <Table isLoading={isLoading}>
          {data?.data.hits.map((request: HitsType<RequestSearchType>) => (
            <LhotseHomeTableRow key={request.document.intake_uuid} request={request.document} />
          ))}
          {!hasResults && (
            <tr>
              <td className="w-full text-center py-12 px-4 space-y-1" colSpan={6}>
                <HiDocumentText className="w-12 h-12 mx-auto text-gray-200 mb-2" />
                <div className="text-sm font-semibold text-gray-600">
                  {t('home.no_requests_found', 'No requests found')}
                </div>
                <div className="text-sm font-medium text-gray-400">
                  {t(
                    'home.no_requests_match_filter',
                    'No requests match the current filter. Remove the filter or try another one.'
                  )}
                </div>
              </td>
            </tr>
          )}
        </Table>
      </TableContainer>
      {hasResults && (
        <div className="mt-4 w-full xl:w-1/2 self-center flex justify-center">
          <Pagination
            forcePage={getCurrentPage()}
            pageCount={data?.last_page ?? 0}
            onPageChange={({ selected }) => changePageHandler(selected + 1)}
          />
        </div>
      )}
    </>
  )
}

export default LhotseHomeTable
