import { StarIcon, TrashIcon } from '@heroicons/react/24/outline'
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../../../../core/providers/UtilsProvider'
import TableCell from '../../../../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../../../../../shared/components/atoms/SharedTable/TableRow'
import Button from '../../../../../../../../../shared/components/atoms/buttons/Button'
import { priceToInternationalPriceString } from '../../../../../../../../../shared/constants/currencyFormat'
import { OfferItem } from '../../../../../../../../../types/Offering'
import useDeleteOfferItem from '../../../../../../../../Forms/hooks/useDeleteOfferItem'
import useUpdateOfferItem from '../../../../../../../../Forms/hooks/useUpdateOfferItem'
import OfferModal from '../OfferModal/OfferModal'

type IProductSectionOffersTableRow = {
  offerItem: OfferItem
}

const OffersTableRow: React.FC<IProductSectionOffersTableRow> = ({ offerItem }) => {
  const { modal } = useUtils()
  const { mutate: deleteOfferItem } = useDeleteOfferItem()
  const { mutateAsync: updateOfferItem } = useUpdateOfferItem()
  const { t } = useTranslation()

  const handleEditOffer = () => {
    modal.set({
      title: 'Edit Offer',
      isOpen: true,
      keepOpenOnBackdrop: true,
      content: <OfferModal offer={offerItem} updateOffer={updateOfferItem} mode="edit" />,
    })
  }

  return (
    <TableRow key={offerItem.uuid} onClick={() => handleEditOffer()}>
      <TableCell>{offerItem.offer?.custom_id}</TableCell>
      <TableCell>{offerItem.offer?.supplier_branch?.name}</TableCell>
      <TableCell>{priceToInternationalPriceString(offerItem.total_net_price, offerItem.currency_code)}</TableCell>

      <TableCell className="text-right">
        <div className="flex justify-end ">
          {offerItem.is_indicative && (
            <div className="px-3 text-xs text-white bg-sky-blue-light rounded-full flex items-center">Indicative</div>
          )}

          <Button
            className="btn-ghost"
            title={t('generic.preferred-offer', 'Preferred Offer')}
            onClick={async (e) => {
              e.stopPropagation()
              updateOfferItem({ ...offerItem, is_selected: !offerItem.is_selected })
            }}
          >
            {offerItem.is_selected ? <SolidStarIcon className="w-4 text-sun-yellow " /> : <StarIcon className="w-4" />}
          </Button>

          <Button
            className="btn-ghost"
            onClick={async (e) => {
              e.stopPropagation()
              deleteOfferItem(offerItem)
            }}
          >
            <TrashIcon className="w-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default OffersTableRow
