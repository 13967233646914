import { useTranslation } from 'react-i18next'
import { MdAddCircleOutline } from 'react-icons/md'
import SearchSelect, { ISearchSelect } from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import Button from '../../../shared/components/atoms/buttons/Button'
import useGetAddressData from '../../../shared/hooks/useGetAddressData'
import { AddressQuestionValue, FormQuestionValue } from '../../../types/Forms'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import useAddAddressModal from '../../Settings/Views/General/addresses/useAddAddressModal'
import { getOptionFromAddress, getValueFromAddress } from '../../Forms/formUtils'

type AddressQuestionInputProps = {
  answer: FormQuestionValue
  onUpdate: (updated: FormQuestionValue) => void
} & Omit<ISearchSelect, 'onChange' | 'options' | 'currentSelection'>

export const AddressField: React.FC<AddressQuestionInputProps> = ({ answer, onUpdate, ...searchSelectProps }) => {
  const { data: addressData } = useGetAddressData()
  const { t } = useTranslation()

  const handleNewAddressCreation = useAddAddressModal({
    onSuccess: (data) => {
      const { id, name } = data[data.length - 1]
      if (id && name) {
        onUpdate({ ...answer, value: { id, name } })
      }
    },
  })

  const handleOnChange = ({ id }: InputSearchSelectItemType) => {
    const selectedAddress = addressData?.find((address) => address.id === id)
    if (!selectedAddress) return

    onUpdate({ ...answer, value: getValueFromAddress(selectedAddress) })
  }

  const currentAddress = addressData?.find(({ id }) => id === (answer.value as Partial<AddressQuestionValue>)?.id)

  return (
    <SearchSelect
      wrapperClassName="w-full"
      onChange={handleOnChange}
      options={addressData?.map(getOptionFromAddress) || []}
      currentSelection={currentAddress && getOptionFromAddress(currentAddress)}
      placeholder={t('generic.address_input_placeholder')}
      nullable
      onClear={() => onUpdate({ ...answer, value: '' })}
      {...searchSelectProps}
    >
      <Button onClick={handleNewAddressCreation} className="no-focus-button">
        <MdAddCircleOutline className="text-secondary-gray w-[14px]" size={16} />
        <span className="text-secondary-gray">{t('company.add_address')}</span>
      </Button>
    </SearchSelect>
  )
}

export default AddressField
