import { useUtils } from '../../../../../core/providers/UtilsProvider'
import ExternalDataModalHeader from '../ExternalDataModalHeader'
import ExternalDataModalBody, { ExternalDataDetails } from '../ExternalDataModalBody'
import { ExternalDataListEntity } from '../../../../../types/ExternalDataManager/ExternalDataManagerTypes'
import useExternalDataSourceMutations from '../../../hooks/useExternalDataSourceMutations'

export const useExternalDataEditModal = (externalData: ExternalDataListEntity) => {
  const { modal } = useUtils()

  const { update } = useExternalDataSourceMutations()

  const handleSubmit = (updatedExternalData: ExternalDataDetails) =>
    update({ uuid: externalData.uuid, name: updatedExternalData.name })

  return () => {
    modal.set({
      isOpen: true,
      title: <ExternalDataModalHeader />,
      content: (
        <ExternalDataModalBody
          initialData={{ name: externalData.name, file: null }}
          uploadEnabled={false}
          onSubmit={handleSubmit}
        />
      ),
      className: 'max-w-[500px]',
    })
  }
}

export default useExternalDataEditModal
