import { FC } from 'react'

export interface IBaseInputWrapper {
  label?: string
  children: ReactI18NextChild | Iterable<ReactI18NextChild>
}

const BaseInputWrapper: FC<IBaseInputWrapper> = ({ label, children }) => (
  <div className="flex flex-col space-y-2 w-full">
    {label && <label className="text-md font-semibold">{label}</label>}
    {children}
  </div>
)

export default BaseInputWrapper
