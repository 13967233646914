import { FormSettingsType } from '../../../../../types/Forms/formTypes'
import useGetFormData from '../../../hooks/useGetFormData'
import useUpdateForm from '../../hooks/useUpdateForm'

export default () => {
  const { updateForm } = useUpdateForm()
  const { data } = useGetFormData()

  return (key: keyof FormSettingsType, value: any) => {
    if (!data) return

    updateForm({
      template: {
        __settings: {
          [key]: value,
        },
      },
    })
  }
}
