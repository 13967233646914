import dayjs from 'dayjs'
import { dateTimeFormatChat } from '../../../../../../shared/constants/dateFormat'
import { MessageType } from '../../../../../../types/Messenger/types'

interface ITimestamp {
  message: MessageType
}

const Timestamp: React.FC<ITimestamp> = ({ message }) => (
  <div className="text-xs text-gray-500 font-light">{`${dayjs(message.created_at).format('HH:mm')}`}</div>
)

export default Timestamp
