import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md'
import NewTagInput from './NewTagInput'
import TagBadge from './TagBadge'
import useGetFormData from '../../../../hooks/useGetFormData'
import { FormTag } from '../../../../../../types/Forms'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import useGetFormTags from './hooks/useGetFormTags'
import useFormTagsMutations from './hooks/useFormTagsMutations'

const TagsContainer = () => {
  const { data } = useGetFormData()
  const [showInput, setShowInput] = useState(false)
  const { t } = useTranslation()
  const { data: tags, isLoading } = useGetFormTags()
  const { detach } = useFormTagsMutations()

  if (isLoading) return null

  const handleNewTagClick = () => {
    setShowInput((prevState) => !prevState)
  }

  const handleRemove = (tag: FormTag, index: number) => {
    detach.mutate([tag.name])
  }

  const handleRemoveAll = () => {
    detach.mutate(tags?.map((tag) => tag.name) || [])
  }

  return (
    <div className="flex flex-col flex-wrap justify-between gap-2.5">
      <div className="flex flex-wrap gap-1.5">
        {tags?.map((tag, index) => (
          <TagBadge onRemove={() => handleRemove(tag, index)} key={`form-tag-${tag.id}`} tag={tag} />
        ))}
      </div>

      <div className="flex justify-between">
        <div>
          {!showInput && (
            <div className="flex flex-row items-center">
              <MdAddCircleOutline />
              <Button className="px-1" onClick={handleNewTagClick}>
                {t('forms.tag_section.add_tag')}
              </Button>
            </div>
          )}
          {showInput && <NewTagInput onComplete={() => setShowInput(false)} onCancel={() => setShowInput(false)} />}
        </div>
        <div>
          {tags && tags.length > 0 && (
            <Button onClick={handleRemoveAll}>
              <MdRemoveCircleOutline />
              {t('forms.tag_section.remove_all')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
export default TagsContainer
