import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'

type ExternalDataTableHeaderProps = {
  columns: string[]
}

const ExternalDataTableHeader = ({ columns }: ExternalDataTableHeaderProps) => (
  <TableHead>
    {columns.map((column) => (
      <TableHeader key={`column-${column}`}>{column}</TableHeader>
    ))}
  </TableHead>
)

export default ExternalDataTableHeader
