import { useQuery } from 'react-query'
import companyQueryStrings from '../../../constants/companyQueryStrings'
import { apiGetPaginatedCompanyUsers } from '../../../services/CompanyService'

export const useGetPaginatedUsers = (page: number) =>
  useQuery([companyQueryStrings.company_users, 'paginated', page], () => apiGetPaginatedCompanyUsers(page), {
    // keepPreviousData: true,
  })

export default useGetPaginatedUsers
