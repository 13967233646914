import { useTranslation } from 'react-i18next'
import PriceInput from '../../../shared/components/organisms/PriceInput/PriceInput'
import { FormQuestionValue } from '../../../types/Forms'
import { useFormField } from '../providers/FormFieldProvider'

export const FormFieldPrice = () => {
  const { t } = useTranslation()
  const { field, updateValue, setValues } = useFormField()

  return (
    <div className="flex flex-col gap-4 text-base font-normal">
      <div key={`intake-field-${field.id}`} className="">
        <PriceInput
          defaultValue={(field.value?.value || '0') as string}
          disabled={field.disabled}
          currentCurrency={field.value?.currencyId}
          placeholder={t('generic.type_your_response')}
          onCurrencyChange={(selection) =>
            setValues({ ...field.value, currencyId: selection?.id as string } as FormQuestionValue)
          }
          onChange={(updatedValue: number) => updateValue(updatedValue)}
        />
      </div>
    </div>
  )
}

export default FormFieldPrice
