import React, { useCallback } from 'react'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { WorkflowBlock } from '../../../types/Workflow/WorkflowBlock'
import { getMergedDeepOverwritingArrays } from '../../../shared/utils/objectHelpers'
import { useWorkflowStructureMutations } from '../hooks/mutations'
import { useWorkflow } from './WorkflowProvider'
import useDraftState from '../../../core/useDraftState'
import blockHasApprovers from '../helpers'
import { useValidation } from '../../../core/hooks/useValidation'

export type WorkflowBlockDraftErrors = Partial<Record<keyof WorkflowBlock, string | undefined>>

type WorkflowBlockDraftContextType = {
  block: WorkflowBlock
  errors: WorkflowBlockDraftErrors
  isDirty: boolean
  updateBlock: (block: Partial<WorkflowBlock> | ((prevBlock: WorkflowBlock) => Partial<WorkflowBlock>)) => void
  reset: () => void
  save: () => boolean
}

type WorkflowBlockDraftProviderProps = {
  block: WorkflowBlock
}

const WorkflowBlockDraftContext = React.createContext({} as WorkflowBlockDraftContextType)

export const WorkflowBlockDraftProvider = ({
  block,
  children,
}: React.PropsWithChildren<WorkflowBlockDraftProviderProps>) => {
  const { t } = useTranslation()
  const { workflow, selectedStep } = useWorkflow()
  const [draftBlock, setDraftBlock] = useDraftState(block)
  const { updateBlock: sendBlockUpdate, addStep, addBlock } = useWorkflowStructureMutations(workflow)

  const {
    errors,
    validate,
    reset: clearErrors,
  } = useValidation(draftBlock, {
    name: { required: true },
    approvers: (block) => {
      if (blockHasApprovers(block) && block.approvers.length < 1) {
        return t('general.required', 'Required')
      }

      return undefined
    },
  })

  const updateBlock = useCallback(
    (blockOrUpdater: Partial<WorkflowBlock> | ((prevBlock: WorkflowBlock) => Partial<WorkflowBlock>)) => {
      clearErrors()

      setDraftBlock((prevBlock) => {
        if (typeof blockOrUpdater === 'function') {
          return getMergedDeepOverwritingArrays<WorkflowBlock>(prevBlock, blockOrUpdater(prevBlock))
        }
        return getMergedDeepOverwritingArrays<WorkflowBlock>(prevBlock, blockOrUpdater)
      })
    },
    [setDraftBlock]
  )

  const blockDraftState = React.useMemo<WorkflowBlockDraftContextType>(
    () => ({
      block: draftBlock,
      errors,
      isDirty: !isEqual(draftBlock, block),
      updateBlock,
      reset: () => setDraftBlock(block),
      save: () => {
        if (!validate()) return false

        if (!selectedStep) return false

        if (!selectedStep.uuid) {
          addStep({ ...selectedStep, blocks: [draftBlock] })
          return true
        }

        if (!draftBlock.uuid) {
          addBlock({ stepUuid: selectedStep.uuid, data: draftBlock })
          return true
        }

        sendBlockUpdate(draftBlock)
        return true
      },
    }),
    [draftBlock, setDraftBlock, updateBlock, sendBlockUpdate, addStep, selectedStep, block, errors]
  )

  return <WorkflowBlockDraftContext.Provider value={blockDraftState}>{children}</WorkflowBlockDraftContext.Provider>
}

export default WorkflowBlockDraftProvider

export const useWorkflowBlockDraft = () => React.useContext(WorkflowBlockDraftContext)
