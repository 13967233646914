import { ApiSearchType } from '../SearchTypes'
import useDebounceSearchRequest from './useDebounceSearchRequest'

export default function useSearch<T>({ entity, queryFilters, appendFilters }: ApiSearchType) {
  if (appendFilters) {
    // eslint-disable-next-line no-param-reassign
    queryFilters.filter_by = `${queryFilters.filter_by} && ${appendFilters.filter_by}`
  }
  return useDebounceSearchRequest<T>({ key: [entity, JSON.stringify(queryFilters)], query: { entity, queryFilters } })
}
