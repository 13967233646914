import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useSelectableState from '../../../../core/hooks/useSelectableState'
import { getUpdatedByProperty } from '../../../../shared/utils/arrayHelpers'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'
import { IntakeFieldType, IntakeType } from '../../../../types/Intake'
import { ActiveWorkflow } from '../../../../types/Workflow/ActiveWorkflow'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'
import useGetCurrentIntake from '../hooks/useGetCurrentIntake'
import useIntakeSidebar from '../hooks/useIntakeSidebar'
import useGetWorkflow from '../hooks/useGetWorkflow'
import useIntakeFormStore from '../../IntakeFormResponse/stores/useIntakeFormStore'
import useToggleableState from '../../../../core/hooks/useToggleableState'

type IntakeFlowContext = {
  activeWorkflow: ActiveWorkflow | undefined
  workflow: ActiveWorkflow | undefined
  intake: IntakeType | undefined
  updateField: (updatedField: IntakeFieldType) => void
  updateActiveWorkflowBlock: (updatedBlock: WorkflowBlock) => void
  editModeOn: boolean
  toggleEditMode: (on?: boolean) => void
  inspectBlock: (block: WorkflowBlock) => void
  toggleBlockInspection: (block: WorkflowBlock) => void
  selectedBlock: WorkflowBlock | null
}

const intakeFlowContext = createContext({} as IntakeFlowContext)

type IntakeFlowProviderProps = React.PropsWithChildren<{
  initiallyEditable?: boolean
}>

const IntakeFlowProvider: React.FC<IntakeFlowProviderProps> = ({ children }) => {
  const [editModeOn, toggleEditMode] = useToggleableState()
  const [selectedBlock, select, deselect] = useSelectableState<WorkflowBlock>(null)
  const { data: intakeData } = useGetCurrentIntake()
  const [intake, setIntake] = useState<IntakeType | undefined>(intakeData)
  const [refetchInterval, setRefetchInterval] = useState<number>(0)
  const { data: activeWorkflow } = useGetWorkflow(intakeData?.active_workflow_uuid, refetchInterval)
  const [activeWorkflowState, setActiveWorkflowState] = useState<ActiveWorkflow | undefined>(activeWorkflow)
  const { minified, setMinifiedState } = useIntakeSidebar()

  const setFormDirty = useIntakeFormStore((state) => state.setFormDirty)

  useEffect(() => {
    setIntake(intakeData)
  }, [intakeData])

  useEffect(() => {
    setActiveWorkflowState(activeWorkflow)
  }, [activeWorkflow])

  useEffect(() => {
    if (minified) deselect()
  }, [minified])

  const shouldRefetch = (): boolean => {
    if (!activeWorkflow || ['completed', 'declined', 'declined_by_requester'].includes(activeWorkflow.status)) {
      return false
    }

    if (activeWorkflow.active_blocks.length === 0 && activeWorkflow?.status === 'ongoing') {
      return true
    }

    const pendingBlock = activeWorkflow.active_blocks.filter(
      (workflowBlock: WorkflowBlock) => workflowBlock.status !== 'pending'
    )

    return pendingBlock.length > 0
  }

  useEffect(() => {
    setRefetchInterval(0)
    if (shouldRefetch()) {
      setRefetchInterval(5000)
    }
  }, [activeWorkflow])

  const updateField = useCallback(
    (updatedField: IntakeFieldType) => {
      if (!intake) return

      const fieldSection = intake.sections.find((section) => section.fields.find(({ id }) => id === updatedField.id))

      if (!fieldSection) return

      const updatedIntake = getMergedDeepOverwritingArrays(intake, {
        sections: getUpdatedByProperty('id', intake.sections, [
          {
            id: fieldSection.id,
            fields: getUpdatedByProperty('id', fieldSection.fields, [updatedField]),
          },
        ]),
      })

      setIntake(updatedIntake)
      setFormDirty(true)
    },
    [intake]
  )

  const updateActiveWorkflowBlock = useCallback(
    (updatedBlock: WorkflowBlock) => {
      if (!activeWorkflowState) return

      const stepToUpdate = activeWorkflowState.steps.find((step) =>
        step.blocks.find(({ uuid }) => uuid === updatedBlock.uuid)
      )

      if (!stepToUpdate) return

      const updatedActiveWorkflow = getMergedDeepOverwritingArrays(activeWorkflowState, {
        steps: getUpdatedByProperty('uuid', activeWorkflowState.steps, [
          {
            uuid: stepToUpdate.uuid,
            blocks: getUpdatedByProperty('uuid', stepToUpdate.blocks, [updatedBlock]),
          },
        ]),
      })

      // also set activeWorkflowState.required_action_workflow_blocks block with the same ID to updatedBlock
      // updatedActiveWorkflow.required_action_workflow_blocks = updatedActiveWorkflow.required_action_workflow_blocks
      //   ?.map((block) => {
      //     if (block.uuid === updatedBlock.uuid) {
      //       return updatedBlock
      //     }
      //
      //     return block
      //   })

      setActiveWorkflowState(updatedActiveWorkflow)
    },
    [activeWorkflowState]
  )

  const inspectBlock = useCallback(
    (block: WorkflowBlock) => {
      select(block)
      setMinifiedState(false)
    },
    [select, setMinifiedState]
  )

  const contextState = useMemo<IntakeFlowContext>(
    () => ({
      activeWorkflow: activeWorkflowState,
      workflow: activeWorkflow,
      intake,
      selectedBlock,
      inspectBlock,
      toggleBlockInspection: (block: WorkflowBlock) => {
        if (selectedBlock?.uuid === block.uuid) {
          deselect()
        } else {
          inspectBlock(block)
        }
      },
      editModeOn,
      toggleEditMode,
      updateField,
      updateActiveWorkflowBlock,
    }),
    [activeWorkflowState, editModeOn, intake, selectedBlock]
  )

  return <intakeFlowContext.Provider value={contextState}>{children}</intakeFlowContext.Provider>
}

export default IntakeFlowProvider

export const useIntakeFlow = () => useContext(intakeFlowContext)
