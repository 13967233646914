import IntakeFormResponsePage from './IntakeFormResponse/IntakeFormResponsePage'
import IntakeFlowOverviewPage from './IntakeOverview/IntakeFlowOverviewPage'
import useGetCurrentIntake from './IntakeOverview/hooks/useGetCurrentIntake'

const Intake = () => {
  const { data: intakeData } = useGetCurrentIntake()
  if (intakeData?.status === 'draft') {
    return <IntakeFormResponsePage />
  }

  return <IntakeFlowOverviewPage />
}

export default Intake
