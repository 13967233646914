import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import Button from '../../../shared/components/atoms/buttons/Button'
import Titlebar from '../../../shared/components/layout/Titlebar'
import useTabs from '../../../shared/hooks/useTabs'
import TabbedNav from '../../../shared/components/layout/TabbedNav/TabbedNav'
import useGetExternalDataEntity from '../hooks/useGetExternalDataEntity'
import BackButton from '../../../shared/components/atoms/navigation/BackButton'
import ExternalDataTable from '../components/ExternalDataTable/ExternalDataTable'
import ExternalDataEntitySettingsPage from './ExternalDataEntitySettingsPage'
import useGenerateEmbeddings from '../hooks/useGenerateEmbeddings'

export const ExternalDataEntityPage = () => {
  const { t } = useTranslation()
  const { data: entity, isLoading } = useGetExternalDataEntity()
  const generateEmbeddings = useGenerateEmbeddings(entity?.uuid!!)
  const history = useHistory()

  const handleGenerateEmbeddingsClick = async () => {
    if (!entity?.uuid || entity.columns_for_embeddings_count === 0) return
    await generateEmbeddings.mutateAsync()
  }

  const tabsData = useMemo(
    () => [
      {
        title: t('external_data.data', 'Data'),
        current: true,
        TabComponent: ExternalDataTable,
      },
      {
        title: t('external_data.settings', 'Settings'),
        current: false,
        TabComponent: ExternalDataEntitySettingsPage,
      },
    ],
    []
  )

  const {
    navItems,
    changeTab,
    current: { TabComponent },
  } = useTabs(tabsData)

  if (isLoading) return null

  if (!entity) {
    return <div className="flex w-full flex-col h-full">{t('external_data.invalid_uuid', 'No such data exist')}</div>
  }

  return (
    <div className="w-full">
      <Titlebar
        title={
          <div className="px-14 py-4 space-y-2">
            <BackButton
              onBack={() => history.push('/settings/data-manager')}
              name={t('generic.back_to_settings', 'Back to settings')}
            />
            <h1 className=" text-xl font-bold uppercase">{entity.name}</h1>
          </div>
        }
      >
        <div className="flex gap-x-2 px-14">
          <Button
            variant="solid"
            disabled={entity.columns_for_embeddings_count === 0}
            color="sky-blue"
            onClick={handleGenerateEmbeddingsClick}
          >
            {t('external_data.generate_embeddings', 'Generate embeddings')}
          </Button>
        </div>
      </Titlebar>
      <div className="px-14">
        <TabbedNav className="!justify-start" items={navItems} onTabChange={changeTab} />
      </div>
      <div className="flex flex-col space-x-9 h-full py-12">
        <div className="flex flex-row gap-4">
          <div className="px-12 min-w-full">{TabComponent && <TabComponent />}</div>
        </div>
      </div>
    </div>
  )
}

export default ExternalDataEntityPage
