import type { PartialDeep } from 'type-fest'
import { apiDelete, apiGet, apiPost, apiPostData, apiPut } from '../../../../core/utils/api/generic'
import { IntakeChangeLogType, IntakeType } from '../../../../types/Intake'
import { PaginatedResponse } from '../../../../types/shared/PaginatedResponse'
import { Offer, Offering } from '../../../../types/Offering'

export const getIntakes = () =>
  apiGet<{ data: IntakeType[] }>('/intake-flows').then(({ data: intake_flow }) => intake_flow)

export const getIntake = (intakeId: string) =>
  apiGet<{ data: IntakeType }>(`/intake-flows/${intakeId}`).then(({ data: intake_flow }) => intake_flow)

export const getIntakeOffers = (intakeId: string) =>
  apiGet<{ data: Offer[] }>(`/intake-flows/${intakeId}/offers`).then(({ data: offers }) => offers)

export const getIntakeChangeLog = (intakeId: string) =>
  apiGet<{ data: IntakeChangeLogType }>(`/intake-flows/${intakeId}/logs`).then(
    ({ data: intake_change_log }) => intake_change_log
  )

export const putIntake = (intakeId: string, data: IntakeType) =>
  apiPut(`/intake-flows/${intakeId}`, data).then(({ data: intake }) => intake)

export const deleteIntake = (intakeId: string) => apiDelete(`/intake-flows/${intakeId}`)

export const duplicateIntake = (intakeUuid: string) => apiPostData<IntakeType>(`/intake-flows/${intakeUuid}/duplicate`)

export const putIntakeSubmitDraft = (intakeId: string) => apiPut(`/intake-flows/${intakeId}/submit`)

export const postIntake = (data: PartialDeep<IntakeType>) => apiPost('/intake-flows', data)

export const getPaginatedIntakeOfferings = (intakeUuid: string, page = 1) =>
  apiGet<PaginatedResponse<Offering>>(`/intake-flows/${intakeUuid}/offerings?page=${page}`)

export const postOffering = (intakeUuid: string, data: Partial<Omit<Offering, 'uuid'>>) =>
  apiPost(`/intake-flows/${intakeUuid}/offerings`, data)

export const putOffering = (intakeUuid: string, { uuid, ...data }: Partial<Offering> & Pick<Offering, 'uuid'>) =>
  apiPut(`/intake-flows/${intakeUuid}/offerings/${uuid}`, data)

export const deleteOffering = (intakeUuid: string, offeringUuid: string) =>
  apiDelete(`/intake-flows/${intakeUuid}/offerings/${offeringUuid}`)
