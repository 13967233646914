import { useTranslation } from 'react-i18next'
import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'

const ExternalDataSourcesTableHeader: React.FC = () => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableHeader>{t('generic.name', 'Name')}</TableHeader>
      <TableHeader>{t('generic.created_at', 'Created')}</TableHeader>
      <TableHeader>{t('generic.updated_at', 'Updated')}</TableHeader>
      <TableHeader>{t('generic.action', 'Action')}</TableHeader>
    </TableHead>
  )
}

export default ExternalDataSourcesTableHeader
