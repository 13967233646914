import { useTranslation } from 'react-i18next'
import { MdAddCircleOutline } from 'react-icons/md'
import { useEffect, useState } from 'react'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import InputTextGray from '../../../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import useFormTagsMutations from './hooks/useFormTagsMutations'

type TagsContainerControlsProps = {
  onComplete: () => void
  onCancel: () => void
}

const NewTagInput = ({ onComplete, onCancel }: TagsContainerControlsProps) => {
  const { t } = useTranslation()

  const [newTag, setNewTag] = useState('')
  const [showError, setShowError] = useState(false)
  const { attach } = useFormTagsMutations()

  const handleAdd = () => {
    if (newTag.length < 1) {
      setShowError(true)
      return
    }

    attach.mutate([newTag])
    setNewTag('')
    onComplete()
  }

  useEffect(() => {
    if (newTag.length > 0) {
      setShowError(false)
    }
  }, [newTag.length])

  return (
    <div className="flex flex-row">
      <div className="flex">
        <InputTextGray
          error={showError && t('generic.required')}
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          placeholder={t('forms.tag_section.add_tag_input')}
        />
        <Button className="m-1 text-ice-blue bg-vibrant-blue" onClick={handleAdd}>
          <MdAddCircleOutline />
        </Button>
        <Button onClick={onCancel}>{t('generic.cancel')}</Button>
      </div>
    </div>
  )
}
export default NewTagInput
