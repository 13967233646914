import { useTranslation } from 'react-i18next'
import { FormSectionType, FormQuestionType } from '../../../../../../../../types/Forms'
import FormLogicTargetSelector from './FormLogicTargetSelector'
import useQuestionLogicSwitchMutations from '../../hooks/useQuestionLogicSwitchMutations'

interface IFormLogicGoToRow {
  question: FormQuestionType
  section: FormSectionType
}

const FormLogicGoToDefaultTargetSelector: React.FC<IFormLogicGoToRow> = ({ question, section }) => {
  const { t } = useTranslation()
  const { updateDefault } = useQuestionLogicSwitchMutations(question, section)

  return (
    <div className="flex flex-col gap-y-4">
      <p className="font-medium uppercase">{t('forms.otherwise')}</p>
      <div className="max-w-xl">
        <FormLogicTargetSelector
          onChange={({ id }) => updateDefault(id as string)}
          selected={question.__logic?.switch?.default}
          question={question}
          section={section}
        />
      </div>
    </div>
  )
}

export default FormLogicGoToDefaultTargetSelector
