import useIntakeId from './useIntakeId'
import useGetIntake from './useGetIntake'

export const useGetCurrentIntake = () => {
  const intakeId = useIntakeId()

  return useGetIntake(intakeId)
}

export default useGetCurrentIntake
