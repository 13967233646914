import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'
import type { PartialDeep } from 'type-fest'
import CoreLoadingIcon from '../../../core/components/CoreLoadingIcon'
import { apiPostSubmission } from './constants/formService'
import useGetFormData from '../hooks/useGetFormData'
import { FormTemplateType } from '../../../types/Forms'

const FormCreatePage: React.FC = () => {
  const history = useHistory()
  const { formId } = useParams<{ formId: string }>()
  const { isLoading: isLoadingFormData, data: formData, error } = useGetFormData()

  const { mutate: saveDraft } = useMutation(
    'createFormSubmission',
    (data: PartialDeep<FormTemplateType>) => apiPostSubmission(formId, data),
    {
      onSuccess: (data: { uuid: string }) => {
        if (data.uuid) {
          history.push(`/forms/${formId}/submission/${data.uuid}`)
        }
      },
    }
  )

  useEffect(() => {
    if (formData && !isLoadingFormData) {
      saveDraft(formData.template)
    }
  }, [isLoadingFormData])

  if (error) {
    history.push('/')
  }

  return <CoreLoadingIcon />
}

export default FormCreatePage
