import { useEffect, useState } from 'react'
import useDashboardUrls from './services/useMetabaseDashboardService'
import CoreLoadingIcon from '../../core/components/CoreLoadingIcon'

const AnalyticsPage = () => {
  const { data: dashboard_configs } = useDashboardUrls()
  const metabaseConfigs = dashboard_configs?.dashboard_urls

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (metabaseConfigs?.length === 0) setIsLoading(false)
  }, [metabaseConfigs?.length])

  return (
    <div className="w-full h-full">
      {isLoading && <CoreLoadingIcon />}
      {metabaseConfigs &&
        metabaseConfigs?.map((config) => (
          <iframe
            key={config.dashboard_id}
            src={config.url}
            title={`Metabase Dashboard ${config.dashboard_id}`}
            width="100%"
            height="100%"
            allowFullScreen
            onLoad={() => setIsLoading(false)}
          />
        ))}
    </div>
  )
}

export default AnalyticsPage
