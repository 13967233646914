import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FocusEvent, MouseEvent } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import useReactiveState from '../../../../core/hooks/useReactiveState'
import InputTextGray from '../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import WorkflowActionButton from './WorkflowActionButton'
import WorkflowRemoveButton from './WorkflowRemoveButton'
import InputError from '../../../Forms/FormBuilder/QuestionTypes/atoms/InputError'
import { inputErrorClassNames } from '../../../Forms/FormBuilder/QuestionTypes/atoms/helpers'

type EditableTextProps = {
  value: string
  onSubmit?: (newValue: string) => void | Promise<void>
  onChange?: (updatedValue: string) => void
  onBlur?: (newValue: string) => void
  placeholder?: string
  isProcessing?: boolean
  disabled?: boolean
  editable?: boolean
  hideControls?: boolean
  displayTextOnBlur?: boolean
  error?: string
}

export const EditableText = ({
  value,
  placeholder,
  onSubmit,
  onBlur,
  onChange,
  isProcessing,
  disabled,
  editable,
  hideControls = false,
  displayTextOnBlur = true,
  error,
}: EditableTextProps) => {
  const { t } = useTranslation()
  const [isEditorOpen, setEditorOpen] = useReactiveState(editable)
  const [internalValue, setInternalValue] = useReactiveState(value)

  const toggleEditor = (on: boolean) => {
    if (editable !== undefined) return
    setEditorOpen(on)
  }

  const handleEdit = () => {
    if (disabled) return
    toggleEditor(true)
  }

  const handleSubmit = async (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (onSubmit) await onSubmit(internalValue)
    toggleEditor(false)
  }

  const handleCancel = () => {
    toggleEditor(false)
    setInternalValue(value)
  }

  const handleBlur = (event: FocusEvent) => {
    if (event.relatedTarget) return
    if (onBlur) onBlur(internalValue)
    if (displayTextOnBlur) handleCancel()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.target.value)
    if (onChange) onChange(event.target.value)
  }

  return (
    <div className="relative" onBlur={handleBlur}>
      {isEditorOpen && (
        <div className="flex flex-row gap-2 w-full h-8">
          <InputTextGray
            value={internalValue}
            onChange={handleChange}
            placeholder={placeholder || t('general.placeholder')}
            disabled={isProcessing}
            fullWidth
            className={classnames('px-2', {
              [inputErrorClassNames]: !!error,
            })}
            maxLength={100}
          />
          {isProcessing && (
            <div>
              <CoreLoadingIcon className="p-2" />
            </div>
          )}
          {!hideControls && !isProcessing && (
            <>
              <WorkflowActionButton variant="ghost" className="!p-1" onClick={handleSubmit}>
                <CheckIcon className="w-4" style={{ strokeWidth: 3 }} />
              </WorkflowActionButton>
              <WorkflowRemoveButton variant="ghost" className="!p-1" onClick={handleCancel} />
            </>
          )}
        </div>
      )}
      {!isEditorOpen && (
        <>
          <div className="text-2xl w-full h-8" />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={classnames('text-2xl w-full font-bold truncate absolute top-0', { 'cursor-pointer': !disabled })}
            onClick={handleEdit}
          >
            {value || placeholder}
          </div>
        </>
      )}
      <InputError className="absolute bottom-[-28px] font-normal" error={error} />
    </div>
  )
}

export default EditableText
