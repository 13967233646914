import { ReactNode } from 'react'
import cn from '../../../lib/util'

export type LayoverProps = {
  children: JSX.Element | ReactNode
  className?: string
}

const Overlay = ({ children, className }: LayoverProps) => (
  <div
    className={cn(
      'fixed flex h-screen justify-center items-center z-50 inset-0 overflow-y-auto  mx-auto bg-white w-full',
      className
    )}
  >
    {children}
  </div>
)

export default Overlay
