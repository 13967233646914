import { MdClose } from 'react-icons/md'
import { TagType } from '../../../../types/shared/GeneralTypes'
import Badge from './Badge'

interface TagTypeComponent {
  tag: TagType
  removeTag: ((tag: TagType) => void) | undefined
  editable?: boolean
}

const Tag: React.FC<TagTypeComponent> = ({ tag, removeTag, editable }) => (
  <Badge className="group gap-1 bg-gray-100 rounded-full whitespace-nowrap">
    <div className={`px-2 ${editable && 'group-hover:px-0'}`}>{tag?.name}</div>
    {editable && removeTag && (
      <button type="button" className="hidden group-hover:flex" onClick={() => removeTag(tag)}>
        <MdClose className="hidden group-hover:flex" />
      </button>
    )}
  </Badge>
)

export default Tag
