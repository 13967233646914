interface ISectionHeader {
  title: string
  sectionButton?: React.ReactNode
}

const SectionHeader: React.FC<ISectionHeader> = ({ title, sectionButton }) => (
  <div className="border-b border-border-gray flex justify-between px-7">
    <p className="text-lg py-5">{title}</p>
    {sectionButton && sectionButton}
  </div>
)

export default SectionHeader
