import { useCallback, useMemo } from 'react'
import { IntakeSectionType } from '../../../../../types/Intake'
import {
  EvaluatedIntakeFormSectionType,
  EvaluatedSectionsReturnType,
} from '../../../../../types/Intake/form/IntakeSubmission'
import getLogicAllowedQuestions from './getLogicAllowedQuestions'

export default (sectionsToEvaluate: IntakeSectionType[] | undefined) => {
  const allQuestions = sectionsToEvaluate?.flatMap((formSections) => formSections.fields) || []

  const enabledQuestionsUuids = getLogicAllowedQuestions(allQuestions)

  const evaluateSection = useCallback(
    (section: IntakeSectionType): EvaluatedIntakeFormSectionType => {
      const activeAndEnabledByLogicFields = section.fields.filter(
        (question) => question.uuid && enabledQuestionsUuids.includes(question.external_reference || question.uuid)
      )

      const enabledFields = activeAndEnabledByLogicFields.filter((question) => !question.input.settings?.misc?.hidden)

      const processableFields = activeAndEnabledByLogicFields

      return {
        ...section,
        enabledFields,
        processableFields,
        disabledFields: section.fields.filter(
          (activeField) => !enabledFields.find((enabledField) => enabledField.uuid === activeField.uuid)
        ),
      }
    },
    [sectionsToEvaluate]
  )

  return useMemo<EvaluatedSectionsReturnType>(() => {
    const enabledSections = (sectionsToEvaluate || [])
      .map<EvaluatedIntakeFormSectionType>(evaluateSection)
      .filter((section) => (section.enabledFields?.length || 0) > 0)

    return { enabledSections, enabledQuestionsUuids }
  }, [sectionsToEvaluate])
}
