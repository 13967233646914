import type { PartialDeep } from 'type-fest'
import { apiDelete, apiGet, apiGetData, apiPost, apiPostData, apiPutData } from '../../../../core/utils/api/generic'
import { FormDataType, FormSubmissionType, FormTag, FormTemplateType } from '../../../../types/Forms'

export const formsQueryKeys = {
  apiGetFormBuilder: () => ['form-builder'],
  apiGetForm: (uuid: string) => ['form', uuid],
  apiGetForms: () => ['company-forms'],
  apiGetWorkflow: (formUuid: string) => ['workflow', formUuid],
}

// @ts-ignore
const formatUuid = <T extends Record<string, any> = FormDataType>(form: T): T => ({
  ...form,
  __uuid: form.__uuid || form.uuid,
})

// FORMS
export type FormsQueryParams = {
  publish?: boolean
  types?: string[]
}

export const apiGetForms = (params: FormsQueryParams = {}) =>
  apiGetData<FormDataType[]>('/forms', params).then((forms) => forms.map(formatUuid))

// FORM BUILDER
export const apiGetFormBuilder = (isTestForm?: boolean) => {
  const url = isTestForm ? '/forms/builder?requireTestForm=true' : '/forms/builder'
  return apiGet<FormTemplateType>(url)
}

// FORM
export const apiGetForm = (uuid: string) => apiGetData<FormDataType>(`/forms/${uuid}`).then(formatUuid)
export const apiPostForm = async (form: Partial<FormDataType>) =>
  apiPostData<FormDataType>('/forms', form).then(formatUuid) // only name
export const apiPutForm = async (form: FormDataType, uuid: string) =>
  apiPutData<FormDataType>(`/forms/${uuid}`, form).then(formatUuid) // putting the whole object including the template key
export const apiDeleteForm = async (uuid: string) => apiDelete<string>(`/forms/${uuid}`)
export const apiPublishForm = (uuid: string) => apiPost(`/forms/${uuid}/publish`, { publish: true })

// FORM TAGS
export const apiGetFormTags = (formId: string) => apiGetData<FormTag[]>(`forms/${formId}/tags`)
export const apiCreateFormTags = (formId: string, tags: string[]) =>
  apiPostData(`forms/${formId}/tags/attach`, { tags })
export const apiDeleteFormTags = (formId: string, tags: string[]) =>
  apiPostData(`forms/${formId}/tags/detach`, { tags })

// SUBMISSIONS
export const apiPostSubmission = (formId: string, data?: PartialDeep<FormTemplateType>) =>
  apiPostData<FormSubmissionType>(`/forms/${formId}/submissions`, { body: data }).then(formatUuid)
