import { Menu } from '@headlessui/react'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import { MenuItemType } from '../menus/Menu'
import MenuItem from '../menus/MenuItem'

interface IPopoverMenu extends INodeChildren {
  menuItems: MenuItemType[]
  className?: string
}

const PopoverMenu: React.FC<IPopoverMenu> = ({ children, menuItems, className }) => (
  <Menu>
    <Menu.Button>{children}</Menu.Button>
    <Menu.Items className={`${className} bg-white absolute top-12 right-0 w-max rounded-md shadow-md z-50`}>
      {menuItems?.map((menuItem, index) => (
        <MenuItem key={menuItem.name} item={menuItem} index={index} menuItemsLength={menuItems.length} />
      ))}
    </Menu.Items>
  </Menu>
)

export default PopoverMenu
