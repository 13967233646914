import { HiXMark } from 'react-icons/hi2'
import SendMessageButton from './SendMessageButton'
import TextInput from './TextInput'
import { emptyMessageProperties, useMessengerContext } from '../../../../../core/providers/MessengerProvider'
import cn from '../../../../../lib/util'

const MessageInput: React.FC = () => {
  const {
    messageProperties: [{ messageContent, files }, setMessageProperties],
  } = useMessengerContext()

  const clearMessageAndFiles = () => {
    setMessageProperties(emptyMessageProperties)
  }

  return (
    <div
      id="chatMessageBox"
      className="flex justify-between items-start space-x-2 bg-white border-rock-gray-light border rounded w-full p-2 cursor-auto shadow-md"
    >
      <TextInput />
      <div className="">
        <button
          onClick={clearMessageAndFiles}
          type="button"
          className={cn(
            'w-full flex flex-row items-center justify-center text-sm mb-1 transition-colors text-red-500',
            !messageContent && !files.length && 'text-gray-500'
          )}
          disabled={!messageContent && !files.length}
        >
          <HiXMark className="w-4 h-4" />
          Clear
        </button>

        <SendMessageButton />
      </div>
    </div>
  )
}

export default MessageInput
