import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputTextGray from '../../../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import { FormSectionType, FormDataType } from '../../../../../../types/Forms/formTypes'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateForm from '../../../hooks/useUpdateForm'

interface ITitleInput {
  section: FormSectionType
  index: number
}

const TitleInput: React.FC<ITitleInput> = ({ section, index }) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(section.name || '')
  const { updateForm } = useUpdateForm()
  const { data: formData } = useGetFormData()

  const handleSave = () => {
    if (!formData) return

    const newSections =
      formData?.template?.sections.map((templateSection, templateSectionIndex) =>
        templateSectionIndex === index
          ? {
              ...templateSection,
              name: title,
            }
          : templateSection
      ) || []

    updateForm({ template: { sections: newSections } })
  }

  return (
    <InputTextGray
      value={title}
      onChange={(e) => setTitle(e.currentTarget.value)}
      placeholder={t('forms.enter_section_name')}
      className="text-sm w-1/2"
      onBlur={handleSave}
      disabled={!section.actions.rename}
    />
  )
}

export default TitleInput
