import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputTextGray from '../../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import useGetFormData from '../../../hooks/useGetFormData'
import useUpdateForm from '../../hooks/useUpdateForm'

interface IFormTitleInput {
  disabled: boolean
}

const FormTitleInput: React.FC<IFormTitleInput> = ({ disabled }) => {
  const { t } = useTranslation()

  const { updateForm } = useUpdateForm()
  const { data: companyForm } = useGetFormData()

  const currentTitle = companyForm?.name || ''

  const [title, setTitle] = useState(currentTitle)

  const updateRequisitionTitle = () => {
    if (!companyForm) return

    updateForm({ name: title })
  }
  return (
    <div className="w-3/6">
      <InputTextGray
        value={title}
        onChange={(e) => setTitle(e.currentTarget.value)}
        onBlur={updateRequisitionTitle}
        error={!title.length && t('form.name_cant_be_empty')}
        disabled={disabled}
        placeholder={t('forms.enter_form_name')}
        className="text-lg w-full"
      />
    </div>
  )
}

export default FormTitleInput
