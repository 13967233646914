import { FC } from 'react'
import { dateFormatter, dateTimeFormatter } from '../../../shared/constants/dateFormat'
import {
  AddressQuestionValueNameWithFullAddress,
  FormQuestionContentType,
  FormQuestionValue,
  FormQuestionValueType,
} from '../../../types/Forms'
import { useFormField } from '../providers/FormFieldProvider'
import { germanPriceStringToFloat, priceToInternationalPriceString } from '../../../shared/constants/currencyFormat'
import { CompanyUserType } from '../../../types/shared/RequisitionDataTypes'
import generateUserName from '../../../shared/utils/generateUserName'
import FormFieldDisplayDefault from './FormFieldDisplayDefault'

export const valueToString = (type: FormQuestionContentType, value: FormQuestionValueType) => {
  if (type === 'DATE') {
    return dateFormatter(value as string)
  }

  if (type === 'DATE_TIME') {
    return dateTimeFormatter(value as string)
  }

  if (type === 'USER') {
    return generateUserName(value as CompanyUserType)
  }

  if (type === 'ADDRESS') {
    const { name, street, zip, city, state, country } = value as AddressQuestionValueNameWithFullAddress
    const countryName = country?.name ?? ''
    const addressArray = [name ?? '', street ?? '', city ?? '', zip ?? '', state ?? '', countryName].filter(
      (val) => val !== ''
    )
    return addressArray.join(', ')
  }

  if (typeof value === 'string') {
    return value
  }

  if (type === 'MULTI_SELECT') {
    return value
      ? (Array.isArray(value) ? value : [value])
          .filter((value) => !!value)
          .map((value) => value.value)
          .filter((value) => !!value)
          .join(', ')
      : '-'
  }

  return value.toString()
}

const missingValue = (type: FormQuestionContentType) => {
  if (type === 'ADDRESS') {
    return <FormFieldDisplayDefault />
  }

  if (['NUMBER', 'PRICE', 'TEXT', 'TEXT_AREA', 'SELECT', 'MULTI_SELECT', 'PRICE'].includes(type)) {
    return <FormFieldDisplayDefault />
  }

  return <FormFieldDisplayDefault />
}

interface IFormFieldDisplayGenericComponent {
  id: string
  value: FormQuestionValue | null
  type: FormQuestionContentType
  className?: string
}

export const FormFieldDisplayGenericComponent: FC<IFormFieldDisplayGenericComponent> = ({
  id,
  value,
  type,
  className,
}) => {
  const getValue = () => {
    if (type === 'MULTI_SELECT') {
      // @ts-ignore
      return value && Object.values(value)
    }
    if (type === 'PRICE') {
      // @ts-ignore
      return priceToInternationalPriceString(germanPriceStringToFloat(value?.value as string), value?.currencyId)
    }

    if (type === 'DATE_RANGE') {
      // @ts-ignore
      return (value?.value as string)
        ?.split(',')
        .map((date) => `${dateFormatter(date)}`)
        .join(' - ')
    }

    // @ts-ignore
    return value?.display_name || value?.value
  }

  return (
    <div key={`form-field-display-${id}`} className={className}>
      {/* @ts-ignore */}
      {(getValue() && valueToString(type, getValue())) || missingValue(type)}
    </div>
  )
}

export const FormFieldDisplayGeneric = () => {
  const {
    field: {
      id,
      value,
      input: { type },
    },
  } = useFormField()

  return (
    <FormFieldDisplayGenericComponent
      id={id}
      value={value}
      type={type}
      className="flex flex-col gap-4 text-base font-normal whitespace-pre-wrap break-words break-all"
    />
  )
}

export default FormFieldDisplayGeneric
