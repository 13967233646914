import { useFormField } from '../providers/FormFieldProvider'
import { ToggleBoolean } from '../../../shared/components/atoms/Toggle'

export const FormFieldToggle = () => {
  const {
    field: {
      value,
      input: { type },
      disabled,
    },
    updateValue,
  } = useFormField()

  if (type !== 'TOGGLE') return null

  return <ToggleBoolean selected={value?.value as unknown as boolean} disabled={disabled} onSelect={updateValue} />
}

export default FormFieldToggle
