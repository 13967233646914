import type { RequireAtLeastOne } from 'type-fest'
import { apiDelete, apiGet, apiPost, apiPostData, apiPut } from '../../../core/utils/api/generic'
import { Offer, OfferItem } from '../../../types/Offering'
import { PaginatedResponse } from '../../../types/shared/PaginatedResponse'
import { CalculateOfferTotalPriceParams } from '../types'

export const getOffers = (searchParams?: RequireAtLeastOne<{ reference_key: string; offering_uuid: string }>) =>
  apiGet<{ data: OfferItem[] }>('/offering-offers', searchParams).then(({ data }) => data)

export const getAllOffers = (reference_keys?: string[]) =>
  apiPost<{ data: OfferItem[] }>('/offering-offers/all', { reference_keys }).then(({ data }) => data)

export const getPaginatedOffers = (
  searchParams?: RequireAtLeastOne<{ reference_key: string; offering_uuid: string }>,
  page = 1,
  per_page = 5
) => apiGet<PaginatedResponse<OfferItem>>('/offering-offers', { ...searchParams, page, per_page })

export const postOffer = (data: Partial<Offer>, intakeUuid: string) =>
  apiPostData(`/intake-flows/${intakeUuid}/offers`, data)
export const postOfferItem = (
  data: Partial<Omit<OfferItem, 'uuid'>> &
    RequireAtLeastOne<{ reference_key: string; offering_uuid: string }> & { intake_flow_uuid?: string }
) => apiPost('/offering-offers', data)

export const putOfferItem = ({ uuid, ...data }: Partial<OfferItem> & Pick<OfferItem, 'uuid'>) =>
  apiPut(`/offering-offers/${uuid}`, data)

export const deleteOfferItem = (uuid: string) => apiDelete(`/offering-offers/${uuid}`)

export const calculateOfferTotalPrice = (data: CalculateOfferTotalPriceParams) =>
  apiPost<{ data: OfferItem }>('/offering-offers/calculate-price', data).then(({ data: return_data }) => return_data)
