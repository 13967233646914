import { TableHTMLAttributes } from 'react'

const TableHeader: React.FC<
  ReactI18NextChild | (Iterable<ReactI18NextChild | string> & TableHTMLAttributes<HTMLTableCellElement>)
> = ({ children, className, ...divProps }) => (
  <th
    className={`table-cell text-left text-xs text-secondary-gray font-normal px-4 py-2 last:text-right ${className}`}
    {...divProps}
  >
    {children}
  </th>
)

export default TableHeader
