import { useMutation, useQuery } from 'react-query'
import { apiDelete, apiGetData, apiPostData } from '../../../../core/utils/api/generic'

export type IntegrationPartyType =
  | 'openai'
  | 'jira'
  | 'unite'
  | 'drive'
  | 'teams'
  | 'docu-sign'
  | 'webhook'
  | 'slack'
  | 'sap'
  | 'sap-ariba'

export type IntegrationSettingsType = {
  id?: number
  party: IntegrationPartyType
  client_id?: string
  client_secret?: string
  token?: string
}

export type IntegrationType = {
  name: string
  party: IntegrationPartyType
  image: string
  description: string
  fields?: Array<keyof IntegrationSettingsType>
}

const apiPostIntegration = async (data: Omit<IntegrationSettingsType, 'id'>) =>
  apiPostData<IntegrationSettingsType>('/api-configuration', data)
const apiGetIntegrations = async () => apiGetData<IntegrationSettingsType[]>('/api-configuration')
const apiDeleteIntegration = async (id: number) => apiDelete(`/api-configuration/${id}`)

export const queryServiceKey = (method: string, schemaUuid?: string) => `data-schema-${method}-${schemaUuid}`

export const useIntegrationService = () => {
  const getIntegrations = useQuery(['integrations'], apiGetIntegrations)
  const postIntegration = useMutation(apiPostIntegration, { onSuccess: () => getIntegrations.refetch() })
  const deleteIntegration = useMutation(apiDeleteIntegration, { onSuccess: () => getIntegrations.refetch() })
  return { postIntegration, getIntegrations, deleteIntegration }
}
