import { MessageType } from '../../../../../../types/Messenger/types'
import MessageBody from './MessageBody'
import ReplyButton from './ReplyButton'
import Timestamp from './Timestamp'

interface IMessage {
  message: MessageType
}

const Message: React.FC<IMessage> = ({ message }) => (
  <div className="w-full flex">
    <div
      className={`w-full flex flex-col pb-5 ${message.is_my_message ? 'pl-10 text-right' : 'pr-10 text-left'}`}
      id={`message-${message.uuid}`}
    >
      <div className={`text-sm font-semibold text-black-light ${message.is_my_message ? '' : ''}`}>
        {message.user?.full_name}
      </div>
      <div
        className={`w-full flex-row px-3 rounded-sm flex
        my-1 justify-between ${message.is_my_message ? ' bg-rock-gray-light' : ' bg-sky-blue-light text-white'}`}
      >
        <MessageBody message={message} />
        {!message.is_my_message && (
          <div className="flex items-center justify-end">
            <ReplyButton message={message} />
          </div>
        )}
      </div>
      <Timestamp message={message} />
    </div>
  </div>
)

export default Message
