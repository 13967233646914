import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import ProviderButton, { IconsType } from './Components/ProviderButton'
import EmailLoginForm from './Components/EmailLoginForm'
import { getTenant } from '../../../../core/utils/tenancy'
import AuthLayout from '../shared/AuthLayout'
import useGetSSOProviders from '../shared/hooks/useGetSSOProviders'

type ProviderType = {
  type: IconsType
  name: string
  url?: string
}

const Login = (): JSX.Element => {
  const { t } = useTranslation()
  const { data: ssoProviders } = useGetSSOProviders()
  const [emailSelected, setEmailSelected] = useState<boolean>(false)
  const tenant: string = getTenant()
  const allProvider: ProviderType[] = ssoProviders?.data?.map(
    (provider: string): ProviderType => ({
      type: provider as IconsType,
      name: t(
        `generic.sign-in-with-${provider}`,
        `Sign in with ${provider.charAt(0).toUpperCase() + provider.slice(1)}`
      ),
      url: provider !== 'email' ? `${process.env.REACT_APP_BASEURL}/${tenant}/login/${provider}?company=1` : undefined,
    })
  )

  const handleSelection = (provider: ProviderType) => {
    if (provider.type === 'email') {
      setEmailSelected(true)
    }
    if (!provider.url) {
      return
    }
    window.location.href = provider.url
  }

  return (
    <AuthLayout
      title={t('generic.sign-in', 'Sign in to your account')}
      disclaimer={t(
        'generic.disclaimer',
        'By clicking “Sign in” or “Sign in with SSO” you agree to our Terms of Use and Privacy policy.'
      )}
    >
      <div className="h-full w-full flex flex-col items-center justify-center">
        <div className="flex flex-col space-y-9 w-96 h-full">
          <AnimatePresence mode="wait">
            {emailSelected ? (
              <motion.div
                key={emailSelected.toString()}
                animate={{ opacity: 1, transition: { duration: 0.2 } }}
                exit={{ opacity: 0, transition: { duration: 0.2 } }}
              >
                <EmailLoginForm onBackButtonClick={() => setEmailSelected(false)} />
              </motion.div>
            ) : (
              <motion.div
                key={emailSelected.toString()}
                className="flex flex-col space-y-6"
                animate={{ opacity: 1, transition: { duration: 0.2 } }}
                exit={{ opacity: 0, transition: { duration: 0.2 } }}
              >
                {allProvider?.map((providerType) => (
                  <ProviderButton
                    key={providerType.type}
                    type={providerType.type}
                    name={providerType.name}
                    url={providerType.url}
                    onClick={handleSelection}
                  />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </AuthLayout>
  )
}
export default Login
