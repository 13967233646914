import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import DnDDroppable from './DnDDroppable'

interface IDnDContext extends INodeChildren {
  droppableId: string
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void
  droppableType: string
}

const DnDContext: React.FC<IDnDContext> = ({ children, droppableId, onDragEnd, droppableType }) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <DnDDroppable droppableId={droppableId} droppableType={droppableType}>
      {children}
    </DnDDroppable>
  </DragDropContext>
)

export default DnDContext
