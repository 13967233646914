import { FormikErrors, FormikTouched } from 'formik'
import { useTranslation } from 'react-i18next'
import PhoneNumberInputUnderline from '../../../../../../shared/components/atoms/inputs/underlined/PhoneNumberInputUnderline'
import FormikInputGrayUnderline from '../../../../../../shared/components/atoms/inputs/underlined/FormikInputGrayUnderline'

interface IAddressFormTextInput {
  label: string
  placeholder: string
  customOnChange?: (e: any) => void
  constants: {
    touched: FormikTouched<any>
    values: {}
    errors: FormikErrors<any>
    handleChange: (e: React.ChangeEvent) => void
  }
  phoneNumber?: boolean
  errorKey: string
  className?: string
  dataCy?: string
}

const AddressFormTextInput: React.FC<IAddressFormTextInput> = ({
  label,
  dataCy,
  constants,
  placeholder,
  errorKey,
  className,
  customOnChange,
  phoneNumber,
}) => {
  const { t } = useTranslation()

  if (phoneNumber) {
    return (
      <PhoneNumberInputUnderline
        dataCy={dataCy}
        name={errorKey}
        handleChange={constants.handleChange}
        placeholder={placeholder}
        values={constants.values}
        label={label}
        className={`border-none ${className}`}
        error={{ props: { error: t(constants.errors[errorKey] as string) } }}
      />
    )
  }

  return (
    <FormikInputGrayUnderline
      dataCy={dataCy}
      type="text"
      name={errorKey}
      handleChange={constants.handleChange}
      placeholder={placeholder}
      values={constants.values}
      label={label}
      className={`border-none ${className}`}
      error={{ props: { error: t(constants.errors[errorKey] as string) } }}
      customOnChange={customOnChange}
    />
  )
}

export default AddressFormTextInput
