import classnames from 'classnames'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { IntakeChangeLogItemType, IntakeChangeLogItemValue } from '../../../../../../types/Intake'
import { getItemEventBackgroundColour, getItemEventIcon, useGetItemHeading } from '../../../helpers/changeLogHelpers'

const ChangeLogItem: React.FC<{ item: IntakeChangeLogItemType }> = ({ item }) => {
  const { t } = useTranslation()
  const StatusIcon = getItemEventIcon(item.event)
  const itemHeading = useGetItemHeading(item)

  const parseValue = (itemValue: IntakeChangeLogItemValue | undefined) => {
    const { value, status } = itemValue || {}
    const content = value || status || ''

    if (content.includes('value')) {
      const parsedValue = JSON.parse(content)
      return parsedValue.value
    }
    return content
  }

  return (
    <div className="relative flex space-x-4">
      <div>
        <span
          className={classnames(
            getItemEventBackgroundColour(item.new_values?.status || item.event),
            'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
          )}
        >
          <StatusIcon className="h-5 w-5 text-white text-md" aria-hidden="true" />
        </span>
      </div>
      <div className="flex min-w-0 flex-1 flex-col justify-between">
        <div className="text-sm font-semibold text-black-light">{itemHeading}</div>
        <div className="flex flex-col text-xs text-black-light-2 pt-3">
          <div className="flex flex-row">
            <span>{dayjs.utc(item.date).local().format('HH:mm')}</span>
            <span className="mx-1 text-rock-gray-light">•</span>
            <span>{`${t('generic.by', 'by')} ${item.author.full_name}`}</span>
          </div>

          {parseValue(item?.old_values) && parseValue(item?.new_values) && (
            <div className="flex mt-4">
              <div className="flex flex-row border border-rock-gray-light p-2 items-center">
                {t(parseValue(item.old_values))}
                <ArrowLongRightIcon className="mx-2 h-5 w-5 text-md" aria-hidden="true" />
                <div className="text-sky-blue-light font-semibold">{t(parseValue(item.new_values))}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChangeLogItem
