import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import getEditMenu from '../../../../shared/components/organisms/menus/hooks/getEditMenu'
import Menu from '../../../../shared/components/organisms/menus/Menu'
import useGetSupplierBranch from '../../hooks/useGetSupplierBranch'
import SupplierIdForm from '../../SupplierDetailsPage/SupplierIdForm'

interface ISupplierIds {
  editable: boolean | undefined
}

const SupplierIds: React.FC<ISupplierIds> = ({ editable }) => {
  const { t } = useTranslation()
  const { data: supplierBranchData } = useGetSupplierBranch()

  const [editMode, setEditMode] = useState(false)

  const menuItems = getEditMenu(setEditMode)

  if (editMode) return <SupplierIdForm hideForm={() => setEditMode(false)} />

  return (
    <div className="flex justify-between w-full items-center">
      <div className="flex gap-x-6 text-sm text-primary-gray">
        <p>
          <span className="font-medium">{`${t('supplier.id')}:`}</span>
          {` ${supplierBranchData?.customer_supplier_id || t('supplier.no_id')}`}
        </p>
        <p>
          <span className="font-medium">DUNS:</span>
          <span data-cy="duns-number">{` ${supplierBranchData?.duns_number || t('supplier.no_duns')}`}</span>
        </p>
      </div>
      {editable && <Menu menuItems={menuItems} buttonCy="supplier-id-menu" />}
    </div>
  )
}

export default SupplierIds
