import { DropResult } from 'react-beautiful-dnd'
import { useQuestionItemsList } from './QuestionItemsList'
import QuestionItem, { QuestionItemType } from './QuestionItem'
import DnDContext from '../../../../../../shared/components/organisms/DnD/DnDContext'
import DnDDroppable from '../../../../../../shared/components/organisms/DnD/DnDDroppable'

const QuestionItems: React.FC = <T extends QuestionItemType>() => {
  const { onDragEnd, items } = useQuestionItemsList()

  const handleDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index
    if (destinationIndex === undefined) return

    onDragEnd(sourceIndex, destinationIndex)
  }

  return (
    <div>
      <DnDContext droppableId="droppable" onDragEnd={handleDragEnd} droppableType="droppable">
        <DnDDroppable droppableId="droppable" droppableType="droppable">
          {items?.map((item, index) => <QuestionItem key={item.__uuid} index={index} item={item} />)}
        </DnDDroppable>
      </DnDContext>
    </div>
  )
}

export default QuestionItems
