import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { getTenant } from '../../../../../core/utils/tenancy'
import useGetSupplierBranch from '../../../hooks/useGetSupplierBranch'
import SupplierDetailsSource from './SupplierDetailsSource'

interface ISupplierSource {
  editable?: boolean
}

const SupplierSource: React.FC<ISupplierSource> = ({ editable }) => {
  const { t } = useTranslation()
  const { data: supplierBranchData } = useGetSupplierBranch()

  return (
    <div className="flex gap-x-6 text-sm">
      {editable ? (
        <SupplierDetailsSource />
      ) : (
        <p className="text-primary-gray">
          <span className="font-medium">{`${t('requisition_suppliers.list_source')}: `}</span>
          {_.capitalize(supplierBranchData?.source || getTenant())}
        </p>
      )}
    </div>
  )
}

export default SupplierSource
