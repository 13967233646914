import { useQuery } from 'react-query'
import SuppliersQueryKeys from '../../Suppliers/constants/SuppliersQueryKeys'
import SupplierUserType from '../../../types/Suppliers/SupplierUserType'
import { apiGetSupplierBranchUsers } from '../../Suppliers/services/SuppliersService'
import useGetSupplierBranchId from './useGetSupplierBranchId'

export default (supplierBranchIdParam?: number | undefined) => {
  const supplierBranchIdState = useGetSupplierBranchId()
  const supplierBranchId = supplierBranchIdParam || supplierBranchIdState

  return useQuery(
    [SuppliersQueryKeys.SUPPLIER_USERS, supplierBranchId],
    () => apiGetSupplierBranchUsers(supplierBranchId) as Promise<SupplierUserType[]>,
    { enabled: !!supplierBranchId }
  )
}
