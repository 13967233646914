import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import SearchMultiSelect from '../../../../../../shared/components/atoms/SearchSelect/SearchMultiSelect'
import fileTypeOptions from './fileTypeOptions'
import BaseInputWrapper from '../../../../../../shared/components/atoms/Input/BaseInputWrapper'

const FileQuestionSettings: React.FC = () => {
  const { t } = useTranslation()
  const {
    question: [question],
    updateQuestion,
  } = useFormContext()

  if (question?.type !== 'FILE') return null
  const optionValues = question?.__settings?.fileType

  return (
    <BaseInputWrapper label="Select allowed filetypes">
      <SearchMultiSelect
        nullable
        onChange={(data) =>
          updateQuestion({
            __settings: {
              fileType: {
                allowedFileTypes: data?.map(
                  (filetype) =>
                    ({
                      label: filetype.label,
                      id: filetype.id,
                    }) as { label: string; id: string }
                ),
              },
            },
          })
        }
        options={fileTypeOptions}
        currentSelection={fileTypeOptions.filter(
          (option) => optionValues?.allowedFileTypes?.map((o) => o.id).includes(option.id as string)
        )}
      />
    </BaseInputWrapper>
  )
}

export default FileQuestionSettings
