import { useMutation, useQueryClient } from 'react-query'
import { useRef } from 'react'
import { putActiveWorkflowTaskStatus } from '../services'
import useGetCurrentWorkflow from './useGetCurrentWorkflow'
import { ActiveWorkflowAction, TaskActionObject } from '../../../../types/Workflow/WorkflowAction'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'
import { getUpdatedByProperty } from '../../../../shared/utils/arrayHelpers'

export type UpdateTaskStatusProps = TaskActionObject & { blockUuid: WorkflowBlock['uuid'] } & Pick<
    ActiveWorkflowAction,
    'status_uuid'
  >

const useUpdateTaskStatusMutation = () => {
  const { refetch } = useGetCurrentWorkflow()
  const queryClient = useQueryClient()
  const previousValue = useRef<ActiveWorkflowAction[]>()

  return useMutation(
    (props: UpdateTaskStatusProps) => {
      const { uuid, status, parent_type, status_uuid } = props
      return putActiveWorkflowTaskStatus(uuid!, { status, parent_type, parent_uuid: status_uuid })
    },
    {
      onMutate: async ({ blockUuid, ...taskUpdate }: UpdateTaskStatusProps) => {
        const queryCacheKey = ['blockActions', blockUuid]
        await queryClient.cancelQueries(queryCacheKey)
        const previousActions = queryClient.getQueryData<ActiveWorkflowAction[]>(queryCacheKey)
        previousValue.current = previousActions

        const actionToUpdate = previousActions?.find((action) => {
          const actionObject = action.object as TaskActionObject
          return actionObject?.uuid === taskUpdate.uuid
        })

        if (!previousActions || !actionToUpdate) return

        const updatedActions = getUpdatedByProperty(
          // @ts-ignore it's fine to access nested properties
          'object.uuid',
          previousActions,
          [
            {
              ...actionToUpdate,
              object: getMergedDeepOverwritingArrays(actionToUpdate.object || {}, taskUpdate),
            },
          ]
        )

        queryClient.setQueryData<ActiveWorkflowAction[]>(queryCacheKey, updatedActions)
      },
      onSuccess: () => refetch(),
      onError: (error, { blockUuid }) => {
        if (!previousValue.current) return

        queryClient.setQueryData<ActiveWorkflowAction[]>(['blockActions', blockUuid], previousValue.current)
      },
    }
  )
}

export default useUpdateTaskStatusMutation
