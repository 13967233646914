import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../core/providers/FormProvider'
import uuid from '../../../../../core/utils/uuid'
import { getUpdatedByUuid, move } from '../../../../../shared/utils/arrayHelpers'
import QuestionItemsList from '../components/QuestionItemsList/QuestionItemsList'
import { FormQuestionResponseType } from '../../../../../types/Forms'
import QuestionOptionInput from './QuestionOptionInput'

interface IQuestionChoice {}

const QuestionChoices: React.FC<IQuestionChoice> = () => {
  const { t } = useTranslation()
  const {
    question: [question],
    updateQuestion,
  } = useFormContext()

  const choices = question?.options

  const handleDragEnd = (from: number, to: number) => {
    updateQuestion({
      options: move(choices || [], from, to),
      forceUpdateId: uuid(),
    })
  }

  const addChoice = () => {
    if (!question) return

    updateQuestion({
      options: [
        ...(question.options || []),
        {
          __uuid: uuid(),
          value: '',
          label: question.question_label || '',
        },
      ],
    })
  }

  const handleChange = (updatedOption: FormQuestionResponseType) => {
    if (!question?.options) return

    updateQuestion({
      options: getUpdatedByUuid(question.options, [updatedOption]),
    })
  }

  const deleteQuestionChoice = ({ __uuid }: FormQuestionResponseType) => {
    if (!question) return

    updateQuestion({
      options: question.options?.filter((builderChoice) => builderChoice.__uuid !== __uuid),
    })
  }

  if (!choices) return null

  return (
    <QuestionItemsList<FormQuestionResponseType>
      description={t('forms.choices_input')}
      title={t('forms.choices')}
      items={choices}
      onDragEnd={handleDragEnd}
      onCreate={addChoice}
      onDelete={deleteQuestionChoice}
      onChange={handleChange}
      ItemComponent={QuestionOptionInput}
    />
  )
}

export default QuestionChoices
