import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import PageTopbar from '../../../../../shared/components/layout/PageTopBar'
import useGetUserById from '../../Profile/hooks/useGetUserById'
import UserDetailsDefaultValues from './UserDetailsDefaultValues'

interface IUserDetails {}

const UserDetails: FC<IUserDetails> = () => {
  const { t } = useTranslation()
  const { userId } = useParams<{ userId: string }>()

  const { data: user, isLoading } = useGetUserById(parseInt(userId, 10))

  return (
    <div className="w-full">
      <PageTopbar title={t('user-settings.user_details', 'User Details')} />
      <div className="max-w-4xl mx-auto py-12">{!isLoading && user && <UserDetailsDefaultValues user={user} />}</div>
    </div>
  )
}

export default UserDetails
