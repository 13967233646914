import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type Validator<T extends object> = (state: T, property: keyof T) => string | undefined

export type ValidationConfig = {
  required: boolean
}

type ErrorsIndex<T extends object> = Record<keyof T, string | undefined>

export const useValidation = <T extends object>(
  state: T,
  config: Partial<Record<keyof T, ValidationConfig | Validator<T>>>
) => {
  const [errors, setErrors] = useState({} as ErrorsIndex<T>)
  const { t } = useTranslation()

  const validate = () => {
    const errors = {} as ErrorsIndex<T>

    ;(Object.keys(state) as (keyof T)[]).forEach((key) => {
      if (typeof config[key] === 'function') {
        errors[key] = (config[key] as Validator<T>)(state, key)
        return
      }

      if ((config[key] as ValidationConfig)?.required && !state[key]) {
        errors[key] = t('generic.required', 'Required')
      }
    })

    setErrors(errors)

    return (Object.keys(errors) as (keyof T)[]).filter((key) => !!errors[key]).length === 0
  }

  const reset = () => {
    setErrors({} as ErrorsIndex<T>)
  }

  return { errors, validate, reset }
}
