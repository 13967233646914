import { ComponentType, useMemo } from 'react'
import { EdgeProps } from 'reactflow'
import {
  WorkflowChartBlockNodeProps,
  WorkflowChartEdgeType,
  WorkflowChartGroupNodeProps,
  WorkflowChartNodeHelperProps,
} from '../../../../types/Workflow/WorkflowChart'
import WorkflowChartNodeGeneric from '../../components/WorkflowChartNodes/WorkflowChartNodeGeneric'
import WorkflowChartNodesGroup from '../../components/WorkflowChartNodes/WorkflowChartNodesGroup'
import WorkflowChartNodeHelper from '../../components/WorkflowChartNodes/WorkflowChartNodeHelper'
import WorkflowChartEdgeWithExtendButton from '../../components/WorkflowChartEdges/WorkflowChartEdgeWithExtendButton'

export type NodeTypes = {
  APPROVAL: ComponentType<WorkflowChartBlockNodeProps>
  APPROVER: ComponentType<WorkflowChartBlockNodeProps>
  ACTION: ComponentType<WorkflowChartBlockNodeProps>
  GROUP: ComponentType<WorkflowChartGroupNodeProps>
  HELPER: ComponentType<WorkflowChartNodeHelperProps>
}

const defaultNodeTypes: NodeTypes = {
  APPROVAL: WorkflowChartNodeGeneric,
  APPROVER: WorkflowChartNodeGeneric,
  ACTION: WorkflowChartNodeGeneric,
  GROUP: WorkflowChartNodesGroup,
  HELPER: WorkflowChartNodeHelper,
}

export type EdgeTypes = Record<WorkflowChartEdgeType, ComponentType<EdgeProps>>

const defaultEdgeTypes: EdgeTypes = {
  EDGE_WITH_ADD_BUTTON: WorkflowChartEdgeWithExtendButton,
}

type WorkflowChartNodeTypes = {
  nodes?: Partial<NodeTypes>
  edges?: Partial<EdgeTypes>
}

export const useWorkflowChartNodeTypes = ({
  nodes: customNodeTypes,
  edges: customEdgeTypes,
}: WorkflowChartNodeTypes) => {
  const nodeTypes = useMemo(() => ({ ...defaultNodeTypes, ...(customNodeTypes || {}) }), [])

  const edgeTypes = useMemo(() => ({ ...defaultEdgeTypes, ...(customEdgeTypes || {}) }), [])

  return { nodeTypes, edgeTypes }
}
