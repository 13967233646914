import { MdOutlineDescription } from 'react-icons/md'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'

interface ISharedFileUploadPillContent {
  file: { name: string; url?: string }
  isUploading: boolean | undefined
  iconClassName: string | undefined
}

const SharedFileUploadPillContent: React.FC<ISharedFileUploadPillContent> = ({ file, isUploading, iconClassName }) => (
  <div className="flex gap-x-2 items-center font-normal">
    {isUploading ? (
      <div className="h-4 w-4">
        <CoreLoadingIcon className="max-h-full" />
      </div>
    ) : (
      <span>
        <MdOutlineDescription className={`min-w-4 text-lhotse-orange ${iconClassName}`} size={15} />
      </span>
    )}
    <p className="line-clamp-1 text-left box-border break-all text-lhotse-orange">{file.name}</p>
  </div>
)

export default SharedFileUploadPillContent
