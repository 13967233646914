import { useTranslation } from 'react-i18next'
import BaseInputWrapper from '../../../../../shared/components/atoms/Input/BaseInputWrapper'
import SearchSelect from '../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { WorkflowBlockReminder, WorkflowBlockReminders } from '../../../../../types/Workflow/WorkflowBlock'
import { InputSearchSelectItemType } from '../../../../../types/shared/InputSearchSelectTypes'
import useWorkflowBlockDraftMutations from '../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflow } from '../../../providers/WorkflowProvider'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'

export const WorkflowBuilderSidePanelDeadlineField = () => {
  const { t } = useTranslation()
  const { readonly } = useWorkflow()
  const { block } = useWorkflowBlockDraft()
  const { setDeadline } = useWorkflowBlockDraftMutations()

  return (
    <BaseInputWrapper label={t('workflow.block.deadlines', 'Deadline')}>
      <SearchSelect
        options={WorkflowBlockReminders.map((wr) => ({ label: wr, id: wr }))}
        currentSelection={{ label: block.reminder, id: block.reminder }}
        onChange={(item: InputSearchSelectItemType) => setDeadline(item.id as WorkflowBlockReminder)}
        disabled={readonly}
      />
    </BaseInputWrapper>
  )
}

export default WorkflowBuilderSidePanelDeadlineField
