import { useTranslation } from 'react-i18next'
import TableCell from '../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../shared/components/atoms/SharedTable/TableRow'

export type DynamicRowData = Record<string, any> & { id: number }

type ExternalDataTableRowProps = {
  data: DynamicRowData
}

const ExternalDataTableRow: React.FC<ExternalDataTableRowProps> = ({ data }) => {
  const { t } = useTranslation()

  // todo row actions are not supported yet
  /*
  const detailsActions: MenuItemType[] = [
    { name: t('general.edit'), onClick: () => console.log('edit item') },
    { name: t('general.Delete'), onClick: () => console.log('remove item') }]
  */

  return (
    <TableRow>
      {Object.keys(data).map((key) => (
        <TableCell key={`external-data-${data.id}-cell-${key}`}>{data[key]}</TableCell>
      ))}
      {/*      <TableCell>
        <Menu menuItems={detailsActions} />
      </TableCell> */}
    </TableRow>
  )
}

export default ExternalDataTableRow
