import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { ExternalDataColumn } from '../../../../types/ExternalDataManager/ExternalDataManagerTypes'
import BaseInputWrapper from '../../../../shared/components/atoms/Input/BaseInputWrapper'
import SearchMultiSelect from '../../../../shared/components/atoms/SearchSelect/SearchMultiSelect'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import { apiPatchExternalDataEntityColumns } from '../../services/ExternalDataServices'
import useCurrentExternalDataSourceUuid from '../../hooks/useCurrentExternalDataSourceUuid'

interface IExternalDataEmbeddingsModal {
  columns: ExternalDataColumn[]
}

const ExternalDataEmbeddingsModal: FC<IExternalDataEmbeddingsModal> = ({ columns }) => {
  const { modal } = useUtils()
  const { t } = useTranslation()
  const cache = useQueryClient()

  const externalDataUuid = useCurrentExternalDataSourceUuid()
  const [columnsState, setColumnsState] = useState<ExternalDataColumn[]>(columns)

  const primaryKeySearchSelectOptions = columnsState.map((column) => ({
    id: column.id,
    label: column.column_name,
    is_selected: column.primary_key,
  }))
  const pkSelection = primaryKeySearchSelectOptions.filter((option) => option.is_selected)

  const embeddingsSearchSelectOptions = columnsState.map((column) => ({
    id: column.id,
    label: column.column_name,
    is_selected: column.for_embeddings,
  }))
  const embeddingsSelection = embeddingsSearchSelectOptions.filter((option) => option.is_selected)

  const updateColumnPrimaryKeys = (items: InputSearchSelectItemType[]) => {
    const updatedColumns = columnsState.map((column) => {
      const foundItem = items.find((item) => item.id === column.id)
      if (foundItem) {
        return { ...column, primary_key: true }
      }
      return { ...column, primary_key: false }
    })

    setColumnsState(updatedColumns)
  }

  const updateColumnEmbeddings = (items: InputSearchSelectItemType[]) => {
    const updatedColumns = columnsState.map((column) => {
      const foundItem = items.find((item) => item.id === column.id)
      if (foundItem) {
        return { ...column, for_embeddings: true }
      }
      return { ...column, for_embeddings: false }
    })

    setColumnsState(updatedColumns)
  }

  const onSubmit = async () => {
    const response = await apiPatchExternalDataEntityColumns({ uuid: externalDataUuid, columns: columnsState })

    if (response.status === 200) {
      await cache.invalidateQueries([externalDataUuid])
      await cache.invalidateQueries(['relationships'])
      await cache.invalidateQueries(['entities'])
      modal.set({ isOpen: false })
    }
  }

  return (
    <div className="flex flex-col justify-between min-h-[400px]">
      <div className="flex flex-col gap-11 mb-5">
        <BaseInputWrapper label={t('external_data.primary_key', 'Primary key')}>
          <SearchMultiSelect
            onChange={updateColumnPrimaryKeys}
            options={primaryKeySearchSelectOptions}
            currentSelection={pkSelection}
          />
        </BaseInputWrapper>
        <BaseInputWrapper label={t('external_data.embeddings', 'Embeddings')}>
          <SearchMultiSelect
            onChange={updateColumnEmbeddings}
            options={embeddingsSearchSelectOptions}
            currentSelection={embeddingsSelection}
          />
        </BaseInputWrapper>
      </div>
      <div>
        <SaveCancelFormButtons isLoading={false} onCancel={() => modal.set({ isOpen: false })} onSave={onSubmit} />
      </div>
    </div>
  )
}

export default ExternalDataEmbeddingsModal
