import type { PartialDeep } from 'type-fest'
import { cloneDeep } from 'lodash'
import { FormQuestionType, FormSectionType, LoopQuestionLogic } from '../../../../../../../types/Forms'
import useGetFormData from '../../../../../hooks/useGetFormData'
import useSectionMutations from './useSectionMutations'
import { mergeDeepOverwritingArrays } from '../../../../../../../shared/utils/objectHelpers'

export default (uuid: string, section: FormSectionType) => {
  const { data: formData } = useGetFormData()
  const { updateSection } = useSectionMutations()

  const byUuid = (question: FormQuestionType) => question.__uuid === uuid
  const getMutableQuestions = () => cloneDeep(section.fields) || []

  const updateQuestion = (updateData: PartialDeep<FormQuestionType>) => {
    if (!formData) return

    const questions = getMutableQuestions()

    const questionToUpdate = questions.find(byUuid)

    if (!questionToUpdate) return

    mergeDeepOverwritingArrays(questionToUpdate, updateData)

    updateSection(section.__uuid, {
      fields: questions,
    })
  }

  const updateLoop = (loopData: PartialDeep<LoopQuestionLogic>) => {
    updateQuestion({
      __loop: loopData,
    })
  }

  return { updateQuestion, updateLoop }
}
