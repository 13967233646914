import { FC } from 'react'
import JiraLogo from './ActionIcons/Jira_logo.svg'
import SlackLogo from './ActionIcons/Slack_logo.svg'

import { WorkflowActionSlugType } from '../../../../../../../../types/Workflow/WorkflowAction'

// Mapping of slugs to the corresponding logo
type LogoMapType = { [key in WorkflowActionSlugType]: string | null }
const logoMap: LogoMapType = {
  jira_issue_create: JiraLogo,
  send_slack_message: SlackLogo,
  task_action: null,
}

interface IIntakeFlowchartActionIcon {
  slug: WorkflowActionSlugType | null
}

const IntakeFlowchartActionIcon: FC<IIntakeFlowchartActionIcon> = ({ slug }) => {
  if (!slug) return null

  // Get the logo based on the slug
  const LogoToRender = logoMap[slug]
  return LogoToRender ? <img className="w-8 h-8" src={LogoToRender} alt={`${slug} logo`} /> : null
}

export default IntakeFlowchartActionIcon
