import { useTranslation } from 'react-i18next'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import useGetCurrentWorkflow from '../../../hooks/useGetCurrentWorkflow'
import useDuplicateIntake from '../../../hooks/useDuplicateIntake'

const IntakeSidePanelActiveStepRestart = () => {
  const { data: activeWorkflow } = useGetCurrentWorkflow()
  const { mutate: duplicateIntake } = useDuplicateIntake()

  const { t } = useTranslation()

  if (!['declined', 'completed', 'declined_by_requester'].includes(activeWorkflow?.status || '')) return null

  return (
    <Button
      className="btn-primary w-full"
      onClick={() => activeWorkflow?.entity.uuid && duplicateIntake(activeWorkflow?.entity.uuid)}
    >
      {t('generic.restart', 'Restart')}
    </Button>
  )
}

export default IntakeSidePanelActiveStepRestart
