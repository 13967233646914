import { ReactNode } from 'react'
import { INodeChildren } from '../../../types/shared/GeneralTypes'
import { useLocalStorage } from '../../providers/LocalStorageProvider'

interface ICoreTopbarLayout extends INodeChildren {
  topbar: ReactNode
}

const CoreTopbarLayout: React.FC<ICoreTopbarLayout> = ({ topbar, children }) => {
  const [getLocalStorage] = useLocalStorage()

  const minified = getLocalStorage('sidebarMinified', 'true')

  return (
    <div className="flex w-full min-h-screen ">
      <div className="w-full h-auto overflow-auto flex right-0 flex-col">
        <div className={`${minified ? 'w-[calc(100vw-5rem)]' : 'w-[calc(100vw-13em)]'} z-50 fixed`}>{topbar}</div>
        <div className="pt-28">{children}</div>
      </div>
    </div>
  )
}
export default CoreTopbarLayout
