import { useHistory } from 'react-router'

export default () => {
  const history = useHistory()
  return (url: string, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.ctrlKey || e.metaKey || e.button === 1) {
      window.open(url)
    } else {
      history.push(url)
    }
  }
}
