import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FileType } from '../../../../types/shared/FileTypes'
import { MediaType } from '../../../../types/shared/MediaTypes'
import BaseInputWrapper from '../../atoms/Input/BaseInputWrapper'
import { RemoveUploadedFileFunctionType } from '../../layout/SharedFileUploadPill/SharedFileUploadPillTypes'
import './TipTapEditor.scss'
import TipTapEditorFilesOnTop from './TipTapEditorFilesOnTop'
import { TipTapOnBlurPropType } from './TipTapEditorTypes'
import TipTapEditorWithFilesOnBottom from './TipTapEditorWithFilesOnBottom'

export interface ITipTapEditor {
  label?: string
  content?: string
  placeholder?: string
  onBlur?: TipTapOnBlurPropType
  editable?: boolean
  editorContentClassName?: string
  onEnterShift?: () => void
  onUpdate?: (text: string) => void
  handleAddFiles?: (files: FileList) => void
  handleDeleteFile?: RemoveUploadedFileFunctionType
  files?: FileType[] | MediaType[]
  className?: string
  expandedEditor?: boolean
  filesOnTop?: boolean
  dataCy?: string
  messageReply?: boolean
  isSending?: boolean
  noFocus?: boolean
  customPlaceholder?: string
}

const TipTapEditor: React.FC<ITipTapEditor> = ({
  onBlur,
  handleDeleteFile,
  placeholder,
  onUpdate,
  handleAddFiles,
  onEnterShift,
  className = 'w-full ',
  content = '',
  files,
  editable = true,
  expandedEditor = true,
  filesOnTop,
  dataCy,
  isSending,
  editorContentClassName,
  messageReply,
  label,
  noFocus,
  customPlaceholder,
}) => {
  const { t } = useTranslation()

  const handleKeyDown = (_: any, event: KeyboardEvent) => {
    if (event.key === 'Enter' && event.shiftKey && !!onEnterShift) {
      onEnterShift()
      return true
    }
    return false
  }

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Placeholder.configure({ placeholder: customPlaceholder || t(placeholder || 'generic.write_something') }),
    ],
    content,
    editable,
    onUpdate: ({ editor: descriptionData }) => onUpdate && onUpdate(descriptionData.getHTML()),
    editorProps: {
      handleKeyDown,
    },
    onBlur: (e) => onBlur?.(e),
  })

  useEffect(() => {
    if (content !== editor?.getHTML()) {
      editor?.commands.setContent(content)
    }
  }, [content, content?.length])

  useEffect(() => {
    editor?.setOptions({
      editorProps: {
        handleKeyDown,
      },
    })
  }, [handleKeyDown, content])

  useEffect(() => {
    editor?.off('update')
    editor?.on('update', ({ editor: descriptionData }) => onUpdate && onUpdate(descriptionData.getHTML()))
  }, [onUpdate])

  return (
    <BaseInputWrapper label={label}>
      {/* {editor && editable && <TipTapEditorBubbleMenu editor={editor} />} */}
      <TipTapEditorFilesOnTop
        filesOnTop={filesOnTop}
        messageReply={messageReply}
        isSending={isSending}
        files={files}
        handleDeleteFile={handleDeleteFile}
      />
      <TipTapEditorWithFilesOnBottom
        files={files}
        filesOnTop={filesOnTop}
        handleDeleteFile={handleDeleteFile}
        isSending={isSending}
        className={className}
        editor={editor}
        expandedEditor={expandedEditor}
        dataCy={dataCy}
        handleAddFiles={handleAddFiles}
        editorContentClassName={editorContentClassName}
        editable={editable}
        noFocus={noFocus}
      />
    </BaseInputWrapper>
  )
}

export default TipTapEditor
