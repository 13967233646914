import { WorkflowBlockTrigger } from '../../../../../../../types/Workflow/WorkflowBlock'
import useGetInputTypes from '../../../../../../Forms/hooks/useGetInputTypes'
import { useWorkflow } from '../../../../../providers/WorkflowProvider'
import useWorkflowBlockDraftMutations from '../../../../../hooks/useWorkflowBlockDraftMutations'
import FormFieldPlaceholder from '../../../../../../Forms-v2/FormFieldPlaceholder'
import FormField from '../../../../../../Forms-v2/FormField'
import { InputType } from '../../../../../../../types/shared/InputType'
import { fromFieldValue, toFieldValue } from '../../../../../../Forms-v2/helpers'

export const TriggerValueInput = ({ trigger }: { trigger: WorkflowBlockTrigger }) => {
  const { data: inputs } = useGetInputTypes()
  const { readonly } = useWorkflow()
  const { updateTrigger } = useWorkflowBlockDraftMutations()

  if (!inputs || inputs.length === 0) return <FormFieldPlaceholder />

  const originalInput = inputs.find((input) => input.uuid === trigger.input_reference) || inputs[0]

  const input = { ...inputs[0], ...originalInput, type: trigger.input_type }

  if (input.type === 'FILE') return <div className="w-full" />

  return (
    <FormField
      className="w-full"
      field={{
        id: 'value',
        input: input as unknown as InputType,
        options: undefined,
        value: toFieldValue(trigger.value, input),
        media: null,
        disabled: readonly,
      }}
      updateField={({ value }) => {
        updateTrigger({ ...trigger, value: fromFieldValue(value, input) })
      }}
    />
  )
}

export default TriggerValueInput
