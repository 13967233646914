// REFACTOR - GENERIC
import _ from 'lodash'
import { ChangeEventHandler, useState } from 'react'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import { germanPriceStringToFloat } from '../../../../../../shared/constants/currencyFormat'
import QuestionInputNumber from '../../components/inputs/QuestionInputNumber'
import useUpdateQuestionSetting from '../../../hooks/useUpdateQuestionSetting'

interface IDefinedNumberInput {}

const DefinedNumberInput: React.FC<IDefinedNumberInput> = () => {
  const {
    question: [question],
  } = useFormContext()
  const updateQuestionSettings = useUpdateQuestionSetting()

  const number = question?.__settings?.answers?.min || 1

  const [shouldUpdate, setShouldUpdate] = useState<string>('')

  const totalChoices = question?.options?.length

  const update = (numberOfChoices: number) => {
    updateQuestionSettings({
      answers: {
        min: numberOfChoices,
        max: numberOfChoices,
      },
    })
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const parsedNumber = germanPriceStringToFloat(e.target.value) ?? undefined

    if (parsedNumber !== undefined && totalChoices && parsedNumber > totalChoices) {
      update(totalChoices)
    } else if (parsedNumber === 0) {
      update(1)
    } else {
      update(parsedNumber)
    }

    setShouldUpdate(_.uniqueId())
  }

  return (
    <div className="w-fit">
      <QuestionInputNumber
        precision={0}
        value={number}
        onBlur={onChange}
        forceUpdate={shouldUpdate}
        className="max-w-[200px]"
        placeholder="Maximum"
      />
    </div>
  )
}

export default DefinedNumberInput
