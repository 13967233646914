import { useTranslation } from 'react-i18next'
import useGetCurrentWorkflow from '../../../hooks/useGetCurrentWorkflow'

const IntakeSidePanelActiveDeclinedMessage = () => {
  const { data } = useGetCurrentWorkflow()
  const { t } = useTranslation()

  const declinedBlock = data?.steps.flatMap((step) => step.blocks).find((block) => block.status === 'declined')

  return (
    <div className="bg-red-light text-white p-4 text-sm my-4 rounded-lg flex flex-col gap-y-2">
      <div className="font-semibold">
        {declinedBlock?.name} {t('generic.declined', 'declined')}:
      </div>
      <div className="italic">{declinedBlock?.status_comment || 'No comment'}</div>
    </div>
  )
}

export default IntakeSidePanelActiveDeclinedMessage
