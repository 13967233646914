import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@heroicons/react/24/outline'
import React from 'react'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import useIsCompanyScreenLoading from '../General/documents/hooks/useIsCompanyScreenLoading'
import BaseSettingsSection from '../../components/BaseSettingsSection'
import { USER_ADD } from '../../../../shared/constants/permissions'
import CompanyUsersNew from './CompanyUsersNew'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import usePermissions from '../../../../core/hooks/usePermissions'
import Button from '../../../../shared/components/atoms/buttons/Button'
import UsersTable from './UserTable'

const UsersTab = () => {
  const { t } = useTranslation()
  const isLoading = useIsCompanyScreenLoading()
  const { modal } = useUtils()
  const permissions = usePermissions([{ slug: USER_ADD }])
  if (isLoading) return <CoreLoadingIcon />

  const triggerUsersModal = () => {
    modal.set({
      isOpen: true,
      title: 'account.create_new_user',
      content: <CompanyUsersNew />,
    })
  }

  return (
    <BaseSettingsSection
      title={t('generic.user_plural', 'Users')}
      description={t('generic.users_description', 'List of users in your company.')}
      action={
        <div className="w-full ">
          {permissions.get(USER_ADD).allowed && (
            <Button variant="solid" color="sky-blue" onClick={triggerUsersModal}>
              <PlusIcon className="w-4" style={{ strokeWidth: 3 }} />
              {t('account.add_user')}
            </Button>
          )}
        </div>
      }
    >
      <UsersTable />
    </BaseSettingsSection>
  )
}

export default UsersTab
