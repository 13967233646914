import { FormQuestionContentType } from '../../types/Forms'

import { IntakeFormFieldValuesProps } from '../Intake/IntakeOverview/components/IntakeSections/IntakeFormField'
import FormFieldDisplayGeneric from './FormFieldDisplay/FormFieldDisplayGeneric'
import FormFieldDisplayMedia from './FormFieldDisplay/FormFieldDisplayMedia'
import FormFieldInput from './FormFieldInput'
import { useFormField } from './providers/FormFieldProvider'
import ErrorMessage from '../../shared/components/atoms/ErrorMessage'
import FormFieldDisplayMultiple from './FormFieldDisplay/FormFieldDisplayMultiple'

const FormFieldDisplayComponents: Partial<Record<FormQuestionContentType, React.FC<IntakeFormFieldValuesProps>>> = {
  FILE: FormFieldDisplayMedia,
  MULTI_SELECT: FormFieldDisplayMultiple,
}

const getFormFieldDisplayComponent = (type: FormQuestionContentType) =>
  FormFieldDisplayComponents[type] || FormFieldDisplayGeneric

export const FormFieldDisplay = () => {
  const {
    field: {
      input: { type },
      error,
    },
  } = useFormField()
  const DisplayComponent = getFormFieldDisplayComponent(type)

  return (
    <div className={`flex flex-col gap-2 ${error ? 'border-l-2 border-l-red-500 pl-4' : null}`}>
      <DisplayComponent />
      {error ? (
        <div className="w-full">
          <ErrorMessage error={error} />
        </div>
      ) : null}
    </div>
  )
}

export default FormFieldInput
