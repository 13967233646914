import { useMutation, useQueryClient } from 'react-query'
import { toastr } from '../../../../../shared/components/organisms/toastr'
import { undef } from '../../../../../types/shared/GeneralTypes'
import keys from '../../../constants/SuppliersQueryKeys'
import SupplierUserType from '../../../../../types/Suppliers/SupplierUserType'
import { apiDeleteSupplierBranchUser } from '../../../services/SuppliersService'

const useDeleteUserMutation = (supplierGroupId: number, supplierBranchId: number, user: SupplierUserType) => {
  const cache = useQueryClient()

  return useMutation(
    (userId: number) =>
      apiDeleteSupplierBranchUser({
        supplierGroupId,
        supplierBranchId,
        userId,
      }),
    {
      onSuccess: () => {
        toastr.success('success.item_deleted')
        cache.setQueryData<undef<SupplierUserType[]>>(
          [keys.SUPPLIER_USERS, supplierBranchId],
          (oldData: undef<SupplierUserType[]>) =>
            oldData
              ? oldData.map((usr) => (usr.id === user.id ? { ...usr, deleted_at: JSON.stringify(new Date()) } : usr))
              : oldData
        )
      },
    }
  )
}

export default useDeleteUserMutation
