import { useMutation, useQueryClient } from 'react-query'
import useCurrentExternalDataSourceUuid from './useCurrentExternalDataSourceUuid'
import { ExternalDataRelationship } from '../../../types/ExternalDataManager/ExternalDataManagerTypes'
import { apiSaveExternalDataRelationship } from '../services/ExternalDataServices'

export const useExternalDataRelationshipMutations = () => {
  const externalDataUuid = useCurrentExternalDataSourceUuid()
  const cache = useQueryClient()

  const cacheKey = ['entities', externalDataUuid, 'relationships']
  const invalidate = async () => {
    if (!externalDataUuid) return
    await cache.invalidateQueries(cacheKey)
  }

  const create = useMutation(
    (newRelationship: ExternalDataRelationship) => apiSaveExternalDataRelationship(newRelationship),
    {
      mutationKey: cacheKey,
      onSettled: invalidate,
    }
  )

  const remove = useMutation(
    (newRelationship: Pick<ExternalDataRelationship, 'source_column' | 'source_uuid'>) =>
      apiSaveExternalDataRelationship({ ...newRelationship, target_column: '', target_uuid: '' }),
    {
      mutationKey: cacheKey,
      onSettled: invalidate,
    }
  )

  return {
    create: create.mutateAsync,
    update: create.mutateAsync,
    remove: remove.mutateAsync,
    isProcessing: cache.isMutating({ mutationKey: cacheKey }) > 0,
  }
}

export default useExternalDataRelationshipMutations
