import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Button from '../buttons/Button'

export type BackButtonProps = {
  name?: string
  onBack?: () => void
}

export const BackButton = ({ name, onBack }: BackButtonProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleBack = () => {
    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }

  return (
    <Button className="text-black-light-2 !p-0 text-sm w-fit" onClick={handleBack}>
      <ChevronLeftIcon className="h-[10px]" />
      {name || t('generic.back', 'Back')}
    </Button>
  )
}

export default BackButton
