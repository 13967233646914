import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from '../../../../../core/components/Table/Table'
import { dateTimeFormatter } from '../../../../../shared/constants/dateFormat'
import Menu from '../../../../../shared/components/organisms/menus/Menu'
import { useWorkflowMutations } from '../../../../Workflow/hooks/mutations'
import useOpenWorkflowBuilder from '../../../../Workflow/hooks/useOpenWorkflowBuilder'
import useOpenWorkflowDetails from '../../../../Workflow/hooks/useOpenWorkflowDetails'
import { Workflow } from '../../../../../types/Workflow/Workflow'

type WorkflowsTableRowProps = {
  workflow: Workflow
}

const WorkflowTableRow = ({ workflow }: WorkflowsTableRowProps) => {
  const { t } = useTranslation()
  const { remove } = useWorkflowMutations()
  const goToWorkflowBuilder = useOpenWorkflowBuilder(workflow)
  const goToWorkflowDetails = useOpenWorkflowDetails(workflow)
  return (
    <TableRow
      key={`schema-${workflow.uuid}`}
      className="h-16 hover:bg-gray-200/25 text-black-light hover:cursor-pointer"
      onClick={() => goToWorkflowDetails()}
    >
      <TableCell className="first-letter:uppercase">{workflow.name}</TableCell>
      <TableCell className="">{dateTimeFormatter(workflow.created_at, false)}</TableCell>
      <TableCell className="first-letter:uppercase">{`V${workflow.version}`}</TableCell>
      <TableCell className="first-letter:uppercase">
        {workflow.published_at && dateTimeFormatter(workflow.published_at, false)}
      </TableCell>
      <TableCell>
        <Menu
          menuItems={[
            { name: t('general.edit', 'Edit'), onClick: goToWorkflowBuilder },
            { name: t('general.delete', 'Delete'), onClick: () => remove(workflow) },
          ]}
        />
      </TableCell>
    </TableRow>
  )
}

export default WorkflowTableRow
