import { useMutation, useQueryClient } from 'react-query'
import SupplierBranchDataType from '../../../types/Suppliers/SupplierBranchDataType'
import keys from '../../Suppliers/constants/SuppliersQueryKeys'
import { apiPutSupplierBranch } from '../../Suppliers/services/SupplierBranchesService'

const useUpdateBranchMutation = (supplierBranchId: number | undefined, onSuccess?: () => any) => {
  const cache = useQueryClient()

  return useMutation(
    ({ body, supplierId }: { body: Partial<SupplierBranchDataType>; supplierId: number }) =>
      supplierBranchId ? apiPutSupplierBranch(supplierId, supplierBranchId, body) : new Promise(() => {}),
    {
      onSuccess: (newData) => {
        cache.setQueryData([keys.SUPPLIER_BRANCH, supplierBranchId], newData)
        onSuccess?.()
      },
    }
  )
}

export default useUpdateBranchMutation
