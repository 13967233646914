import { useTranslation } from 'react-i18next'
import { getValidationSchema, ValidationPropertyConfig } from './ValidationSchemas'
import type { ValidationProperty, ValidationConfig } from './ValidationSchemas'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import useUpdateQuestionValidation from '../../../hooks/useUpdateQuestionValidation'
import NumberValidationProperty from './ValidationProperties/NumberValidationProperty'
import QuestionSection from '../../components/QuestionSection'
import BooleanValidationProperty from './ValidationProperties/BooleanValidationProperty'
import CharacterLimitSetting from '../OtherSettings/CharacterLimitSetting'
import { FormQuestionContentType } from '../../../../../../types/Forms'
import NumberOrSameTypeQuestionValidationProperty from './ValidationProperties/NumberOrSameTypeQuestionValidationProperty'

export type ValidationSettingsProps = {
  config?: ValidationConfig
}

export const ValidationSettings = ({ config }: ValidationSettingsProps) => {
  const {
    question: [question],
  } = useFormContext()
  const updateValidation = useUpdateQuestionValidation()
  const { t } = useTranslation()
  const typesWithCharactersLimit: FormQuestionContentType[] = ['SELECT', 'MULTI_SELECT', 'TEXT_AREA', 'TEXT']

  if (!question) return null

  const getPropertyComponent = ({ type, allowsQuestionAsValue }: ValidationPropertyConfig) => {
    if (type === 'NUMERIC')
      return allowsQuestionAsValue ? NumberOrSameTypeQuestionValidationProperty : NumberValidationProperty
    return BooleanValidationProperty
  }

  const renderPropertyComponent = ([validationProperty, settings]: [ValidationProperty, ValidationPropertyConfig]) => {
    const PropertyComponent = getPropertyComponent(settings)
    return (
      <PropertyComponent
        question={question}
        key={`${question?.__uuid}-validation-${validationProperty}`}
        config={settings}
        property={validationProperty as ValidationProperty}
        onUpdate={(updated) => updateValidation({ [validationProperty]: updated })}
      />
    )
  }

  const enabledProperties = (
    Object.entries(config || getValidationSchema(question?.type)) as [ValidationProperty, ValidationPropertyConfig][]
  ).filter(([, { enabled, type }]) => enabled)

  const questionValidationProperties = enabledProperties.filter(([, { scope }]) => scope === 'question')

  const fieldValidationProperties = enabledProperties.filter(([, { scope }]) => scope === 'field')

  return (
    <QuestionSection title={t('forms.questions.settings.validation')}>
      {questionValidationProperties.map(renderPropertyComponent)}
      {(fieldValidationProperties.length > 0 || typesWithCharactersLimit.includes(question?.type)) && (
        <p className="font-medium">{t('forms.questions.settings.question_validation')}</p>
      )}
      {fieldValidationProperties.map(renderPropertyComponent)}
      {typesWithCharactersLimit.includes(question?.type) && <CharacterLimitSetting />}
    </QuestionSection>
  )
}

export default ValidationSettings
