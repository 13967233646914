import { ChangeEvent, FC, KeyboardEventHandler } from 'react'
import { NumericFormat } from 'react-number-format'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import SearchSelect from '../../atoms/SearchSelect/SearchSelect'
import priceQuestionOptions from './priceQuestionHelpers'

interface IPriceInput {
  disabled?: boolean
  value?: string
  onChange?: (value: number) => void
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  placeholder?: string
  currentCurrency?: string
  onCurrencyChange: (selection?: InputSearchSelectItemType) => void
  defaultValue?: string
}

const PriceInput: FC<IPriceInput> = ({
  disabled,
  value,
  onChange,
  onBlur,
  onKeyDown,
  placeholder,
  currentCurrency,
  onCurrencyChange,
  defaultValue,
}) => {
  const currentCurrencySelection: InputSearchSelectItemType =
    priceQuestionOptions.find((option) => option.id === currentCurrency) || priceQuestionOptions[0]

  return (
    <div className="flex relative w-full">
      <NumericFormat
        required={false}
        thousandsGroupStyle="thousand"
        value={value}
        valueIsNumericString
        onValueChange={(values) => {
          onChange && onChange(values.floatValue || 0)
        }}
        disabled={disabled}
        decimalSeparator=","
        thousandSeparator="."
        displayType="input"
        type="text"
        decimalScale={2}
        fixedDecimalScale
        className={`${disabled ? 'bg-transparent' : ''} base-form-input  date-input !border-r-0 !rounded-r-none `}
        onBlur={onBlur}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        allowNegative={false}
        defaultValue={defaultValue}
      />
      <SearchSelect
        options={priceQuestionOptions}
        currentSelection={currentCurrencySelection}
        onChange={onCurrencyChange}
        inputClassNames="!rounded-l-none"
      />
    </div>
  )
}
export default PriceInput
