import cn from '../../../lib/util'

type BaseSettingsSectionProps = {
  title: string
  description?: string
  action?: React.ReactNode
  children: React.ReactNode
}
const BaseSettingsSection = ({ title, description, action, children }: BaseSettingsSectionProps) => (
  <div className={cn('w-full flex flex-col space-y-9 p-10 shadow-md shadow-gray-100/10 rounded-md bg-white')}>
    <div className="flex justify-between items-center space-x-6">
      <div className="flex flex-col space-y-2.5 w-9/12">
        <div className="text-gray-600 font-semibold text-sm">{title}</div>
        <div className={`text-gray-500 text-sm font-medium ${!description ? 'hidden' : ''}`}>{description}</div>
      </div>
      <div className={`${!action ? 'hidden' : ''}`}>{action}</div>
    </div>
    <div className={cn('w-full h-full')}>{children}</div>
  </div>
)

export default BaseSettingsSection
