import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import ConfirmationModal from '../../../../../../shared/components/organisms/modals/ConfirmationModal'
import useActiveWorkflowMutation from '../../../hooks/useActiveWorkflowMutations'
import useGetCurrentWorkflow from '../../../hooks/useGetCurrentWorkflow'
import useGetCurrentIntake from '../../../hooks/useGetCurrentIntake'
import useGetCurrentUser from '../../../../../../core/hooks/useGetCurrentUser'

const AbortRequestButton = () => {
  const { modal } = useUtils()
  const { t } = useTranslation()
  const {
    update: { mutate },
  } = useActiveWorkflowMutation()
  const { data } = useGetCurrentWorkflow()
  const { data: intakeFlow } = useGetCurrentIntake()
  const { data: currentUser } = useGetCurrentUser()

  if (['declined_by_requester', 'completed'].includes(data?.status || '') || !data?.uuid) return null

  const abortRequest = async () => {
    if (!data?.uuid) return

    mutate({
      active_workflow_uuid: data?.uuid,
      action: 'declined_by_requester',
      workflow_block_uuid: data.active_blocks[0].uuid,
    })

    modal.close()
  }

  const handleAbortRequest = () => {
    modal.set({
      isOpen: true,
      title: t('generic.abort-request', 'Abort request'),
      content: (
        <ConfirmationModal onConfirm={abortRequest}>
          <div>{t('generic.confirm-abort', 'Are you sure that you want to abort the Request?')}</div>
        </ConfirmationModal>
      ),
    })
  }

  if (intakeFlow?.creator.email !== currentUser?.email) return null

  return (
    <Button onClick={handleAbortRequest} className="btn-ghost border-sky-blue-light text-sky-blue-light border">
      {t('generic.abort-request', 'Abort request')}
    </Button>
  )
}
export default AbortRequestButton
