import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { IntakeType } from '../../../../types/Intake'
import useIntakeFormStore from '../stores/useIntakeFormStore'
import useGetIntake from '../../IntakeOverview/hooks/useGetIntake'
import { putIntake, putIntakeSubmitDraft } from '../../IntakeOverview/services'

export const useIntakeFormMutations = () => {
  const intake = useIntakeFormStore((state) => state.intake)
  const resetStore = useIntakeFormStore((state) => state.reset)
  const history = useHistory()

  const { refetch } = useGetIntake(intake?.uuid || '')
  const client = useQueryClient()

  const submitDraft = useMutation(() => putIntakeSubmitDraft(intake!.uuid), {
    onError: (error) => {
      console.error(error)
    },
    onSuccess: () => {
      // navigate to the intake page
      client.invalidateQueries(['intake', intake!.uuid]).then(() => {
        resetStore()
        history.push(`/intake-flow/${intake!.uuid}`)
      })
    },
  })

  const update = useMutation(() => putIntake(intake!.uuid, intake!), {
    onMutate: async () => {
      await client.cancelQueries(['intake', intake!.uuid])
      const prevIntake = client.getQueryData<IntakeType>(['intake', intake!.uuid])
      if (!intake) return { prevIntake }
      client.setQueryData<IntakeType>(['intake', intake!.uuid], intake)
      client.invalidateQueries(['intake', intake.uuid, 'logs'])
      return { prevIntake }
    },
  })

  return { submitDraft, update }
}

export default useIntakeFormMutations
