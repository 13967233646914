import { useMutation } from 'react-query'
import { useMemo } from 'react'
import type { SetRequired } from 'type-fest'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'
import { Workflow } from '../../../../types/Workflow/Workflow'
import { useWorkflowInvalidate } from './useWorkflowInvalidate'
import { WorkflowAction, WorkflowActionWrapped } from '../../../../types/Workflow/WorkflowAction'
import useFindWorkflowBlockStep from '../useFindWorkflowBlockStep'
import {
  ActionBlockRequestIdentifiers,
  assignActionToBlock,
  deleteWorkflowBlockAction,
  updateWorkflowBlockAction,
} from '../../services'

export const useWorkflowBlockMutations = (
  workflow: Workflow | undefined,
  block: Pick<WorkflowBlock, 'uuid'> | undefined | null
) => {
  const findBlockStep = useFindWorkflowBlockStep(workflow)
  const invalidate = useWorkflowInvalidate(workflow)

  const retrieveMutationDetails = (): ActionBlockRequestIdentifiers => {
    if (!workflow) throw new Error('Workflow is undefined')
    const step = findBlockStep(block)
    if (!step) throw new Error('Step is undefined')
    if (!block) throw new Error('Block is undefined')

    return {
      workflowUuid: workflow.uuid,
      workflowVersion: workflow.version!,
      workflowStepUuid: step.uuid,
      workflowBlockUuid: block.uuid,
    }
  }

  const removeBlockAction = useMutation(
    (action: Pick<WorkflowActionWrapped, 'uuid'>) => deleteWorkflowBlockAction(retrieveMutationDetails(), action.uuid),
    {
      onSettled: invalidate,
    }
  )

  const addActionToBlock = useMutation(
    (action: WorkflowAction) => assignActionToBlock(retrieveMutationDetails(), action),
    {
      onSettled: invalidate,
    }
  )

  const updateBlockAction = useMutation(
    ({
      uuid,
      action,
      force_pending,
    }: SetRequired<Pick<WorkflowActionWrapped, 'uuid' | 'action' | 'force_pending'>, 'action'>) =>
      updateWorkflowBlockAction(retrieveMutationDetails(), uuid, { ...action, force_pending }),
    {
      onSettled: invalidate,
    }
  )

  return useMemo(
    () => ({
      addAction: addActionToBlock.mutateAsync,
      updateAction: updateBlockAction.mutateAsync,
      removeAction: removeBlockAction.mutateAsync,
      isProcessing: addActionToBlock.isLoading || updateBlockAction.isLoading || removeBlockAction.isLoading,
    }),
    [workflow]
  )
}

export default useWorkflowBlockMutations
