import { UsersIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import WorkflowBlockEntry from '../../../misc/WorkflowBlockEntry'
import { WorkflowBlock } from '../../../../../../types/Workflow/WorkflowBlock'

export const WorkflowBlockApproversCounter = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()

  return (
    <WorkflowBlockEntry
      className="w-1/2"
      Icon={UsersIcon}
      title={t('intake.flowchart.approvalNodeContent.approver_counter', 'Approvers')}
    >
      {block.approvers.length}{' '}
      {t(
        'intake.flowchart.approvalNodeContent.number_of_approvers',
        block.approvers.length > 1 ? 'approvers' : 'approver'
      )}
    </WorkflowBlockEntry>
  )
}

export default WorkflowBlockApproversCounter
