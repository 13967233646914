import TipTapEditor from '../../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import { MessageType } from '../../../../../../types/Messenger/types'
import ReplyLink from '../../IntakeReplyPreview/ReplyLink'

interface IMessageBody {
  message: MessageType
}

const MessageBody: React.FC<IMessageBody> = ({ message }) => {
  const formattedFiles = message.files?.map((file) => ({
    url: file.url,
    name: file.name,
  }))

  return (
    <div className="flex flex-col w-full">
      {message.reference && (
        <div className="mt-2">
          <ReplyLink referenceMessage={message.reference} />
        </div>
      )}

      <div className="text-sm">
        <TipTapEditor
          content={message.text_message}
          className="text-sm mr-12 font-light shadow-none border-none"
          expandedEditor
          editable={false}
          editorContentClassName="pl-0 pr-0 shadow-none border-none"
          files={formattedFiles}
        />
      </div>
    </div>
  )
}

export default MessageBody
