import { useMutation, useQueryClient } from 'react-query'
import { toastr } from '../../../../../shared/components/organisms/toastr'
import { UserType } from '../../../../../types/shared/AssignmentTypes'
import queryKeys from '../constants/settingsQueryKeys'
import { apiUpdateUserLanguage } from '../services/SettingsService'
import useSwitchLanguage from './useSwitchLanguage'

export default () => {
  const cache = useQueryClient()
  const switchLanguage = useSwitchLanguage()

  return useMutation(apiUpdateUserLanguage, {
    onSuccess: (updatedUser) => {
      toastr.success('success.language_updated', 'successLanguageUpdate')
      cache.setQueryData<UserType | undefined>([queryKeys.USER_PROFILE], (oldData: UserType | undefined) =>
        oldData ? { ...oldData, language: updatedUser.language } : oldData
      )
      switchLanguage(updatedUser.language)
    },
    onError: () => {
      toastr.error('error.language_update', 'errorLanguageUpdate')
    },
  })
}
