/* eslint-disable no-param-reassign */

import { cloneDeep } from 'lodash'
import { Node } from 'reactflow'
import { WorkflowChartHelperNode, WorkflowChartNode } from '../../../types/Workflow/WorkflowChart'

type Position = { x: number; y: number }

const getNodeCenter = (node: Node) => ({
  x: (node.width || 0) / 2,
  y: (node.height || 0) / 2,
})

export default (
  nodes: WorkflowChartNode[],
  { startPosition = { x: 0, y: 0 }, columnsGap = 100 }: { startPosition?: Position; columnsGap?: number }
) => {
  const nodesWorkingCopy = cloneDeep(nodes)
  const currentPosition = { x: startPosition.x, y: 0 }
  const helpersGap = { x: columnsGap / 2, y: columnsGap / 2 }

  const updateNode = (node: WorkflowChartNode) => {
    node.position = {
      x: currentPosition.x,
      y: startPosition.y - (node.height || 0) / 2,
    }
    currentPosition.x = node.position.x + (node.width || 0) + columnsGap
  }

  const updateNodePositionAndDimensions = (node: WorkflowChartNode) => {
    if (node.parentNode) return

    updateNode(node)
  }

  const numberOfColumns = nodesWorkingCopy.filter(({ type }) => type === 'GROUP').length

  const updateHelperNodesPosition = (node: WorkflowChartHelperNode) => {
    const targetNode = nodesWorkingCopy.find(({ id }) => id === node.data.target?.uuid)

    if (!targetNode) {
      if (node.data.position < 1) {
        node.position = {
          x: startPosition.x - helpersGap.x - (node.width || 0),
          y: startPosition.y - getNodeCenter(node).y,
        }
      } else if (node.data.position > numberOfColumns) {
        node.position = { x: currentPosition.x - columnsGap + helpersGap.x, y: startPosition.y - getNodeCenter(node).y }
      }

      return
    }

    node.position = {
      x: targetNode.position.x + getNodeCenter(targetNode).x - getNodeCenter(node).x,
      y: targetNode.position.y + (targetNode.height || 0) + helpersGap.y,
    }
  }

  nodesWorkingCopy.filter(({ type }) => type !== 'HELPER').forEach(updateNodePositionAndDimensions)

  nodesWorkingCopy
    .filter((node): node is WorkflowChartHelperNode => node.type === 'HELPER')
    .forEach(updateHelperNodesPosition)

  return nodesWorkingCopy
}
