import CorePageContainerLayout from '../../../core/components/layout/CorePageContainerLayout'
import SuppliersTable from './SuppliersTable/SuppliersTable'

const SuppliersContainer: React.FC = () => (
  <CorePageContainerLayout className="flex justify-center flex-wrap">
    <div className="flex flex-col w-full 2xl:w-[1000px]">
      <SuppliersTable />
    </div>
  </CorePageContainerLayout>
)
export default SuppliersContainer
