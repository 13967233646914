import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toastr } from '../../../../../shared/components/organisms/toastr'
import { UserType } from '../../../../../types/shared/AssignmentTypes'
import keys from '../constants/settingsQueryKeys'
import { apiGetUser, apiUpdateUser } from '../services/SettingsService'

export default () => {
  const cache = useQueryClient()
  const { data: userData } = useQuery([keys.USER_PROFILE], apiGetUser)

  return useMutation((data: Partial<UserType>) => apiUpdateUser({ userId: userData?.id as number, data }), {
    onSuccess: (newData: UserType) => {
      cache.setQueryData([keys.USER_PROFILE], () => {
        toastr.success('user_profile.updated_successfully', 'userProfileUpdated')
        return newData
      })
      !!cache.invalidateQueries([keys.USER_PROFILE])
    },
    onError: () => {
      toastr.error('error._item_not_updated', 'userProfileUpdateError')
    },
  })
}
