import classnames from 'classnames'
import useBlockStatus from './hooks/useBlockStatus'
import { WorkflowChartNodeTemplate } from '../../../../../../WorkflowChart'
import { WorkflowChartBlockNodeProps } from '../../../../../../../types/Workflow/WorkflowChart'
import { useIntakeFlow } from '../../../../providers/IntakeFlowProvider'
import IntakeFlowchartNodeIcon from './components/IntakeFlowchartNodeIcon'
import IntakeFlowchartApprovalNodeContent from './IntakeFlowchartNodeContent/IntakeFlowchartApprovalNodeContent'
import IntakeFlowchartActionNodeContent from './IntakeFlowchartNodeContent/IntakeFlowchartActionNodeContent'
import IntakeFlowchartActionIcon from './components/IntakeFlowchartActionIcon'

const blockContentComponents = {
  APPROVAL: IntakeFlowchartApprovalNodeContent,
  APPROVER: IntakeFlowchartApprovalNodeContent,
  ACTION: IntakeFlowchartActionNodeContent,
  GROUP: undefined,
  HELPER: undefined,
}

export const IntakeFlowchartNode = (node: WorkflowChartBlockNodeProps) => {
  const { data } = node
  const Content = blockContentComponents[data.type || 'APPROVAL']
  const { toggleBlockInspection } = useIntakeFlow()

  const { requiresCurrentUserApproval } = useBlockStatus(data)

  const handleClick = () => {
    toggleBlockInspection(data)
  }

  return (
    <WorkflowChartNodeTemplate
      node={node}
      className={classnames({ 'border border-sky-blue-light': requiresCurrentUserApproval })}
      onClick={handleClick}
    >
      <div className="flex flex-row border-b border-b-ice-blue pb-2 w-full items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <IntakeFlowchartNodeIcon block={data} />
          <div className="flex-grow-1 font-bold">{data.name}</div>
        </div>
        <IntakeFlowchartActionIcon slug={data.actions?.[0]?.slug || null} />
      </div>
      <div className="w-full">{Content && <Content block={data} />}</div>
    </WorkflowChartNodeTemplate>
  )
}

export default IntakeFlowchartNode
