interface IButtonIcon {
  onClick?: () => void
  className?: string
  icon: React.ComponentType<any>
  variant?: string
}

const ButtonIcon: React.FC<IButtonIcon> = ({ onClick, className, icon: Icon, variant }) => {
  const variantStyles =
    variant === 'transparent'
      ? 'text-transparent group-hover:text-sky-blue hover:text-sky-blue '
      : 'hover:text-sky-blue text-gray-400'

  const classNames = `
  focus:text-indigo-50
  p-2  text-2xl
  ${variantStyles}
  ${className}`

  if (!Icon) return <> </>

  return onClick ? (
    <button onClick={onClick} type="button" className={classNames}>
      <Icon size={20} />
    </button>
  ) : (
    <div className={classNames}>
      <Icon />
    </div>
  )
}
export default ButtonIcon
