import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import DropzoneArea from '../../../../../../../../../shared/components/atoms/DropzoneArea'
import MediaTable from '../../../../../../../../../shared/components/atoms/FileUpload/MediaTable'
import { Offer } from '../../../../../../../../../types/Offering'
import { offerMediaService } from '../../../../../../../../../shared/utils/attachmentsService'
import { MediaType } from '../../../../../../../../../types/shared/MediaTypes'

type IProductSectionOfferModalFiles = {
  offer?: Offer | Partial<Offer> | null | undefined
  onFilesChange: (files: File[]) => void
  disabled?: boolean
}
const OfferModalFiles: React.FC<IProductSectionOfferModalFiles> = ({ offer, onFilesChange, disabled }) => {
  const { remove } = offerMediaService(offer?.uuid || '')

  const {
    data: filesFromServer,
    refetch,
    isLoading,
  } = useQuery<MediaType[]>(['offerMedia', offer?.uuid], () => offerMediaService(offer?.uuid || '').list(), {
    enabled: !!offer?.uuid,
  })
  const [files, setFiles] = useState<MediaType[]>([])

  useEffect(() => {
    setFiles(filesFromServer || [])
  }, [filesFromServer])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      // map newly added files to MediaType for display
      const mappedFiles = acceptedFiles.map((file) => ({
        ...file,
        name: file.name,
        file_name: file.name,
        mime_type: file.type,
        media_type: file.type,
        size: file.size,
        type: 'offer',
        url: URL.createObjectURL(file),
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        uuid: '',
      }))

      setFiles([...files, ...mappedFiles])
      onFilesChange(acceptedFiles)
    },
    [offer]
  )

  return (
    <div>
      {files && files.length > 0 && (
        <MediaTable media={files || []} onDelete={(media) => remove(media.uuid)} disabled={disabled} />
      )}

      <DropzoneArea
        onDrop={onDrop}
        multiple
        allowedFileTypes={[{ id: 'application/pdf', label: 'PDF' }]}
        disabled={disabled}
      />
    </div>
  )
}

export default OfferModalFiles
