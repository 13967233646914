import { getTargetValueAndQuestions } from '../helpers/getTargetValueAndQuestions'
import { getMissingTargetValueError } from '../helpers/getMissingTargetValueError'

export const max = function (requestedValue) {
  return this.test('max', 'has to be lower than', (value, schema) => {
    if (value === undefined) return true

    const { targetValue, targetQuestion } = getTargetValueAndQuestions(requestedValue, schema)

    if (targetValue === undefined && value !== undefined) {
      return getMissingTargetValueError(targetQuestion, schema)
    }

    return value < targetValue
      ? true
      : schema.createError({
          message: `should be lower than ${targetValue}`,
          path: schema.path,
        })
  })
}

export default max
