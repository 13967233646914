import { FC } from 'react'
import { t } from 'i18next'
import { SearchActiveBlockType } from '../../../../../../types/Search/SubmissionSearchTypes'
import LhotseHomeTableRowStatusIndicator from '../../../../../../shared/components/atoms/StatusIndicator'

interface ILhotseHomeTableRowStatusItem {
  status: string
  block?: SearchActiveBlockType
}

const LhotseHomeTableRowStatusItem: FC<ILhotseHomeTableRowStatusItem> = ({ status, block }) => {
  switch (status) {
    case 'completed':
      return (
        <LhotseHomeTableRowStatusIndicator
          status={t('status.completed', 'Completed')}
          className="bg-green-500 "
          textClassName="text-green-600"
        />
      )
    case 'ongoing':
      return (
        <LhotseHomeTableRowStatusIndicator
          status={block?.workflow_blocks_name ?? t('status.ongoing', 'Ongoing')}
          className="bg-yellow-500 animate animate-pulse"
          textClassName="text-yellow-600"
        />
      )
    case 'declined':
      return (
        <LhotseHomeTableRowStatusIndicator
          status={t('status.declined', 'Declined')}
          className="bg-red-500 "
          textClassName="text-red-600"
        />
      )
    case 'declined_by_requester':
      return (
        <LhotseHomeTableRowStatusIndicator
          status={t('status.declined_by_requester', 'Declined by requester')}
          className="bg-red-500 "
          textClassName="text-red-600"
        />
      )
    default:
      return (
        <LhotseHomeTableRowStatusIndicator
          status={block?.workflow_blocks_name ?? t('general.missing_block_name')}
          className="bg-gray-300 "
          textClassName="text-gray-600"
        />
      )
  }
}

export default LhotseHomeTableRowStatusItem
