import { useTranslation } from 'react-i18next'
import { TagType } from '../../../../types/shared/GeneralTypes'
import Tag from './Tag'

interface TagTypesGroup {
  tags: TagType[]
  removeTag?: (tag: TagType) => void
  editable?: boolean
}

const TagsGroup: React.FC<TagTypesGroup> = ({ tags, removeTag, editable }) => {
  const { t } = useTranslation()

  if (!tags?.length)
    return (
      <p className="text-xs items-center flex text-secondary-gray font-light italic pt-1">{t('suppliers.no_tags')}</p>
    )

  return (
    <div className="flex gap-2 flex-wrap w-full">
      {tags?.map((tag) => <Tag key={tag?.id} tag={tag} removeTag={removeTag} editable={editable} />)}
    </div>
  )
}

export default TagsGroup
