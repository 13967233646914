import cn from '../../../../lib/util'

export type VerticalTabItemType = {
  path: string
  fullPath?: string
  title: string
  icon: JSX.Element
  active?: boolean
  tabIndex?: number
  visible: boolean
}

type VerticalTabItemProps = {
  tab: VerticalTabItemType
  onClick: (item: VerticalTabItemType) => void
}
const VerticalMenuTabsItem = ({ tab, onClick }: VerticalTabItemProps) => (
  <button
    type="button"
    className={cn(
      `py-2 px-3 text-sm w-full font-semibold text-left text-black-light-2 rounded-md flex items-center
      ${
        tab.active
          ? 'bg-gray-200 text-gray-500 font-semibold'
          : 'hover:font-semibold hover:text-black-light-2 hover:bg-gray-100'
      }`
    )}
    onClick={() => onClick(tab)}
  >
    {tab.icon && <div className="mr-2">{tab.icon}</div>}
    {tab.title}
  </button>
)
export default VerticalMenuTabsItem
