import ExternalDataTableRow, { DynamicRowData } from './ExternalDataTableRow'
import Table from '../../../../shared/components/atoms/SharedTable/Table'

type ExternalDataTableBodyProps = {
  rows: DynamicRowData[]
}

const ExternalDataTableBody = ({ rows }: ExternalDataTableBodyProps) => (
  <Table>{rows?.map((row) => <ExternalDataTableRow key={`external-data-${row.id}-row`} data={row} />)}</Table>
)

export default ExternalDataTableBody
