import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useOnboardingStore, { OnboardingError, SectionType, StoreState } from '../Store/useOnboardingStore'
import FormField from '../../Forms-v2/FormField'
import { InputType } from '../../../types/shared/InputType'
import Button from '../../../shared/components/atoms/buttons/Button'
import { FormQuestionValue } from '../../../types/Forms'
import { CustomDataSchemaFieldType } from '../../Settings/Views/Schemas/useSchemaService'

export type OnboardingStepContentProps = {
  onUpdate: (currentSection: SectionType) => void
}

const OnboardingStepContent = ({ onUpdate }: OnboardingStepContentProps) => {
  const edit = useOnboardingStore((state) => (state as StoreState).edit)
  const currentSection = useOnboardingStore((state) => (state as StoreState).currentSection)
  const setCurrentSection = useOnboardingStore((state) => (state as StoreState).setCurrentSection)
  const nextSection = useOnboardingStore((state) => (state as StoreState).nextSection())
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({ mode: 'onChange' })

  const onSubmit = (attributes: any) => {
    if (!currentSection || attributes.length === 0) {
      return
    }
    setCurrentSection(currentSection)
    onUpdate(currentSection)
  }

  const onValueUpdated = (fieldUuid: string, updatedValue: FormQuestionValue | null) => {
    if (!currentSection || !updatedValue || !fieldUuid) {
      return
    }
    const newFields = [...currentSection.fields]
    const changingField = newFields.find((field) => field.input_uuid === fieldUuid)
    if (!changingField) {
      return
    }
    changingField.values = updatedValue
    setCurrentSection({
      ...currentSection,
      fields: newFields,
    })
  }

  const onError = (attributes: any) => {
    if (!currentSection || attributes.length === 0) {
      return
    }
    const errorBag: OnboardingError[] = []
    currentSection.fields
      .filter((attribute) => attributes[attribute.name] ?? undefined)
      .forEach((field: CustomDataSchemaFieldType) => {
        errorBag.push({
          attribute: field.name,
          message: attributes[field.name].message ?? '',
        })
      })

    if (errorBag.length === 0) {
      return
    }

    setCurrentSection({
      ...currentSection,
      errors: [...(currentSection?.errors ?? []), ...errorBag],
    })
  }

  return (
    <div
      className="flex flex-col space-y-9 transition duration-150 ease-out"
      key={`${currentSection?.title.toLowerCase()}`}
    >
      <div className="space-y-6">
        <h1 className="flex flex-col items-center justify-center text-2xl font-semibold">{currentSection?.title}</h1>
        <div className="flex flex-col items-center justify-center flex-wrap text-black-light">
          {currentSection?.description}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="flex flex-col space-y-4">
        {currentSection?.fields.map((field: CustomDataSchemaFieldType) => (
          <Controller
            key={field.input_uuid}
            name={field.input_uuid!!}
            control={control}
            defaultValue={field.values}
            rules={{
              required: field.required,
            }}
            render={({ field: controllerField }) => (
              <FormField
                label={field.name}
                readonly={edit}
                disabled={field.disabled}
                updateField={(event) => {
                  controllerField.onChange(event.value)
                  onValueUpdated(controllerField.name, event?.value)
                }}
                field={{
                  disabled: field.disabled,
                  id: controllerField.name,
                  uuid: field.input_uuid,
                  input: {
                    defaultValue: field.values,
                    disabled: field.disabled,
                    id: field.input_uuid,
                    description: null,
                    type: field.type,
                    name: controllerField.name,
                    placeholder: field.placeholder,
                    settings: {
                      fileType: {
                        allowedFileTypes:
                          field.type === 'FILE'
                            ? [
                                {
                                  label: 'pdf',
                                  id: 'pdf',
                                },
                              ]
                            : [],
                      },
                    },
                  } as InputType,
                  label: field.name,
                  media: null,
                  options: null,
                  value: controllerField.value,
                  error: (errors[field.input_uuid]?.message as string) ?? '',
                }}
              />
            )}
          />
        ))}
        <Button disabled={!isValid} className="bg-sky-blue text-white" onClick={handleSubmit(onSubmit, onError)}>
          {!nextSection ? `${t('onboarding.submit', 'Submit')}` : `${t('onboarding.next', 'Next')}`}
        </Button>
      </form>
    </div>
  )
}

export default OnboardingStepContent
