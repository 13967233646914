import React from 'react'
import { useTranslation } from 'react-i18next'
import { useWorkflow } from '../../providers/WorkflowProvider'
import { useWorkflowMutations } from '../../hooks/mutations'
import { useWorkflowVersionType } from '../../hooks/useWorkflowVersionType'
import EditableText from '../misc/EditableText'

export const WorkflowEditableTitle = () => {
  const { workflow } = useWorkflow()
  const { rename, isMutating } = useWorkflowMutations()
  const { t } = useTranslation()

  const handleTitleChange = (newName: string) =>
    rename({ uuid: workflow.uuid, version: workflow.version, name: newName })

  const versionType = useWorkflowVersionType(workflow.version)

  return (
    <div className="flex flex-col gap-2 w-1/2 min-w-[250px]">
      <EditableText
        value={workflow.name}
        onSubmit={handleTitleChange}
        placeholder={t('workflow.title')}
        isProcessing={isMutating}
      />
      <div className="flex flex-row gap-4 text-sm text-black-light-2">
        <span>
          {`V${workflow.version}`}
          {versionType === 'published' && ` - ${t('workflow.published', 'published')}`}
          {versionType === 'draft' && ` - ${t('workflow.draft', 'draft')}`}
        </span>
      </div>
    </div>
  )
}

export default WorkflowEditableTitle
