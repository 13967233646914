import { get, groupBy, sortBy } from 'lodash'
import type { PartialDeep } from 'type-fest'
import { mergeDeepOverwritingArrays } from './objectHelpers'
import uuid from '../../core/utils/uuid'

export const move = <T>(array: T[], fromIndex: number, toIndex: number) => {
  const copiedArray = [...array]
  copiedArray.splice(toIndex, 0, copiedArray.splice(fromIndex, 1)[0])
  return copiedArray
}

export const insertAt = <T>(array: T[], index: number, newElement: T) => [
  ...array.slice(0, index),
  newElement,
  ...array.slice(index),
]

export const replaceAt = <T>(array: T[], index: number, newElement?: T) => {
  const copiedArray = [...array]
  newElement ? copiedArray.splice(index, 1, newElement) : copiedArray.splice(index, 1)
  return copiedArray
}

export const deleteAt = <T>(array: T[], index: number) => replaceAt(array, index)

export const replaceByProperty = (
  array: any[] | undefined,
  property: string,
  deleteElementId: number | string,
  newElement?: any
) => {
  if (!array) return []
  const newArray = [...array]
  const find = (element: any) => element[property] === deleteElementId
  const index = newArray.findIndex(find)
  if (index !== 0 && !index) return array
  newArray.splice(index, 1)
  if (newElement) {
    newArray.splice(index, 0, newElement)
  }
  return newArray
}

export const replaceById = (array: any[] | undefined, deleteElementId: number | string, newElement?: any) =>
  replaceByProperty(array, 'id', deleteElementId, newElement)

export const replaceByUuid = <T extends { __uuid: string }>(
  array: T[] | undefined,
  deleteElementId: number | string,
  newElement?: any
): T[] => replaceByProperty(array, '__uuid', deleteElementId, newElement)

export const getUpdatedBy = <T extends Record<string, unknown>>(
  target: T[],
  filter: (value: T) => boolean,
  updater: (value: T) => T
) =>
  target.map((value) => {
    if (filter(value)) return updater(value)
    return value
  })

export const getUpdatedByProperty = <T extends Record<string, unknown>>(
  propertyOrPath: keyof T,
  target: T[],
  ...sources: PartialDeep<T>[][]
): T[] => {
  const updatedTargets = Object.values(
    groupBy([...target, ...sources.flat()] as T[], (element) => {
      const value = get(element, propertyOrPath)
      if (value === null || value === undefined) return uuid()
      return value
    })
  ).flatMap<T>((objects: T[]) => mergeDeepOverwritingArrays({} as T, ...objects))

  return sortBy(updatedTargets, (finalObject) =>
    target.findIndex((targetObject) => get(targetObject, propertyOrPath) === get(finalObject, propertyOrPath))
  )
}

export const getUpdatedByUuid = <T extends { __uuid: string }>(target: T[], ...sources: PartialDeep<T>[][]): T[] =>
  getUpdatedByProperty('__uuid', target, ...sources)

export const getUpdatedByUuidWithoutNew = <T extends { __uuid: string }>(
  target: T[],
  ...sources: PartialDeep<T>[][]
): T[] =>
  getUpdatedByProperty('__uuid', target, ...sources).filter((finalValue) =>
    target.find((targetValue) => targetValue.__uuid === finalValue.__uuid)
  )

export const array = () => {}
