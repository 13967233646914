import { useTranslation } from 'react-i18next'
import OtherSettings from './OtherSettings/OtherSettings'
import QuestionHintsSetting from './QuestionHintsSetting'
import SelectableChoicesSetting from './SelectableChoicesSetting'
import ValidationSettings from './ValidationSettings/ValidationSettings'
import { useFormContext } from '../../../../../core/providers/FormProvider'
import QuestionSection from '../components/QuestionSection'

const QuestionSettings: React.FC = () => {
  const {
    question: [question],
  } = useFormContext()
  const { t } = useTranslation()

  if (!question) return null

  return (
    <div className="h-min flex flex-col gap-y-4">
      <QuestionSection title={t('forms.settings_section')}>
        <SelectableChoicesSetting />
        <QuestionHintsSetting />
        <OtherSettings />
      </QuestionSection>
      <ValidationSettings />
    </div>
  )
}

export default QuestionSettings
