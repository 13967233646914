import { useEffect } from 'react'
import { useReactFlow } from 'reactflow'
import useIntakeSidebar from '../../hooks/useIntakeSidebar'

export const IntakeFlowchartLayout = () => {
  const { minified: sidePanelOpen } = useIntakeSidebar()
  const { fitView } = useReactFlow()

  useEffect(() => {
    setTimeout(() => fitView(), 0)
  }, [sidePanelOpen])

  return null
}

export default IntakeFlowchartLayout
