import { useMutation, useQueryClient } from 'react-query'
import useIntakeId from '../../Intake/IntakeOverview/hooks/useIntakeId'
import { postOfferItem } from '../../Offerings/services'
import { OfferItem } from '../../../types/Offering'
import { RequireAtLeastOne } from '../../../types/shared/UtilityTypes'

export const useCreateOfferItem = () => {
  const intakeFlowUuid = useIntakeId()
  const client = useQueryClient()

  return useMutation(
    (
      newOffer: Partial<Omit<OfferItem, 'uuid'>> & RequireAtLeastOne<{ reference_key: string; offering_uuid: string }>
    ) =>
      postOfferItem({
        ...newOffer,
        intake_flow_uuid: intakeFlowUuid,
      }),
    {
      onSettled: ({ data: newOffer }) => {
        client.refetchQueries(['offering-offers', newOffer.reference_key])
        client.refetchQueries(['intake-offerings', intakeFlowUuid, 'paginated', 1])
      },
    }
  )
}

export default useCreateOfferItem
