import { useQuery } from 'react-query'
import { getIntakeOffers } from '../services'

export const useGetIntakeOffers = (intakeUuid?: string) =>
  useQuery(['intake-offers', intakeUuid], () => {
    if (!intakeUuid) return Promise.resolve([])
    return getIntakeOffers(intakeUuid)
  })

export default useGetIntakeOffers
