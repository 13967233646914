import { CalendarIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import WorkflowBlockEntry from '../../../misc/WorkflowBlockEntry'
import { WorkflowBlock } from '../../../../../../types/Workflow/WorkflowBlock'

export const WorkflowBlockDeadline = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()

  return (
    <WorkflowBlockEntry
      className="w-1/2 overflow-hidden"
      Icon={CalendarIcon}
      title={t('intake.flowchart.approvalNodeContent.deadline', 'Deadline')}
    >
      {block.reminder}
    </WorkflowBlockEntry>
  )
}

export default WorkflowBlockDeadline
