import Calendar from '../../../../../../../../../shared/components/organisms/Calendar/Calendar'
import { InputSearchSelectItemType } from '../../../../../../../../../types/shared/InputSearchSelectTypes'

interface ILogicComparatorDateInput {
  value?: string | null
  onChange: (value: InputSearchSelectItemType) => void
}

const LogicComparatorDateInput: React.FC<ILogicComparatorDateInput> = ({ value, onChange }) => (
  <div className="max-w-[208px]">
    <Calendar
      onChange={(date) => onChange({ id: 'date', label: date.toString() })}
      selected={value ? new Date(value) : undefined}
      className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
    />
  </div>
)

export default LogicComparatorDateInput
