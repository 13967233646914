import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import BackToNavigationButton from '../../../shared/components/atoms/buttons/BackNavigationButton'
import { useSuppliersProvider } from '../../Suppliers/providers/SuppliersProvider'

const BackToSupplierSearchBanner = () => {
  const history = useHistory()
  const {
    origin: [origin],
  } = useSuppliersProvider()
  const { t } = useTranslation()

  const isFromCoordinate = origin.includes('execute')

  return (
    <BackToNavigationButton
      text={isFromCoordinate ? t('supplier.back_to_search') : t('supplier.back_to_all')}
      onClick={() => history.push(origin || '/suppliers')}
      titleClassName={!isFromCoordinate ? 'underline' : ''}
      className={`${isFromCoordinate ? 'bg-sky-blue text-white text-sm' : 'pt-4'}`}
    />
  )
}

export default BackToSupplierSearchBanner
