import ExternalDataRelationshipsTableRow from './ExternalDataRelationshipsTableRow'
import Table from '../../../../shared/components/atoms/SharedTable/Table'
import useGetExternalDataRelationshipList from '../../hooks/useExternalDataRelationships'

const ExternalDataRelationshipsTableBody: React.FC = () => {
  const { data: relationships } = useGetExternalDataRelationshipList()

  return (
    <Table>
      {relationships?.map((relationship) => (
        <ExternalDataRelationshipsTableRow
          key={`external-data-relationship-${relationship.column_name}-row`}
          relationship={relationship}
        />
      ))}
    </Table>
  )
}

export default ExternalDataRelationshipsTableBody
