export const getTenant = () => {
  let tenant = window.location.host.split('.')[0]

  if (window.location.host.split('.')[1] === 'pr') {
    tenant = process.env.REACT_APP_PR_TENANT
  }
  if (window.location.host.includes('vercel')) {
    tenant = process.env.REACT_APP_PR_TENANT
  }
  return tenant
}

export const handleWrongTenantUrl = () => {
  window.location.href = `${window.location.protocol}//app.${new URL(window.location.origin).hostname.replace(
    /^[^.]+\./g,
    ''
  )}:${window.location.port}/`
}

export const getSherpaUrl = () => {
  const tenant = getTenant()
  return `${process.env.REACT_APP_BASEURL}/sherpa/v2/${tenant}`
}

export const redirectToInstance = (tenant, redirect = '') => {
  window.location.href = `${window.location.protocol}//${tenant}.${new URL(window.location.origin).hostname.replace(
    /^[^.]+\./g,
    ''
  )}:${window.location.port}${redirect}`
}

export const getApiUrl = () => {
  const tenant = getTenant()
  return `${process.env.REACT_APP_BASEURL}/api/v2/${tenant}`
}

export const getCentralApiUrl = () => `${process.env.REACT_APP_BASEURL}/api`

export const getAdminApiUrl = () => {
  const tenant = getTenant()
  return `${process.env.REACT_APP_BASEURL}/admin-api/${tenant}`
}

export const getTenantUrl = () => {
  const tenant = getTenant()
  return `${process.env.REACT_APP_BASEURL}/${tenant}`
}
