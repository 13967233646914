import { useTranslation } from 'react-i18next'
import QuestionItemInput from './QuestionItemInput'
import { useFormContext } from '../../../../../../core/providers/FormProvider'

interface IQuestionTextInput {}

const QuestionTextInput: React.FC<IQuestionTextInput> = () => {
  const { t } = useTranslation()

  const {
    question: [question],
  } = useFormContext()
  const canRename = question?.actions?.rename

  return (
    <QuestionItemInput
      title={t('question_builder.question_text')}
      placeholder={t('question_builder.enter_question')}
      property="label"
      disabled={!canRename}
    />
  )
}

export default QuestionTextInput
