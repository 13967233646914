import { useTranslation } from 'react-i18next'
import SearchSelect from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'

interface ILanguageSelect {
  values?: { language: string }
  handleChange?: (value: string) => void
}

const LanguageSelect: React.FC<ILanguageSelect> = ({ values, handleChange }) => {
  const { t } = useTranslation()

  const handleLanguageChange = (newValue?: string) => {
    if (!newValue || values?.language === newValue) {
      return
    }
    handleChange && handleChange(newValue)
  }
  return (
    <SearchSelect
      onChange={(newValue) => handleLanguageChange(newValue?.value)}
      currentSelection={{
        id: values?.language!!,
        value: values?.language || 'en',
        label: t(`generic.${values?.language === 'en' ? 'english' : 'german'}`),
      }}
      className="w-1/5"
      options={[
        { id: 'en', value: 'en', label: t('generic.english') },
        { id: 'de', value: 'de', label: t('generic.german') },
      ]}
    />
  )
}

export default LanguageSelect
