import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { CustomDataSchemaFieldType } from '../../Settings/Views/Schemas/useSchemaService'

export type OnboardingError = {
  attribute: string
  message: string
}

export type SectionType = {
  title: string
  description: string
  stepName: string
  errors?: OnboardingError[]
  fields: CustomDataSchemaFieldType[]
  data?: any
  id: number
}

export type StoreState = {
  activeSectionIndex: number
  sections: SectionType[]
  currentSection: SectionType | null
  edit: boolean
  setCurrentSection: (section: SectionType) => SectionType
  nextSection: () => SectionType | null
  setSections: (sections: SectionType[]) => void
  deleteEverything: () => void
  changeCurrentSection: (newIndex: number) => void
}

const useOnboardingStore = create(
  persist(
    (set, get) => ({
      sections: [],
      activeSectionIndex: 1,
      currentSection: null,
      edit: false,
      setSections: (sections: SectionType[]) =>
        set((state: StoreState) => ({
          sections,
          currentSection: sections[state.activeSectionIndex - 1],
        })),
      nextSection: () => {
        const { sections, activeSectionIndex } = get() as StoreState
        const nextIndex = activeSectionIndex - 1
        const possibleNextSection = sections[nextIndex + 1] ?? null
        return possibleNextSection?.fields?.length > 0 ? possibleNextSection : null
      },
      setCurrentSection: (section: SectionType) =>
        set((state: StoreState) => {
          const newSections = state.sections.map((currentSection) =>
            currentSection.id === section.id ? section : currentSection
          )
          return {
            currentSection: section,
            sections: newSections,
          }
        }),
      deleteEverything: () =>
        set(
          (state: StoreState) => ({
            ...state,
            activeSectionIndex: 1,
            currentSection: null,
            sections: [],
          }),
          true
        ),
      changeCurrentSection: (newIndex: number) =>
        set((state: StoreState) => ({
          activeSectionIndex: newIndex > state.sections.length ? 1 : newIndex,
          currentSection: newIndex > state.sections.length ? state.sections[0] : state.sections[newIndex - 1],
        })),
    }),
    {
      name: 'onboardingState',
      version: 1,
    }
  )
)

export default useOnboardingStore
