export const companyQueryStrings = {
  company_addresses: 'company-addresses',
  customer_documents: 'customer-documents',
  company_users: 'company-users',
  countries: 'countries',
  company_inputs: 'company-inputs',
  company_input_categories: 'company-input-categories',
}

export default companyQueryStrings
