import { useLocalStorage } from '../../../../../core/providers/LocalStorageProvider'
import PreviousSectionButton from './components/PreviousSectionButton'
import NextSectionButton from './components/NextSectionButton'
import { useIntakeFlow } from '../../../IntakeOverview/providers/IntakeFlowProvider'

const SectionNavigationButtons: React.FC = () => {
  const [getLocalStorage] = useLocalStorage()
  const minified = getLocalStorage('sidebarMinified')

  const { intake } = useIntakeFlow()

  return (
    <div
      className={`${minified ? 'pl-24' : 'pl-56'} h-20 bottom-0 left-0 flex flex-1 z-30
      fixed w-full px-4 bg-background shadow-[10px_0px_5px_rgba(0,0,0,0.1)]`}
    >
      <div className="flex w-full py-5 px-2">
        <div className="flex w-full justify-between gap-4">
          <PreviousSectionButton />
          <div className="px-4 flex justify-between text-sm items-center h-full ">
            <div className="flex h-fit">
              <p className="pl-2.5 font-medium text-nds-primary-gray">{intake?.name}</p>
            </div>
          </div>
          <NextSectionButton />
        </div>
      </div>
    </div>
  )
}

export default SectionNavigationButtons
