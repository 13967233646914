import ExternalDataSourcesTableRow from './ExternalDataSourcesTableRow'
import Table from '../../../../shared/components/atoms/SharedTable/Table'
import useGetExternalDataEntitiesList from '../../hooks/useGetExternalDataEntitiesList'

const ExternalDataSourcesTableBody: React.FC = () => {
  const { data: externalDataList } = useGetExternalDataEntitiesList()

  return (
    <Table>
      {externalDataList?.map((externalData) => (
        <ExternalDataSourcesTableRow key={`external-data-${externalData.uuid}-row`} externalData={externalData} />
      ))}
    </Table>
  )
}

export default ExternalDataSourcesTableBody
