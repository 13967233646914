import { ForwardedRef, forwardRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IntakeSectionType } from '../../../../../types/Intake'
import SectionRenderer from './SectionRenderer'
import BlueButton from '../../../../../shared/newui/BlueButton'
import useIntakeFormStore from '../../stores/useIntakeFormStore'
import useIntakeFormMutations from '../../hooks/useIntakeFormMutations'

const IntakeFormConfirmationSection = forwardRef((props, detailRef: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation()
  const intake = useIntakeFormStore((state) => state.intake)
  const setShowErrors = useIntakeFormStore((state) => state.setShowErrors)

  const {
    submitDraft: { mutate: handleIntakeDraftSubmission },
  } = useIntakeFormMutations()

  const submit = () => {
    handleIntakeDraftSubmission()
  }

  useEffect(() => {
    setShowErrors(true)
  }, [])

  const errors = useIntakeFormStore((state) => state.errors)
  const offersErrors = useIntakeFormStore((state) => state.offersErrors)
  const hasErrors =
    (errors ? Object.keys(errors).length > 0 : false) || (offersErrors ? Object.keys(offersErrors).length > 0 : false)

  let offersErrorMessage = ''
  if (offersErrors) {
    if (Object.values(offersErrors).some((error) => !error.hasOffers)) {
      offersErrorMessage = t(
        'forms.offer_validation_errors_no_offers',
        'Please add at least one offer to every product'
      )
    } else if (Object.values(offersErrors).some((error) => !error.hasOfferQuantities || !error.hasOfferPrices)) {
      offersErrorMessage = t(
        'forms.offer_validation_errors_no_quantity_or_price',
        'Please fill in a quantity and price for every offer'
      )
    }
  }

  return (
    <div ref={detailRef} className="flex flex-col gap-8 px-10 text-black-light max-w-7xl mx-auto">
      <div
        className={`flex flex-col text-sm
        ${hasErrors ? 'bg-yellow-50 text-yellow-700 ' : 'bg-green-50 text-green-700  border-green-400  '}
         px-8 py-7 space-y-4 rounded shadow`}
      >
        <div className="text-xl font-semibold mb-2">
          {!hasErrors &&
            t(
              'forms.all_questions_answered_new',
              "Great job! You've successfully answered all the questions. It's time to submit the form!"
            )}
          {hasErrors && t('forms.validation_errors_found', 'There are some validation issues!')}
        </div>
        <div>
          {t(
            'forms.review_your_answers_new',
            'Kindly review your answers and make any necessary adjustments. Once you are ready, click to save the draft and then on the Send button to submit the form.'
          )}
          {offersErrors && (
            <div className="border-t mt-4 pt-4 font-semibold flex justify-between items-center">
              {offersErrorMessage}
            </div>
          )}
        </div>
        {!hasErrors && (
          <div className="pt-4 flex justify-end">
            <BlueButton onClick={() => submit()}>{t('forms.submit_form')}</BlueButton>
          </div>
        )}
      </div>

      {intake?.sections.map((section: IntakeSectionType) => (
        <SectionRenderer section={section} key={`section-${section.id}`} useSectionWrapper />
      ))}
    </div>
  )
})

IntakeFormConfirmationSection.displayName = 'IntakeFormConfirmationSection'

export default IntakeFormConfirmationSection
