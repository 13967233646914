import { useHistory } from 'react-router'
import { useCallback } from 'react'
import { Workflow } from '../../../types/Workflow/Workflow'

export const useOpenWorkflowDetails = (workflow: Workflow) => {
  const history = useHistory()

  return useCallback(
    (version?: number) => {
      history.push(`/workflows/${workflow.uuid}/version/${version || workflow.version.toString()}`)
    },
    [history, workflow]
  )
}

export default useOpenWorkflowDetails
