import { useFormContext } from '../../../../../../core/providers/FormProvider'
import { IInputTextGray } from '../../../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import TipTapEditor from '../../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import { FormQuestionType } from '../../../../../../types/Forms'
import type { KeysMatching } from '../../../../../../types/shared/TypeHelpers'

type QuestionItemRichTextInputProps = {
  title: string
  property: KeysMatching<FormQuestionType, string>
  onChange?: (newValue: string) => void
} & Omit<IInputTextGray, 'onChange' | 'value'>

const QuestionItemRichTextInput: React.FC<QuestionItemRichTextInputProps> = ({
  title,
  onChange,
  property,
  ...inputProps
}) => {
  const {
    question: [question],
    updateQuestion,
  } = useFormContext()

  const handleQuestionChange = (value: string) => {
    if (onChange) onChange(value)
    else updateQuestion({ [property]: value })
  }

  return (
    <div className="flex flex-col gap-y-2">
      <p className="text-xs text-primary-gray">{title}</p>

      <div className="max-w-lg">
        <TipTapEditor content={question?.[property] || ''} onUpdate={(d) => handleQuestionChange(d)} />
      </div>

      {/* <QuestionInputTextGray
        className="w-full max-w-lg"
        onChange={handleQuestionChange}
        value={question?.[property] || ''}
        {...inputProps}
      /> */}
    </div>
  )
}

export default QuestionItemRichTextInput
