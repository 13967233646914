import { useCallback, useMemo } from 'react'
import useUsers from './useUsers'
import { CompanyUserType } from '../../../types/shared/RequisitionDataTypes'
import generateUserName from '../../utils/generateUserName'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'

export const toUserOption = (user: CompanyUserType): InputSearchSelectItemType => ({
  id: user.id,
  label: generateUserName(user),
})

export const useUserOptions = (condition?: (user: CompanyUserType) => boolean) => {
  const { data: users, isLoading } = useUsers()

  const options = useMemo(() => users?.filter(condition || (() => true)).map(toUserOption), [condition, users])

  return {
    options,
    users,
    isLoading,
    findUser: useCallback((id: CompanyUserType['id'] | undefined) => users?.find((user) => user.id === id), [users]),
    findOption: useCallback(
      (id: CompanyUserType['id'] | undefined) => options?.find((option) => option.id === id),
      [options]
    ),
  }
}

export const useUserOptionsExcluding = (excludedUsers: { id: CompanyUserType['id'] }[]) =>
  useUserOptions((user) => !excludedUsers.find((excludedUser) => excludedUser.id === user.id))

export default useUserOptions
