import useGetWorkflow from '../hooks/useGetWorkflow'
import WorkflowProvider from '../providers/WorkflowProvider'
import WorkflowBuilderChart from '../components/WorkflowBuilderChart/WorkflowBuilderChart'
import { WorkflowBuilderHeader } from '../components/WorkflowBuilderHeader'
import WorkflowBuilderBlockSidepanel from '../components/WorkflowBuilderSidePanel/WorkflowBuilderBlockSidepanel'
import WorkflowBuilderDetailsSidePanel from '../components/WorkflowBuilderHistorySidePanel/WorkflowBuilderDetailsSidePanel'

export const WorkflowDetailsPage = () => {
  const { data: workflow } = useGetWorkflow()

  if (!workflow) return null

  return (
    <WorkflowProvider workflow={workflow}>
      <div className="flex flex-row w-full">
        <div className="flex flex-col relative w-full">
          <WorkflowBuilderHeader />
          <div className="flex flex-col gap-6 h-full">
            <div className="-ml-px h-full">
              <WorkflowBuilderChart />
            </div>
          </div>
        </div>
        <WorkflowBuilderBlockSidepanel />
        <WorkflowBuilderDetailsSidePanel />
      </div>
    </WorkflowProvider>
  )
}

export default WorkflowDetailsPage
