import {
  FormNumberOfChoicesOptionSettingType,
  FormQuestionContentType,
  FormQuestionSettings,
} from '../../../../../../types/Forms'

export const getTypeOfAnswersSetting = ({
  min,
  max,
}: FormQuestionSettings['answers'] = {}): FormNumberOfChoicesOptionSettingType => {
  if (min === 1 && max === 1) return 'single'
  if (max === undefined) return 'unlimited'
  if (max !== min) return 'range'
  if (min === max) return 'defined'

  return 'single'
}

export const getAvailableChoices = (type?: FormQuestionContentType): FormNumberOfChoicesOptionSettingType[] => {
  switch (type) {
    case 'NUMBER':
    case 'TEXT':
    case 'KEY_VALUE':
    case 'PRICE':
    case 'ADDRESS':
    case 'MULTI_SELECT':
    case 'SELECT':
    case 'FILE':
      return ['unlimited', 'single', 'defined', 'range']
    default:
      return []
  }
}

export const getAnswersMinMax = (type: FormNumberOfChoicesOptionSettingType) => {
  if (type === 'unlimited') return { min: 1, max: undefined }
  if (type === 'defined' || type === 'single') return { min: 1, max: 1 }
  if (type === 'range') return { min: 1, max: 2 }
  return undefined
}
