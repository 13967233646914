import { useUtils } from '../../../../../core/providers/UtilsProvider'
import ExternalDataModalHeader from '../ExternalDataModalHeader'
import ExternalDataModalBody, { ExternalDataDetails } from '../ExternalDataModalBody'
import useExternalDataUpload, { ExternalDataUploadPayload } from '../../../hooks/useExternalDataUpload'

export const useExternalDataUploadModal = () => {
  const { modal } = useUtils()

  const { upload } = useExternalDataUpload()

  const handleSubmit = (externalDataDetails: ExternalDataDetails) =>
    upload(externalDataDetails as ExternalDataUploadPayload)

  return () => {
    modal.set({
      isOpen: true,
      title: <ExternalDataModalHeader />,
      content: <ExternalDataModalBody onSubmit={handleSubmit} />,
      className: 'max-w-[500px]',
    })
  }
}

export default useExternalDataUploadModal
