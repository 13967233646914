import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface IDateDivider {
  dateString: string
}
const DateDivider: React.FC<IDateDivider> = ({ dateString }) => {
  const { t } = useTranslation()
  const dayJSDate = dayjs(dateString)
  const isToday = dayJSDate.isSame(dayjs(), 'day')

  return (
    <div className="relative flex pt-5 items-center first:pt-0 pb-5">
      <div className="flex-grow border-t border-rock-gray-light" />
      <span className="flex-shrink mx-4 text-rock-gray capitalize">
        {isToday ? t('generic.today', 'Today') : dayJSDate.format('ddd, D MMM')}
      </span>
      <div className="flex-grow border-t border-rock-gray-light" />
    </div>
  )
}

export default DateDivider
