import toast from 'react-hot-toast'
import Toast, { ToastProps } from './Toast'

const openToast = (
  theme: ToastProps['theme'],
  translationString: ToastProps['translationString'],
  id: any,
  additionalTextValue?: ToastProps['additionalTextValue']
) =>
  toast.custom(
    (toastElement) => (
      <Toast
        theme={theme}
        translationString={translationString}
        id={id}
        toastElement={toastElement}
        additionalTextValue={additionalTextValue}
      />
    ),
    {
      position: 'bottom-right',
      duration: 4000,
      id,
    }
  )

export const toastr = {
  info: (translationString: string, id?: any, additionalTextValue?: string) =>
    openToast('info', translationString, id, additionalTextValue),
  success: (translationString: string, id?: any, additionalTextValue?: string) =>
    openToast('success', translationString, id || translationString, additionalTextValue),
  warning: (translationString: string, id?: any, additionalTextValue?: string) =>
    openToast('warning', translationString, id || translationString, additionalTextValue),
  error: (translationString?: string, id?: any, additionalTextValue?: string) =>
    openToast(
      'error',
      translationString || 'error.oops_generic',
      id || translationString || 'generic.error',
      additionalTextValue
    ),
}

export default toastr
