import { FormQuestionType, FormSectionType } from '../../../../../../../../types/Forms/formTypes'
import FormLogicLoopCase from './LogicCases/FormLogicLoopCase'

interface ILoopTriggerLogic {
  question: FormQuestionType
  section: FormSectionType
}

const LoopTriggerLogic: React.FC<ILoopTriggerLogic> = ({ question, section }) => {
  if (!question.__loop) return null

  return (
    <div className="flex flex-col gap-y-4 text-primary-gray text-xs">
      <div className="flex flex-col max-w-[560px] w-full gap-y-4 border border-quaternary-grey rounded-md p-4">
        <FormLogicLoopCase logicCase={question.__loop} question={question} section={section} />
      </div>
    </div>
  )
}

export default LoopTriggerLogic
