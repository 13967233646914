import { useTranslation } from 'react-i18next'
import WorkflowActionButton from '../misc/WorkflowActionButton'
import { useWorkflow } from '../../providers/WorkflowProvider'
import WorkflowMenu from '../misc/WorkflowMenu'
import { useWorkflowMutations } from '../../hooks/mutations'
import useOpenWorkflowBuilder from '../../hooks/useOpenWorkflowBuilder'
import useOpenWorkflowDetails from '../../hooks/useOpenWorkflowDetails'
import useGoToWorkflowList from '../../hooks/useGoToWorkflowList'

export const WorkflowBuilderHeaderControls = () => {
  const { readonly: canOpenEditor, workflow, openDetailsSidePanel } = useWorkflow()
  const openBuilder = useOpenWorkflowBuilder(workflow)
  const goToWorkflowDetails = useOpenWorkflowDetails(workflow)
  const goToWorkflowList = useGoToWorkflowList()
  const { publish, remove, invalidate, isMutating } = useWorkflowMutations(true)
  const { t } = useTranslation()

  const menuItems = [
    canOpenEditor && {
      name: t('general.edit', 'Edit'),
      onClick: openBuilder,
    },
    {
      name: t('general.history', 'History'),
      onClick: openDetailsSidePanel,
    },
    {
      name: t('general.remove', 'Delete'),
      onClick: async () => {
        await remove(workflow)
        goToWorkflowList()
        invalidate()
      },
    },
  ]

  const handlePublish = async () => {
    await publish(workflow)
    goToWorkflowDetails()
    invalidate()
  }

  return (
    <div className="flex flex-row gap-2 items-center">
      {!workflow.published_at && (
        <WorkflowActionButton isLoading={isMutating} onClick={handlePublish}>
          {t('general.publish')}
        </WorkflowActionButton>
      )}
      <WorkflowMenu items={menuItems} />
    </div>
  )
}

export default WorkflowBuilderHeaderControls
