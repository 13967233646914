import { Combobox } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ChangeEvent, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { CgSpinner } from 'react-icons/cg'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import Button from '../buttons/Button'

export interface ISearchSelectOptions extends INodeChildren {
  onClear?: () => void
  disabled?: boolean
  optionsElements: React.ReactNode
  currentSelection?: InputSearchSelectItemType
  handleInputChange?: (e: ChangeEvent<HTMLInputElement>) => any
  placeholder?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
  wrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
  nullable?: boolean
  error?: string
  onAdd?: () => void
  addButtonText?: string
  isLoading?: boolean
  alwaysVisible?: boolean
}

const SearchSelectOptions: React.FC<ISearchSelectOptions> = ({
  onAdd,
  addButtonText,
  optionsElements,
  children,
  isLoading = false,
  alwaysVisible = false,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <Combobox.Options
      static={alwaysVisible}
      as="div"
      className={classNames(
        'z-10 max-h-60 w-full overflow-auto shadow rounded-b-md border border-t-0  ',
        'bg-white text-base focus:outline-none sm:text-sm',
        { absolute: !alwaysVisible },
        className
      )}
    >
      {optionsElements}
      {isLoading && (
        <div className="py-2 place-content-center flex">
          <CgSpinner className="animate-spin text-xl" />
        </div>
      )}
      {children && <div>{children}</div>}
      {onAdd && (
        <div>
          <Button
            onClick={onAdd}
            className="no-focus-button w-full rounded-t-none hover:bg-sky-blue-light hover:text-white group"
          >
            <PlusIcon className="w-4 " />
            <span className="">{addButtonText || t('generic.add_new')}</span>
          </Button>
        </div>
      )}
    </Combobox.Options>
  )
}

export default SearchSelectOptions
