import { useCallback, useState } from 'react'

const useIsInViewport = (options?: IntersectionObserverInit) => {
  const [isInViewport, setIsInViewport] = useState(false)

  const setViewportObserverFor = useCallback((element: HTMLDivElement | null | undefined) => {
    const observer = new IntersectionObserver(([entry]) => setIsInViewport(entry.isIntersecting), options)

    element && observer.observe(element)

    return () => {
      element && observer.unobserve(element)
    }
  }, [])

  return { isInViewport, setViewportObserverFor }
}
export default useIsInViewport
