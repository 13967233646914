import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination } from '../../../../shared/components/atoms/SharedTable/Pagination'
import TableRow from '../../../../shared/components/atoms/SharedTable/TableRow'
import TableCell from '../../../../shared/components/atoms/SharedTable/TableCell'
import Menu from '../../../../shared/components/organisms/menus/Menu'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import useSearch from '../../../Search/Hooks/useSearch'
import { HitsType } from '../../../Search/SearchTypes'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'
import Table from '../../../../shared/components/atoms/SharedTable/Table'
import useUrlParams from '../../../LhotseHome/hooks/useUrlParams'
import useOpenSupplierDetailsPage from '../../../SupplierDetails/hooks/useOpenSupplierDetailsPage'
import { SupplierSearchBaseType } from '../../../Search/Types/SearchEntitiesTypes'

const SuppliersResults: FC = () => {
  const [rowSelected, setRowSelected] = useState<number | undefined>()
  const [totalPages, setTotalPages] = useState(0)
  const openDetailsModal = useOpenSupplierDetailsPage(rowSelected)
  const { setParam, getParam } = useUrlParams()
  const query = getParam('query')
  const changePage = (selected: number) => setParam({ page: selected.toString() })
  const { t } = useTranslation()

  const { data, isLoading } = useSearch<SupplierSearchBaseType>({
    entity: 'suppliers',
    queryFilters: {
      query: query ?? '',
      page: Number(getParam('page')) ?? 1,
    },
  })

  useEffect(() => {
    if (data && data.last_page > 0 && data.last_page !== totalPages) {
      setTotalPages(data.last_page)
    }
    if (query) {
      changePage(1)
    }
  }, [data?.last_page, query])

  useEffect(() => {
    if (rowSelected) {
      openDetailsModal()
    }
  }, [rowSelected])

  if (data?.total === 0 && !isLoading) return <div>{t('generic.no_results')}</div>
  const handleRowClick = (id: string | undefined): void => {
    if (!id) return
    setRowSelected(Number(id))
  }

  const getCurrentPage = (): number => Number(getParam('page') && getParam('page') !== '' ? getParam('page') : 1) - 1

  return (
    <>
      <TableContainer>
        <TableHead>
          <TableHeader>{t('generic.name', 'Name')}</TableHeader>
          <TableHeader>{t('generic.requestor12', 'Tags')}</TableHeader>
          <TableHeader>{t('generic.requestor12', 'Default user')}</TableHeader>
          <TableHeader>{t('generic.requestor12', 'Created at')}</TableHeader>
          <TableHeader />
        </TableHead>
        <Table isLoading={isLoading}>
          {data?.data.hits.map((submission: HitsType<SupplierSearchBaseType>) => (
            <TableRow
              onClick={handleRowClick}
              key={submission.document.id}
              referenceId={submission.document.id.toString()}
            >
              <TableCell>
                <span className="line-clamp-1 max-w-[170px]">{submission.document.name}</span>
              </TableCell>
              <TableCell>
                <span className="line-clamp-1">
                  {submission?.document.tags.length ? submission?.document.tags.length : '-'}
                </span>
              </TableCell>
              <TableCell>
                <span className="line-clamp-1">{submission?.document.email ?? '-'}</span>
              </TableCell>
              <TableCell>{new Date(submission?.document.created_at).toLocaleDateString('de-DE')}</TableCell>
              <TableCell>
                <Menu
                  menuItems={[
                    {
                      name: 'Delete',
                      onClick: () => console.log('test'),
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
      <div className="flex justify-end items-center text-sm text-secondary-gray mr-2 p-2">
        <span className="mr-2">{`${t('generic.results', 'Results')}:`}</span>
        <div>{isLoading ? <CoreLoadingIcon /> : data?.total ?? 0}</div>
      </div>
      <div className="mt-4 w-full xl:w-1/2 self-center flex justify-center">
        <Pagination
          forcePage={getCurrentPage()}
          pageCount={data?.last_page ?? 0}
          onPageChange={({ selected }) => changePage(selected + 1)}
        />
      </div>
    </>
  )
}

export default SuppliersResults
