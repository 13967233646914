import IntakeFormNavHeaderButton from './IntakeFormNavHeaderButton'
import useIntakeFormStore from '../../stores/useIntakeFormStore'
import { IntakeSectionType } from '../../../../../types/Intake'

interface IFormSectionNavigationHeader {
  formSections: IntakeSectionType[] | undefined
}
const IntakeFormNavHeader: React.FC<IFormSectionNavigationHeader> = ({ formSections }) => {
  const activeSectionIndex = useIntakeFormStore((state) => state.activeSectionIndex)

  // Calculate the position of the progress bar
  let positionPercent = `${0}%`
  if (formSections) {
    const positionDecimal = ((activeSectionIndex + 1) / formSections.length) * 100
    const middleAlignPercentage = 100 / formSections.length / 2
    const targetPosition = positionDecimal - middleAlignPercentage
    positionPercent = `${targetPosition}%`
  }

  const sectionEnabled = (section: IntakeSectionType) => {
    if (section.type_name === 'OFFERINGS' || section.type_name === 'CONFIRMATION') {
      return true
    }
    return section.fields.some((field) => field.visible)
  }

  return (
    <div className="flex flex-col bg-white  pt-4 gap-y-4">
      <div
        className="flex font-semibold max-w-screen overflow-auto
      justify-between w-full -mb-0.5 "
      >
        {formSections?.map((section, index) => (
          <IntakeFormNavHeaderButton
            key={section.id}
            active={activeSectionIndex === index}
            enabled={sectionEnabled(section)}
            title={section.name}
            sectionIndex={index}
          />
        ))}
      </div>
      <div className="overflow-hidden rounded-full w-full bg-gray-300">
        <div className="h-1 rounded-full bg-sky-blue" style={{ width: positionPercent }} />
      </div>
    </div>
  )
}

export default IntakeFormNavHeader
