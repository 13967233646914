import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../../../../shared/components/atoms/Dialog/Dialog'
import Button from '../../../../../../../../shared/components/atoms/buttons/Button'
import useUpdateTaskStatusMutation from '../../../../../hooks/useUpdateTaskStatusMutation'
import useGetBlockActions from '../../../../../hooks/useGetBlockActions'
import { WorkflowBlock } from '../../../../../../../../types/Workflow/WorkflowBlock'
import { TaskActionObject } from '../../../../../../../../types/Workflow/WorkflowAction'
import Checkbox from '../../../../../../../../shared/components/atoms/inputs/Checkbox'
import useBlockStatus from '../hooks/useBlockStatus'
import useGetCurrentUser from '../../../../../../../../core/hooks/useGetCurrentUser'

interface IIntakeFlowchartActionDialog {
  block: WorkflowBlock
}

const IntakeFlowchartActionDialog: FC<IIntakeFlowchartActionDialog> = ({ block }) => {
  const { t } = useTranslation()

  const { data: selectedBlockActions } = useGetBlockActions(block)
  const { data: currentUser } = useGetCurrentUser()

  const { mutate: updateTaskStatus } = useUpdateTaskStatusMutation()
  const { isEditable: isSelectedBlockEditable } = useBlockStatus(block)

  // filter actions to only show tasks
  const taskActions = selectedBlockActions?.filter((action) => action.slug === 'task_action')

  const isTaskDisabled = (task: TaskActionObject) => {
    // case 1. task belongs to a block which is already approved
    if (
      taskActions &&
      taskActions.some((someTaskAction) => {
        const taskActionObject = someTaskAction.object as TaskActionObject
        return taskActionObject.id === task.id
      }) &&
      !isSelectedBlockEditable
    ) {
      return true
    }

    // case 2. user is not assigned to the task
    if (
      task.assignees?.length === 0 ||
      !task.assignees.some((assignee) => assignee.id === currentUser?.id || assignee.user_id === currentUser?.id)
    ) {
      return true
    }

    return false
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="flex-grow-1 btn-primary w-full text-white justify-center"
          onClick={(e) => {
            e.stopPropagation()
          }}
          disabled={false}
        >
          <div className="flex flex-row gap-2">
            {t('intake.flowchart.actionNodeContent.seeMore', 'See more details')}
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>{t('workflow.tasks', 'Tasks')}</DialogTitle>
          <DialogDescription className="text-gray-500 text-sm">
            {t(
              'workflow.actions_dialog_description',
              'Here you will find a list of tasks that requires your attention'
            )}
          </DialogDescription>
        </DialogHeader>
        {taskActions && taskActions.length > 0 && (
          <div className="text-black-light-2 text-sm pb-2">
            {taskActions.map(({ status_uuid, object }) => {
              const taskActionObject = object as TaskActionObject

              return (
                <div key={`task-${taskActionObject.id}`} className="flex flex-row my-4 space-y-3 items-center">
                  <div className="flex-grow text-sm font-medium text-gray-600">{taskActionObject!.name}</div>
                  <div className="">
                    <Checkbox
                      disabled={isTaskDisabled(taskActionObject!)}
                      onChange={(v) => {
                        updateTaskStatus({
                          ...taskActionObject!,
                          status: v ? 1 : 0,
                          blockUuid: block.uuid,
                          status_uuid,
                        })
                      }}
                      checked={taskActionObject!.status === 1}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )}
        <DialogFooter className="sm:justify-end" />
      </DialogContent>
    </Dialog>
  )
}

export default IntakeFlowchartActionDialog
