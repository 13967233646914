import React from 'react'
import { useWorkflow } from '../../providers/WorkflowProvider'
import { Toggle, ToggleProps } from '../../../../shared/components/atoms/Toggle'

// eslint-disable-next-line comma-spacing
export const WorkflowToggle = <T,>(props: Omit<ToggleProps<T>, 'disabled'>) => {
  const { readonly } = useWorkflow()

  return <Toggle {...props} disabled={readonly} />
}

export default WorkflowToggle
