import { FormQuestionType, FormSectionType } from '../../../../../../types/Forms/formTypes'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateForm from '../../../hooks/useUpdateForm'

export default (section: FormSectionType, question: FormQuestionType) => {
  const { data: formData } = useGetFormData()
  const { updateForm } = useUpdateForm()

  return () => {
    if (!formData) return

    updateForm({
      template: {
        sections: formData.template.sections.map((templateSection) =>
          templateSection.__uuid === section.__uuid
            ? {
                ...templateSection,
                fields: templateSection.fields.filter(
                  (templateQuestion) => templateQuestion.__uuid !== question.__uuid
                ),
              }
            : templateSection
        ),
      },
    })
  }
}
