import { HashtagIcon } from '@heroicons/react/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import useGetTransmissions from '../../hooks/useGetTransmissions'
import { useIntakeFlow } from '../../providers/IntakeFlowProvider'
import { useUtils } from '../../../../../core/providers/UtilsProvider'

export const IntakeFlowOverviewPageHeaderTransmission = () => {
  const { intake, activeWorkflow } = useIntakeFlow()

  const hasActiveTransmissionSteps =
    (activeWorkflow?.steps
      .flatMap((step) =>
        step.blocks
          .flatMap((block) => block.actions)
          .filter((action) => ['AdvarioTransmissionAction'].includes(action?.name || ''))
      )
      .filter((action) => action?.status === 'ongoing')?.length || 0) > 0

  const { data: transmissions } = useGetTransmissions('INTAKE_FLOW', intake?.uuid || '', hasActiveTransmissionSteps)
  const { t } = useTranslation()
  const { modal } = useUtils()

  const latestTransmission = transmissions?.[(transmissions?.length || 1) - 1] || undefined

  const handleModal = () => {
    modal.set({
      isOpen: true,
      content: (
        <div lang="xml" className="pt-8">
          {latestTransmission?.response_payload?.[0]}
        </div>
      ),
    })
  }

  return (
    <>
      {latestTransmission?.external_creation_id && (
        <div className="flex space-x-2  items-center">
          <HashtagIcon className="w-4" />
          <span>{latestTransmission?.external_creation_id}</span>
        </div>
      )}
      {latestTransmission?.status === 'error' && (
        <button
          className="flex space-x-2 text-white bg-red-light px-2 rounded-md py-1 items-center"
          onClick={handleModal}
          type="button"
        >
          <ExclamationTriangleIcon className="w-4" />
          <span>
            {t(
              'generic.advario-transmission-error',
              'Error during transmission. You do not have to do anything. Lhotse is on it!'
            )}
          </span>
        </button>
      )}
    </>
  )
}

export default IntakeFlowOverviewPageHeaderTransmission
