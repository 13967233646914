import {
  FormQuestionContentType,
  FormLogicComparisonType,
  FormQuestionType,
} from '../../../../../../../../../types/Forms'

export type LogicComparisonOption = {
  id: FormLogicComparisonType
  label: string
}

export default (question: FormQuestionType) => {
  const questionOptions: Record<FormQuestionContentType, LogicComparisonOption[]> = {
    TEXT: [
      { id: 'equal', label: 'Is equal to' },
      { id: 'not_equal', label: 'Is not equal to' },
      { id: 'starts_with', label: 'Starts with' },
      { id: 'ends_with', label: 'Ends with' },
    ],

    TEXT_AREA: [
      { id: 'equal', label: 'Is equal to' },
      { id: 'not_equal', label: 'Is not equal to' },
      { id: 'contains', label: 'Answers contain' },
    ],

    KEY_VALUE: [{ id: 'contains', label: 'Answers contain' }],

    DATE: [
      { id: 'equal', label: 'Is on' },
      { id: 'not_equal', label: 'Is not on' },
      { id: 'greater_than', label: 'After' },
      { id: 'less_than', label: 'Before' },
    ],

    DATE_TIME: [
      { id: 'equal', label: 'Is on' },
      { id: 'not_equal', label: 'Is not on' },
      { id: 'greater_than', label: 'After' },
      { id: 'less_than', label: 'Before' },
    ],

    DATE_RANGE: [
      { id: 'equal', label: 'Is on' },
      { id: 'not_equal', label: 'Is not on' },
      { id: 'greater_than', label: 'After' },
      { id: 'less_than', label: 'Before' },
    ],
    DATE_TIME_RANGE: [
      { id: 'equal', label: 'Is on' },
      { id: 'not_equal', label: 'Is not on' },
      { id: 'greater_than', label: 'After' },
      { id: 'less_than', label: 'Before' },
    ],

    NUMBER: [
      { id: 'equal', label: 'Is equal to' },
      { id: 'not_equal', label: 'Is not equal to' },
      { id: 'greater_than', label: 'Greater than' },
      { id: 'less_than', label: 'Less than' },
    ],

    PRICE: [
      { id: 'equal', label: 'Is equal to' },
      { id: 'not_equal', label: 'Is not equal to' },
      { id: 'greater_than', label: 'Greater than' },
      { id: 'less_than', label: 'Less than' },
    ],

    SELECT: [
      { id: 'empty', label: 'Is empty' },
      { id: 'not_empty', label: 'Is not empty' },
      { id: 'equal', label: 'Equals' },
      { id: 'not_equal', label: 'Does not equal' },
    ],

    TOGGLE: [
      { id: 'equal', label: 'Equals' },
      { id: 'not_equal', label: 'Does not equal' },
    ],

    MULTI_SELECT: [{ id: 'contains', label: 'Includes' }],

    ADDRESS: [
      { id: 'empty', label: 'Is empty' },
      { id: 'not_empty', label: 'Is not empty' },
      { id: 'equal', label: 'Equals' },
      { id: 'not_equal', label: 'Does not equal' },
    ],

    SUPPLIER: [
      { id: 'empty', label: 'Is empty' },
      { id: 'not_empty', label: 'Is not empty' },
    ],

    USER: [
      { id: 'empty', label: 'Is empty' },
      { id: 'not_empty', label: 'Is not empty' },
    ],

    MULTI_USER: [
      { id: 'empty', label: 'Is empty' },
      { id: 'not_empty', label: 'Is not empty' },
    ],

    FILE: [
      { id: 'empty', label: 'Is empty' },
      { id: 'not_empty', label: 'Is not empty' },
    ],

    SEARCH_SELECT: [
      { id: 'empty', label: 'Is empty' },
      { id: 'not_empty', label: 'Is not empty' },
      { id: 'equal', label: 'Is equal to' },
      { id: 'not_equal', label: 'Is not equal to' },
    ],
  }

  if ((question.type === 'TEXT' || question.type === 'TEXT_AREA') && (question?.__settings?.answers?.min || 0) > 1) {
    questionOptions.TEXT = [
      { id: 'contains', label: 'Answers contain' },
      { id: 'not_contains', label: 'Answers dont contain' },
    ]
  }

  return questionOptions[question.type]
}
