import { pick } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseInputWrapper from '../../../../../../../../../shared/components/atoms/Input/BaseInputWrapper'
import Input from '../../../../../../../../../shared/components/atoms/Input/Input'
import TextareaInput from '../../../../../../../../../shared/components/atoms/Input/TextareaInput'
import PriceInput from '../../../../../../../../../shared/components/organisms/PriceInput/PriceInput'
import { germanPriceStringToFloat } from '../../../../../../../../../shared/constants/currencyFormat'
import { Offer, OfferItem } from '../../../../../../../../../types/Offering'
import FormNumericInput from '../../../../../../../../Forms-v2/FormInputs/FormNumericInput'
import OfferModalFiles from './OfferModalFiles'
import IndicativeSwitch from './components/IndicativeSwitch'
import OfferSupplierField from './components/OfferSupplierField'
import Calendar from '../../../../../../../../../shared/components/organisms/Calendar/Calendar'
import { calendarDateFormat } from '../../../../../../../../../shared/constants/dateFormat'
import OfferSelectionField from '../OfferSelectionField'

type IProductSectionOfferInline = {
  offerItem?: OfferItem | Partial<OfferItem>
  offers?: Offer[]
  updateOffer: (offer: OfferItem) => Promise<void>
  addOffer?: (offer: OfferItem | Partial<OfferItem>) => Promise<OfferItem>
  isCreating?: boolean
}

export type PriceCalculationValues = {
  quantity?: number
  price_per_unit?: number
  vat?: number
}
const OfferInline: React.FC<IProductSectionOfferInline> = ({
  offerItem,
  offers,
  updateOffer,
  addOffer,
  isCreating,
}) => {
  const { t } = useTranslation()
  const [currentOfferItem, setCurrentOfferItem] = useState<OfferItem | Partial<OfferItem> | undefined>(offerItem)
  const [prices, setPrices] = useState({ total_net: 0 })
  const currentOfferExists = !!offers?.find((offer) => offer.uuid === currentOfferItem?.offer?.uuid)

  const handleCreateOffer = async (immediateData?: Partial<OfferItem>) => {
    const newOffer = addOffer && (await addOffer({ ...currentOfferItem, ...immediateData, is_selected: true }))
    setCurrentOfferItem(newOffer)
    return newOffer
  }

  const submit = async (immediateData?: Partial<OfferItem>) => {
    if (currentOfferItem?.id) {
      const newOffer = { ...currentOfferItem, ...immediateData, is_selected: true } as OfferItem
      await updateOffer(newOffer)
    } else if (currentOfferItem?.offer || (currentOfferItem?.offer === undefined && immediateData?.offer)) {
      // Only create an offer item if there is an offer (since it is required by the BE)
      await handleCreateOffer({ ...currentOfferItem, ...immediateData, is_selected: true })
    }
  }

  const handlePriceCalculation = ({ quantity = 1, price_per_unit = 0 }: PriceCalculationValues) => {
    const total_net = quantity * price_per_unit
    setTimeout(() => {
      setPrices({
        total_net,
      })
    }, 500)
  }

  useEffect(() => {
    handlePriceCalculation({
      quantity: currentOfferItem?.quantity || 1,
      price_per_unit: currentOfferItem?.price_per_unit || 0,
      vat: currentOfferItem?.vat || 19,
    })
  }, [])

  const handleValueChange = (key: string, value: string | number | Date | boolean | null) => {
    const newValue = { ...currentOfferItem, [key]: value }
    setCurrentOfferItem(newValue)
    submit(newValue)
  }

  const handleOfferValueChange = (values: Partial<Offer>) => {
    const updatedOffer = { ...currentOfferItem?.offer, ...values }

    // update offerItem.offer
    const updatedOfferItem = {
      ...currentOfferItem,
      offer: updatedOffer,
    }

    setCurrentOfferItem(updatedOfferItem)
    submit(updatedOfferItem)
  }

  const handleSelectedOfferChange = (value: string) => {
    const selectedOffer = offers?.find((offer) => offer.uuid === value)
    if (value === 'new-offer') {
      // If "Create a new offer" is selected, reset the selected offer
      const updatedOfferItem = {
        ...currentOfferItem,
        offer: {},
      }
      setCurrentOfferItem(updatedOfferItem)
    }

    if (selectedOffer) {
      handleOfferValueChange(selectedOffer)
    }
  }

  return (
    <div>
      <div className="gap-4 grid">
        <IndicativeSwitch currentOffer={currentOfferItem} handleValueChange={handleValueChange} />
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-6">
            <BaseInputWrapper label={t('offer.select_or_create_offer', 'Select existing offer or create a new one')}>
              <OfferSelectionField
                offers={offers}
                selected={currentOfferItem?.offer?.uuid}
                onSelectedOfferChange={handleSelectedOfferChange}
              />
            </BaseInputWrapper>
          </div>
          <div className="col-span-6">
            <BaseInputWrapper
              label={
                currentOfferItem?.is_indicative
                  ? t('generic.select-preferred-supplier', 'Select preferred supplier')
                  : t('generic.select-supplier', 'Select supplier')
              }
            >
              <OfferSupplierField
                disabled={isCreating || currentOfferExists}
                onAdd={(supplier) => handleValueChange('supplier', supplier.name || '')}
                onSelect={(supplier) => {
                  // only update if the supplier has changed
                  if (
                    currentOfferItem?.offer?.supplier_branch_id !== supplier.supplier_branch_id ||
                    currentOfferItem?.offer?.supplier_user_id !== supplier.supplier_user_id
                  ) {
                    handleOfferValueChange(pick(supplier, ['supplier_branch_id', 'supplier_user_id']))
                  }
                }}
                selected={{
                  supplier_branch_id: currentOfferItem?.offer?.supplier_branch?.id,
                  supplier_user_id: currentOfferItem?.offer?.supplier_user?.id,
                }}
              />
            </BaseInputWrapper>
          </div>
          {!currentOfferItem?.is_indicative && (
            <div className="col-span-3">
              <Input
                key={`offer-id-field-${currentOfferItem?.offer?.custom_id || ''}`}
                disabled={isCreating || currentOfferExists}
                type="text"
                label={t('generic.offer-id', 'Offer Id')}
                id="custom_id"
                defaultValue={currentOfferItem?.offer?.custom_id || ''}
                onBlur={(value) => handleOfferValueChange({ custom_id: value as string })}
              />
            </div>
          )}

          <div className="col-span-6">
            <BaseInputWrapper label={t('offer.file', 'Offer File')}>
              <OfferModalFiles
                offer={currentOfferItem?.offer}
                onFilesChange={(files) => handleOfferValueChange({ files })}
                disabled={isCreating || currentOfferExists}
              />
            </BaseInputWrapper>
          </div>

          <div className="col-span-6 border-t my-4" />

          <div className="col-span-3">
            <Calendar
              dateFormat={calendarDateFormat}
              disabled={isCreating}
              showTimeSelect={false}
              onChange={(val) => {
                handleValueChange('delivery_date', val)
              }}
              label={
                currentOfferItem?.is_indicative
                  ? t('generic.delivery-date-Indicative', 'Delivery Date Indicative')
                  : t('generic.delivery-date', 'Delivery Date')
              }
              selected={(currentOfferItem?.delivery_date && new Date(currentOfferItem.delivery_date)) || undefined}
            />
          </div>

          <div className="col-span-6">
            <TextareaInput
              disabled={isCreating}
              type="number"
              label={t('generic.comment', 'Comment')}
              id="comment"
              key="comment"
              defaultValue={currentOfferItem?.comment}
              onBlur={(e) => {
                handleValueChange('comment', e as string)
              }}
            />
          </div>

          <div className="col-span-6 grid grid-cols-4  gap-4">
            <div>
              <Input
                disabled={isCreating}
                type="number"
                min={1}
                label={
                  currentOfferItem?.is_indicative
                    ? t('generic.quantity-indicative', 'Quantity Indicative')
                    : t('generic.quantity', 'Quantity')
                }
                id="quantity"
                defaultValue={currentOfferItem?.quantity || 1}
                onBlur={(e) => {
                  handleValueChange('quantity', Math.max(1, e as number))
                  handlePriceCalculation({
                    quantity: e as number,
                    price_per_unit: currentOfferItem?.price_per_unit || 0,
                  })
                }}
              />
            </div>
            <div className="col-span-2">
              <BaseInputWrapper
                label={
                  currentOfferItem?.is_indicative
                    ? t('generic.unit-price-indicative', 'Unit price Indicative')
                    : t('generic.unit-price', 'Unit price ')
                }
              >
                <PriceInput
                  disabled={isCreating}
                  onCurrencyChange={(selection) => {
                    handleValueChange('currency_code', selection?.id.toString() || 'EUR')
                  }}
                  currentCurrency={currentOfferItem?.currency_code}
                  onBlur={(val) => {
                    handleValueChange('price_per_unit', germanPriceStringToFloat(val.currentTarget.value))
                    handlePriceCalculation({
                      quantity: currentOfferItem?.quantity,
                      price_per_unit: germanPriceStringToFloat(val.currentTarget.value),
                    })
                  }}
                  placeholder={t('type_number')}
                  defaultValue={currentOfferItem?.price_per_unit?.toString() || '0'}
                />
              </BaseInputWrapper>
            </div>
            <BaseInputWrapper label={t('generic.total-net-price', 'Total Net Price')}>
              <FormNumericInput disabled onChange={() => {}} value={prices.total_net} className="text-right" />
            </BaseInputWrapper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferInline
