import { useQuery } from 'react-query'
import { getActiveWorkflow } from '../services'
import { ActiveWorkflow } from '../../../../types/Workflow/ActiveWorkflow'
import { Query } from '../../../../types/shared/Api'

export type ActiveWorkflowQuery = Query<ActiveWorkflow>

export const useGetWorkflow = (
  workflowUuid: string | undefined | null,
  refetchInterval: number = 0
): ActiveWorkflowQuery =>
  useQuery(['intake', workflowUuid, 'workflow'], () => getActiveWorkflow(workflowUuid!), {
    enabled: !!workflowUuid,
    refetchInterval,
  })

export default useGetWorkflow
