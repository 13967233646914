import CoreSidebarLink from './CoreSidebarLink'
import CoreSidebarLogo from './CoreSidebarLogo'
import CreateNewRequisitionButton from './CreateNewRequisitionButton'
import useGetNavigationItems from './useGetNavigationItems'

interface ICoreSidebarNavigationItems {
  minified: boolean
}

const CoreSidebarNavigationItems: React.FC<ICoreSidebarNavigationItems> = ({ minified }) => {
  const navItems = useGetNavigationItems()

  return (
    <div className={`flex flex-col space-y-4 ${minified && 'items-center'}`}>
      <CoreSidebarLogo minified={minified} />
      <ul className="flex flex-col space-y-6 w-full">
        <CreateNewRequisitionButton minified={minified} />
        {navItems.map((item) => (
          <CoreSidebarLink key={item.href} item={item} minified={minified} visible={item.visible} />
        ))}
      </ul>
    </div>
  )
}

export default CoreSidebarNavigationItems
