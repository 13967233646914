import { useTranslation } from 'react-i18next'
import UserRow from '../../../shared/components/organisms/UserRow/UserRow'
import useGetSupplierBranchUsers from '../hooks/useGetSupplierBranchUsers'

interface ISupplierUsers {
  className?: string
  editable?: boolean
}

const SupplierUsers: React.FC<ISupplierUsers> = ({ className, editable }) => {
  const { data: usersData } = useGetSupplierBranchUsers()
  const sortedUsers = usersData?.sort((user) => (!user.main ? 1 : -1))

  const { t } = useTranslation()

  if (!usersData?.length) {
    return <p className={`text-sm text-primary-gray ${className}`}>{t('supplier.no_users_available')}</p>
  }

  return (
    <div className={`flex flex-col gap-y-6 ${className}`}>
      {sortedUsers?.map((user) => <UserRow user={user} key={user.id} editable={editable} />)}
    </div>
  )
}

export default SupplierUsers
