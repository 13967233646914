import classnames from 'classnames'
import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import useWorkflowBlockDraftMutations from '../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'
import Checkbox from '../../../../../shared/components/atoms/Checkbox/Checkbox'
import { useWorkflow } from '../../../providers/WorkflowProvider'
import InputError from '../../../../Forms/FormBuilder/QuestionTypes/atoms/InputError'
import WorkflowBuilderApprovalDialog from './WorkflowBuilderApprovalDialog'

export const WorkflowBuilderBlockApprovals = ({ className, ...divProps }: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation()
  const { block, errors } = useWorkflowBlockDraft()
  const { toggleSkipRequestorApproval } = useWorkflowBlockDraftMutations()
  const { readonly } = useWorkflow()

  return (
    <div className={classnames('relative flex flex-col gap-6 text-black-light', className)} {...divProps}>
      <div className="flex flex-col">
        <h2 className="text-left font-semibold text-md">{t('workflow.block.approvals', 'Approvals')}</h2>
        <hr className="border-rock-gray-light mt-2" />
      </div>
      <Checkbox
        id={block.uuid}
        label={t('workflow.skip-for-requestor', 'Skip for Requestor')}
        checked={block.options?.allowApproveUserRequester || false}
        onChange={toggleSkipRequestorApproval}
        disabled={readonly}
      />
      <div className="flex flex-col gap-6">
        <WorkflowBuilderApprovalDialog block={block} readonly={readonly} />
      </div>
      <InputError className="absolute bottom-[-28px] font-normal" error={errors.approvers} />
    </div>
  )
}

export default WorkflowBuilderBlockApprovals
