import CoreLoadingIcon from '../../../core/components/CoreLoadingIcon'
import { useSuppliersProvider } from '../../Suppliers/providers/SuppliersProvider'
import useIsSupplierDetailsLoading from '../hooks/useIsSupplierDetailsLoading'
import AllTags from '../SupplierDetailsPage/AllTags'
import GeneralTabBody from './GeneralTabBody'

const SupplierDetailsModal: React.FC = () => {
  const {
    supplierBranchId: [supplierBranchId],
    activeSupplierModalTab: [activeSupplierModalTab],
  } = useSuppliersProvider()

  const isLoading = useIsSupplierDetailsLoading()

  if (isLoading || !supplierBranchId) {
    return (
      <div className="flex items-center justify-center h-72">
        <CoreLoadingIcon />
      </div>
    )
  }

  if (activeSupplierModalTab === 'general') return <GeneralTabBody />
  if (activeSupplierModalTab === 'tags') return <AllTags className="px-0 py-0" />

  return null
}

export default SupplierDetailsModal
