import { useGetWorkflow } from './useGetWorkflow'
import useGetCurrentIntake from './useGetCurrentIntake'

export const useGetCurrentWorkflow = () => {
  const { data: intakeData } = useGetCurrentIntake()

  return useGetWorkflow(intakeData?.active_workflow_uuid)
}

export default useGetCurrentWorkflow
