const STORAGE_ATTRIBUTE = 'lhotseAuth'

export function getLocalInfo() {
  const lhotseAuth = localStorage.getItem(STORAGE_ATTRIBUTE)

  if (lhotseAuth) {
    const parsedAuth = JSON.parse(lhotseAuth)
    return parsedAuth
  }
  return {}
}

export const setLocalInfo = (data: any) => {
  const storageData = getLocalInfo()
  localStorage.setItem(STORAGE_ATTRIBUTE, JSON.stringify({ ...storageData, ...data }))
  localStorage.setItem('language', data?.language || 'de')
}
