import Jira from './Jira.svg'
import Slack from './Slack.svg'
import MSTeams from './MS-Teams.svg'
import Webhook from './Webhook.svg'
import DocuSign from './DocuSign.svg'
import SAP from './SAP.svg'
import SAPAriba from './SAP-Ariba.svg'
import OpenAI from './Open-AI.svg'
import Unite from './Unite.svg'

export default {
  Jira,
  Webhook,
  Slack,
  MSTeams,
  DocuSign,
  SAP,
  SAPAriba,
  OpenAI,
  Unite,
}
