// apiGetInputById

import { useQuery } from 'react-query'
import { useMemo } from 'react'
import companyQueryStrings from '../../Settings/constants/companyQueryStrings'
import { apiGetInputById } from '../../IntakeConfiguration/services/CompanyInputsService'

export const useGetInputById = (inputId: string | null | undefined) => {
  const query = useQuery([companyQueryStrings.company_inputs, inputId], () => apiGetInputById(inputId || ''), {
    staleTime: 1000 * 60 * 5, // ms
  })

  return useMemo(
    () => ({
      ...query,
    }),
    [query]
  )
}

export default useGetInputById
