import { useTranslation } from 'react-i18next'
import { StickySidePanel } from '../../../../shared/components/layout/StickySidePanel'
import { useWorkflow } from '../../providers/WorkflowProvider'
import WorkflowVersionEntry from './components/WorkflowVersionEntry'

export const WorkflowBuilderDetailsSidePanel = () => {
  const { t } = useTranslation()
  const { detailsSidePanelOpen, closeSidePanel, workflow } = useWorkflow()

  return (
    <StickySidePanel open={detailsSidePanelOpen} onToggle={closeSidePanel} title={`${t('generic.history', 'History')}`}>
      <div className="flex-col flex gap-5 w-full">
        {workflow.versions.map((version) => (
          <WorkflowVersionEntry {...version} key={`workflow-${workflow.uuid}-version-${version.version}`} />
        ))}
      </div>
    </StickySidePanel>
  )
}

export default WorkflowBuilderDetailsSidePanel
