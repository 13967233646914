import { useTranslation } from 'react-i18next'
import WorkflowAddButton from './WorkflowAddButton'
import { useNewWorkflowModal } from '../NewWorkflowModal'

export const CreateNewWorkflowButton = () => {
  const { t } = useTranslation()

  const openNewWorkflowModal = useNewWorkflowModal()

  return (
    <WorkflowAddButton onClick={openNewWorkflowModal}>
      {t('workflows.add_new', 'Create new workflow')}
    </WorkflowAddButton>
  )
}

export default CreateNewWorkflowButton
