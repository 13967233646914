import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { sanitize } from 'dompurify'
import {
  HiOutlineArrowPath,
  HiOutlineDocumentDuplicate,
  HiOutlinePlusCircle,
  HiOutlineSparkles,
  HiXMark,
} from 'react-icons/hi2'
import { IntakeFieldType } from '../../../../../types/Intake'
import useIntakeFormStore from '../../../IntakeFormResponse/stores/useIntakeFormStore'
import { getApiUrl } from '../../../../../core/utils/tenancy'
import { getToken } from '../../../../../core/utils/apiClient'
import { FormFieldSettings } from '../../../../Forms-v2/types'
import FormFieldCopilot from '../../../../Forms-v2/Copilot/FormFieldCopilot'
import usePrepareIntakeFormField from '../../../IntakeFormResponse/hooks/usePrepareIntakeFormField'
import { toastr } from '../../../../../shared/components/organisms/toastr'
import cn from '../../../../../lib/util'
import useGetEmbeddingsData from '../../hooks/useGetEmbeddingsData'
import useSearchSelectOptions from '../../../../../shared/hooks/useSearchSelectOptions'

interface IIntakeFormFieldCoPilot {
  field: IntakeFieldType
  updateField: (field: FormFieldSettings<string>) => void
  editModeOn?: boolean
  disabled?: boolean
}

type StreamStatus = 'idle' | 'loading' | 'open' | 'close' | 'error'

const IntakeFormFieldCoPilot: FC<IIntakeFormFieldCoPilot> = ({ field, updateField, editModeOn, disabled }) => {
  const { shouldShowSubtitle, readonly, label, errorMessage, handleUpdateField, showErrors, isFieldHidden } =
    usePrepareIntakeFormField(field, updateField, editModeOn || false)

  const { t } = useTranslation()
  const [choices, setChoices] = useState<string>('')
  const [streamStatus, setStreamStatus] = useState<StreamStatus>('idle')
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const [isBoxClosed, setIsBoxClosed] = useState<boolean>(true)

  const intake = useIntakeFormStore((state) => state.intake)
  const offerings = useIntakeFormStore((state) => state.offerings)

  const sectionOrOfferingFields =
    intake?.sections.find((section) => section.fields.find(({ id }) => id === field.id))?.fields ||
    offerings?.find((offering) => offering.fields.find(({ id }) => id === field.id))?.fields

  const enabled: boolean = !!field.input.settings?.coPilot?.embeddings || !!field.input.settings?.coPilot?.prompt
  const fieldTypeIsSelect =
    field.input.type === 'SELECT' || field.input.type === 'MULTI_SELECT' || field.input.type === 'SEARCH_SELECT'

  // This works because every Select is technically a SearchSelect under the hood
  const { options: searchSelectOptions, selectedToOption } = useSearchSelectOptions({
    __uuid: field.uuid,
    values: field.value ? [field.value] : [],
    inputId: field.input.uuid,
    ...field.input,
  })
  const embeddingsData = useGetEmbeddingsData(field)

  const getFieldValues = (field: IntakeFieldType) => {
    if (fieldTypeIsSelect) {
      return searchSelectOptions?.map((o) => o.value || o.label).join('\n') || ''
    }

    return field.value?.value as string
  }

  const generatePrompt = () => {
    let prompt = field.input.settings?.coPilot?.prompt ?? ''
    // Get base Prompt
    const vars = [...prompt.matchAll(/{{([\w-]+)}}/g)]
    // Extract Variables
    for (let i = 0; i < vars.length; i += 1) {
      // Get all fields from current section
      let value = ''
      if (vars[i][0] === '{{this}}') {
        value = getFieldValues(field)
      } else {
        value = sectionOrOfferingFields?.find((f) => f.external_reference === vars[i][1])?.value?.value as string
      }
      prompt = prompt.replaceAll(vars[i][0], value)
    }
    return prompt
  }

  async function getSuggestion() {
    setStreamStatus('idle')
    setChoices('')
    const prompt = generatePrompt()

    const body = {
      model: field.input.settings?.coPilot?.model,
      prompt,
      systemPrompt: field.input.settings?.coPilot?.systemPrompt,
      temperature: field.input.settings?.coPilot?.temperature,
      maxTokens: field.input.settings?.coPilot?.maxTokens,
      topP: field.input.settings?.coPilot?.topP,
      frequencyPenalty: field.input.settings?.coPilot?.frequencyPenalty,
      presencePenalty: field.input.settings?.coPilot?.presencePenalty,
    }

    const url = `${getApiUrl()}/co-pilot/prompt`
    setStreamStatus('loading')
    await fetchEventSource(url, {
      method: 'POST',
      headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
      onopen: async () => {
        setStreamStatus('open')
        return Promise.resolve()
      },
      body: JSON.stringify(body),
      onmessage: (evt) => {
        setChoices((state) => `${state}${evt.data}`)
      },
      onerror: (): void => {
        setStreamStatus('error')
      },
      onclose: (): void => {
        setStreamStatus('close')
      },
    })
  }

  const onCopilotButtonClick = () => {
    setIsBoxClosed(false)
    if (enabled) {
      getSuggestion()
    }
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    toastr.success('Copied to clipboard')
  }

  // Select type fields
  const handleUpdateFieldWithSelection = (line: string) => {
    const lowercaseLine = line.toLowerCase()
    const option =
      searchSelectOptions &&
      searchSelectOptions.find(
        (o) =>
          (o.value && lowercaseLine.includes(o.value.toLowerCase())) ||
          (o.label && lowercaseLine.includes(o.label.toLowerCase()))
      )
    if (option) {
      const updatedValue = selectedToOption(option)
      const updatedField = {
        ...field,
        value: updatedValue,
      }
      handleUpdateField(updatedField)
      toastr.success('Selected value copied to field')
    }
  }

  const getLines = (html: string) =>
    html
      .split(/<br\s*\/?>/i)
      .map((line) => sanitize(line, { USE_PROFILES: { html: true } }))
      .filter((line) => line.trim() !== '') // Filter out blank lines

  const lines =
    field.input.settings?.coPilot?.mode === 'embeddings' && embeddingsData ? embeddingsData : getLines(choices)

  return (
    <div className={cn(isFieldHidden && 'hidden')}>
      <FormFieldCopilot
        field={field}
        label={label || undefined}
        subtitle={shouldShowSubtitle && field.subtitle ? field.subtitle : undefined}
        error={showErrors ? errorMessage : undefined}
        readonly={readonly}
        updateField={handleUpdateField}
        onCopilotButtonClick={onCopilotButtonClick}
        copilotStreamStatus={streamStatus}
        disabled={disabled}
      />

      <div
        className={cn(
          'bg-white rounded shadow-lg bg-opacity-20 mt-4 text-sm flex flex-col',
          (streamStatus.includes('loading') || isBoxClosed) && 'hidden'
        )}
      >
        <div className="px-3 py-3 bg-sky-blue rounded-t text-white">
          <div className="flex justify-between">
            <button
              className="flex gap-2 items-center text-sm font-semibold"
              type="button"
              onClick={onCopilotButtonClick}
            >
              <HiOutlineSparkles className="w-4" />
            </button>
            <button type="button" className="flex gap-1 items-center" onClick={() => setIsBoxClosed(true)}>
              <HiXMark className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className={cn('py-4 px-2 relative bg-white overflow-hidden', isCollapsed && 'h-24 gradient-blur')}>
          {(lines?.length && enabled) || streamStatus.includes('loading') ? (
            <div>
              {!streamStatus.includes('loading') && (
                <div className="flex flex-col justify-between items-start">
                  {lines.map((line, index) => (
                    <div
                      key="bla"
                      className="w-full py-2.5 px-2 flex justify-between items-center group hover:shadow-md rounded-md transition-all"
                    >
                      <div className="text-gray-500 flex-grow" dangerouslySetInnerHTML={{ __html: line }} />
                      {fieldTypeIsSelect ? (
                        <button
                          type="button"
                          className="text-sky-blue font-semibold p-1 ml-2 rounded shadow opacity-0 group-hover:opacity-100 transition-all hover:bg-sky-blue hover:text-white"
                          onClick={() => {
                            handleUpdateFieldWithSelection(line)
                          }}
                        >
                          <HiOutlinePlusCircle className="w-4 h-4" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="text-sky-blue font-semibold p-1 ml-2 rounded shadow opacity-0 group-hover:opacity-100 transition-all hover:bg-sky-blue hover:text-white"
                          onClick={() => {
                            copyToClipboard(line)
                          }}
                        >
                          <HiOutlineDocumentDuplicate className="w-4 h-4" />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : null}
        </div>
        <div className="flex w-full p-4 justify-between rounded-b bg-white">
          <button
            type="button"
            className={`text-gray-400 hover:text-sky-blue font-medium ${
              streamStatus.includes('close') ? '' : 'hidden'
            }`}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? t('generic.show_more', 'Show more') : t('generic.show_less', 'Show less')}
          </button>
          <div className="space-x-2">
            <button
              type="button"
              className={`text-sky-blue font-semibold p-1 rounded shadow transition-colors hover:text-white hover:bg-sky-blue ${
                streamStatus.includes('close') ? '' : 'hidden'
              }`}
              onClick={onCopilotButtonClick}
            >
              <HiOutlineArrowPath className="w-4 h-4" />
            </button>
            <button
              type="button"
              className={`text-sky-blue font-semibold p-1 rounded shadow transition-colors hover:text-white hover:bg-sky-blue ${
                streamStatus.includes('close') ? '' : 'hidden'
              }`}
              onClick={() => {
                copyToClipboard(choices)
              }}
            >
              <HiOutlineDocumentDuplicate className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntakeFormFieldCoPilot
