import { useTranslation } from 'react-i18next'
import { HiBolt, HiOutlineCalendar } from 'react-icons/hi2'
import dayjs from 'dayjs'
import { WorkflowBlock } from '../../../../../../../../types/Workflow/WorkflowBlock'
import { WorkflowBlockEntry } from '../../../../../../../Workflow'
import Button from '../../../../../../../../shared/components/atoms/buttons/Button'
import IntakeFlowchartActionDialog from './IntakeFlowchartActionDialog'
import IntakeFlowchartActionButton from './IntakeFlowchartActionButton'

export const IntakeFlowchartActionNodeContent = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()

  // Determine what text to display under "Executed" heading
  const blockHistory = block.actions?.[0]?.history
  const executed_date =
    blockHistory && blockHistory?.length > 1 ? blockHistory[blockHistory.length - 1]?.created_at : null
  const now = dayjs()

  const executedDisplayText =
    dayjs(executed_date).isBefore(now) ||
    block.actions?.[0]?.status === 'completed' ||
    block.actions?.[0]?.status === 'failed'
      ? dayjs(executed_date).format('DD.MM.YY HH:mm')
      : t('generic.upcoming', 'Upcoming')

  return (
    <div className="flex flex-col w-full gap-3">
      <div className="flex flex-row">
        <WorkflowBlockEntry Icon={HiBolt} title={t('intake.flowchart.approvalNodeContent.actions_counter', 'Actions')}>
          {block.actions?.length && (
            <>
              {block.actions?.length}{' '}
              {t('intake.flowchart.actionNodeContent.action', {
                count: block.actions?.length || 0,
                defaultValue: 'actions',
              })}
            </>
          )}
        </WorkflowBlockEntry>

        <WorkflowBlockEntry
          Icon={HiOutlineCalendar}
          title={t('intake.flowchart.approvalNodeContent.executed', 'Executed')}
        >
          <div className="flex flex-row capitalize">{executedDisplayText}</div>
        </WorkflowBlockEntry>
      </div>
      <div className="flex">
        <IntakeFlowchartActionButton block={block} />
      </div>
    </div>
  )
}

export default IntakeFlowchartActionNodeContent
