import { useQuery } from 'react-query'
import apiClient from '../../../core/utils/apiClient'
import { apiGet, apiGetData } from '../../../core/utils/api/generic'

type DashboardUrl = {
  dashboard_id: number
  url: string
}

type DashboardUrls = {
  dashboard_urls: DashboardUrl[]
}

const endpoints = {
  dashboardUrlsGET: () => '/metabase-configuration',
}

const useDashboardUrls = () =>
  useQuery(['metabaseConfiguration'], () =>
    apiGet<DashboardUrls>(endpoints.dashboardUrlsGET()).then((dashboardUrls) => dashboardUrls)
  )

export default useDashboardUrls
