import { useState } from 'react'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import SaveCancelFormButtons from '../../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import useGetFormBuilder from '../../hooks/useGetFormBuilder'
import usePostCompanyForm from '../../hooks/usePostCompanyForm'
import CompanyFormsNameInput from './CompanyFormsNameInput'

interface INewCompanyFormModal {
  editable?: boolean
  isTestForm?: boolean
}

const NewCompanyFormModal: React.FC<INewCompanyFormModal> = ({ editable = true, isTestForm = false }) => {
  const { modal } = useUtils()
  const { data: builderData } = useGetFormBuilder(isTestForm)

  const [companyForm, setCompanyForm] = useState({
    name: '',
    published: false,
  })

  const { mutate: postForm } = usePostCompanyForm()

  const [formTouched, setFormTouched] = useState(false)

  const isNameError = !companyForm.name

  const handleCreateForm = () => {
    if (isNameError) return

    setFormTouched(true)
    modal.set({ isOpen: false })
    postForm({
      ...companyForm,
      thumbnail: {
        url: '',
        name: '',
        content: '',
      },
      template: builderData,
    })
  }

  return (
    <div className="flex flex-col gap-y-6 w-full">
      <div className="flex flex-col gap-4 w-full">
        {editable && (
          <div className="flex justify-between gap-2 w-full ">
            <CompanyFormsNameInput
              handleChange={(v) => setCompanyForm(() => ({ ...companyForm, name: v }))}
              formTouched={formTouched}
              companyForm={companyForm}
            />
          </div>
        )}
      </div>
      {editable && (
        <SaveCancelFormButtons
          canSave={!isNameError}
          onCancel={() => modal.set({ isOpen: false })}
          onSave={handleCreateForm}
        />
      )}
    </div>
  )
}

export default NewCompanyFormModal
