import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import WorkflowActionButton, { WorkflowActionButtonProps } from './WorkflowActionButton'

export const WorkflowRemoveButton = ({ children, ...props }: WorkflowActionButtonProps) => (
  <WorkflowActionButton {...props}>
    <XMarkIcon className="w-4" style={{ strokeWidth: 3 }} />
    {children}
  </WorkflowActionButton>
)

export default WorkflowRemoveButton
