import { ValidationError } from 'yup' // Update the import path as necessary
import useIntakeFormStore from '../stores/useIntakeFormStore'
import { FormFieldSettings } from '../../../Forms-v2/types'
import { IntakeFieldType } from '../../../../types/Intake'

const usePrepareIntakeFormField = (
  field: IntakeFieldType,
  updateField: (field: FormFieldSettings<string>) => void,
  editModeOn: boolean
) => {
  const isDraftIntake = useIntakeFormStore((state) => state.intake?.status === 'draft')
  const intakeSections = useIntakeFormStore((state) => state.intake?.sections)
  const activeSectionIndex = useIntakeFormStore((state) => state.activeSectionIndex)
  const formErrors = useIntakeFormStore((state) => state.errors)
  const showErrors = useIntakeFormStore((state) => state.showErrors)

  const handleUpdateField = (field: FormFieldSettings<string>) => {
    updateField(field)
  }

  let shouldShowSubtitle = isDraftIntake
  let readonly = !(isDraftIntake || editModeOn)

  // Check if in the last section
  if (intakeSections && activeSectionIndex === intakeSections.length - 1) {
    readonly = true
    shouldShowSubtitle = false
  }

  const fieldIsRequired = field.validation?.required?.value
  let label = isDraftIntake ? field.question : field.label || field.question
  if (fieldIsRequired && label) {
    label = label.concat('*')
  }

  const errorFromStore =
    formErrors && field.uuid && Object.keys(formErrors).includes(field.uuid) ? formErrors[field.uuid] : null
  const errorMessage = errorFromStore instanceof ValidationError ? errorFromStore.message : ''

  const isFieldHidden = isDraftIntake ? !field.visible || field.input.settings?.misc?.hidden === true : !field.visible

  return {
    isDraftIntake,
    shouldShowSubtitle,
    readonly,
    label,
    errorMessage,
    isFieldHidden,
    handleUpdateField,
    showErrors,
  }
}

export default usePrepareIntakeFormField
