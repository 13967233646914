// @ts-nocheck - FIXME: fix types and check whether this contains logic is still relevant (ie. MULTI-SELECT)

import dayjs from 'dayjs'
import { FormQuestionValue } from '../../../../../../types/Forms'
import { FormLogicQuestionType } from './checkIfAnswerFulfillsRequirement'
import { getUsesUUIDs } from './constants'

export default (question: FormLogicQuestionType, questionAnswers: FormQuestionValue, questionLogic: string) => {
  const usesUUIDs = getUsesUUIDs(question)

  const hasMultipleTextInputs = !getUsesUUIDs(question) && (question.question.input.settings?.answers?.min || 1) > 1

  if (usesUUIDs) {
    const questionAnswerUUID = questionAnswers.__uuid
    return questionAnswerUUID.includes(questionLogic)
  }

  if (hasMultipleTextInputs) {
    const questionAnswerValues = questionAnswers.map((questionAnswer) => questionAnswer.value)
    return questionAnswerValues.includes(questionLogic)
  }

  if (question.questionType === 'DATE') {
    // TODO: dates are strings like "2023-09-20T21:00:00.000Z,2023-09-27T21:00:00.000Z" now
    const dateString = questionAnswers.value as string
    const dates = dateString.split(',')
    const startDate = dates[0]
    const endDate = dates[1]

    return dayjs(questionLogic).isBetween(startDate, endDate)
  }

  const questionAnswerValue = questionAnswers.value
  return questionAnswerValue.includes(questionLogic)
}
