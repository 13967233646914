interface IRequiredLabel {
  className?: string
  style?: {}
  shouldHide?: boolean
}

const RequiredLabel: React.FC<IRequiredLabel> = ({ className, style, shouldHide }) => {
  if (shouldHide) {
    return null
  }

  return <div className={`absolute bg-orange-400 w-1 rounded-r-xl z-10 ${className}`} style={style} />
}

export default RequiredLabel
