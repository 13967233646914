import { useMutation, useQuery, useQueryClient } from 'react-query'
import apiClient from '../../../../core/utils/apiClient'
import { FormQuestionContentType, FormQuestionValue } from '../../../../types/Forms'

const endpoints: { [key: string]: (schemaUuidOrEntity?: string) => string } = {
  indexGET: () => '/schemas',
  createPOST: () => '/schemas',
  viewGET: (schemaUuid) => `/schemas/${schemaUuid}`,
  updatePUT: (schemaUuid) => `/schemas/${schemaUuid}`,
  renamePUT: (schemaUuid) => `/schemas/${schemaUuid}/rename`,
  deleteDELETE: (schemaUuid) => `/schemas/${schemaUuid}`,
  detailsGET: (entity) => `/schemas/${entity}/details`,
  detailsPOST: (entity) => `/schemas/${entity}/details`,
}

export type CustomDataSchemaFieldType = {
  field_uuid?: string
  name: string
  input_uuid: string
  required: boolean
  placeholder?: string
  type?: FormQuestionContentType
  disabled?: boolean
  values?: FormQuestionValue | FormQuestionValue[] | null
}
export type DataSchemaCustomFieldType = Pick<CustomDataSchemaFieldType, 'name' | 'type'>

export type DataSchemaViewResponseType = {
  uuid: string
  name: string
  entity: string
  default_fields: CustomDataSchemaFieldType[]
  custom_fields: CustomDataSchemaFieldType[]
  created_at: string
}

export type DataSchemaIndexResponseType = {
  uuid: string
  name: string
  entity: string
  fields_count: number
  created_at: string
}

export type DataSchemaServiceUpdateMutationType = {
  custom_fields: CustomDataSchemaFieldType[]
}

export type DataSchemaServiceRenameMutationType = {
  name: string
}

export type DataSchemaServiceCreationMutationType = {
  name?: string
  entity?: string
}

export type DataSchemaServiceDetailsMutationType = {
  custom_fields: CustomDataSchemaFieldType[]
  default_fields: CustomDataSchemaFieldType[]
}

export const queryServiceKey = (method: string, schemaUuid?: string) => `data-schema-${method}-${schemaUuid}`

export const useSchemaServiceIndex = () =>
  useQuery(queryServiceKey('INDEX'), () =>
    apiClient.get<{ data: DataSchemaIndexResponseType[] }>(endpoints.indexGET()).then(({ data }) => data)
  )

export const useSchemaServiceView = (schemaUuid: string) =>
  useQuery(queryServiceKey('VIEW', schemaUuid), () =>
    apiClient.get<{ data: DataSchemaViewResponseType }>(endpoints.viewGET(schemaUuid)).then(({ data }) => data)
  )

export const useSchemaServiceCreateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (newSchema: DataSchemaServiceCreationMutationType) =>
      apiClient.post<{ data: DataSchemaViewResponseType }>(endpoints.createPOST(), newSchema).then(({ data }) => data),
    {
      onSuccess: () => {
        !!queryClient.invalidateQueries(queryServiceKey('INDEX'))
      },
    }
  )
}

export const useSchemaServiceUpdateMutation = (schemaUuid: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (newSchema: DataSchemaServiceUpdateMutationType) =>
      apiClient.put<DataSchemaViewResponseType>(endpoints.updatePUT(schemaUuid), newSchema).then(({ data }) => data),
    {
      onSuccess: () => {
        !!queryClient.invalidateQueries(queryServiceKey('VIEW', schemaUuid))
        !!queryClient.invalidateQueries(queryServiceKey('INDEX'))
      },
    }
  )
}

export const useSchemaServiceRenameMutation = (schemaUuid: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (newSchema: DataSchemaServiceRenameMutationType) =>
      apiClient.put<DataSchemaViewResponseType>(endpoints.renamePUT(schemaUuid), newSchema).then(({ data }) => data),
    {
      onSuccess: () => {
        !!queryClient.invalidateQueries(queryServiceKey('VIEW', schemaUuid))
        !!queryClient.invalidateQueries(queryServiceKey('INDEX'))
      },
    }
  )
}

export const useSchemaServiceDeleteMutation = (schemaUuid: string) => {
  const queryClient = useQueryClient()

  return useMutation(() => apiClient.delete(endpoints.deleteDELETE(schemaUuid)), {
    onSuccess: () => {
      !!queryClient.invalidateQueries(queryServiceKey('INDEX'))
    },
  })
}

export const useSchemaServiceDetails = (entity: string) =>
  useQuery(queryServiceKey('DETAILS', entity), () =>
    apiClient.get<{ data: DataSchemaViewResponseType }>(endpoints.detailsGET(entity)).then(({ data }) => data?.data)
  )

export const useSchemaServiceDetailsMutation = (entity: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (newSchema: DataSchemaServiceDetailsMutationType) =>
      apiClient.post(endpoints.detailsPOST(entity), newSchema).then(({ data }) => data),
    {
      onSuccess: () => {
        !!queryClient.invalidateQueries(queryServiceKey('DETAILS', entity))
      },
    }
  )
}
