import { useTranslation } from 'react-i18next'
import { TabType } from '../../../shared/components/atoms/Tabs/TabButton'
import { useSuppliersProvider } from '../../Suppliers/providers/SuppliersProvider'

export default (supplierBranchId: number, isButton?: boolean) => {
  const { t } = useTranslation()
  const {
    activeSupplierModalTab: [, setActiveSupplierModalTab],
  } = useSuppliersProvider()

  const tabs: TabType[] = [
    {
      title: t('suppliers.tag_general'),
      link: `/supplier/${supplierBranchId}/general`,
      cy: 'general-tab',
      onClick: isButton
        ? () => {
            setActiveSupplierModalTab('general')
          }
        : undefined,
      id: 'general',
    },
    {
      title: t('suppliers.tags'),
      link: `/supplier/${supplierBranchId}/tags`,
      cy: 'tags-tab',
      onClick: isButton
        ? () => {
            setActiveSupplierModalTab('tags')
          }
        : undefined,
      id: 'tags',
    },
  ]

  return tabs
}
