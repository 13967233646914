import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import CorePageContainerLayout from '../../core/components/layout/CorePageContainerLayout'
import LhotseHomeSearch from './components/LhotseHome/LhotseHomeSearch'
import LhotseHomeResultList from './components/LhotseHome/LhotseHomeResultList'
import useFilteredForms from './hooks/useFilteredForms'
import LhotseHomeFormResult from './components/LhotseHome/LhotseHomeResults/LhotseHomeFormResult'
import LhotseHomeFormSkeleton from './components/LhotseHome/LhotseHomeResults/LoadingSkeletons/LhotseHomeFormSkeleton'
import useSearchSubmissions from '../Search/Hooks/Submissions/useSearchSubmissions'
import LhotseHomeRequestResult from './components/LhotseHome/LhotseHomeResults/LhotseHomeRequestResult'
import useUrlParams from './hooks/useUrlParams'
import LhotseHomeRequestSkeleton from './components/LhotseHome/LhotseHomeResults/LoadingSkeletons/LhotseHomeRequestSkeleton'

const LhotseHome = () => {
  const history = useHistory()

  const { t } = useTranslation()
  const { getParam } = useUrlParams()
  const searchText = getParam('query')
  const [activeSearch, setActiveSearch] = useState<'all' | 'forms'>('all')

  const { filteredForms, isLoading: isFormsLoading } = useFilteredForms()

  const [isLoading, setIsLoading] = useState(true)

  const { data: activeRequestsData, isLoading: isLoadingActiveRequests } = useSearchSubmissions({
    query: activeSearch === 'all' ? searchText : '',
    filter_by: 'status:=[ongoing, draft]',
    per_page: 6,
  })
  const activeRequests = activeRequestsData?.data?.hits.map((request) => request.document)

  const { data: pastRequestsData, isLoading: isLoadingPastRequests } = useSearchSubmissions({
    query: activeSearch === 'all' ? searchText : '',
    filter_by: 'status:=completed',
    per_page: 6,
  })
  const pastRequests = pastRequestsData?.data?.hits.map((request) => request.document)

  // Delay the loading of the content until the skeletons have been shown for a certain amount of time
  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | null | undefined = null

    if (!isLoadingPastRequests && !isLoadingActiveRequests) {
      // Set a timeout to transition from the skeleton to the actual content
      timeoutId = setTimeout(() => {
        setIsLoading(false)
      }, 1000) // Adjust the time based on your needs
    } else {
      // Immediately set to loading if we are in a loading state
      setIsLoading(true)
    }

    // Cleanup function to clear the timeout when the component unmounts or the dependencies change
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isLoadingPastRequests, isLoadingActiveRequests])

  const requestsSkeletons = Array.from({ length: 6 }).map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <LhotseHomeRequestSkeleton key={`request-skeleton-${index}`} />
  ))
  const formsSkeletons = Array.from({ length: 4 }).map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <LhotseHomeFormSkeleton key={`form-skeleton-${index}`} />
  ))

  const requestsContent = (
    <>
      <LhotseHomeResultList
        title={`${t('home.active_requests', 'Active requests')}`}
        numItems={activeRequestsData?.total}
        numColumns={3}
        onShowMoreClick={() => history.push('/intake-flows?filter_by=status:=[ongoing, draft]')}
      >
        {isLoading && requestsSkeletons}
        {!isLoading && activeRequests?.length === 0 && (
          <div className="font-medium text-sm text-gray-400">
            {t('home.no_active_requests', 'No active requests found')}
          </div>
        )}

        {!isLoading &&
          activeRequests?.map((request) => (
            <LhotseHomeRequestResult request={request} key={`active-request-${request.intake_uuid}`} />
          ))}
      </LhotseHomeResultList>

      <LhotseHomeResultList
        title={`${t('home.past_requests', 'Past requests')}`}
        numItems={pastRequestsData?.total}
        numColumns={3}
        onShowMoreClick={() => history.push('/intake-flows?filter_by=status:=completed')}
      >
        {isLoading && requestsSkeletons}

        {!isLoading && pastRequests?.length === 0 && (
          <div className="font-medium text-sm text-gray-400">
            {t('home.no_past_requests', 'No past requests found')}
          </div>
        )}

        {!isLoading &&
          pastRequests?.map((request) => (
            <LhotseHomeRequestResult request={request} key={`past-request-${request.intake_uuid}`} />
          ))}
      </LhotseHomeResultList>
    </>
  )

  return (
    <CorePageContainerLayout className="flex justify-center pb-28">
      <div className="flex flex-col max-w-4xl w-full gap-y-4">
        <div className="flex w-full text-gray-600 text-2xl font-semibold justify-center mb-8">
          {t('generic.what-are-you-looking-for-today', 'What are you looking for today?')}
        </div>
        <div className="space-y-9">
          <LhotseHomeSearch activeSearch={activeSearch} setActiveSearch={setActiveSearch} />

          <LhotseHomeResultList
            title={`${t('generic.forms', 'Forms')}`}
            numItems={filteredForms?.length}
            onShowMoreClick={
              filteredForms && filteredForms?.length > 4 && activeSearch !== 'forms'
                ? () => setActiveSearch('forms')
                : undefined
            }
          >
            {!isFormsLoading && filteredForms?.length === 0 && (
              <div className="font-medium text-sm text-gray-400">{t('home.no_forms_found', 'No forms found')}</div>
            )}

            {!isFormsLoading
              ? filteredForms
                  ?.slice(0, activeSearch === 'forms' ? filteredForms.length : 4)
                  .map((form) => <LhotseHomeFormResult form={form} key={form.__uuid} />)
              : formsSkeletons}
          </LhotseHomeResultList>

          {activeSearch === 'all' && requestsContent}
        </div>
      </div>
    </CorePageContainerLayout>
  )
}

export default LhotseHome
