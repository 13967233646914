import { useMutation, useQueryClient } from 'react-query'
import { useMemo } from 'react'
import { fieldMediaService } from '../../../shared/utils/attachmentsService'
import { useFormField } from '../providers/FormFieldProvider'
import useIntakeId from '../../Intake/IntakeOverview/hooks/useIntakeId'

export const useIntakeFlowFileInputMutations = () => {
  const {
    field: { uuid, media },
    setFieldMedia,
  } = useFormField()
  const intakeId = useIntakeId()

  const queryClient = useQueryClient()

  const mediaService = uuid ? fieldMediaService(uuid) : undefined

  // Invalidate intake query so that the intake flow will re-fetch when a file is uploaded
  const invalidate = () => {
    queryClient.invalidateQueries(['intake', intakeId])
  }

  const remove = useMutation((uuidToRemove: string) => mediaService!.remove(uuidToRemove), {
    onSettled: invalidate,
    onSuccess: (data, context) => {
      const updatedMedia = media?.filter(({ uuid: mediaUuid }) => mediaUuid !== context)
      setFieldMedia(updatedMedia || [])
    },
  })

  const upload = useMutation((files: File[]) => mediaService!.upload(files), {
    onSettled: invalidate,
    onSuccess: (data) => {
      const updatedMedia = media ? [...media, ...data] : data
      setFieldMedia(updatedMedia)
    },
  })

  return useMemo(
    () => ({
      ...(mediaService || { saveFile: () => {} }),
      remove: mediaService ? remove.mutate : () => {},
      upload: mediaService ? upload.mutate : () => {},
      enabled: !!mediaService,
    }),
    [mediaService]
  )
}

export default useIntakeFlowFileInputMutations
