import Underline from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect } from 'react'
import './TipTapEditor.scss'

interface ITipTapReadOnly {
  text: string
  className?: string
}

const TipTapReadOnly: React.FC<ITipTapReadOnly> = ({ text, className }) => {
  const editor = useEditor({
    content: typeof text === 'string' ? text : JSON.stringify(text),
    editable: false,
    extensions: [StarterKit, Underline],
  })

  useEffect(() => {
    editor?.commands.setContent(text)
  }, [text])

  return (
    <EditorContent
      editor={editor}
      className={`${className} outer-editor mr-12 h-full break-words list-outside text-left overflow-x-hidden list-disc w-full`}
    />
  )
}

export default TipTapReadOnly
