import { IS_AUTHED } from '../../../shared/constants/environments'
import CoreSidebar from '../Sidebar/CoreSidebar'
import BackToBasecampButton from './BackToBasecampButton'
import RedirectToLoginButton from './RedirectToLoginButton'

interface IErrorPage {
  header: string
  title: string
  description?: string
}

const ErrorPage: React.FC<IErrorPage> = ({ header, title, description }) => (
  <div className="flex h-screen">
    {IS_AUTHED && <CoreSidebar />}
    <div className="flex flex-col justify-center items-center text-center gap-y-6 w-full">
      <h1 className="text-8xl ">{header}</h1>
      <h2 className="text-5xl font-light">{title}</h2>
      {description && <p className="w-[450px] text-center">{description}</p>}
      {IS_AUTHED ? <BackToBasecampButton /> : <RedirectToLoginButton />}
    </div>
  </div>
)
export default ErrorPage
