import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { CgChevronLeft } from 'react-icons/cg'
import { useHistory } from 'react-router'
import Button from '../atoms/buttons/Button'

export interface IPageTopBar {
  title?: string
  backString?: string
  backTarget?: string
  actions?: ReactElement
  titleComponent?: ReactElement
}

const PageTopbar: React.FC<IPageTopBar> = ({ backString = '', title = '', actions, titleComponent, backTarget }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div
      className="w-full px-32 py-4 space-x-4 flex justify-between items-center bg-background border-b"
      id="page-topbar"
    >
      <div className="flex space-x-4 items-center h-min-8 mt-4 flex-grow">
        {backString ? (
          <Button
            className="btn-secondary-ghost text-sm"
            onClick={() => (backTarget ? history.push(backTarget) : history.goBack())}
          >
            <CgChevronLeft className="h-4" />
            {t(backString)}
          </Button>
        ) : null}
        {titleComponent || (
          <span className="text-2xl flex items-center font-bold text-black-light" data-cy="req-topbar-title">
            {t(title)}
          </span>
        )}
      </div>
      <div className="flex items-center h-full">{actions}</div>
    </div>
  )
}

export default PageTopbar as (props: IPageTopBar) => ReactElement
