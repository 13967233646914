import { WorkflowAction, WorkflowActionAttribute } from '../../../../../../types/Workflow/WorkflowAction'
import WorkflowActionAttributeField from './WorkflowActionAttributeField'
import { getUpdatedByProperty } from '../../../../../../shared/utils/arrayHelpers'

type WorkflowActionAttributesFormProps = {
  action: WorkflowAction
  onUpdate: (updated: WorkflowAction) => void
  errors: Record<WorkflowActionAttribute['id'], string>
}

export const WorkflowActionAttributesForm = ({ action, errors, onUpdate }: WorkflowActionAttributesFormProps) => {
  const handleUpdate = (updatedAttribute: WorkflowActionAttribute) => {
    onUpdate({
      ...action,
      attributes: getUpdatedByProperty('id', action.attributes, [updatedAttribute]),
    })
  }

  return (
    <div className="flex flex-col gap-6">
      {action.attributes.map((attribute) => (
        <WorkflowActionAttributeField
          attribute={attribute}
          key={`action-${action.uuid}-attribute-${attribute.id}`}
          onUpdate={handleUpdate}
          error={errors[attribute.id]}
        />
      ))}
    </div>
  )
}

export default WorkflowActionAttributesForm
