import { useTranslation } from 'react-i18next'
import TableHead from '../SharedTable/TableHead'
import TableHeader from '../SharedTable/TableHeader'
import Table from '../SharedTable/Table'
import TableContainer from '../SharedTable/TableContainer'
import { MediaType } from '../../../../types/shared/MediaTypes'
import MediaTableRow from './MediaTableRow'
import MediaTableRowActions from './MediaTableRowActions'

interface MediaTableProps {
  media: MediaType[]
  onDelete?: (media: MediaType) => void
  disabled?: boolean
}

const MediaTable: React.FC<MediaTableProps> = ({ media, onDelete, disabled }) => {
  const { t } = useTranslation()

  return (
    <TableContainer disabled={disabled}>
      <TableHead>
        <TableHeader>{t('generic.name')}</TableHeader>
        <TableHeader>{t('generic.type')}</TableHeader>
        <TableHeader>{t('media.updated_at')}</TableHeader>
        <TableHeader>{t('generic.actions')}</TableHeader>
      </TableHead>
      <Table>
        {media?.map(
          (file) =>
            file && (
              <MediaTableRow media={file} key={`address-row-${file?.uuid}`}>
                <MediaTableRowActions
                  media={file}
                  onDelete={!disabled ? onDelete && (() => onDelete(file)) : undefined}
                />
              </MediaTableRow>
            )
        )}
      </Table>
    </TableContainer>
  )
}

export default MediaTable
