import cn from '../../../lib/util'
import useUrlParams from '../../../modules/LhotseHome/hooks/useUrlParams'

export type ChipMenuItemProps = {
  name: string
  isActive: boolean
  order?: number
  classNameOrder?: string
  classNameText?: string
}

const ChipMenuItem = ({ name, isActive, order, classNameOrder, classNameText }: ChipMenuItemProps) => {
  const { setParam } = useUrlParams()
  return (
    <button
      type="button"
      className={`py-1.5 px-2 flex space-x-2.5 items-center h-full 
    ${isActive ? 'text-sky-blue' : ''}`}
      onClick={() => setParam({ step: order?.toString() })}
    >
      {order ? (
        <span
          className={cn(
            `
      h-6 w-6 items-center flex justify-center rounded-full border-y-[0.5px] border-x-[0.5px] text-sm 
      ${isActive ? 'border-sky-blue text-sky-blue' : 'border-rock-gray-light text-black-light'}`,
            classNameOrder
          )}
        >
          {order}
        </span>
      ) : null}
      <span className={cn(`${isActive ? 'text-sky-blue' : 'text-black-light'} text-sm`, classNameText)}>{name}</span>
    </button>
  )
}
export default ChipMenuItem
