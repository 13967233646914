import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import { ChangeEvent, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { HiChevronDown, HiX } from 'react-icons/hi'
import { debounce, omit } from 'lodash'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import SearchSelectOption from './SearchSelectOption'
import SearchSelectOptions from './SearchSelectOptions'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import cn from '../../../../lib/util'

export interface ISearchSelect {
  onChange: (value: InputSearchSelectItemType) => void | undefined
  onClear?: () => void
  disabled?: boolean
  children?: ReactI18NextChild | Iterable<ReactI18NextChild>
  options?: InputSearchSelectItemType[]
  currentSelection?: InputSearchSelectItemType
  onInputChange?: (value: string) => any
  onInputBlur?: (value: string) => void
  placeholder?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
  wrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionsWrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionsClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionClassName?: HTMLAttributes<HTMLDivElement>['className']
  nullable?: boolean
  error?: string
  onAdd?: (value?: string) => void
  addButtonText?: string
  isLoading?: boolean
  alwaysVisible?: boolean
  showArrow?: boolean
  inputWrapperClassNames?: HTMLAttributes<HTMLDivElement>['className']
  showSelectionAsQuery?: boolean
  showSearchIcon?: boolean
  inputId?: string
  autoClear?: boolean
  inputClassNames?: string
  visibleColumns?: { [key: string]: any } // Column headers from an external data source, if applicable
  showOnlyVisibleData?: boolean // If true, only show option data that are in visible columns
  enableGroupByCategory?: string // If set, will group options by the value of the specified key
  selectType?: 'SELECT' | 'MULTI_SELECT' | 'SEARCH_SELECT'
}

const SearchSelect: React.FC<ISearchSelect> = ({
  options,
  onChange,
  currentSelection,
  disabled,
  onInputChange,
  onInputBlur,
  placeholder,
  nullable,
  wrapperClassName,
  error,
  onAdd,
  addButtonText,
  onClear = () => {},
  children,
  isLoading = false,
  alwaysVisible = false,
  showArrow = true,
  inputWrapperClassNames,
  showSelectionAsQuery = true,
  optionsWrapperClassName,
  optionsClassName,
  optionClassName,
  showSearchIcon = false,
  inputId = '',
  autoClear = false,
  visibleColumns,
  showOnlyVisibleData,
  inputClassNames,
  enableGroupByCategory,
  selectType,
}) => {
  const [query, setQuery] = useState('')
  const [selection, setSelection] = useState<InputSearchSelectItemType | undefined>(currentSelection || undefined)

  useEffect(() => {
    if (!selection?.id) return

    onChange(selection as InputSearchSelectItemType)
  }, [selection?.id])

  const clear = () => {
    setSelection(undefined)
    setQuery('')
    onClear()
  }

  // If the currentSelection (passed as a prop) changes due to autopopulated selection,
  // update the selection and fire the onChange callback
  useEffect(() => {
    setSelection(currentSelection)
    if (currentSelection?.id && currentSelection?.isExternalDataOption) {
      onChange(currentSelection as InputSearchSelectItemType)
    }
  }, [currentSelection?.id])

  // If options change, and currentSelection is in options and is an autopopulated option, update selection
  useEffect(() => {
    if (selection && options?.some((option) => option.id === selection.id && option.isExternalDataOption)) {
      onChange(selection as InputSearchSelectItemType)
    }
  }, [JSON.stringify(options?.map((option) => omit(option, ['icon'])))])

  const debouncedInputChange = useCallback(
    debounce((value) => {
      onInputChange?.(value)
    }, 500),
    []
  )

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    debouncedInputChange(e.target.value)
  }

  const handleSelection = (value: InputSearchSelectItemType | undefined) => {
    if (!value) {
      setSelection(undefined)
      onClear && onClear()
    }

    setSelection(value)
    onChange(value as InputSearchSelectItemType)
    if (autoClear) clear()
  }

  const filteredOptions =
    query === ''
      ? options
      : options?.filter((option) => {
          if (option.dataFields) {
            // dataFields are used for searching data in the select returned by external data sources
            // Check if any values in option.dataFields match the query
            return Object.values(option.dataFields).some((field) => {
              if (!field) return false
              return field.toString().toLowerCase().includes(query.toLowerCase())
            })
          }
          // If no dataFields, check if the option label matches the query (default behaviour)
          return option.label?.toLowerCase().includes(query.toLowerCase())
        })

  const groupedOptions =
    enableGroupByCategory && filteredOptions?.length
      ? filteredOptions?.reduce((grouped: any, option: InputSearchSelectItemType) => {
          const categoryKey: string = option.category || 'Uncategorized'
          return {
            ...grouped,
            [categoryKey]: grouped[categoryKey] ? [...grouped[categoryKey], option] : [option],
          }
        }, {})
      : null

  const optionsElements =
    filteredOptions && filteredOptions.length > 0 ? (
      <div className={classnames('max-h-44 overflow-auto', optionsWrapperClassName)}>
        {groupedOptions ? (
          Object.keys(groupedOptions).map((category) => (
            <>
              <div className="pt-2">
                <p className="text-xs text-gray-400 font-bold lowercase first-letter:uppercase p-2 select-none">
                  {category}
                </p>
              </div>
              {groupedOptions[category].map((option: InputSearchSelectItemType) => (
                <SearchSelectOption
                  key={`search-select-option-${option.id}`}
                  option={option}
                  className={optionClassName}
                  visibleColumns={visibleColumns}
                  showOnlyVisibleData={showOnlyVisibleData}
                />
              ))}
            </>
          ))
        ) : (
          <>
            {visibleColumns && (
              <div className="flex flex-row items-center bg-gray-100 p-2 pl-3 pr-2 gap-3">
                {Object.keys(visibleColumns).map((key) => (
                  <div key={key} className="flex flex-col flex-1 truncate">
                    <span className="text-sm capitalize">{visibleColumns[key]}</span>
                  </div>
                ))}
              </div>
            )}
            {filteredOptions.map((option) => (
              <SearchSelectOption
                key={`search-select-option-${option.id}`}
                option={option}
                className={optionClassName}
                visibleColumns={visibleColumns}
                showOnlyVisibleData={showOnlyVisibleData}
              />
            ))}
          </>
        )}
      </div>
    ) : null

  return (
    <Combobox
      as="div"
      value={selection || null}
      disabled={disabled}
      onChange={handleSelection}
      multiple={false}
      className={classnames(wrapperClassName)}
    >
      <div className="relative">
        <Combobox.Button
          as="div"
          className={classnames('relative flex flex-row gap-1 items-center', inputWrapperClassNames)}
        >
          {showSearchIcon && <MagnifyingGlassIcon className="h-5 w-5 text-black" aria-hidden="true" />}
          <Combobox.Input
            id={inputId}
            className={cn(
              { '!border-red-500': error },
              'w-full base-form-input pr-10',
              selectType !== 'SEARCH_SELECT' && 'cursor-pointer',
              inputClassNames
            )}
            onChange={handleInputChange}
            onBlur={() => onInputBlur && onInputBlur(query)}
            displayValue={() => (showSelectionAsQuery ? selection?.label || query : query)}
            placeholder={placeholder}
            readOnly={selectType !== 'SEARCH_SELECT'}
          />
          <div className="absolute inset-y-0 right-0 flex items-center mx-1 ">
            {nullable && selection && (
              <button
                type="button"
                className="flex items-center rounded-r-md focus:outline-none"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClear()
                  if (autoClear) clear()
                }}
              >
                <HiX className="h-4 w-4 " aria-hidden="true" />
              </button>
            )}
            {(showArrow || isLoading) && (
              <Combobox.Button className="flex items-center rounded-r-md focus:outline-none px-2">
                {isLoading ? <CoreLoadingIcon /> : <HiChevronDown className="h-5 w-5 text-black" aria-hidden="true" />}
              </Combobox.Button>
            )}
          </div>
        </Combobox.Button>

        <SearchSelectOptions
          alwaysVisible={alwaysVisible}
          optionsElements={optionsElements}
          onAdd={onAdd ? () => onAdd(query) : undefined}
          addButtonText={addButtonText}
          isLoading={isLoading}
          className={optionsClassName}
        >
          {children}
        </SearchSelectOptions>
      </div>
    </Combobox>
  )
}

export default SearchSelect
