import { isArray } from 'lodash'
import { IntakeFieldType } from '../../../../types/Intake'
import useIntakeFormStore from '../../IntakeFormResponse/stores/useIntakeFormStore'
import useGetInputById from '../../../Forms/hooks/useGetInputById'
import useGetExternalDataEntityData from '../../../ExternalDataManager/hooks/useGetExternalDataEntityData'

const useGetEmbeddingsData = (field: IntakeFieldType) => {
  const intake = useIntakeFormStore((state) => state.intake)
  const offerings = useIntakeFormStore((state) => state.offerings)

  const sectionOrOfferingFields =
    intake?.sections.find((section) => section.fields.find(({ id }) => id === field.id))?.fields ||
    offerings?.find((offering) => offering.fields.find(({ id }) => id === field.id))?.fields

  // logic for embeddings
  const { data: input } = useGetInputById(field.input.uuid)
  const filterSettings = input?.filter_settings
  const { data_source: fileUuid, input_value_column } = filterSettings || {}
  const embeddingsQueryField = field.input.settings?.coPilot?.embeddings?.query
  const embeddingsQueryFieldValue = sectionOrOfferingFields?.find((f) => f.external_reference === embeddingsQueryField)
    ?.value?.value

  // Start constructing the query string
  let queryString = field.input.settings?.coPilot?.mode === 'embeddings' ? `?embeddings=true` : ''
  if (embeddingsQueryFieldValue) {
    // If queryString already has parameters, use '&' to append, otherwise start with '?'
    const separator = queryString ? '&' : '?'
    queryString += `${separator}query=${encodeURIComponent(embeddingsQueryFieldValue.toString())}`
  }

  if (field.input.settings?.coPilot?.embeddings?.threshold) {
    const separator = queryString ? '&' : '?'
    queryString += `${separator}threshold=${field.input.settings.coPilot.embeddings.threshold}`
  }

  if (field.input.settings?.coPilot?.embeddings?.count) {
    const separator = queryString ? '&' : '?'
    queryString += `${separator}count=${field.input.settings.coPilot.embeddings.count}`
  }

  const embeddingsData = useGetExternalDataEntityData(fileUuid, queryString)
  const embeddingsDataValues =
    input_value_column && isArray(embeddingsData.data)
      ? embeddingsData.data.map((item: any) => item[input_value_column])
      : []

  return embeddingsDataValues
}

export default useGetEmbeddingsData
