import { useQuery } from 'react-query'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'
import { getActiveWorkflowBlockActions } from '../services'
import useGetCurrentWorkflow from './useGetCurrentWorkflow'

export const useGetBlockActions = (block: WorkflowBlock | null) => {
  const { data } = useGetCurrentWorkflow()

  const workflowUuid = data?.uuid

  return useQuery(['blockActions', block?.uuid], () => getActiveWorkflowBlockActions(workflowUuid!, block?.uuid!), {
    enabled: !!workflowUuid && !!block?.uuid && block?.has_actions,
  })
}

export default useGetBlockActions
