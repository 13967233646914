import React from 'react'
import classnames from 'classnames'
import PageHeaderWithBasicNavigation from '../../../../shared/components/layout/PageHeaderWithBasicNavigation'
import { useWorkflow } from '../../providers/WorkflowProvider'
import WorkflowBuilderHeaderControls from './WorkflowBuilderHeaderControls'
import WorkflowEditableTitle from './WorkflowEditableTitle'
import useGoToWorkflowList from '../../hooks/useGoToWorkflowList'

export const WorkflowBuilderHeader = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { sidePanelOpen } = useWorkflow()
  const goToWorkflows = useGoToWorkflowList()

  return (
    <PageHeaderWithBasicNavigation
      title={<WorkflowEditableTitle />}
      ref={ref}
      className={classnames({ '!pr-2': sidePanelOpen })}
      sideSection={<WorkflowBuilderHeaderControls />}
      onBack={goToWorkflows}
    />
  )
})

WorkflowBuilderHeader.displayName = 'WorkflowPageHeader'

export default WorkflowBuilderHeader
