import { FC, useState } from 'react'
import Tooltip from '../../organisms/tooltip/Tooltip'
import { ToggleOption } from '../../../../types/shared/ToggleButtonTypes'

interface IToggleButton {
  options: ToggleOption[]
  selectedOptionId?: string | number
  onChange?: (selection: ToggleOption) => void
  disabled?: boolean
}

const ToggleButton: FC<IToggleButton> = ({ options, selectedOptionId, onChange, disabled }) => {
  const [selected, setSelected] = useState(selectedOptionId || options[0].id)

  const handleSelect = (selection: ToggleOption) => {
    if (selected === selection.id) return

    setSelected(selection.id)
    onChange?.(selection)
  }

  return (
    <div className="inline-flex items-center bg-white rounded border border-rock-gray-light px-2 py-1 font-sans text-sm font-medium text-gray-400 ">
      {options.map((option) => (
        <Tooltip
          key={option.id}
          tooltip={option.tooltip || ''}
          showCondition={!!(option.tooltip && option.tooltip !== '')}
        >
          <button
            type="button"
            disabled={disabled}
            onClick={() => handleSelect(option)}
            className={`${
              selected === option.id
                ? `text-sky-blue-light bg-ice-blue ${disabled ? 'bg-rock-gray-light text-rock-gray' : 'bg-ice-blue'}`
                : `text-rock-gray hover:bg-ice-blue hover:text-sky-blue-light ${
                    disabled ? 'hover:bg-transparent hover:text-rock-gray' : ''
                  }`
            }
            px-3 py-1 mr-1 font-medium rounded-md transition-colors`}
          >
            {option.label}
          </button>
        </Tooltip>
      ))}
    </div>
  )
}

export default ToggleButton
