import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import ErrorMessage from '../../../../shared/components/atoms/ErrorMessage'
import FormikSelect from '../../../../shared/components/organisms/formik/FormikSelect'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import dropdownUserTypes from '../../../../types/shared/DropdownUserTypes'
import { errorMessages } from '../../../../shared/utils/validation/validationErrorMessages'
import UserInviteType from '../../../../types/Company/UserTypes'
import AddressFormTextInput from '../General/addresses/AddressForm/AddressFormTextInput'
import { userRoles } from '../../../../config/ability'

interface IUsersForm {
  hideForm: () => void
  onSubmit: (v: UserInviteType) => void
}

const UserForm: React.FC<IUsersForm> = ({ hideForm, onSubmit }) => {
  const { t } = useTranslation()
  const addressValidationSchema = Yup.object().shape({
    email: Yup.string().email().required(t(errorMessages.email)),
    role: Yup.string(),
  })

  const initialValues = {
    email: '',
    role: dropdownUserTypes[0].value,
  }

  const visibleOptions = dropdownUserTypes.filter((role) => role.value !== userRoles.SUPER_ADMIN)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addressValidationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ touched, values, handleChange, resetForm, errors }) => {
        const constants = {
          values,
          errors,
          handleChange,
          touched,
        }
        return (
          <Form className="flex flex-col">
            <div className="flex items-center gap-2">
              <AddressFormTextInput
                constants={constants}
                placeholder="hello@gmail.com"
                label="generic.email"
                errorKey="email"
                className="w-1/2"
              />
              <FormikSelect
                name="role"
                handleChange={handleChange}
                values={values}
                error={errors.role && touched.role ? <ErrorMessage error={errors.role as string} /> : undefined}
                label="account.user_role"
                className="w-1/2"
                options={visibleOptions}
                noPlaceholder
              />
            </div>
            <SaveCancelFormButtons
              onCancel={() => {
                resetForm()
                hideForm()
              }}
              isSubmitButton
              saveString="account.send_email_invite"
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserForm
