import { HTMLAttributes } from 'react'
import classnames from 'classnames'
import { inputErrorTextClassNames } from './helpers'

type InputErrorProps = HTMLAttributes<HTMLDivElement> & {
  error?: string | null
}

const InputError = ({ error, className, ...divAttributes }: InputErrorProps) => {
  if (!error) return null

  return (
    <div className={classnames(`${inputErrorTextClassNames} text-sm`, className)} {...divAttributes}>
      {error}
    </div>
  )
}

export default InputError
