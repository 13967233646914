import { useTranslation } from 'react-i18next'
import {
  HiCircleStack,
  HiCog6Tooth,
  HiDocumentPlus,
  HiDocumentText,
  HiPencilSquare,
  HiPuzzlePiece,
  HiRectangleGroup,
  HiUserCircle,
  HiUsers,
} from 'react-icons/hi2'
import { useHistory } from 'react-router'
import useGetCurrentUser from '../../../core/hooks/useGetCurrentUser'
import VerticalMenuTabs, { TabsSectionType } from '../../../shared/components/layout/VerticalMenuTabs/VerticalMenuTabs'
import VerticalMenuTabsItem, {
  VerticalTabItemType,
} from '../../../shared/components/layout/VerticalMenuTabs/VerticalMenuTabsItem'

const CompanyTabbar: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const currentUserRole = useGetCurrentUser()?.data?.role
  const adminSuperAdmin = ['admin', 'super_admin'].includes(currentUserRole || '')
  const adminSuperAdminAndPurchaser = ['admin', 'super_admin', 'purchaser'].includes(currentUserRole || '')
  const superAdmin = ['super_admin'].includes(currentUserRole || '')
  const tabs: TabsSectionType[] = [
    {
      name: t('account.user_settings', 'User settings'),
      basePath: 'settings',
      visible: true,
      tabs: [
        {
          title: t('generic.profile', 'Profile'),
          path: 'profile',
          icon: <HiUserCircle />,
          visible: true,
        },
      ],
    },
    {
      name: t('account.app_settings', 'App settings'),
      basePath: 'settings',
      visible: adminSuperAdminAndPurchaser,
      tabs: [
        {
          title: t('generic.general', 'General'),
          path: 'general',
          icon: <HiCog6Tooth />,
          visible: adminSuperAdminAndPurchaser,
        },
        {
          title: t('generic.users', 'Users'),
          path: 'users',
          icon: <HiUsers />,
          visible: adminSuperAdminAndPurchaser,
        },
        {
          title: t('generic.workflows', 'Workflows'),
          path: 'workflows',
          icon: <HiRectangleGroup />,
          visible: adminSuperAdmin,
        },
        {
          title: t('generic.forms', 'Forms'),
          path: 'forms',
          icon: <HiDocumentText />,
          visible: adminSuperAdmin,
        },
        {
          title: t('generic.inputs', 'Inputs'),
          path: 'inputs',
          icon: <HiPencilSquare />,
          visible: adminSuperAdmin,
        },
        {
          title: t('generic.integrations', 'Integrations'),
          path: 'integrations',
          icon: <HiPuzzlePiece />,
          visible: adminSuperAdmin,
        },
        {
          title: t('generic.data_manager', 'Data manager'),
          path: 'data-manager',
          icon: <HiDocumentPlus />,
          visible: adminSuperAdmin,
        },
        {
          title: t('generic.data_schema', 'Custom data schema'),
          path: 'schemas',
          icon: <HiCircleStack />,
          visible: superAdmin,
        },
      ],
    },
  ]

  const handleTabClick = (tab: VerticalTabItemType) => {
    if (!tab.path) {
      return
    }
    history.push(tab.path)
  }

  return (
    <VerticalMenuTabs sections={tabs}>
      {(tab: VerticalTabItemType) => (
        <VerticalMenuTabsItem tab={tab} key={`tab-index-${tab.title}`} onClick={handleTabClick} />
      )}
    </VerticalMenuTabs>
  )
}

export default CompanyTabbar
