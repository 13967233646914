import * as React from 'react'
import { IntakeSectionType } from '../../../../../types/Intake'
import IntakeBasicSection from './IntakeSectionsContent/IntakeBasicSection'
import { FormSectionCategoryType } from '../../../../../types/Forms'
import SectionWrapper from '../../../../../shared/components/organisms/SectionWrapper'
import IntakeFlowDetailsActions from '../IntakeFlowDetailsActions'
import useIntakeFormStore from '../../../IntakeFormResponse/stores/useIntakeFormStore'

type IntakeSectionProps = {
  section: IntakeSectionType
  useWrapper?: boolean
}

export type IntakeSectionContentProps = {
  section: IntakeSectionType
}

const sectionComponents: Partial<Record<FormSectionCategoryType, React.FC<IntakeSectionContentProps>>> = {
  GENERAL_INFO: IntakeBasicSection,
  GENERAL: IntakeBasicSection,
}

export const IntakeSection = ({ section, useWrapper = true }: IntakeSectionProps) => {
  const isDraftIntake = useIntakeFormStore((state) => state.intake?.status === 'draft')

  const Content = sectionComponents[section.type_name || 'GENERAL_INFO']

  if (useWrapper) {
    return (
      <SectionWrapper title={section.name} actions={!isDraftIntake ? <IntakeFlowDetailsActions /> : null}>
        <div className="p-6 ">{Content && <Content section={section} />}</div>
      </SectionWrapper>
    )
  }
  return <div className="p-6 ">{Content && <Content section={section} />}</div>
}
export default IntakeSection
