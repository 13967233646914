import { useTranslation } from 'react-i18next'

const ExternalDataRelationshipHeader = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-y-2">
      <p>{t('external_data.crate_relationship')}</p>
      <p className="text-secondary-gray text-sm pb-6">{t('external_data.fill_relationship_info')}</p>
    </div>
  )
}

export default ExternalDataRelationshipHeader
