import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from '../providers/LocalStorageProvider'
import { UserType } from '../../types/shared/AssignmentTypes'

function useOnboardingCheck() {
  const history = useHistory()
  const [getLocalStorage] = useLocalStorage()
  const userData: UserType | undefined = getLocalStorage('lhotseAuth')

  useEffect(() => {
    const checkOnboarding = () => {
      if (!userData || !Object.hasOwn(userData, 'onboarded_at')) {
        return
      }

      if (window.location.href.indexOf('/onboarding') !== -1 && userData.onboarded_at) {
        history.push('/settings/profile')
      }

      if (!userData.onboarded_at && window.location.href.indexOf('/onboarding') === -1) {
        history.push('/onboarding')
      }
    }

    checkOnboarding()
  }, [history, userData])

  return null
}

export default useOnboardingCheck
