/* eslint-disable no-continue */
import { IntakeFieldType } from '../../../../../../types/Intake'
import checkIfAnswerFulfillsRequirement, { FormLogicQuestionType } from './checkIfAnswerFulfillsRequirement'
import generateLogicTree from './generateLogicTree'

export default (fields?: IntakeFieldType[]) => {
  const allQuestions = fields || []

  const findAllowedIndex = (currentIndex: number, uuid?: string | null) => {
    const foundIndex =
      allQuestions.slice(currentIndex).findIndex((question) => uuid === question.external_reference) + currentIndex
    return foundIndex !== -1 ? foundIndex : allQuestions.length
  }

  const questionToLogicTest = (question: IntakeFieldType): FormLogicQuestionType => ({
    questionUUID: question.external_reference || question.uuid || '',
    questionType: question.input.type,
    switchLogic: question.logic?.switch,
    question,
  })

  const analyzeQuestions = () => {
    const enabledQuestionIds: string[] = []

    if (!allQuestions.length) return []

    const { generateTree } = generateLogicTree(allQuestions)
    const tree = generateTree()

    let currentStep = 0

    while (currentStep !== -1 && currentStep < tree.length) {
      const {
        question: { external_reference, value, logic },
        nextQuestion,
        hasLogic,
      } = tree[currentStep]

      external_reference && enabledQuestionIds.push(external_reference)

      if (!hasLogic) {
        currentStep = nextQuestion[0] || 0
        continue
      }

      const matchingCase = logic?.switch?.cases
        // eslint-disable-next-line no-loop-func
        .find((condition) => {
          const baseConditionMet = checkIfAnswerFulfillsRequirement(
            condition,
            questionToLogicTest(tree[currentStep].question),
            value
          )

          if (!condition.additionalConditions) return baseConditionMet

          const allAdditionalConditionsMet = condition.additionalConditions?.every((additionalCondition) => {
            // Scope to current section when question within loop
            // All questions until here and then last occurance
            const questionsUntilHere = allQuestions.slice(0, currentStep)
            const sourceQuestion = questionsUntilHere
              .filter((q) => q.external_reference?.startsWith(additionalCondition.source))
              .pop()

            if (!sourceQuestion || !sourceQuestion?.value) {
              return false
            }
            const conditionIsMet = checkIfAnswerFulfillsRequirement(
              additionalCondition,
              questionToLogicTest(sourceQuestion),
              sourceQuestion?.value
            )

            return conditionIsMet
          })

          return baseConditionMet && allAdditionalConditionsMet
        })

      const nextQuestionUUID = matchingCase?.target || logic?.switch?.default

      if (!nextQuestionUUID) {
        currentStep += 1
        continue
      }

      currentStep = findAllowedIndex(currentStep, nextQuestionUUID)
    }

    return enabledQuestionIds
  }

  return analyzeQuestions()
}
