import { FC } from 'react'
import { HiOutlineDocumentDuplicate, HiOutlineDocumentText } from 'react-icons/hi2'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { RequestSearchType } from '../../../../Search/Types/SearchEntitiesTypes'
import { unixtimeDateFormatter } from '../../../../../shared/constants/dateFormat'
import { Avatar, AvatarFallback } from '../../../../../shared/components/atoms/Avatar/Avatar'
import stringToHslColor from '../../../../../shared/utils/stringToHslColor'
import { getUserInitialsFromFullNameOrEmail } from '../../../../../shared/utils/getUserInitials'
import StatusIndicatorWithTooltip from '../../../../../shared/components/atoms/StatusIndicatorWithTooltip'
import useDuplicateIntake from '../../../../Intake/IntakeOverview/hooks/useDuplicateIntake'
import LhotseHomeResultTransition from './LhotseHomeResultTransition'

interface ILhotseHomeRequestResult {
  request: RequestSearchType
}

const LhotseHomeRequestResult: FC<ILhotseHomeRequestResult> = ({ request }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const userInitials = getUserInitialsFromFullNameOrEmail(request.author_full_name, request.author_email)
  const { mutate: duplicateIntake } = useDuplicateIntake()

  return (
    <LhotseHomeResultTransition>
      <div
        onClick={() => {
          history.push(`/requests/${request.intake_uuid}`)
        }}
        role="button"
        tabIndex={0}
        className="group relative bg-white rounded-lg shadow-sm p-3 flex items-center focus:shadow-md hover:shadow-md transition"
      >
        <div className="flex items-center space-x-3 w-full cursor-pointer">
          <div className="bg-gray-950 p-2.5 rounded-md text-white">
            <HiOutlineDocumentText className="h-6 w-6" />
          </div>
          <div className="w-full space-y-0.5">
            <div className="flex flex-row justify-between items-center">
              <div className="text-xs font-medium text-gray-400">{request.unique_name_identifier}</div>
              <StatusIndicatorWithTooltip status={request.status} />
            </div>
            <div className="text-sm font-medium text-gray-600 line-clamp-1 pr-1">{request.name}</div>
            <div className="flex items-center">
              <Avatar className="h-5 w-5">
                <AvatarFallback
                  style={{ backgroundColor: stringToHslColor({ name: userInitials }) }}
                  className="font-semibold text-[8px]"
                >
                  {userInitials}
                </AvatarFallback>
              </Avatar>
              <span className="mx-1 text-gray-200">·</span>
              <span className="text-sm font-medium text-gray-400">{unixtimeDateFormatter(request.created_at)}</span>
            </div>
          </div>
        </div>
        {/* Icon menu, hidden by default and shown on group hover, positioned absolutely above the card */}
        <div className="absolute top-0 right-0 transform translate-y-[-100%] transition-opacity opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto flex rounded">
          <div className="flex flex-row bg-gray-950 rounded mb-1">
            <button
              type="button"
              tabIndex={-1}
              aria-label={t('generic.duplicate', 'Duplicate')}
              className="text-white p-2 hover:bg-gray-800 rounded-l transition-colors flex flex-row items-center"
              onClick={() => duplicateIntake(request.intake_uuid)}
            >
              <HiOutlineDocumentDuplicate className="h-5 w-5 mr-1" />
              <span className="text-sm">{t('generic.duplicate', 'Duplicate')}</span>
            </button>
          </div>
        </div>
      </div>
    </LhotseHomeResultTransition>
  )
}

export default LhotseHomeRequestResult
