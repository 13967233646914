import { useTranslation } from 'react-i18next'
import EditableText from '../misc/EditableText'
import { useWorkflow } from '../../providers/WorkflowProvider'
import { useWorkflowBlockDraft } from '../../providers/WorkflowBlockDraftProvider'

export const WorkflowBlockTitle = () => {
  const { t } = useTranslation()
  const { readonly } = useWorkflow()
  const { block, updateBlock, errors } = useWorkflowBlockDraft()

  const handleSubmit = (name: string) => {
    updateBlock({ name })
  }

  return (
    <EditableText
      value={block.name}
      onChange={handleSubmit}
      editable={!readonly}
      disabled={readonly}
      hideControls
      error={errors.name}
      placeholder={t('workflow.block_name_placeholder', 'Block name')}
    />
  )
}

export default WorkflowBlockTitle
