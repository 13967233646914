import { Popover, Transition } from '@headlessui/react'
import { Fragment, ReactElement, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import PopoverModal from './PopoverModal'
import getPopoverStyles from './PopoverUtils'

interface IPopoverComponent extends INodeChildren {
  popoverButton: ReactElement
  popoverClassName?: string
  popoverContentClassName?: string
  closeOnClick?: boolean
  buttonId?: string
  buttonCy?: string
  scrollableElement?: HTMLElement
}
const PopoverComponent: React.FC<IPopoverComponent> = ({
  popoverButton,
  popoverClassName = 'absolute top-0 w-80',
  children,
  popoverContentClassName = 'flex flex-col gap-2 bg-white',
  closeOnClick = true,
  buttonId,
  buttonCy,
  scrollableElement,
}) => {
  const root = document.getElementById('headlessui-portal-root') || (document.getElementById('root') as HTMLElement)

  const buttonRef = useRef<null>()

  const modalRef = useRef<HTMLDivElement>(null)
  const popoverButtonRef = useRef<HTMLButtonElement>(null)

  const [popoverPanelStyle, setPopoverPanelStyle] = useState<any>()

  const updateStyles = () => {
    setPopoverPanelStyle(getPopoverStyles(popoverButtonRef.current, modalRef.current))
  }

  return (
    <div id="popover-component">
      <Popover className="flex items-center justify-end overflow-visible" data-cy="popover-component">
        {() => (
          <>
            <span ref={buttonRef as any} className="flex">
              <Popover.Button id={buttonId} data-cy={buttonCy || 'popover-component-button'} ref={popoverButtonRef}>
                {popoverButton}
              </Popover.Button>
            </span>
            {createPortal(
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className={`absolute ${popoverClassName}`} style={popoverPanelStyle}>
                  {({ close }) => (
                    <PopoverModal
                      close={close}
                      popoverContentClassName={popoverContentClassName}
                      closeOnClick={closeOnClick}
                      scrollableElement={scrollableElement}
                      ref={modalRef}
                      updateStyles={updateStyles}
                    >
                      {children}
                    </PopoverModal>
                  )}
                </Popover.Panel>
              </Transition>,
              root
            )}
          </>
        )}
      </Popover>
    </div>
  )
}

export default PopoverComponent
