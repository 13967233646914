import { useTranslation } from 'react-i18next'
import { HiSparkles } from 'react-icons/hi2'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import BaseInputWrapper from '../../../../../../shared/components/atoms/Input/BaseInputWrapper'
import SearchSelect from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import useGetFormData from '../../../../hooks/useGetFormData'
import { ToggleOption } from '../../../../../../types/shared/ToggleButtonTypes'
import ToggleButton from '../../../../../../shared/components/atoms/buttons/ToggleButton'
import { CopilotModeType } from '../../../../../../types/Forms'

const PromptSettings: React.FC = () => {
  const { t } = useTranslation()
  const {
    question: [question],
    updateQuestion,
  } = useFormContext()
  const { data: form } = useGetFormData()

  const fields = form?.template.sections.flatMap((section) =>
    section.fields.map((field) => ({
      label: `${field.inputName || ''} ${field.inputName ? ':' : ''} ${field.label || ''}`,
      id: field.__uuid,
    }))
  )

  const coPilotSettings = question?.__settings?.coPilot

  const toggleOptions: ToggleOption[] = [
    {
      id: 'gpt',
      label: t('copilot.ai_gpt', 'AI (GPT)'),
    },
    { id: 'embeddings', label: t('copilot.embeddings', 'Embeddings') },
  ]

  const aiSettings = (
    <>
      <BaseInputWrapper label="Specify the OpenAI model.">
        <textarea
          className="base-form-input"
          rows={1}
          value={coPilotSettings?.model}
          onChange={(e) => updateQuestion({ __settings: { coPilot: { model: e.target.value } } })}
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Enter the 'system' prompt.">
        <textarea
          className="base-form-input"
          rows={5}
          value={coPilotSettings?.systemPrompt}
          onChange={(e) => updateQuestion({ __settings: { coPilot: { systemPrompt: e.target.value } } })}
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Specify the temperature (max: 2)">
        <input
          className="base-form-input"
          type="number"
          step={0.01}
          onWheel={(e) => e.currentTarget.blur()}
          value={`${coPilotSettings?.temperature}`}
          onChange={(e) => updateQuestion({ __settings: { coPilot: { temperature: parseFloat(e.target.value) } } })}
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Specify the max tokens (max: 4096)">
        <input
          className="base-form-input"
          type="number"
          step={1}
          onWheel={(e) => e.currentTarget.blur()}
          value={`${coPilotSettings?.maxTokens}`}
          onChange={(e) => updateQuestion({ __settings: { coPilot: { maxTokens: parseInt(e.target.value, 10) } } })}
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Specify the top P (max: 1)">
        <input
          className="base-form-input"
          type="number"
          step={0.01}
          max={1}
          onWheel={(e) => e.currentTarget.blur()}
          value={`${coPilotSettings?.topP}`}
          onChange={(e) => updateQuestion({ __settings: { coPilot: { topP: parseFloat(e.target.value) } } })}
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Specify the frequency penalty (max: 2)">
        <input
          className="base-form-input"
          type="number"
          step={0.01}
          max={2}
          onWheel={(e) => e.currentTarget.blur()}
          defaultValue={`${coPilotSettings?.frequencyPenalty}`}
          onChange={(e) =>
            updateQuestion({ __settings: { coPilot: { frequencyPenalty: parseFloat(e.target.value) } } })
          }
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Specify the presence penalty max: 2)">
        <input
          className="base-form-input"
          type="number"
          step={0.01}
          max={2}
          onWheel={(e) => e.currentTarget.blur()}
          defaultValue={`${coPilotSettings?.presencePenalty}`}
          onChange={(e) => updateQuestion({ __settings: { coPilot: { presencePenalty: parseFloat(e.target.value) } } })}
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Enter prompt. You can add {{this}} to reference the value of the current input or add variables in the {{ }} notation by selecting the field in the dropdown below">
        <textarea
          className="base-form-input"
          rows={5}
          value={coPilotSettings?.prompt}
          onChange={(e) => updateQuestion({ __settings: { coPilot: { prompt: e.target.value } } })}
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Select the field you want to reference to get the variable name in the {{ }} notation.">
        <SearchSelect
          options={fields}
          onChange={(selection) => {
            window.prompt('Copy to clipboard: Ctrl+C, Enter', `{{${selection.id.toString()}}}`)
          }}
          nullable
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Enter a call to action here to generate a button that will trigger the prompt.">
        <textarea
          className="base-form-input"
          rows={1}
          value={coPilotSettings?.callToAction}
          onChange={(e) => updateQuestion({ __settings: { coPilot: { callToAction: e.target.value } } })}
        />
      </BaseInputWrapper>
    </>
  )

  const embeddingsSettings = (
    <>
      <BaseInputWrapper label="Select the field you want to use as a query to the embeddings.">
        <SearchSelect
          options={fields}
          currentSelection={fields?.find((field) => field.id === coPilotSettings?.embeddings?.query)}
          onChange={(selection) => {
            updateQuestion({ __settings: { coPilot: { embeddings: { query: selection.id.toString() } } } })
          }}
          nullable
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Threshold (default 0.5)">
        <input
          className="base-form-input"
          type="number"
          step={0.01}
          max={2}
          onWheel={(e) => e.currentTarget.blur()}
          defaultValue={`${coPilotSettings?.embeddings?.threshold}`}
          onChange={(e) =>
            updateQuestion({ __settings: { coPilot: { embeddings: { threshold: parseFloat(e.target.value) } } } })
          }
        />
      </BaseInputWrapper>
      <BaseInputWrapper label="Count (default 10)">
        <input
          className="base-form-input"
          type="number"
          step={0.01}
          max={2}
          onWheel={(e) => e.currentTarget.blur()}
          defaultValue={`${coPilotSettings?.embeddings?.count}`}
          onChange={(e) =>
            updateQuestion({ __settings: { coPilot: { embeddings: { count: parseFloat(e.target.value) } } } })
          }
        />
      </BaseInputWrapper>
    </>
  )

  return (
    <div className="p-4 border rounded border-sky-blue bg-sky-blue-light bg-opacity-10 flex flex-col gap-4">
      <div className="flex gap-2">
        <HiSparkles className="w-6 h-6 text-sky-blue" />
        <span className="font-bold">Lhotse Co-Pilot</span>
      </div>

      <BaseInputWrapper label="Enable Co-Pilot">
        <ToggleButton
          options={toggleOptions}
          selectedOptionId={coPilotSettings?.mode || 'gpt'}
          onChange={(mode) => updateQuestion({ __settings: { coPilot: { mode: mode.id as CopilotModeType } } })}
        />
      </BaseInputWrapper>

      {coPilotSettings?.mode === 'embeddings' ? embeddingsSettings : aiSettings}
    </div>
  )
}

export default PromptSettings
