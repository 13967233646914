import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../../../../shared/components/organisms/modals/ConfirmationModal'
import TextareaInput from '../../../../../../shared/components/atoms/Input/TextareaInput'
import { ActiveWorkflowApprovalActionsType } from '../../../../../../types/Workflow/ActiveWorkflow'

interface IConfirmDeclineModal {
  blockUuid: string
  handleWorkflowAction: (action: ActiveWorkflowApprovalActionsType, blockUuid: string, comment?: string) => void
}

const ConfirmDeclineModal: FC<IConfirmDeclineModal> = ({ blockUuid, handleWorkflowAction }) => {
  const [comment, setComment] = useState<string>('')
  const { t } = useTranslation()

  return (
    <ConfirmationModal onConfirm={() => handleWorkflowAction('declined', blockUuid, comment)}>
      <TextareaInput
        label={t('generic.decline-reason', 'Please provide a reason for declining this request.')}
        onChange={(e) => setComment(e.currentTarget.value)}
      />
    </ConfirmationModal>
  )
}

export default ConfirmDeclineModal
