import { useQuestionItemsList } from './QuestionItemsList'
import QuestionInputTextGray from '../inputs/QuestionInputTextGray'
import type { QuestionItemType } from './QuestionItem'

interface IQuestionChoiceInput {
  item: QuestionItemType
}

const QuestionItemInput: React.FC<IQuestionChoiceInput> = ({ item }) => {
  const { onChange } = useQuestionItemsList()

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ __uuid: item.__uuid, value: e.target.value })
  }

  return (
    <QuestionInputTextGray
      placeholder="Enter value here..."
      fullWidth
      onChange={handleOnChange}
      value={item.value}
      error={!item.value && 'Field cannot be empty'}
    />
  )
}

export default QuestionItemInput
