import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import getEditMenu from '../../../shared/components/organisms/menus/hooks/getEditMenu'
import Menu from '../../../shared/components/organisms/menus/Menu'
import AddressInput from '../../../shared/components/organisms/inputs/AddressInput'
import AddressInfo from './AddressInfo'
import useGetSupplierBranch from '../hooks/useGetSupplierBranch'
import useAddAddressModal from '../../Settings/Views/General/addresses/useAddAddressModal'
import { useUtils } from '../../../core/providers/UtilsProvider'
import { apiDeleteSupplierBranchAddress, apiPostSupplierBranchAddress } from '../../Suppliers/services/SuppliersService'
import ConfirmationModal from '../../../shared/components/organisms/modals/ConfirmationModal'

interface ISupplierAddress {
  editable?: boolean
}

const SupplierAddress: React.FC<ISupplierAddress> = ({ editable }) => {
  const { t } = useTranslation()
  const { data: supplierBranchData, refetch } = useGetSupplierBranch()
  const { modal } = useUtils()

  const [editMode, setEditMode] = useState(false)

  const addNewAddress = useAddAddressModal({
    onSubmit: async (companyAddress) => {
      if (!supplierBranchData) return
      modal.set({ isOpen: false })
      await apiPostSupplierBranchAddress(supplierBranchData?.id, companyAddress)
      refetch()
    },
  })

  const removeAddress = () =>
    modal.set({
      isOpen: true,
      title: t('generic.confirm'),
      content: (
        <ConfirmationModal
          confirmString={t('confirm.are_you_sure_delete')}
          onConfirm={async () => {
            modal.set({ isOpen: false })
            await apiDeleteSupplierBranchAddress(supplierBranchData?.address?.id)
            refetch()
          }}
        >
          {t('supplier.are_you_sure_delete_address')}
        </ConfirmationModal>
      ),
    })

  const menuItems = supplierBranchData?.address
    ? [
        ...getEditMenu(setEditMode),
        {
          name: 'generic.delete',
          onClick: removeAddress,
        },
      ]
    : [
        {
          name: 'generic.add',
          onClick: addNewAddress,
        },
      ]

  const onSuccess = () => {
    refetch().then(() => {
      setEditMode(false)
    })
  }

  if (editMode && supplierBranchData?.address) {
    return (
      <AddressInput address={supplierBranchData?.address} onCancel={() => setEditMode(false)} onSuccess={onSuccess} />
    )
  }

  return (
    <div className="flex flex-col gap-y-4">
      <p className="font-semibold">{t('supplier.address')}</p>
      <div className="flex justify-between">
        <AddressInfo />
        {editable && <Menu menuItems={menuItems} buttonCy="supplier-address" />}
      </div>
    </div>
  )
}

export default SupplierAddress
