import { WorkflowChart } from '../../../WorkflowChart'
import { useWorkflow } from '../../providers/WorkflowProvider'
import WorkflowBuilderBlock from './WorkflowBuilderChartNodes/WorkflowBuilderBlock'

export const WorkflowBuilderChart = () => {
  const { workflow, readonly, selectedBlock, createStepDraft, createBlockDraft } = useWorkflow()

  const handleStepAddition = (position: number) => {
    createStepDraft(position)
  }

  const handleBlockAddition = (stepUuid: string) => {
    createBlockDraft(stepUuid)
  }

  return (
    <WorkflowChart
      className="h-full"
      workflow={workflow}
      readonly={readonly}
      selectedBlock={selectedBlock}
      nodes={{
        APPROVAL: WorkflowBuilderBlock,
        APPROVER: WorkflowBuilderBlock,
        ACTION: WorkflowBuilderBlock,
      }}
      onAddStep={handleStepAddition}
      onAddToStep={handleBlockAddition}
    />
  )
}

export default WorkflowBuilderChart
