import QuestionOptionsSelector from './QuestionOptionsSelector'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'
import ValuesDefinition from './ValuesDefinition/ValuesDefinition'

const QuestionDetails: React.FC = () => (
  <div className="rounded-lg flex flex-col gap-y-4 w-full">
    <TextAndSubtitleInput />
    <QuestionOptionsSelector />

    <ValuesDefinition />
  </div>
)

export default QuestionDetails
