import { ReactNode } from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import SharedModal from '../../../shared/components/organisms/modals/Modal/Modal'

interface INonAuthMiddleware extends RouteComponentProps<any> {
  path: string
  component: ReactNode
}

const NonAuthMiddleware: React.FC<INonAuthMiddleware> = ({ path, component }) => (
  <Route path={path}>
    {component}
    <SharedModal />
  </Route>
)
export default withRouter<INonAuthMiddleware, React.FC<INonAuthMiddleware>>(NonAuthMiddleware)
