import { mergeWith, isArray } from 'lodash'
import type { PartialDeep } from 'type-fest'

export const mergeDeepOverwritingArrays = <T extends Record<string, unknown>>(
  target: T,
  ...sources: (PartialDeep<T> | T)[]
): T =>
  mergeWith(
    target,
    ...sources,
    (
      targetValue: unknown,
      sourceValue: unknown,
      property: string,
      target: Record<string, unknown>,
      source: Record<string, unknown>
    ) => {
      if (isArray(targetValue)) {
        return sourceValue
      }

      // eslint-disable-next-line no-prototype-builtins
      if (source.hasOwnProperty(property) && target.hasOwnProperty(property) && sourceValue === undefined) {
        // eslint-disable-next-line no-param-reassign
        delete target[property]
      }

      return undefined
    }
  )

export const getMergedDeepOverwritingArrays = <T extends Record<string, unknown>>(
  source: T,
  ...sources: (PartialDeep<T> | T)[]
): T => mergeDeepOverwritingArrays<T>({} as T, source, ...sources)
