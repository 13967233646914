import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../../../shared/components/atoms/buttons/Button'
import { IntakeFieldType } from '../../../../../../../types/Intake'
import { Offering } from '../../../../../../../types/Offering'
import { OfferingField } from '../../../../../../Offerings/types'
import useOfferingMutations from '../../../../hooks/useOfferingMutations'
import useIntakeFormStore from '../../../../../IntakeFormResponse/stores/useIntakeFormStore'
import IntakeFormField from '../../IntakeFormField'
import OffersSection from './Offers/OffersSection'
import IntakeFormFieldCoPilot from '../../IntakeFormFieldCoPilot'

interface IIntakeOfferingSectionForm {
  offering: Offering
  editModeOn?: boolean
}

const IntakeOfferingSectionForm: React.FC<IIntakeOfferingSectionForm> = ({ offering }) => {
  const { t } = useTranslation()

  const isDraftIntake = useIntakeFormStore((state) => state.intake?.status === 'draft')
  const isInLastSection = useIntakeFormStore(
    (state) => state.intake && state.activeSectionIndex === state.intake.sections.length - 1
  )
  const offerings = useIntakeFormStore((state) => state.offerings)
  const setOfferings = useIntakeFormStore((state) => state.setOfferings)

  const { update } = useOfferingMutations()

  useEffect(() => {
    if (!offering && !isDraftIntake) return
    update.mutate(offering)
  }, [offering])

  const handleUpdateField = (field: IntakeFieldType) => {
    const isFirstOffering = offering.uuid === offerings?.[0].uuid

    const updatedOfferings = offerings?.map((o: Offering) => {
      // Update any offerings that should copy their values from the first offering
      const updatedOfferingFields = o.fields.map((f: OfferingField) => {
        // Update other offering fields
        if (isDraftIntake && isFirstOffering && field.input.settings?.loop?.hasCopiedValuesFromParent) {
          if (f.external_reference === field.external_reference && f.id !== field.id) {
            return { ...f, value: field.value }
          }
        }

        // Update current offering field
        if (f.id === field.id) {
          return field
        }

        return f
      })

      return {
        ...o,
        fields: updatedOfferingFields,
      }
    })

    if (updatedOfferings) {
      setOfferings(updatedOfferings)
    }
  }

  const [editModeOn, setEditModeOn] = useState<boolean>(isDraftIntake && !isInLastSection)
  const isFirstOffering = offerings && offerings[0].uuid === offering.uuid

  return (
    <div className="flex flex-col gap-y-8">
      {offering.fields.map((field) => {
        const copiesFromParent =
          (isDraftIntake &&
            !isFirstOffering &&
            field.input?.settings?.loop?.hasCopiedValuesFromParent &&
            field.input?.settings?.loop?.hasCopiedValuesFromParentisForced) ||
          false

        if (field.input.settings?.coPilot) {
          return (
            <IntakeFormFieldCoPilot
              key={`intake-form-field-copilot-${field.id}`}
              field={field}
              updateField={handleUpdateField}
              editModeOn={editModeOn}
              disabled={copiesFromParent}
            />
          )
        }

        return (
          <IntakeFormField
            key={`${field.id}-${copiesFromParent ? field.value?.value : ''}`}
            field={field}
            updateField={handleUpdateField}
            editModeOn={editModeOn}
            disabled={copiesFromParent}
          />
        )
      })}

      {!isDraftIntake && (
        <div className="col-span-2">
          {editModeOn ? (
            <div className="flex gap-x-2">
              <Button
                className="btn-ghost"
                onClick={() => {
                  setEditModeOn(false)
                }}
              >
                {t('generic.cancel', 'Cancel')}
              </Button>
              <Button
                className="btn-primary"
                onClick={() => {
                  update.mutate(offering)
                  setEditModeOn(false)
                }}
              >
                {t('generic.save', 'Save')}
              </Button>
            </div>
          ) : (
            <Button className="btn-primary" onClick={() => setEditModeOn(true)}>
              {t('generic.edit', 'Edit')}
            </Button>
          )}
        </div>
      )}
      <OffersSection offering={offering} editModeOn={editModeOn} />
    </div>
  )
}

export default IntakeOfferingSectionForm
