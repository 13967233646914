import { AriaRole, Children, cloneElement, FC, isValidElement } from 'react'
import cn from '../../../../lib/util'
import { INodeOrStringChildren } from '../../../../types/shared/GeneralTypes'

interface ITableRow extends INodeOrStringChildren {
  referenceId?: string
  flex?: boolean
  className?: string
  onClick?: (id: string | undefined) => void
  active?: boolean
  role?: AriaRole
}

const TableRow: FC<ITableRow> = ({ children, flex, className, onClick, active, referenceId, role = 'button' }) => {
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child)
    }
    return child
  })

  return (
    <tr
      role={role}
      tabIndex={0}
      onClick={(): void => onClick && onClick(referenceId)}
      className={cn('my-2', flex ? 'flex' : 'table-row', active ? 'bg-gray-100' : '', className)}
    >
      {childrenWithProps}
    </tr>
  )
}

export default TableRow
