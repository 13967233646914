import { useHistory } from 'react-router'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import useSaveQuestion from '../../../../modules/Forms/FormBuilder/hooks/useSaveQuestion'
import TributtonModal from './TributtonModal'
import { useNavigationPrompt } from '../../../providers/NavigationPropmptProvider'

interface ICompanyFormUnsavedChangesModal {}

const CompanyFormUnsavedChangesModal: React.FC<ICompanyFormUnsavedChangesModal> = () => {
  const { modal } = useUtils()
  const history = useHistory()
  const { removePrompt } = useNavigationPrompt()

  const { forwardingUrl } = modal.get

  const saveQuestion = useSaveQuestion()

  const redirectToUrl = () => {
    if (forwardingUrl) {
      modal.set({ isOpen: false })
      removePrompt()
      history.push(forwardingUrl)
    }
  }

  return (
    <TributtonModal
      alertButtonText="Yes, discard changes"
      handleAlertButton={redirectToUrl}
      neutralButtonText="No, stay here"
      positiveButtonText="No, save draft"
      handlePositiveButton={async () => {
        modal.update({ isLoading: true })
        await saveQuestion()
        modal.update({ isLoading: false })
        redirectToUrl()
      }}
      text="You have unsaved changes to this form. Any changes will be discarded if you navigate away from this page. Are you sure you want to discard changes to this form?"
    />
  )
}

export default CompanyFormUnsavedChangesModal
