import AnalyticsPage from '../../modules/Analytics/AnalyticsPage'
import CompanyPage from '../../modules/Settings/CompanyPage'
import UserDetails from '../../modules/Settings/Views/Users/UserDetails/UserDetails'
import SchemaDetailView from '../../modules/Settings/Views/Schemas/SchemaDetailView'
import ExternalDataEntityPage from '../../modules/ExternalDataManager/pages/ExternalDataEntityPage'
import FormCreatePage from '../../modules/Forms/FormBuilder/FormCreatePage'
import FormBuilderPage from '../../modules/Forms/FormBuilder/FormsPage/FormBuilderPage'
import FormQuestionPage from '../../modules/Forms/FormBuilder/QuestionPage/FormQuestionPage'
import Intake from '../../modules/Intake/Intake'
import LhotseHomeDashboard from '../../modules/LhotseHome/LhotseHomeDashboard'
import LhotseHome from '../../modules/LhotseHome/LhotseHome'
import OnboardingPage from '../../modules/Onboarding/OnboardingPage'
import SupplierDetailsPage from '../../modules/SupplierDetails/SupplierDetailsPage/SupplierDetailsPage'
import SuppliersPage from '../../modules/Suppliers/SuppliersPage'
import { WorkflowBuilderPage } from '../../modules/Workflow'
import WorkflowDetailsPage from '../../modules/Workflow/pages/WorkflowDetailsPage'
import RouteType from '../../types/shared/RouteType'

const formRoutes: RouteType[] = [
  {
    path: '/forms/:formId/create',
    exact: true,
    component: <FormCreatePage />,
  },
  {
    path: '/forms/:formId/:location',
    exact: true,
    component: <FormBuilderPage />,
  },
  {
    path: '/forms/:formId/section/:sectionId/question/:questionId/:location',
    exact: true,
    component: <FormQuestionPage />,
  },
]

const workflowRoutes = [
  {
    path: '/workflows/:uuid/builder',
    exact: true,
    component: <WorkflowBuilderPage />,
  },
  {
    path: '/workflows/:uuid/version/:version',
    exact: true,
    component: <WorkflowDetailsPage />,
  },
]

// TODO: Check if we still use intake-flow/ route
const privateRoutes: RouteType[] = [
  ...formRoutes,
  ...workflowRoutes,
  {
    path: '/intake-flow/:intakeId',
    component: <Intake />,
  },
  {
    path: '/requests/:intakeId',
    component: <Intake />,
  },
  {
    path: '/settings/:tab?',
    component: <CompanyPage />,
  },
  {
    path: '/users/:userId',
    component: <UserDetails />,
  },
  {
    path: '/data-manager/:uuid',
    component: <ExternalDataEntityPage />,
  },
  {
    path: '/schemas/:uuid',
    component: <SchemaDetailView />,
  },
  {
    path: '/suppliers',
    component: <SuppliersPage />,
    exact: true,
  },
  {
    path: '/supplier/:supplierBranchId/:screen?',
    exact: true,
    component: <SupplierDetailsPage />,
  },
  {
    path: '/basecamp/:error?',
    exact: true,
    component: <LhotseHomeDashboard />,
  },
  {
    path: '/intake-flows',
    exact: true,
    component: <LhotseHomeDashboard />,
  },
  {
    path: '/',
    exact: true,
    component: <LhotseHome />,
  },
  {
    path: '/intake-flows/create',
    exact: true,
    component: <LhotseHome />,
  },
  {
    path: '/onboarding/:step?',
    exact: true,
    component: <OnboardingPage />,
  },
  {
    path: '/analytics',
    exact: true,
    component: <AnalyticsPage />,
  },
]

export default privateRoutes
