import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactTooltip, { TooltipProps } from 'react-tooltip'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import useHandleWindowResize from './hooks/useHandleWindowResize'

type ITooltip = {
  tooltip: string
  showOnlyOnSmallScreenSize?: boolean
  showCondition?: boolean
  className?: string
  style?: React.CSSProperties
  id?: number | string
  dataCy?: string
} & TooltipProps

const Tooltip: React.FC<React.PropsWithChildren<ITooltip>> = ({
  children,
  tooltip,
  showOnlyOnSmallScreenSize,
  showCondition,
  className,
  style,
  id,
  dataCy,
  ...tooltipProps
}) => {
  const { t } = useTranslation()

  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  const [toolTipMounted, setToolTipMounted] = useState(false)

  useHandleWindowResize(setWindowSize)

  const hideOnSmallScreen = showOnlyOnSmallScreenSize && windowSize.width < 1440

  const showTooltip = !hideOnSmallScreen && toolTipMounted

  const stringId = id ? id?.toString() : undefined

  return (
    <>
      <div
        data-tip={stringId || tooltip}
        data-for={stringId || tooltip}
        className={className}
        style={style}
        onMouseEnter={() => setToolTipMounted(true)}
        onMouseLeave={() => setToolTipMounted(false)}
        data-cy={dataCy}
      >
        {children}
      </div>
      {showTooltip && showCondition && (
        <ReactTooltip className="w-fit break-all" {...tooltipProps} id={stringId || tooltip}>
          <p className="max-w-xl">{t(tooltip)}</p>
        </ReactTooltip>
      )}
    </>
  )
}

export default Tooltip
