import CompanyInputFilters from '../../../../../../CompanyInputs/CompanyInputFilters'
import QuestionTypeOption from './QuestionTypeOption'
import { useInputTemplates } from '../../../../../../CompanyInputs/providers/InputTemplatesProvider'
import { InputTemplate } from '../../../../../../../types/Forms'

type QuestionTypesListProps = {
  onClick: (input: InputTemplate) => void
}

export const QuestionTypesList = ({ onClick }: QuestionTypesListProps) => {
  const {
    templates: { data: inputs },
  } = useInputTemplates()

  return (
    <div className="flex flex-col gap-y-3 py-3">
      <CompanyInputFilters />
      {inputs?.map((input) => <QuestionTypeOption key={input.uuid} input={input} handleClick={() => onClick(input)} />)}
    </div>
  )
}

export default QuestionTypesList
