import * as React from 'react'
import { WorkflowBlock } from '../../../../../../types/Workflow/WorkflowBlock'
import { useBlockIcon } from '../../../../hooks/useBlockIcon'

export const WorkflowBlockHeadline = ({ block }: { block: WorkflowBlock }) => {
  const Icon = useBlockIcon(block)

  return (
    <div className="flex flex-row gap-3 border-b border-b-ice-blue pb-2 w-full items-center">
      {Icon && (
        <div className="flex-grow-0 rounded-md p-1 border-2 border-sky-blue-light text-sky-blue-light">
          <Icon className="h-6" />
        </div>
      )}
      <div className="flex-grow-1 font-medium">{block.name}</div>
    </div>
  )
}

export default WorkflowBlockHeadline
