import { MessageType } from '../../types/Messenger/types'

export const scrollToBottomOfChat = () => {
  setTimeout(() => {
    const scrollableContainer = document.getElementById('message-container') as HTMLElement
    scrollableContainer?.scroll({ top: 9999999999999, behavior: 'smooth' })
  }, 50)
}

export const getMessageBubbleClasses = (message: MessageType) => {
  const baseClasses = `my-1 justify-between border shadow border-gray-300 ${
    message.is_my_message ? ' bg-blue-50 ' : ' bg-white '
  }`
  return baseClasses
}

export const scrollToMessage = (referenceId: string) => {
  const messageContainer = document.getElementById('message-container')
  const referencedMessageBubble = document.getElementById(`message-${referenceId}`)?.offsetTop

  messageContainer?.scrollTo({ top: referencedMessageBubble, behavior: 'smooth' })
}
