import { DropResult } from 'react-beautiful-dnd'
import { FormSectionType, FormDataType } from '../../../../../../types/Forms/formTypes'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateForm from '../../../hooks/useUpdateForm'

export default (section: FormSectionType) => {
  const { updateForm } = useUpdateForm()
  const { data: formData } = useGetFormData()
  return (result: DropResult) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index

    if (!formData || destinationIndex === undefined) return

    const newQuestions = formData.template.sections.find((templateSection) => templateSection.__uuid === section.__uuid)
      ?.fields

    if (!newQuestions) return

    const item = newQuestions.splice(sourceIndex, 1)
    newQuestions.splice(destinationIndex, 0, item[0])

    updateForm({
      template: {
        sections: formData.template.sections.map((templateSection) =>
          templateSection.__uuid === section.__uuid
            ? {
                ...templateSection,
                fields: newQuestions,
              }
            : templateSection
        ),
      },
    })
  }
}
