import { CgSpinner } from 'react-icons/cg'

interface ICoreLoadingIcon {
  className?: string
  dataCy?: string
}

const CoreLoadingIcon: React.FC<ICoreLoadingIcon> = ({ className = '' }) => (
  <div className={`flex h-full w-full justify-center items-center ${className}`} data-cy="loading-spinner">
    <CgSpinner className="animate-spin text-xl" />
  </div>
)

export default CoreLoadingIcon
