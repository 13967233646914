import DropzoneArea from '../../../../../../shared/components/atoms/DropzoneArea'
import { toastr } from '../../../../../../shared/components/organisms/toastr'
import toBase64 from '../../../../../../shared/utils/toBase64'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateForm from '../../../hooks/useUpdateForm'

const ThumbnailDropzone = () => {
  const { updateForm } = useUpdateForm()
  const { data: companyForm, refetch } = useGetFormData()

  const onFileUpload = async (files: File[]) => {
    if (!files || !companyForm) return

    try {
      const base64 = await toBase64(files[0])
      if (typeof base64 !== 'string') return

      await updateForm({ thumbnail: { name: files[0].name, content: base64, url: '' } })
      await refetch()
    } catch {
      toastr.error()
    }
  }

  return (
    <div className="max-w-xl w-full">
      <DropzoneArea
        onDrop={onFileUpload}
        allowedFileTypes={['image/jpeg', 'image/png', 'image/gif'].map((type) => ({ label: type, id: type }))}
      />
    </div>
  )
}

export default ThumbnailDropzone
