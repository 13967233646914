import CoreTopbarLayout from '../../core/components/layout/CoreTopbarLayout'
import SupplierTopbar from './components/layout/SupplierTopbar'
import SuppliersContainer from './components/SuppliersContainer'

const SuppliersPage = () => (
  <CoreTopbarLayout topbar={<SupplierTopbar />}>
    <SuppliersContainer />
  </CoreTopbarLayout>
)

export default SuppliersPage
