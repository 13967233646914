import { useTranslation } from 'react-i18next'
import Button from '../../../shared/components/atoms/buttons/Button'
import { useExternalDataUploadModal } from '../components/ExternalDataModals'
import ExternalDataSourcesTable from '../components/ExternalDataSourcesTable/ExternalDataSourcesTable'
import BaseSettingsSection from '../../Settings/components/BaseSettingsSection'

export const ExternalDataListPage = () => {
  const { t } = useTranslation()
  const openUploadModal = useExternalDataUploadModal()

  return (
    <BaseSettingsSection
      title={t('generic.external_data', 'External data')}
      description={t('external_data.description', 'Import and use data from external sources.')}
      action={
        <div className="flex justify-end">
          <Button variant="solid" color="sky-blue" onClick={openUploadModal}>
            {t('external_data.import', 'Import data')}
          </Button>
        </div>
      }
    >
      <ExternalDataSourcesTable />
    </BaseSettingsSection>
  )
}

export default ExternalDataListPage
