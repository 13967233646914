import { useTranslation } from 'react-i18next'
import PageTopbar from '../../../../shared/components/layout/PageTopBar'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import SupplierNewModal from '../SupplierNewModal'
import AddButton from '../../../../shared/components/atoms/buttons/AddButton'

const SupplierTopbar = () => {
  const { modal } = useUtils()
  const { t } = useTranslation()
  const handlerDisplayModal = () => {
    modal.set({
      title: t('suppliers.add_supplier'),
      content: <SupplierNewModal />,
      isLoading: false,
      isOpen: true,
    })
  }

  return (
    <PageTopbar
      title={t('suppliers.headline')}
      actions={
        <AddButton onClick={handlerDisplayModal} dataCy="add-supplier-data-button">
          {t('suppliers.add_supplier')}
        </AddButton>
      }
    />
  )
}

export default SupplierTopbar
