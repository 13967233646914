import { Dispatch, SetStateAction, createContext, useContext, useState, useMemo } from 'react'
import type { PartialDeep } from 'type-fest'
import { FormQuestionType, FormSectionType, FormTemplateType } from '../../types/Forms'
import { WorkflowType } from '../../types/Forms/workflowTypes'
import { INodeChildren } from '../../types/shared/GeneralTypes'
import { getMergedDeepOverwritingArrays } from '../../shared/utils/objectHelpers'
import { StateCallback, useStateWithCallback } from '../hooks/useStateWithCallback'

export type PartialSection = { __uuid: 'Confirmation' | string } & Partial<FormSectionType>

type FormResponseConfig = {
  viewType: 'simple' | 'form'
  activeSectionUUID?: string
  questionNavigatorActive: boolean
}

type FormContextType = {
  question: [FormQuestionType | undefined, Dispatch<SetStateAction<FormQuestionType | undefined>>]
  updateQuestion: (newConfig: PartialDeep<FormQuestionType>) => void
  submission: [
    FormTemplateType | undefined,
    (state: FormTemplateType | undefined, callback?: StateCallback<FormTemplateType | undefined>) => void,
  ]
  responseConfig: [FormResponseConfig, Dispatch<SetStateAction<FormResponseConfig>>]
  updateConfig: (newConfig: PartialDeep<FormResponseConfig>) => void
  workflow: [WorkflowType | undefined, Dispatch<SetStateAction<WorkflowType | undefined>>]
  lastSaved: [Date | undefined, Dispatch<SetStateAction<Date | undefined>>]
  findQuestion: (questionUuid: string) => FormQuestionType | undefined
  findSection: (questionUuid: string) => FormSectionType | undefined
}

const FormContext = createContext<FormContextType>({} as FormContextType)

export function useFormContext() {
  return useContext(FormContext)
}

const FormProvider: React.FC<INodeChildren> = ({ children }) => {
  const question = useState<FormQuestionType>()
  const submission = useStateWithCallback<FormTemplateType | undefined>()
  const responseConfig = useState<FormResponseConfig>({ viewType: 'simple', questionNavigatorActive: false })
  const workflow = useState<WorkflowType>()
  const lastSaved = useState<Date>()

  const values = useMemo(
    () => ({
      question,
      updateQuestion: (newQuestion: PartialDeep<FormQuestionType>) =>
        question[1](
          getMergedDeepOverwritingArrays<FormQuestionType>(question[0] || ({} as FormQuestionType), newQuestion)
        ),
      submission,
      responseConfig,
      updateConfig: (newConfig: PartialDeep<FormResponseConfig>) =>
        responseConfig[1](getMergedDeepOverwritingArrays<FormResponseConfig>(responseConfig[0], newConfig)),
      workflow,
      lastSaved,
      findQuestion: (questionUuid: string) =>
        submission[0]?.sections
          ?.flatMap((section) => section.fields)
          .find((question) => question.__uuid === questionUuid),
      findSection: (questionUuid: string) =>
        submission[0]?.sections?.find((section) => section.fields.find((question) => question.__uuid === questionUuid)),
    }),
    [question, submission, responseConfig, workflow, lastSaved]
  )

  return <FormContext.Provider value={values}>{children}</FormContext.Provider>
}

export default FormProvider
