import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { LanguageType } from '../../../../../types/shared/CoreTypes'
import { dateFormat } from '../../../../../shared/constants/dateFormat'
import keys from '../constants/settingsQueryKeys'
import { apiGetUser } from '../services/SettingsService'

export default () => {
  const { data: userData } = useQuery([keys.USER_PROFILE], apiGetUser)
  const { t } = useTranslation()

  return {
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    email: userData?.email || '',
    added_on: dayjs(userData?.created_at).format(dateFormat),
    id_provider: userData?.sso_provider || t('account.direct_registration'),
    role: userData?.role || '',
    language: userData?.language || ('de' as LanguageType),
    id: userData?.id || 0,
    sso_provider: userData?.sso_provider || '',
  }
}
