import { Dispatch, SetStateAction, useEffect } from 'react'
import FormikSelect from '../../../../../../shared/components/organisms/formik/FormikSelect'
import { CompanyAddressType } from '../../../../../../types/Company/AddressTypes'
import { CountryOptionType } from '../../../../../../types/Company/CountryType'

interface IAddressSelectCountry {
  countryCode?: string
  error?:
    | {
        props: {
          error: string
        }
      }
    | undefined
  onChange: (addressId?: number) => void
  values: Partial<CompanyAddressType> | undefined
  countryOptions: CountryOptionType[]
  className?: string
}

const AddressSelectCountry: React.FC<IAddressSelectCountry> = ({
  className = '',
  countryCode,
  error,
  values,
  countryOptions,
  onChange,
}) => {
  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(value, 10))
  }

  useEffect(() => {
    if (!countryCode || !countryOptions) return

    onChange(countryOptions.find((c: CountryOptionType) => c.code === countryCode)?.value)
  }, [countryCode, countryOptions])

  return (
    <FormikSelect
      name="country_id"
      handleChange={handleOnChange}
      values={values}
      error={error}
      label="account.company_country"
      className={className}
      options={countryOptions}
    />
  )
}

export default AddressSelectCountry
