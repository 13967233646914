import { useTranslation } from 'react-i18next'

export const FormNotSupported = () => {
  const { t } = useTranslation()
  return (
    <div className="p-2 m-2">
      <span>{t('forms.version_mismatch')}</span>
    </div>
  )
}

export default FormNotSupported
