import { MdEuro } from 'react-icons/md'

const InputTextGrayEuroIcon: React.FC = () => (
  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
    <span className="text-gray-500 " id="euro-icon">
      <MdEuro />
    </span>
  </div>
)

export default InputTextGrayEuroIcon
