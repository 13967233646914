import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionResponseType } from '../../../types/Forms'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import { useFormField } from '../providers/FormFieldProvider'

export const FormFieldSelect = () => {
  const {
    field: {
      value,
      error,
      input: { options, type },
      disabled,
    },
    setValues,
  } = useFormField()

  if (type !== 'SELECT' || !options) return null

  const selectOptions: InputSearchSelectItemType[] = options.map(
    (option) =>
      ({
        id: option.__uuid || option.uuid,
        value: option.value,
        label: option.display_name,
      }) as InputSearchSelectItemType
  )

  const selectedOption = selectOptions.find(
    (option) => option.id === value?.__uuid || option.id === value?.id || option.id === value?.uuid
  )

  return (
    <SearchSelect
      disabled={disabled}
      error={error}
      onChange={(selected) =>
        setValues({
          __uuid: selected?.id!,
          label: selected?.label!,
          display_name: selected?.label!,
          value: selected?.value!,
        } as FormQuestionResponseType)
      }
      options={selectOptions}
      currentSelection={selectedOption}
      selectType={type}
    />
  )
}

export default FormFieldSelect
