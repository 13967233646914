import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../../shared/components/atoms/buttons/Button'

const BackToBasecampButton = () => {
  const { t } = useTranslation()
  return (
    <Link to="/">
      <Button variant="solid" color="lhotse-orange" className="py-3 px-6">
        {t('utils.back_to_basecamp')}
      </Button>
    </Link>
  )
}

export default BackToBasecampButton
