import { useTranslation } from 'react-i18next'
import CoreLoading from '../../../../core/components/CoreLoading'
import { Dialog, DialogTrigger } from '../../../../shared/components/atoms/Dialog/Dialog'
import Switch from '../../../../shared/components/atoms/buttons/Switch'
import IntegrationsModalContent from './IntegrationsModalContent'
import { IntegrationType, useIntegrationService } from './useIntegrationService'

import cn from '../../../../lib/util'
import Logos from './images/index'

const Integrations = () => {
  const { t } = useTranslation()

  const integrations: IntegrationType[] = [
    {
      name: 'OpenAI',
      party: 'openai',
      image: Logos.OpenAI,
      fields: ['client_secret'],
      description: t('integrations.openai-description', 'OpenAI description'),
    },
    {
      name: 'Jira',
      party: 'jira',
      image: Logos.Jira,
      fields: ['client_id', 'client_secret'],
      description: t('integrations.jira-description', 'Jira description'),
    },
    {
      name: 'Unite',
      party: 'unite',
      image: Logos.Unite,
      fields: ['client_id', 'client_secret'],
      description: t('integrations.unite-description', 'Unite description'),
    },
    {
      name: 'Slack',
      party: 'slack',
      image: Logos.Slack,
      description: t('integrations.slack-description', 'Slack description'),
    },
    {
      name: 'MS Teams',
      party: 'teams',
      image: Logos.MSTeams,
      description: t('integrations.ms-teams-description', 'MS Teams description'),
    },
    {
      name: 'Docu Sign',
      party: 'docu-sign',
      image: Logos.DocuSign,
      description: t('integrations.docu-sign-description', 'Docu Sign description'),
    },
    {
      name: 'Webhook',
      party: 'webhook',
      image: Logos.Webhook,
      description: t('integrations.webhook-description', 'Webhook description'),
    },
    {
      name: 'SAP',
      party: 'sap',
      image: Logos.SAP,
      description: t('integrations.sap-description', 'SAP description'),
    },
    {
      name: 'SAP Ariba',
      party: 'sap-ariba',
      image: Logos.SAPAriba,
      description: t('integrations.sap-ariba-description', 'SAP Ariba description'),
    },
  ]

  const {
    getIntegrations: { data: integrationConfigurations, isLoading },
  } = useIntegrationService()

  if (isLoading) return <CoreLoading />

  // sort integrations by availability first.
  integrations.sort((a, b) => {
    if (integrationConfigurations?.find((config) => config.party === a.party)) return -1
    if (integrationConfigurations?.find((config) => config.party === b.party)) return 1
    return 0
  })

  return (
    <div className="flex w-full flex-col ">
      <div className="flex justify-between items-center space-x-6">
        <div className="flex flex-col space-y-2.5 w-9/12">
          <div className="text-gray-600 font-semibold text-sm">{t('generic.integrations', 'Integrations')}</div>
          <div className="text-gray-500 text-sm font-medium">
            {t('integrations.description', 'Integrations description')}
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6 mt-12">
        {integrations.map((integration) => {
          const configurationAvailable = !!integrationConfigurations?.find(
            (config) => config.party === integration.party
          )

          return (
            <div key={integration.party}>
              <Dialog>
                <DialogTrigger asChild>
                  <button type="button" className="p-4 w-full bg-white flex flex-col text-sm rounded">
                    <div className="flex gap-4 justify-start w-full">
                      <img src={integration.image} alt="openai" className="w-12 h-12" />
                      <div className="flex flex-col w-full text-left">
                        <div className="font-semibold flex gap-4 justify-between">
                          <div>{integration.name}</div>
                          <div>
                            <Switch
                              onChange={() => undefined}
                              checked={
                                (integrationConfigurations?.filter((config) => config.party === integration.party)
                                  ?.length || 0) > 0
                              }
                            />
                          </div>
                        </div>
                        <div className="flex">
                          <div
                            className={cn(
                              'px-2 text-xs  rounded-full py-0.5',
                              configurationAvailable ? 'bg-sky-blue-light text-white' : 'text-gray-800 bg-gray-300'
                            )}
                          >
                            {configurationAvailable
                              ? t('integration.available', 'Available')
                              : t('integration.coming-soon', 'Coming soon')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="font-normal w-full text-left h-14 line-clamp-2 pt-4 text-sm">
                      {integration.description}
                    </span>
                  </button>
                </DialogTrigger>
                {integration.fields && (
                  <IntegrationsModalContent
                    integration={integration}
                    configuration={integrationConfigurations?.filter((config) => config.party === integration.party)}
                  />
                )}
              </Dialog>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Integrations
