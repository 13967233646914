import SearchMultiSelect from '../../../shared/components/atoms/SearchSelect/SearchMultiSelect'
import { FormQuestionResponseType } from '../../../types/Forms'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import { useFormField } from '../providers/FormFieldProvider'

export const FormFieldMultiSelect = () => {
  const {
    field: {
      values,
      input: { options, type },
      disabled,
    },
    setValues,
  } = useFormField()

  const selectOptions: InputSearchSelectItemType[] =
    options?.map(
      (option) =>
        ({
          id: option.__uuid,
          label: option.value,
        }) as InputSearchSelectItemType
    ) || []

  if (type !== 'MULTI_SELECT' || !options) return null

  const selectedOption = values
    ? selectOptions.filter((option) => values.findIndex((i) => i?.__uuid === option.id) > -1)
    : selectOptions.filter((option) => options.find((item) => item.__uuid === option.id && item.is_selected))

  return (
    <SearchMultiSelect
      onChange={(selected) => {
        setValues(
          selected.map((item) => ({
            __uuid: item?.id!,
            label: item?.label!,
            display_name: item?.label!,
            value: item?.label!,
          })) as FormQuestionResponseType[]
        )
      }}
      disabled={disabled}
      options={selectOptions}
      currentSelection={selectedOption}
      selectType={type}
    />
  )
}

export default FormFieldMultiSelect
