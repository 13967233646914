import type { PartialDeep } from 'type-fest'
import { useFormContext } from '../../../../core/providers/FormProvider'
import { FormQuestionSettings, FormQuestionType } from '../../../../types/Forms'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'
import uuid from '../../../../core/utils/uuid'
import { createValueLabel } from '../constants/labels'

const getNewValue = (question: FormQuestionType, index = 1) => ({
  __uuid: uuid(),
  value: '',
  label: createValueLabel(question.question_label || '', index),
})

export default () => {
  const {
    question: [question, setQuestion],
  } = useFormContext()

  return (__settings: PartialDeep<FormQuestionSettings>) => {
    if (!question) return

    const values = [
      ...Array.from(Array(__settings.answers?.min || question.__settings?.answers?.min || 0).keys()).map((index) =>
        getNewValue(question, index + 1)
      ),
    ]

    const updatedQuestion = {
      __settings,
      values,
    }

    setQuestion(getMergedDeepOverwritingArrays(question, updatedQuestion))
  }
}
