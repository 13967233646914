import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import { getUpdatedByUuid, move } from '../../../../../../shared/utils/arrayHelpers'
import uuid from '../../../../../../core/utils/uuid'
import { QuestionItemType } from '../../components/QuestionItemsList/QuestionItem'
import QuestionSection from '../../components/QuestionSection'
import QuestionItemsList from '../../components/QuestionItemsList/QuestionItemsList'
import { FormQuestionType } from '../../../../../../types/Forms'
import { useQuestionAnswersLimit } from '../../../hooks/useQuestionAnswersLimit'
import { getIndexedQuestionValueLabel } from '../../../constants/labels'

const getNewValue = (question: FormQuestionType, index = 1) => ({
  __uuid: uuid(),
  value: '',
  label: getIndexedQuestionValueLabel(question, index),
})

type ValuesInputProps = {
  title: string
  onChange: (item: QuestionItemType) => void
  items: QuestionItemType[]
}

export const ValuesInput = ({ onChange, items, title }: ValuesInputProps) => {
  const {
    question: [question],
    updateQuestion,
  } = useFormContext()
  const { canRemove, canAdd } = useQuestionAnswersLimit(question)
  const { t } = useTranslation()

  if (!question) return null

  const handleDragEnd = (from: number, to: number) => {
    updateQuestion({
      values: move(question.values, from, to),
    })
  }

  const handleLabelCreation = () => {
    updateQuestion({ values: [...(question.values || []), getNewValue(question)] })
  }

  const deleteQuestionChoice = ({ __uuid }: QuestionItemType) => {
    updateQuestion({
      values: question.values?.filter((value) => value.__uuid !== __uuid),
    })
  }

  return (
    <QuestionSection title={title}>
      <QuestionItemsList
        description={t('forms.question.predefined_values_description')}
        title={t('forms.question.predefined_values')}
        items={items}
        onDragEnd={handleDragEnd}
        onCreate={canAdd ? handleLabelCreation : undefined}
        onDelete={canRemove ? deleteQuestionChoice : undefined}
        onChange={onChange}
      />
    </QuestionSection>
  )
}

export default ValuesInput
