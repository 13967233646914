import { useMemo } from 'react'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'
import useGetRequiredActionWorkflowBlocks from './useGetRequiredActionWorkflowBlocks'
import useGetCurrentUser from '../../../../core/hooks/useGetCurrentUser'

export const useUserActiveApprovalWorkflowBlocks = () => {
  const requiredActionWorkflowBlocks = useGetRequiredActionWorkflowBlocks()
  const { data: currentUser } = useGetCurrentUser()

  return useMemo(
    () =>
      requiredActionWorkflowBlocks?.filter((workflowBlock: WorkflowBlock) => {
        const userIsAssignedToWorkflowBlock = workflowBlock?.approvers?.some(
          // @ts-ignore The type of user here is a little bit buggy, user_id does exist on the object, but adding it to the type breaks typing in a bunch of other places.
          (assignedUser) => assignedUser?.user?.user_id === currentUser?.id
        )

        return (
          workflowBlock?.type === 'APPROVAL' &&
          (workflowBlock?.status === 'in_queue' || workflowBlock?.status === 'pending') &&
          userIsAssignedToWorkflowBlock
        )
      }),
    [currentUser, requiredActionWorkflowBlocks]
  )
}

export default useUserActiveApprovalWorkflowBlocks
