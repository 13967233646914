import Table from '../../../../shared/components/atoms/SharedTable/Table'
import useGetExternalDataRelationshipList from '../../hooks/useExternalDataRelationships'
import ExternalDataEmbeddingsTableRow from './ExternalDataEmbeddingsTableRow'

const ExternalDataEmbeddingsTableBody: React.FC = () => {
  const { data: columns } = useGetExternalDataRelationshipList()

  return (
    <Table>
      {columns?.map((column) => (
        <ExternalDataEmbeddingsTableRow key={`external-data-relationship-${column.column_name}-row`} column={column} />
      ))}
    </Table>
  )
}

export default ExternalDataEmbeddingsTableBody
