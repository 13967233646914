import { useFormContext } from '../../../../../../core/providers/FormProvider'
import { FormQuestionContentType } from '../../../../../../types/Forms'
import QuestionSliderSettingRow from '../../components/inputs/QuestionSliderSettingRow'
import QuestionInputNumber from '../../components/inputs/QuestionInputNumber'
import useUpdateQuestionValidation from '../../../hooks/useUpdateQuestionValidation'

const CharacterLimitSetting: React.FC = () => {
  const relevantQuestion: FormQuestionContentType[] = ['TEXT_AREA', 'TEXT']

  const {
    question: [question],
  } = useFormContext()
  const updateValidation = useUpdateQuestionValidation()

  const characterLimit = question?.validation.max_characters
  const isChecked = characterLimit !== undefined

  if (!question?.type || !relevantQuestion.includes(question?.type)) return null

  return (
    <QuestionSliderSettingRow
      title="Character limit for text responses"
      description="The maximum number of characters that can be added to a text response."
      onChange={() =>
        isChecked
          ? updateValidation({ max_characters: undefined })
          : updateValidation({ max_characters: { value: 280 } })
      }
      checked={isChecked}
    >
      <QuestionInputNumber
        placeholder="1 - 1000"
        value={characterLimit?.value}
        precision={0}
        onChange={(e) => updateValidation({ max_characters: { value: parseInt(e.currentTarget.value, 10) } })}
      />
    </QuestionSliderSettingRow>
  )
}

export default CharacterLimitSetting
