import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  return Yup.string().test(
    'len',
    t('error.invalid_phone_number'),
    (val) => (val?.length || 0) < 26 && (val?.length || 0) > 3
  )
}
