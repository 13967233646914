import { useTranslation } from 'react-i18next'
import { useIsFetching } from 'react-query'
import { MdArrowForwardIos } from 'react-icons/md'
import CoreLoadingIcon from '../../../../../../core/components/CoreLoadingIcon'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import useIntakeFormStore from '../../../stores/useIntakeFormStore'
import useIntakeFormSections from '../../../hooks/useIntakeFormSections'

const NextSectionButton = () => {
  const { t } = useTranslation()
  const isQueryClientFetching = useIsFetching()
  const isLoading = isQueryClientFetching > 0

  const activeSectionIndex = useIntakeFormStore((state) => state.activeSectionIndex)
  const setActiveSectionIndex = useIntakeFormStore((state) => state.setActiveSectionIndex)

  const formSections = useIntakeFormSections()

  const nextEnabled = formSections && activeSectionIndex !== formSections.length - 1

  return (
    <Button
      className={`btn-primary ${!nextEnabled ? 'invisible' : ''}`}
      disabled={isLoading || !nextEnabled}
      onClick={() => setActiveSectionIndex(activeSectionIndex + 1)}
    >
      <p className="whitespace-nowrap">{t('forms.next_section')}</p>
      {isLoading ? <CoreLoadingIcon /> : <MdArrowForwardIos />}
    </Button>
  )
}

export default NextSectionButton
