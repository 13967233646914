import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../../shared/components/atoms/buttons/Button'

const RedirectToLoginButton = () => {
  const { t } = useTranslation()
  return (
    <Link to="/login">
      <Button variant="solid" color="primary" className="py-3 px-6">
        {t('login.login')}
      </Button>
    </Link>
  )
}

export default RedirectToLoginButton
