import * as Yup from 'yup'
import validation from '../../../../shared/constants/validationErrors'
import useGetPhoneNumberValidation from '../../../../shared/validationSchema/useGetPhoneNumberValidation'

export default () => {
  const phoneValidation = useGetPhoneNumberValidation()
  return Yup.object().shape({
    first_name: Yup.string().nullable(),
    last_name: Yup.string().nullable(),
    email: Yup.string().email(validation.email).required(validation.required),
    phone_number: phoneValidation,
  })
}
