// Based on https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
// and https://dev.to/murashow/how-to-use-resize-observer-with-react-5ff5
import { Ref, useCallback, useRef, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

interface Size {
  width: number
  height: number
}

export default function useElementSize<T extends HTMLElement = HTMLDivElement>(): [Ref<T>, Size] {
  const target = useRef<T | null>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  const setRoundedSize = ({ width, height }: Size) => {
    setSize({ width: Math.round(width), height: Math.round(height) })
  }

  const setTarget = useCallback((element: T | null) => {
    target.current = element
    target.current && setRoundedSize(target.current.getBoundingClientRect())
  }, [])

  useResizeObserver(target, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0]
    setRoundedSize({ width, height })
  })

  return [setTarget, size]
}
