import { useParams } from 'react-router'
import SupplierContacts from '../components/SupplierContacts'
import SupplierDetails from '../components/SupplierDetails'
import TagsDetailsBody from '../components/TagsDetailsBody'
import { SupplierBodyOptionsType } from '../../../types/SupplierDetails/SupplierDetailsTypes'

const SupplierBody = () => {
  const { screen } = useParams<{ screen: SupplierBodyOptionsType }>()

  if (screen === 'general') {
    return (
      <div className="flex flex-col gap-y-7 py-7 overflow-auto">
        <SupplierDetails />
        <SupplierContacts />
      </div>
    )
  }

  if (screen === 'tags') {
    return <TagsDetailsBody editable />
  }

  return null
}

export default SupplierBody
