import { useTranslation } from 'react-i18next'
import useWorkflowBlockDraftMutations from '../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'
import { useWorkflow } from '../../../providers/WorkflowProvider'
import WorkflowAddButton from '../../misc/WorkflowAddButton'
import WorkflowBuilderBlockTriggerForm from './WorkflowBuilderBlockTriggerForm/WorkflowBuilderBlockTriggerForm'

export const WorkflowBlockTriggers = () => {
  const { t } = useTranslation()
  const { block } = useWorkflowBlockDraft()
  const { addTrigger } = useWorkflowBlockDraftMutations()
  const { readonly } = useWorkflow()

  return (
    <div className="flex flex-col gap-6">
      <div>
        <h2 className="text-left text-black-light font-semibold text-md">{t('workflow.block.triggers', 'Trigger')}</h2>
        <hr className="border-rock-gray-light mt-2" />
      </div>
      <div className="flex flex-col gap-6">
        {block.triggers.map((trigger, index) => (
          <WorkflowBuilderBlockTriggerForm trigger={trigger} key={`trigger-${trigger.uuid}`} />
        ))}
      </div>
      {!readonly && (
        <div className="flex flex-row">
          <WorkflowAddButton variant="ghost" onClick={addTrigger} className="text-sky-blue-light bg-transparent !p-0">
            {t('workflow.block.add_condition')}
          </WorkflowAddButton>
        </div>
      )}
    </div>
  )
}

export default WorkflowBlockTriggers
