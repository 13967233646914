import { Link, useLocation } from 'react-router-dom'

export type TabType = {
  link?: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => any
  disabled?: boolean
  cy?: string
  title: React.ReactNode
  id?: string
  hidden?: boolean
}

interface ITabButton {
  tab: TabType
  currentTab?: string
}
const TabButton: React.FC<ITabButton> = ({ tab, currentTab }) => {
  const activeTabStyles = 'border-b-2 text-sky-blue border-blue-600'
  const { pathname } = useLocation()

  if (tab.onClick && tab.id) {
    return (
      <button
        onClick={tab.onClick}
        type="button"
        className={`p-4 text-sm ${currentTab === tab.id && activeTabStyles} 
        ${tab.hidden && 'hidden'} cursor-pointer block ${tab?.disabled && 'cursor-not-allowed pointer-events-none'}`}
        data-cy={tab?.cy}
      >
        {tab.title}
      </button>
    )
  }

  if (tab.link) {
    const currentTabFromUrl = pathname.includes(tab.link)
    return (
      <Link
        to={tab.link}
        className={`p-4 text-sm flex items-center ${currentTabFromUrl && activeTabStyles} ${
          tab.hidden && 'hidden'
        } cursor-pointer block ${tab?.disabled && 'cursor-not-allowed pointer-events-none'}`}
        data-cy={tab?.cy}
      >
        {tab.title}
      </Link>
    )
  }

  return null
}

export default TabButton
