export const priceQuestionOptions = [
  {
    id: 'EUR',
    label: 'EUR',
    symbol: '€',
  },
  {
    id: 'USD',
    label: 'USD',
    symbol: '$',
  },
  {
    id: 'JPY',
    label: 'JPY',
    symbol: '¥',
  },
  {
    id: 'GBP',
    label: 'GBP',
    symbol: '£',
  },
  {
    id: 'CHF',
    label: 'CHF',
    symbol: 'CHF',
  },
  {
    id: 'CNY',
    label: 'CNY',
    symbol: '¥',
  },
]

export default priceQuestionOptions
