import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'

const ExternalDataEmbeddingsTableHeader: FC = () => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableHeader>{t('external_data.column_name', 'Column name')}</TableHeader>
      <TableHeader>{t('external_data.include_in_embedding', 'Include in Embedding')}</TableHeader>
      <TableHeader>{t('external_data.primary_key', 'Primary key')}</TableHeader>
    </TableHead>
  )
}

export default ExternalDataEmbeddingsTableHeader
