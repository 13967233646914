import { getTenant } from '../../../../../core/utils/tenancy'
import useGetSupplierBranch from '../../../hooks/useGetSupplierBranch'
import useUpdateBranchMutation from '../../../hooks/useUpdateBranchMutation'

export default (supplierBranchId: number) => {
  const { data: branchData } = useGetSupplierBranch()

  const isLhotseSupplier = branchData?.source?.toLowerCase() === 'lhotse'
  const tenantName = getTenant()

  const { mutate: updateBranchMutation } = useUpdateBranchMutation(supplierBranchId)

  return () => {
    if (branchData?.supplier_id) {
      updateBranchMutation({
        body: {
          source: isLhotseSupplier ? tenantName : 'lhotse',
        },
        supplierId: branchData.supplier_id,
      })
    }
  }
}
