import LhotseSpinner from '../assets/lhotse-spinner.gif'
import { useUtils } from '../providers/UtilsProvider'

const CoreLoading = () => {
  const utils = useUtils()

  if (utils?.isLoading?.get) {
    return (
      <div className="absolute z-50 top-0 left-0 w-screen h-screen items-center justify-center bg-indigo-100 bg-opacity-60">
        <div className="flex justify-center items-center h-screen">
          <img src={LhotseSpinner} alt="" width="90px" />
        </div>
      </div>
    )
  }

  return null
}

export default CoreLoading
