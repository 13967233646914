import { useTranslation } from 'react-i18next'
import QuestionSubtitleInput from './QuestionSubtitleInput'
import QuestionTextInput from './QuestionTextInput'
import SearchSelectOptionSelection from './SearchSelectOptionSelection'
import QuestionSection from '../../components/QuestionSection'
import QuestionLabelInput from './QuestionLabelInput'

const TextAndSubtitleInput: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <QuestionSection title={t('forms.question.details')}>
      <QuestionTextInput />
      <QuestionSubtitleInput />
      <QuestionLabelInput />
      <SearchSelectOptionSelection />
      {children}
    </QuestionSection>
  )
}

export default TextAndSubtitleInput
