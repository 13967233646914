import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { IntakeFieldType, IntakeSectionType } from '../../../../../types/Intake'
import useGetPaginatedIntakeOfferings from '../../../IntakeOverview/hooks/useGetPaginatedIntakeOfferings'
import SectionWrapper from '../../../../../shared/components/organisms/SectionWrapper'
import IntakeOfferingSection from '../../../IntakeOverview/components/IntakeSections/IntakeSectionsContent/IntakeOfferingSection/IntakeOfferingSection'
import IntakeSection from '../../../IntakeOverview/components/IntakeSections/IntakeSection'
import AddButton from '../../../../../shared/components/atoms/buttons/AddButton'
import useIntakeFormStore from '../../stores/useIntakeFormStore'
import useOfferingMutations from '../../../IntakeOverview/hooks/useOfferingMutations'
import useApplyFormOfferingsLogic from '../../logic/useApplyFormOfferingsLogic'
import uuid from '../../../../../core/utils/uuid'
import { Offering } from '../../../../../types/Offering'
import UniteSearch from '../../../../Forms/FormResponsePage/sections/ProductSection/UniteSearch/UniteSearch'

const SectionRenderer = ({
  section,
  useSectionWrapper,
}: {
  section: IntakeSectionType
  useSectionWrapper: boolean
}) => {
  const isDraftIntake = useIntakeFormStore((state) => state.intake?.status === 'draft')
  const { data: offeringsResponse } = useGetPaginatedIntakeOfferings(1)

  const storeOfferings = useIntakeFormStore((state) => state.offerings)
  const offeringsWithLogicApplied = useApplyFormOfferingsLogic(storeOfferings)
  const allowAddingProductsOnOverview = useIntakeFormStore(
    (state) => state.intake?.settings?.allowAddingProductsOnOverview
  )

  const { type_name, id, fields } = section
  const { t } = useTranslation()

  const setStoreOfferings = useIntakeFormStore((state) => state.setOfferings)
  const isInLastSection = useIntakeFormStore(
    (state) => state.intake && state.activeSectionIndex === state.intake.sections.length - 1
  )

  // Set the store offerings if it has not been set yet (is still null, or if the length has changed by a new product addition)
  useEffect(() => {
    if (!offeringsResponse?.data) return

    // overwrite everything in store except the offering fields, since the form is responsible for that state
    const newOfferings: Offering[] = offeringsResponse?.data.map((offering) => {
      const existingOffering = storeOfferings?.find((o) => o.uuid === offering.uuid)
      return {
        ...offering,
        fields: existingOffering?.fields || offering.fields,
      }
    })

    setStoreOfferings(newOfferings)
  }, [offeringsResponse?.data])

  const { create } = useOfferingMutations()

  const addNewProduct = () => {
    // Clones the first offering in the list, reset all the field values and uuid
    const firstOffering = offeringsWithLogicApplied?.[0]
    const clonedOffering = JSON.parse(JSON.stringify(firstOffering))

    clonedOffering.fields = clonedOffering.fields.map((field: IntakeFieldType) => {
      const shouldCopyValuesFromParent = field.input.settings?.loop?.hasCopiedValuesFromParent

      const newField = field
      newField.value = {
        ...field.value,
        __uuid: field.value?.__uuid || uuid('form-question-value'),
        value: shouldCopyValuesFromParent && field.value ? field.value?.value : '',
      }
      return newField
    })
    clonedOffering.uuid = undefined
    create.mutate(clonedOffering)
  }

  const isOfferingSection = type_name === 'OFFERINGS'
  const offeringsToDisplay = isDraftIntake ? offeringsWithLogicApplied : storeOfferings

  if (isOfferingSection && offeringsToDisplay && offeringsToDisplay?.length > 0) {
    return (
      <>
        <SectionWrapper title={t('section_type.product', 'Product')} key={`intake-section-${id}`}>
          <IntakeOfferingSection offerings={offeringsToDisplay} />
        </SectionWrapper>
        {(isDraftIntake || allowAddingProductsOnOverview) && !isInLastSection && (
          <div className="flex">
            <AddButton onClick={addNewProduct} label={t('products.add_product', 'Add product')} />
            <UniteSearch
              onAdd={(data) => {
                console.log(data)
              }}
            />
          </div>
        )}
      </>
    )
  }

  if (fields.length > 0) {
    return <IntakeSection key={`intake-section-${id}`} section={section} useWrapper={useSectionWrapper} />
  }

  return null
}

export default SectionRenderer
