import { useQuery, UseQueryOptions } from 'react-query'
import { getActions } from '../../services'
import { WorkflowAction } from '../../../../types/Workflow/WorkflowAction'

export const useGetWorkflowActions = (
  provider?: string,
  options?: Omit<UseQueryOptions<WorkflowAction[]>, 'queryKey' | 'queryFn'>
) => useQuery(['workflow-actions', provider], () => getActions(provider), options)

export default useGetWorkflowActions
