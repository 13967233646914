import { useTranslation } from 'react-i18next'
import { FormSectionType, FormQuestionType } from '../../../../../../../types/Forms'
import useGetQuestionIndex from '../../../../../hooks/useGetQuestionIndex'
import AddLogicConditionButton from './AddLogicConditionButton'
import FormLogicConditionInput from './FormLogicConditionInput/FormLogicConditionInput'
import CopyQuestionSetDefaultCheckbox from './FormLoopCopyValuesCheckbox/FormLoopCopyValuesCheckbox'

interface IFormLogicQuestion {
  question: FormQuestionType
  section: FormSectionType
}

const FormLogicQuestion: React.FC<IFormLogicQuestion> = ({ question, section }) => {
  const { t } = useTranslation()

  const questionIndex = useGetQuestionIndex(question)
  const isLoopTrigger = question.__loop?.is_loop_trigger
  const isProductSection = section.type === 'PRODUCT' && !isLoopTrigger

  return (
    <div
      className={`flex gap-x-4 w-full text-secondary-gray mb-8 ${
        isLoopTrigger && 'border border-vibrant-blue p-4 rounded-lg'
      }`}
    >
      <p className="text-xs font-medium pt-1.5">{`${questionIndex}.`}</p>
      <div className="flex flex-col w-full gap-y-4">
        <div className="flex justify-between">
          <div className="flex flex-col gap-y-1">
            <p className="text-primary-gray font-semibold">{question.label || 'Undefined'}</p>
            <p className="text-sm">{t(`forms.${question.type.toLowerCase()}_question`)}</p>
            <p className="text-xs text-secondary-gray">{question.subtitle}</p>
          </div>
          {isProductSection && <CopyQuestionSetDefaultCheckbox question={question} section={section} />}
          {isLoopTrigger && (
            <p className="text-xs text-vibrant-blue font-medium uppercase">{t('forms.loop_question')}</p>
          )}
        </div>
        <AddLogicConditionButton question={question} section={section} />
        <FormLogicConditionInput question={question} section={section} />
      </div>
    </div>
  )
}

export default FormLogicQuestion
