import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * A utility function that merges class names and tailwind classes.
 * https://www.youtube.com/watch?v=re2JFITR7TI
 * @param inputs
 */
export default function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
