import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import CompanyAddresses from './addresses/CompanyAddresses'
import CompanyImageUpload from './Image/ImageUpload'
import useIsCompanyScreenLoading from './documents/hooks/useIsCompanyScreenLoading'

const CompanyGeneralTab: React.FC = () => {
  const isLoading = useIsCompanyScreenLoading()

  if (isLoading) return <CoreLoadingIcon />
  return (
    <div className="flex flex-col gap-8 w-full h-full items-center">
      <CompanyImageUpload />
      <CompanyAddresses />
    </div>
  )
}

export default CompanyGeneralTab
