import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItemType } from './Menu'

interface IMenuItem {
  item: MenuItemType
  index: number
  menuItemsLength: number
}

const MenuItem: React.FC<IMenuItem> = ({ item, index, menuItemsLength }) => {
  const { t } = useTranslation()

  const isFirstItem = !index
  const isLastItem = menuItemsLength - 1 === index

  const handleClicked = (e: SyntheticEvent) => {
    e.stopPropagation()
    item.onClick(e)
  }

  return (
    <button
      type="button"
      key={`${item.name}-menu-item`}
      onClick={handleClicked}
      className={`
        flex w-full whitespace-nowrap 
        transition ease-in-out duration-100 text-sm p-4 py-3 
        ${isFirstItem && 'rounded-t-md'}
        ${isLastItem && 'rounded-b-md'}
        ${item.disabled ? 'text-slate-400' : 'hover:bg-blue-500 hover:text-white'}
      `}
      data-cy={item.dataCy || 'menu-item'}
      disabled={item.disabled}
    >
      {t(item.name)}
    </button>
  )
}

export default MenuItem
