import { useQuery } from 'react-query'
import settingsQueryKeys from '../../modules/Settings/Views/Profile/constants/settingsQueryKeys'
import { apiGetUser } from '../../modules/Settings/Views/Profile/services/SettingsService'
import { getToken } from '../utils/apiClient'

export const useGetCurrentUser = () => {
  const token = getToken()
  return useQuery([settingsQueryKeys.USER_PROFILE], apiGetUser, {
    enabled: !!token,
  })
}

export default useGetCurrentUser
