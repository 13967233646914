import useGetCurrentUser from '../../../../core/hooks/useGetCurrentUser'
import useUrlParams from '../../../LhotseHome/hooks/useUrlParams'
import { ApiSearchType, QueryFilterType } from '../../SearchTypes'
import useDebounceSearchRequest from '../useDebounceSearchRequest'
import { RequestSearchType } from '../../Types/SearchEntitiesTypes'

/**
 * @Deprecated, only use in LhotseHomeDashboard, a new global will be created soon
 */
export default function useSearchSubmissions(dataFilters?: QueryFilterType) {
  const { getParam } = useUrlParams()

  const { data: currentUser } = useGetCurrentUser()
  const getFilters = dataFilters?.filter_by ?? getParam('filter_by')
  const isRequestor = currentUser?.role === 'requestor'
  const isApproverFilterPresent = getFilters?.includes('approvers.approver_id')
  const isApproverIdMatchingCurrentUser =
    isApproverFilterPresent && getFilters.split(':=')[1] === currentUser?.id.toString()

  // Construct the search string based on the user role and the filters
  let searchString = getFilters ?? '' // Default to whatever the filters are

  // If the user is a requestor, modify the search string accordingly
  if (isRequestor) {
    // If the approver filter is present and matches the current user's ID, keep the filters as-is
    // Otherwise, append or set the author_email filter
    searchString = isApproverIdMatchingCurrentUser
      ? searchString
      : `author_email:=${currentUser?.email}${searchString ? ` && ${searchString}` : ''}`
  }

  const page = getParam('page') ? Number(getParam('page')) : undefined

  const fullData: ApiSearchType = dataFilters
    ? {
        entity: 'intakes',
        queryFilters: {
          ...dataFilters,
          filter_by: searchString || undefined,
          page: page || undefined,
        },
      }
    : {
        entity: 'intakes',
        queryFilters: {
          query: getParam('query'),
          filter_by: searchString || undefined,
          facet_by: undefined,
          per_page: Number(getParam('per_page')) || undefined,
          page: page || undefined,
        },
      }

  return useDebounceSearchRequest<RequestSearchType>({
    key: ['search-submissions', JSON.stringify(fullData.queryFilters)],
    query: fullData,
  })
}
