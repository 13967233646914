import { Dialog } from '@headlessui/react'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import Body from './Body'
import cn from '../../../../../lib/util'
import OverlayBody from './OverlayBody'

const SharedModal: React.FC = () => {
  const {
    modal: {
      get: { isOpen, keepOpenOnBackdrop, baseClassName, isOverlay },
      close,
    },
  } = useUtils()

  const onClose = () => {
    if (keepOpenOnBackdrop) return
    close()
  }

  return (
    <Dialog
      className={cn('fixed flex justify-center items-start z-50 inset-0 overflow-y-auto mx-auto', baseClassName)}
      open={isOpen}
      onClose={onClose}
      data-cy="shared-modal"
      id="shared-modal"
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity overflow-hidden" />
      {!isOverlay && <Body />}
      {isOverlay && <OverlayBody />}
    </Dialog>
  )
}

export default SharedModal
