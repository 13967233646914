import { useTranslation } from 'react-i18next'
import Switch from '../../../../../../shared/components/atoms/buttons/Switch'
import useUpdateForm from '../../../hooks/useUpdateForm'
import useGetFormData from '../../../../hooks/useGetFormData'

const TagSectionIsDefaultSwitch = () => {
  const { t } = useTranslation()
  const { data } = useGetFormData()

  const { updateForm } = useUpdateForm()
  const handleChange = () => {
    updateForm({ isDefault: !data?.isDefault })
  }

  return (
    <div className="gap-x-4 flex">
      <Switch onChange={() => handleChange()} checked={data?.isDefault || false} />
      <p className="text-primary-gray text-sm">{t('forms.tag_section.is_default')}</p>
    </div>
  )
}
export default TagSectionIsDefaultSwitch
