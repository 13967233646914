import { useTranslation } from 'react-i18next'
import { isBasicInput } from '../helpers/InputTemplateHelpers'
import { InputTemplate } from '../../../types/Forms'

export const useInputTemplateName = () => {
  const { t } = useTranslation()

  return (input: InputTemplate) => {
    const { name, translate_display_name, type } = input

    if (translate_display_name) return t(translate_display_name)

    if (isBasicInput(input)) return t(`forms.${type.toLowerCase()}_question`)

    return name || t(`forms.${type.toLowerCase()}_question`) // t('generic.unnamed_input')
  }
}

export default useInputTemplateName
