import useGetCurrentWorkflow from '../../../hooks/useGetCurrentWorkflow'
import IntakeSidePanelActiveDeclinedMessage from './IntakeSidePanelActiveDeclinedMessage'
import IntakeSidePanelActiveStepItem from './IntakeSidePanelActiveStepItem'
import useGetBlocksToApprove from '../../../hooks/useGetBlocksToApprove'
import IntakeSidePanelActiveStepRestart from './IntakeSidePanelActiveStepRestart'

const IntakeSidePanelActiveStep = () => {
  const { data } = useGetCurrentWorkflow()
  const blocksToApprove = useGetBlocksToApprove()

  return (
    <div className="w-full p-6 pt-2">
      <div className="flex flex-col space-y-4 pt-6 border-t bg-white ">
        {blocksToApprove?.map((block) => <IntakeSidePanelActiveStepItem key={block.uuid} block={block} />)}
        {data?.status === 'declined' && <IntakeSidePanelActiveDeclinedMessage />}
      </div>
      <IntakeSidePanelActiveStepRestart />
    </div>
  )
}

export default IntakeSidePanelActiveStep
