import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import { HTMLAttributes } from 'react'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'

export interface ISearchSelectOption {
  option: InputSearchSelectItemType
  className?: HTMLAttributes<HTMLDivElement>['className']
  visibleColumns?: { [key: string]: any } // Column headers from an external data source, if applicable
  showOnlyVisibleData?: boolean // If true, only show option data that are in visible columns
}

const SearchSelectOption: React.FC<ISearchSelectOption> = ({
  option,
  className,
  visibleColumns,
  showOnlyVisibleData,
}) => (
  <Combobox.Option
    as="div"
    key={option.id}
    value={option}
    className={({ active, selected }) =>
      classNames(
        'relative cursor-pointer select-none py-2 pl-3 pr-2',
        {
          'bg-ice-blue': active || selected,
          'text-sky-blue': active,
          'text-sky-blue-light': selected,
          'font-semibold': selected,
        },
        className
      )
    }
  >
    {option.dataFields ? (
      <div className={classNames('flex truncate gap-3 items-center')}>
        {Object.keys(option.dataFields)
          .filter((key) => !showOnlyVisibleData || !visibleColumns || Object.keys(visibleColumns).includes(key))
          .map((key) => (
            <div key={key} className="flex flex-col flex-1 overflow-hidden" title={option.dataFields?.[key]}>
              <span className="text-sm capitalize overflow-ellipsis overflow-hidden">{option.dataFields?.[key]}</span>
            </div>
          ))}
      </div>
    ) : (
      <div className={classNames('flex truncate gap-1 items-center')}>
        {option.icon}
        {option.label}
      </div>
    )}
  </Combobox.Option>
)

export default SearchSelectOption
