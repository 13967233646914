import { useTranslation } from 'react-i18next'
import useListDataEntityColumns from '../../hooks/useGetExternalDataColumnsList'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import { ExternalDataColumn } from '../../../../types/ExternalDataManager/ExternalDataManagerTypes'
import SearchSelect, { ISearchSelect } from '../../../../shared/components/atoms/SearchSelect/SearchSelect'

type ExternalDataSourceColumnSelectProps = {
  sourceUuid: string | undefined
  selected: string | undefined
  onSelect: (column: string) => void
  label?: string
} & Omit<ISearchSelect, 'options' | 'onChange' | 'placeholder' | 'currentSelection'>

const ExternalDataSourceColumnSelect: React.FC<ExternalDataSourceColumnSelectProps> = ({
  onSelect,
  sourceUuid,
  selected,
  label,
  ...selectorProps
}) => {
  const { t } = useTranslation()
  const { data: dataEntityColumns } = useListDataEntityColumns(sourceUuid)

  const options: InputSearchSelectItemType[] =
    dataEntityColumns?.map((entity: ExternalDataColumn) => ({
      id: entity.column_name,
      label: entity.column_name,
    })) || []

  const selectedColumn = options.find((option) => option.id === selected)

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="reference_dataset">{label || t('inputs.column', 'Column')}</label>
      <SearchSelect
        {...selectorProps}
        onChange={(item) => onSelect(item.label)}
        options={options}
        placeholder={t('inputs.select_column', 'Select column to populate select input options from')}
        currentSelection={selectedColumn}
      />
    </div>
  )
}

export default ExternalDataSourceColumnSelect
