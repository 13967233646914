import { useEffect, useRef, useState } from 'react'

export type StateCallback<T> = (currentState: T) => Promise<void> | void

export const useStateWithCallback = <T = any>(
  initialState?: T
): [T | undefined, (state: T, callback?: StateCallback<T>) => void] => {
  const [state, setState] = useState<T>(initialState as T)

  const callbacksRef = useRef<StateCallback<T>[]>([])

  const setStateCallback = (state: T, callback?: StateCallback<T>) => {
    if (callback) callbacksRef.current.push(callback)
    setState(state)
  }

  useEffect(() => {
    if (callbacksRef.current.length > 0) {
      callbacksRef.current.forEach((callback) => callback(state))
      callbacksRef.current = []
    }
  }, [state])

  return [state, setStateCallback]
}

export default useStateWithCallback
