import { RemoveUploadedFileFunctionType } from '../../layout/SharedFileUploadPill/SharedFileUploadPillTypes'
import { ITipTapEditor } from './TipTapEditor'
import TipTapEditorFileList from './TipTapEditorFileList'

interface ITipTapEditorFilesOnTop extends Pick<ITipTapEditor, 'files'> {
  filesOnTop?: boolean
  messageReply?: boolean
  handleDeleteFile?: RemoveUploadedFileFunctionType
  isSending?: boolean
}

const TipTapEditorFilesOnTop: React.FC<ITipTapEditorFilesOnTop> = ({
  files,
  filesOnTop,
  messageReply,
  handleDeleteFile,
  isSending,
}) =>
  !!files?.length && filesOnTop ? (
    <div className={`absolute ${messageReply ? 'bottom-[195px]' : 'bottom-[110px]'}`} data-cy="tiptap-files-on-top">
      <TipTapEditorFileList
        files={files}
        handleDeleteFile={handleDeleteFile}
        isSending={isSending}
        filesOnTop={filesOnTop}
      />
    </div>
  ) : null

export default TipTapEditorFilesOnTop
