// @ts-nocheck
// TODO: Fix typescript errors

import toast, { Toast as ReactToast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import classnames from 'classnames'
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import { Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { IconComponent } from '../../../../types/shared/Styles'

export type ToastTheme = 'info' | 'success' | 'warning' | 'error'

const ToastThemes: Record<
  ToastTheme,
  {
    border: HTMLElement['className']
    icon: IconComponent
    iconStyle: HTMLElement['className']
    text: HTMLElement['className']
  }
> = {
  info: {
    border: 'border-t-blue-100',
    icon: InformationCircleIcon,
    iconStyle: 'text-blue-100',
    text: 'black-light',
  },
  warning: {
    border: 'border-t-sun-yellow',
    icon: CheckCircleIcon,
    iconStyle: 'text-sun-yellow',
    text: 'black-light',
  },
  success: {
    border: 'border-t-green-light',
    icon: ExclamationTriangleIcon,
    iconStyle: 'text-green-light',
    text: 'black-light',
  },
  error: {
    border: 'border-t-red-light',
    icon: ExclamationTriangleIcon,
    iconStyle: 'text-red-light',
    text: 'black-light',
  },
}

export type ToastProps = {
  theme: ToastTheme
  translationString?: string
  id: any
  toastElement: ReactToast
  additionalTextValue?: string
}

export const Toast: React.FC<ToastProps> = ({ theme, translationString, id, additionalTextValue }) => {
  const { t } = useTranslation()

  const { icon: Icon, ...styles } = ToastThemes[theme]

  return (
    <Transition
      as={Fragment}
      appear
      show
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="h-full opacity-100 w-full"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classnames(
          ' max-w-md w-full bg-white border-t-4 border drop-shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5',
          styles.border
        )}
        data-cy={`toastr-${translationString}`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-center min-h-[2rem]">
            <Icon className={classnames('text-xl h-6 w-6', styles.iconStyle)} />
            <div className="ml-3 flex-1">
              <p className={classnames('text-sm font-medium', styles.text)} data-cy="toastr-text">
                {`${translationString ? t(translationString) : t('error.oops_generic')} ${additionalTextValue || ''}`}
              </p>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={() => toast.remove(id || translationString)}
          className={classnames('items-center flex justify-center bg-transparent p-4 z-50', styles.text)}
        >
          <MdClose />
        </button>
      </div>
    </Transition>
  )
}
export default Toast
