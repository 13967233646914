import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pick } from 'lodash'
import { Offer } from '../../../../../../../../../../types/Offering'
import { InputSearchSelectItemType } from '../../../../../../../../../../types/shared/InputSearchSelectTypes'
import SupplierBranchDataType from '../../../../../../../../../../types/Suppliers/SupplierBranchDataType'
import SearchSelect, {
  ISearchSelect,
} from '../../../../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { useUtils } from '../../../../../../../../../../core/providers/UtilsProvider'
import SupplierNewModal from '../../../../../../../../../Suppliers/components/SupplierNewModal'
import SupplierUserType from '../../../../../../../../../../types/Suppliers/SupplierUserType'
import OfferSupplierUserDetails from './OfferSupplierUserDetails'
import generateUserName from '../../../../../../../../../../shared/utils/generateUserName'
import useSearch from '../../../../../../../../../Search/Hooks/useSearch'
import { HitsType } from '../../../../../../../../../Search/SearchTypes'
import { SupplierUserSearchType } from '../../../../../../../../../Search/Types/SearchEntitiesTypes'

export type SupplierOption = Pick<Offer, 'supplier_branch_id' | 'supplier_user_id'> & {
  __uuid: string
  value: string // supplier name for backward compatibility
}

type SupplierSearchSelectOption = Omit<InputSearchSelectItemType, 'dataFields'> & {
  dataFields: {
    supplierName: string
    sap: string
    userName: string
    email: string
    userId: number
    supplierId: number
  }
}

type SupplierFieldProps = {
  selected: { supplier_branch_id?: number; supplier_user_id?: number } | undefined
  onAdd: (supplier: SupplierBranchDataType) => void
  onSelect: (value: SupplierOption) => void
} & Omit<ISearchSelect, 'onChange' | 'options' | 'currentSelection' | 'onAdd'>

const OfferSupplierField: React.FC<SupplierFieldProps> = ({ selected, onAdd, onSelect, ...searchSelectProps }) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedSupplierUser, setSelectedSupplierUser] = useState<
    { supplier_branch_id?: number; supplier_branch_user_id?: number } | undefined
  >(selected)

  // update selected supplier user when selected prop changes
  useEffect(() => {
    setSelectedSupplierUser(selected)
  }, [selected?.supplier_branch_id])

  const { data: searchedSuppliers, isLoading: isSearching } = useSearch<SupplierUserSearchType>({
    entity: 'supplier-users',
    queryFilters: {
      query: searchQuery,
      per_page: 50,
    },
  })

  const openNewSupplierModal = () => {
    modal.set({
      title: t('suppliers.add_supplier'),
      content: <SupplierNewModal onFinish={onAdd} />,
      isLoading: false,
      isOpen: true,
    })
  }

  const onChange = (value: SupplierSearchSelectOption) => {
    if (!value) return
    setSelectedSupplierUser({
      supplier_branch_id: value.dataFields.supplierId,
      supplier_branch_user_id: value.dataFields.userId,
    })
    onSelect({
      __uuid: value.id as string,
      value: value.dataFields.supplierName,
      supplier_branch_id: value.dataFields.supplierId,
      supplier_user_id: value.dataFields.userId,
    })
  }

  const suppliers: SupplierUserSearchType[] | undefined = searchedSuppliers?.data.hits.map(
    (hit: HitsType<SupplierUserSearchType>) => hit.document
  )

  const options: SupplierSearchSelectOption[] =
    suppliers?.map((supplierUser: SupplierUserSearchType) => {
      const dataFields = {
        supplierName: supplierUser.supplier.name,
        sap: supplierUser.supplier?.id ?? '',
        userName:
          generateUserName({
            firstName: supplierUser.first_name,
            lastName: supplierUser.last_name,
            email: supplierUser.email,
          }) ?? '-',
        email: supplierUser.email || 'No Email',
        userId: Number(supplierUser.id),
        supplierId: Number(supplierUser.supplier?.id),
      }

      return {
        id: `${supplierUser.supplier.id}-${supplierUser.id}`,
        label: Object.values(pick(dataFields, ['supplierName', 'userName']))
          .filter((v) => !!v)
          .join(', '),
        dataFields,
      }
    }) || []

  const selectedUser: SupplierUserSearchType | undefined = suppliers?.find(
    (user: SupplierUserSearchType) => Number(user.id) === selected?.supplier_user_id
  )

  const supplierDetails = {
    supplierName: t('offer.supplier_name,', 'Supplier name'),
    userName: t('offer.user_name', 'User name'),
    email: t('offer.email', 'Email'),
  }

  const activeValue = selected
    ? options.find(
        ({ dataFields: { userId, supplierId } }) =>
          supplierId === selected.supplier_branch_id && userId === selected.supplier_user_id
      )
    : undefined

  const selectedItem = (): SupplierUserType | undefined => {
    const selectedUserNew = suppliers?.find(
      (user: SupplierUserSearchType) => Number(user.id) === selectedSupplierUser?.supplier_branch_user_id
    )
    return selectedUserNew
      ? {
          ...selectedUserNew,
          supplier_branch: {
            ...selectedUserNew.supplier,
            supplier_id: Number(selectedUserNew.supplier.id),
            address: undefined,
            created_at: selectedUserNew.supplier.created_at.toString(),
            updated_at: selectedUserNew.supplier.updated_at.toString(),
            id: Number(selectedUserNew.supplier.id),
          },
          created_at: selectedUserNew.created_at ?? '',
          id: Number(selectedUserNew.id),
          email: selectedUserNew.email ?? '',
          first_name: selectedUserNew.first_name ?? '',
          last_name: selectedUserNew.last_name ?? '',
          is_new: false,
          name: selectedUserNew.name ?? '',
          phone_number: selectedUserNew.phone_number ?? '',
          salutation: selectedUserNew.salutation ?? '',
        }
      : undefined
  }

  return (
    <div className="flex flex-col gap-2">
      <SearchSelect
        onChange={onChange as (value: InputSearchSelectItemType) => void}
        isLoading={isSearching || (isSearching && (!searchedSuppliers || searchedSuppliers.data.length === 0))}
        options={options}
        addButtonText={t('question.new_supplier')}
        onAdd={openNewSupplierModal}
        onInputChange={(value) => {
          setSearchQuery(value || '')
        }}
        currentSelection={activeValue}
        placeholder={t('question.enter_supplier')}
        visibleColumns={supplierDetails}
        showOnlyVisibleData
        {...searchSelectProps}
      />
      <OfferSupplierUserDetails supplierContactPerson={selectedItem()} />
    </div>
  )
}

export default OfferSupplierField
