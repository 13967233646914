import { isNil, merge, omitBy } from 'lodash'
// @ts-ignore
import { ref, Reference } from 'yup'
import { IntakeFieldType } from '../../../../types/Intake'
import { FormQuestionContentType, FormQuestionValidationProperty } from '../../../../types/Forms'

export const getType = (question: IntakeFieldType): 'array' | 'object' | 'string' | 'number' => {
  // possible types: string | number | boolean | MediaType | FormQuestionDateValue | AddressQuestionValue | CompanyUserType
  const questionWithArrayAsValue: FormQuestionContentType[] = ['FILE']
  const questionWithObjectAsValue: FormQuestionContentType[] = ['ADDRESS', 'USER']

  if (questionWithArrayAsValue.includes(question.input.type)) {
    return 'array'
  }
  if (questionWithObjectAsValue.includes(question.input.type)) {
    return 'object'
  }
  if (question.input.type === 'NUMBER') return 'number'

  return 'string'
}

const hasArrayOfValues = (question: IntakeFieldType) => getType(question) === 'array'

export const getRequired = (question: IntakeFieldType) => question.validation?.required?.value

export const getRefOrValue = <T>(property?: FormQuestionValidationProperty<T>) =>
  property?.target ? ref(`$${property.target}`) : property?.value

export const getMinValues = (question: IntakeFieldType): number | undefined | Reference =>
  question.validation?.min_values?.value || (question.validation?.required?.value ? 1 : 0)
export const getMaxValues = (question: IntakeFieldType): number | undefined | Reference =>
  question.validation?.max_values?.value

export const getMin = (question: IntakeFieldType): number | undefined | Reference =>
  getRefOrValue(question.validation?.min)
export const getMax = (question: IntakeFieldType): number | undefined | Reference =>
  getRefOrValue(question.validation?.max)

export const getWholeQuestionValidationSchema = (question: IntakeFieldType) =>
  omitBy(
    {
      min: getMinValues(question),
      max: getMaxValues(question),
      total: getRefOrValue(question.validation?.total),
    },
    isNil
  )

export const getSingleInputFieldValidationSchema = (question: IntakeFieldType) =>
  omitBy(
    {
      required: getRequired(question),
      nullable: question.validation?.required?.value === false,
      customMin: getMin(question),
      customMax: getMax(question),
      min: question.validation?.min_characters?.value,
      max: question.validation?.max_characters?.value,
      exact: getRefOrValue(question.validation?.exact),
    },
    isNil
  )

export const getQuestionValidationValue = (question: IntakeFieldType) => {
  if (question.input.type === 'FILE') {
    if (question.media?.length === 0) return undefined
    return question.media
  }

  if (question.value === null || question.value === undefined) return undefined

  return question.value.value
}

export const getQuestionValueSchema = (question: IntakeFieldType) => ({
  // @ts-ignore TODO: fix type
  [question.uuid]: getQuestionValidationValue(question),
})

export const getQuestionValues = (questions: IntakeFieldType[]) => merge({}, ...questions.map(getQuestionValueSchema))
