import de from 'date-fns/locale/de'
import { registerLocale } from 'react-datepicker'

export default () => {
  registerLocale('de', {
    ...de,
    options: {
      ...de.options,
      weekStartsOn: 1,
    },
  })
}
