import { useHistory, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { HiCircleStack } from 'react-icons/hi2'
import PageTopbar from '../../../../shared/components/layout/PageTopBar'
import BackButton from '../../../../shared/components/atoms/navigation/BackButton'
import useGetInputTypes from '../../../Forms/hooks/useGetInputTypes'
import { InputSearchParams } from '../../../IntakeConfiguration/services/CompanyInputsService'
import {
  EditFieldModal,
  FieldItemIcons,
  FieldList,
  FieldItem,
  StaticFields,
  DataSchemaActions,
} from './Components/SchemaDetailComponents'
import Menu from '../../../../shared/components/organisms/menus/Menu'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { CustomDataSchemaFieldType, useSchemaServiceUpdateMutation, useSchemaServiceView } from './useSchemaService'
import { toastr } from '../../../../shared/components/organisms/toastr'

const SchemaDetailView = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [filters, setFilters] = useState<InputSearchParams>({})
  const [selectedInputs, setSelectedInputs] = useState<CustomDataSchemaFieldType[]>([])
  const { data: inputs, isLoading } = useGetInputTypes(filters)
  const { data: SchemaServiceView } = useSchemaServiceView(uuid)
  const updateSchema = useSchemaServiceUpdateMutation(uuid)
  const history = useHistory()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const { modal } = useUtils()
  const { t } = useTranslation()
  const convertedInputs: CustomDataSchemaFieldType[] | undefined = inputs?.map((input) => ({
    field_uuid: undefined,
    input_uuid: input.uuid!!,
    name: input.name,
    type: input.type,
    value: null,
    label: input.name,
    placeholder: input.placeholder ?? '',
    required: false,
  }))

  useEffect(() => {
    if (SchemaServiceView?.data.custom_fields) {
      setSelectedInputs(SchemaServiceView?.data.custom_fields)
    }
  }, [SchemaServiceView])

  const reorder = (list: CustomDataSchemaFieldType[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }
    setIsDirty(true)
    const reOrderSelectedInput = reorder(selectedInputs, result.source.index, result.destination.index)
    setSelectedInputs(reOrderSelectedInput)
  }

  const handleDelete = (item: CustomDataSchemaFieldType): void => {
    setIsDirty(true)
    setSelectedInputs(selectedInputs.filter((input) => input.input_uuid !== item.input_uuid))
    modal.close()
  }

  const handleEdit = (item: CustomDataSchemaFieldType): void => {
    setIsDirty(true)
    setSelectedInputs(selectedInputs.map((input) => (input.input_uuid === item.input_uuid ? item : input)))
  }

  const rightButton = (item: CustomDataSchemaFieldType) => {
    const menuItems = [
      {
        name: 'generic.edit',
        onClick: () =>
          modal.set({
            isOpen: true,
            title: <h2 className="text-black font-bold">{t('generic.edit_field', 'Edit field')}</h2>,
            content: <EditFieldModal field={item} onSave={handleEdit} />,
            baseClassName: 'items-center w-[600px]',
          }),
        dataCy: 'edit-input',
      },
      {
        name: 'generic.delete',
        onClick: () => handleDelete(item),
        dataCy: 'remove-input',
      },
    ]
    return <Menu menuItems={menuItems} buttonId="address-context-menu" buttonCy="address-context-menu" />
  }

  const handleAddingInput = (field: CustomDataSchemaFieldType): void => {
    setIsDirty(true)
    setSelectedInputs([...selectedInputs, field])
  }

  const handleSave = (): void => {
    updateSchema
      .mutateAsync({
        custom_fields: selectedInputs,
      })
      .then(() => {
        setIsDirty(false)
        toastr.success(t('generic.success', 'Success'))
      })
      .catch(() => {
        toastr.error(t('generic.error_message', 'Something went wrong, please try again later.'))
      })
  }

  return (
    <div className="w-full h-screen overflow-hidden">
      <PageTopbar
        titleComponent={
          <div className="flex flex-col">
            <BackButton onBack={() => history.push('/settings/schemas')} name="All schemas" />
            <span className="text-2xl flex items-center font-bold text-black-light" data-cy="req-topbar-title">
              <h2 className="first-letter:capitalize">{SchemaServiceView?.data.name}</h2>
            </span>
            <span className="flex mt-2 items-center">
              <HiCircleStack className="text-2xl mr-1 h-4 text-gray-400" />
              <span className="text-sm text-gray-400 first-letter:capitalize">{SchemaServiceView?.data.entity}</span>
            </span>
          </div>
        }
        actions={
          <div className="flex items-center">
            <button
              disabled={!isDirty || updateSchema.isLoading}
              onClick={handleSave}
              type="button"
              className="py-2 px-5 bg-sky-blue rounded text-white disabled:hover:brightness-100
        disabled:!bg-gray-200 disabled:!text-gray-400"
            >
              {t('generic.save', 'Save')}
            </button>
            {!isLoading && SchemaServiceView && (
              <div className="ml-2">
                <DataSchemaActions name={SchemaServiceView?.data.name} uuid={SchemaServiceView?.data.uuid} />
              </div>
            )}
          </div>
        }
      />
      <div className="px-32 pt-12 grid grid-cols-6 w-full gap-1 divide-x divide-gray-100 h-screen">
        <div className="col-span-4 flex flex-col pr-20 overflow-y-scroll max-h-[calc(85vh-10px)] mb-10">
          <div className="space-y-6">
            {SchemaServiceView?.data.custom_fields && (
              <StaticFields fields={SchemaServiceView?.data.default_fields ?? []} />
            )}
            <div className="space-y-6">
              <div className="flex flex-col select-none">
                <h3 className="text-black-light font-bold text-lg">{t('schema.custom_fields', 'Custom fields')}</h3>
                <p className="text-black-light-2 text-sm">
                  {t('schema.custom_fields_description', 'Fields added by you')}
                </p>
              </div>
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div className="space-y-2" {...provided.droppableProps} ref={provided.innerRef}>
                        {selectedInputs &&
                          selectedInputs?.map((inputItem: CustomDataSchemaFieldType, index) => (
                            <Draggable draggableId={inputItem.input_uuid!} key={inputItem.input_uuid} index={index}>
                              {(provided) => (
                                <FieldItem
                                  leftBtn={() => FieldItemIcons.drag}
                                  rightBtn={rightButton}
                                  innerRef={provided.innerRef}
                                  classNames="bg-gray-200 hover:bg-gray-200"
                                  item={inputItem}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                />
                              )}
                            </Draggable>
                          ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
        <FieldList
          inputs={convertedInputs ?? []}
          selectedInputs={selectedInputs}
          isLoading={isLoading}
          onInputChange={(value) => setFilters(value)}
          onAddInput={handleAddingInput}
        />
      </div>
    </div>
  )
}
export default SchemaDetailView
