import { IntakeFieldType } from '../../../../../../types/Intake'
import generateLogicTreeMermaidMarkup from './generateLogicTreeMermaidMarkup'

type LogicGroupCheckpoints = number[][]

export default (questions: IntakeFieldType[]) => {
  const generateTree = () => {
    let logicGroups: LogicGroupCheckpoints = []

    const getQuestionIndex = (uuid: string, index: number = 0) =>
      questions.slice(index).findIndex((q) => q.external_reference?.startsWith(uuid)) + index

    const removeObsoleteLogicGroups = (lastIndex: number, currentIndex: number) => {
      if (lastIndex <= currentIndex) {
        logicGroups = [...logicGroups.slice(1)]
        removeObsoleteLogicGroups(logicGroups[0]?.slice(-1)[0], currentIndex)
      }
      return logicGroups
    }

    const addNewLogicGroups = (q: IntakeFieldType, index: number) => {
      const questionLogic = q.logic

      if (questionLogic && questionLogic?.switch?.cases?.length) {
        return [
          [
            ...questionLogic.switch.cases.map((c) => getQuestionIndex(c.target, index)),
            getQuestionIndex(questionLogic.switch.default || '', index),
          ],
          ...logicGroups,
        ]
      }
      return logicGroups
    }

    // Could check if next waypoint is within next waypoint of current logic (logic to go prevent going into a one way)
    const nextSaveIndex = (logicGroupData: LogicGroupCheckpoints, index: number) =>
      logicGroupData.map((lg) => lg.filter((v) => v > index))

    const nextVisibleQuestion = (nextWaypoint: number[][], index: number) => {
      /*
                Issues:
                * Does not check, if there is a waypoint after the current logic question that is not part of the logic group
                  Logic Group -> UNRELATED_GROUP -> First Logic Question
              */
      const questionLogic = questions[index].logic

      // No Logic Group
      if (!nextWaypoint?.length) return [index + 1]

      // Logic Question
      if (questionLogic && questionLogic.switch?.cases.length) {
        return [
          ...(questionLogic?.switch?.cases.map((c) => getQuestionIndex(c.target, index)) || []),
          getQuestionIndex(questionLogic?.switch?.default || '', index),
        ]
      }

      // Question with natural next step in the array of next waypoints
      if (
        nextWaypoint.flat().sort()[0] &&
        nextWaypoint.flat().sort()[0] > index + 1 &&
        !questionLogic?.switch?.cases.length
      ) {
        return [index + 1]
      }

      if (nextWaypoint[0]?.length === 1) return [nextWaypoint[0][0]]
      return nextWaypoint.flat().sort().slice(-1)
    }

    const generateTreeData = (q: IntakeFieldType, index: number) => {
      logicGroups = removeObsoleteLogicGroups(logicGroups[0]?.slice(-1)[0], index)
      logicGroups = addNewLogicGroups(q, index)

      const nextWaypoints = nextSaveIndex(logicGroups, index)
      const nextQuestion = nextVisibleQuestion(nextWaypoints, index)

      return {
        question: q,
        hasLogic: !!q.logic?.switch?.cases?.length,
        // logic: q.__logic?.switch?.cases,
        logicGroups: [...logicGroups],
        // nextWaypoints,
        nextQuestion,
      }
    }

    return questions.map(generateTreeData)
  }

  const mermaidMarkup = () => generateLogicTreeMermaidMarkup(generateTree())
  return { generateTree, mermaidMarkup }
}
