import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useReactFlow, useStore, Edge } from 'reactflow'
import { WorkflowChartNode } from '../../../types/Workflow/WorkflowChart'
import { calculateLayout } from '../../Intake/IntakeOverview/helpers'
import { useWorkflowChart } from '../providers/WorkflowChartProvider'
import { reveal } from '../helpers/WorkflowChartNodesDisplay'

export const WorkflowChartLayout = ({
  updateNodes,
  updateEdges,
}: {
  updateNodes: Dispatch<SetStateAction<WorkflowChartNode[]>>
  updateEdges: Dispatch<SetStateAction<Edge[]>>
}) => {
  const nodes = useStore((state) => state.getNodes() as WorkflowChartNode[])
  const edges = useStore((state) => state.edges)
  const { fitView } = useReactFlow()
  const [isViewFitted, setIsViewFitted] = useState(false)
  const { readonly } = useWorkflowChart()
  const [isInitialFitDone, setInitialFitDone] = useState(false)

  const [nodesHasChanged, setNodesHasChanged] = useState(false)
  // repositioningRequest is a number instead of boolean
  // to prevent issues when nodes change in the same frame as (already requested) repositioning is finished
  const [repositioningRequest, setRepositioningRequest] = useState(0)

  useEffect(() => {
    const allNodesHaveDimensions = nodes.every(({ position, width, height }) => !!height && !!width)

    const anyNodePositionIsNotSet = nodes.some(({ position }) => position.x === 0 && position.y === 0)

    if (anyNodePositionIsNotSet && !nodesHasChanged) {
      setNodesHasChanged(true)
    }

    if (nodesHasChanged && allNodesHaveDimensions) {
      setRepositioningRequest(repositioningRequest + 1)
    }
  }, [nodes])

  useEffect(() => {
    if (repositioningRequest === 0) return

    setIsViewFitted(false)
    setNodesHasChanged(false)

    const repositionedNodes = calculateLayout(nodes, {
      columnsGap: readonly ? 50 : 100,
    })

    updateNodes(reveal(repositionedNodes))
    updateEdges(reveal(edges))
  }, [repositioningRequest])

  // double buffering of fitView assures that all nodes will be taken into account during fitting
  // - executes fit in next render after it was requested and nodes were rendered -
  useEffect(() => {
    if (!isViewFitted) {
      setIsViewFitted(true)
    }
  }, [nodes])

  useEffect(() => {
    if (isInitialFitDone || nodesHasChanged) return

    if (isViewFitted) {
      fitView()
      setInitialFitDone(true)
    }
  }, [isViewFitted])

  return null
}

export default WorkflowChartLayout
