type StringToHslColorType = {
  name: string
  saturation?: number
  lightness?: number
}
/**
 * Will generate a hex color based on the string passed in
 * @param name
 * @param saturation
 * @param lightness
 */
const stringToHslColor = ({ name, saturation = 60, lightness = 80 }: StringToHslColorType): string => {
  let hash = 0
  for (let i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + Math.imul(hash, 31)
  }

  const h = hash % 360
  return `hsl(${h}, ${saturation}%, ${lightness}%)`
}

export default stringToHslColor
