import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import SidepanelTabTransition from '../../../../../shared/components/layout/SidepanelTabTransition'
import { FormFieldDisplayGenericComponent } from '../../../../Forms-v2/FormFieldDisplay/FormFieldDisplayGeneric'
import useGetCurrentIntake from '../../hooks/useGetCurrentIntake'
import useGetPaginatedIntakeOfferings from '../../hooks/useGetPaginatedIntakeOfferings'
import useGetRequiredActionWorkflowBlocks from '../../hooks/useGetRequiredActionWorkflowBlocks'
import useUserActiveApprovalWorkflowBlocks from '../../hooks/useUserActiveApprovalWorkflowBlocks'
import { useIntakeFlow } from '../../providers/IntakeFlowProvider'
import useFirstUserActiveApprovalWorkflowBlock from '../../hooks/useFirstUserActiveApprovalWorkflowBlock'
import IntakeFlowchartApprovers from '../IntakeFlowchart/components/IntakeFlowchartNodes/IntakeFlowchartNodeContent/IntakeFlowchartApprovers'

const IntakeSidepanelSummary = () => {
  const { t } = useTranslation()

  const { data: intake } = useGetCurrentIntake()
  const { data: offerings } = useGetPaginatedIntakeOfferings(1)

  // Get all inputs used in the triggers
  const extractSummaryItemsFromWorkflowSteps = () => {
    const intakeFields =
      intake?.sections
        ?.flatMap((section) => section.fields)
        .filter((field) => field.input.settings?.intakeOverview?.showInSummary) || []

    const triggerFieldsFromOfferings = sortBy(
      offerings?.data
        ?.map((fields) => fields?.fields?.filter((input) => input.input.settings?.intakeOverview?.showInSummary))
        ?.flat() || [],
      ['input.key']
    )

    return [...intakeFields, ...triggerFieldsFromOfferings]
  }

  const summaryItems = extractSummaryItemsFromWorkflowSteps()

  // Check if the current user is assigned to an active approval workflow block
  const requiredActionWorkflowBlocks = useGetRequiredActionWorkflowBlocks()
  const userActiveApprovalWorkflowBlocks = useUserActiveApprovalWorkflowBlocks()

  // Get all data relevant to the details section
  const { selectedBlock } = useIntakeFlow()
  const userActiveApprovalWorkflowBlock = useFirstUserActiveApprovalWorkflowBlock()
  const detailsBlock = selectedBlock || userActiveApprovalWorkflowBlock
  const firstTrigger = detailsBlock?.triggers?.[0]

  return (
    <SidepanelTabTransition>
      <div className="h-full flex flex-col justify-between">
        <div>
          <div className="border-b border-b-rock-gray-light pb-5">
            {summaryItems?.map((item, index) => (
              <div key={`summaryItem-${item.input.uuid || ''}_${item.id}}`} className="flex flex-row pb-4 text-sm">
                <div className="basis-1/3 mr-2 text-black-light-2">
                  {item.label !== summaryItems[index - 1]?.label ? item.label : null}
                </div>
                <div className="basis-2/3 flex flex-row text-black-light-1 justify-between text-left whitespace-pre-wrap">
                  <FormFieldDisplayGenericComponent
                    type={item.input.type}
                    value={item.value}
                    id={item.uuid || item.id + index}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col space-y-4 my-8">
            {detailsBlock?.approvers && (
              <div className="flex flex-row text-sm">
                <div className="basis-1/3 mr-2 text-black-light-2">{t('workflow.approvers', 'Approvers')}</div>
                <div className="basis-2/3 flex flex-row text-black-light-1 justify-between text-left whitespace-pre-wrap">
                  <IntakeFlowchartApprovers block={detailsBlock} />
                </div>
              </div>
            )}
            <div className="flex flex-row text-sm">
              <div className="basis-1/3 mr-2 text-black-light-2">{t('workflow.block.deadlines', 'Deadline')}</div>
              <div className="basis-2/3 flex flex-row text-black-light-1 justify-between text-left whitespace-pre-wrap">
                {detailsBlock?.reminder}
              </div>
            </div>
            {detailsBlock?.triggers && detailsBlock.triggers.length > 0 && (
              <div className="flex flex-row text-sm">
                <div className="basis-1/3 mr-2 text-black-light-2">{t('workflow.block.triggers', 'Trigger')}</div>
                <div className="basis-2/3 flex flex-row text-black-light-1 justify-between text-left whitespace-pre-wrap">
                  {firstTrigger?.name}
                  {detailsBlock.triggers.length > 1 ? ` +${detailsBlock.triggers.length - 1}` : null}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col space-y-8">
          <div className="flex flex-col">
            {userActiveApprovalWorkflowBlocks && userActiveApprovalWorkflowBlocks.length > 0 && (
              <div className="mb-2">
                <div className="text-black-light-2 text-sm pb-2">
                  {t('intake.sidepanel.summary.actionsRequired', '1 out of {{ numActions }} actions to complete', {
                    numActions: requiredActionWorkflowBlocks?.length,
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </SidepanelTabTransition>
  )
}

export default IntakeSidepanelSummary
