import classnames from 'classnames'
import { ColorStyle } from '../../../../types/shared/Styles'

type RoundIconWrapperProps = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> & {
  color?: ColorStyle
}
export const RoundIconWrapper = ({ children, className, color, ...divProps }: RoundIconWrapperProps) => (
  <div className={classnames('flex items-center rounded-full p-2', color && `bg-${color}`, className)} {...divProps}>
    {children}
  </div>
)

export default RoundIconWrapper
