import { useTranslation } from 'react-i18next'
import BaseInputWrapper from '../../../../../shared/components/atoms/Input/BaseInputWrapper'
import SearchSelect from '../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { WorkflowBlockActionType } from '../../../../../types/Workflow/WorkflowBlock'
import { InputSearchSelectItemType } from '../../../../../types/shared/InputSearchSelectTypes'
import { useWorkflow } from '../../../providers/WorkflowProvider'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'

export const WorkflowBuilderSidePanelTypeField = () => {
  const { t } = useTranslation()

  const { readonly } = useWorkflow()
  const { block, updateBlock } = useWorkflowBlockDraft()

  const typeOptions: { label: string; id: WorkflowBlockActionType }[] = [
    { label: t('workflow.block.type_approval', 'Approval'), id: 'APPROVAL' },
    { label: t('workflow.block.type_action', 'Action'), id: 'ACTION' },
  ]

  const handleUpdate = ({ id: type }: InputSearchSelectItemType) => {
    updateBlock({ type: type as WorkflowBlockActionType })
  }

  return (
    <BaseInputWrapper label={t('workflow.block.type_label', 'Block type')}>
      <SearchSelect
        options={typeOptions}
        onChange={handleUpdate}
        currentSelection={typeOptions.find(
          (it) => it.id === block.type || (it.id === 'APPROVAL' && block.type === 'APPROVER')
        )}
        disabled={readonly}
      />
    </BaseInputWrapper>
  )
}

export default WorkflowBuilderSidePanelTypeField
