import { useEffect } from 'react'
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom'
import CoreAuthenticating from '../../../core/components/CoreAuthenticating'
import useUpdateLocalAuth from '../hooks/useUpdateLocalAuth'

const AuthenticationRoute = () => {
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const { token } = useParams<{ token?: string }>()
  const updateLocalAuth = useUpdateLocalAuth()

  useEffect(() => {
    const prepFrontend = async () => {
      if (token) {
        await updateLocalAuth(token)
        window.location.href = query.get('redirect') || '/'
      } else {
        history.push('/login')
      }
    }

    prepFrontend()
  }, [token])

  return <CoreAuthenticating />
}

export default withRouter(AuthenticationRoute)
