import { useCallback, useState } from 'react'
import { BackendEntity } from '../../types/shared/Api'

export const useSelectableState = <T extends string | number | BackendEntity>(
  initialState?: T | null,
  selectableValues?: T[]
) => {
  const [selected, setSelected] = useState<T | null>(initialState ?? null)

  const select = useCallback(
    (selectedValueOrUuid: T | string | null) => {
      if (
        !selectedValueOrUuid ||
        !selectableValues ||
        selectableValues.length === 0 ||
        typeof selectableValues[0] === 'string' ||
        typeof selectableValues[0] === 'number'
      ) {
        setSelected(selectedValueOrUuid as T)
        return
      }

      if (typeof selectedValueOrUuid === 'string') {
        setSelected(
          ((selectableValues as BackendEntity[]).find(
            ({ uuid, id, reference_key }) =>
              uuid === selectedValueOrUuid || selectedValueOrUuid === id || selectedValueOrUuid === reference_key
          ) as T) ?? null
        )
      } else {
        setSelected(selectedValueOrUuid)
      }
    },
    [selectableValues]
  )

  const deselect = useCallback(() => setSelected(null), [])

  return [selected, select, deselect] as const
}

export default useSelectableState
