import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../core/providers/FormProvider'
import useNavigate from '../../../../shared/hooks/useNavigate'
import useGetFormData from '../../hooks/useGetFormData'
import useGetFormParams from '../hooks/useGetFormParams'

const FormNavigation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    question: [question],
  } = useFormContext()
  const { data: form } = useGetFormData()
  const { formId } = useGetFormParams()

  return (
    <div className="flex gap-x-1 text-secondary-gray pt-3 w-full h-fit px-7 text-xs bg-background">
      <button
        type="button"
        onClick={(e) => {
          navigate('/settings/forms', e)
        }}
        className="underline"
      >
        {t('generic.forms_plural')}
      </button>
      <p>/</p>
      <button type="button" className="underline" onClick={(e) => navigate(`/forms/${formId}/content`, e)}>
        {form?.name}
      </button>
      {question?.type && (
        <>
          <p>/</p>
          <p className="text-primary-gray">{t(`forms.${question?.type.toLowerCase()}_question`)}</p>
        </>
      )}
    </div>
  )
}

export default FormNavigation
