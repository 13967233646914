import useGetCurrentWorkflow from './useGetCurrentWorkflow'

export const useGetBlocksToApprove = () => {
  const { data } = useGetCurrentWorkflow()

  if (data?.status !== 'ongoing') return null

  return data?.active_blocks.filter((b) => b.current_user_can_approve)
}

export default useGetBlocksToApprove
