import { FC } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { t } from 'i18next'
import SuppliersResults from './SuppliersResults'
import Input from '../../../../shared/components/atoms/Input/Input'
import useUrlParams from '../../../LhotseHome/hooks/useUrlParams'

const SuppliersTable: FC = () => {
  const { setParam, getParam } = useUrlParams()

  return (
    <div className="w-full flex flex-col justify-center gap-y-2">
      <Input
        placeholder={t('generic.search', 'Search')}
        defaultValue={getParam('query') ?? ''}
        className="pl-8"
        onChange={(e) => setParam({ query: e.currentTarget.value ?? '' })}
        lead={
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 mr-10">
            <MagnifyingGlassIcon className="w-4 opacity-70" />
          </div>
        }
      />
      <SuppliersResults />
    </div>
  )
}

export default SuppliersTable
