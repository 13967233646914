import { MdReply } from 'react-icons/md'
import { useMessengerContext } from '../../../../../../core/providers/MessengerProvider'
import { MessageType } from '../../../../../../types/Messenger/types'

interface IReplyButton {
  message: MessageType
}

const ReplyButton: React.FC<IReplyButton> = ({ message }) => {
  const {
    messageProperties: [, setMessageProperties],
  } = useMessengerContext()

  const updateMessageReply = () => {
    setMessageProperties((messageProperties) => ({
      ...messageProperties,
      messageReply: {
        referenceId: message.uuid,
        referenceText: message.text_message,
        referenceName: message.user.full_name,
      },
    }))
  }

  return (
    <button
      className="rounded-full text-xs p-1 text-opacity-50"
      type="button"
      onClick={updateMessageReply}
      data-cy="messageReplyButton"
    >
      <MdReply />
    </button>
  )
}

export default ReplyButton
