import CoreLoading from '../../../core/components/CoreLoading'
import BackToSupplierSearchBanner from './BackToSupplierSearchBanner'
import SupplierBody from './SupplierBody'
import SupplierTabs from '../components/SupplierTabs'
import SupplierTitle from '../components/SupplierTitle'
import useIsSupplierDetailsLoading from '../hooks/useIsSupplierDetailsLoading'

const SupplierDetailsPage = () => {
  const isLoading = useIsSupplierDetailsLoading()

  if (isLoading)
    return (
      <div>
        <CoreLoading />
      </div>
    )

  return (
    <div className="flex flex-col w-full">
      <BackToSupplierSearchBanner />
      <SupplierTitle editable />
      <SupplierTabs />
      <SupplierBody />
    </div>
  )
}

export default SupplierDetailsPage
