const cleanStringFormMermaid = (string: string) =>
  string?.replaceAll('"', "'").replaceAll('(', '_').replaceAll(')', '_')

export default (tree: any) => {
  let mermaidMarkup = '\ngraph TD\n'
  try {
    tree.forEach((t: any, index: number) => {
      t.nextQuestion.forEach((tq: any) => {
        mermaidMarkup += `${index}${t.question.logic ? '{' : '('}${cleanStringFormMermaid(t.question.label)}${
          t.question.logic ? '}' : ')'
        }-->${tq}\n`
      })
      if (t.question.logic?.switch?.cases?.flatMap((c: any) => c.additionalConditions).length) {
        t.question.logic.switch.cases
          .flatMap((c: any) => c.additionalConditions)
          .forEach((ac: any) => {
            if (!ac?.source) return
            const sourceIndex = tree.findIndex((q: any) => q.question.external_reference === ac.source)
            mermaidMarkup += `${sourceIndex}-.->${index}\n`
          })
      }
    })
  } catch (e) {
    console.log('Mermaid did not work', e)
  }

  return mermaidMarkup
}
