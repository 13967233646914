import { CompanyAddressType } from '../../../types/Company/AddressTypes'

type GoogleAddressType = {
  long_name: string
  short_name: string
  types: string[]
}

const fillAddress = (
  address_components: {
    long_name: string
    short_name: string
    types: string[]
  }[]
) => {
  const address = {
    zip: '',
    city: '',
    country_code: '',
    state: '',
    street: '',
  }

  let streetNumber = ''

  address_components.forEach((addressElement: GoogleAddressType) => {
    if (addressElement.types.includes('postal_code')) {
      address.zip = addressElement.long_name
    } else if (addressElement.types.includes('country')) {
      address.country_code = addressElement.short_name
    } else if (
      addressElement.types.includes('administrative_area_level_2') ||
      addressElement.types.includes('administrative_area_level_1')
    ) {
      address.city = addressElement.long_name
    } else if (addressElement.types.includes('administrative_area_level_3')) {
      address.state = addressElement.long_name
    } else if (addressElement.types.includes('route')) {
      address.street = addressElement.long_name
    } else if (addressElement.types.includes('street_number')) {
      streetNumber = addressElement.long_name
    }
  })

  if (streetNumber && address.street) {
    address.street += ` ${streetNumber}`
  }

  return Object.fromEntries(
    Object.entries(address).filter(([key, value]) => key !== '' && value !== '')
  ) as unknown as CompanyAddressType
}

export default fillAddress
