import { useQuery } from 'react-query'
import { TransmissionType } from '../../../../types/Transmissions/Transmission'
import { Query } from '../../../../types/shared/Api'
import { getTranmsissions } from '../services/transmissionService'

export type TransmissionQuery = Query<TransmissionType[]>

export const useGetTransmissions = (
  entity: string,
  entityId: string,
  pollingActive: boolean = false
): TransmissionQuery =>
  useQuery(['transmission', entity, entityId], () => getTranmsissions(entity, entityId), {
    refetchInterval: pollingActive && 5000,
  })

export default useGetTransmissions
