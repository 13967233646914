import TipTapReadOnly from '../../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'
import CloseButton from './CloseButton'
import MessageAuthor from './MessageAuthor'

interface IReplyPreview {
  inChat?: boolean
  author: string
  message: string
}

const ReplyPreview: React.FC<IReplyPreview> = ({ inChat, author, message }) => (
  <div
    className="relative bg-rock-gray bg-opacity-20 flex w-full rounded-md p-2 mb-2 border-l-4 border-sky-blue-light"
    data-cy="reply-preview"
  >
    <div className="text-xs flex justify-between w-full items-center">
      <div className="flex flex-col">
        <MessageAuthor author={author} />
        <TipTapReadOnly text={message} className="text-sm line-clamp-2 pr-10" />
      </div>
      <CloseButton inChat={inChat} />
    </div>
  </div>
)

export default ReplyPreview
