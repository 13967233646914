import { useMutation, useQueryClient } from 'react-query'
import apiClient from '../../../core/utils/apiClient'
import { toastr } from '../../../shared/components/organisms/toastr'

const endpoints: { [key: string]: (schemaUuidOrEntity?: string) => string } = {
  generateEmbeddingsPOST: (schemaUuid) => `/external-data-manager/entity/${schemaUuid}/generate-embeddings`,
}

const useGenerateEmbeddings = (schemaUuid: string) => {
  const queryClient = useQueryClient()
  return useMutation(() => apiClient.post(endpoints.generateEmbeddingsPOST(schemaUuid)), {
    onSuccess: () => {
      !!queryClient.invalidateQueries('entities')
      toastr.success('external-data-manager-generate-embeddings-success')
    },
  })
}

export default useGenerateEmbeddings
