import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import * as Yup from 'yup'
import RowCell from '../../../shared/components/layout/RowCell'
import SaveCancelFormButtons from '../../../shared/components/organisms/formik/SaveCancelFormButtons'
import { toastr } from '../../../shared/components/organisms/toastr'
import AddressFormTextInput from '../../Settings/Views/General/addresses/AddressForm/AddressFormTextInput'
import useGetSupplierBranchId from '../hooks/useGetSupplierBranchId'
import SuppliersQueryKeys from '../../Suppliers/constants/SuppliersQueryKeys'
import { apiGetSupplierBranch } from '../../Suppliers/services/SupplierBranchesService'
import useUpdateBranchMutation from '../hooks/useUpdateBranchMutation'

interface ISupplierInformationForm {
  hideForm(): void
}

const SupplierInformationForm: React.FC<ISupplierInformationForm> = ({ hideForm }) => {
  const { t } = useTranslation()
  const supplierBranchId = useGetSupplierBranchId()

  const { data: branchData } = useQuery([SuppliersQueryKeys.SUPPLIER_BRANCH, supplierBranchId], () =>
    apiGetSupplierBranch(supplierBranchId)
  )

  const updateBranchMutation = useUpdateBranchMutation(supplierBranchId as number)

  const validationSchema = Yup.object().shape({
    customer_supplier_id: Yup.string().nullable(),
    duns_number: Yup.string().matches(/^[\d]{9}$/, t('supplier_details.duns_regex_error')),
  })

  const initialValues = {
    customer_supplier_id: branchData?.customer_supplier_id || '',
    duns_number: branchData?.duns_number || undefined,
  }

  const handlePostSupplierBranch = (formValues: { customer_supplier_id?: string; duns_number?: number }) => {
    const tempValues = { ...formValues }
    delete tempValues.customer_supplier_id

    if (branchData?.supplier_id) {
      updateBranchMutation.mutate(
        {
          body: {
            id: branchData?.id || 0,
            ...formValues,
          },
          supplierId: branchData?.supplier_id,
        },
        {
          onSuccess: () => {
            hideForm()
            toastr.success('success.item_updated', 'branch_update')
          },
        }
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handlePostSupplierBranch}
      validateOnBlur={false}
    >
      {({ touched, values, handleChange, handleSubmit, resetForm, errors }) => {
        const constants = {
          values,
          errors,
          handleChange,
          touched,
        }
        return (
          <Form className="flex flex-col w-full">
            <div>
              <div className="flex w-full">
                <RowCell width="w-1/2 pb-0">
                  <AddressFormTextInput
                    constants={constants}
                    placeholder="-"
                    label="suppliers.customer_supplier_id"
                    errorKey="customer_supplier_id"
                    className="w-full"
                  />
                </RowCell>
                <RowCell width="w-1/2 pb-0 pr-0">
                  <AddressFormTextInput
                    constants={constants}
                    placeholder="-"
                    label="suppliers.duns_number"
                    errorKey="duns_number"
                    className="w-full"
                    dataCy="duns-number"
                  />
                </RowCell>
              </div>
            </div>
            <SaveCancelFormButtons
              onCancel={() => {
                resetForm()
                hideForm()
              }}
              onSave={handleSubmit}
              className="pt-6"
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default SupplierInformationForm
