import { Dispatch, SetStateAction } from 'react'
import toastr from '../../../organisms/toastr/toastr'
import { TagCategoryType } from '../type'
import usePostNewSupplierTag from './usePostNewSupplierTag'

export default (inputValue: string, setInputValue: Dispatch<SetStateAction<string>>, category: TagCategoryType) => {
  const handlePostTag = usePostNewSupplierTag(inputValue, setInputValue, category)

  return (event: React.KeyboardEvent<HTMLInputElement>) => {
    const MAX_TAG_LENGTH = 21
    const MIN_TAG_LENGTH = 1

    if (event.key === 'Enter') {
      if (inputValue.length > MIN_TAG_LENGTH && inputValue.length < MAX_TAG_LENGTH) {
        handlePostTag()
      } else {
        toastr.error('supplier_details.tag_between_2')
      }
    }
  }
}
