import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { debounce } from 'lodash'
import InputTextGray, { IInputTextGray } from './InputTextGray/InputTextGray'

type ISearchInput = {
  debounce?: number
} & IInputTextGray

const SearchInput: React.FC<ISearchInput> = ({
  onChange,
  dataCy,
  debounce: delay,
  fullWidth = true,
  ...inputProps
}) => {
  const { t } = useTranslation()

  const handleOnChange = useCallback(debounce(onChange || (() => {}), delay), [onChange])

  return (
    <InputTextGray
      placeholder={t('generic.search')}
      fullWidth={fullWidth}
      search
      onChange={onChange && handleOnChange}
      dataCy={dataCy || 'search-input'}
      useInternalState
      {...inputProps}
    />
  )
}

export default SearchInput
