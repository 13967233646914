import { useHistory } from 'react-router'
import { useCallback } from 'react'

export const useGoToWorkflowList = () => {
  const history = useHistory()

  return useCallback(() => {
    history.push('/settings/workflows')
  }, [history])
}

export default useGoToWorkflowList
