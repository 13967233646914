import { CompanyUserType } from '../../types/shared/RequisitionDataTypes'
import SupplierUserType from '../../types/Suppliers/SupplierUserType'
import { UserType } from '../../types/shared/AssignmentTypes'
import { WorkflowUserType } from '../../types/shared/UserType'
import { SupplierUserSearchType } from '../../modules/Search/Types/SearchEntitiesTypes'

const generateUserName = (
  user?: Partial<(SupplierUserType | CompanyUserType | UserType | SupplierUserSearchType) & WorkflowUserType>,
  defaultValue?: string
) => {
  if (!user) {
    return ''
  }

  if (user.first_name) {
    return `${user.first_name} ${user.last_name ?? ''}`
  }

  if (user?.firstName) {
    return `${user.firstName} ${user.lastName ?? ''}`
  }

  return user.fullName || user.full_name || user?.email || defaultValue || ''
}

export default generateUserName
