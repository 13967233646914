import { passwordStrength } from 'check-password-strength'

export default (password: string) => {
  const { id, contains } = passwordStrength(password)

  const tooShort = password.length < 8
  const notEnoughDiversity = contains.length < 3

  return {
    id,
    contains,
    canSubmit: !tooShort && !notEnoughDiversity,
  }
}
