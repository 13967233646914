import { FC, HTMLAttributes } from 'react'
import { TabbedNavItemType } from '../../../../types/shared/TabbedNavItemType'
import TabbedNavItem from './TabbedNavItem'
import cn from '../../../../lib/util'

export interface ITabbedNav {
  items: TabbedNavItemType[]
  onTabChange: (tab: number) => void
  itemClassName?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
}

const TabbedNav: FC<ITabbedNav> = ({ itemClassName, items, onTabChange, className }) => (
  <nav
    className={cn('-mb-px flex justify-center w-full border-b-rock-gray-light border-2 border-transparent', className)}
    aria-label="Tabs"
  >
    {items.map((item) => (
      <TabbedNavItem key={item.title} item={item} className={itemClassName} onTabChange={onTabChange} />
    ))}
  </nav>
)

export default TabbedNav
