import { BoltIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import WorkflowBlockEntry from '../../../misc/WorkflowBlockEntry'
import { WorkflowBlock } from '../../../../../../types/Workflow/WorkflowBlock'

export const WorkflowBlockActionsCounter = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()

  return (
    <WorkflowBlockEntry
      className="w-1/2"
      Icon={BoltIcon}
      title={t('intake.flowchart.approvalNodeContent.actions_counter', 'Actions')}
    >
      {block.actions?.length}{' '}
      {t('intake.flowchart.actionNodeContent.action', { count: block.actions?.length || 0, defaultValue: 'actions' })}
    </WorkflowBlockEntry>
  )
}

export default WorkflowBlockActionsCounter
