import { useTranslation } from 'react-i18next'
import { TrashIcon } from '@heroicons/react/24/outline'
import uuid from 'react-uuid'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import WorkflowActionForm from './WorkflowActionForm'
import WorkflowAddButton from '../../misc/WorkflowAddButton'
import { useWorkflow } from '../../../providers/WorkflowProvider'
import { WorkflowActionWrapped } from '../../../../../types/Workflow/WorkflowAction'
import { useWorkflowBlockMutations } from '../../../hooks/mutations'

const WorkflowActionList = () => {
  const { t } = useTranslation()
  const { block } = useWorkflowBlockDraft()
  const { modal } = useUtils()
  const { readonly, workflow } = useWorkflow()
  const { removeAction } = useWorkflowBlockMutations(workflow, block)

  const openActionModal = (action?: WorkflowActionWrapped) => {
    const content = <WorkflowActionForm block={block} workflow={workflow} action={action} readonly={readonly} />

    modal.set({
      isOpen: true,
      title: t('workflow.action_create_modal', 'Create an action'),
      content,
    })
  }

  const handleDelete = (wrappedAction: WorkflowActionWrapped) => {
    removeAction(wrappedAction)
  }

  return (
    <div className="flex flex-col w-full">
      {!readonly && (
        <div className="mb-4">
          <WorkflowAddButton
            variant="ghost"
            onClick={() => openActionModal()}
            className="text-sky-blue-light hover:text-sky-blue transition-colors bg-transparent !p-0"
          >
            {t('workflow.block.create_action', 'Create an action')}
          </WorkflowAddButton>
        </div>
      )}
      {block.actions?.map((wrappedAction) => (
        <div
          key={uuid()}
          role="button"
          tabIndex={0}
          onClick={() => openActionModal(wrappedAction)}
          className="flex w-full border border-rock-gray-light hover:shadow-md
          transition-shadow shadow-sm py-2.5 px-5 rounded-md mb-4 cursor-pointer"
        >
          <div className="text-sm font-medium">{wrappedAction.action?.name || wrappedAction.name}</div>
          {!readonly && (
            <div className="flex ml-auto">
              <TrashIcon
                className="w-5 h-5 my-auto text-rock-gray
              hover:text-red-light transition-colors cursor-pointer"
                onClick={(event) => {
                  event.stopPropagation()
                  handleDelete(wrappedAction)
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default WorkflowActionList
