import { useTranslation } from 'react-i18next'
import { getTenant } from '../../../../../core/utils/tenancy'
import SupplierDetailsSourceButton from './SupplierDetailsSourceButton'

const SupplierDetailsSource: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex gap-x-2 items-center w-full justify-start text-sm text-primary-gray">
      <p className="font-medium">{`${t('requisition_suppliers.list_source')}:`}</p>
      <SupplierDetailsSourceButton option="Lhotse" />
      <SupplierDetailsSourceButton option={getTenant()} />
    </div>
  )
}

export default SupplierDetailsSource
