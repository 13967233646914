import { useTranslation } from 'react-i18next'
import SupplierIds from './SupplierIds'
import SupplierSource from './SupplierDetailsSource/SupplierSource'

interface ISupplierInformation {
  editable?: boolean
}

const SupplierInformation: React.FC<ISupplierInformation> = ({ editable }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-4">
      <p className="font-semibold">{t('supplier.information')}</p>
      <div className="flex justify-between">
        <div className="gap-y-2 flex flex-col w-full">
          <SupplierIds editable={editable} />
          <SupplierSource editable={editable} />
        </div>
      </div>
    </div>
  )
}

export default SupplierInformation
