import { apiDelete, apiGet, apiGetData, apiPost, apiPut } from '../../../core/utils/api/generic'
import {
  WorkflowAction,
  WorkflowActionAttribute,
  WorkflowActionProvider,
  WorkflowActionWrapped,
} from '../../../types/Workflow/WorkflowAction'

export const getActions = (provider?: string) =>
  apiGetData<WorkflowAction[]>('/workflow/actions', { 'actionProvider.name': provider })

export const getActionProviders = () => apiGet<WorkflowActionProvider[]>('/workflow/actions/providers')

const generateAttributeUpdateDiff = (attribute: WorkflowActionAttribute) => ({
  id: attribute.id,
  value: attribute.value,
})

export type ActionBlockRequestIdentifiers = {
  workflowUuid: string
  workflowVersion: number
  workflowStepUuid: string
  workflowBlockUuid: string
}

export const assignActionToBlock = (
  { workflowUuid, workflowVersion, workflowStepUuid, workflowBlockUuid }: ActionBlockRequestIdentifiers,
  action: WorkflowAction
) =>
  apiPost(
    `/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks/${workflowBlockUuid}/workflow-actions`,
    {
      action_uuid: action.uuid,
      action_attributes: action.attributes.map(generateAttributeUpdateDiff),
      force_pending: action.force_pending,
    }
  )

export const updateWorkflowBlockAction = (
  { workflowUuid, workflowVersion, workflowStepUuid, workflowBlockUuid }: ActionBlockRequestIdentifiers,
  actionId: WorkflowActionWrapped['uuid'],
  actionData: Partial<WorkflowAction> & Pick<WorkflowAction, 'uuid'>
) =>
  apiPut(
    `/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks/${workflowBlockUuid}/workflow-actions/${actionId}/attribute-values`,
    {
      attribute_values: actionData.attributes?.map(generateAttributeUpdateDiff),
      force_pending: actionData.force_pending,
    }
  )

export const deleteWorkflowBlockAction = (
  { workflowUuid, workflowVersion, workflowStepUuid, workflowBlockUuid }: ActionBlockRequestIdentifiers,
  actionId: WorkflowActionWrapped['uuid']
) =>
  apiDelete(
    `/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks/${workflowBlockUuid}/workflow-actions/${actionId}`
  )
