import { useCallback } from 'react'
import useIntakeFormStore from '../stores/useIntakeFormStore'
import { getUpdatedByProperty } from '../../../../shared/utils/arrayHelpers'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'
import { IntakeFieldType } from '../../../../types/Intake'

const useUpdateFormField = () => {
  const intake = useIntakeFormStore((state) => state.intake)
  const setIntake = useIntakeFormStore((state) => state.setIntake)
  const setFormDirty = useIntakeFormStore((state) => state.setFormDirty)

  return useCallback(
    (updatedField: IntakeFieldType) => {
      if (!intake) return

      const fieldSection = intake.sections.find((section) => section.fields.find(({ id }) => id === updatedField.id))

      if (!fieldSection) return

      const updatedIntake = getMergedDeepOverwritingArrays(intake, {
        sections: getUpdatedByProperty('id', intake.sections, [
          {
            id: fieldSection.id,
            fields: getUpdatedByProperty('id', fieldSection.fields, [updatedField]),
          },
        ]),
      })

      setIntake(updatedIntake)
      setFormDirty(true)
    },
    [intake]
  )
}

export default useUpdateFormField
