import { useQuery } from 'react-query'
import useGetFormId from '../FormBuilder/hooks/useGetFormId'
import { apiGetForm, formsQueryKeys } from '../FormBuilder/constants/formService'

export default (formIdProp?: string) => {
  const formId: string = formIdProp || useGetFormId()

  const QUERY_KEY = formsQueryKeys.apiGetForm(formId)

  return useQuery(QUERY_KEY, () => apiGetForm(formId), {
    enabled: !!formId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}
