import * as React from 'react'
import classnames from 'classnames'

export type WorkflowBlockEntryProps = React.PropsWithChildren<{
  Icon: React.ElementType
  title: string
}> &
  React.HTMLAttributes<HTMLDivElement>

export const WorkflowBlockEntry = ({ Icon, title, children, className, ...props }: WorkflowBlockEntryProps) => (
  <div className={classnames('flex flex-col gap-2 w-1/2', className)} {...props}>
    <div className="flex flex-row  text-gray-400 gap-2">
      <div className="flex-grow-0 whitespace-nowrap">
        <Icon className="h-5" />
      </div>
      <div className="flex-grow-1 whitespace-nowrap">{title}</div>
    </div>
    <div className="flex flex-row">{children}</div>
  </div>
)

export default WorkflowBlockEntry
