import { PlusIcon } from '@heroicons/react/24/outline'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { IButton } from './Button'

export interface IAddButton extends IButton {
  label?: string
}
const AddButton: FC<IAddButton> = ({ label = '', ...buttonProps }) => {
  const { t } = useTranslation()
  const defaultLabel = t('generic.add', 'Add')

  return (
    <div className="w-full">
      <Button className="float-right text-sky-blue hover:text-sky-blue-light transition-colors" {...buttonProps}>
        <PlusIcon className="h-4" />
        {label || defaultLabel}
      </Button>
    </div>
  )
}

export default AddButton
