import { create } from 'zustand'
import { IntakeType } from '../../../../types/Intake'
import { Offering } from '../../../../types/Offering'
import { ErrorsType, ProductsWithErrorsType } from '../../../../types/Intake/form/IntakeSubmission'

interface IntakeFormState {
  activeSectionIndex: number
  formDirty: boolean
  intake: IntakeType | null
  offerings: Offering[] | null
  errors: ErrorsType
  offersErrors: ProductsWithErrorsType | null
  showErrors: boolean
}

interface IntakeFormActions {
  setActiveSectionIndex: (index: number) => void
  setFormDirty: (dirty: boolean) => void
  setIntake: (intake: IntakeType) => void
  setOfferings: (offerings: Offering[]) => void
  setErrors: (errors: ErrorsType) => void
  setOffersErrors: (errors: ProductsWithErrorsType) => void
  setShowErrors: (showErrors: boolean) => void

  reset: () => void
}

const initialState: IntakeFormState = {
  activeSectionIndex: 0, // track active section
  formDirty: false, // track whether the form needs to update
  intake: null,
  offerings: null,
  errors: null,
  offersErrors: null,
  showErrors: false,
}

const useIntakeFormStore = create<IntakeFormState & IntakeFormActions>((set) => ({
  ...initialState,

  setActiveSectionIndex: (index: number) => set({ activeSectionIndex: index }),
  setFormDirty: (dirty: boolean) => set({ formDirty: dirty }),
  setIntake: (intake: IntakeType) => {
    set({ intake })
  },
  setOfferings: (offerings: Offering[]) => set({ offerings }),
  setErrors: (error: ErrorsType) => set({ errors: error }),
  setOffersErrors: (errors: ProductsWithErrorsType) => set({ offersErrors: errors }),
  setShowErrors: (showErrors: boolean) => set({ showErrors }),

  reset: () => {
    set({ ...initialState })
  },
}))

export default useIntakeFormStore
