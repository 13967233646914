// Refactor => ts
import { apiDelete, apiGet, apiGetData, apiPostData, apiPutData } from '../../../core/utils/api/generic'
import { useApiFetchMore } from '../../../core/utils/queryHooks'

export const apiGetSuppliers = async (page = 1, perPage = 15) =>
  apiGet('/supplier-branches', { page, per_page: perPage })

export const useApiGetMoreSuppliers = () => useApiFetchMore({ queryId: 'suppliers', request: apiGetSuppliers })

export const apiGetSupplier = async (id) => apiGet(`/suppliers/${id}`)

// SUPPLIER USERS
export const apiPutSupplier = async (id, body) => apiPutData(`suppliers/${id}`, body)

export const apiGetSupplierBranchTag = async (id, category) =>
  apiGetData(`suppliers/supplier-branches/${id}/tags/${category}`)
export const apiPostSupplierBranchTag = async (id, body, category) =>
  apiPostData(`suppliers/supplier-branches/${id}/tags/attach`, { tags: body, category })

export const apiPostRemoveSupplierBranchTag = async (id, body, category) =>
  apiPostData(`suppliers/supplier-branches/${id}/tags/detach`, { tags: body, category })
export const apiDeleteSupplierBranchTag = async (supplierBranchId, tagId) =>
  apiDelete(`/suppliers/supplier-branches/${supplierBranchId}/tags/${tagId}`)

export const apiGetSupplierBranchUsers = async (id) => apiGetData(`supplier-branches/${id}/users`)
export const apiPostSupplierBranchUser = async ({ supplierGroupId, supplierBranchId, userData }) =>
  apiPostData(`/suppliers/${supplierGroupId}/supplier-branches/${supplierBranchId}/user`, userData)

export const apiUpdateSupplierBranchUser = async ({ supplierGroupId, supplierBranchId, userId, userData }) =>
  apiPutData(`/suppliers/${supplierGroupId}/supplier-branches/${supplierBranchId}/user/${userId}`, userData)

export const apiDeleteSupplierBranchUser = async ({ supplierGroupId, supplierBranchId, userId }) => {
  apiDelete(`/suppliers/${supplierGroupId}/supplier-branches/${supplierBranchId}/user/${userId}`)
}

export const apiGetSupplierBranchAddress = async (id) => apiGetData(`/addresses/supplier-branches/${id}`)

export const apiPostSupplierBranchAddress = async (id, body) => apiPostData(`/addresses/supplier-branches/${id}`, body)
export const apiUpdateAddress = async (id, body) => apiPutData(`/addresses/${id}`, body)
export const apiDeleteSupplierBranchAddress = async (id) => apiDelete(`/addresses/${id}`)
