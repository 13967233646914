import { useTranslation } from 'react-i18next'

import FormLogicGroups from './FormLogicGroups'
import FormLogicSwitch from './FormLogicSwitch'

const FormLogic: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="bg-white px-6 py-4 rounded-md flex flex-col gap-y-4 text-primary-gray">
      <p className="font-medium">{t('forms.form_logic')}</p>
      <FormLogicSwitch />
      <FormLogicGroups />
    </div>
  )
}

export default FormLogic
