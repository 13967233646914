import { TrashIcon } from '@heroicons/react/24/outline'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import BaseInputWrapper from '../../../../../shared/components/atoms/Input/BaseInputWrapper'
import Input from '../../../../../shared/components/atoms/Input/Input'
import SearchSelect from '../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { InputSearchSelectItemType } from '../../../../../types/shared/InputSearchSelectTypes'

export type MetaInputKeyValueType = {
  id: string
  key: string
  value: string
}

interface IUserDetailsDefaultValuesForm {
  metaInput?: MetaInputKeyValueType
  index?: number
  handleChange: (key: string, value: string, index?: number) => void
  options: InputSearchSelectItemType[]
  handleDelete: (index: number) => void
}

const UserDetailsDefaultValuesForm: FC<IUserDetailsDefaultValuesForm> = ({
  metaInput,
  index,
  handleChange,
  options,
  handleDelete,
}) => {
  const { t } = useTranslation()

  return (
    <div className="grid grid-cols-2 gap-4">
      <BaseInputWrapper label={t('generic.key', 'Key')}>
        <SearchSelect
          options={options}
          currentSelection={{ id: metaInput?.key || '', label: metaInput?.key! }}
          onChange={(selection) => handleChange(selection?.label || '', metaInput?.value || '', index)}
          onInputBlur={(q) => handleChange(q || metaInput?.key || '', metaInput?.value || '', index)}
        />
      </BaseInputWrapper>
      <div className="flex gap-4 items-end">
        <Input
          label={t('generic.value', 'Default Value')}
          id="value"
          defaultValue={metaInput?.value}
          onBlur={(val) => handleChange(metaInput?.key || '', val?.toString() || '', index)}
        />
        <Button className="my-2" tabIndex={-1} onClick={() => index !== undefined && handleDelete(index)}>
          <TrashIcon className="w-4" />
        </Button>
      </div>
    </div>
  )
}
export default UserDetailsDefaultValuesForm
