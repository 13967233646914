import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

export const useFormValidationLocalization = () => {
  const { t } = useTranslation()

  Yup.setLocale({
    mixed: {
      default: (inputParams) => t('forms.validation.invalid', inputParams),
      required: (inputParams) => t('forms.validation.required', inputParams),
    },
    array: {
      min: (inputParams) => t('forms.validation.minItems', inputParams),
    },
  })
}

export default useFormValidationLocalization
