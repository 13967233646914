import useGetFormData from '../../../../hooks/useGetFormData'
import FormLogicGetGraphButton from './FormLogicGetGraphButton'
import FormLogicGroup from './FormLogicGroup/FormLogicGroup'

interface IFormLogicGroups {}

const FormLogicGroups: React.FC<IFormLogicGroups> = () => {
  const { data: formData } = useGetFormData()
  const hasLogic = !!formData?.template.__settings?.logic

  if (!hasLogic) return null

  return (
    <div className="flex flex-col gap-y-4">
      {formData?.template.sections.map((section) => <FormLogicGroup key={section.__uuid} section={section} />)}
      <FormLogicGetGraphButton />
    </div>
  )
}

export default FormLogicGroups
