import { FC } from 'react'
import { Avatar, AvatarFallback } from './Avatar'
import stringToHslColor from '../../../utils/stringToHslColor'

interface IAvatarGroupProps {
  userInitials: string[]
  maxAvatars: number
}

const AvatarGroup: FC<IAvatarGroupProps> = ({ userInitials, maxAvatars }) => {
  const displayedUsers = userInitials.slice(0, maxAvatars)
  const remainingCount = userInitials.length - displayedUsers.length

  return (
    <div className="flex -space-x-1.5">
      {displayedUsers.map((userInitial) => (
        <Avatar className="h-6 w-6 z-10 border-[1px] rounded-full border-white" key={`avatar-grp-${userInitial}`}>
          <AvatarFallback
            style={{ backgroundColor: stringToHslColor({ name: userInitial }) }}
            className="font-semibold text-[8px]"
          >
            {userInitial}
          </AvatarFallback>
        </Avatar>
      ))}
      {remainingCount > 0 && (
        <div className="h-6 w-6 z-20 rounded-full bg-gray-100 font-medium text-gray-500 flex items-center justify-center border-[1px] border-white text-[8px]">
          +{remainingCount}
        </div>
      )}
    </div>
  )
}

export default AvatarGroup
