import UserField from './UserField'
import { useFormField } from '../providers/FormFieldProvider'

export const FormFieldUserSelect = () => {
  const {
    field: { value, disabled },
    setValues,
  } = useFormField()

  return <UserField disabled={disabled} selected={value || undefined} onSelect={setValues} />
}

export default FormFieldUserSelect
