import { useMutation, useQuery } from 'react-query'
import { apiDelete, apiGetData, apiPost } from '../../../../../../../core/utils/api/generic'

export type AbsenceType = {
  uuid: string
  from: string
  to: string
  substitute_user: { id: number; first_name?: string; last_name?: string }
  reason: string
}

export const apiPostAbsence = async (data: Omit<AbsenceType, 'id'>) => apiPost<AbsenceType>('/absences', data)
export const apiGetAbsences = async () => apiGetData<AbsenceType[]>('/absences')
export const apiDeleteAbsences = async (absenceId: string) => apiDelete<AbsenceType[]>(`/absences/${absenceId}`)

export default function useAbsenceService() {
  const getAbsences = useQuery(['absences'], apiGetAbsences)

  const postAbsence = useMutation(apiPostAbsence, { onSuccess: () => getAbsences.refetch() })
  const deleteAbsence = useMutation(apiDeleteAbsences, { onSuccess: () => getAbsences.refetch() })

  return { getAbsences, postAbsence, deleteAbsence }
}
