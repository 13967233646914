import { useMemo } from 'react'
import useCurrentExternalDataSourceUuid from './useCurrentExternalDataSourceUuid'
import useGetExternalDataEntitiesList from './useGetExternalDataEntitiesList'

export const useGetExternalDataEntity = () => {
  const uuid = useCurrentExternalDataSourceUuid()
  const { data: entities, isLoading } = useGetExternalDataEntitiesList()

  return useMemo(
    () => ({
      data: entities?.find((entity) => entity.uuid === uuid),
      isLoading,
    }),
    [entities, uuid]
  )
}

export default useGetExternalDataEntity
