import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

const fallbackLng = 'en'

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    languages: ['de', 'en'],
    lng: localStorage.language,
    fallbackLng,
    debug: false,
    keySeparator: false,
    backend: {
      loadPath: `${process.env.REACT_APP_TRANSLATIONS_URL}/api/v2/locales/{{lng}}/{{ns}}`,
      crossDomain: true,
    },
    interpolation: {
      escapeValue: false,
    },
    returnEmptyString: false,
    returnNull: false,
  })
export default i18n
