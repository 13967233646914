import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import useIntakeId from '../../hooks/useIntakeId'
import { useGetIntakeChangeLog } from '../../hooks/useGetIntakeChangeLog'
import { IntakeChangeLogItemType } from '../../../../../types/Intake'
import ChangeLogItem from './components/ChangeLogItem'
import SidepanelTabTransition from '../../../../../shared/components/layout/SidepanelTabTransition'

type ChangeLogItemDateGroupType = {
  [key: string]: IntakeChangeLogItemType[]
}

const IntakeSidepanelChangeLog = () => {
  const { t } = useTranslation()
  const intakeId = useIntakeId()
  const { data: changeLogItems } = useGetIntakeChangeLog(intakeId)

  const changeLogItemsByDate: ChangeLogItemDateGroupType = {}
  changeLogItems?.forEach((item) => {
    const date = dayjs.utc(item.date).local().format('YYYY-MM-DD')
    if (!changeLogItemsByDate[date]) {
      changeLogItemsByDate[date] = []
    }
    changeLogItemsByDate[date].push(item)
  })

  return (
    <SidepanelTabTransition>
      <div className="h-full flex flex-col overflow-y-auto">
        <div role="list" className="-mb-8">
          {Object.keys(changeLogItemsByDate).map((date) => (
            <div key={date} className="border-b border-rock-gray-light mb-6 last:border-none">
              <div className="relative pb-4 text-black-light text-sm font-semibold">
                {dayjs(date).isSame(dayjs(), 'day') ? t('generic.today', 'Today') : dayjs(date).format('DD.MM.YY')}
              </div>
              {changeLogItemsByDate[date].map((item, itemIdx) => (
                <div key={uuid()}>
                  <div className="relative pb-8">
                    {itemIdx !== changeLogItemsByDate[date].length - 1 ? (
                      <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    ) : null}
                    <ChangeLogItem item={item} />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </SidepanelTabTransition>
  )
}

export default IntakeSidepanelChangeLog
