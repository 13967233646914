import { ForwardedRef, forwardRef, useEffect, useLayoutEffect } from 'react'
import { useIntakeFlow } from '../providers/IntakeFlowProvider'
import useIntakeFormStore from '../../IntakeFormResponse/stores/useIntakeFormStore'
import SectionRenderer from '../../IntakeFormResponse/components/IntakeFormSections/SectionRenderer'
import { IntakeSectionType } from '../../../../types/Intake'
import validate from '../../IntakeFormResponse/validation/formValidation'

export const IntakeFlowOverviewPageDetails = forwardRef((props, detailRef: ForwardedRef<HTMLDivElement>) => {
  const { intake } = useIntakeFlow()

  const setIntake = useIntakeFormStore((state) => state.setIntake)
  const resetFormStore = useIntakeFormStore((state) => state.reset)

  const intakeSections = useIntakeFormStore((state) => state.intake?.sections)
  const offerings = useIntakeFormStore((state) => state.offerings)
  const setErrors = useIntakeFormStore((state) => state.setErrors)
  const setShowErrors = useIntakeFormStore((state) => state.setShowErrors)

  useLayoutEffect(() => {
    if (!intake) return

    resetFormStore()
    setIntake(intake)
  }, [intake?.uuid])

  useEffect(() => {
    // Calculate errors for visible fields
    if (intakeSections && offerings) {
      const intakeFields = intakeSections.flatMap((section: IntakeSectionType) => section.fields)
      const offeringsFields = offerings.flatMap((offering) => offering.fields)
      const allFields = intakeFields.concat(offeringsFields || [])

      const visibleFields = allFields.filter((field) => field.visible)

      const errors = validate(visibleFields)
      setErrors(errors)
      setShowErrors(true)
    }
  }, [intakeSections, offerings])

  if (!intake) return null

  return (
    <div ref={detailRef} className="flex flex-col gap-11 text-black-light max-w-7xl mx-auto">
      {intake.sections.map((section) => (
        <SectionRenderer section={section} key={`section-${section.id}`} useSectionWrapper />
      ))}
    </div>
  )
})
IntakeFlowOverviewPageDetails.displayName = 'IntakeFlowOverviewPageDetails'
export default IntakeFlowOverviewPageDetails
