import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useFormField } from '../providers/FormFieldProvider'
import useSearchSelectOptions from '../../../shared/hooks/useSearchSelectOptions'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'

export const FormFieldSearchSelect = () => {
  const {
    field: { uuid, value, input, disabled, error },
    setValues,
  } = useFormField()
  const { t } = useTranslation()

  // The value only changes once the form is saved,so we need to keep track of
  // the local value as the user interacts with the search select, otherwise it does not visually update
  const [localValue, setLocalValue] = useState(value)

  const { options, selected, selectedToOption, visibleColumns, isLoading } = useSearchSelectOptions({
    __uuid: uuid,
    values: localValue ? [localValue] : [],
    inputId: input.uuid,
    ...input,
  })

  if (input.type !== 'SEARCH_SELECT') return null

  const handleSelect = (currentSelection: InputSearchSelectItemType) => {
    if (!currentSelection) return
    setValues(selectedToOption(currentSelection))
    setLocalValue(selectedToOption(currentSelection))
  }

  return (
    <SearchSelect
      isLoading={isLoading}
      disabled={disabled}
      onChange={handleSelect}
      options={options}
      currentSelection={selected}
      placeholder={t('question.search_option')}
      visibleColumns={visibleColumns}
      error={error}
      selectType={input.type}
    />
  )
}

export default FormFieldSearchSelect
