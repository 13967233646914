import { FC } from 'react'

interface IUniteSearchStockStatus {
  inStock: boolean
}

const UniteSearchStockStatus: FC<IUniteSearchStockStatus> = ({ inStock }) => (
  <div className="text-xs font-normal leading-6">
    {inStock ? <div className="text-sky-blue">In Stock</div> : <div className="text-red-600">Out of Stock</div>}
  </div>
)

export default UniteSearchStockStatus
