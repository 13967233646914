import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import BaseInputWrapper from '../../../../../../shared/components/atoms/Input/BaseInputWrapper'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import TipTapEditor from '../../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import UserField from '../../../../../Forms-v2/FormFields/UserField'
import FormDateInput from '../../../../../Forms-v2/FormInputs/FormDateInput'
import useAbsenceService from './hooks/useAbsenceService'
import { toastr } from '../../../../../../shared/components/organisms/toastr'

interface IAbsenceModal {}

const AbsenceModal: FC<IAbsenceModal> = () => {
  const { t } = useTranslation()

  const {
    postAbsence: { mutateAsync: createAbsence },
  } = useAbsenceService()

  const { modal } = useUtils()
  const { handleSubmit, control } = useForm({ mode: 'onChange' })

  const onSubmit = async (attributes: any) => {
    await createAbsence(attributes)
    modal.set({ isOpen: false })
    toastr.success(t('profile.absence-created', 'Absence created'))
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
        <BaseInputWrapper label={t('generic.dates', 'Dates')}>
          <div className="flex gap-4 items-center">
            <Controller
              name="from"
              control={control}
              render={({ field: { onChange, ...fieldProps } }) => (
                <FormDateInput
                  {...fieldProps}
                  type="DATE"
                  onChange={(date) => onChange(date.toISOString().substring(0, 10))}
                  date={fieldProps.value}
                />
              )}
            />
            {' - '}
            <Controller
              name="to"
              control={control}
              render={({ field: { onChange, ...fieldProps } }) => (
                <FormDateInput
                  {...fieldProps}
                  type="DATE"
                  onChange={(date) => onChange(date.toISOString().substring(0, 10))}
                  date={fieldProps.value}
                />
              )}
            />
          </div>
        </BaseInputWrapper>

        <Controller
          name="substitute_user"
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => (
            <BaseInputWrapper label={t('generic.substitute', 'Substitute')}>
              <UserField
                {...fieldProps}
                selected={undefined}
                onSelect={(user) => {
                  onChange({ id: user.__uuid })
                }}
              />
            </BaseInputWrapper>
          )}
        />
        <Controller
          name="reason"
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => (
            <BaseInputWrapper label={t('generic.reason', 'Reason')}>
              <TipTapEditor {...fieldProps} onUpdate={(input) => onChange(input)} />
            </BaseInputWrapper>
          )}
        />
        <div className="flex w-full justify-end">
          <Button onClick={() => modal.close()} className="">
            {t('generic.cancel', 'Cancel')}
          </Button>
          <Button type="submit" className="bg-sky-blue text-white">
            {t('generic.submit', 'Submit')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default AbsenceModal
