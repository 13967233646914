import { useMutation, useQueryClient } from 'react-query'
import { apiUploadExternalData } from '../services/ExternalDataServices'

export type ExternalDataUploadPayload = { name: string; file: File }

export const useExternalDataUpload = () => {
  const cache = useQueryClient()

  const upload = useMutation(({ name, file }: ExternalDataUploadPayload) => apiUploadExternalData(name, file), {
    mutationKey: ['entities', 'upload'],
    onSettled: () => {
      !!cache.invalidateQueries('entities')
    },
  })
  return {
    upload: upload.mutateAsync,
    isProcessing: cache.isMutating({ mutationKey: ['entities', 'upload'] }) > 0,
  }
}

export default useExternalDataUpload
