import { WorkflowChartBlockNodeProps } from '../../../../types/Workflow/WorkflowChart'
import { WorkflowChartNodeTemplate } from './components/WorkflowChartNodeTemplate'

export const WorkflowChartNodeGeneric = ({ data, ...node }: WorkflowChartBlockNodeProps) => {
  const status = data.workflow_block_status?.status.name

  return (
    <WorkflowChartNodeTemplate node={{ ...node, data }}>
      <div className="flex flex-row gap-3 border-b border-b-ice-blue pb-2 w-full items-center">
        <div className="flex-grow-1 font-bold ">{data.name}</div>
      </div>
      <div>{status}</div>
    </WorkflowChartNodeTemplate>
  )
}

export default WorkflowChartNodeGeneric
