import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import AddButton from '../../../../../../shared/components/atoms/buttons/AddButton'
import useIntakeFormStore from '../../../../../Intake/IntakeFormResponse/stores/useIntakeFormStore'
// import { LoopSectionFieldValues } from '../../../hooks/useLoopSection'
import UniteLogo from './UniteLogo.png'
import UniteSearchModal from './UniteSearchModal'

interface IUniteSearch {
  onAdd: (data: any) => void
}

const UniteModalTitle: FC = () => (
  <div className="flex gap-x-2 items-center">
    <img src={UniteLogo} className="h-6" alt="Unite Logo" />
  </div>
)

const UniteSearch: FC<IUniteSearch> = ({ onAdd }) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const intakeSettings = useIntakeFormStore((state) => state.intake?.settings)

  if (!intakeSettings?.uniteEnabled) return null

  const handleSearchClick = () => {
    modal.set({
      isOpen: true,
      isOverlay: true,
      title: <UniteModalTitle />,
      content: <UniteSearchModal onAdd={onAdd} />,
      className: ' ',
    })
  }

  return (
    <div className="">
      <AddButton label={t('generic.add-catalog-product', 'Add catalog product')} onClick={handleSearchClick}>
        Search with Unite
      </AddButton>
    </div>
  )
}
export default UniteSearch
