import { MdAdd } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import useInputTemplateName from '../../../../../../CompanyInputs/hooks/useInputTemplateName'
import { isBasicInput } from '../../../../../../CompanyInputs/helpers/InputTemplateHelpers'
import { InputTemplate } from '../../../../../../../types/Forms'

interface IQuestionTypeOption {
  additionalInfo?: string
  input: InputTemplate
  handleClick: (questionType: string) => void
}

const QuestionTypeOption: React.FC<IQuestionTypeOption> = ({ additionalInfo, handleClick, input }) => {
  const getName = useInputTemplateName()
  const { type } = input
  const { t } = useTranslation()

  return (
    <button
      className="p-4 border border-quaternary-grey rounded-lg hover:bg-vibrant-blue hover:bg-opacity-5 hover:border hover:border-vibrant-blue text-left justify-between flex items-center"
      key={type}
      type="button"
      onClick={() => handleClick(type)}
    >
      <div className="flex flex-col gap-y-1">
        <p>{getName(input)}</p>
        {!isBasicInput(input) && <p className="text-secondary-gray text-sm">{type ? t(`forms.input.${type}`) : ''}</p>}
        {additionalInfo && <p className="text-secondary-gray text-sm">{additionalInfo}</p>}
      </div>
      <MdAdd className="text-primary-gray" size={20} />
    </button>
  )
}

export default QuestionTypeOption
