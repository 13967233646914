import { useFormContext } from '../../../../../../../../core/providers/FormProvider'
import { ValidationProperty } from '../../ValidationSchemas'

export default <T>(property: ValidationProperty) => {
  const {
    question: [question],
  } = useFormContext()

  const validation = question ? question.validation[property] : undefined
  const isChecked = validation !== undefined

  return {
    currentValue: validation?.value as T,
    currentTarget: validation?.target,
    isChecked,
    answerSelector: validation?.answerSelector,
  }
}
