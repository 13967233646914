import { apiGet, apiGetData, apiPost, apiPutData } from '../../../../core/utils/api/generic'
import {
  ActiveWorkflow,
  ActiveWorkflowActionType,
  TaskStatusPayloadType,
  TaskStatusUpdateResponseType,
} from '../../../../types/Workflow/ActiveWorkflow'
import { ActiveWorkflowAction } from '../../../../types/Workflow/WorkflowAction'

export const getActiveWorkflow = (intakeId: string) => apiGet<ActiveWorkflow>(`/active-workflow/${intakeId}`)

export const postActiveWorkflowAction = (activeWorkflowId: string, data: ActiveWorkflowActionType) =>
  apiPost<ActiveWorkflow>(`/active-workflow/${activeWorkflowId}/action`, {
    active_workflow_uuid: activeWorkflowId,
    ...data,
  })

export const putActiveWorkflowTaskStatus = (taskUuid: string, data: TaskStatusPayloadType) =>
  apiPutData<TaskStatusUpdateResponseType>(`/tasks/${taskUuid}/status`, data)

type RawActiveWorkflowAction = Omit<ActiveWorkflowAction, 'status_uuid' | 'status' | 'object'> & {
  status_uuid: ActiveWorkflowAction['status_uuid'] | null
  status: ActiveWorkflowAction['status'] | null
  object: ActiveWorkflowAction['object'] | Array<void>
}

export const getActiveWorkflowBlockActions = (workflowUuid: string, workflowBlockUuid: string) =>
  apiGetData<RawActiveWorkflowAction[]>(`/active-workflow/${workflowUuid}/blocks/${workflowBlockUuid}/actions`).then(
    (actions) =>
      actions.filter(
        ({ status, status_uuid, object }) => status && status_uuid && !Array.isArray(object)
      ) as ActiveWorkflowAction[]
  )
