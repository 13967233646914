import { useMutation, useQueryClient } from 'react-query'
import { useUtils } from '../../../core/providers/UtilsProvider'
import { toastr } from '../../../shared/components/organisms/toastr'
import companyQueryStrings from '../constants/companyQueryStrings'
import { apiMakeCompanyAddressPublic } from '../services/CompanyService'

function useMakeCompanyAddressesPublic(company: { address_id: number }) {
  const cache = useQueryClient()
  const utils = useUtils()

  const { mutate } = useMutation(() => apiMakeCompanyAddressPublic(company), {
    onSuccess: (newData) => {
      cache.setQueryData([companyQueryStrings.company_addresses], () => newData?.data)
      utils.modal.set({ isOpen: false })
      toastr.success('success.company_address_change', 'companyAddressChange')
    },
  })
  return mutate
}
export default useMakeCompanyAddressesPublic
