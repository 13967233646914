import { useMessengerContext } from '../../../../core/providers/MessengerProvider'
import useIntakeId from '../../../Intake/IntakeOverview/hooks/useIntakeId'
import useGetAndSetActiveIntakeMessage from './useGetAndSetActiveIntakeMessage'

export default () => {
  const {
    messageProperties: [{ messageReply, files }],
  } = useMessengerContext()
  const modelType = 'intake_flow'
  const modelId = useIntakeId()

  const [message] = useGetAndSetActiveIntakeMessage()
  const referenceId = messageReply?.referenceId

  // 1. convert files to base64
  // 2. on conversion resolve them to be in the shape backend expects
  return async () => {
    const base64Files = await Promise.all(
      files.map(
        async (file) =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.onload = (readFile) => {
              const result = readFile?.target?.result
              if (typeof result === 'string') {
                resolve({
                  content: result?.split(',')[1],
                  name: file.name,
                })
              }
            }

            fileReader.onerror = (error) => reject(error)
            fileReader.readAsDataURL(file)
          })
      )
    )

    return {
      model_type: modelType,
      model_id: modelId,
      text_message: message || undefined,
      reference_uuid: referenceId,
      files: base64Files,
    }
  }
}
