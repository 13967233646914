import classnames from 'classnames'
import * as React from 'react'
import { WorkflowBlock } from '../../../../../../../../types/Workflow/WorkflowBlock'
import { useBlockIcon } from '../../../../../../../Workflow'

type IntakeFlowchartNodeIconProps = {
  block: WorkflowBlock | undefined
}

export const IntakeFlowchartNodeIcon = ({ block }: IntakeFlowchartNodeIconProps) => {
  const status = block?.status || 'pending'
  const Icon = useBlockIcon(block)

  if (!Icon) return null

  return (
    <div
      className={classnames('flex-grow-0 rounded-full p-1.5', {
        'bg-green-light text-white': ['completed', 'approved'].includes(status),
        'bg-sky-blue text-white': ['ongoing', 'pending'].includes(status),
        'border-2 border-gray-400 text-gray-400': ['in_queue'].includes(status),
        'bg-gray-100 text-gray-800': status === 'skipped',
        'bg-red-500 text-white': ['declined', 'failed'].includes(status),
      })}
    >
      <Icon className="h-5" />
    </div>
  )
}

export default IntakeFlowchartNodeIcon
