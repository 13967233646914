import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import MessageBoxActivity from './MessageBubble/MessageBubble'
import useGetIntakeChat from '../../../hooks/IntakeFlowChat/useGetIntakeChat'
import { MessageType } from '../../../../../types/Messenger/types'
import DateDivider from './DateDivider'
import { scrollToBottomOfChat } from '../../../utils'

interface IMessageBox {
  style?: React.CSSProperties
}

const MessageTimeline: React.FC<IMessageBox> = ({ style }) => {
  const { data: messages, isLoading } = useGetIntakeChat()
  const { t } = useTranslation()

  useEffect(() => {
    scrollToBottomOfChat()
  }, [messages])

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <CoreLoadingIcon />
      </div>
    )
  }

  type DateSeparator = {
    type: string
    created_at: string
    uuid: string
  }

  type AccumulatorType = DateSeparator | MessageType

  // Add a date separator between messages
  const messagesWithDateSeparators = messages?.reduce((acc: AccumulatorType[], message) => {
    const lastMessage = acc[acc.length - 1]
    const lastMessageDate = lastMessage?.created_at
    const messageDate = message?.created_at

    const messageDateObj = dayjs(messageDate)
    const lastMessageDateObj = dayjs(lastMessageDate)

    // If the current message is not the same date as the previous message add a date separator
    const isSameDate = dayjs(messageDateObj).isSame(lastMessageDateObj, 'day')
    if (!isSameDate) {
      acc.push({
        type: 'dateSeparator',
        created_at: message.created_at,
        uuid: '',
      })
    }

    const newMessage = message
    newMessage.type = 'message'

    acc.push(newMessage)
    return acc
  }, [])

  return (
    <div className="flex h-full w-full cursor-auto">
      <div className="p-4 pt-0 overflow-y-auto w-full h-full flex flex-col relative" id="message-container">
        {messagesWithDateSeparators?.map((message: DateSeparator | MessageType) => {
          if (message.type === 'dateSeparator') {
            return <DateDivider key={`divider-${message.created_at}`} dateString={message.created_at} />
          }
          return <MessageBoxActivity key={message?.uuid} message={message as MessageType} />
        })}

        {messages?.length === 0 && (
          <div className="flex w-full justify-center h-full items-center text-sm text-gray-500">
            {t('sherpa.no_messages')}
          </div>
        )}
      </div>
    </div>
  )
}

export default MessageTimeline
