import LanguageSelect from './LanguageSelect'
import BaseInput from '../../../../../../shared/components/atoms/Input/BaseInput'

type UserProfileInputResolverProps = {
  inputType: string
  value: string
  disabled: boolean
  readonly: boolean
  onBlur: (value: string | number | readonly string[] | undefined) => void
}
const UserProfileInputResolver = ({ value, disabled, readonly, inputType, onBlur }: UserProfileInputResolverProps) => {
  const CustomInputType = {
    language: <LanguageSelect values={{ language: value }} handleChange={onBlur} />,
  }

  return (
    CustomInputType[inputType as keyof typeof CustomInputType] || (
      <BaseInput
        className="w-full"
        onBlur={onBlur}
        defaultValue={value}
        disabled={disabled}
        readOnly={readonly}
        type={inputType}
      />
    )
  )
}
export default UserProfileInputResolver
