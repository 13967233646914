import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { CoreNavigationLinkType } from '../../../../types/shared/CoreNavigationLinkType'
import activeMark from '../../../assets/active-item-mark.svg'

interface ICoreSidebarLink {
  item: CoreNavigationLinkType
  minified?: boolean
  visible?: boolean
}

const CoreSidebarLink: React.FC<ICoreSidebarLink> = ({ item, minified, visible }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const splitLocation = pathname.split('/')

  const activeUrl = `/${splitLocation[1]}`
  const isActive = item.sections?.includes(activeUrl)

  if (!visible) return null

  return (
    <div className="flex items-center h-12">
      {isActive && <img src={activeMark} alt="" className="absolute l-0 stroke-white text-white" />}
      <div className="w-full mx-2 rounded-md">
        <NavLink
          to={item.href}
          className={`text-white w-full p-2 transition-colors hover:bg-sky-blue-light flex items-center ${
            minified && 'justify-center'
          } rounded-md`}
          onClick={item.onClick}
        >
          {item.icon && (
            <span
              className={`${
                !isActive ? 'opacity-70' : null
              } w-8 h-8 flex transition-opacity justify-center items-center align-middle text-2xl`}
            >
              <item.icon />
            </span>
          )}
          {!minified && <span className="ml-3 pr-2 text-sm w-full">{t(item.name)}</span>}
        </NavLink>
      </div>
    </div>
  )
}

export default CoreSidebarLink
