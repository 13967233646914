import classnames from 'classnames'

type QuestionSectionProps = {
  title: string
} & React.HTMLAttributes<HTMLDivElement>

export const QuestionSubSection: React.FC<React.PropsWithChildren<QuestionSectionProps>> = ({
  title,
  children,
  className,
  ...divProps
}) => (
  <div className={classnames('flex flex-col gap-y-3 w-full text-primary-gray', className)} {...divProps}>
    {title && <p className="font-small">{title}</p>}
    {children}
  </div>
)

export default QuestionSubSection
