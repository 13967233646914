import { FilterSettingsType } from '../../../types/Forms'
import ExternalDataSourceSelect from '../../ExternalDataManager/components/selectors/ExternalDataSourceSelect'

interface ICompanyExternalDataSelect {
  handleFilterSettingsInput: (updatedSettings: Partial<FilterSettingsType>) => void
  selectedFileUuid: string | undefined
}
const DatasetSelect: React.FC<ICompanyExternalDataSelect> = ({ handleFilterSettingsInput, selectedFileUuid }) => {
  const onSelectedReferenceDatasetChange = (uuid: string) => {
    handleFilterSettingsInput({ data_source: uuid })
  }

  return <ExternalDataSourceSelect onSelect={onSelectedReferenceDatasetChange} selected={selectedFileUuid} />
}

export default DatasetSelect
