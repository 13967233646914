import { useState } from 'react'
import ExternalDataEntitySettingsTabbar from '../components/ExternalDataEntitySettingsTabbar/ExternalDataEntitySettingsTabbar'
import ExternalDataRelationshipsTable from '../components/ExternalDataRelationshipsTable/ExternalDataRelationshipsTable'
import ExternalDataEmbeddingsTable from '../components/ExternalDataEmbeddings/ExternalDataEmbeddingsTable'

const tabs: { [key: string]: JSX.Element } = {
  relationships: <ExternalDataRelationshipsTable />,
  embeddings: <ExternalDataEmbeddingsTable />,
}

const ExternalDataEntitySettingsPage = () => {
  const [activeTab, setActiveTab] = useState('relationships')
  const tabElement = tabs[activeTab] ?? undefined

  return (
    <div className="flex flex-row w-full h-full overflow-hidden">
      <ExternalDataEntitySettingsTabbar activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="w-full flex justify-center pb-10 overflow-y-auto">
        <div className="flex flex-col w-full 3xl:w-2/3 2xl:w-5/6 space-y-10 pl-14 mb-20">{tabElement}</div>
      </div>
    </div>
  )
}

export default ExternalDataEntitySettingsPage
