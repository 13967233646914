import { FilterSettingsType } from '../../../types/Forms'
import ExternalDataSourceColumnSelect from '../../ExternalDataManager/components/selectors/ExternalDataSourceColumnSelect'

interface IDatasetInputValueColumnSelect {
  handleFilterSettingsInput: (updatedSettings: Partial<FilterSettingsType>) => void
  datasetUuid: string | undefined
  selectedColumnName: string | undefined
}
const DatasetInputValueColumnSelect: React.FC<IDatasetInputValueColumnSelect> = ({
  handleFilterSettingsInput,
  datasetUuid,
  selectedColumnName,
}) => {
  const onColumnSelect = (column_name: string) => {
    handleFilterSettingsInput({ input_value_column: column_name })
  }

  return (
    <ExternalDataSourceColumnSelect sourceUuid={datasetUuid} selected={selectedColumnName} onSelect={onColumnSelect} />
  )
}

export default DatasetInputValueColumnSelect
