import { apiGetData, apiPost, apiPostData, apiPutData, apiDelete } from '../../../core/utils/api/generic'
import SupplierBranchDataType from '../../../types/Suppliers/SupplierBranchDataType'
import SupplierSearchType from '../../../types/Suppliers/SupplierSearchType'

// BRANCHES

export const apiPostSupplierSearch = async (body: { query: string }): Promise<{ data: SupplierSearchType[] }> =>
  apiPost('/supplier-branches/search', body)

export const apiGetSupplierBranch = (id: number | undefined) =>
  apiGetData<SupplierBranchDataType>(`supplier-branches/${id}`)
export const apiPutSupplierBranch = async (supplierId: number, id: number, body: any) =>
  apiPutData(`suppliers/${supplierId}/supplier-branches/${id}`, body)
export const apiPostSupplierBranch = async (body: { name: string; email?: string; phone_number?: string }) =>
  apiPostData('supplier-branches', body)
export const apiDeleteSupplierBranch = async (supplierId: number, branchId: number) =>
  apiDelete(`/suppliers/${supplierId}/supplier-branches/${branchId}`)
// USERS
type MainTagType = { category: 'general'; tags: ['main'] }
export const apiPostMainSupplierUser = async ({
  supplierUserId,
  body,
}: {
  supplierUserId: number
  body: MainTagType
}) => apiPostData(`/supplier-users/${supplierUserId}/tags`, body)
export const apiPostSupplierUserSearch = async (query: string) => apiPost('/supplier-users/search', query)
