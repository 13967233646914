type UserInitialsType = {
  first_name?: string
  last_name?: string
  email: string
}

export const getUserInitials = (user: UserInitialsType): string => {
  if (user.first_name || user.last_name) {
    const initials = (user.first_name ? user.first_name[0] : '') + (user.last_name ? user.last_name[0] : '')
    return initials.toUpperCase()
  }

  // Defaults to first two characters of email
  return user.email.slice(0, 2).toUpperCase()
}

// Gets initials from full name, but only for first name and last name (ignore middle names)
export const getUserInitialsFromFullNameOrEmail = (fullName: string, email: string): string => {
  if (fullName !== '') {
    const names = fullName.split(' ')
    if (names.length > 1) {
      return (names[0][0] + names[names.length - 1][0]).toUpperCase()
    }
    return fullName.slice(0, 2).toUpperCase()
  }

  return email.slice(0, 2).toUpperCase()
}

export default getUserInitials
