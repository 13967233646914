import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import SearchSelect, { ISearchSelect } from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionValue } from '../../../types/Forms'
import SupplierNewModal from '../../Suppliers/components/SupplierNewModal'
import { useUtils } from '../../../core/providers/UtilsProvider'
import SupplierBranchDataType from '../../../types/Suppliers/SupplierBranchDataType'
import useSearch from '../../Search/Hooks/useSearch'
import { HitsType } from '../../Search/SearchTypes'
import { SupplierUserSearchType } from '../../Search/Types/SearchEntitiesTypes'

type SupplierFieldProps = {
  selectedValue: FormQuestionValue | undefined
  onAdd: (supplier: SupplierBranchDataType) => void
  onSelect: (value: FormQuestionValue | null) => void
} & Omit<ISearchSelect, 'onChange' | 'options' | 'currentSelection' | 'onAdd'>

const SupplierField: React.FC<SupplierFieldProps> = ({ selectedValue, onAdd, onSelect, ...searchSelectProps }) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const [searchQuery, setSearchQuery] = useState((selectedValue?.value as string) || '')

  const { data: searchedSuppliers, isLoading: isSearching } = useSearch<SupplierUserSearchType>({
    entity: 'supplier-users',
    queryFilters: {
      query: searchQuery,
      per_page: 50,
    },
  })

  const openNewSupplierModal = () => {
    modal.set({
      title: t('suppliers.add_supplier'),
      content: <SupplierNewModal onFinish={onAdd} />,
      isLoading: false,
      isOpen: true,
    })
  }

  const onChange = (value: InputSearchSelectItemType) => {
    if (!value) return

    // String has to be split because backend selects supplier by name!
    onSelect({
      __uuid: value.id as string,
      value: value.label?.split('||')[0]?.trimEnd(),
    })
  }

  const suppliers: SupplierUserSearchType[] | undefined = searchedSuppliers?.data.hits.map(
    (hit: HitsType<SupplierUserSearchType>) => hit.document
  )

  let options: InputSearchSelectItemType[] =
    suppliers?.map((supplier: SupplierUserSearchType) => ({
      id: Number(supplier.supplier.id),
      label: `${supplier.supplier.name} || ${supplier?.first_name || ''} ${supplier.last_name || ''} ${
        supplier?.email || 'No Email'
      }`,
    })) || []

  const activeValue = useMemo(
    () =>
      selectedValue && selectedValue.__uuid
        ? {
            id: selectedValue?.__uuid,
            label: selectedValue?.value as string,
          }
        : undefined,
    [selectedValue]
  )

  // Move the selected option to the top of the options list, if it isn't in the list, add it to the top
  const selectedIndex = options.findIndex(
    (option: InputSearchSelectItemType) => option.id?.toString() === activeValue?.id.toString()
  )

  // If the selected option isn't in the list, add it to the top
  if (selectedIndex === -1) {
    options.unshift({
      id: activeValue?.id,
      label: activeValue?.label,
    } as InputSearchSelectItemType)
  }

  options = options.filter(({ id }) => !!id)

  // If the selected option is not at the top of the list, move it to the top
  if (selectedIndex > 0) {
    const [selectedOption] = options.splice(selectedIndex, 1)
    options.unshift(selectedOption)
  }

  return (
    <SearchSelect
      onChange={onChange}
      isLoading={isSearching && (!searchedSuppliers || searchedSuppliers.data.length === 0)}
      options={options}
      addButtonText={t('question.new_supplier')}
      onAdd={openNewSupplierModal}
      onInputChange={(value) => {
        setSearchQuery(value || '')
      }}
      currentSelection={activeValue}
      placeholder={t('question.enter_supplier')}
      nullable
      onClear={() => onSelect(null)}
      {...searchSelectProps}
    />
  )
}

export default SupplierField
