import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../core/providers/FormProvider'
import Tooltip from '../../../../../shared/components/organisms/tooltip/Tooltip'
import QuestionSliderSettingRow from '../components/inputs/QuestionSliderSettingRow'
import QuestionInputWrapper from '../components/QuestionInputWrapper'
import QuestionSubSection from '../components/QuestionSubSection'

const QuestionHintsSetting: React.FC = () => {
  const { t } = useTranslation()
  const {
    question: [question],
    updateQuestion,
  } = useFormContext()

  const updateHint = (hint: string | null) => updateQuestion({ hint })

  const questionHint = question?.hint
  const isChecked = typeof questionHint === 'string'

  if (question?.type === 'FILE') return null

  return (
    <QuestionSubSection title={t('forms.question.settings.hint')}>
      {isChecked && (
        <Tooltip tooltip={questionHint || ''} showCondition>
          <p className="underline">{t('forms.tooltip_preview')}</p>
        </Tooltip>
      )}
      <QuestionSliderSettingRow
        title="Display question hints for this question"
        onChange={() => (isChecked ? updateHint(null) : updateHint(''))}
        checked={isChecked}
      />
      {isChecked && (
        <QuestionInputWrapper>
          {(inputProps) => (
            <textarea
              {...inputProps}
              placeholder="Enter question hints here..."
              value={questionHint}
              onChange={(e) => updateHint(e.currentTarget.value)}
              className="active:border-sky-blue-light p-4 w-full rounded-md border-none shadow placeholder:text-black-light placeholder:opacity-50"
            />
          )}
        </QuestionInputWrapper>
      )}
    </QuestionSubSection>
  )
}

export default QuestionHintsSetting
