import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'

interface ITable extends INodeChildren {
  dataCy?: string
  isLoading?: boolean
}
const Table: React.FC<ITable> = ({ children, dataCy, isLoading }) => (
  <tbody className="table-row-group " data-cy={dataCy}>
    {isLoading ? (
      <tr>
        <td colSpan={100}>
          <div className="py-4">
            <CoreLoadingIcon />
          </div>
        </td>
      </tr>
    ) : (
      children
    )}
  </tbody>
)

export default Table
