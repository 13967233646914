import { FormQuestionLogicType, FormQuestionType, FormSectionType } from '../../../../../../../types/Forms'
import useQuestionMutations from './useQuestionMutations'

export default (question: FormQuestionType, section: FormSectionType) => {
  const questionLogic = question.__logic
  const { updateQuestion } = useQuestionMutations(question.__uuid, section)

  const setFormLogic = (newLogic: FormQuestionLogicType | undefined | null) => {
    updateQuestion({ __logic: newLogic })
  }

  return [questionLogic, setFormLogic] as const
}
