import { Handle, HandleType, Position } from 'reactflow'
import { getHandleId } from '../../../helpers'

const positions = {
  source: Position.Right,
  target: Position.Left,
}

export type WorkflowChartNodeHandleProps = {
  id: string
  type: HandleType
  position?: Position
  alias?: HandleType | 'helper'
  style?: React.HTMLAttributes<HTMLDivElement>['style']
}

export const WorkflowChartNodeHandle = ({ id, type, alias, position, style }: WorkflowChartNodeHandleProps) => (
  <Handle
    type={type}
    isConnectable={false}
    position={position || positions[type]}
    id={getHandleId(id, alias || type)}
    style={{ border: 'none', background: 'none', ...(style || {}) }}
  />
)

export default WorkflowChartNodeHandle
