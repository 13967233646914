import { Dispatch, SetStateAction, useState } from 'react'
import ErrorMessage from '../../atoms/ErrorMessage'
import Input from './Input'
import FloatingElements from './InputSaveCancelButtons/FloatingElements'

export interface IInputWithSaveCancelButtons {
  title: string
  setTitle: Dispatch<SetStateAction<string>>
  onCancel: () => void
  onSave: () => void
  placeholder: string
  charLimit?: number
  charMin?: number
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  error?: string
  showError?: boolean
  disabled?: boolean
  className?: string
  inputClassName?: string
  focusOnLoad?: boolean
}

const InputWithSaveCancelButtons: React.FC<IInputWithSaveCancelButtons> = ({
  disabled,
  title,
  setTitle,
  showError,
  error,
  onCancel,
  onSave,
  className,
  placeholder,
  inputClassName,
  charLimit,
  charMin,
  focusOnLoad,
}) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className={`flex flex-col relative w-full gap-y-1 ${className}`}>
      <Input
        title={title}
        setTitle={setTitle}
        placeholder={placeholder}
        disabled={disabled}
        showError={showError}
        onCancel={onCancel}
        onSave={onSave}
        setIsActive={setIsActive}
        inputClassName={inputClassName}
        focusOnLoad={focusOnLoad}
      />
      {showError && error && <ErrorMessage error={error} className="text-sm absolute left-0 -bottom-5" />}
      <FloatingElements
        disabled={disabled}
        onCancel={onCancel}
        onSave={onSave}
        shouldDisplayButtons={isActive && !!title.length}
        setIsInputActive={setIsActive}
        inputValue={title}
        charLimit={charLimit}
        charMin={charMin}
      />
    </div>
  )
}

export default InputWithSaveCancelButtons
