import { ReactElement, useEffect } from 'react'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { useNavigationPrompt } from '../../../providers/NavigationPropmptProvider'

export default (when: boolean, content: ReactElement<any>, title: string) => {
  const { modal } = useUtils()
  const { setPrompt, removePrompt } = useNavigationPrompt()

  useEffect(() => {
    if (!when) {
      removePrompt()
      return
    }

    setPrompt((prompt) => {
      modal.set({
        isOpen: true,
        content,
        title,
        forwardingUrl: prompt.pathname,
        className: 'w-[650px]',
      })
      return 'true'
    })
  }, [when, setPrompt])
}
