import { useUtils } from '../../../../../core/providers/UtilsProvider'
import ExternalDataRelationshipHeader from '../ExternalDataRelationshipHeader'
import ExternalDataRelationshipBody from '../ExternalDataRelationshipBody'
import useCurrentExternalDataSourceUuid from '../../../hooks/useCurrentExternalDataSourceUuid'
import {
  ExternalDataColumn,
  ExternalDataRelationship,
} from '../../../../../types/ExternalDataManager/ExternalDataManagerTypes'
import useExternalDataRelationshipMutations from '../../../hooks/useExternalDataRelationshipMutations'

export const useExternalDataRelationshipModal = (relationshipToEdit?: ExternalDataColumn) => {
  const { modal } = useUtils()
  const uuid = useCurrentExternalDataSourceUuid()
  const { create } = useExternalDataRelationshipMutations()

  const handleSubmit = (relationship: ExternalDataRelationship) => create(relationship)

  return () => {
    modal.set({
      isOpen: true,
      title: <ExternalDataRelationshipHeader />,
      content: (
        <ExternalDataRelationshipBody
          initialData={{
            source_uuid: uuid,
            source_column: relationshipToEdit?.column_name || undefined,
            target_uuid: relationshipToEdit?.foreign_uuid || undefined,
            target_column: relationshipToEdit?.foreign_column_name || undefined,
          }}
          onSubmit={handleSubmit}
        />
      ),
      className: 'max-w-[500px]',
    })
  }
}

export default useExternalDataRelationshipModal
