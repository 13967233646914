import { useApiDelete } from '../../../core/utils/queryHooks'
import companyQueryStrings from '../constants/companyQueryStrings'
import { apiDeleteAddress } from '../services/CompanyAddressesService'

const useDeleteAddress = ({
  addressId,
  onSuccess,
  onError,
}: {
  addressId: number
  onSuccess: () => void
  onError: () => void
}) =>
  useApiDelete(addressId, {
    queryId: companyQueryStrings.company_addresses,
    request: apiDeleteAddress,
    onSuccess,
    onError,
  })

export default useDeleteAddress
