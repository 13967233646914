import { useTranslation } from 'react-i18next'
import { HiArrowLeft } from 'react-icons/hi'
import useLoginMutation from '../hooks/useLoginMutation'
import { FormFieldType } from '../../shared/Types'
import AuthForm, { emailValidator, passwordValidator } from '../../shared/Components/AuthForm'

type EmailLoginFormProps = {
  onBackButtonClick: () => void
}

const EmailLoginForm = ({ onBackButtonClick }: EmailLoginFormProps) => {
  const { t } = useTranslation()

  const { mutate, isLoading } = useLoginMutation()

  const form: FormFieldType[] = [
    {
      attribute: 'email',
      name: t('generic.email', 'Email'),
      placeholder: t('generic.email_placeholder', 'Email'),
      value: '',
      type: 'text',
      validator: emailValidator,
    },
    {
      attribute: 'password',
      name: t('generic.password', 'Password'),
      placeholder: t('generic.password_placeholder', 'Password'),
      value: '',
      type: 'password',
      validator: passwordValidator,
    },
  ]

  const onSubmit = (data: any) => mutate(data)
  const onError = (data: any) => console.log(data)

  return (
    <div>
      <AuthForm
        fields={form}
        onSubmit={onSubmit}
        onError={onError}
        isSubmitting={isLoading}
        btnText={t('generic.sign_in', 'Sign in')}
      />
      <button type="button" className="text-black-light-2 mt-5 text-sm hover:text-sky-blue" onClick={onBackButtonClick}>
        <HiArrowLeft className="inline-block mr-2" />
        {t('generic.more-login-options', 'Other sign in options')}
      </button>
    </div>
  )
}

export default EmailLoginForm
