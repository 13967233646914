import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import QuestionItemInput from './QuestionItemInput'
import { getIndexedGenericOrEmptyLabels } from '../../../constants/labels'

type QuestionLabelInputProps = {}

const QuestionLabelInput: React.FC<QuestionLabelInputProps> = () => {
  const { t } = useTranslation()

  const {
    question: [question],
    updateQuestion,
  } = useFormContext()

  const canEdit = question?.actions?.edit

  const handleChange = (label: string) => {
    const questionPatch = {
      question_label: label,
      values: question?.values && getIndexedGenericOrEmptyLabels(question, label),
      options: question?.options?.map((option) => ({ ...option, label })),
    }
    updateQuestion(questionPatch)
  }

  if (question?.isFormElement) return null

  return (
    <QuestionItemInput
      title={t('question_builder.question_label')}
      placeholder={t('question_builder.enter_question_label')}
      property="question_label"
      disabled={!canEdit}
      onChange={handleChange}
    />
  )
}

export default QuestionLabelInput
