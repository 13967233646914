import { useMutation, useQuery, useQueryClient } from 'react-query'
import apiClient from '../../../core/utils/apiClient'
import { CustomDataSchemaFieldType, DataSchemaViewResponseType } from '../../Settings/Views/Schemas/useSchemaService'
import keys from '../../Settings/Views/Profile/constants/settingsQueryKeys'

const endpoints: { [key: string]: (schemaUuidOrEntity?: string) => string } = {
  completePOST: () => '/onboarding/users',
  onboardingGET: () => '/onboarding/users',
}

export type DataSchemaServiceDetailsMutationType = {
  custom_fields: CustomDataSchemaFieldType[]
  default_fields: CustomDataSchemaFieldType[]
}

export const useOnboardingDetails = (entity: string) =>
  useQuery(['onboarding', entity], () =>
    apiClient.get<{ data: DataSchemaViewResponseType }>(endpoints.onboardingGET(entity)).then(({ data }) => data?.data)
  )

export const useOnboardingCompleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (newSchema: DataSchemaServiceDetailsMutationType) =>
      apiClient.post(endpoints.completePOST(), newSchema).then(({ data }) => data),
    {
      onSuccess: () => {
        !!queryClient.invalidateQueries([keys.USER_PROFILE])
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )
}
