import { useTranslation } from 'react-i18next'
import SupplierUsers from '../components/SupplierUsers'
import SupplierAddress from '../components/SupplierAddress'
import SupplierInformation from '../components/SupplierInformation/SupplierInformation'

const GeneralTabBody = () => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full min-w-full flex-col gap-y-6">
      <SupplierInformation />
      <SupplierAddress />
      <div className="flex flex-col gap-y-4">
        <p className="font-semibold">{t('suppliers.supplier_users')}</p>
        <SupplierUsers />
      </div>
    </div>
  )
}

export default GeneralTabBody
