import { useTranslation } from 'react-i18next'
import BaseSettingsSection from '../../components/BaseSettingsSection'
import CreateNewWorkflowButton from '../../../Workflow/components/misc/CreateNewWorkflowButton'
import WorkflowTable from './Table/WorkflowTable'

const WorkflowListTab: React.FC = () => {
  const { t } = useTranslation()
  return (
    <BaseSettingsSection
      title={t('generic.workflows', 'Workflows')}
      description={t(
        'generic.workflows_description',
        'Workflows are a series of steps that can be used to automate the intake process.'
      )}
      action={
        <div className="w-full ">
          <CreateNewWorkflowButton />
        </div>
      }
    >
      <div className="overflow-hidden">
        <WorkflowTable />
      </div>
    </BaseSettingsSection>
  )
}

export default WorkflowListTab
