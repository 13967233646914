import { AbilityBuilder, defineAbility } from '@casl/ability'

export const FEATURE_FLAGS = {
  mercateoSearch: 'useFeatureFlag.mercateoSearch', // ???
  larrySearch: 'useFeatureFlag.larrySearch', // ???
  hideRequisitionDefinition: 'useFeatureFlag.hideRequisitionDefinition', // only EON ???
  seeDefaultCreateRequisitionComponents: 'useFeatureFlag.seeDefaultCreateRequisitionComponents', // only Fraport
  sapIntegrationActive: 'useFeatureFlag.sapIntegrationActive',
  submissionsOnly: 'useFeatureFlag.submissionsOnly',
  intakeFlowSubmission: 'useFeatureFlag.intakeFlowSubmission',
}

export const ABILITIES = {
  editAnySupplierOffer: 'editAnySupplierOffer', // ???
  editPortfolioTags: 'editPortfolioTags',
  editInputMetadata: 'canEditInputMetadata',
  ...FEATURE_FLAGS,
}

export const userRoles = {
  ADMIN: 'admin',
  PURCHASER: 'purchaser',
  REQUESTOR: 'requestor',
  SUPER_ADMIN: 'super_admin',
  test: 'TEST',
}

export function updateAbility(ability, user, featureFlags) {
  const { can, rules, cannot } = new AbilityBuilder()
  const userRole = user?.role
  const features = Object.keys(featureFlags || {})

  // SUPERADMIN, ADMIN, PURCHASER, TEST
  if ([userRoles.ADMIN, userRoles.PURCHASER, userRoles.SUPER_ADMIN, userRoles.test].includes(userRole)) {
    // SUPERADMIN, ADMIN, TEST
    if ([userRoles.SUPER_ADMIN, userRoles.ADMIN, userRoles.test].includes(userRole)) {
      can([ABILITIES.editAnySupplierOffer, ABILITIES.editPortfolioTags])
    }

    if (window.location.host.includes('fraport')) {
      cannot([ABILITIES.hideRequisitionCreation])
    }

    // SUPERADMIN, TEST
    if ([userRoles.SUPER_ADMIN, userRoles.test].includes(userRole)) {
      can([ABILITIES.seeActionPanelActions, ABILITIES.addApprovalStep, ABILITIES.editInputMetadata])

      // TEST
      if ([userRoles.test].includes(userRole)) {
        Object.values(FEATURE_FLAGS).map((feature) => can(feature))
      }
    }
  }

  // Enable feature Flags
  const filteredFeatures = features?.filter((key) => featureFlags[key] === true)

  filteredFeatures.map((feature) => can(`useFeatureFlag.${feature}`))
  ability.update(rules)
}

export default defineAbility(() => {})
