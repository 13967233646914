import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import QuestionSubSection from '../../components/QuestionSubSection'
import FileQuestionSettings from './FileQuestionSettings'
import IntakeOverviewSettings from './IntakeOverviewSettings'
import LabelSettings from './LabelSettings'
import MiscSettings from './MiscSettings'
import OptionSettings from './OptionSettings'
import UserSettings from './UserSettings'
import PromptSettings from './PromptSettings'

const OtherSettings: React.FC = () => {
  const { t } = useTranslation()
  const {
    question: [question],
  } = useFormContext()

  if (!question?.type) return null

  return (
    <>
      <QuestionSubSection title={t('forms.settings.user_section')}>
        <UserSettings />
      </QuestionSubSection>
      <LabelSettings />
      <OptionSettings />
      <MiscSettings />
      <FileQuestionSettings />
      <IntakeOverviewSettings />
      <PromptSettings />
    </>
  )
}

export default OtherSettings
