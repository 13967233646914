import { Crisp } from 'crisp-sdk-web'
import { useLocalStorage } from '../providers/LocalStorageProvider'
import { UserType } from '../../types/shared/AssignmentTypes'
import { IS_DEVELOPMENT } from '../../shared/constants/environments'

export default function useCrispChat() {
  const [getLocalStorage] = useLocalStorage()
  const User: UserType | undefined | null = getLocalStorage('lhotseAuth')

  if (!process.env.REACT_APP_CRISP_WEBSITE_ID || IS_DEVELOPMENT) {
    return
  }

  Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID || '')

  if (User?.email) {
    Crisp.user.setEmail(User.email)
    Crisp.user.setNickname(`${User.first_name ?? ''} ${User.last_name ?? ''}`)
    Crisp.user.setCompany(User.tenant ?? '', {})
  }
}
