import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'
import { useUtils } from '../../../core/providers/UtilsProvider'
import Button from '../../../shared/components/atoms/buttons/Button'
import RowCell from '../../../shared/components/layout/RowCell'
import { toastr } from '../../../shared/components/organisms/toastr'
import AddressFormTextInput from '../../Settings/Views/General/addresses/AddressForm/AddressFormTextInput'
import SupplierDetailsModalHeader from '../../SupplierDetails/SupplierDetailsModal/SupplierDetailsModalHeader'
import { useSuppliersProvider } from '../providers/SuppliersProvider'
import { apiPostSupplierBranch } from '../services/SupplierBranchesService'
import SupplierDetailsModal from '../../SupplierDetails/SupplierDetailsModal/SupplierDetailsModal'
import SupplierBranchDataType from '../../../types/Suppliers/SupplierBranchDataType'
import queryKeys from '../constants/SuppliersQueryKeys'

export interface NewSupplierType {
  name: string
  email: string
  phone: string
}

type SupplierNewModalProps = {
  onFinish?: (newSupplier: SupplierBranchDataType) => void
}

const SupplierNewModal: React.FC<SupplierNewModalProps> = ({ onFinish }) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const queryClient = useQueryClient()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    phone: Yup.string()
      .matches(/^\+\d+$/, t('error.invalid_phone_number'))
      .min(4, t('error.invalid_phone_number')),
  })

  const initialValues = {
    name: '',
    phone: '',
  }

  const newSupplierMutation = useMutation((body: NewSupplierType) => apiPostSupplierBranch(body))
  const {
    supplierBranchId: [, setSupplierBranchId],
  } = useSuppliersProvider()

  const submitNewSupplier = (formValues: NewSupplierType) => {
    newSupplierMutation.mutate(formValues, {
      onSuccess: (newSupplier) => {
        queryClient.invalidateQueries([queryKeys.SUPPLIERS])
        setSupplierBranchId(newSupplier.id)
        modal.set({
          title: <SupplierDetailsModalHeader supplierBranchId={newSupplier.id} />,
          isOpen: true,
          content: <SupplierDetailsModal />,
        })
        toastr.success('success.supplier_created', 'supplier_creation')
        onFinish && onFinish(newSupplier)
      },
      onError: () => {
        toastr.error('error.item_not_created', 'supplier_creation')
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues as NewSupplierType}
      validationSchema={validationSchema}
      onSubmit={submitNewSupplier}
      validateOnBlur={false}
    >
      {({ touched, values, handleChange, handleSubmit, errors }) => {
        const constants = {
          values,
          errors,
          handleChange,
          touched,
        }

        return (
          <Form>
            <div className="flex flex-col">
              <RowCell width="w-full items-center">
                <AddressFormTextInput
                  constants={constants}
                  placeholder={t('supplier_view.supplier_name')}
                  label="generic.name"
                  errorKey="name"
                  className="w-full"
                  dataCy="supplierNameInput"
                />
              </RowCell>
              <RowCell width="w-full items-center">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="supplier@email.com"
                  label="generic.email"
                  errorKey="email"
                  className="w-full"
                  dataCy="supplierEmailInput"
                />
              </RowCell>
              <RowCell width="w-full items-center">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="+49123456789"
                  phoneNumber
                  label="supplier_profile.phone_number"
                  errorKey="phone"
                  className="w-full"
                  dataCy="supplierPhoneInput"
                />
              </RowCell>
              <RowCell width="w-full" className="justify-end">
                <Button
                  color="lhotse-orange"
                  variant="solid"
                  onClick={handleSubmit}
                  className="text-sm"
                  dataCy="submitNewSupplier"
                >
                  {t('generic.next')}
                </Button>
              </RowCell>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SupplierNewModal
