import { useTranslation } from 'react-i18next'
import SectionHeader from '../../../shared/components/layout/SectionHeader'
import SupplierAddress from './SupplierAddress'
import SupplierInformation from './SupplierInformation/SupplierInformation'

const SupplierDetails = () => {
  const { t } = useTranslation()

  return (
    <div className="px-7">
      <div className="bg-white rounded-lg">
        <SectionHeader title={t('supplier_offer.supplier_details')} />
        <div className="p-7 gap-y-6 flex flex-col">
          <SupplierInformation editable />
          <SupplierAddress editable />
        </div>
      </div>
    </div>
  )
}

export default SupplierDetails
