import { DropResult } from 'react-beautiful-dnd'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateForm from '../../../hooks/useUpdateForm'

export default () => {
  const { data: formData } = useGetFormData()
  const { updateForm } = useUpdateForm()

  return (result: DropResult) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index

    if (!formData || destinationIndex === undefined) return

    const newSections = formData.template.sections
    const item = newSections.splice(sourceIndex, 1)
    newSections.splice(destinationIndex, 0, item[0])

    updateForm({ template: { sections: newSections } })
  }
}
