import classnames from 'classnames'
import { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { filterPassedTime } from '../../../constants/dateFormat'
import BaseInputWrapper from '../../atoms/Input/BaseInputWrapper'
import './react-datepicker.scss'
import registerCustomLocale from './registerCustomLocale'

interface ICalendar {
  onChange(date: Date, event: React.SyntheticEvent<any, Event> | undefined): void
  disabled?: boolean
  minDate?: Date
  maxDate?: Date
  onBlur?(event: React.FocusEvent<HTMLInputElement, Element>): void
  selected?: Date | null | undefined
  className?: string
  wrapperClassName?: string
  inputClassNames?: string | null
  dataCy?: string
  shouldFilterTime?: boolean
  dateFormat?: string
  placeholder?: string
  showTimeSelect?: boolean
  label?: string
}

const Calendar: React.FC<ICalendar> = ({
  disabled,
  minDate,
  onBlur,
  onChange,
  selected,
  maxDate,
  shouldFilterTime,
  dateFormat,
  placeholder,
  showTimeSelect = true,
  label,
}) => {
  const [date, setDate] = useState<Date | undefined>(selected || undefined)

  useEffect(() => {
    setDate(selected || undefined)
  }, [])

  const handleChange = (newDate: Date, e: React.SyntheticEvent<any, Event> | undefined) => {
    setDate(newDate)
    onChange && onChange(newDate, e)
  }

  registerCustomLocale()

  return (
    <BaseInputWrapper label={label}>
      <ReactDatePicker
        placeholderText={placeholder || ''}
        previousMonthButtonLabel="<"
        nextMonthButtonLabel=">"
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        shouldCloseOnSelect
        dateFormat={dateFormat || 'dd.MM.yyyy HH:mm'}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        onBlur={onBlur}
        className={classnames('base-form-input ')}
        onChange={handleChange}
        selected={date}
        locale="de"
        filterTime={(time) => filterPassedTime(minDate, shouldFilterTime, time)}
      />
    </BaseInputWrapper>
  )
}

export default Calendar
