import { INodeChildren } from '../../../../types/shared/GeneralTypes'

interface ITableHead extends INodeChildren {
  className?: string
}

const TableHead: React.FC<ITableHead> = ({ children, className }) => (
  <thead className={`table-header-group ${className}`}>
    <tr>{children}</tr>
  </thead>
)

export default TableHead
