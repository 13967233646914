import { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import ChipMenu from '../../core/components/ChipMenu/ChipMenu'
import ChipMenuItem from '../../core/components/ChipMenu/ChipMenuItem'
import Layover from '../../core/components/Overlay/Overlay'
import OnboardingStepContent from './Components/OnboardingStepContent'
import useOnboardingStore, { SectionType, StoreState } from './Store/useOnboardingStore'
import useUrlParams from '../LhotseHome/hooks/useUrlParams'
import { toastr } from '../../shared/components/organisms/toastr'
import { useOnboardingCompleteMutation, useOnboardingDetails } from './Service/useOnboardingService'
import { useLocalStorage } from '../../core/providers/LocalStorageProvider'
import { UserType } from '../../types/shared/AssignmentTypes'

const onboardingSteps: Omit<SectionType, 'id'>[] = [
  {
    title: 'onboarding.welcome_step_title',
    description: 'onboarding.welcome_step_description',
    stepName: 'onboarding.welcome_step_name',
    fields: [],
  },
  {
    title: 'onboarding.required_fields_step_title',
    description: 'onboarding.required_fields_step_description',
    stepName: 'onboarding.required_fields_step_name',
    fields: [],
  },
]

const OnboardingPage: FC = () => {
  const { getParam, setParam } = useUrlParams()
  const history = useHistory()
  const { t } = useTranslation()
  const routeParam = Number(getParam('step')) && !Number.isNaN(getParam('step')) ? Number(getParam('step')) : undefined
  const activeSectionIndex: number | undefined = useOnboardingStore((state) => (state as StoreState).activeSectionIndex)
  const sections = useOnboardingStore((state) => (state as StoreState).sections)
  const currentSection: SectionType | null = useOnboardingStore((state) => (state as StoreState).currentSection)
  const setSections = useOnboardingStore((state) => (state as StoreState).setSections)
  const changeCurrentSection = useOnboardingStore((state) => (state as StoreState).changeCurrentSection)
  const nextSection = useOnboardingStore((state) => (state as StoreState).nextSection())
  const details = useOnboardingDetails('users')
  const onboardingCompleteMutation = useOnboardingCompleteMutation()
  const deleteEverything = useOnboardingStore((state) => (state as StoreState).deleteEverything)
  const [getLocalStorage, setLocalStorage] = useLocalStorage()
  const userData: UserType | undefined = getLocalStorage('lhotseAuth')

  useEffect(() => {
    if (!routeParam || routeParam > sections.length) {
      deleteEverything()
      setParam({ step: '1' })
      return
    }
    changeCurrentSection(routeParam)
  }, [routeParam])

  useEffect(() => {
    if (!sections.length && details.data) {
      const calData: SectionType[] = onboardingSteps.map((section: Omit<SectionType, 'id'>, index: number) => {
        const newId = index + 1
        return {
          ...section,
          stepName: t(section.stepName),
          title: t(section.title),
          description: t(section.description),
          fields:
            newId === 1
              ? details.data.default_fields.map((item) => ({ ...item, name: t(item.name) }))
              : details.data.custom_fields,
          id: newId,
        }
      })
      setSections(calData!!)
    }
  }, [details])

  async function changeSection(): Promise<void> {
    if (nextSection) {
      setParam({ step: nextSection?.id.toString() })
      return
    }

    const result = await onboardingCompleteMutation.mutateAsync({
      default_fields: sections[0].fields,
      custom_fields: sections[1]?.fields ?? [],
    })

    if (result.isError) {
      toastr.error(result.error.message)
    }

    if (userData) {
      setLocalStorage('lhotseAuth', {
        ...userData,
        onboarded_at: new Date().toString(),
      })
    }

    toastr.success(t('onboarding.success', 'Onboarding completed successfully'))
    deleteEverything()
    history.push('/settings/profile')
  }

  return (
    <Layover>
      <div className="h-full w-full flex items-top justify-center py-9">
        <div className="flex flex-col space-y-16 w-4/12">
          <ChipMenu>
            {() =>
              sections
                .filter((section) => section.fields.length)
                .map((step, index) => (
                  <ChipMenuItem
                    classNameOrder="cursor-default select-none"
                    classNameText="cursor-default select-none"
                    key={step.stepName}
                    name={step.stepName}
                    isActive={step.id === activeSectionIndex}
                    order={step.id}
                  />
                ))
            }
          </ChipMenu>
          <div className="py-6 px-20">
            {currentSection ? <OnboardingStepContent onUpdate={() => changeSection()} /> : null}
          </div>
        </div>
      </div>
    </Layover>
  )
}

export default OnboardingPage
