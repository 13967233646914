import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cn from '../../../lib/util'
import Tooltip from '../organisms/tooltip/Tooltip'

interface IStatusIndicator {
  status?: string
  className?: string
  textClassName?: string
}

interface StatusEntry {
  color: string
  text: string
}

// Define a type for the status keys
type StatusKey = 'error' | 'cancelled' | 'draft' | 'ongoing' | 'completed'

const StatusIndicatorWithTooltip: FC<IStatusIndicator> = ({ status, className, textClassName }) => {
  const { t } = useTranslation()

  // Define the status map with the StatusKey type as its keys
  const statusMap: Record<StatusKey, StatusEntry> = {
    error: {
      color: 'bg-red-600',
      text: t('status.error', 'Error'),
    },
    cancelled: {
      color: 'bg-red-600',
      text: t('status.cancelled', 'Cancelled'),
    },
    draft: {
      color: 'bg-yellow-500',
      text: t('status.draft', 'Draft'),
    },
    ongoing: {
      color: 'bg-yellow-500',
      text: t('status.ongoing', 'Ongoing'),
    },
    completed: {
      color: 'bg-green-500',
      text: t('status.completed', 'Completed'),
    },
  }

  const defaultStatus: StatusEntry = {
    color: 'bg-gray-300',
    text: '',
  }

  // Ensure that request.status is a valid status key
  const statusKey: StatusKey | undefined = status as StatusKey

  // Use the statusKey to index into statusMap safely
  const { color: statusIndicatorColour, text: statusText } = statusKey ? statusMap[statusKey] : defaultStatus

  return (
    <Tooltip tooltip={statusText} showCondition={!!statusText}>
      <div className={cn('flex h-2 w-2 rounded-full', statusIndicatorColour)} />
    </Tooltip>
  )
}

export default StatusIndicatorWithTooltip
