import { FcGoogle } from 'react-icons/fc'
import { HiOutlineMail } from 'react-icons/hi'
import { SiMicrosoftazure, SiOkta } from 'react-icons/si'
import cn from '../../../../../lib/util'

const Icons = {
  google: <FcGoogle className="h-5 w-5" />,
  email: <HiOutlineMail className="h-6 w-6" />,
  azure: <SiMicrosoftazure style={{ color: '#008AD7' }} className="h-5 w-5" />,
  okta: <SiOkta className="h-6 w-6" />,
}

export type IconsType = keyof typeof Icons

type ProviderButtonProps = {
  type: IconsType
  name: string
  url?: string
  onClick: (type: { type: IconsType; name: string; url?: string }) => void
  className?: string
}

const ProviderButton = ({ type, name, onClick, className, url }: ProviderButtonProps) => (
  <button
    type="button"
    className={cn(
      'flex justify-center items-center border rounded-md py-3 ' +
        'w-full hover:border-sky-blue hover:text-sky-blue shadow-sm font-medium hover:font-bold',
      className
    )}
    onClick={() => onClick({ type, name, url })}
  >
    <div className="mr-4">{Icons[type] ?? null}</div>
    {name}
  </button>
)
export default ProviderButton
