import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import TableCell from '../../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../../../shared/components/atoms/SharedTable/TableRow'
import { priceToInternationalPriceString } from '../../../../../../../shared/constants/currencyFormat'
import { Offering } from '../../../../../../../types/Offering'
import useIntakeFormStore from '../../../../../IntakeFormResponse/stores/useIntakeFormStore'

interface IIntakeOfferingSectionMainTableRow {
  offering: Offering
  children?: React.ReactNode
  actions?: React.ReactNode
}

const IntakeOfferingSectionMainTableRow: React.FC<IIntakeOfferingSectionMainTableRow> = ({
  offering,
  children,
  actions,
}) => {
  const isDraftIntake = useIntakeFormStore((state) => state.intake?.status === 'draft')
  const isInLastSection = useIntakeFormStore(
    (state) => state.intake && state.activeSectionIndex === state.intake.sections.length - 1
  )
  const errors = useIntakeFormStore((state) => state.errors)
  const showErrors = useIntakeFormStore((state) => state.showErrors)

  const hasErrors =
    errors && showErrors && offering.fields?.some((field) => Object.keys(errors).includes(field.uuid || ''))

  // FIXME: Filter out Questions that are not allowed by logic. This is especially important for the product quantity field.
  // const enabledQuestionsUuids = useGetLogicAllowedQuestions()

  const [childVisible, setChildVisible] = useState<boolean>(isDraftIntake && !isInLastSection)
  useEffect(() => {
    // If the intake is not a draft, we don't want to show the children by default
    setChildVisible(isDraftIntake && !isInLastSection)
  }, [isDraftIntake, isInLastSection])

  const selectedOffer = offering.offers?.find((o) => o.is_selected)
  const productName =
    (offering?.fields?.find((f) => f.input?.key === 'prod-name' && f.visible)?.value?.value as string) || '-'

  const productQuantityFields = offering?.fields?.filter((f) => f.input?.key === 'prod-quantity' && f.visible)
  const productQuantity = (productQuantityFields?.find((f) => f.value?.value && f.value.value > 0)?.value?.value ||
    1) as string

  const productPriceFields = offering?.fields?.filter((f) => f.input?.key === 'prod-price' && f.visible)
  const productPriceField = productPriceFields?.find((f) => f.value?.value && f.value.value > 0)

  const costCenter =
    (offering?.fields?.find((f) => f.input?.key === 'prod-cost_center' && f.visible)?.value?.value as string) ||
    (offering?.fields?.find((f) => f.input?.key === 'saps4-costcenter' && f.visible)?.value?.value as string) ||
    (offering?.fields?.find((f) => f.input?.key === 'saps4-orderid' && f.visible)?.value?.value as string) ||
    (offering?.fields?.find((f) => f.input?.key === 'saps4-wbselement' && f.visible)?.value?.display_name as string) ||
    '-'

  const supplier =
    selectedOffer?.offer?.supplier_branch?.name ||
    (offering?.fields?.find((f) => f.input?.key === 'prod-supplier' && f.visible)?.value?.value as string) ||
    '-'

  const getPrice = () => {
    if (selectedOffer) {
      return priceToInternationalPriceString(selectedOffer?.total_net_price, selectedOffer?.currency_code)
    }

    const price = (productPriceField?.value?.value as number) || 0
    const currencyId = (productPriceField?.value?.currencyId as string) || 'EUR'
    return priceToInternationalPriceString(parseInt(productQuantity, 10) * price, currencyId) || 0
  }

  const price = getPrice()

  return (
    <>
      <TableRow
        key={offering.uuid}
        onClick={() => setChildVisible(!childVisible)}
        active={childVisible}
        className={`sticky z-10 cursor-normal hover:bg-gray-100 transition-colors ${
          !isDraftIntake ? 'top-12' : 'top-0'
        } ${hasErrors ? 'border-l-2 border-red-500 rounded' : null}`}
      >
        <TableCell>
          <div className="flex">
            <div className="flex flex-col">
              <span className="font-semibold whitespace-nowrap">
                {selectedOffer?.quantity || productQuantity || 1} x
              </span>
              <span className="text-xs leading-6">&nbsp;</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className="flex">
            <div className="flex flex-col">
              <span className="font-semibold">{productName}</span>
              <span className="text-xs leading-6">{costCenter}</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className="flex-col ">
            <div>{supplier}</div>
            <div className="flex-col  text-xs leading-6">{price}</div>
          </div>
        </TableCell>
        <TableCell className="text-right">
          <div className="flex justify-end gap-x-2">
            {selectedOffer?.is_indicative && (
              <div className="px-3 text-xs text-white bg-sky-blue-light rounded-full flex items-center">Indicative</div>
            )}

            {actions}
            {children && childVisible ? <ChevronUpIcon className="w-5" /> : <ChevronDownIcon className="w-5" />}
          </div>
        </TableCell>
      </TableRow>

      {childVisible && (
        <TableRow role="row">
          <TableCell className="border-t-2 border-sky-blue-light border-collapse py-8" colSpan={4}>
            {children}
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default IntakeOfferingSectionMainTableRow
