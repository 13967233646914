import { useParams } from 'react-router'
import { MessageModelType } from '../../../types/Messenger/types'

export default () =>
  useParams<{
    modelId: string
    requisitionId: string
    modelType: MessageModelType
    messageReferenceId?: string
  }>()
