import { useMemo } from 'react'
import { WorkflowBlock } from '../../../../../../../../types/Workflow/WorkflowBlock'
import useGetRequiredActionWorkflowBlocks from '../../../../../hooks/useGetRequiredActionWorkflowBlocks'
import useCanApproveBlock from '../../../../../hooks/useCanApproveBlock'

export const useBlockStatus = (block: WorkflowBlock | null) => {
  const activeBlocks = useGetRequiredActionWorkflowBlocks()
  const canApprove = useCanApproveBlock()

  const isActive = useMemo(
    () => activeBlocks.some((activeBlock) => activeBlock.uuid === block?.uuid),
    [activeBlocks, block]
  )

  return useMemo(
    () => ({
      requiresCurrentUserApproval: block && canApprove(block),
      isApproved: block?.status === 'approved',
      isEditable: block?.status !== 'approved' && block?.status !== 'declined' && block?.status !== 'completed',
      isActive,
    }),
    [block, isActive, canApprove]
  )
}

export default useBlockStatus
