import dayjs from 'dayjs'
import {
  FormQuestionContentType,
  FormQuestionValue,
  SwitchLogic,
  SwitchLogicAdditionalCase,
  SwitchLogicCase,
} from '../../../../../../types/Forms'
import { IntakeFieldType } from '../../../../../../types/Intake'
import checkIfContains from './checkIfContains'
import checkIfEqual from './checkIfEqual'
import { checkHasAnyAnswer } from './helpers'

export type FormLogicQuestionType = {
  questionUUID: string
  questionType: FormQuestionContentType
  switchLogic?: SwitchLogic
  question: IntakeFieldType
}

export default (
  switchCase: SwitchLogicCase<string> | SwitchLogicAdditionalCase<string>,
  question: FormLogicQuestionType,
  questionAnswers: FormQuestionValue | null // TODO: change to not an array
) => {
  if (!questionAnswers) return false

  const { comparisonType, value: conditionValue } = switchCase

  const hasAnyAnswer = checkHasAnyAnswer(questionAnswers)

  if (comparisonType === 'empty') return !hasAnyAnswer

  if (comparisonType === 'not_empty') return hasAnyAnswer

  if (!hasAnyAnswer) return false

  if (comparisonType === 'equal') return checkIfEqual(question, questionAnswers, conditionValue)
  if (comparisonType === 'not_equal') return !checkIfEqual(question, questionAnswers, conditionValue)
  if (comparisonType === 'contains') return checkIfContains(question, questionAnswers, conditionValue)
  if (comparisonType === 'not_contains') return !checkIfContains(question, questionAnswers, conditionValue)

  if (comparisonType === 'starts_with') {
    return (questionAnswers?.value as string)?.startsWith(conditionValue)
  }

  if (comparisonType === 'ends_with') {
    return (questionAnswers?.value as string)?.endsWith(conditionValue)
  }

  if (comparisonType === 'greater_than') {
    if (question.questionType === 'DATE') {
      const dateString = questionAnswers?.value as string
      const dates = dateString.split(',')
      const startDate = dates[0]
      return dayjs(startDate).isAfter(conditionValue)
    }

    const number = Number(questionAnswers?.value as string)
    const comparison = Number(conditionValue)
    return number > comparison
  }

  if (comparisonType === 'less_than') {
    if (question.questionType === 'DATE') {
      const dateString = questionAnswers?.value as string
      const dates = dateString.split(',')
      const startDate = dates[0]
      return dayjs(startDate as string).isBefore(conditionValue)
    }

    const number = Number(questionAnswers?.value as string)
    const comparison = Number(conditionValue)
    return number < comparison
  }

  return false
}
