import { useMutation, useQueryClient } from 'react-query'
import { toastr } from '../../../../../shared/components/organisms/toastr'
import { undef } from '../../../../../types/shared/GeneralTypes'
import { UserType } from '../../../../../types/shared/AssignmentTypes'
import { replaceById } from '../../../../../shared/utils/arrayHelpers'
import useGetSupplierBranch from '../../../../SupplierDetails/hooks/useGetSupplierBranch'
import keys from '../../../constants/SuppliersQueryKeys'
import SupplierUserType from '../../../../../types/Suppliers/SupplierUserType'
import { apiUpdateSupplierBranchUser } from '../../../services/SuppliersService'

const useUpdateSupplierBranchUser = (hideForm: () => void, user: SupplierUserType | undefined) => {
  const cache = useQueryClient()
  const { data: supplierBranch } = useGetSupplierBranch()
  const supplierId = supplierBranch?.supplier_id
  const supplierBranchId = supplierBranch?.id

  return useMutation(
    (item: UserType) =>
      apiUpdateSupplierBranchUser({
        supplierGroupId: supplierId,
        supplierBranchId,
        userData: item,
        userId: user?.id,
      }),
    {
      onSuccess: (newData: SupplierUserType) => {
        hideForm()
        toastr.success('success.item_updated', 'successUserUpdate')
        cache.setQueryData<undef<SupplierUserType[]>>(
          [keys.SUPPLIER_USERS, supplierBranchId],
          (oldData: undef<SupplierUserType[]>) => replaceById(oldData, newData.id, newData)
        )
      },
      onError: () => {
        toastr.error('error.item_updated', 'errorSupplierUserUpdate')
      },
    }
  )
}

export default useUpdateSupplierBranchUser
