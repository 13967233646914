// REFACTOR
import { toastr } from '../../shared/components/organisms/toastr'
import { FileDataBody, FileType } from '../../types/shared/FileTypes'
import { apiDelete, apiUpload, getFile } from './api/generic'
import { getApiUrl } from './tenancy'

const IMAGE_FILE_TYPES = ['JPG', 'JPEG', 'PNG', 'APNG', 'AVIF', 'GIF', 'SVG', 'WEBP']
export const MAX_FILE_SIZE = 20000000 // 20MB

export const checkFileRules = (file: File, image?: boolean) => {
  const fileType = file.name.split('.')[file.name.split('.').length - 1].toUpperCase()

  if (image && !IMAGE_FILE_TYPES.includes(fileType)) {
    toastr.error('error.file_extension_image', 'fileNotImage')
    return false
  }

  if (file.size > MAX_FILE_SIZE) {
    toastr.error('error.file_size_50', 'fileTooLarge')
    return false
  }
  return true
}

export const postFile = async (
  file: File,
  model: FileDataBody['model'],
  modelId: number | string,
  collection?: string
): Promise<FileType | null> => {
  try {
    const formData = new FormData()
    formData.append('model', model)
    formData.append('model_id', modelId?.toString())
    formData.append('file', file)
    if (collection) {
      formData.append('collection', collection)
    }

    const url = `${getApiUrl()}/files`

    const {
      data: { data },
    } = await apiUpload(url, formData)

    return data
  } catch (error) {
    toastr.error('error.upload_failed', 'failedUpload')
    return new Promise(() => {})
  }
}

export const checkAndUploadFile = (
  file: File | null,
  model: FileDataBody['model'],
  modelId: number | string,
  collection?: string,
  image?: boolean
): Promise<FileType | null> => {
  if (file) {
    if (checkFileRules(file, image)) {
      return postFile(file, model, modelId, collection)
    }
  }
  return Promise.resolve(null)
}

export const deleteFile = async (file: FileType) => {
  try {
    await apiDelete(`/files/${file.name}`)
  } catch {
    toastr.error('error.failed_to_delete', 'failedDelete')
  }
}

type checkFileSubmittedFxnType = (newFiles: FileList, existingFiles: FileType[] | undefined) => File[]

export const filterForNonDuplicateFiles: checkFileSubmittedFxnType = (newFiles, existingFiles) => {
  if (existingFiles) {
    const fileNames = existingFiles?.map((file) => file.name)
    const fileListArray = Array.from(newFiles)
    return fileListArray.reduce((acc: File[], val) => {
      const isDuplicate = fileNames.includes(val.name)
      if (isDuplicate) {
        toastr.error('error.cannot_upload_duplicate_file', 'cannotUpload')
      }
      return isDuplicate ? acc : [...acc, val]
    }, [])
  }
  return Array.from(newFiles)
}

export const legacyDownloadFileFromURL = (fileUrl: string, fileName: string) => {
  const a = document.createElement('a')

  a.href = fileUrl
  a.target = '_blank'
  a.setAttribute('download', fileName)
  a.click()
}

export const downloadFileFromURL = async (fileUrl: string, fileName: string) => {
  const a = document.createElement('a')
  const link = getFile(fileUrl)
  link.then((s3Link: string) => {
    a.href = s3Link
    a.target = '_blank'
    a.setAttribute('download', fileName)
    a.click()
  })
}
