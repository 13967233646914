import WorkflowBuilderTriggers from '../WorkflowBuilderTriggers/WorkflowBuilderTriggers'
import WorkflowBuilderBlockApprovals from '../WorkflowBuilderApprovals/WorkflowBuilderBlockApprovals'
import WorkflowBuilderBlockControls from './WorkflowBuilderBlockControls'
import SidepanelTabTransition from '../../../../../shared/components/layout/SidepanelTabTransition'
import WorkflowBuilderBlockMisc from './WorkflowBuilderBlockMisc'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'
import WorkflowBuilderSidePanelDeadlineField from '../WorkflowBuilderSidepanelFields/WorkflowBuilderSidePanelDeadlineField'
import blockHasApprovers from '../../../helpers'

export const WorkflowBuilderSidepanelBlockDetails = () => {
  const { block } = useWorkflowBlockDraft()

  return (
    <SidepanelTabTransition>
      <div className="flex flex-col gap-11 ">
        <WorkflowBuilderBlockMisc />
        <WorkflowBuilderTriggers />
        {blockHasApprovers(block) && <WorkflowBuilderBlockApprovals />}
        <div>
          <WorkflowBuilderSidePanelDeadlineField />
        </div>
        <WorkflowBuilderBlockControls />
      </div>
    </SidepanelTabTransition>
  )
}

export default WorkflowBuilderSidepanelBlockDetails
