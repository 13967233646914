import cn from '../../../../lib/util'

interface TableContainerProps {
  disabled?: boolean
  border?: boolean
  children?: React.ReactNode
  emptyState?: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
}

const TableContainer: React.FC<TableContainerProps> = ({
  children,
  header,
  footer,
  disabled,
  border = true,
  emptyState,
}) => (
  <div
    className={cn('rounded-md flex flex-col items-center', border && 'shadow-sm  bg-white', disabled && 'bg-gray-100')}
  >
    {emptyState ? (
      <div className="text-center text-sm py-4">{emptyState}</div>
    ) : (
      <>
        {header}
        <table className="table table-auto w-full">{children}</table>
      </>
    )}
    {footer && <div className="m-2">{footer}</div>}
  </div>
)

export default TableContainer
