import { useTranslation } from 'react-i18next'
import AuthLayout from '../shared/AuthLayout'
import usePostForgotPassword from './usePostForgotPassword'
import { FormFieldType } from '../shared/Types'
import AuthForm, { emailValidator } from '../shared/Components/AuthForm'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const { mutate: submitResetPassword, isLoading } = usePostForgotPassword()

  const form: FormFieldType[] = [
    {
      attribute: 'email',
      name: t('generic.email', 'Email'),
      placeholder: t('generic.email_placeholder', 'Email'),
      value: '',
      type: 'text',
      validator: emailValidator,
    },
  ]

  const onSubmit = (data: any) => submitResetPassword(data)
  const onError = (data: any) => console.log(data)

  return (
    <AuthLayout title={t('generic.forgot_password', 'Forgot Password')} showBackButton>
      <AuthForm
        fields={form}
        onSubmit={onSubmit}
        isSubmitting={isLoading}
        onError={onError}
        btnText={t('generic.reset_password')}
      />
    </AuthLayout>
  )
}

export default ForgotPassword
