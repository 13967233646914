import { useTranslation } from 'react-i18next'
import { ShareIcon, Square2StackIcon } from '@heroicons/react/24/solid'
import { TableCell, TableRow } from '../../../../core/components/Table/Table'
import { InputTemplate } from '../../../../types/Forms'
import CompanyInputForm from '../../../CompanyInputs/CompanyInputForm'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import useInputTemplateName from '../../../CompanyInputs/hooks/useInputTemplateName'
import useInvalidateInputTypes from '../../../Forms/hooks/useInvalidateInputTypes'
import { apiDeleteCompanyInput } from '../../../IntakeConfiguration/services/CompanyInputsService'
import { toastr } from '../../../../shared/components/organisms/toastr'
import EditDeleteOptions from '../../../../shared/components/organisms/menus/EditDeleteMenu'

type InputTableRowProps = {
  input: InputTemplate
}

const InputTableRow = ({ input }: InputTableRowProps) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const getName = useInputTemplateName()
  const invalidateInputTemplates = useInvalidateInputTypes()
  const openInputEditModal = () => {
    modal.set({
      isOpen: true,
      title: t(`inputs_modal_title.${input ? 'update' : 'create'}`, `${input ? 'Update' : 'Create'} custom input type`),
      content: <CompanyInputForm input={input} />,
    })
  }

  const remove = async () => {
    if (!input.uuid) return

    const result = await apiDeleteCompanyInput(input.uuid)

    if (!result.success) {
      toastr.success('error.item_deleted', 'errorDelete')
      return
    }

    await invalidateInputTemplates()
    toastr.success('success.item_deleted', 'successDelete')
  }

  return (
    <TableRow
      key={`schema-${input.uuid}`}
      className="h-16 hover:bg-gray-200/25 text-black-light hover:cursor-pointer"
      onClick={() => openInputEditModal()}
    >
      <TableCell className="first-letter:uppercase">{getName(input)}</TableCell>
      <TableCell className="">
        <p className="lowercase first-letter:uppercase">{input.type}</p>
      </TableCell>
      <TableCell className="first-letter:uppercase">{input.key}</TableCell>
      <TableCell className="first-letter:uppercase">{input.custom_key}</TableCell>
      <TableCell className="first-letter:uppercase">
        <div className="flex space-x-6">
          {input?.children && input?.children?.length > 0 && (
            <div className="flex">
              <Square2StackIcon className="h-5 w-5 mr-2" />
              {input.children.length}{' '}
              {input.children.length === 1
                ? t('forms.input.single_relationship', 'input relationship')
                : t('forms.input.plural_relationship', 'input relationships')}
            </div>
          )}
          {input.is_in_use && (
            <div className="flex">
              <ShareIcon className="h-5 w-5 mr-2" />
              {t('forms.input.used_in_workflows', 'Used in workflows')}
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <EditDeleteOptions
          onEdit={() => openInputEditModal()}
          onDelete={remove}
          deleteDisabled={input.is_in_use || (input.children && input.children?.length > 0) || false}
        />
      </TableCell>
    </TableRow>
  )
}
export default InputTableRow
