import { NestedValues } from '../types/shared/UtilityTypes'

export const TrackingEvents = {
  navigation: {
    pageChange: 'Page navigation',
  },
} as const

export type TrackingEvent = NestedValues<typeof TrackingEvents>

export default TrackingEvents
