import { useTranslation } from 'react-i18next'
import { TrashIcon } from '@heroicons/react/24/outline'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import { WorkflowBlockTrigger } from '../../../../../../types/Workflow/WorkflowBlock'
import useWorkflowBlockDraftMutations from '../../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflowBlockDraft } from '../../../../providers/WorkflowBlockDraftProvider'
import { useWorkflow } from '../../../../providers/WorkflowProvider'
import WorkflowToggle from '../../../misc/WorkflowToggle'
import TriggerValueInput from './components/TriggerValueInput'
import TriggerComparisonSelector from './components/TriggerComparisonSelector'
import TriggerInputTypeSelector from './components/TriggerInputTypeSelector'

type WorkflowBuilderBlockTriggerFormProps = {
  trigger: WorkflowBlockTrigger
}

export const WorkflowBuilderBlockTriggerForm = ({ trigger }: WorkflowBuilderBlockTriggerFormProps) => {
  const { t } = useTranslation()

  const { readonly } = useWorkflow()
  const { removeTrigger, updateTrigger } = useWorkflowBlockDraftMutations()
  const { block } = useWorkflowBlockDraft()

  const handleRemove = () => {
    removeTrigger(trigger.uuid)
  }

  return (
    <div key={trigger.uuid} className="flex flex-col gap-6">
      {trigger.order === 1 && <h3 className="font-bold text-sm uppercase">{t('common.if', 'If')}</h3>}
      <div className="flex flex-col ">
        <TriggerInputTypeSelector trigger={trigger} />
        <div className="flex flex-row gap-x-4 text-sm">
          <div className="w-full mt-4">
            <TriggerComparisonSelector trigger={trigger} />
          </div>
          <TriggerValueInput trigger={trigger} />
        </div>
      </div>
      <div className="flex flex-row justify-between items-center">
        <WorkflowToggle
          onSelect={(condition_operator) =>
            updateTrigger({ ...trigger, condition_operator: condition_operator as 'AND' | 'OR' })
          }
          selected={trigger.condition_operator}
          options={['AND', 'OR']}
          render={(option) => option.toLowerCase()}
        />
        {!readonly && (
          <Button variant="ghost" className="text-black-light-2 bg-transparent !p-0" onClick={handleRemove}>
            <TrashIcon className="w-4" />
          </Button>
        )}
      </div>
      {trigger.order < block.triggers.length && (
        <h3 className="font-bold text-sm uppercase">
          {trigger.condition_operator === 'AND' ? t('common.and', 'And') : t('common.or', 'Or')}
        </h3>
      )}
    </div>
  )
}

export default WorkflowBuilderBlockTriggerForm
