import { useMemo } from 'react'
import { sortBy } from 'lodash'
import {
  WorkflowBlockApprover,
  WorkflowBlockReminder,
  WorkflowBlockTrigger,
} from '../../../types/Workflow/WorkflowBlock'
import { getUpdatedByProperty } from '../../../shared/utils/arrayHelpers'
import uuid from '../../../core/utils/uuid'
import { useWorkflowBlockDraft } from '../providers/WorkflowBlockDraftProvider'
import useGetInputTypes from '../../Forms/hooks/useGetInputTypes'

type WorkflowBlockDraftMutations = {
  updateTrigger: (trigger: Partial<WorkflowBlockTrigger> & Pick<WorkflowBlockTrigger, 'uuid'>) => void
  addTrigger: () => void
  removeTrigger: (triggerId: string) => void
  setDeadline: (deadline: WorkflowBlockReminder) => void
  addApprover: (approver: WorkflowBlockApprover) => void
  removeApprover: (userId: number | string) => void
  toggleSkipRequestorApproval: () => void
  removeAll: () => void
  removeTasks: () => void
}

export const useWorkflowBlockDraftMutations = (): WorkflowBlockDraftMutations => {
  const { updateBlock } = useWorkflowBlockDraft()
  const { data: inputs } = useGetInputTypes()

  return useMemo(
    () => ({
      removeAll: () => {
        updateBlock({
          triggers: [],
          approvers: [],
          reminder: '12H',
        })
      },
      updateTrigger: (trigger: Partial<WorkflowBlockTrigger> & Pick<WorkflowBlockTrigger, 'uuid'>) => {
        updateBlock((prevBlock) => ({
          triggers: getUpdatedByProperty<WorkflowBlockTrigger>('uuid', prevBlock.triggers, [trigger]),
        }))
      },
      addTrigger: () => {
        updateBlock((prevBlock) => ({
          triggers: sortBy(
            getUpdatedByProperty<WorkflowBlockTrigger>('uuid', prevBlock.triggers, [
              {
                uuid: uuid(),
                order: prevBlock.triggers.length + 1,
                condition_operator: 'AND',
                comparison_operator: 'EQUAL',
                value: '',
              },
            ]),
            'order'
          ),
        }))
      },
      removeTrigger: (triggerId: string) => {
        updateBlock((prevBlock) => ({
          triggers: prevBlock.triggers.filter((trigger) => trigger.uuid !== triggerId),
        }))
      },
      setDeadline: (deadline: WorkflowBlockReminder) => {
        updateBlock({
          reminder: deadline,
        })
      },
      addApprover: (approver: WorkflowBlockApprover) => {
        updateBlock((prevBlock) => ({
          approvers: [...prevBlock.approvers, approver],
        }))
      },
      toggleSkipRequestorApproval: () => {
        updateBlock((prevBlock) => ({
          options: { ...prevBlock.options, allowApproveUserRequester: !prevBlock.options?.allowApproveUserRequester },
        }))
      },
      removeApprover: (userIdOrValue: number | string) => {
        updateBlock((prevBlock) => ({
          approvers: prevBlock.approvers.filter((approver) => approver.value !== userIdOrValue),
        }))
      },
      removeTasks: () => {
        updateBlock({
          tasks: [],
        })
      },
    }),
    [updateBlock, inputs]
  )
}

export default useWorkflowBlockDraftMutations
