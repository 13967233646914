import { useTranslation } from 'react-i18next'
import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'

const ExternalDataRelationshipsTableHeader: React.FC = () => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableHeader>{t('external_data.column_name', 'Column name')}</TableHeader>
      <TableHeader>{t('generic.target', 'Target')}</TableHeader>
      <TableHeader>{t('generic.target_column_name', 'Target column name')}</TableHeader>
      <TableHeader>{t('generic.action', 'Action')}</TableHeader>
    </TableHead>
  )
}

export default ExternalDataRelationshipsTableHeader
