import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useRef } from 'react'
import { StickySidePanel } from '../../../../shared/components/layout/StickySidePanel'
import WorkflowBlockDraftProvider from '../../providers/WorkflowBlockDraftProvider'
import { useWorkflow } from '../../providers/WorkflowProvider'
import useTabs from '../../../../shared/hooks/useTabs'

import WorkflowMenu from '../misc/WorkflowMenu'
import WorkflowBuilderSidepanelBlockDetails from './WorkflowBuilderSidepanelBlockDetails/WorkflowBuilderSidepanelBlockDetails'
import WorkflowBuilderSidepanelActions from './WorkflowBuilderSidepanelActions/WorkflowBuilderSidepanelActions'
import { WorkflowBlockTitle } from './WorkflowBlockTitle'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'

export const WorkflowBuilderBlockSidepanel = () => {
  const { t } = useTranslation()
  const { blockSidePanelOpen, removeSelectedBlock, selectedBlock, readonly, deselect } = useWorkflow()
  const previouslySelectedBlock = useRef<WorkflowBlock | null>(null)

  const tabsData = useMemo(
    () => [
      selectedBlock && {
        title: t('workflow.sidepanel_general', 'General'),
        TabComponent: WorkflowBuilderSidepanelBlockDetails,
        current: selectedBlock.uuid !== previouslySelectedBlock.current?.uuid,
      },
      ((selectedBlock?.actions?.length || 0) > 0 || (!readonly && !!selectedBlock?.uuid)) && {
        title: t('workflow.sidepanel_actions', 'Actions'),
        TabComponent: WorkflowBuilderSidepanelActions,
      },
    ],
    [selectedBlock]
  )

  useEffect(() => {
    previouslySelectedBlock.current = selectedBlock
  }, [selectedBlock])

  const { navItems, changeTab, current: selectedTab, updateTabs } = useTabs(tabsData)

  useEffect(() => {
    updateTabs(tabsData)
  }, [tabsData])

  if (!selectedBlock) return null

  const menuItems = [
    !readonly && {
      name: t('general.remove', 'Remove'),
      onClick: removeSelectedBlock,
    },
  ].filter(Boolean)

  const TabsBody = selectedTab?.TabComponent

  return (
    <WorkflowBlockDraftProvider block={selectedBlock}>
      <StickySidePanel
        open={blockSidePanelOpen}
        onToggle={readonly ? deselect : undefined}
        tabs={navItems}
        onTabChange={changeTab}
        header={
          <div className="flex flex-row">
            <WorkflowMenu items={menuItems} />
          </div>
        }
        title={<WorkflowBlockTitle />}
      >
        <div className="flex-col flex justify-between h-full flex-auto">
          <div className=" flex h-full">{TabsBody && <TabsBody />}</div>
        </div>
      </StickySidePanel>
    </WorkflowBlockDraftProvider>
  )
}

export default WorkflowBuilderBlockSidepanel
