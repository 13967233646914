import { useFormField } from '../providers/FormFieldProvider'
import SupplierBranchDataType from '../../../types/Suppliers/SupplierBranchDataType'
import SupplierField from './SupplierField'
import { FormQuestionValue } from '../../../types/Forms'

export const FormFieldSupplier = () => {
  const {
    field: { value, disabled },
    setValues,
  } = useFormField()

  const handleAdd = (newSupplier: SupplierBranchDataType) => {
    setValues({ __uuid: newSupplier.id.toString(), value: newSupplier.name })
  }

  const handleSelect = (selectedSupplier: FormQuestionValue | null) => {
    setValues(selectedSupplier || { __uuid: '', value: '' })
  }

  return (
    <SupplierField selectedValue={value || undefined} onSelect={handleSelect} onAdd={handleAdd} disabled={disabled} />
  )
}

export default FormFieldSupplier
