import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { ExternalDataColumn } from '../../../types/ExternalDataManager/ExternalDataManagerTypes'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import useListDataEntityColumns from '../../ExternalDataManager/hooks/useGetExternalDataColumnsList'
import { FilterSettingsType } from '../../../types/Forms'
import SearchMultiSelect from '../../../shared/components/atoms/SearchSelect/SearchMultiSelect'

interface IDatasetDisplayColumnMultiSelect {
  handleFilterSettingsInput: (updatedSettings: Partial<FilterSettingsType>) => void
  datasetUuid: string | undefined
  selectedColumns: string | undefined
}
const DatasetDisplayColumnMultiSelect: React.FC<IDatasetDisplayColumnMultiSelect> = ({
  handleFilterSettingsInput,
  datasetUuid,
  selectedColumns,
}) => {
  const { t } = useTranslation()
  const { data: dataEntityColumns } = useListDataEntityColumns(datasetUuid)

  // Map externalDataEntities to SearchSelect options
  let options: InputSearchSelectItemType[] = []
  if (dataEntityColumns) {
    options = dataEntityColumns.map((entity: ExternalDataColumn) => ({
      id: uuid(),
      label: entity.column_name,
    }))
  }

  // Set options in state to avoid uuid changes on rerender
  const [optionsState, setOptionsState] = useState<InputSearchSelectItemType[]>(options)
  useEffect(() => {
    setOptionsState(options)
  }, [dataEntityColumns])

  // Find selected columns
  const selectedColumnsArray = selectedColumns?.split(',')
  const selectedColumnsOptions = optionsState.filter((option) => selectedColumnsArray?.includes(option.label))

  const onSelectedColumn = (selectedColumns: InputSearchSelectItemType[]) => {
    // filter out non unique values
    const selectedColumnsString = selectedColumns.map((column) => column.label).join(',')
    handleFilterSettingsInput({ visible_columns: selectedColumnsString })
  }

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="reference_dataset">
        {t('inputs.select_columns_to_be_shown', 'Select columns to be shown to the requester')}
      </label>
      <SearchMultiSelect
        showArrow
        onChange={(selectedColumns) => onSelectedColumn(selectedColumns)}
        options={optionsState}
        currentSelection={selectedColumnsOptions}
        placeholder={t('inputs.select_columns_to_be_shown', 'Select columns to be shown to the requester')}
      />
    </div>
  )
}

export default DatasetDisplayColumnMultiSelect
