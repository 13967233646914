import DnDContext from '../../../../../shared/components/organisms/DnD/DnDContext'
import DnDDroppable from '../../../../../shared/components/organisms/DnD/DnDDroppable'
import useGetFormData from '../../../hooks/useGetFormData'
import FormQuestionGroup from './FormQuestionGroup/FormQuestionGroup'
import useHandleQuestionGroupsDragEnd from './hooks/useHandleQuestionGroupsDragEnd'

const FormQuestionGroups: React.FC = () => {
  const { data: formData } = useGetFormData()

  const handleDragEnd = useHandleQuestionGroupsDragEnd()

  return (
    <div className="pb-4">
      <DnDContext droppableId="question-groups" onDragEnd={handleDragEnd} droppableType="droppable">
        <DnDDroppable droppableId="question-groups" droppableType="droppable">
          {formData?.template?.sections?.map((section, sectionIndex) => (
            <FormQuestionGroup key={section.__uuid} section={section} sectionIndex={sectionIndex} />
          ))}
        </DnDDroppable>
      </DnDContext>
    </div>
  )
}

export default FormQuestionGroups
