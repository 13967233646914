import MediaTable from '../../../shared/components/atoms/FileUpload/MediaTable'
import { useFormField } from '../providers/FormFieldProvider'

export const FormFieldDisplayMedia = () => {
  const {
    field: { media },
  } = useFormField()

  if (!media) return <div>No Files</div>

  return <MediaTable media={media} />
}

export default FormFieldDisplayMedia
