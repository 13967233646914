import { MdOutlineFileUpload } from 'react-icons/md'

interface ITipTapEditorAddFileButton {
  fileUploadRef: React.RefObject<HTMLInputElement>
  handleAddFiles: (files: FileList) => void
}

const acceptedFileTypes =
  'application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/zip, application/gzip, text/csv, text/plain, image/gif'

const TipTapEditorAddFileButton: React.FC<ITipTapEditorAddFileButton> = ({ fileUploadRef, handleAddFiles }) => (
  <div className="flex h-full items-center">
    <button
      className="border-2 border-gray-500 p-3 rounded-md border-dotted cursor-pointer hover:border-indigo-800 group h-12 w-12 m-2"
      type="button"
      onClick={() => fileUploadRef?.current?.click()}
    >
      <MdOutlineFileUpload className="text-xl text-secondary-gray group-hover:text-indigo-800" />
      <input
        type="file"
        id="file"
        ref={fileUploadRef}
        multiple
        data-cy="tiptap-upload-file-button"
        name="image"
        className="hidden"
        onChange={(e) => handleAddFiles(e.target.files as FileList)}
        accept={acceptedFileTypes}
      />
    </button>
  </div>
)

export default TipTapEditorAddFileButton
