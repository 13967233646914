import { useTranslation } from 'react-i18next'
import React from 'react'
import { Table, TableBody, TableHead, TableHeader, TableRow } from '../../../../../core/components/Table/Table'
import useWorkflowService from '../../../../Workflow/services/useWorkflowService'
import { Workflow } from '../../../../../types/Workflow/Workflow'
import WorkflowTableRow from './WorkflowTableRow'

const WorkflowTable = () => {
  const { t } = useTranslation()
  const {
    getWorkflows: { data: workflows, isLoading },
  } = useWorkflowService()
  return (
    <Table className="bg-white shadow-rock-gray-light ">
      <TableHeader>
        <TableRow>
          <TableHead className="w-48 font-bold first-letter:uppercase">{t('generic.name', 'Name')}</TableHead>
          <TableHead className="w-48 font-bold first-letter:uppercase">{t('generic.created_at', 'Created')}</TableHead>
          <TableHead className="w-48 font-bold first-letter:uppercase">{t('generic.version', 'Version')}</TableHead>
          <TableHead className="w-48 font-bold first-letter:uppercase">
            {t('generic.published_at', 'Published')}
          </TableHead>
          <TableHead className="w-48 font-bold first-letter:uppercase text-right">
            {t('generic.action', 'Action')}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {workflows?.map((workflow: Workflow) => <WorkflowTableRow key={workflow.uuid} workflow={workflow} />)}
      </TableBody>
    </Table>
  )
}

export default WorkflowTable
