import { FC, useState } from 'react'
import uuid from 'react-uuid'
import { PlusIcon } from '@heroicons/react/24/outline'
import { uniqBy } from 'lodash'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { UserMetaInputType, UserProfileType } from '../../../../../types/shared'
import useUpdateUser from '../../Profile/hooks/useUpdateUser'
import UserDetailsDefaultValuesForm from './UserDetailsDefaultValuesForm'
import useGetInputTypes from '../../../../Forms/hooks/useGetInputTypes'

interface IUserDetailsDefaultValues {
  user: UserProfileType
}

export type MetaInputKeyValueType = {
  id: string
  key: string
  value: string
}

const UserDetailsDefaultValues: FC<IUserDetailsDefaultValues> = ({ user }) => {
  const metaInputs = Object.keys(user?.meta_inputs || {}).map((key: string) => ({
    key,
    value: user?.meta_inputs?.[key as string],
    id: uuid(),
  }))
  const [metaInputsKeyValues, setMetaInputsKeyValues] = useState<MetaInputKeyValueType[]>(metaInputs)

  const { mutate: updateUser } = useUpdateUser()

  const { data } = useGetInputTypes()

  const options = uniqBy(
    data
      ?.filter((d) => d.key && !metaInputsKeyValues.map((m) => m.key).includes(d.key))
      .map((inputType) => ({ id: inputType.key || '', label: inputType.key || '' })) || [],
    'id'
  )

  const handleAdd = () => {
    const newMetaInputs = [...metaInputsKeyValues, { key: '', value: '', id: uuid() }]
    setMetaInputsKeyValues(newMetaInputs)
  }

  const handleSubmit = (newMetaInputs: MetaInputKeyValueType[]) => {
    const metaAsKeyValue = newMetaInputs.reduce(
      (prev: UserMetaInputType, current: MetaInputKeyValueType) =>
        ({
          ...prev,
          [current.key]: current.value,
        }) as UserMetaInputType,
      {}
    )

    updateUser({ id: user.id, meta_inputs: metaAsKeyValue })
  }

  const handleChange = (key: string, value: string, index?: number) => {
    const newMetaInputs = [...metaInputsKeyValues]

    newMetaInputs[index as number] = { ...newMetaInputs[index as number], key, value }
    setMetaInputsKeyValues(newMetaInputs)
    handleSubmit(newMetaInputs)
  }

  const handleDelete = (index: number) => {
    const newArray = [...metaInputsKeyValues]
    newArray.splice(index, 1)

    setMetaInputsKeyValues(newArray)
    handleSubmit(newArray)
  }

  return (
    <div className="flex flex-col gap-4">
      {metaInputsKeyValues.map((metaInput, index) => (
        <UserDetailsDefaultValuesForm
          metaInput={metaInput}
          key={metaInput.id}
          handleChange={handleChange}
          index={index}
          options={options}
          handleDelete={handleDelete}
        />
      ))}

      <div className="mx-auto mt-8">
        <Button className="btn-primary" onClick={handleAdd}>
          <PlusIcon className="w-4" /> Add
        </Button>
      </div>
    </div>
  )
}

export default UserDetailsDefaultValues
