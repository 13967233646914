import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import classnames from 'classnames'
import useGetInputCategories from '../Forms/hooks/useGetInputCategories'
import { InputSearchSelectItemType } from '../../types/shared/InputSearchSelectTypes'
import SearchInput from '../../shared/components/atoms/inputs/SearchInput'
import { useInputTemplates } from './providers/InputTemplatesProvider'
import SearchSelect from '../../shared/components/atoms/SearchSelect/SearchSelect'
import { InputCategory } from '../../types/Forms'
import cn from '../../lib/util'

const getOptionFromCategory = (category: InputCategory): InputSearchSelectItemType => ({
  id: category.uuid,
  label: category.name,
})

type CompanyInputFiltersProps = {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

const CompanyInputFilters: React.FC<CompanyInputFiltersProps> = ({ className }) => {
  const { filters, update } = useInputTemplates()
  const { t } = useTranslation()

  const { data: categories, getById } = useGetInputCategories()

  const handleSearch = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      update({ search: value })
    },
    [update]
  )

  const handleFilter = (categoryOption?: InputSearchSelectItemType) => {
    update({ category_uuid: (categoryOption?.id as string) || undefined })
  }

  const selectedCategory = filters.category_uuid && getById(filters.category_uuid)

  return (
    <div className={cn('flex items-center justify-between w-full space-x-4', className)}>
      <SearchInput fullWidth={false} value={filters.search} onChange={handleSearch} debounce={300} className="w-full" />
      <SearchSelect
        onChange={handleFilter}
        onClear={handleFilter}
        options={categories?.map(getOptionFromCategory) || []}
        currentSelection={selectedCategory ? getOptionFromCategory(selectedCategory) : undefined}
        placeholder={t('forms.inputs.filter.category')}
        nullable
        className="text-sm !border-gray-200"
      />
    </div>
  )
}

export default CompanyInputFilters
