import { DialogClose } from '@radix-ui/react-dialog'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../shared/components/atoms/Dialog/Dialog'
import Input from '../../../../shared/components/atoms/Input/Input'
import Button from '../../../../shared/components/atoms/buttons/Button'
import { toastr } from '../../../../shared/components/organisms/toastr'
import { IntegrationSettingsType, IntegrationType, useIntegrationService } from './useIntegrationService'

interface IIntegrationsModalContent {
  integration: IntegrationType
  configuration?: IntegrationSettingsType[]
}

const IntegrationsModalContent: FC<IIntegrationsModalContent> = ({
  integration: { party, fields, name },
  configuration,
}) => {
  const { t } = useTranslation()

  // Handle only one configuration for now
  const apiConfiguration = configuration?.[0] || undefined

  const defaultValues: IntegrationSettingsType = {
    party,
    id: apiConfiguration?.id,
    ...fields?.reduce((fieldObject, field) => ({ ...fieldObject, [field]: apiConfiguration?.[field] || '' }), {}),
  }

  const { handleSubmit, control } = useForm({ mode: 'onChange', values: { ...defaultValues } })

  const {
    postIntegration: { mutateAsync: createIntegration },
    deleteIntegration: { mutateAsync: deleteIntegration },
  } = useIntegrationService()

  const onSubmit = async (attributes: any) => {
    await createIntegration(attributes)

    toastr.success(t('integration.setting-saved', 'Integration setting saved'))

    document.getElementById('close')?.click()
  }

  const handleDelete = async (id?: number) => {
    if (!id) return
    await deleteIntegration(id)
    toastr.success(t('integration.setting-deleted', 'Integration deleted'))

    document.getElementById('close')?.click()
  }

  return (
    <DialogContent className="sm:max-w-2xl">
      <DialogHeader>
        <DialogTitle>
          {t('generic.edit', 'Edit')} <span className="capitalize">{name}</span>
        </DialogTitle>
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4 ">
        <Controller
          name="id"
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => (
            <Input className="hidden" {...fieldProps} value={apiConfiguration?.id} />
          )}
        />
        <Controller
          name="party"
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => (
            <Input className="hidden" {...fieldProps} value={party} />
          )}
        />

        {fields?.includes('client_id') && (
          <Controller
            name="client_id"
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <Input
                label={t('integration.client_id', 'Client Id')}
                {...fieldProps}
                onChange={(input) => onChange(input)}
              />
            )}
          />
        )}

        {fields?.includes('client_secret') && (
          <Controller
            name="client_secret"
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <Input
                type="password"
                label={t('integration.client_secret', 'Client Secret')}
                {...fieldProps}
                onChange={(input) => onChange(input)}
              />
            )}
          />
        )}

        <DialogFooter className="pt-8">
          {apiConfiguration && (
            <div className=" w-full">
              <Button
                className="btn bg-red-500 text-white float-left"
                onClick={() => handleDelete(apiConfiguration?.id)}
              >
                {t('generic.delete', 'Delete')}
              </Button>
            </div>
          )}
          <DialogClose asChild>
            <Button className="btn-ghost" id="close">
              {t('generic.cancel', 'Cancel')}
            </Button>
          </DialogClose>
          <Button className="btn-primary" type="submit">
            {' '}
            {t('generic.save', 'Save')}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  )
}
export default IntegrationsModalContent
