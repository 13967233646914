import { PlusIcon } from '@heroicons/react/24/outline'
import React from 'react'
import WorkflowActionButton, { WorkflowActionButtonProps } from './WorkflowActionButton'

export const WorkflowAddButton = ({ children, ...props }: WorkflowActionButtonProps) => (
  <WorkflowActionButton {...props}>
    <PlusIcon className="w-4" style={{ strokeWidth: 3 }} />
    {children}
  </WorkflowActionButton>
)

export default WorkflowAddButton
