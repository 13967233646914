import { getTargetValueAndQuestions } from '../helpers/getTargetValueAndQuestions'
import { getMissingTargetValueError } from '../helpers/getMissingTargetValueError'

export const sumEquals = function (requestedValue) {
  return this.test('total', `sum has to be equal to ${requestedValue}`, (values, schema) => {
    const { targetValue, targetQuestion } = getTargetValueAndQuestions(requestedValue, schema)

    const existingValues = values?.filter((value) => value !== undefined)

    if (existingValues === undefined || existingValues.length === 0) return true

    if (targetValue === undefined && existingValues !== undefined && existingValues.length > 0) {
      return getMissingTargetValueError(targetQuestion, schema)
    }

    return existingValues?.reduce((sum, value) => sum + value, 0) === targetValue
      ? true
      : schema.createError({ message: `Sum must be equal to ${targetValue}`, path: schema.path })
  })
}

export default sumEquals
