import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import QuestionItemRichTextInput from './QuestionItemRichTextInput'

interface IQuestionSubtitleInput {}

const QuestionSubtitleInput: React.FC<IQuestionSubtitleInput> = () => {
  const { t } = useTranslation()

  const {
    question: [question],
  } = useFormContext()
  const canRename = question?.actions?.rename

  return (
    <QuestionItemRichTextInput
      title={t('question_builder.subtitle_text')}
      placeholder={t('question_builder.enter_subtitle')}
      property="subtitle"
      disabled={!canRename}
    />
  )
}

export default QuestionSubtitleInput
