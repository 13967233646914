import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { HiArrowLeft } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import LhotseIconBlack from '../../../../core/assets/logo-icon-lhotse-nofill.svg'
import Overlay from '../../../../core/components/Overlay/Overlay'
import cn from '../../../../lib/util'

type AuthLayoutProps = {
  title: string
  disclaimer?: string
  children: ReactNode
  className?: string
  showBackButton?: boolean
}

const AuthLayout = ({ title, disclaimer, className, children, showBackButton = false }: AuthLayoutProps) => {
  const { t } = useTranslation()
  return (
    <Overlay>
      <div className="h-full w-full flex flex-col items-center justify-center py-9">
        <div className={cn('flex flex-col space-y-9 w-96 h-full mt-60', className)}>
          <div className="flex flex-col items-center space-y-6">
            <img src={LhotseIconBlack} alt="Lhotse-logo" className="w-14 h-14" />
            <h1 className="text-black-light text-2xl font-semibold">{title}</h1>
          </div>
          <div>
            {children}
            {showBackButton && (
              <Link to="login" type="button" className="text-black-light-2 mt-5 text-sm hover:text-sky-blue">
                <HiArrowLeft className="inline-block mr-2" />
                {t('generic.more-login-options', 'Other sign in options')}
              </Link>
            )}
          </div>
        </div>
        <span className="text-sm text-black-light-2 flex justify-end max-w-2/4 mt-9">{disclaimer}</span>
      </div>
    </Overlay>
  )
}
export default AuthLayout
