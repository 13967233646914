// import CompanyFormWorkflow from './CompanyFormWorkflow/CompanyFormWorkflow'
import useGetFormParams from '../hooks/useGetFormParams'
import CompanyFormWorkflow from './CompanyFormWorkflow/CompanyFormWorkflow'
import FormQuestions from './FormQuestionGroups/FormQuestions'
import FormSettings from './Settings/FormSettings'

const Body: React.FC = () => {
  const { location } = useGetFormParams()

  if (location === 'content') return <FormQuestions />
  // if (location === 'settings') return <CompanyFormSettings />
  if (location === 'workflow') return <CompanyFormWorkflow />
  if (location === 'settings') return <FormSettings />

  return null
}

export default Body
