import React, { FC, HTMLProps } from 'react'
import BaseInput from './BaseInput'
import BaseInputWrapper from './BaseInputWrapper'

export interface IInput extends Omit<HTMLProps<HTMLInputElement>, 'onBlur'> {
  editable?: boolean | undefined
  loading?: boolean
  required?: boolean | undefined
  label?: string
  onBlur?: (value: string | number | readonly string[] | undefined) => void
  type?: string
  lead?: React.ReactNode
}

const Input: FC<IInput> = ({ label, defaultValue, loading, onBlur, editable = true, type = 'text', lead, ...rest }) => (
  <BaseInputWrapper label={label}>
    {!loading && editable ? (
      <BaseInput type={type} {...rest} lead={lead} defaultValue={defaultValue} onBlur={onBlur} />
    ) : (
      <span className="text-sm p-2">{defaultValue}</span>
    )}
  </BaseInputWrapper>
)

export default Input
