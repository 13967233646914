import { apiDelete, apiGetData, apiUpload, getFile } from '../../core/utils/api/generic'
import { getApiUrl } from '../../core/utils/tenancy'
import { MediaType } from '../../types/shared/MediaTypes'

export const mediaService = (
  model:
    | 'requisition'
    | 'item_section'
    | 'submission'
    | 'offering'
    | 'offer'
    | 'offering_offer'
    | 'intake_flow_input'
    | 'field',
  id: string
) => {
  const download = (attachmentId: string): Promise<string> =>
    getFile(`${getApiUrl()}/media/${model}/${id}/download/${attachmentId}`)
  return {
    list: () => apiGetData<MediaType[]>(`/media/${model}/${id}`),
    download,
    saveFile: async (attachmentId: string, name: string): Promise<void> => {
      const link = document.createElement('a')

      link.href = await download(attachmentId)
      link.setAttribute('download', name)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    remove: (attachmentId: string): Promise<{ success: boolean }> =>
      apiDelete(`${getApiUrl()}/media/${model}/${id}/delete/${attachmentId}`),
    upload: (files: File[]): Promise<MediaType[]> => {
      const formData = new FormData()
      formData.append('model', model)
      formData.append('model_id', id)
      files.forEach((file) => formData.append('files[]', file))

      const url = `${getApiUrl()}/media/${model}/${id}/upload`

      return apiUpload(url, formData).then(({ data: { data } }) => data)
    },
  }
}

export const offerMediaService = (id: string) => mediaService('offer', id)

export const submissionMediaService = (id: string) => mediaService('submission', id)

export const requisitionMediaService = (id: string) => mediaService('requisition', id)

export const fieldMediaService = (id: string) => mediaService('field', id)
