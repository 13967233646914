import { MdStar } from 'react-icons/md'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import usePostTag from '../../../../shared/components/atoms/tags/hooks/usePostTag'
import useRemoveTag from '../../../../shared/components/atoms/tags/hooks/useRemoveTag'
import useGetSupplierBranch from '../../../SupplierDetails/hooks/useGetSupplierBranch'
import useHandleTogglePreferred from './useHandleTogglePreferred'
import useUpdatePreferredCache from './useUpdatePreferredCache'

interface ISupplierPreferredStar {
  supplierBranchId: number
  disabled?: boolean
  preferred?: boolean
  className?: string
}

const SupplierPreferredStar: React.FC<ISupplierPreferredStar> = ({
  supplierBranchId,
  preferred,
  disabled,
  className,
}) => {
  const { data: supplierBranchData } = useGetSupplierBranch(supplierBranchId, true)

  const isPreferred = preferred || !!supplierBranchData?.preferred

  const updateCache = useUpdatePreferredCache(supplierBranchId)
  const { isLoading: isLoadingPost } = usePostTag('general', updateCache)
  const { isLoading: isLoadingRemove } = useRemoveTag('general', updateCache)

  const handleTogglePreferred = useHandleTogglePreferred(supplierBranchId, disabled, preferred !== undefined)

  if (isLoadingPost || isLoadingRemove) {
    return (
      <div>
        <CoreLoadingIcon />
      </div>
    )
  }

  return (
    <button
      type="button"
      className={disabled ? 'pointer-events-none cursor-default' : ''}
      onClick={handleTogglePreferred}
      disabled={disabled}
    >
      <MdStar
        className={`flex text-xl
          ${className}
          ${
            isPreferred
              ? `text-sky-blue ${!disabled && ' hover:text-black '}`
              : `text-tertiary-gray ${!disabled && ' hover:text-sky-blue '}`
          }`}
      />
    </button>
  )
}

export default SupplierPreferredStar
