import qs from 'qs'
import apiClient from '../apiClient'

export type ExtendedPromise<DataType> = Promise<{ data: DataType }>

export const apiSearch = async ({ query, url }: { query: string; url: string }) => {
  if (query === '') return []
  const res = await apiClient.post(url, { query })
  return res?.data || []
}

export async function getFile(url: string): Promise<string> {
  const res = await apiClient.get(`${url}`)
  return res?.data.url || ''
}

export type ParamsObject = Record<string, string | boolean | number | object | undefined>

export async function apiGet<T = any, K extends ParamsObject = ParamsObject>(
  url: string,
  params?: K,
  arrayFormat?: any
): Promise<T> {
  const res = await apiClient.get(`${url}`, {
    params,
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: arrayFormat || 'brackets' })
    },
  })
  return res?.data || []
}

export async function apiGetData<T = any, K extends ParamsObject = ParamsObject>(url: string, params?: K): Promise<T> {
  return (await apiGet(url, params))?.data || []
}

export async function apiPost<Type = any>(url: string, body?: any): Promise<Type> {
  const res = await apiClient.post(`${url}`, body)
  return res?.data
}

export async function apiPostData<Type = any>(url: string, body?: any): Promise<Type> {
  const res = await apiClient.post(`${url}`, body)
  return res.data?.data || []
}

export async function apiDelete<T = any>(url: string, deletedId?: number | string): Promise<T> {
  const res = await apiClient.delete(`${url}`)
  return deletedId || res?.data || []
}

export const apiPut = async (url: string, body?: any) => {
  const res = await apiClient.put(`${url}`, body)
  return res?.data || []
}

export async function apiPutData<T = any>(url: string, body: any): Promise<T> {
  const res = await apiClient.put(`${url}`, body)
  return res.data?.data || []
}

export async function apiUpload<T = any>(url: string, body: FormData) {
  return apiClient.post<T>(url, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
