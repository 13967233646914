import { FC, HTMLProps, useEffect, useRef, useState } from 'react'

export interface IBaseInput extends Omit<HTMLProps<HTMLInputElement>, 'onBlur'> {
  required?: boolean | undefined
  label?: string
  onBlur?: (value: string | number | readonly string[] | undefined) => void
  lead?: React.ReactNode
}

const BaseInput: FC<IBaseInput> = ({ className, onBlur, lead, ...rest }) => {
  const [value, setValue] = useState<string | number | readonly string[] | undefined>(rest.defaultValue || '')
  const ref = useRef<HTMLInputElement>(null)

  const handleBlur = () => {
    onBlur && onBlur(value)
  }

  useEffect(() => {
    setValue(rest.defaultValue)
  }, [rest.defaultValue])

  return (
    <div className="relative rounded-md flex w-full ">
      {lead && lead}
      <input
        onChange={({ target: { value: inputValue } }) => setValue(inputValue)}
        onBlur={handleBlur}
        ref={ref}
        type={rest.type || 'text'}
        className={`
         base-form-input peer
         block w-full  ${className} `}
        {...rest}
      />
    </div>
  )
}

export default BaseInput
