// REFACTOR
import { Form, Formik } from 'formik'
import RowCell from '../../../../shared/components/layout/RowCell'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import { UserType } from '../../../../types/shared/AssignmentTypes'
import AddressFormTextInput from '../../../Settings/Views/General/addresses/AddressForm/AddressFormTextInput'
import usePostUserMutation, {
  NewUserFormType,
} from '../../../Suppliers/components/SupplierProfile/hooks/usePostUserMutation'
import useUpdateSupplierBranchUser from '../../../Suppliers/components/SupplierProfile/hooks/useUpdateSupplierBranchUser'
import SupplierUserType from '../../../../types/Suppliers/SupplierUserType'
import useGetFormValidation from './useGetFormValidation'

interface ISupplierUserForm {
  user?: SupplierUserType
  supplierBranchId: number
  className?: string
  hideForm: () => void
}

const SupplierUserForm: React.FC<ISupplierUserForm> = ({ user, hideForm, supplierBranchId, className }) => {
  const { mutate: postUserMutate } = usePostUserMutation(hideForm)
  const { mutate: updateUserMutate } = useUpdateSupplierBranchUser(hideForm, user)

  const initialValues = {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    phone_number: user?.phone_number || '',
  }

  const handleOnSubmit = (data: UserType | NewUserFormType) =>
    user ? updateUserMutate(data as UserType) : postUserMutate(data as NewUserFormType)

  const formValidation = useGetFormValidation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleOnSubmit}
      validateOnBlur={false}
    >
      {({ touched, values, handleChange, handleSubmit, resetForm, errors }) => {
        const constants = {
          values,
          errors,
          handleChange,
          touched,
        }

        return (
          <Form className={`flex flex-col overflow-y-visible ${className}`}>
            <div className="flex justify-between rounded bg-white">
              <RowCell className="pb-4 pt-4 overflow-y-visible pl-0" width="w-1/3 items-center gap-2">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="Jane"
                  label="generic.first_name"
                  errorKey="first_name"
                  className="w-1/2"
                  dataCy="user-first-name-input"
                />
                <AddressFormTextInput
                  constants={constants}
                  placeholder="Smith"
                  label="generic.last_name"
                  errorKey="last_name"
                  className="w-1/2"
                  dataCy="user-last-name-input"
                />
              </RowCell>
              <RowCell className="pb-4 pt-4 overflow-y-visible" width="w-1/3">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="jane@smith.com"
                  label="generic.email"
                  errorKey="email"
                  className="w-full"
                />
              </RowCell>
              <RowCell className="pb-4 pt-4 overflow-y-visible pr-0" width="w-1/3">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="+49 152 10 99"
                  label="suppliers.phone_number"
                  errorKey="phone_number"
                  className="w-full"
                  phoneNumber
                />
              </RowCell>
            </div>
            {supplierBranchId && (
              <SaveCancelFormButtons
                onCancel={() => {
                  resetForm()
                  hideForm()
                }}
                onSave={handleSubmit}
                className="pt-2"
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default SupplierUserForm
