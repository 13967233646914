import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { FormikInputType } from '../../../../../types/shared/FormikType'
import ErrorMessage from '../../ErrorMessage'
import { getUnderlineInputClasses } from './underlineConstants'

interface IFormikInputGrayUnderline extends FormikInputType {
  handleChange: (e: React.ChangeEvent) => void
  type?: string
  placeholder?: string
}

const FormikInputGrayUnderline: React.FC<IFormikInputGrayUnderline> = ({
  type,
  name,
  handleChange,
  dataCy,
  placeholder,
  values,
  error,
  handleOnEnter,
  min,
  max,
  validate,
  disabled,
  handleOnBlur,
  customOnChange,
  className,
  label,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`flex flex-col relative ${className}`}>
      {label && (
        <label htmlFor={name} className="text-gray-400 mb-1 text-xs outline-none">
          <div className="flex justify-between">{t(label)}</div>
        </label>
      )}
      <Field
        disabled={disabled}
        name={name}
        type={type}
        placeholder={t(placeholder || '')}
        value={(values && values[name]) || ''}
        onChange={(v: React.ChangeEvent) => {
          if (customOnChange) {
            customOnChange(v)
          }
          handleChange(v)
        }}
        data-cy={dataCy}
        onBlur={handleOnBlur ? (e: any) => handleOnBlur({ name, value: e.target.defaultValue }) : () => {}}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter' && handleOnEnter) {
            e.preventDefault()
            handleOnEnter()
          }
        }}
        min={min}
        max={max}
        validate={validate}
        className={getUnderlineInputClasses(error?.props.error)}
      />
      <ErrorMessage error={error?.props.error} className="absolute left-0 bottom-1 w-fit" />
    </div>
  )
}

export default FormikInputGrayUnderline
