import { useLocation } from 'react-router'
import CoreAuthenticating from '../../core/components/CoreAuthenticating'
import ForgotPassword from './submodules/ForgotPassword/ForgotPassword'
import ResetPassword from './submodules/ResetPassword/ResetPassword'
import Login from './submodules/Login/Login'
import useGetSSOProviders from './submodules/shared/hooks/useGetSSOProviders'
import SignUp from './submodules/SignUp/SignUp'

const views: { [key: string]: JSX.Element } = {
  login: <Login />,
  'forgot-password': <ForgotPassword />,
  'reset-password': <ResetPassword />,
  join: <SignUp />,
}

const RenderView = ({ pathName }: { pathName: string }): JSX.Element => views[pathName] ?? <Login />

const AuthenticationPage = () => {
  const { isLoading } = useGetSSOProviders()
  const { pathname } = useLocation()

  if (isLoading) {
    return <CoreAuthenticating />
  }

  const pathNameClean: string = pathname.replace('/', '').toString().toLowerCase()

  return <RenderView pathName={pathNameClean} />
}

export default AuthenticationPage
