import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useFormContext } from '../../../../../core/providers/FormProvider'
import { FormNumberOfChoicesOptionSettingType } from '../../../../../types/Forms'
import NumberOfChoicesOption from './NumberOfChoicesOption/NumberOfChoicesOption'
import QuestionSubSection from '../components/QuestionSubSection'
import useUpdateQuestionSetting from '../../hooks/useUpdateQuestionSetting'
import {
  getAnswersMinMax,
  getAvailableChoices,
  getTypeOfAnswersSetting,
} from './NumberOfChoicesOption/questionAnswersSettings'

const SelectableChoicesSetting: React.FC = () => {
  const { t } = useTranslation()
  const {
    question: [question],
  } = useFormContext()

  const updateQuestionSettings = useUpdateQuestionSetting()
  const [selectedOption, selectOption] = useState(getTypeOfAnswersSetting(question?.__settings?.answers))
  const availableChoices = getAvailableChoices(question?.type)

  if (!availableChoices.length) return null

  const handleSelect = (type: FormNumberOfChoicesOptionSettingType) => {
    selectOption(type)
    updateQuestionSettings({
      answers: getAnswersMinMax(type),
    })
  }

  return (
    <QuestionSubSection title={t('forms.question.settings.choices')}>
      {availableChoices.map((option) => (
        <NumberOfChoicesOption
          key={option}
          type={option}
          isChecked={selectedOption === option}
          onSelect={handleSelect}
        />
      ))}
    </QuestionSubSection>
  )
}

export default SelectableChoicesSetting
