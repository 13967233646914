import { useCallback, useMemo, useState } from 'react'
import { TabbedNavItemType } from '../../types/shared/TabbedNavItemType'

type TabNavItemData = Omit<TabbedNavItemType, 'tabIndex' | 'current'> & { tabIndex?: number; current?: boolean }

type TabOrNil = TabNavItemData | null | undefined | false

const parseTabsData = (tabsData: TabOrNil[], activeByDefaultIndex = 0): TabbedNavItemType[] => {
  const normalizedTabs = (tabsData.filter((tab) => !!tab) as TabNavItemData[]).map((tab, index) => ({
    tabIndex: index,
    current: false,
    ...tab,
  }))

  const hasActiveTab = normalizedTabs.some((tab) => tab.current)
  if (!hasActiveTab && normalizedTabs.length > 0) {
    ;(normalizedTabs[activeByDefaultIndex] || normalizedTabs[0]).current = true
  }

  return normalizedTabs
}

export const useTabs = (tabs: TabOrNil[]) => {
  const [navItems, setNavItems] = useState<TabbedNavItemType[]>(parseTabsData(tabs))

  const changeTab = useCallback(
    (tabIndex: number) => {
      const newNavItems = navItems.map((item: TabbedNavItemType) => ({
        ...item,
        current: item.tabIndex === tabIndex,
      }))

      setNavItems(newNavItems)
    },
    [navItems]
  )

  const currentIndex = useMemo(() => navItems.findIndex((item) => item.current), [navItems])
  const current = useMemo(() => navItems[currentIndex], [navItems, currentIndex])

  const updateTabs = useCallback(
    (tabs: TabOrNil[]) => {
      setNavItems(parseTabsData(tabs, currentIndex))
    },
    [currentIndex]
  )

  return {
    navItems,
    current,
    changeTab,
    updateTabs,
  } as const
}

export default useTabs
