import { useUtils } from '../../../../../core/providers/UtilsProvider'
import AddressesFormCreate, { IAddressesFormCreate } from './AddressesFormCreate'

export default (addressFormProps: IAddressesFormCreate = {}) => {
  const { modal } = useUtils()

  return () =>
    modal.set({
      isOpen: true,
      title: 'company.add_address',
      content: <AddressesFormCreate {...addressFormProps} />,
    })
}
