import coreColors, { coreColorsType } from '../constants/coreColors'

function isCoreColor(color: string | undefined): color is keyof typeof coreColors {
  return !!color && coreColors[color as keyof typeof coreColors] !== undefined
}

const buttonStylePicker = (
  color: coreColorsType | string | undefined,
  variant: 'outlined' | 'solid' | undefined | string
) => {
  const styles = {} as React.CSSProperties
  let buttonClassNames = ''
  let textClassNames = ''
  styles.backgroundColor = 'white'
  switch (variant) {
    case 'outlined':
      styles.borderStyle = 'solid'
      buttonClassNames += ' active:brightness-75 hover:brightness-90'
      styles.borderWidth = 'thin'
      switch (color) {
        case 'lhotse-orange':
        case 'sky-blue':
          styles.color = coreColors[color]
          styles.borderColor = coreColors[color]
          break
        case 'lhotse-gray':
          styles.color = 'black'
          styles.borderColor = coreColors['lhotse-gray']
          break
        default:
          if (isCoreColor(color)) {
            styles.color = coreColors[color]
            styles.borderColor = coreColors[color]
          } else {
            styles.color = color
            styles.borderColor = color
          }
          break
      }
      break

    case 'solid':
      styles.color = 'white'
      buttonClassNames += ' active:brightness-90'
      buttonClassNames += color !== 'sky-blue' ? ' hover:brightness-110' : ''

      switch (color) {
        case 'lhotse-orange':
        case 'lhotse-gray':
          styles.backgroundColor = coreColors[color]
          break
        case 'sky-blue':
          styles.backgroundColor = undefined
          buttonClassNames += ' bg-sky-blue hover:bg-sky-blue-light'
          break
        default:
          styles.color = 'white'
          if (isCoreColor(color)) {
            styles.backgroundColor = coreColors[color]
          } else {
            styles.backgroundColor = color
          }
          break
      }
      break

    default:
      textClassNames += ' hover:border-current'
      switch (color) {
        case 'lhotse-orange':
        case 'sky-blue':
        case 'lhotse-gray':
          styles.color = coreColors[color]
          break
        default:
          styles.color = color
          break
      }
      break
  }

  return [styles, buttonClassNames, textClassNames] as const
}

export default buttonStylePicker
