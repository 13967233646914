import { useMutation } from 'react-query'
import { OfferItem } from '../../../types/Offering'
import { deleteOfferItem } from '../../Offerings/services'
import useRefetchOfferQuery from './useRefetchQuery'

export const useDeleteOfferItem = () => {
  const refetchOfferQuery = useRefetchOfferQuery()

  return useMutation((offerToDelete: OfferItem) => deleteOfferItem(offerToDelete.uuid), {
    onSettled: async (a, b, offer) => {
      ;(await refetchOfferQuery) && refetchOfferQuery(offer.reference_key)
    },
  })
}

export default useDeleteOfferItem
