import { useTranslation } from 'react-i18next'
import useGetExternalDataEntitiesList from '../../hooks/useGetExternalDataEntitiesList'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import { ExternalDataListEntity } from '../../../../types/ExternalDataManager/ExternalDataManagerTypes'
import SearchSelect, { ISearchSelect } from '../../../../shared/components/atoms/SearchSelect/SearchSelect'

type ExternalDataSourceSelectProps = {
  onSelect: (externalDataSourceUuid: string) => void
  selected: string | undefined
  excludedSources?: string[]
  label?: string
} & Omit<ISearchSelect, 'options' | 'onChange' | 'placeholder' | 'currentSelection'>

const ExternalDataSourceSelect: React.FC<ExternalDataSourceSelectProps> = ({
  onSelect,
  selected,
  excludedSources,
  label,
  ...selectorProps
}) => {
  const { t } = useTranslation()
  const { data: externalDataEntities } = useGetExternalDataEntitiesList()

  let options: InputSearchSelectItemType[] =
    externalDataEntities?.map((entity: ExternalDataListEntity) => ({
      id: entity.uuid,
      label: entity.name,
    })) || []

  if (excludedSources) options = options.filter((option) => !excludedSources.includes(option.id as string))

  const selectedOption = options.find((option) => option.id === selected)

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="reference_dataset">{label || t('inputs.referenced_dataset', 'Referenced Dataset')}</label>
      <SearchSelect
        onChange={(item) => onSelect(item.id as string)}
        options={options}
        placeholder={t('inputs.select_referenced_dataset', 'Select dataset to reference')}
        currentSelection={selectedOption}
        {...selectorProps}
      />
    </div>
  )
}

export default ExternalDataSourceSelect
