import { useTranslation } from 'react-i18next'
import TagSectionIsDefaultSwitch from './TagSectionIsDefaultSwitch'
import TagsContainer from './TagsContainer'

const FormTagSection = () => {
  const { t } = useTranslation()

  return (
    <div className="bg-white px-6 py-4 rounded-md flex flex-col gap-y-4 text-primary-gray">
      <p className="font-medium">{t('forms.tag_section.header')}</p>
      <TagSectionIsDefaultSwitch />
      <TagsContainer />
    </div>
  )
}

export default FormTagSection
