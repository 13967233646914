import { createContext, useCallback, useContext, useMemo } from 'react'
import { FormQuestionValue, FormQuestionValueType } from '../../../types/Forms'
import { getMergedDeepOverwritingArrays } from '../../../shared/utils/objectHelpers'
import { FormFieldSettings } from '../types'
import { MediaType } from '../../../types/shared/MediaTypes'

type FormFieldContextType = {
  field: FormFieldSettings
  setValues: (newValue: FormQuestionValue | FormQuestionValue[]) => void
  updateValue: (updatedValue: FormQuestionValueType) => void
  setFieldMedia: (media: MediaType[]) => void
}

const FormFieldContext = createContext({} as FormFieldContextType)

export type FormFieldProviderProps<T extends string = string> = React.PropsWithChildren<{
  field: FormFieldSettings<T>
  updateField: (field: FormFieldSettings<T>) => void
}>

const FormFieldProvider = <T extends string = string>({ children, field, updateField }: FormFieldProviderProps<T>) => {
  const updateValue = useCallback(
    (updatedValue: FormQuestionValueType) => {
      updateField(getMergedDeepOverwritingArrays(field, { value: { ...field.value, value: updatedValue } }))
    },
    [field, updateField]
  )

  const setValues = useCallback(
    (values: FormQuestionValue | FormQuestionValue[]) => {
      // @ts-ignore we need to set multiple values sometimes (multiselect) for backward compatibility reasons
      updateField(getMergedDeepOverwritingArrays(field, { value: values }))
    },
    [field, updateField]
  )

  const setFieldMedia = useCallback(
    (media: MediaType[]) => {
      updateField(getMergedDeepOverwritingArrays(field, { media }))
    },
    [field, updateField]
  )

  const contextState = useMemo(
    () => ({
      field,
      updateValue,
      setValues,
      setFieldMedia,
    }),
    [field, updateField]
  )

  return <FormFieldContext.Provider value={contextState}>{children}</FormFieldContext.Provider>
}

export default FormFieldProvider

export const useFormField = () => useContext(FormFieldContext)
