import { Editor, EditorContent } from '@tiptap/react'
import { useRef } from 'react'
import cn from '../../../../lib/util'
import { FileType } from '../../../../types/shared/FileTypes'
import { RemoveUploadedFileFunctionType } from '../../layout/SharedFileUploadPill/SharedFileUploadPillTypes'
import TipTapEditorAddFileButton from './TipTapEditorAddFileButton'
import TipTapEditorFileList from './TipTapEditorFileList'

interface ITipTapEditorWithFilesOnBottom {
  filesOnTop?: boolean
  handleDeleteFile?: RemoveUploadedFileFunctionType
  isSending?: boolean
  files?: FileType[]
  className?: string
  editor: Editor | null
  expandedEditor?: boolean
  dataCy?: string
  handleAddFiles: ((files: FileList) => void) | undefined
  editorContentClassName?: string
  editable: boolean | undefined
  noFocus?: boolean
}

const TipTapEditorWithFilesOnBottom: React.FC<ITipTapEditorWithFilesOnBottom> = ({
  files,
  filesOnTop,
  handleDeleteFile,
  isSending,
  editor,
  editable,
  className,
  expandedEditor,
  dataCy,
  handleAddFiles,
  editorContentClassName,
  noFocus,
}) => {
  const fileUploadRef = useRef<HTMLInputElement>(null)

  return (
    <div
      className={cn(
        'flex h-full overflow-y-scroll overflow-overlay shadow-sm',
        editor?.isFocused && !noFocus ? ' border-2  border-sky-blue' : ' border-none ',
        editor?.isEditable ? '  bg-white' : ' bg-transparent cursor-not-allowed ',
        className
      )}
    >
      <div className="flex flex-col w-full ">
        {(editable || editor?.getText()) && (
          <EditorContent
            editor={editor}
            className={cn(
              'p-2 text-sm list-inside text-left base-form-input overflow-x-hidden list-disc w-full',
              'lhotse-texteditor',
              expandedEditor ? 'h-full p-2' : 'h-12 max-h-28',
              editorContentClassName
            )}
            data-cy={dataCy}
          />
        )}

        {(!!files?.length || isSending) && !filesOnTop && (
          <div className="bottom-2">
            <TipTapEditorFileList files={files || []} handleDeleteFile={handleDeleteFile} isSending={isSending} />
          </div>
        )}
      </div>
      {handleAddFiles && <TipTapEditorAddFileButton fileUploadRef={fileUploadRef} handleAddFiles={handleAddFiles} />}
    </div>
  )
}

export default TipTapEditorWithFilesOnBottom
