import { useCallback } from 'react'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'
import useGetBlocksToApprove from './useGetBlocksToApprove'

export const useCanApproveBlock = () => {
  const blocksToApprove = useGetBlocksToApprove()

  return useCallback(
    ({ uuid }: Pick<WorkflowBlock, 'uuid'>) => blocksToApprove?.some((blockToApprove) => blockToApprove.uuid === uuid),
    [blocksToApprove]
  )
}

export default useCanApproveBlock
