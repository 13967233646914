import { forwardRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import useGetCurrentUser from '../../../../../../core/hooks/useGetCurrentUser'
import { BasicFormInputTypes, FormQuestionContentType } from '../../../../../../types/Forms'
import { UserType } from '../../../../../../types/shared/AssignmentTypes'
import useGetUserFormInitialValues from '../../hooks/useGetUserFormInitialValues'
import useUpdateUserLanguage from '../../hooks/useUpdateUserLanguage'
import useUpdateUserMutation from '../../hooks/useUpdateUserMutation'
import UserProfileInputResolver from './UserProfileInputResolver'
import { useSchemaServiceDetails } from '../../../Schemas/useSchemaService'
import { dateFormat } from '../../../../../../shared/constants/dateFormat'
import FormField from '../../../../../Forms-v2/FormField'
import { InputType } from '../../../../../../types/shared/InputType'

type ProfileFormTypeProps = {
  editable: boolean
}

const ProfileForm = ({ editable }: ProfileFormTypeProps, ref: React.Ref<HTMLButtonElement>) => {
  const { t } = useTranslation()
  const { data: userData, isLoading: fetchIsLoading } = useGetCurrentUser()
  const { mutate: updateLanguage } = useUpdateUserLanguage()
  const { mutate: updateUserMutation } = useUpdateUserMutation()
  const initialValues = useGetUserFormInitialValues()
  const currentUserRole = useGetCurrentUser()?.data?.role
  const adminSuperAdmin = ['admin', 'super_admin'].includes(currentUserRole || '')
  const { handleSubmit, control } = useForm({ mode: 'onChange' })
  const { data } = useSchemaServiceDetails('users')

  const staticFields: {
    label: string
    name: string
    readonly: boolean
    type: FormQuestionContentType | 'language'
    disabled: boolean
    placeholder?: string
  }[] = [
    {
      label: `${t('user_profile.first_name', 'First Name')}`,
      name: 'first_name',
      disabled: false,
      readonly: false,
      type: 'TEXT',
    },
    {
      label: `${t('user_profile.last_name', 'Last Name')}`,
      name: 'last_name',
      disabled: false,
      readonly: false,
      type: 'TEXT',
    },
    {
      label: `${t('user_profile.email', 'Email')}`,
      name: 'email',
      disabled: !adminSuperAdmin,
      readonly: !adminSuperAdmin,
      type: 'TEXT',
    },
    {
      label: `${t('user_profile.role', 'Role')}`,
      name: 'role',
      disabled: !adminSuperAdmin,
      readonly: !adminSuperAdmin,
      type: 'TEXT',
    },
    {
      label: `${t('user_profile.language', 'Language')}`,
      name: 'language',
      disabled: false,
      readonly: false,
      placeholder: 'Select a language',
      type: 'language',
    },
  ]

  const onSubmit = (attributes: { [key in keyof Omit<Partial<UserType>, 'id' | 'internal_user'>]: any }) => {
    const staticFieldNames = staticFields.map((field) => field.name)

    const standardAttributes: { [k: string]: any } = {}
    const customAttributes: { [k: string]: any } = {}
    const tempAttributes = attributes as any

    Object.keys(tempAttributes).forEach((key) => {
      if (staticFieldNames.includes(key)) {
        standardAttributes[key as string] = tempAttributes[key]
      } else {
        customAttributes[key] = tempAttributes[key]
      }
    })

    standardAttributes.custom = {
      ...customAttributes,
    }

    updateUserMutation(standardAttributes as any)

    if (attributes.language !== initialValues.language) {
      updateLanguage({ language: attributes.language })
    }
  }

  const getAttribute = (key: string) => {
    if (fetchIsLoading || !userData) {
      return 'N/A'
    }

    return userData[key as keyof UserType]
  }

  function getValue(staticField: any) {
    if (!staticField) {
      return ''
    }
    return staticField.type === 'DATE' ? dayjs(staticField.values.value).format(dateFormat) : staticField.values.value
  }

  return (
    <div className="w-full flex flex-col">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        {staticFields?.map((staticField) => (
          <Controller
            key={staticField.name}
            name={staticField.name}
            control={control}
            defaultValue={getAttribute(staticField.name)}
            rules={{
              required: true,
            }}
            render={({ field: controllerField }) => (
              <div className="flex space-x-5 py-4 items-center w-full">
                <div className="text-sm font-semibold text-gray-600 w-48">
                  <p className="lowercase first-letter:uppercase">{staticField.label}</p>
                </div>
                <div className="text-sm font-medium text-gray-600 text-left w-full">
                  {editable ? (
                    <UserProfileInputResolver
                      key={staticField.name}
                      inputType={staticField.type}
                      value={controllerField.value}
                      disabled={staticField.disabled}
                      readonly={staticField.readonly}
                      onBlur={(value) => controllerField.onChange(value)}
                    />
                  ) : (
                    controllerField.value
                  )}
                </div>
              </div>
            )}
          />
        ))}
        {data?.custom_fields?.map((staticField) => (
          <Controller
            key={staticField.name}
            name={staticField.field_uuid!!}
            control={control}
            defaultValue={staticField.values}
            rules={{
              required: true,
            }}
            render={({ field: controllerField }) => (
              <div className="flex space-x-5 py-4 items-center w-full">
                <div className="text-sm font-semibold text-gray-600 w-48">
                  <p className="lowercase first-letter:uppercase">{staticField.name}</p>
                </div>
                <div className="text-sm font-medium text-gray-600 text-left w-full">
                  <FormField
                    className="px-1 py-0 p-0 gap-0"
                    field={{
                      value: staticField.values!!,
                      uuid: '1212',
                      id: '1212',
                      input: {
                        name: staticField.name,
                        type: staticField.type as unknown as (typeof BasicFormInputTypes)[number],
                      } as InputType,
                    }}
                    readonly={!editable}
                    updateField={(value) => controllerField.onChange(value.value)}
                    disabled={false}
                  />
                </div>
              </div>
            )}
          />
        ))}
        <button type="submit" className="hidden" ref={ref}>
          Submit
        </button>
      </form>
    </div>
  )
}

export default forwardRef(ProfileForm)
