import { useCallback, useMemo } from 'react'
import { InputSearchSelectItemType } from '../../types/shared/InputSearchSelectTypes'
import { FormQuestionResponseType, FormQuestionValue, SearchSelectType } from '../../types/Forms'
import { useGetCustomerSearch } from './useGetCustomerSearch'
import useGetSearchSelectOptionsFromFile from './useGetSearchSelectOptionsFromFile'

type SearchSelectOptionsMetadata = (
  | {
      searchType?: SearchSelectType | undefined | null
      options?: FormQuestionResponseType[]
    }
  | {
      searchType: Exclude<SearchSelectType, 'CUSTOM'> | undefined
      options: undefined
    }
) & {
  values: FormQuestionValue[]
  inputId?: string | null
  __uuid?: string
}

export const useSearchSelectOptions = ({
  searchType,
  options: optionsData,
  values,
  inputId,
  __uuid: questionUuid,
}: SearchSelectOptionsMetadata) => {
  const {
    data: dynamicOptionsData,
    isCustomSearch,
    isLoading: isCustomerSearchLoading,
  } = useGetCustomerSearch(searchType)

  const {
    options: externalDataOptions,
    visibleColumns,
    isLoading: areExternalOptionsLoading,
  } = useGetSearchSelectOptionsFromFile(questionUuid, inputId)

  const options: InputSearchSelectItemType[] | undefined = useMemo(
    () =>
      isCustomSearch
        ? optionsData?.map((option) => ({
            id: option.__uuid || option.uuid || '',
            label: option.display_name || option.value,
          })) || []
        : dynamicOptionsData?.map((option) => ({
            id: option.id,
            label: option.value,
          })),
    [dynamicOptionsData, isCustomSearch]
  )

  // use externalDataOptions if it's not an empty array, otherwise use dynamicOptionsData
  const optionsDataToUse = externalDataOptions && externalDataOptions.length > 0 ? externalDataOptions : options

  const selected = useMemo((): InputSearchSelectItemType | undefined => {
    // If externalDataOptions only have one option, return that option as preselected
    if (externalDataOptions && externalDataOptions.length === 1) return externalDataOptions[0]

    return optionsDataToUse?.find((option: InputSearchSelectItemType) => option.label === values[0]?.value)
  }, [externalDataOptions, optionsDataToUse, values])

  const selectedToOption = useCallback(
    (selected: InputSearchSelectItemType) => ({
      __uuid: selected.id ? selected.id.toString() : '',
      value: selected.label,
    }),
    [optionsDataToUse]
  )

  return {
    options: optionsDataToUse,
    visibleColumns,
    selected,
    selectedToOption,
    isLoading: isCustomerSearchLoading || areExternalOptionsLoading,
  }
}

export default useSearchSelectOptions
