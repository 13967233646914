import { Dispatch, SetStateAction } from 'react'
import SupplierUserForm from '../SupplierDetailsPage/SupplierUserForm/SupplierUserForm'
import useGetSupplierBranchId from '../hooks/useGetSupplierBranchId'
import SupplierUsers from './SupplierUsers'

interface IUsers {
  showNewUserForm: boolean
  setShowNewUserForm: Dispatch<SetStateAction<boolean>>
}

const Users: React.FC<IUsers> = ({ showNewUserForm, setShowNewUserForm }) => {
  const supplierBranchId = useGetSupplierBranchId()

  return showNewUserForm ? (
    <div className="px-6 py-6">
      <SupplierUserForm supplierBranchId={supplierBranchId} hideForm={() => setShowNewUserForm(false)} />
    </div>
  ) : (
    <SupplierUsers editable className="p-6" />
  )
}

export default Users
