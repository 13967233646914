import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { WorkflowBlock } from '../../../../../types/Workflow/WorkflowBlock'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import WorkflowActionProviderField from './components/WorkflowActionProviderField'
import {
  WorkflowAction,
  WorkflowActionAttribute,
  WorkflowActionProvider,
  WorkflowActionWrapped,
} from '../../../../../types/Workflow/WorkflowAction'
import WorkflowActionField from './components/WorkflowActionField'
import WorkflowActionAttributesForm from './components/WorkflowActionAttributesForm'
import { useWorkflowBlockMutations } from '../../../hooks/mutations'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import { Workflow } from '../../../../../types/Workflow/Workflow'
import useGetWorkflowActionProviders from '../../../hooks/actions/useGetWorkflowActionProviders'
import { getMergedDeepOverwritingArrays } from '../../../../../shared/utils/objectHelpers'
import { ToggleBoolean } from '../../../../../shared/components/atoms/Toggle'
import BaseInputWrapper from '../../../../../shared/components/atoms/Input/BaseInputWrapper'

interface IWorkflowActionFormProps {
  readonly?: boolean
  block: WorkflowBlock
  workflow: Workflow
  action?: WorkflowActionWrapped
}

const WorkflowActionForm: React.FC<IWorkflowActionFormProps> = ({
  readonly,
  block,
  workflow,
  action: wrappedAction,
}) => {
  const { t } = useTranslation()
  const { modal } = useUtils()

  const [provider, setProvider] = useState<WorkflowActionProvider | null>(null)
  const [actionDraft, setActionDraft] = useState<WorkflowAction | null>(wrappedAction?.action || null)
  const [forcePending, setForcePending] = useState<boolean>(wrappedAction?.force_pending || false)
  const { addAction, updateAction, isProcessing } = useWorkflowBlockMutations(workflow, block)
  const [errors, setErrors] = useState<Record<WorkflowActionAttribute['id'], string>>([])
  const { data: providers } = useGetWorkflowActionProviders()

  useEffect(() => {
    setForcePending(wrappedAction?.force_pending || false)
  }, [wrappedAction])

  useEffect(() => {
    if (!wrappedAction) return

    setProvider(providers?.find(({ name }) => name === wrappedAction.action?.provider_name) || null)
  }, [providers, wrappedAction])

  const validate = () => {
    if (!actionDraft) return false

    const missingAttributes = actionDraft.attributes.filter(
      (attribute) =>
        attribute.validation.includes('required') &&
        (attribute.value === undefined ||
          attribute.value === null ||
          attribute.value === '' ||
          (Array.isArray(attribute.value) && attribute.value.length === 0))
    )

    setErrors(Object.fromEntries(missingAttributes.map((attribute) => [attribute.id, t('forms.validation.required')])))

    return missingAttributes.length === 0
  }

  const handleSubmit = async () => {
    if (!actionDraft) return

    if (!validate()) {
      return
    }

    if (wrappedAction) {
      await updateAction({ uuid: wrappedAction.uuid, action: actionDraft, force_pending: forcePending })
    } else {
      await addAction({ ...actionDraft, force_pending: forcePending })
    }
    modal.set({ isOpen: false })
  }

  const handleActionUpdate = (updatedAction: WorkflowAction) => {
    setActionDraft(updatedAction)
  }

  const handleProviderSelection = (selectedProvider: WorkflowActionProvider | undefined) => {
    setProvider(selectedProvider || null)
  }

  const handleActionSelection = (selectedAction: WorkflowAction | undefined) => {
    let selectedActionPrefilled: WorkflowAction | undefined = selectedAction

    if (selectedAction && (block.type === 'APPROVER' || block.type === 'APPROVAL')) {
      selectedActionPrefilled = {
        ...selectedAction,
        attributes: selectedAction.attributes.map((attribute) => {
          if (attribute.input?.key !== 'actions-multi-user') {
            return attribute
          }

          return {
            ...attribute,
            __disabled: true,
            value: block.approvers.map((approver) => approver.user.user_id),
          }
        }),
      }
    }

    if (actionDraft && selectedActionPrefilled?.uuid === actionDraft?.uuid) {
      setActionDraft(getMergedDeepOverwritingArrays(selectedActionPrefilled, actionDraft))
    } else {
      setActionDraft(selectedActionPrefilled || null)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <WorkflowActionProviderField disabled={readonly} selected={provider} onSelect={handleProviderSelection} />
        {provider && (
          <WorkflowActionField
            provider={provider}
            disabled={readonly}
            selected={actionDraft}
            onSelect={handleActionSelection}
          />
        )}
      </div>
      {!!actionDraft && (
        <>
          <WorkflowActionAttributesForm action={actionDraft} errors={errors} onUpdate={handleActionUpdate} />
          <BaseInputWrapper label={t('actions.force_pending', 'Stop workflow on this action')}>
            <ToggleBoolean selected={forcePending} onSelect={(forcePending) => setForcePending(forcePending)} />
          </BaseInputWrapper>
        </>
      )}
      <div className="flex flex-row justify-between">
        <div className="flex items-center">
          {/* <Button variant="ghost" className="text-black-light-2 bg-transparent !p-0" onClick={() => {}}> */}
          {/*  {t('common.Delete', 'Delete')} */}
          {/* </Button> */}
        </div>
        <div className="flex flex-row gap-4">
          <Button onClick={() => modal.close()} variant="outlined">
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button
            variant="solid"
            className="bg-sky-blue-light text-white"
            onClick={handleSubmit}
            disabled={readonly || isProcessing}
          >
            {isProcessing && <CoreLoadingIcon />}
            {t('common.confirm', 'Confirm')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default WorkflowActionForm
