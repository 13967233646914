import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { Workflow } from '../../../../types/Workflow/Workflow'

export const useWorkflowInvalidate = (workflow: Workflow | undefined) => {
  const queryClient = useQueryClient()

  return useCallback(() => {
    queryClient.invalidateQueries(['workflows'])
    queryClient.invalidateQueries(['workflow', workflow?.uuid, 'draft'])
    queryClient.invalidateQueries(['workflow', workflow?.uuid, 'version', workflow?.version])
  }, [queryClient, workflow])
}

export default useWorkflowInvalidate
