import { useTranslation } from 'react-i18next'
import useIntakeFormStore from '../stores/useIntakeFormStore'
import useApplyFormSectionLogic from '../logic/useApplyFormSectionLogic'

const useIntakeFormSections = () => {
  const { t } = useTranslation()

  const intakeFormSections = useIntakeFormStore((state) => state.intake?.sections)
  const intakeFormSettings = useIntakeFormStore((state) => state.intake?.settings)
  const evaluatedSections = useApplyFormSectionLogic(intakeFormSections)

  let sectionsToUse = intakeFormSections
  // If form logic is enabled, use evaluated sections with logic applied, else use without logic
  if (intakeFormSettings?.logic === true) {
    sectionsToUse = evaluatedSections
  }

  // check if intakeFormSections contains a confirmation section
  // if not, add a confirmation section to the end of the sections
  const hasConfirmationSection = sectionsToUse?.some((section) => section.type_name === 'CONFIRMATION')
  if (!hasConfirmationSection) {
    sectionsToUse?.push({
      id: -1,
      name: t('generic.confirmation', 'Confirmation'),
      type: 2,
      fields: [],
      type_name: 'CONFIRMATION',
      meta: [],
      offerings: [],
    })
  }

  return sectionsToUse
}

export default useIntakeFormSections
