import usePostTag from '../../../../shared/components/atoms/tags/hooks/usePostTag'
import useRemoveTag from '../../../../shared/components/atoms/tags/hooks/useRemoveTag'
import useGetSupplierBranch from '../../../SupplierDetails/hooks/useGetSupplierBranch'
import useUpdatePreferredCache from './useUpdatePreferredCache'

export default (supplierBranchId: number, disabled: boolean | undefined, enabled?: boolean) => {
  const { data: supplierBranchData } = useGetSupplierBranch(supplierBranchId, !enabled)

  const preferred = !!supplierBranchData?.preferred

  const preferredKey = 'preferred'

  const updateCache = useUpdatePreferredCache(supplierBranchId)
  const { mutate: postTag } = usePostTag('general', updateCache)
  const { mutate: removeTag } = useRemoveTag('general', updateCache)

  const preferredTagId = supplierBranchData?.tags?.find((tag) => tag.name === preferredKey)?.id

  return () => {
    if (!disabled) {
      if (!preferred) {
        postTag(preferredKey)
      } else if (preferredTagId) removeTag({ id: preferredTagId, name: preferredKey })
    }
  }
}
