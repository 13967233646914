import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import ExternalDataSourcesTableBody from './ExternalDataSourcesTableBody'
import ExternalDataSourcesTableHeader from './ExternalDataSourcesTableHeader'
import useGetExternalDataEntitiesList from '../../hooks/useGetExternalDataEntitiesList'

export const ExternalDataSourcesTable = () => {
  const { data: externalData, isLoading } = useGetExternalDataEntitiesList()
  const { t } = useTranslation()

  if (isLoading)
    return (
      <div className="m-4">
        <CoreLoadingIcon />
      </div>
    )

  return (
    <TableContainer
      emptyState={
        !externalData?.length && (
          <div>{t('external_data.missing_external_data', 'You dont have any external data uploaded yet.')}</div>
        )
      }
    >
      <ExternalDataSourcesTableHeader />
      <ExternalDataSourcesTableBody />
    </TableContainer>
  )
}

export default ExternalDataSourcesTable
