import { useTranslation } from 'react-i18next'
import BaseSettingsSection from '../../components/BaseSettingsSection'
import CreateFormButton from '../../../Forms/FormBuilder/FormsPage/buttons/CreateFormButton'
import CompanyFormsList from '../../../Forms/FormBuilder/FormsPage/FormsTable/CompanyFormsList'

const FormsListTab: React.FC = () => {
  const { t } = useTranslation()

  return (
    <BaseSettingsSection
      title={t('generic.forms_plural', 'Forms')}
      description={t('generic.forms_description', 'Create forms to collect data from your users.')}
      action={
        <div className="w-full gap-x-2 ">
          <CreateFormButton isTestForm={false} />
        </div>
      }
    >
      <div className="overflow-hidden">
        <CompanyFormsList />
      </div>
    </BaseSettingsSection>
  )
}

export default FormsListTab
