import { useParams } from 'react-router'

export default () =>
  useParams<{
    formId: string
    questionId: string
    sectionId: string
    submissionId: string
    location: 'content' | 'settings' | 'workflow'
  }>()
