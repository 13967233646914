import { FC } from 'react'

interface IStatusIndicator {
  status?: string
  className: string
  textClassName: string
}

const StatusIndicator: FC<IStatusIndicator> = ({ status, className, textClassName }) => (
  <div className="flex items-center space-x-2 rounded-full ">
    <div className={`h-2 w-2 rounded-full ${className}`} />
    <span className={` ${textClassName}`}>{status || ''}</span>
  </div>
)

export default StatusIndicator
