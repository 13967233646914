import { useEffect } from 'react'
import { useHistory } from 'react-router'
import CoreLoadingIcon from '../CoreLoadingIcon'
import { IS_DEVELOPMENT } from '../../../shared/constants/environments'

const ErrorHandler = ({ error }: { error: Error }) => {
  const history = useHistory()

  useEffect(() => {
    if (IS_DEVELOPMENT) return

    history.push(`/?error=${error?.message?.length ? error.message : 'error.generic'}`)
    window.location.reload()
  }, [])

  return (
    <div className="flex h-screen w-full justify-center items-center text-gray-900">
      <CoreLoadingIcon />
    </div>
  )
}

export default ErrorHandler
