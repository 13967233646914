import SearchSelect, { ISearchSelect } from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionValue } from '../../../types/Forms'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import { CompanyUserType } from '../../../types/shared/RequisitionDataTypes'
import useUserOptions from '../../../shared/hooks/queries/useUserOptions'

type UserFieldProps = {
  selected: FormQuestionValue | undefined
  onSelect: (value: FormQuestionValue) => void
} & Omit<ISearchSelect, 'onChange' | 'options' | 'currentSelection' | 'onAdd'>

export const UserField = ({ selected, onSelect, disabled, ...searchSelectProps }: UserFieldProps) => {
  const { users: availableUsers, options: userOptions, findOption } = useUserOptions()

  const select = (userValue: FormQuestionValue | null | undefined) => {
    onSelect(userValue || { __uuid: '', value: '' })
  }

  const handleSelection = (userOption?: InputSearchSelectItemType) => {
    if (!userOption) {
      select(null)
      return
    }

    const selectedUser = availableUsers?.find((user) => user.id === userOption.id)

    if (!selectedUser) return

    select({ __uuid: selectedUser.id.toString(), value: selectedUser })
  }

  const selectedUserOption = findOption((selected?.value as CompanyUserType)?.id)

  return (
    <SearchSelect
      options={userOptions}
      onChange={handleSelection}
      onClear={() => select(null)}
      currentSelection={selectedUserOption}
      nullable={!disabled}
      disabled={disabled}
      {...searchSelectProps}
    />
  )
}

export default UserField
