import { useTranslation } from 'react-i18next'
import React from 'react'
import InputTemplatesProvider, { useInputTemplates } from '../../../CompanyInputs/providers/InputTemplatesProvider'
import BaseSettingsSection from '../../components/BaseSettingsSection'
import Button from '../../../../shared/components/atoms/buttons/Button'
import CompanyInputFilters from '../../../CompanyInputs/CompanyInputFilters'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import CompanyInputForm from '../../../CompanyInputs/CompanyInputForm'
import { Table, TableBody, TableHead, TableHeader, TableRow } from '../../../../core/components/Table/Table'
import { InputTemplate } from '../../../../types/Forms'
import InputTableRow from './InputTableRow'

const CompanyInputsContent: React.FC = () => {
  const { t } = useTranslation()
  const {
    templates: { data: inputs },
  } = useInputTemplates()
  const { modal } = useUtils()

  const openCreateInputModal = () => {
    modal.set({
      isOpen: true,
      title: t('inputs_modal_title.create', 'Create custom input type'),
      content: <CompanyInputForm />,
      className: 'max-w-[450px]',
    })
  }

  return (
    <BaseSettingsSection
      title={t('generic.inputs_plural', 'Inputs')}
      description={t('inputs.description', 'Inputs are used to collect data from your users.')}
      action={
        <div className="flex flex-row gap-2">
          <Button className="text-sm bg-sky-blue text-white" variant="solid" onClick={openCreateInputModal}>
            {t('inputs.add_input', 'Add Input')}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col space-y-5">
        <CompanyInputFilters />
        <Table className="bg-white shadow-rock-gray-light ">
          <TableHeader>
            <TableRow>
              <TableHead className="w-48 font-bold first-letter:uppercase">{t('schema.table_name', 'Name')}</TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">{t('inputs.type', 'Type')}</TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">{t('inputs.key', 'Key')}</TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('inputs.custom_key', 'Custom key')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('inputs.relationships', 'Relationships')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase text-right">
                {t('generic.actions', 'Actions')}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {inputs?.map((input: InputTemplate) => <InputTableRow key={input.uuid} input={input} />)}
          </TableBody>
        </Table>
      </div>
    </BaseSettingsSection>
  )
}

const InputsTab: React.FC = () => (
  <InputTemplatesProvider>
    <CompanyInputsContent />
  </InputTemplatesProvider>
)

export default InputsTab
