import { UserIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'
import { UserProfileType } from '../../../../../types/shared'
import activeMark from '../../../../assets/active-item-mark.svg'

interface ICoreSidebarProfile {
  href: string
  activeUrl: string
  minified?: boolean
  data: UserProfileType
}

const CoreSidebarProfile: React.FC<ICoreSidebarProfile> = ({ href, activeUrl, minified, data }) => {
  const isActive = activeUrl === href

  return (
    <div className="flex items-center ">
      {isActive && <img src={activeMark} alt="" className="absolute l-0" />}
      <div className="w-full mx-3 rounded-md">
        <NavLink
          to={href}
          className={`
          ${isActive ? 'text-white' : 'text-white opacity-70'}
          ${minified ? 'justify-center' : ''} 
          w-full p-2 hover:text-white flex rounded-md items-center`}
        >
          <div className="max-h-full">
            <UserIcon className="h-5" />
          </div>
          {!minified && (
            <span className="ml-3 pr-2 flex items-center text-sm truncate h-full">
              <span>{data.email}</span>
            </span>
          )}
        </NavLink>
      </div>
    </div>
  )
}

export default CoreSidebarProfile
