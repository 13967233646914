export const errorMessages = {
  required: 'generic.required',
  email: 'generic.valid_email',
  input_required: 'generic.input_required',
  type_number: 'generic.type_number',
  positive_number: 'generic.positive_number',
}

export const errorMessagesCustom = {
  required: 'generic.required',
}
