import CheckmarkIndicator from './CheckmarkIndicator'

interface ICheckableRequirement {
  checked: boolean
  text: string
  showErrors: boolean
}

const CheckableRequirement: React.FC<ICheckableRequirement> = ({ checked, text, showErrors }) => (
  <div className="flex gap-x-2">
    <CheckmarkIndicator checked={checked} showError={showErrors} />
    <p className="text-xs">{text}</p>
  </div>
)

export default CheckableRequirement
