import { useTranslation } from 'react-i18next'
import { HiCheck } from 'react-icons/hi2'
import TableCell from '../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../shared/components/atoms/SharedTable/TableRow'
import { ExternalDataColumn } from '../../../../types/ExternalDataManager/ExternalDataManagerTypes'

type ExternalDataTableRowProps = {
  column: ExternalDataColumn
}

const ExternalDataEmbeddingsTableRow: React.FC<ExternalDataTableRowProps> = ({ column }) => {
  const { t } = useTranslation()

  return (
    <TableRow>
      <TableCell>{column.column_name}</TableCell>
      <TableCell>{column.for_embeddings ? t('general.true', 'True') : t('general.false', 'False')}</TableCell>
      <TableCell>{column.primary_key && <HiCheck className="w-5 h-5" />}</TableCell>
      <TableCell />
    </TableRow>
  )
}

export default ExternalDataEmbeddingsTableRow
