import { useMutation, useQueryClient } from 'react-query'
import { toastr } from '../../../shared/components/organisms/toastr'
import SuppliersQueryKeys from '../../Suppliers/constants/SuppliersQueryKeys'
import { apiPostMainSupplierUser } from '../../Suppliers/services/SupplierBranchesService'

function usePostMainSupplierUser(supplierBranchId: number) {
  const cache = useQueryClient()

  return useMutation(
    (supplierUserId: number) =>
      apiPostMainSupplierUser({
        body: {
          tags: ['main'],
          category: 'general',
        },
        supplierUserId,
      }),
    {
      onSuccess: () => {
        cache.refetchQueries([SuppliersQueryKeys.SUPPLIER_USERS, supplierBranchId])
      },
      onError: () => {
        toastr.error('error.item_not_sent', 'errorResolveTask')
      },
    }
  )
}

export default usePostMainSupplierUser
