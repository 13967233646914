import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import WorkflowActionButton from '../../misc/WorkflowActionButton'
import useWorkflowBlockDraftMutations from '../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'
import { useWorkflow } from '../../../providers/WorkflowProvider'

export const WorkflowBuilderBlockControls = ({
  className,
  tab,
  ...divProps
}: HTMLAttributes<HTMLDivElement> & { tab?: string }) => {
  const { t } = useTranslation()
  const { deselect, readonly } = useWorkflow()
  const { removeAll, removeTasks } = useWorkflowBlockDraftMutations()
  const { reset, save } = useWorkflowBlockDraft()

  if (readonly) return null

  const handleRemoveAll = () => {
    if (tab === 'actions') {
      removeTasks()
    } else {
      removeAll()
    }
  }

  const handleSave = () => {
    const success = save()

    if (!success || tab === 'actions') return // don't deselect if we're in the actions tab
    deselect()
  }

  const handleCancel = () => {
    reset()
    deselect()
  }

  return (
    <div className={classnames('flex flex-row justify-between', className)} {...divProps}>
      <div className="flex items-center">
        <Button variant="ghost" className="text-black-light-2 bg-transparent !p-0" onClick={handleRemoveAll}>
          {t('common.remove_all', 'Remove all')}
        </Button>
      </div>
      <div className="flex flex-row gap-4">
        <WorkflowActionButton onClick={handleCancel} variant="outlined">
          {t('common.cancel', 'Cancel')}
        </WorkflowActionButton>
        <WorkflowActionButton onClick={handleSave}>{t('common.save', 'Save')}</WorkflowActionButton>
      </div>
    </div>
  )
}

export default WorkflowBuilderBlockControls
