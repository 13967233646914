import { useFormContext } from '../../../../core/providers/FormProvider'
import { FormQuestionType } from '../../../../types/Forms/formTypes'
import useGetFormData from '../../hooks/useGetFormData'
import useGetFormParams from './useGetFormParams'
import useUpdateForm from './useUpdateForm'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'

export default () => {
  const { sectionId } = useGetFormParams()
  const { updateForm } = useUpdateForm()
  const { data: formData } = useGetFormData()
  const {
    question: [question],
  } = useFormContext()

  return (passedQuestion?: FormQuestionType, passedSectionId?: string) => {
    const sectionIdToChange = passedSectionId || sectionId
    const newQuestion = passedQuestion || question

    if (!newQuestion || !formData) return Promise.resolve()

    return updateForm({
      template: {
        sections: formData.template.sections?.map((section) =>
          section.__uuid === sectionIdToChange
            ? getMergedDeepOverwritingArrays(section, {
                fields: section.fields.map((sectionQuestion) =>
                  sectionQuestion.__uuid === newQuestion.__uuid ? newQuestion : sectionQuestion
                ),
              })
            : section
        ),
      },
    })
  }
}
