import { useUtils } from '../../../../core/providers/UtilsProvider'
import ConfirmationModal from '../../../../shared/components/organisms/modals/ConfirmationModal'

export default () => {
  const { modal } = useUtils()

  return <T,>(onConfirm: () => Promise<T>): Promise<T | void> =>
    new Promise((resolve, reject) => {
      const unpublishConfirmationModal = (
        <ConfirmationModal
          onConfirm={() => {
            onConfirm().then(resolve).catch(reject)
          }}
          onCancel={resolve}
          leftNote="You are going to modify a published form. This action will unpublish your form, and users will not be able to create submissions for this form until you publish it again."
          confirmString="Save & unpublish"
        />
      )

      modal.set({
        isOpen: true,
        content: unpublishConfirmationModal,
        title: 'Are you sure you want to unpublish the form?',
        className: 'w-[650px]',
        keepOpenOnBackdrop: true,
      })
    })
}
