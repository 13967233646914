import cn from '../../../../lib/util'

interface ITableCell extends React.HTMLAttributes<HTMLTableCellElement> {
  first?: boolean
  last?: boolean
  highlight?: boolean
  className?: string
  dataCy?: string
  colSpan?: number
}

const TableCell: React.FC<ITableCell> = ({ children, first, highlight, className, dataCy, colSpan, ...rest }) => (
  <td
    className={cn('table-cell p-4 text-sm border-t border-gray-100', highlight && 'border-indigo-500', className)}
    colSpan={colSpan}
    data-cy={dataCy}
    {...rest}
  >
    {children}
  </td>
)

export default TableCell
