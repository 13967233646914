import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import CorePageContainerLayout from '../../../../core/components/layout/CorePageContainerLayout'
import CoreTopbarLayout from '../../../../core/components/layout/CoreTopbarLayout'
import useGetFormData from '../../hooks/useGetFormData'
import Body from './Body'
import useHandleUnsavedNavigation from './hooks/useHandleUnsavedNavigation'
import QuestionTopbar from './QuestionTopbar'
import QuestionNavigationTabs from './QuestionNavigationTabs'

const FormQuestionPage: React.FC = () => {
  useHandleUnsavedNavigation()
  const { isLoading } = useGetFormData()

  if (isLoading) return <CoreLoadingIcon />

  return (
    <CoreTopbarLayout topbar={<QuestionTopbar />}>
      <CorePageContainerLayout>
        <QuestionNavigationTabs />
        <Body />
      </CorePageContainerLayout>
    </CoreTopbarLayout>
  )
}

export default FormQuestionPage
