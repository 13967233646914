import { useTranslation } from 'react-i18next'

const NewWorkflowModalHeader = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-y-2">
      <p>{t('workflows.create_new_workflow')}</p>
      <p className="text-secondary-gray text-sm pb-6">{t('workflows.fill_info')}</p>
    </div>
  )
}

export default NewWorkflowModalHeader
