/* eslint-disable no-use-before-define */
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import cn from '../../../lib/util'
import { FormQuestionAllowedFiletypes } from '../../../types/Forms'

interface IDropzoneArea {
  onDrop: (files: File[]) => void
  multiple?: boolean
  allowedFileTypes?: FormQuestionAllowedFiletypes[]
  disabled?: boolean
}

const DropzoneArea: FC<IDropzoneArea> = ({ onDrop, multiple = false, allowedFileTypes, disabled }) => {
  const { t } = useTranslation()

  const validator = (file: File) => {
    if (
      !allowedFileTypes?.map((o) => o.id).includes(file.type) &&
      !((allowedFileTypes?.filter((fileType) => file.type.match(fileType.id)).length || 0) > 0)
    ) {
      return {
        code: 'Not allowed',
        message: `${t('generic.allowed-file-types', 'Only files of the following types are allowed: ')} ${
          allowedFileTypes?.map((o) => o.label).join(', ') || ''
        }`,
      }
    }
    return null
  }

  const executeOnDrop = (acceptedFiles: File[]) => {
    acceptedFiles.length && onDrop(acceptedFiles)
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    validator,
    onDrop: executeOnDrop,
    disabled,
  })

  return (
    <div {...getRootProps()} className="">
      <input {...getInputProps()} multiple={multiple} className="hidden" type="file" disabled={disabled} />
      {fileRejections.map(({ file, errors }) => (
        <ul key={file.name} className=" list-none py-2">
          {errors.map((e) => (
            <li key={e.code} className="flex space-x-2 bg-red-light py-1 rounded-md text-white px-2">
              <ExclamationTriangleIcon className="w-4" />
              <span>
                <span className="font-semibold">{file.name}</span>: {e.message}
              </span>
            </li>
          ))}
        </ul>
      ))}
      <div
        className={cn(
          'cursor-pointer hover:border-sky-blue-light text-secondary-gray text-sm border border-gray-200 rounded-md text-center py-4 shadow-sm',
          isDragActive ? 'bg-sky-blue-light transition transition-colors' : 'bg-white',
          disabled ? 'border-gray-200 bg-gray-100 cursor-not-allowed hover:border-gray-200' : ''
        )}
      >
        {isDragActive
          ? t('generic.drop-files', 'Drop files to upload')
          : t('generic.drop-instructions', 'Drag + drop some files here, or click to select files')}
      </div>
    </div>
  )
}

export default DropzoneArea
