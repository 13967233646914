import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import Input from '../../../../../shared/components/atoms/Input/Input'
import useUrlParams from '../../../hooks/useUrlParams'

const LhotseHomePageSearch = () => {
  const { t } = useTranslation()

  const { getParam: getFilter, setParam: setFilter } = useUrlParams()

  return (
    <div>
      <Input
        placeholder={t('generic.search', 'Search')}
        defaultValue={getFilter('query')}
        className="pl-8 transition-colors !shadow-sm border-white hover:border-sky-blue cursor-text "
        onChange={(e) => {
          setFilter({ query: e.currentTarget.value })
        }}
        lead={
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 mr-10">
            <MagnifyingGlassIcon className="w-4 opacity-70" />
          </div>
        }
      />
    </div>
  )
}

export default LhotseHomePageSearch
