import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import FormField from '../../../Forms-v2/FormField'
import { generateField } from '../../../Forms-v2/helpers'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { useValidation } from '../../../../core/hooks/useValidation'
import { getFileTypeOptions } from '../../../Forms/FormBuilder/QuestionPage/QuestionSettings/OtherSettings/fileTypeOptions'
import DropzoneArea from '../../../../shared/components/atoms/DropzoneArea'
import InputError from '../../../Forms/FormBuilder/QuestionTypes/atoms/InputError'
import useExternalDataSourceMutations from '../../hooks/useExternalDataSourceMutations'
import useExternalDataUpload from '../../hooks/useExternalDataUpload'

export type ExternalDataDetails = {
  name: string
  file: File | null
}

type ExternalDataUploadModalBodyProps = {
  uploadEnabled?: boolean
  onSubmit: (data: ExternalDataDetails) => void
  initialData?: ExternalDataDetails
}

export const ExternalDataModalBody = ({
  uploadEnabled = true,
  onSubmit,
  initialData,
}: ExternalDataUploadModalBodyProps) => {
  const { t } = useTranslation()
  const [externalDataDetails, setExternalDataDetails] = useState<ExternalDataDetails>(
    initialData || { name: '', file: null }
  )
  const { modal } = useUtils()
  const { isProcessing: isUpdating } = useExternalDataSourceMutations()
  const { isProcessing: isUploading } = useExternalDataUpload()

  const { errors, validate } = useValidation(externalDataDetails, {
    name: { required: true },
    file: { required: uploadEnabled },
  })

  const handleUpdate = (updatedField: keyof ExternalDataDetails, updatedValue: string | File | undefined) => {
    if (updatedValue === undefined) return

    setExternalDataDetails({ ...externalDataDetails, [updatedField]: updatedValue })
  }

  const handleSubmit = async () => {
    if (!validate()) return

    await onSubmit(externalDataDetails)
    modal.set({ isOpen: false })
  }

  return (
    <div className="flex flex-col gap-11">
      <FormField
        className="w-full"
        field={generateField({
          id: 'name',
          input: { type: 'TEXT', name: '', placeholder: t('generic.title_placeholder', 'Title here') },
          value: { value: externalDataDetails.name },
          error: errors.name,
        })}
        updateField={(updatedField) => handleUpdate(updatedField.id, updatedField.value?.value as string)}
        readonly={false}
      />
      {uploadEnabled && (
        <>
          <DropzoneArea
            multiple={false}
            onDrop={(files) => handleUpdate('file', files[0])}
            allowedFileTypes={getFileTypeOptions('text/csv')}
          />
          <div>
            <span className="text-gray-500">{externalDataDetails.file?.name}</span>
          </div>
          <InputError error={errors.file} />
        </>
      )}
      <div>
        <SaveCancelFormButtons
          isLoading={isUpdating || isUploading}
          onCancel={() => modal.set({ isOpen: false })}
          onSave={handleSubmit}
        />
      </div>
    </div>
  )
}

export default ExternalDataModalBody
