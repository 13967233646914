import { MdArrowBack } from 'react-icons/md'

interface IBackToNavigationButton {
  text: string
  onClick: () => any
  className?: string
  titleClassName?: string
}

const BackToNavigationButton: React.FC<IBackToNavigationButton> = ({ text, onClick, className, titleClassName }) => (
  <div className={`${className} pt-3 flex w-full h-fit px-7 bg-white text-primary-gray text-xs`}>
    <button type="button" className="flex gap-x-2 items-center" onClick={onClick}>
      <MdArrowBack />
      <p className={titleClassName}>{text}</p>
    </button>
  </div>
)

export default BackToNavigationButton
