import { AdjustmentsHorizontalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { debounce } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../../core/components/CoreLoadingIcon'
import Input from '../../../../../../shared/components/atoms/Input/Input'
import SearchMultiSelect from '../../../../../../shared/components/atoms/SearchSelect/SearchMultiSelect'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import { priceToGermanPriceString } from '../../../../../../shared/constants/currencyFormat'
import useCataglogSearchService, {
  UniteCatalogSearchRequestType,
  UniteCatalogSearchResponseType,
} from '../hooks/useCatalogSearchService'
import UniteSearchModalDetails from './UniteSearchModalDetails'
import UniteSearchStockStatus from './UniteSearchStockStatus'

interface IUniteSearchModal {
  initialSearch?: string
  onAdd: (data: any) => void
}

const DetailRow = ({ title, value, id }: { title: string; value: string | number; id: string }) => (
  <div key={id + title} className="">
    <div className="font-semibold">{value}</div>
    <div className=" black-light-2">{title}</div>
  </div>
)

const UniteSearchModal: FC<IUniteSearchModal> = ({ initialSearch, onAdd }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<string | undefined>(undefined)
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false)
  const [dynamicFiltersVisible, setDynamicFiltersVisible] = useState<boolean>(false)
  const [filters, setFilters] = useState<{
    manufacturerFilter: { id: string; label: string }[]
    classficationFilter: { id: string; label: string }[]
  }>({ manufacturerFilter: [], classficationFilter: [] })
  const [queryString, setQueryString] = useState('')
  const [results, setResults] = useState<UniteCatalogSearchResponseType | undefined>(undefined)
  const { t } = useTranslation()

  const {
    search: { mutateAsync: search },
  } = useCataglogSearchService()

  const generateRequestBody = (query: string): UniteCatalogSearchRequestType => {
    const flatFilters: Partial<UniteCatalogSearchRequestType> = {}

    const accFilters =
      filters &&
      Object.keys(filters).reduce((acc, filter) => {
        /* @ts-expect-error */
        flatFilters[filter] = { bucketIds: filters[filter]?.map((f): any => f.id.toString()) }
        return flatFilters
      }, flatFilters)

    return {
      query,
      ...accFilters,
    }
  }

  const performSearch = useMemo(
    () =>
      debounce(async (query: UniteCatalogSearchRequestType) => {
        const queryResults = await search(query)
        setResults(queryResults)

        // TODO: Dynamic filters
        const selectedFilters = {
          manufacturerFilter: queryResults.manufacturerFacet.selectedBuckets.map((b) => ({
            id: b.bucketId,
            label: b.value,
          })),
          classficationFilter: queryResults.classificationIdFacet.selectedBuckets.map((b) => ({
            id: b.bucketId,
            label: b.value,
          })),
        }

        // setFilters(selectedFilters)
        setIsLoading(false)
      }, 800),
    [search]
  )

  const handleSearch = (query: string) => {
    performSearch.cancel()
    setIsLoading(true)
    performSearch(generateRequestBody(query))
  }

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryString(e.target?.value)
    if (e.target?.value.length < 3) return
    handleSearch(e.target?.value)
  }

  const handleChangeFilters = (newFilter: any) => {
    setFilters({ ...filters, ...newFilter })
  }

  useEffect(() => {
    if (initialSearch)
      handleSearchQueryChange({ target: { value: initialSearch } } as React.ChangeEvent<HTMLInputElement>)
  }, [initialSearch])

  return (
    <div className="max-w-6xl mx-auto">
      {!activeItem && (
        <div className="relative">
          <div className="flex flex-col w-full sticky top-0 gap-2 bg-white pb-2 mt-6 ">
            <div className="flex gap-2 items-center">
              <Input
                autoFocus
                placeholder={t('generic.search', 'Search')}
                defaultValue={initialSearch}
                className="pl-8"
                onChange={handleSearchQueryChange}
                lead={
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 mr-10">
                    <MagnifyingGlassIcon className="w-4 opacity-70" />
                  </div>
                }
              />
              <div className="absolute right-0">
                {isLoading ? (
                  <div className="px-4">
                    <CoreLoadingIcon />
                  </div>
                ) : (
                  <Button onClick={() => setFiltersVisible(!filtersVisible)}>
                    <AdjustmentsHorizontalIcon className="w-5 text-gray-400" />
                  </Button>
                )}
              </div>
            </div>
            {filtersVisible && (
              <div className="flex flex-col">
                <div className="w-full grid grid-cols-3 gap-2">
                  <div>
                    <SearchMultiSelect
                      placeholder="Manufacturer"
                      currentSelection={filters?.manufacturerFilter}
                      options={results?.manufacturerFacet.availableBuckets.map((facet) => ({
                        id: facet.bucketId,
                        label: facet.value,
                      }))}
                      onChange={(selection) => handleChangeFilters({ manufacturerFilter: selection })}
                    />
                  </div>
                  <div>
                    <SearchMultiSelect
                      placeholder="Classification"
                      currentSelection={filters?.classficationFilter}
                      options={results?.classificationIdFacet.availableBuckets.map((facet) => ({
                        id: facet.bucketId,
                        label: facet.value,
                      }))}
                      onChange={(selection) => handleChangeFilters({ classficationFilter: selection })}
                    />
                  </div>
                  <div className=" flex  gap-2 items-center justify-end">
                    {results?.dynamicFacets.length ? (
                      <Button onClick={() => setDynamicFiltersVisible(!dynamicFiltersVisible)}>
                        More ({results?.dynamicFacets.length})
                      </Button>
                    ) : null}
                    <Button
                      onClick={() =>
                        setFilters({
                          manufacturerFilter: [],
                          classficationFilter: [],
                        })
                      }
                    >
                      Reset
                    </Button>
                    <Button className="btn-primary" onClick={() => handleSearch(queryString)}>
                      Apply
                    </Button>
                  </div>
                </div>
                {dynamicFiltersVisible && (
                  <div className="flex flex-col gap-2 pt-4 mt-4 border-t">
                    {results?.dynamicFacets.map((dynamicFacet) => (
                      <div key={dynamicFacet.facetId}>
                        <SearchMultiSelect
                          currentSelection={
                            dynamicFacet.selectedBuckets.map((bucket) => ({
                              id: bucket.bucketId,
                              label: bucket.value,
                            })) || []
                          }
                          placeholder={dynamicFacet.name}
                          options={dynamicFacet.availableBuckets.map((facet) => ({
                            id: facet.bucketId,
                            label: facet.value,
                          }))}
                          onChange={(selection) => console.log(selection)}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {/* <div>Price Range</div>
                <div>Only In Stock</div> */}
              </div>
            )}
          </div>
          {!dynamicFiltersVisible && (
            <div className="grid grid-cols-1 divide-y ">
              {results?.items.length
                ? results.items.map((result) => (
                    <div className="p-4 hover:bg-gray-50 cursor-pointer py-8" key={result.id}>
                      <div
                        className="flex  justify-between h-full gap-8 items-center"
                        onClick={() => setActiveItem(result.id)}
                        role="button"
                        tabIndex={-1}
                      >
                        <div className="w-52 flex">
                          <img className="w-48 max-h-44" src={result.previewImage?.url} alt={result.name} />
                        </div>
                        <div className="flex-grow flex flex-col justify-between h-24">
                          <div className="flex justify-between">
                            <div className="font-semibold h-4 max-w-3/4">
                              <div className="line-clamp-1">{result.name}</div>
                            </div>
                            <div className="w-48 font-semibold flex justify-between items-center pb-8">
                              <div>{priceToGermanPriceString(result.price)}</div>
                              <UniteSearchStockStatus inStock={result.inStock} />
                            </div>
                          </div>
                          <div className="flex justify-between text-xs ">
                            <DetailRow title="Manufacturer" value={result.manufacturer} id={result.id} />
                            <DetailRow title="Delivery time" value={result.deliveryTime} id={result.id} />
                            <DetailRow title="Article Id" value={result.manufacturerArticleId} id={result.id} />
                            <div key="shipping" className="w-48">
                              <div className="">{priceToGermanPriceString(result.shippingCosts)}</div>
                              <div className=" black-light-2">Shipping costs</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          )}
        </div>
      )}

      {activeItem && <UniteSearchModalDetails setActiveItem={setActiveItem} activeItem={activeItem} onAdd={onAdd} />}
    </div>
  )
}

export default UniteSearchModal
