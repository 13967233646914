import {
  ArrowPathIcon,
  ArrowUturnRightIcon,
  CheckIcon,
  ClockIcon,
  DocumentCheckIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { IntakeChangeLogItemEventType, IntakeChangeLogItemType } from '../../../../types/Intake'

export const getItemEventBackgroundColour = (event: IntakeChangeLogItemEventType | string) => {
  switch (event) {
    case 'created':
      return 'bg-[#64B587]'
    case 'pending':
      return 'bg-[#FFAF3B]'
    case 'ongoing':
      return 'bg-[#DF8600]'
    case 'skipped':
      return 'bg-[#A0A0A0]'
    case 'completed':
      return 'bg-[#64B587]'
    case 'error':
      return 'bg-[#FF8781]'
    case 'approved':
      return 'bg-[#64B587]'
    case 'declined':
      return 'bg-[#FF8781]'
    default:
      return 'bg-[#A0A0A0]'
  }
}

export const getItemEventIcon = (event: IntakeChangeLogItemEventType) => {
  switch (event) {
    case 'created':
      return PencilIcon
    case 'updated':
      return PencilIcon
    case 'pending':
      return ClockIcon
    case 'ongoing':
      return ArrowPathIcon
    case 'skipped':
      return ArrowUturnRightIcon
    case 'completed':
      return CheckIcon
    case 'error':
      return ExclamationTriangleIcon
    case 'approved':
      return DocumentCheckIcon
    case 'declined':
      return XMarkIcon
    case 'deleted':
      return XMarkIcon
    default:
      return CheckIcon
  }
}

export const useGetItemType = (type: string) => {
  const { t } = useTranslation()
  switch (type) {
    case 'intake_flow':
      return t('intake.changelog_type.workflow', 'Workflow')
    case 'intake_flow_input':
      return t('intake.changelog_type.workflow_input', 'Workflow Input')
    case 'intake_flow_section':
      return t('intake.changelog_type.workflow_section', 'Workflow Section')
    case 'offering':
      return t('intake.changelog_type.offering', 'Offering')
    case 'offering_offer':
      return t('intake.changelog_type.offer', 'Offer')
    case 'offering_input':
      return t('intake.changelog_type.offering_input', 'Offering Input')
    case 'address':
      return t('intake.changelog_type.address', 'Address')
    default:
      return ''
  }
}

export const useGetItemEvent = (event: IntakeChangeLogItemEventType) => {
  const { t } = useTranslation()
  switch (event) {
    case 'created':
      return t('intake.changelog_event.created', 'Created')
    case 'updated':
      return t('intake.changelog_event.updated', 'Changed')
    case 'deleted':
      return t('intake.changelog_event.deleted', 'Deleted')
    case 'pending':
      return t('intake.changelog_event.pending', 'Pending')
    case 'ongoing':
      return t('intake.changelog_event.ongoing', 'Ongoing')
    case 'skipped':
      return t('intake.changelog_event.skipped', 'Skipped')
    case 'completed':
      return t('intake.changelog_event.completed', 'Completed')
    case 'error':
      return t('intake.changelog_event.error', 'Error')
    case 'approved':
      return t('intake.changelog_event.approved', 'Approved')
    case 'declined':
      return t('intake.changelog_event.declined', 'Declined')
    default:
      return ''
  }
}

export const useGetItemHeading = (item: IntakeChangeLogItemType) => {
  const { label, auditable } = item
  const type = useGetItemType(item.type)
  const event = useGetItemEvent(item.event)
  const block = auditable?.workflow_block

  return [`'${block?.name || auditable?.name || label}'`, type, event].filter(Boolean).join(' ')
}
