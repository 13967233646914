import { useTranslation } from 'react-i18next'
import AuthLayout from '../shared/AuthLayout'
import { useGetUrlSearchParam } from '../../../../core/hooks/useGetUrlParam'
import { FormFieldType } from '../shared/Types'
import useResetPassword from '../../hooks/useResetPassword'
import AuthForm, {
  emailValidator,
  passwordConfirmationValidator,
  passwordCreationValidator,
} from '../shared/Components/AuthForm'

const ResetPassword = () => {
  const token = useGetUrlSearchParam('token')
  const email = useGetUrlSearchParam('email')
  const { t } = useTranslation()
  const { mutate: submitResetPassword, isLoading } = useResetPassword()

  const form: FormFieldType[] = [
    {
      attribute: 'email',
      name: t('generic.email', 'Email'),
      placeholder: t('generic.email_placeholder', 'Email'),
      value: email ?? '',
      type: 'text',
      validator: emailValidator,
    },
    {
      attribute: 'password',
      name: t('generic.password', 'Password'),
      placeholder: t('generic.password', 'Password'),
      value: '',
      type: 'password',
      validator: passwordCreationValidator,
    },
    {
      attribute: 'password_confirmation',
      name: t('generic.password_confirmation', 'Confirm Password'),
      placeholder: t('generic.password_confirmation', 'Confirm Password'),
      value: '',
      type: 'password',
      validator: passwordConfirmationValidator,
    },
  ]
  const onSubmit = (data: any) => {
    try {
      submitResetPassword({ ...data, token })
    } catch (error) {
      console.log(error)
    }
  }

  const onError = (data: any) => console.log(data)

  return (
    <AuthLayout title={t('generic.forgot_password', 'Forgot Password')} showBackButton>
      <AuthForm
        fields={form}
        onSubmit={onSubmit}
        onError={onError}
        btnText={t('generic.reset_password', 'Reset Password')}
        isSubmitting={isLoading}
      />
    </AuthLayout>
  )
}
export default ResetPassword
