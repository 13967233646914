import type { PartialDeep } from 'type-fest'
import { cloneDeep, uniqueId, remove } from 'lodash'
import uuid from 'react-uuid'
import {
  FormQuestionLogicType,
  FormQuestionType,
  FormSectionType,
  SwitchLogic,
  SwitchLogicAdditionalCase,
  SwitchLogicCase,
} from '../../../../../../../types/Forms'
import useQuestionLogicSettingState from './useQuestionLogicSettingState'
import {
  getMergedDeepOverwritingArrays,
  mergeDeepOverwritingArrays,
} from '../../../../../../../shared/utils/objectHelpers'

export const getFreshSwitchCase = (): SwitchLogicCase<string> => ({
  __uuid: uuid(),
  value: '',
  comparisonType: 'equal',
  target: '',
})

export const getFreshAdditionalCondition = (): SwitchLogicAdditionalCase<string> => ({
  __uuid: uuid(),
  value: '',
  comparisonType: 'equal',
  source: '',
})

export default (question: FormQuestionType, section: FormSectionType) => {
  const [logic, setLogic] = useQuestionLogicSettingState(question, section)

  const getMutableSwitchLogic = () => cloneDeep(logic?.switch) || { cases: [] }

  const updateLogic = (switchLogic: SwitchLogic) => {
    if (!logic) return

    setLogic(
      getMergedDeepOverwritingArrays<FormQuestionLogicType>(logic, {
        switch: switchLogic,
      })
    )
  }

  const addCase = (newCase: PartialDeep<Omit<SwitchLogicCase<string>, 'uuid'>> = {}) => {
    const switchLogic = getMutableSwitchLogic()

    switchLogic.cases.push({
      ...getFreshSwitchCase(),
      ...newCase,
    })

    updateLogic(switchLogic)
  }

  const addCondition = (newCase: PartialDeep<SwitchLogicCase<string>> = {}) => {
    // Get Switch Logic Case
    const switchLogic = getMutableSwitchLogic()
    const updateIndex = switchLogic.cases.findIndex((c) => c.__uuid === newCase.__uuid)

    if (!switchLogic.cases[updateIndex].additionalConditions) {
      switchLogic.cases[updateIndex].additionalConditions = []
    }

    switchLogic.cases[updateIndex].additionalConditions?.push(getFreshAdditionalCondition())
    updateLogic(switchLogic)
  }

  const updateCondition = (
    logicCaseIndex: number,
    additionalConditionIndex: number,
    updatedCase: PartialDeep<SwitchLogicAdditionalCase<string>>
  ) => {
    const switchLogic = getMutableSwitchLogic()

    if (switchLogic?.cases?.[logicCaseIndex].additionalConditions?.[additionalConditionIndex] !== undefined) {
      switchLogic.cases[logicCaseIndex].additionalConditions![additionalConditionIndex] = {
        ...switchLogic.cases[logicCaseIndex].additionalConditions![additionalConditionIndex],
        ...updatedCase,
      }
    }

    updateLogic(switchLogic)
  }

  const removeCondition = (logicCaseIndex: number, additionalConditionIndex: number) => {
    if (!logic) return

    const switchLogic = getMutableSwitchLogic()

    switchLogic.cases[logicCaseIndex].additionalConditions = switchLogic.cases[
      logicCaseIndex
    ].additionalConditions?.filter((_, index) => index !== additionalConditionIndex)

    updateLogic(switchLogic)
  }

  const updateCase = (uuid: string, updatedCase: PartialDeep<SwitchLogicCase<string>>) => {
    if (!logic) return

    const switchLogic = getMutableSwitchLogic()

    const existingCase = switchLogic.cases.find((existingCase) => existingCase.__uuid === uuid)

    if (!existingCase) return

    mergeDeepOverwritingArrays(existingCase, updatedCase)

    updateLogic(switchLogic)
  }

  const removeCase = ({ __uuid }: SwitchLogicCase<string>) => {
    if (!logic) return

    const switchLogic = getMutableSwitchLogic()

    remove(switchLogic.cases, (existingCase) => existingCase.__uuid === __uuid)
    updateLogic(switchLogic)
  }

  const updateDefault = (newTarget: string) => {
    const switchLogic = getMutableSwitchLogic()

    switchLogic.default = newTarget
    updateLogic(switchLogic)
  }

  return {
    addCase,
    removeCase,
    addCondition,
    updateCondition,
    removeCondition,
    updateCase,
    updateDefault,
  }
}
