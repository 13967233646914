import Button from '../../../../shared/components/atoms/buttons/Button'
import useGetFormParams from '../hooks/useGetFormParams'
import useSaveQuestion from '../hooks/useSaveQuestion'
import useHasUnsavedChanges from '../QuestionPage/hooks/useHasUnsavedChanges'

const SaveQuestionButton: React.FC = () => {
  const { questionId } = useGetFormParams()

  const saveQuestion = useSaveQuestion()

  const hasUnsavedChanges = useHasUnsavedChanges()

  if (!questionId) return null

  return (
    <div className="flex space-x-4">
      <Button variant="solid" color="sky-blue-light" onClick={() => saveQuestion()} disabled={!hasUnsavedChanges}>
        Save question
      </Button>
    </div>
  )
}

export default SaveQuestionButton
