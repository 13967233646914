import { useMemo } from 'react'
import { Offering } from '../../../../../types/Offering'
import getLogicAllowedQuestions from './getLogicAllowedQuestions/getLogicAllowedQuestions'

export default (offerings: Offering[] | undefined | null) => {
  // Evaluate the offerings using logic
  const offeringsAllowedQuestions = offerings?.map((offeringSection) => {
    const logicAllowedQuestions = getLogicAllowedQuestions(offeringSection.fields)
    return {
      offeringUuid: offeringSection.uuid,
      logicAllowedQuestions,
    }
  })

  return useMemo(() => offeringsAllowedQuestions, [offerings, offeringsAllowedQuestions])
}
