import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DropzoneArea from '../../../shared/components/atoms/DropzoneArea'
import MediaTable from '../../../shared/components/atoms/FileUpload/MediaTable'
import { MediaType } from '../../../types/shared/MediaTypes'
import { FormQuestionAllowedFiletypes } from '../../../types/Forms'

type FileFieldProps = {
  mediaList: MediaType[]
  onUpload?: (files: File[]) => void
  onDelete?: (media: MediaType) => void
  disabled: boolean
  canModify: true | undefined
  canAdd: boolean
  errorMessage: React.ReactNode
  allowedFileTypes?: FormQuestionAllowedFiletypes[]
}

const FileField: React.FC<FileFieldProps> = ({
  mediaList,
  onUpload,
  onDelete,
  disabled,
  canModify,
  canAdd,
  errorMessage,
  allowedFileTypes,
}) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      onUpload && onUpload(acceptedFiles)
    },
    [onUpload]
  )

  return (
    <div className={disabled ? 'cursor-not-allowed' : ''}>
      {mediaList.length > 0 && <MediaTable disabled={disabled} media={mediaList} onDelete={canModify && onDelete} />}

      <div className="mt-2">
        {onUpload && canModify && canAdd && (
          <DropzoneArea onDrop={onDrop} multiple allowedFileTypes={allowedFileTypes} />
        )}
      </div>
      {errorMessage}
    </div>
  )
}

export default FileField
