import { useMutation } from 'react-query'
import { useMemo } from 'react'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'
import { Workflow } from '../../../../types/Workflow/Workflow'
import {
  createWorkflowBlock,
  createWorkflowStep,
  CreateWorkflowStepRequestBody,
  deleteWorkflowBlock,
  updateWorkflowBlock,
} from '../../services'
import useFindWorkflowBlockStep from '../useFindWorkflowBlockStep'
import { useWorkflowInvalidate } from './useWorkflowInvalidate'

const throwWorkflowUndefinedError = () => Promise.reject(new Error('Workflow undefined'))
const throwStepUndefinedError = () => Promise.reject(new Error('Step undefined'))

export const useWorkflowStructureMutations = (workflow: Workflow | undefined) => {
  const findBlockStep = useFindWorkflowBlockStep(workflow)
  const invalidate = useWorkflowInvalidate(workflow)

  const addBlock = useMutation(
    ({ stepUuid, data }: { stepUuid: string; data: Partial<WorkflowBlock> }) => {
      if (!workflow) return throwWorkflowUndefinedError()
      return createWorkflowBlock(workflow.uuid, workflow.version!, stepUuid, [data])
    },
    {
      onSettled: invalidate,
    }
  )

  const updateBlock = useMutation(
    (block: Partial<WorkflowBlock> & Pick<WorkflowBlock, 'uuid'>) => {
      if (!workflow) return throwWorkflowUndefinedError()
      const step = findBlockStep(block)
      if (!step) return throwStepUndefinedError()
      return updateWorkflowBlock(workflow.uuid, workflow.version!, step.uuid, block.uuid, block)
    },
    {
      onSettled: invalidate,
    }
  )

  const removeBlock = useMutation(
    (block: Pick<WorkflowBlock, 'uuid'>) => {
      if (!workflow) return throwWorkflowUndefinedError()
      const step = findBlockStep(block)
      if (!step) return throwStepUndefinedError()
      return deleteWorkflowBlock(workflow.uuid, workflow.version!, step.uuid, block.uuid)
    },
    {
      onSettled: invalidate,
    }
  )

  const addStep = useMutation(
    (stepData: CreateWorkflowStepRequestBody) => {
      if (!workflow) return throwWorkflowUndefinedError()
      return createWorkflowStep(workflow.uuid, workflow.version!, stepData)
    },
    {
      onSettled: invalidate,
    }
  )

  return useMemo(
    () => ({
      addBlock: addBlock.mutate,
      updateBlock: updateBlock.mutate,
      removeBlock: removeBlock.mutate,
      addStep: addStep.mutate,
      isProcessing: addBlock.isLoading || updateBlock.isLoading || removeBlock.isLoading || addStep.isLoading,
    }),
    [workflow]
  )
}

export default useWorkflowStructureMutations
