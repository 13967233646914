import { useTranslation } from 'react-i18next'
import StatusIndicator from '../../../../../../shared/components/atoms/StatusIndicator'
import LhotseHomeTableRowStatusItem from './LhotseHomeTableRowStatusItem'
import { RequestSearchType } from '../../../../../Search/Types/SearchEntitiesTypes'

const LhotseHomeTableRowStatus = ({ ...submission }: RequestSearchType) => {
  // TODO: Relay on the status of the intake instead of the type
  const { t } = useTranslation()
  if (submission.type === 'submission') {
    return (
      <StatusIndicator textClassName="text-gray-600" status={t('generic.draft', 'Draft')} className="bg-gray-300  " />
    )
  }

  if (submission.type === 'intake' && submission.status === 'error') {
    return (
      <StatusIndicator textClassName="text-red-600" status={t('generic.error', 'Error')} className="bg-red-300  " />
    )
  }

  if (submission.type === 'intake' && !submission.workflow?.active_blocks?.length) {
    return <LhotseHomeTableRowStatusItem status={submission.status!!} />
  }

  return (
    <div>
      {submission.workflow?.active_blocks?.map((block) => (
        <LhotseHomeTableRowStatusItem
          status={submission.workflow?.active_workflow_status ?? 'unknown'}
          block={block}
          key={`${block.workflow_blocks_name}-${submission.workflow?.active_workflow_status}`}
        />
      ))}
    </div>
  )
}

export default LhotseHomeTableRowStatus
