import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TransitionPromptHook, UnregisterCallback } from 'history'

type INavigationPromptContext = {
  setPrompt: (prompt: boolean | string | TransitionPromptHook) => void
  removePrompt: () => void
}

const navigationPromptContext = createContext({} as INavigationPromptContext)

const NavigationPromptProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { block } = useHistory()
  const unblock = useRef<UnregisterCallback | null>(null)

  const removePrompt = useCallback(() => {
    if (unblock.current) unblock.current()
  }, [unblock])

  // needed to clean up the prompt in case of remounting core of the app (??)
  useEffect(
    () => () => {
      removePrompt()
    },
    [removePrompt]
  )

  const values: INavigationPromptContext = useMemo(
    () => ({
      setPrompt: (prompt) => {
        removePrompt()
        unblock.current = block(prompt)
      },
      removePrompt,
    }),
    [removePrompt, unblock, block]
  )

  return <navigationPromptContext.Provider value={values}>{children}</navigationPromptContext.Provider>
}

export default NavigationPromptProvider

export const useNavigationPrompt = () => useContext(navigationPromptContext)
