import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { useValidation } from '../../../../core/hooks/useValidation'
import ExternalDataSourceSelect from '../selectors/ExternalDataSourceSelect'
import ExternalDataSourceColumnSelect from '../selectors/ExternalDataSourceColumnSelect'
import { ExternalDataRelationship } from '../../../../types/ExternalDataManager/ExternalDataManagerTypes'

type ExternalDataRelationshipBodyProps = {
  onSubmit: (data: ExternalDataRelationship) => void
  initialData: Partial<Omit<ExternalDataRelationship, 'source_uuid'>> & Pick<ExternalDataRelationship, 'source_uuid'>
}

export const ExternalDataRelationshipBody = ({ onSubmit, initialData }: ExternalDataRelationshipBodyProps) => {
  const { t } = useTranslation()

  const [externalDataRelationship, setExternalDataRelationship] = useState<ExternalDataRelationship>({
    source_column: '',
    target_uuid: '',
    target_column: '',
    ...initialData,
  })

  const { modal } = useUtils()

  const { errors, validate } = useValidation(externalDataRelationship, {
    source_column: { required: true },
    target_uuid: { required: true },
    target_column: { required: true },
  })

  const handleUpdate = (updatedField: keyof ExternalDataRelationship, updatedValue: string | File | undefined) => {
    if (updatedValue === undefined) return

    setExternalDataRelationship({ ...externalDataRelationship, [updatedField]: updatedValue })
  }

  const handleSubmit = async () => {
    if (!validate()) return

    await onSubmit(externalDataRelationship)
    modal.set({ isOpen: false })
  }

  return (
    <div className="flex flex-col justify-between min-h-[550px]">
      <div className="flex flex-col gap-11">
        <ExternalDataSourceColumnSelect
          onSelect={(column) => handleUpdate('source_column', column)}
          selected={externalDataRelationship.source_column}
          sourceUuid={externalDataRelationship.source_uuid}
          error={errors.source_column}
          label={t('external_data_manager.relationship_source_column', 'Source column')}
        />
        <ExternalDataSourceSelect
          selected={externalDataRelationship.target_uuid}
          onSelect={(targetUuid) => handleUpdate('target_uuid', targetUuid)}
          excludedSources={[initialData.source_uuid]}
          error={errors.target_uuid}
          label={t('external_data_manager.relationship_target', 'Target')}
        />
        <ExternalDataSourceColumnSelect
          onSelect={(column) => handleUpdate('target_column', column)}
          selected={externalDataRelationship.target_column}
          sourceUuid={externalDataRelationship.target_uuid}
          disabled={!externalDataRelationship.target_uuid}
          error={errors.target_column}
          label={t('external_data_manager.relationship_target_column', 'Target column')}
        />
      </div>
      <div>
        <SaveCancelFormButtons isLoading={false} onCancel={() => modal.set({ isOpen: false })} onSave={handleSubmit} />
      </div>
    </div>
  )
}

export default ExternalDataRelationshipBody
