import { Form, Formik } from 'formik'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import type { FormikConfig } from 'formik'
import CoreLoadingIcon from '../../../../../../core/components/CoreLoadingIcon'
import SaveCancelFormButtons from '../../../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import companyQueryStrings from '../../../../constants/companyQueryStrings'
import { apiGetCountries } from '../../../../services/CompanyService'
import { CompanyAddressType } from '../../../../../../types/Company/AddressTypes'
import { CountryType } from '../../../../../../types/Company/CountryType'
import AddressFormTextInput from './AddressFormTextInput'
import AddressSelectCountry from './AddressSelectCountry'
import LocationSearch from './LocationSearch'
import useGetAddressFormValidation from './useGetAddressFormValidation'

interface IAddressForm {
  onSubmit: (v: CompanyAddressType) => void
  onCancel?: () => void
  onChange?: (v: Partial<CompanyAddressType>) => void
  value?: CompanyAddressType
  showButtons?: boolean
  formProps?: Omit<FormikConfig<CompanyAddressType>, 'initialValues' | 'onSubmit'>
}

const AddressForm: React.FC<IAddressForm> = ({
  onCancel,
  value,
  onSubmit,
  onChange,
  showButtons = true,
  formProps = {},
}) => {
  const { t } = useTranslation()
  const { data: countries, isLoading: queryLoading } = useQuery([companyQueryStrings.countries], () =>
    apiGetCountries()
  )

  const countryOptions = useMemo(
    () => countries?.map((c: CountryType) => ({ value: c.id, label: c.name, code: c.code })) || [],
    [countries]
  )

  const addressValidationSchema = useGetAddressFormValidation()

  const [address, setAddress] = useState<Partial<CompanyAddressType> | undefined>(value)

  const initialValues: CompanyAddressType = {
    country_id: address?.country_id || 1,
    name: address?.name || '',
    city: address?.city,
    street: address?.street,
    zip: address?.zip,
    id: address?.id,
  }

  if (queryLoading) {
    return (
      <div className="flex w-full">
        <CoreLoadingIcon />
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addressValidationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
      {...formProps}
    >
      {({ touched, values, handleChange, handleSubmit, setFieldValue, resetForm, errors }) => {
        const constants = {
          values,
          errors,
          handleChange,
          touched,
        }

        const update = (property: keyof CompanyAddressType, newValue?: string | number) => {
          setFieldValue(property, newValue, false)
          const updatedAddress = { ...address, [property]: newValue }

          if (onChange) onChange(updatedAddress)

          setAddress(updatedAddress)
        }

        const handlingChange = (property: keyof CompanyAddressType) => (value?: string | number) =>
          update(property, value)
        const handlingChangeEvent =
          (property: keyof CompanyAddressType) =>
          ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
            update(property, value)

        return (
          <Form className="flex flex-col w-full">
            <LocationSearch setAutoFillAddressFields={setAddress} />
            <div className="flex gap-2 w-full">
              <AddressFormTextInput
                constants={constants}
                placeholder="John Doe"
                label="generic.name"
                errorKey="name"
                className="w-1/2"
                customOnChange={handlingChangeEvent('name')}
                dataCy="address-name-input"
              />
              <AddressSelectCountry
                onChange={handlingChange('country_id')}
                countryOptions={countryOptions}
                countryCode={address?.country_code}
                className="w-1/2"
                values={values}
                error={{ props: { error: t(constants.errors.country_code || '') } }}
              />
            </div>
            <div className="flex gap-2">
              <AddressFormTextInput
                constants={constants}
                placeholder="Dirckenstraße"
                label="account.company_street_address"
                errorKey="street"
                className="w-1/3"
                customOnChange={handlingChangeEvent('street')}
              />
              <AddressFormTextInput
                constants={constants}
                placeholder="Berlin"
                label="account.company_city"
                errorKey="city"
                className="w-1/3"
                customOnChange={handlingChangeEvent('city')}
              />
              <AddressFormTextInput
                constants={constants}
                placeholder="10178"
                label="account.company_zip"
                errorKey="zip"
                className="w-1/3"
                customOnChange={handlingChangeEvent('zip')}
              />
            </div>
            {showButtons && (
              <div className="pt-6">
                <SaveCancelFormButtons
                  onCancel={() => {
                    resetForm()
                    if (onCancel) onCancel()
                  }}
                  onSave={handleSubmit}
                  saveString="company.save_new_address"
                />
              </div>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddressForm
