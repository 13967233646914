import { useTranslation } from 'react-i18next'
import { DocumentIcon } from '@heroicons/react/24/solid'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import ExternalDataRelationshipsTableBody from './ExternalDataRelationshipsTableBody'
import ExternalDataRelationshipsTableHeader from './ExternalDataRelationshipsTableHeader'
import useGetExternalDataRelationshipList from '../../hooks/useExternalDataRelationships'
import AddButton from '../../../../shared/components/atoms/buttons/AddButton'
import useExternalDataRelationshipModal from '../ExternalDataRelationshipModal/hooks/useExternalDataRelationshipModal'
import Button from '../../../../shared/components/atoms/buttons/Button'

export const ExternalDataRelationshipsTable = () => {
  const { data: relationships, isLoading } = useGetExternalDataRelationshipList()
  const openNewRelationshipModal = useExternalDataRelationshipModal()
  const { t } = useTranslation()

  if (isLoading)
    return (
      <div className="m-4">
        <CoreLoadingIcon />
      </div>
    )

  const relationshipsTableHeader = (
    <div className="flex flex-row justify-between items-center w-full p-4 mb-8">
      <div>
        <div className="text-sm font-semibold text-gray-600 pb-2">
          {t('external_data.relationships', 'Relationships')}
        </div>
        <div className="text-sm font-medium text-gray-400">
          {t('external_data.manage_relationships', 'View, track, and manage data relationships.')}
        </div>
      </div>
      <Button variant="solid" color="sky-blue" onClick={openNewRelationshipModal}>
        {t('external_data.add_relationship', 'Add new relationship')}
      </Button>
    </div>
  )

  return (
    <TableContainer
      header={relationshipsTableHeader}
      emptyState={
        !relationships?.length && (
          <div className="flex flex-col items-center">
            <div className="mb-4">
              <DocumentIcon className="h-6 text-rock-gray" />
            </div>
            <div className="text-rock-gray">{t('external_data.missing_relationships', 'No relationships created')}</div>
            <div>
              <AddButton label={t('external_data.add_relationship', 'Add new relationship')} />
            </div>
          </div>
        )
      }
    >
      <ExternalDataRelationshipsTableHeader />
      <ExternalDataRelationshipsTableBody />
    </TableContainer>
  )
}

export default ExternalDataRelationshipsTable
