import axios, { AxiosError } from 'axios'
import i18n from '../../i18n'
import { redirectToLogin, redirectToUnauthorized } from './authUtils'
import { getApiUrl, handleWrongTenantUrl } from './tenancy'
import { toastr } from '../../shared/components/organisms/toastr'

const STORAGE_ATTRIBUTE = 'lhotseAuth'

export const getToken = () => {
  const lhotseAuth = localStorage.getItem(STORAGE_ATTRIBUTE)
  if (lhotseAuth) {
    const parsedAuth = JSON.parse(lhotseAuth)
    return parsedAuth?.token as string
  }
  return undefined
}

export const apiClient = axios.create({
  baseURL: getApiUrl(),
  timeout: 0,
})

apiClient.interceptors.request.use(
  (config) => {
    const token = getToken()
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Language': i18n.language,
    }

    return { ...config, headers }
  },
  () => {
    toastr.error('error.unable_to_connect', 'unableToConnectError')
  }
)

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ message?: string; exception?: { errors?: Record<string, string[]> } }>) => {
    const status = error.response?.status
    const { method } = error.config
    const pathname = window.location?.pathname

    if (status === 401 && pathname !== '/login') return redirectToLogin()
    // if (status === 404 && method === 'get') return redirectToError()
    if (status === 403 && method === 'get') return redirectToUnauthorized()
    if (status === 501) return handleWrongTenantUrl()
    const formFieldErrors = Object.values(error.response?.data?.exception?.errors || {}).flat()
    toastr.error(
      formFieldErrors[0] || (error.response?.data?.message as string) || error.response?.statusText || 'error.generic'
    )
    return Promise.reject(error)
  }
)

export function setHeaders() {
  apiClient.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  )
}

export default apiClient
