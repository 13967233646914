import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { getLocalInfo, setLocalInfo } from '../../../core/services/userService'
import { getApiUrl, getTenant } from '../../../core/utils/tenancy'
import { toastr } from '../../../shared/components/organisms/toastr'
import i18n from '../../../i18n'

export default function useUpdateLocalAuth() {
  const { t } = useTranslation()
  const updateLocalAuth = async (token?: string) => {
    const lhotseAuth = getLocalInfo()
    try {
      const {
        data: { data: user },
      } = await axios.get(`${getApiUrl()}/user`, {
        headers: { Authorization: `Bearer ${token}` },
      })

      setLocalInfo({
        ...lhotseAuth,
        ...user,
        token,
        language: user.language,
        tenant: getTenant(),
      })
      i18n.changeLanguage(user.language)
    } catch (err) {
      toastr.error(t('generic.invalid-token', 'Invalid token'))
    }
  }

  return updateLocalAuth
}
