import { useQuery } from 'react-query'
import messengerQueryKeys from '../../messengerQueryKeys'
import { apiGetChatMessages } from '../../service'
import useIntakeId from '../../../Intake/IntakeOverview/hooks/useIntakeId'

export default () => {
  const modelType = 'intake_flow'
  const modelIdParam = useIntakeId()

  // TODO: Check why this is here
  const token = ''
  const paramsObject = { model_type: modelType, model_id: modelIdParam }

  const QUERY_KEY = messengerQueryKeys.messages(modelType, Number(modelIdParam))

  return useQuery(QUERY_KEY, () => apiGetChatMessages(token, paramsObject), { enabled: !!(modelType || token) })
}
