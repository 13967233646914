import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { WorkflowVersion } from '../../../../../types/Workflow/Workflow'
import { useWorkflow } from '../../../providers/WorkflowProvider'
import { WorkflowVersionIcon } from './WorkflowVersionIcon'
import { dateTimeFormatter } from '../../../../../shared/constants/dateFormat'
import useOpenWorkflowDetails from '../../../hooks/useOpenWorkflowDetails'
import { useWorkflowVersionType } from '../../../hooks/useWorkflowVersionType'
import useWorkflowIdentification from '../../../hooks/useWorkflowIdentification'

export const WorkflowVersionEntry = ({ version, created_at, published_at }: WorkflowVersion) => {
  const { workflow } = useWorkflow()
  const { t } = useTranslation()

  const type = useWorkflowVersionType(version)
  const openDetailsPage = useOpenWorkflowDetails(workflow)

  const { version: currentlyInspectedVersion } = useWorkflowIdentification()
  const isActive = currentlyInspectedVersion === version || (!currentlyInspectedVersion && type === 'draft')

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames('group flex flex-row gap-3 items-center cursor-pointer p-3 w-full', {
        'bg-gray-100': isActive,
      })}
      onClick={() => openDetailsPage(version)}
    >
      <div className="flex flex-col">
        <WorkflowVersionIcon type={type} />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2">
          <div className="font-medium text-sky-blue-light flex">{`V${version}`}</div>
          <div className="font-medium text-black-light-2">
            {type === 'draft' && t('workflow.draft')}
            {type !== 'draft' && t('workflow.published')}
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="font-medium text-black-light-2">
            {type === 'draft' && dateTimeFormatter(created_at, true)}
            {type !== 'draft' && dateTimeFormatter(published_at, true)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkflowVersionEntry
