import { useQuery } from 'react-query'
import { useMemo } from 'react'
import companyQueryStrings from '../../Settings/constants/companyQueryStrings'
import { apiGetInputCategories } from '../../IntakeConfiguration/services/CompanyInputsService'
import { InputCategory } from '../../../types/Forms'

export const useGetInputCategories = () => {
  const query = useQuery([companyQueryStrings.company_input_categories], () => apiGetInputCategories())

  return {
    ...query,
    getById: (uuid: string) => query.data?.find((category: InputCategory) => category.uuid === uuid),
    defaultCategory: useMemo(() => (query.data && query.data.length > 1 && query.data?.[0]) || undefined, [query.data]),
  }
}

export default useGetInputCategories
