import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import React from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { WorkflowBlock, WorkflowBlockApprover } from '../../../../../types/Workflow/WorkflowBlock'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../shared/components/atoms/Dialog/Dialog'
import SearchSelect from '../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { Avatar, AvatarFallback } from '../../../../../shared/components/atoms/Avatar/Avatar'
import getUserInitials from '../../../../../shared/utils/getUserInitials'
import stringToHslColor from '../../../../../shared/utils/stringToHslColor'
import { InputSearchSelectItemType } from '../../../../../types/shared/InputSearchSelectTypes'
import { useUserOptionsExcluding } from '../../../../../shared/hooks/queries/useUserOptions'
import cn from '../../../../../lib/util'
import useWorkflowBlockDraftMutations from '../../../hooks/useWorkflowBlockDraftMutations'

type WorkflowBuilderApproversModalProps = {
  block: WorkflowBlock
  readonly?: boolean
}

const WorkflowBuilderBlockApprover = ({
  value,
  user,
  type,
  readonly,
  removeApprover,
}: WorkflowBlockApprover & {
  value?: number | string
  readonly?: boolean
  removeApprover: (approverValue: number | string) => void
}) => (
  <div className="flex items-center justify-between w-full rounded-md py-2 pl-2 pr-4 group hover:bg-gray-100">
    <div className="flex space-x-4 items-center">
      {type === 'USER' && (
        <>
          <Avatar>
            <AvatarFallback
              style={{ backgroundColor: stringToHslColor({ name: getUserInitials(user) }) }}
              className="font-semibold"
            >
              {getUserInitials(user)}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <p
              className={`font-semibold text-gray-700 ${user.first_name || user.last_name ? '' : 'hidden'}`}
            >{`${user.first_name} ${user.last_name}`}</p>
            <p className="text-sm text-secondary-gray">{user.email}</p>
          </div>
        </>
      )}
      {type !== 'USER' && (
        <div className="flex flex-col">
          <p className="font-semibold text-gray-700 lowercase first-letter:uppercase">{value}</p>
        </div>
      )}
    </div>
    <div className="cursor-pointer">
      <button hidden={readonly} type="button" onClick={() => removeApprover(value!!)}>
        <MdClose className="h-4 w-4 group-hover:block hidden " />
      </button>
    </div>
  </div>
)

const WorkflowBuilderApprovalDialog = ({ block, readonly }: WorkflowBuilderApproversModalProps) => {
  const {
    users: usersData,
    isLoading: isLoadingUsers,
    options: selectableUsers,
  } = useUserOptionsExcluding(block?.approvers.filter((item) => item.type === 'USER').map(({ user }) => user) || [])
  const { addApprover, removeApprover } = useWorkflowBlockDraftMutations()
  const { t } = useTranslation()
  const dynamicApprovers = block.approvers.filter((approver) => approver.type === 'AUTHOR')
  const userApprovers = block.approvers.filter((approver) => approver.type === 'USER')
  const handleAddition = (userOption?: InputSearchSelectItemType) => {
    if (!userOption) return

    const selectedUser = usersData?.find((user) => user.id === userOption.id)

    if (!selectedUser && userOption.category === 'STATIC') return

    const newApprover = {
      value: userOption.id,
      user: selectedUser,
      type: userOption.category?.toUpperCase() === 'STATIC' ? 'USER' : userOption.id,
    }
    addApprover(newApprover as unknown as WorkflowBlockApprover)
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button
          type="button"
          className={cn(
            'items-center border border-rock-gray-light rounded flex flex-row justify-between py-2 px-4 base-form-input'
          )}
        >
          <span>{`${block.approvers.length} ${t('workflow.block.approvers', 'approvers')} `}</span>
          <span>
            <PlusIcon className="h-4" />
          </span>
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>{t('workflow.approvers', 'Approvers')}</DialogTitle>
          <DialogDescription className="text-gray-500 text-sm">
            {t('workflow.approvers_dialog_description', 'All users that can approve this workflow block')}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col space-y-4">
          <div hidden={readonly}>
            <SearchSelect
              enableGroupByCategory="category"
              placeholder="Search for users"
              options={[
                ...[
                  {
                    id: 'AUTHOR',
                    category: `${t('workflow.approvers_dropdown_category_dynamic', 'DYNAMIC')}`,
                    label: 'Author',
                  } as InputSearchSelectItemType,
                ].filter((item) => !block.approvers.find((approver) => approver.value === item.id)),
                ...(selectableUsers?.map((user) => ({
                  ...user,
                  category: `${t('workflow.approvers_dropdown_category_static', 'STATIC')}`,
                })) ?? []),
              ]}
              onChange={handleAddition}
              currentSelection={undefined}
              nullable
              autoClear
            />
          </div>
          <div className="flex items-center space-x-2">
            <div className="grid flex-1 gap-2">
              <div
                className="text-sm text-gray-500 font-bold lowercase first-letter:uppercase"
                hidden={!dynamicApprovers.length}
              >
                {t('workflow.approver_type_dynamic', 'Dynamic')}
              </div>
              {dynamicApprovers.map((approver: WorkflowBlockApprover) => (
                <WorkflowBuilderBlockApprover
                  key={approver.value}
                  type={approver.type}
                  value={approver.value}
                  user={approver.user}
                  removeApprover={removeApprover}
                  readonly={readonly}
                />
              ))}
              <div
                className="text-sm font-bold text-gray-500 lowercase first-letter:uppercase"
                hidden={!userApprovers.length}
              >
                {t('workflow.approver_type_users', 'Users')}
              </div>
              {userApprovers.map((approver: WorkflowBlockApprover) => (
                <WorkflowBuilderBlockApprover
                  key={approver.value}
                  type={approver.type}
                  user={approver.user}
                  value={approver.user.id}
                  removeApprover={removeApprover}
                  readonly={readonly}
                />
              ))}
            </div>
          </div>
        </div>
        <DialogFooter className="sm:justify-end" />
      </DialogContent>
    </Dialog>
  )
}

export default WorkflowBuilderApprovalDialog
