import SearchSelect from '../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'

import {
  WorkflowActionTriggerSelectionType,
  WorkflowBlockTrigger,
} from '../../../../../../../types/Workflow/WorkflowBlock'

import { InputSearchSelectItemType } from '../../../../../../../types/shared/InputSearchSelectTypes'
import FormFieldPlaceholder from '../../../../../../Forms-v2/FormFieldPlaceholder'
import useWorkflowBlockDraftMutations from '../../../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflow } from '../../../../../providers/WorkflowProvider'
import useWorkflowService from '../../../../../services/useWorkflowService'
import { assureOperatorIsValid } from './TriggerComparisonSelector'

export const TriggerInputTypeSelector = ({ trigger }: { trigger: WorkflowBlockTrigger }) => {
  const {
    getWorkflowRuleTriggers: { data: triggers },
  } = useWorkflowService()

  const { updateTrigger } = useWorkflowBlockDraftMutations()
  const { readonly } = useWorkflow()

  if (!triggers || triggers.length === 0) return <FormFieldPlaceholder />

  const handleUpdate = (selection: InputSearchSelectItemType) => {
    if (!selection) return
    const selectedTrigger = triggers.find((triggerOption) => selection.id === triggerOption.input_reference)
    if (!selectedTrigger) return

    updateTrigger({
      ...trigger,
      trigger_type: selectedTrigger.trigger_type,
      input_reference: selectedTrigger.input_reference,
      input_type: selectedTrigger.type,
      comparison_operator: assureOperatorIsValid(selectedTrigger.type, trigger.comparison_operator),
    })
  }

  const renderOptionName = (triggerOption: WorkflowActionTriggerSelectionType) =>
    `${triggerOption.trigger_type === 'NON_INPUT' ? '⚡' : ''} ${triggerOption.name}`

  const currentSelectedTrigger = triggers.find(
    (triggerOption) => triggerOption.input_reference === trigger.input_reference
  )

  const currentSelection = currentSelectedTrigger
    ? ({
        label: renderOptionName(currentSelectedTrigger),
        id: currentSelectedTrigger.input_reference,
      } as InputSearchSelectItemType)
    : undefined

  const options = triggers.map((triggerOption) => ({
    label: renderOptionName(triggerOption),
    id: triggerOption.input_reference,
  }))

  return (
    <SearchSelect options={options} onChange={handleUpdate} currentSelection={currentSelection} disabled={readonly} />
  )
}

export default TriggerInputTypeSelector
