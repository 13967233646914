import SidepanelTabTransition from '../../../../../shared/components/layout/SidepanelTabTransition'
import WorkflowActionList from './WorkflowActionList'
import WorkflowBuilderBlockControls from '../WorkflowBuilderSidepanelBlockDetails/WorkflowBuilderBlockControls'

export const WorkflowBuilderSidepanelActions = () => (
  <SidepanelTabTransition>
    <div className="flex flex-col gap-11 h-full justify-between">
      <WorkflowActionList />
      <WorkflowBuilderBlockControls tab="actions" />
    </div>
  </SidepanelTabTransition>
)

export default WorkflowBuilderSidepanelActions
