import axios from 'axios'
import { setLocalInfo } from '../../../core/services/userService'
import apiClient from '../../../core/utils/apiClient'
import { getApiUrl, getCentralApiUrl, getTenant, getTenantUrl, handleWrongTenantUrl } from '../../../core/utils/tenancy'
import JoinFormValueType from '../../../types/Authentication/JoinFormValueType'

const STORAGE_ATTRIBUTE = 'lhotseAuth'

export const removeToken = () => {
  const lhotseAuth = localStorage.getItem(STORAGE_ATTRIBUTE)
  if (lhotseAuth) {
    const parsedAuth = JSON.parse(lhotseAuth)
    localStorage.setItem(
      STORAGE_ATTRIBUTE,
      JSON.stringify({
        ...parsedAuth,
        token: undefined,
      })
    )
  }
}

export const logOut = async () => {
  await apiClient.get('logout')
  removeToken()
}

export const apiLoginUser = async (userCredentials: { email: string; password: string }) => {
  const form = new FormData()
  form.set('grant_type', 'password')
  form.set('client_secret', process.env.REACT_APP_CLIENT_SECRET || '')
  form.set('client_id', process.env.REACT_APP_CLIENT_ID || '')
  form.set('username', userCredentials.email)
  form.set('password', userCredentials.password)

  const { data } = await axios.post(`${getTenantUrl()}/oauth/token`, form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  setLocalInfo({
    refreshToken: data.refresh_token,
    tokenExpiresIn: data.expires_in,
  })

  return data.access_token
}

export const apiPostForgotPassword = async (email: string) => apiClient.post('/forgot-password', { email })

export const apiGetOptions = async () =>
  axios.get(`${getApiUrl()}/auth/options`).catch((err) => {
    if (err.response.status === 501 && getTenant() !== 'app') {
      handleWrongTenantUrl()
      return
    }

    throw err
  })

export const apiResetPassword = async (passwordData: {
  email: string
  password: string
  password_confirmation: string
  token: string
}) => {
  const data = await apiClient.post('/reset-password', passwordData).catch((err) => Promise.reject())

  return data?.data
}

export const apiUserJoin = async (formData: JoinFormValueType) => {
  try {
    const res = await axios.post(`${getApiUrl()}/user/join`, formData)
    return res?.data
  } catch (e: any) {
    throw e.response.data.exception.errors
  }
}

export const apiIdentifyTenant = async (email: string) => {
  const res = await axios.post(`${getCentralApiUrl()}/identify-tenant`, email)
  return res.data?.data || []
}
