import _ from 'lodash'
import { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react'
import { germanPriceStringToFloat } from '../../../constants/currencyFormat'

export default (
  minimumState: [number | undefined, Dispatch<SetStateAction<number | undefined>>],
  maximumState: [number | undefined, Dispatch<SetStateAction<number | undefined>>],
  setShouldUpdate: Dispatch<SetStateAction<string>>,
  maxValue?: number
): ChangeEventHandler<HTMLInputElement> => {
  const [minimum, setMinimum] = minimumState
  const [maximum, setMaximum] = maximumState

  return useCallback(
    (e) => {
      const value = germanPriceStringToFloat(e.target.value) ?? undefined

      if (!value) {
        setMinimum(undefined)
        return setShouldUpdate(_.uniqueId())
      }

      if (maxValue && value > maxValue) {
        setMinimum(maxValue)
        setMaximum(maxValue)
        return setShouldUpdate(_.uniqueId())
      }

      if ((maximum && value >= maximum) || (maxValue && value >= maxValue)) {
        setMinimum(value)
        return setMaximum(value)
      }

      if (!maximum) setMaximum(value + 1)

      return setMinimum(value)
    },
    [minimum, maximum]
  )
}
