import { debounce } from 'lodash'
import { NumericFormat } from 'react-number-format'

type IntakeTextInputProps = { onChange: (newValue: number) => void; defaultValue?: string | number | undefined } & Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'ref' | 'omit'
>

export const FormNumericInput = ({ onChange, type, defaultValue, ...props }: IntakeTextInputProps) => {
  // Debounce the onChange event to prevent too many requests
  const debouncedOnChange = debounce((value: number) => {
    onChange && onChange(value)
  }, 500)
  return (
    <NumericFormat
      required={false}
      thousandsGroupStyle="thousand"
      decimalSeparator=","
      onValueChange={(values) => {
        debouncedOnChange(values.floatValue || 0)
      }}
      thousandSeparator="."
      valueIsNumericString
      displayType="input"
      type="text"
      decimalScale={2}
      fixedDecimalScale
      className={`base-form-input transition-colors ${props.className}`}
      value={props.value as string}
      placeholder={props.placeholder}
      disabled={props.disabled}
      allowNegative={false}
      onBlur={props.onBlur}
      defaultValue={defaultValue}
    />
  )
}

export default FormNumericInput
