import { useTranslation } from 'react-i18next'
import useOpenSupplierDetailsModal from '../../../../../../../../../SupplierDetails/hooks/useOpenSupplierDetailsModal'
import Button from '../../../../../../../../../../shared/components/atoms/buttons/Button'

const OfferSupplierDetailsModalButton = ({ branchId }: { branchId?: number | undefined }) => {
  const openModal = useOpenSupplierDetailsModal(branchId)
  const { t } = useTranslation()

  return (
    <Button className="text-sky-blue-light" type="button" onClick={openModal}>
      {t('general.details', 'Details')}
    </Button>
  )
}

export default OfferSupplierDetailsModalButton
