import QuestionInputWrapper from '../QuestionInputWrapper'
import SliderSettingRow, { ISliderSettingRow } from '../../../../../../shared/components/organisms/SliderSettingRow'

export const QuestionSliderSettingRow = ({ children, ...props }: ISliderSettingRow) => (
  <QuestionInputWrapper>
    {({ disabled }) => (
      <SliderSettingRow disabled={disabled} {...props}>
        {props.checked && children}
      </SliderSettingRow>
    )}
  </QuestionInputWrapper>
)

export default QuestionSliderSettingRow
