import { useUtils } from '../../../../core/providers/UtilsProvider'
import Button from '../../atoms/buttons/Button'

interface ITributtonModal {
  alertButtonText: string
  handleAlertButton: () => void
  neutralButtonText: string
  positiveButtonText: string
  handlePositiveButton: () => void
  text: string
  leftNote?: string
}

const TributtonModal: React.FC<ITributtonModal> = ({
  alertButtonText,
  handleAlertButton,
  neutralButtonText,
  positiveButtonText,
  handlePositiveButton,
  text,
  leftNote,
}) => {
  const { modal } = useUtils()

  const handleCancel = () => {
    modal.close()
  }

  return (
    <div className="flex flex-col w-full">
      <div className="pt-4 text-sm text-secondary-gray" style={{ width: 'calc(100% - 30px)' }}>
        {text}
      </div>
      <div className={`flex flex-col pt-6 ${leftNote ? 'justify-between' : 'justify-end'}`}>
        <p className="text-xs">{leftNote}</p>
        <div className="flex gap-x-2 justify-end">
          <Button variant="outlined" color="secondary-gray" onClick={handleCancel}>
            {neutralButtonText}
          </Button>
          <Button variant="solid" color="alert-dark" onClick={handleAlertButton}>
            {alertButtonText}
          </Button>
          <Button variant="solid" color="nds-dark-green-accessible" onClick={handlePositiveButton}>
            {positiveButtonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TributtonModal
