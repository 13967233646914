import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { HiOutlineArrowRightOnRectangle } from 'react-icons/hi2'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { Avatar, AvatarFallback } from '../../../../shared/components/atoms/Avatar/Avatar'
import Button from '../../../../shared/components/atoms/buttons/Button'
import getUserInitials from '../../../../shared/utils/getUserInitials'
import stringToHslColor from '../../../../shared/utils/stringToHslColor'
import BaseSettingsSection from '../../components/BaseSettingsSection'
import AbsenceModal from './components/AbsenceModal/AbsenceModal'
import AbsenceTable from './components/AbsenceTable/AbsenceTable'
import ProfileForm from './components/ProfileForm/ProfileForm'
import settingsQueryKeys from './constants/settingsQueryKeys'
import { apiGetUser } from './services/SettingsService'
import { logOut } from '../../../Authentication/services/loginService'
import { toastr } from '../../../../shared/components/organisms/toastr'

const UserProfileTab = () => {
  const history = useHistory()
  const { modal } = useUtils()

  const { data: userData } = useQuery([settingsQueryKeys.USER_PROFILE], apiGetUser)
  const [editable, setEditable] = useState(false)
  const profileFormRef = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation()
  const userInitials = getUserInitials({
    ...userData,
    email: userData?.email || '',
  })

  const handleEditOrSubmitButton = () => {
    if (!editable) {
      setEditable(true)
      return
    }

    if (profileFormRef.current) {
      profileFormRef.current.click()
      setEditable(!editable)
    }
  }

  const handleLogoutButtonClick = async () => {
    await logOut()
    toastr.success(t('profile.logout-success', 'You have been logged out successfully.'))
    history.push('/')
  }

  return (
    <div className="flex flex-col gap-8 pb-12">
      <BaseSettingsSection
        title={t('generic.profile', 'Profile')}
        description={t('profile.description', 'Manage your profile information.')}
        action={
          <div className="flex justify-end space-x-5">
            {editable ? (
              <Button className="bg-black-light-2 text-white" onClick={() => setEditable(false)}>
                {t('generic.cancel', 'Cancel')}
              </Button>
            ) : null}
            <Button type="submit" className="bg-sky-blue text-white" onClick={handleEditOrSubmitButton}>
              {editable ? t('generic.save', 'Save') : t('generic.edit', 'Edit')}
            </Button>
          </div>
        }
      >
        <div className="flex flex-col space-y-6">
          <Avatar className="h-24 w-24">
            <AvatarFallback
              style={{ backgroundColor: stringToHslColor({ name: userInitials }) }}
              className="font-semibold text-2xl "
            >
              {userInitials}
            </AvatarFallback>
          </Avatar>
          <ProfileForm editable={editable} ref={profileFormRef} />
          <div className="flex flex-row w-full justify-end">
            <button
              type="button"
              className="text-sm font-medium text-gray-400 flex flex-row items-center hover:text-gray-600 transition-colors"
              onClick={handleLogoutButtonClick}
            >
              <span>{t('profile.log_out', 'Log out')}</span>
              <HiOutlineArrowRightOnRectangle className="ml-2 w-4 h-4" />
            </button>
          </div>
        </div>
      </BaseSettingsSection>
      <BaseSettingsSection
        title={t('profile.absences', 'Absences')}
        description={t('profile.absence-description', 'Add users that are able to represent you while being absent.')}
        action={
          <div className="flex justify-end">
            <Button
              className="bg-sky-blue text-white"
              onClick={() => modal.set({ isOpen: true, title: 'Add Absence', content: <AbsenceModal /> })}
            >
              {t('generic.add', 'Add')}
            </Button>
          </div>
        }
      >
        <AbsenceTable />
      </BaseSettingsSection>
    </div>
  )
}
export default UserProfileTab
