import { useTranslation } from 'react-i18next'
import { useFormField } from '../providers/FormFieldProvider'
import FormTextInput from '../FormInputs/FormTextInput'

export const FormFieldText = () => {
  const { t } = useTranslation()
  const {
    field: {
      error,
      value,
      id,
      input: { type, placeholder },
      disabled,
    },
    updateValue,
  } = useFormField()

  if (type !== 'TEXT' && type !== 'TEXT_AREA') {
    return null
  }

  return (
    <div className="flex flex-col gap-4 text-base font-normal">
      <div key={`intake-field-${id}`}>
        <FormTextInput
          disabled={disabled}
          type={type}
          placeholder={placeholder ?? t('generic.type_your_response')}
          onChange={updateValue}
          defaultValue={(value?.value as string) || ''}
          error={error}
        />
      </div>
    </div>
  )
}

export default FormFieldText
