import { useTranslation } from 'react-i18next'
import AuthLayout from '../../modules/Authentication/submodules/shared/AuthLayout'
import CoreLoadingIcon from './CoreLoadingIcon'

const CoreAuthenticating: React.FC = () => {
  const { t } = useTranslation()

  return (
    <AuthLayout title={t('generic.authenticating')}>
      <div className="h-full w-full flex flex-col items-center justify-center">
        <div className="flex flex-col space-y-9 w-96 h-full">
          <CoreLoadingIcon className="ml-2 " />
        </div>
      </div>
    </AuthLayout>
  )
}

export default CoreAuthenticating
