import { PlusIcon } from '@heroicons/react/24/outline'
import React from 'react'
import Button from '../../../../../shared/components/atoms/buttons/Button'

export const WorkflowChartAddGroupButton = ({ onClick }: { onClick: () => void }) => (
  <div className="w-full h-full p-1 rounded-full">
    <Button variant="solid" className="w-5 h-5 rounded-full !p-1 bg-sky-blue-light text-white" onClick={onClick}>
      <PlusIcon className="w-full h-full" style={{ strokeWidth: 3 }} />
    </Button>
  </div>
)

export default WorkflowChartAddGroupButton
