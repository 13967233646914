import { HiBars3, HiEllipsisVertical, HiPlus, HiMinus } from 'react-icons/hi2'
import uuid from 'react-uuid'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useRef } from 'react'
import { useHistory } from 'react-router'
import cn from '../../../../../lib/util'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import FormField from '../../../../Forms-v2/FormField'
import { InputType } from '../../../../../types/shared/InputType'
import {
  CustomDataSchemaFieldType,
  DataSchemaCustomFieldType,
  DataSchemaViewResponseType,
  useSchemaServiceDeleteMutation,
  useSchemaServiceRenameMutation,
} from '../useSchemaService'
import Menu from '../../../../../shared/components/organisms/menus/Menu'
import ConfirmationModal from '../../../../../shared/components/organisms/modals/ConfirmationModal'
import { toastr } from '../../../../../shared/components/organisms/toastr'

export const FieldItemIcons = {
  drag: <HiBars3 className="mr-2 h-5 w-5" />,
  add: <HiPlus className="h-5 w-5" />,
  options: <HiEllipsisVertical />,
}

export const FieldItemSkeleton = (): JSX.Element => (
  <div className="flex justify-between items-center px-3 py-4 w-full bg-gray-100 text-black-light animate-pulse rounded">
    <span className="text-sm bg-gray-200 h-4 w-1/3 block rounded" />
    <div className="bg-gray-200 h-6 w-6 rounded-full roudned" />
  </div>
)

type FieldItemProps = {
  item: CustomDataSchemaFieldType
  classNames?: string
  leftBtn?: (item: CustomDataSchemaFieldType) => JSX.Element
  rightBtn?: (item: CustomDataSchemaFieldType) => JSX.Element
  innerRef?: any
  rest?: any[]
}

export const FieldItem = ({ item, classNames, leftBtn, rightBtn, innerRef, ...rest }: FieldItemProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div
      ref={innerRef}
      key={`key-${item.input_uuid}`}
      className={cn(
        'flex justify-between items-center px-3 py-3 w-full hover:bg-gray-100 text-slate-500 font-semibold rounded-md',
        classNames
      )}
      {...rest}
    >
      <div className="flex items-center">
        {leftBtn && leftBtn(item)}
        <div className="flex flex-col cursor-default">
          <span className="text-md">{item.name}</span>
          <div className="flex">
            <span className="text-[10px] font-normal text-left lowercase first-letter:capitalize">
              {item.type?.replace('_', ' ')}
            </span>
            <div className="flex items-center">
              {item.required && (
                <span className="text-[10px] text-gray-400">
                  <HiMinus className="text-[10px] mx-1" />
                </span>
              )}
              {item.required && <span className="text-[10px] font-normal">{t('generic.required', 'required')}</span>}
            </div>
          </div>
        </div>
      </div>
      {rightBtn && rightBtn(item)}
    </div>
  )
}

type FieldListProps = {
  isLoading: boolean
  onInputChange: (value: { search: string }) => void
  onAddInput: (input: CustomDataSchemaFieldType) => void
  inputs?: CustomDataSchemaFieldType[]
  selectedInputs: CustomDataSchemaFieldType[]
}

export const FieldList = ({
  isLoading,
  inputs,
  selectedInputs,
  onAddInput,
  onInputChange,
}: FieldListProps): JSX.Element => {
  const rightButton = (item: CustomDataSchemaFieldType) => (
    <button
      type="button"
      className="h-full w-4 text-gray-400 hover:text-sky-blue hover:font-bold"
      onClick={() => onAddInput && onAddInput(item)}
    >
      {FieldItemIcons.add}
    </button>
  )

  return (
    <div className="col-span-2 w-full h-full pl-5">
      <label htmlFor="search_input" className="text-sm font-medium text-black-light-2">
        Search all fields
      </label>
      <input
        name="search_input"
        placeholder="Type to search all fields"
        className="base-form-input"
        type="text"
        onChange={(event) => onInputChange && onInputChange({ search: event.target.value })}
      />
      <div className="mt-5 max-h-[calc(75vh-10px)] overflow-y-auto divide-y-[0.5px] divider-gray-100 hover:divide-gray-100 flex flex-col items-center">
        {isLoading && (
          <div className="flex flex-col w-full space-y-2">
            {[...Array(7)].map((_) => (
              <FieldItemSkeleton key={`key-loading-${uuid()} `} />
            ))}
          </div>
        )}
        {inputs
          ?.filter((input) => !selectedInputs.find((selectedInput) => selectedInput.input_uuid === input.input_uuid))
          ?.map((input) => <FieldItem key={`key-${input.input_uuid}`} item={input} rightBtn={rightButton} />)}
      </div>
    </div>
  )
}

type FieldListModalProps = {
  field: CustomDataSchemaFieldType
  onSave: (field: CustomDataSchemaFieldType) => void
}

export const EditFieldModal = ({ field, onSave }: FieldListModalProps): JSX.Element => {
  const utils = useUtils()
  const { t } = useTranslation()
  const formRef = useRef<HTMLFormElement>(null)
  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({ mode: 'onChange' })
  const onSubmit = (attributes: any) => {
    const newField = {
      ...field,
      label: attributes.name,
      placeholder: attributes.placeholder,
      required: attributes.required.value,
    }
    onSave(newField)
    utils.modal.close()
  }

  const onError = (attributes: any) => {
    console.log('onError', attributes)
  }

  return (
    <div className="flex flex-col space-y-5 transition duration-150 ease-out">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)} className="flex flex-col space-y-6">
        <Controller
          key="name"
          name="name"
          control={control}
          defaultValue={field.name}
          rules={{
            required: 'required',
            minLength: {
              value: 3,
              message: 'Min length is 4',
            },
          }}
          render={({ field: controllerField }) => (
            <FormField
              label={t('schema.field_name', 'Field name')}
              readonly={false}
              updateField={(e) => controllerField.onChange(e.value?.value === '' ? null : e.value?.value)}
              field={{
                disabled: false,
                id: field.name,
                input: {
                  type: 'TEXT',
                  name: controllerField.name,
                  placeholder: 'Field name',
                } as InputType,
                label: field.name,
                media: null,
                options: null,
                value: {
                  __uuid: uuid(),
                  value: controllerField.value,
                },
                error: (errors[field.name]?.message as string) ?? '',
              }}
            />
          )}
        />
        <Controller
          key="placeholder"
          name="placeholder"
          control={control}
          defaultValue={field.placeholder ?? ''}
          render={({ field: controllerField }) => (
            <FormField
              label={t('schema.placeholder', 'Placeholder')}
              readonly={false}
              updateField={(e) => controllerField.onChange(e.value?.value === '' ? null : e.value?.value)}
              field={{
                disabled: false,
                id: controllerField.name,
                uuid: field.input_uuid!,
                input: {
                  type: 'TEXT',
                  name: controllerField.name,
                  placeholder: 'Field placeholder',
                } as InputType,
                label: field.name,
                value: {
                  __uuid: uuid(),
                  value: controllerField.value,
                },
                error: (errors[field.name]?.message as string) ?? '',
              }}
            />
          )}
        />
        <Controller
          key="required"
          name="required"
          control={control}
          defaultValue={{ value: field.required, __uuid: uuid() }}
          rules={{
            required: 'required',
          }}
          render={({ field: controllerField }) => (
            <FormField
              label={t('schema.required', 'Required')}
              readonly={false}
              updateField={(fieldUpdated) => controllerField.onChange(fieldUpdated.value ?? null)}
              field={{
                id: controllerField.name,
                uuid: field.input_uuid!,
                input: {
                  type: 'TOGGLE',
                  name: controllerField.name,
                } as InputType,
                label: field.name,
                value: controllerField.value,
                error: (errors[field.input_uuid!]?.message as string) ?? '',
              }}
            />
          )}
        />
        <div className="flex justify-end">
          <button
            disabled={!isValid || !isDirty}
            type="submit"
            className="bg-sky-blue flex items-center rounded-md px-4 py-2 disabled:hover:brightness-100
        disabled:!bg-gray-200 disabled:!text-gray-400 text-white focus:outline-none focus:ring focus:border-blue-300"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

type StaticFieldsProps = {
  fields: DataSchemaCustomFieldType[]
}

export const StaticFields = ({ fields }: StaticFieldsProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex flex-col select-none">
        <h3 className="text-black-light font-bold text-lg">{t('schema.default_fields', 'Default fields')}</h3>
        <p className="text-black-light-2 text-sm">
          {t('schema.default_fields_description', 'These fields will be added as default for this schema')}
        </p>
      </div>
      <div className="space-y-2">
        {fields.map((field) => (
          <FieldItem
            key={`key-${field.name}`}
            classNames="bg-gray-100 text-gray-400 cursor-not-allowed"
            item={{
              field_uuid: uuid(),
              input_uuid: uuid(),
              name: t(field.name),
              type: t(field.type),
              values: null,
              required: false,
            }}
          />
        ))}
      </div>
    </>
  )
}

type DataSchemaActionsProps = Pick<DataSchemaViewResponseType, 'uuid' | 'name'>
export const DataSchemaActions = ({ name, uuid: schemaUUid }: DataSchemaActionsProps): JSX.Element => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const history = useHistory()
  const deleteMutation = useSchemaServiceDeleteMutation(schemaUUid)
  const renameMutation = useSchemaServiceRenameMutation(schemaUUid)
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm()

  const handleRename = async (data: any): Promise<void> => {
    await renameMutation
      .mutateAsync({ name: data.name })
      .then(() => {
        modal.close()
        toastr.success(t('generic.data_schema_renamed', 'Data schema renamed'))
      })
      .catch(() => {
        modal.close()
        toastr.error(t('generic.error', 'Failed to rename schema'))
      })
  }

  const handleDelete = (): void => {
    try {
      deleteMutation
        .mutateAsync()
        .then(() => {
          modal.close()
          toastr.success(t('generic.data_schema_deleted', 'Data schema deleted'))
          history.push('/settings/schemas')
        })
        .catch(() => {
          modal.close()
          toastr.error(t('generic.error', 'Failed to delete schema'))
        })
    } catch (e) {
      console.error('something when wrong deleting schema', e)
    }
  }

  const menuItems = [
    {
      name: 'generic.rename',
      onClick: () =>
        modal.set({
          isOpen: true,
          title: <h2 className="text-black font-bold">{t('generic.rename', 'Rename field')}</h2>,
          content: (
            <div className="flex flex-col space-y-5 transition duration-150 ease-out">
              <form onSubmit={handleSubmit(handleRename)} className="flex flex-col space-y-6">
                <input type="text" className="base-form-input" defaultValue={name} {...register('name')} />
                <div className="flex justify-end">
                  <button
                    disabled={!isValid}
                    type="submit"
                    className="bg-sky-blue flex items-center rounded-md px-4 py-2 disabled:hover:brightness-100
        disabled:!bg-gray-200 disabled:!text-gray-400 text-white focus:outline-none focus:ring focus:border-blue-300"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          ),
          baseClassName: 'items-center w-[600px]',
        }),
      dataCy: 'edit-rename',
    },
    {
      name: t('generic.delete_schema', 'Delete schema'),
      onClick: () =>
        modal.set({
          isOpen: true,
          title: <h2 className="text-black font-bold">{t('generic.delete_schema', 'Delete schema')}</h2>,
          content: (
            <ConfirmationModal onConfirm={() => handleDelete()}>
              <div>Are you sure you want to delete this schema?</div>
            </ConfirmationModal>
          ),
          baseClassName: 'items-center w-[600px]',
        }),
      dataCy: 'remove-input',
    },
  ]
  return <Menu menuItems={menuItems} buttonId="address-context-menu" buttonCy="address-context-menu" />
}
