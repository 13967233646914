import { useEffect } from 'react'
import { getLocalInfo, setLocalInfo } from '../../services/userService'
import useGetCurrentUser from '../../hooks/useGetCurrentUser'

export default async () => {
  const userData = getLocalInfo()
  const { data: currentUser } = useGetCurrentUser()

  useEffect(() => {
    if (!currentUser || !userData) {
      return
    }

    const { role, last_activity_at, last_login_at, onboarded_at, signed_up_at } = currentUser
    setLocalInfo({
      role,
      last_activity_at,
      last_login_at,
      onboarded_at,
      signed_up_at,
    })
  }, [currentUser])
}
