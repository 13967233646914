import LhotseHomeResultTransition from '../LhotseHomeResultTransition'

const LhotseHomeRequestSkeleton = () => (
  <LhotseHomeResultTransition>
    <div className="bg-white rounded-lg shadow-sm p-3 flex items-center transition">
      <div className="flex items-center space-x-3 w-full animate-pulse">
        <div className="rounded bg-gray-300 p-2.5">
          <div className="h-6 w-6 bg-gray-200" />
        </div>
        <div className="flex-1 space-y-2">
          <div className="w-full flex flex-row justify-between">
            <div className="h-3 bg-gray-200 rounded w-1/4" />
            <div className="bg-gray-200 rounded w-2 h-2" />
          </div>

          <div className="h-4 bg-gray-200 rounded w-3/4" />
          <div className="flex items-center space-x-2">
            <div className="h-6 w-6 bg-gray-200 rounded-full" />
            <div className="h-4 bg-gray-200 rounded w-1/2" />
          </div>
        </div>
      </div>
    </div>
  </LhotseHomeResultTransition>
)

export default LhotseHomeRequestSkeleton
