import { useCallback } from 'react'
import { Workflow } from '../../../types/Workflow/Workflow'
import { WorkflowBlock } from '../../../types/Workflow/WorkflowBlock'
import { ActiveWorkflow } from '../../../types/Workflow/ActiveWorkflow'

export const useFindWorkflowBlockStep = (workflow: Workflow | ActiveWorkflow | undefined) =>
  useCallback(
    (block: Pick<WorkflowBlock, 'uuid'> | undefined | null) =>
      workflow?.steps.find((step) => step.blocks.find(({ uuid }) => uuid === block?.uuid)) || null,
    [workflow]
  )

export default useFindWorkflowBlockStep
