import AuthenticationPage from '../../modules/Authentication/AuthenticationPage'
import AuthenticationRoute from '../../modules/Authentication/submodules/AuthenticationRoute'
import RouteType from '../../types/shared/RouteType'
import PageNotFound from '../components/ErrorPages/PageNotFound/PageNotFound'
import SomethingWentWrong from '../components/ErrorPages/SomethingWentWrong/SomethingWentWrong'
import Unauthorized from '../components/ErrorPages/UnAuthorized/Unauthorized'

const publicRoutes: RouteType[] = [
  {
    path: '/sso-login/:token?',
    component: <AuthenticationRoute />,
  },
  {
    path: '/login/:error?',
    component: <AuthenticationPage />,
  },
  {
    path: '/forgot-password',
    component: <AuthenticationPage />,
  },
  {
    path: '/reset-password',
    component: <AuthenticationPage />,
  },
  {
    path: '/join',
    component: <AuthenticationPage />,
  },
  {
    path: '/404',
    exact: true,
    component: <PageNotFound />,
  },
  {
    path: '/oops',
    exact: true,
    component: <SomethingWentWrong />,
  },
  {
    path: '/403',
    exact: true,
    component: <Unauthorized />,
  },
]

export default publicRoutes
