import { Transition } from '@headlessui/react'
import { FC } from 'react'

interface ILhotseHomeResultTransition {
  children?: React.ReactNode
}

const LhotseHomeResultTransition: FC<ILhotseHomeResultTransition> = ({ children }) => (
  <Transition
    appear
    show
    enter="transition-opacity duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
)

export default LhotseHomeResultTransition
