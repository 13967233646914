import { useTranslation } from 'react-i18next'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { ReactNode } from 'react'
import BaseInputWrapper from '../../../../../../shared/components/atoms/Input/BaseInputWrapper'
import SearchSelect, { ISearchSelect } from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import useGetWorkflowActionProviders from '../../../../hooks/actions/useGetWorkflowActionProviders'
import { InputSearchSelectItemType } from '../../../../../../types/shared/InputSearchSelectTypes'
import { ReactComponent as LhotseIcon } from '../../../../../../core/assets/logo-icon-lhotse-nofill.svg'
import { WorkflowActionProvider } from '../../../../../../types/Workflow/WorkflowAction'

const ProviderIcons: Record<number, ReactNode> = {
  1: <LhotseIcon className="w-5 h-5 !fill-black" />,
} as const

type WorkflowActionProviderFieldProps = Omit<ISearchSelect, 'onChange' | 'options'> & {
  selected?: WorkflowActionProvider | null
  onSelect: (action: WorkflowActionProvider | undefined) => void
}

export const WorkflowActionProviderField = ({
  selected,
  onSelect,
  ...selectorProps
}: WorkflowActionProviderFieldProps) => {
  const { data: providers } = useGetWorkflowActionProviders()
  const { t } = useTranslation()

  const providerOptions: InputSearchSelectItemType[] =
    providers?.map((provider) => ({
      id: provider.id,
      label: provider.name,
      icon: ProviderIcons[provider.id as keyof typeof ProviderIcons] || <PhotoIcon className="w-5 h-5" />,
    })) || []

  const handleChange = (value: InputSearchSelectItemType | undefined) => {
    if (!providers) return
    onSelect(providers?.find((provider) => provider.id === value?.id))
  }

  return (
    <BaseInputWrapper label={t('actions.action_type', 'Action type')}>
      <SearchSelect
        onChange={handleChange}
        placeholder={t('actions.action_type_placeholder', 'Select action provider')}
        options={providerOptions}
        currentSelection={providerOptions.find((option) => option.id === selected?.id)}
        {...selectorProps}
      />
    </BaseInputWrapper>
  )
}

export default WorkflowActionProviderField
