import { useMessengerContext } from '../../../../../core/providers/MessengerProvider'
import ReplyPreview from '../IntakeReplyPreview/ReplyPreview'

const NewMessageReplyPreview: React.FC = () => {
  const {
    messageProperties: [{ messageReply }],
  } = useMessengerContext()

  if (!messageReply.referenceId) return null

  return (
    <div className="space-x-2">
      {messageReply?.referenceId && (
        <div className="space-x-2 border border-white w-full mt-2">
          <ReplyPreview inChat author={messageReply.referenceName} message={messageReply.referenceText} />
        </div>
      )}
    </div>
  )
}

export default NewMessageReplyPreview
