import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import AddressType from '../../../../../../types/shared/AddressType'
import { AddressDataType } from '../../../../../../types/shared/RequisitionDataTypes'
import { toastr } from '../../../../../../shared/components/organisms/toastr'
import { replaceById } from '../../../../../../shared/utils/arrayHelpers'
import companyQueryStrings from '../../../../constants/companyQueryStrings'
import { apiUpdateAddress } from '../../../../services/CompanyAddressesService'
import { CompanyAddressType } from '../../../../../../types/Company/AddressTypes'

const useUpdateAddress = (addressId: number, onSuccess: (address: AddressDataType) => any) => {
  const cache = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation((item: CompanyAddressType) => apiUpdateAddress(item, addressId), {
    onSuccess: (newData) => {
      cache.setQueryData([companyQueryStrings.company_addresses], (oldData: AddressType[] | undefined) =>
        replaceById(
          oldData,
          newData.id,

          newData
        )
      )
      onSuccess(newData)
    },
    onError: () => {
      toastr.error(t('error._item_not_updated'), 'addressUpdatedError')
    },
  })

  return mutation
}

export default useUpdateAddress
