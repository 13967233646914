import { apiDelete, apiGetData, apiPost, apiPut } from '../../../core/utils/api/generic'
import { Workflow } from '../../../types/Workflow/Workflow'
import {
  WorkflowBlock,
  WorkflowBlockApprover,
  WorkflowBlockTrigger,
  WorkflowStep,
} from '../../../types/Workflow/WorkflowBlock'

const normalizeTrigger = (trigger: WorkflowBlockTrigger) => ({
  ...trigger,
})

const normalizeApprover = (approver: WorkflowBlockApprover) => ({
  ...approver,
  user: {
    ...approver.user,
    // @ts-ignore
    id: approver.user?.user_id,
  },
  value: approver.value,
  type: approver.type,
})

const normalizeBlock = (block: WorkflowBlock) => ({
  ...block,
  triggers: block.triggers?.map(normalizeTrigger),
  approvers: block.approvers?.map(normalizeApprover),
})

const normalizeStep = (step: WorkflowStep) => ({
  ...step,
  blocks: step.blocks?.map(normalizeBlock),
})

const normalizeWorkflow = (workflow: Workflow) => ({
  ...workflow,
  steps: workflow.steps?.map(normalizeStep),
})

export const getWorkflows = () => apiGetData<Workflow[]>('/workflows')

export const getWorkflow = (uuid: string, version: number) =>
  apiGetData<Workflow>(`/workflows/${uuid}/version/${version}`).then(normalizeWorkflow)

export const getWorkflowDraft = (uuid: string) =>
  apiGetData<Workflow>(`/workflows/${uuid}/edit`).then(normalizeWorkflow)

export const postWorkflow = (workflowData: Partial<Workflow> & Pick<Workflow, 'name'>) =>
  apiPost<{ data: Workflow }>('/workflows', workflowData).then(({ data }) => data)

export const deleteWorkflow = (id: string, version: number) => apiDelete(`/workflows/${id}/version/${version}`)

export const putWorkflow = (id: string, version: number, workflowData: Partial<Workflow>) =>
  apiPut(`/workflows/${id}/version/${version}/update`, workflowData)

export const renameWorkflow = (id: string, version: number, workflowData: Partial<Workflow>) =>
  apiPut(`/workflows/${id}/version/${version}/rename`, workflowData)

export const publishWorkflow = (uuid: string, version: number) =>
  apiPut(`/workflows/${uuid}/version/${version}/publish`)
