import { ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { blurInputOnEnter } from '../../../../utils/inputUtils'

interface IInputTextGrayInput {
  placeholder: string | undefined
  textValueWrapper: string
  onInputChange: ChangeEventHandler<HTMLInputElement>
  required: boolean | undefined
  disabled: boolean | undefined
  id: string | undefined
  dataCy: string | undefined
  onBlur: React.FocusEventHandler<HTMLInputElement> | undefined
  fullWidth: boolean | undefined
  className: string | undefined
  hasIcon: boolean | undefined
  name?: string
  maxLength?: number
}

const InputTextGrayInput: React.FC<IInputTextGrayInput> = ({
  placeholder,
  textValueWrapper,
  onInputChange,
  required,
  disabled,
  id,
  dataCy,
  onBlur,
  fullWidth,
  className,
  hasIcon,
  name,
  maxLength,
}) => {
  const { t } = useTranslation()

  return (
    <input
      name={name}
      placeholder={t(placeholder || '')}
      onKeyDown={blurInputOnEnter}
      value={textValueWrapper}
      onChange={onInputChange}
      required={required}
      disabled={disabled}
      id={id}
      data-cy={dataCy}
      onBlur={onBlur}
      className={`
          ${className} 
        py-2 px-4 rounded-md flex text-sm text-black max-w-full placeholder:text-secondary-gray  border border-gray-200
        ${fullWidth && 'w-full'} ${disabled && 'bg-gray-200'} ${hasIcon && 'pl-10'} 
      `}
      maxLength={maxLength}
    />
  )
}

export default InputTextGrayInput
