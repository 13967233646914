import { ComponentType } from 'react'
import { useWorkflowChart } from '../../../providers/WorkflowChartProvider'
import { WorkflowChartBlockNodeProps } from '../../../../../types/Workflow/WorkflowChart'

export const WorkflowChartBlockNodeRenderer = ({ data, ...nodeProps }: WorkflowChartBlockNodeProps) => {
  const { nodeTypes } = useWorkflowChart()
  const { type } = data

  if (!type) return null

  const NodeComponent = nodeTypes[type] as ComponentType<WorkflowChartBlockNodeProps>

  return <NodeComponent data={data} {...nodeProps} />
}

export default WorkflowChartBlockNodeRenderer
