import { useUtils } from '../../../../../core/providers/UtilsProvider'
import Content from './Content'
import Title from './Title'

const Body = () => {
  const {
    modal: {
      get: { className },
    },
  } = useUtils()

  return (
    <>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      <div
        className={`
        flex flex-col bg-white pt-5 text-left shadow-xl transform transition-all 
        w-full max-w-3xl max-h-[90vh] mx-4 rounded mt-12 gap-y-4 ${className}
      `}
      >
        <Title />
        <Content />
      </div>
    </>
  )
}

export default Body
