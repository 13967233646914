import { ForwardedRef, forwardRef } from 'react'
import ReactFlow, { Background, Controls } from 'reactflow'

import classnames from 'classnames'
import 'reactflow/dist/style.css'
import useWorkflowChartStructure from '../hooks/useWorkflowChartStructure'
import { useWorkflowChart } from '../providers/WorkflowChartProvider'
import WorkflowChartLayout from './WorkflowChartLayout'
import cn from '../../../lib/util'

// todo enable tailwind config import (by linking it inside node modules)
// import tailwindConfig from '../../../../tailwind.config'

// const Styles = resolveConfig(tailwindConfig)

const onInit = (reactFlowInstance: any) => {}

export type WorkflowChartProps = React.PropsWithChildren<{}> & React.HTMLAttributes<HTMLDivElement>

export const WorkflowChart = forwardRef(
  ({ children, className, ...props }: WorkflowChartProps, flowRef: ForwardedRef<HTMLDivElement>) => {
    const { nodes, setNodes, edges, setEdges, onEdgesChange, onNodesChange } = useWorkflowChartStructure()

    const { nodeTypes, edgeTypes } = useWorkflowChart()

    return (
      <div ref={flowRef} className={cn('w-full min-h-[72] bg-white', className)} {...props}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onInit={onInit}
          fitView
          proOptions={{ hideAttribution: true }}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
          zoomOnScroll={false}
          preventScrolling={false}
          maxZoom={1.25}
        >
          <Controls showInteractive={false} />
          {/* <Background color={Styles.theme?.colors?.['rock-gray']} gap={16} /> */}
          <Background color="#aaa" gap={16} />
          {children}
          <WorkflowChartLayout updateNodes={setNodes} updateEdges={setEdges} />
        </ReactFlow>
      </div>
    )
  }
)

WorkflowChart.displayName = 'Workflow chart'

export default WorkflowChart
