import { getValidDate } from '../../../shared/utils/dataParsing'
import FormDateInput from '../FormInputs/FormDateInput'
import { useFormField } from '../providers/FormFieldProvider'

export const FormFieldDateRange = () => {
  const {
    field: {
      id,
      value,
      disabled,
      input: { type },
    },
    updateValue,
  } = useFormField()

  const handleUpdate = (startDate: Date, endDate?: Date | undefined | string) => {
    const datesToSave = [startDate, getValidDate(endDate?.toString() || '')]
    datesToSave.sort((a, b) => new Date(a || '').getTime() - new Date(b || '').getTime())
    updateValue(datesToSave.map((d) => d?.toISOString()).join(','))
  }

  if (!type.includes('DATE_RANGE')) {
    return null
  }

  const dates = (value?.value as string)?.split(',')

  return (
    <div className="flex flex-col gap-4 text-base font-normal">
      <div className="flex flex-row items-center w-full">
        <div key={`intake-field${id}`} className="w-full">
          <div className="w-full">
            <FormDateInput
              disabled={disabled}
              date={dates ? (dates[0] as string) : ''}
              type={type as 'DATE_RANGE'}
              secondDate={dates ? (dates[1] as string) : ''}
              onChange={handleUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormFieldDateRange
