import { useHistory } from 'react-router'

const useGetInitialValues = () => {
  const history = useHistory()

  const token = new URLSearchParams(history.location.search).get('token') || ''
  const email = new URLSearchParams(history.location.search).get('email') || ''

  return {
    email,
    first_name: '',
    password: '',
    last_name: '',
    token,
  }
}

export default useGetInitialValues
