import { useUtils } from '../../../../../core/providers/UtilsProvider'
import NewWorkflowModalHeader from '../NewWorkflowModalHeader'
import NewWorkflowModalBody from '../NewWorkflowModalBody'

export const useNewWorkflowModal = () => {
  const { modal } = useUtils()

  return () => {
    modal.set({
      isOpen: true,
      title: <NewWorkflowModalHeader />,
      content: <NewWorkflowModalBody />,
      className: 'max-w-[500px]',
    })
  }
}

export default useNewWorkflowModal
