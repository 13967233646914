import type { PartialDeep } from 'type-fest'
import { FormQuestionValidation } from '../../../../types/Forms/formTypes'
import { useFormContext } from '../../../../core/providers/FormProvider'

export const useUpdateQuestionValidation = () => {
  const { updateQuestion } = useFormContext()

  return (validation: PartialDeep<FormQuestionValidation>) => updateQuestion({ validation })
}

export default useUpdateQuestionValidation
