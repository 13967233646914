import { useHistory } from 'react-router'
import { useCallback } from 'react'

export const useGoToExternalDataEntry = (uuid: string) => {
  const history = useHistory()

  return useCallback(() => {
    history.push(`/data-manager/${uuid}`)
  }, [history, uuid])
}

export default useGoToExternalDataEntry
