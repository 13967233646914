import { useState } from 'react'
import coreColors, { coreColorsType } from '../../../constants/coreColors'
import cn from '../../../../lib/util'

interface ICheckbox {
  onChange?: (v: boolean) => void
  disabled?: boolean
  checked?: boolean
  className?: string
  color?: coreColorsType
  id?: string
  rounded?: boolean
  dataCy?: string
}

const Checkbox: React.FC<ICheckbox> = ({ disabled, checked, className, color, onChange, id, rounded, dataCy }) => {
  const [checkedState, setCheckedState] = useState(checked)

  const parsedChecked = checked !== undefined && onChange ? checked : checkedState

  const style = { backgroundColor: coreColors[color || ''] }

  return (
    <input
      id={id}
      type="checkbox"
      className={cn(
        'transition appearance-none',
        'hover:bg-rock-gray',
        'checked:border-transparent',
        'ring-0 focus:ring-sky-blue-light focus:ring-1',
        'border-rock-gray disabled:!bg-rock-gray',
        'cursor-pointer',
        { 'rounded-full': rounded },
        { 'rounded-sm': !rounded },
        { 'hover:text-sky-blue-light checked:bg-sky-blue-light': !disabled },
        { 'focus:text-sky-blue-light': color },
        className
      )}
      data-cy={dataCy}
      style={style}
      disabled={disabled}
      checked={parsedChecked}
      onChange={() => {
        onChange?.(!parsedChecked)
        setCheckedState(!parsedChecked)
      }}
    />
  )
}

export default Checkbox
