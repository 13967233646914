import AddQuestionGroupButton from '../buttons/AddQuestionGroupButton'
import FormQuestionGroups from './FormQuestionGroups'
import FormNavigationTabs from '../../FormNavigationTabs'

const FormQuestions: React.FC = () => (
  <div className="flex flex-col h-full">
    <div className="flex flex-row justify-between items-center">
      <FormNavigationTabs />
      <AddQuestionGroupButton />
    </div>
    <FormQuestionGroups />
  </div>
)

export default FormQuestions
