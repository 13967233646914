import { MdClose } from 'react-icons/md'
import { useQuestionItemsList } from './QuestionItemsList'
import type { QuestionItemType } from './QuestionItem'
import QuestionButton from '../inputs/QuestionButton'

interface IDeleteQuestionChoiceButton {
  item: QuestionItemType
}

const DeleteQuestionItemButton: React.FC<IDeleteQuestionChoiceButton> = ({ item }) => {
  const { onDelete } = useQuestionItemsList()

  if (!onDelete) return null

  return (
    <QuestionButton
      className="flex items-center justify-center"
      type="button"
      tabIndex={-1}
      onClick={() => onDelete(item)}
    >
      <MdClose className="text-secondary-gray" />
    </QuestionButton>
  )
}

export default DeleteQuestionItemButton
