import Table from '../../../../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../../../../shared/components/atoms/SharedTable/TableContainer'
import Menu from '../../../../../../../shared/components/organisms/menus/Menu'
import { Offering } from '../../../../../../../types/Offering'
import useOfferingMutations from '../../../../hooks/useOfferingMutations'
import IntakeOfferingSectionForm from './IntakeOfferingSectionForm'
import IntakeOfferingSectionMainTableRow from './IntakeOfferingSectionMainTableRow'
import IntakeOfferingSectionTableHeader from './IntakeOfferingSectionTableHeader'

interface IIntakeOfferingSection {
  offerings: Offering[]
}
const IntakeOfferingSection: React.FC<IIntakeOfferingSection> = ({ offerings }) => {
  const { remove } = useOfferingMutations()

  const handleRemoveOffering = (offering: Offering) => {
    remove.mutate(offering.uuid)
  }

  return (
    <TableContainer border={false}>
      <IntakeOfferingSectionTableHeader />
      <Table>
        {offerings.map((offering) => (
          <IntakeOfferingSectionMainTableRow
            key={offering.uuid}
            offering={offering}
            actions={
              <Menu
                menuItems={[
                  { name: 'Delete', onClick: () => handleRemoveOffering(offering), disabled: offerings.length === 1 },
                ]}
              />
            }
          >
            <IntakeOfferingSectionForm offering={offering} />
          </IntakeOfferingSectionMainTableRow>
        ))}
      </Table>
    </TableContainer>
  )
}

export default IntakeOfferingSection
