import { useTranslation } from 'react-i18next'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { ChangeEvent } from 'react'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import useGetExternalDataRelationshipList from '../../hooks/useExternalDataRelationships'
import AddButton from '../../../../shared/components/atoms/buttons/AddButton'
import ExternalDataEmbeddingsTableHeader from './ExternalDataEmbeddingsTableHeader'
import ExternalDataEmbeddingsTableBody from './ExternalDataEmbeddingsTableBody'
import Button from '../../../../shared/components/atoms/buttons/Button'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import ExternalDataEmbeddingsModal from './ExternalDataEmbeddingsModal'
import { apiGetExternalDataEntityData } from '../../services/ExternalDataServices'
import useCurrentExternalDataSourceUuid from '../../hooks/useCurrentExternalDataSourceUuid'
import useUrlParams from '../../../LhotseHome/hooks/useUrlParams'
import useGetExternalDataEntityData from '../../hooks/useGetExternalDataEntityData'
import useDebouncedValue from '../../../../core/hooks/useDebouncedValue'
import useGetCurrentUser from '../../../../core/hooks/useGetCurrentUser'
import Input from '../../../../shared/components/atoms/Input/Input'

export const ExternalDataEmbeddingsTable = () => {
  const { data: columns, isLoading } = useGetExternalDataRelationshipList()
  const externalDataUuid = useCurrentExternalDataSourceUuid()
  const { getParam: getFilter, setParam: setFilter, clearFilters } = useUrlParams()
  const { t } = useTranslation()
  const queryValue = useDebouncedValue({ query: getFilter('query') }, 500)
  const countValue = useDebouncedValue({ query: getFilter('count') }, 500)
  const thresholdValue = useDebouncedValue({ query: getFilter('threshold') }, 500)
  const { data, isLoading: isLoadingEmbeddings } = useGetExternalDataEntityData(
    externalDataUuid,
    `?query=${queryValue.query}&threshold=${thresholdValue.query}&count=${countValue.query}&embeddings=true`
  )
  const currentUserRole = useGetCurrentUser()?.data?.role

  const { modal } = useUtils()

  if (isLoading) {
    return (
      <div className="m-4">
        <CoreLoadingIcon />
      </div>
    )
  }

  const onEditEmbeddingsClick = () => {
    if (!columns?.length) return

    modal.set({
      isOpen: true,
      title: t('external_data.edit_embeddings', 'Edit embeddings'),
      content: <ExternalDataEmbeddingsModal columns={columns} />,
      className: 'max-w-[500px]',
    })
  }

  const embeddingsTableHeader = (
    <div className="flex flex-row justify-between items-center w-full p-4 mb-8">
      <div>
        <div className="text-sm font-semibold text-gray-600 pb-2">{t('external_data.embeddings', 'Embeddings')}</div>
        <div className="text-sm font-medium text-gray-400">
          {t(
            'external_data.manage_embeddings',
            'Easily manage and optimise your embeddings for enhanced data representation.'
          )}
        </div>
      </div>
      <Button className="btn btn-primary" type="button" onClick={onEditEmbeddingsClick} disabled={!columns}>
        {t('external_data.edit_embeddings', 'Edit embeddings')}
      </Button>
    </div>
  )
  const getData = () => {
    const getQuery = getFilter('query')

    if (!isLoadingEmbeddings && !data?.length && !getQuery) {
      return JSON.stringify('No query value')
    }

    if (isLoadingEmbeddings) {
      return JSON.stringify('Loading')
    }

    return JSON.stringify(data, null, 2)
  }

  function searchValueChange(value: any, type: string) {
    setFilter({ [type]: value })
  }

  return (
    <>
      <TableContainer
        header={embeddingsTableHeader}
        emptyState={
          !columns?.length && (
            <div className="flex flex-col items-center">
              <div className="mb-4">
                <DocumentIcon className="h-6 text-rock-gray" />
              </div>
              <div className="text-rock-gray">
                {t('external_data.missing_relationships', 'No relationships created')}
              </div>
              <div>
                <AddButton label={t('external_data.add_relationship', 'Add new relationship')} />
              </div>
            </div>
          )
        }
      >
        <ExternalDataEmbeddingsTableHeader />
        <ExternalDataEmbeddingsTableBody />
      </TableContainer>

      <div className={`space-y-5 ${currentUserRole === 'super_admin'}`}>
        <div className="flex flex-col space-y-2">
          <h1 className="text-2xl font-bold text-gray-500">Search in embeddings playground</h1>
          <div className="text-sm text-gray-500">This is only visible for admins</div>
        </div>
        <div className="flex flex-full justify-between">
          <div className="flex space-x-6">
            <Input
              className="rounded border-gray-500"
              placeholder="Search"
              defaultValue={getFilter('query')}
              onBlur={(value) => searchValueChange(value, 'query')}
            />
            <Input
              className="rounded border-gray-500"
              placeholder="Threshold (Default 0.5)"
              defaultValue={getFilter('threshold')}
              onBlur={(value) => searchValueChange(value, 'threshold')}
            />
            <Input
              className="rounded border-gray-500"
              placeholder="Count (Default 10)"
              defaultValue={getFilter('count')}
              onBlur={(value) => searchValueChange(value, 'count')}
            />
          </div>
          <button type="button" className="py-1 px-5 bg-sky-blue rounded text-white" onClick={clearFilters}>
            Clear filters
          </button>
        </div>
        <div className="flex flex-full">
          <pre className="bg-gray-200 w-full p-5 rounded">{getData()}</pre>
        </div>
      </div>
    </>
  )
}

export default ExternalDataEmbeddingsTable
