import { useTranslation } from 'react-i18next'
import BaseInputWrapper from '../../../../../../shared/components/atoms/Input/BaseInputWrapper'
import SearchSelect, { ISearchSelect } from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { InputSearchSelectItemType } from '../../../../../../types/shared/InputSearchSelectTypes'
import { WorkflowAction, WorkflowActionProvider } from '../../../../../../types/Workflow/WorkflowAction'
import useGetWorkflowActions from '../../../../hooks/actions/useGetWorkflowActions'

type WorkflowActionFieldProps = Omit<ISearchSelect, 'onChange' | 'options'> & {
  provider?: WorkflowActionProvider | null
  selected?: WorkflowAction | null
  onSelect: (provider: WorkflowAction | undefined) => void
}

export const WorkflowActionField = ({ provider, selected, onSelect, ...selectorProps }: WorkflowActionFieldProps) => {
  const { data: actions } = useGetWorkflowActions(provider?.name || undefined, { enabled: !!provider })
  const { t } = useTranslation()

  const actionTypeOptions: InputSearchSelectItemType[] =
    actions?.map((action) => ({
      id: action.uuid,
      label: action.name,
    })) || []

  const handleChange = (value: InputSearchSelectItemType | undefined) => {
    if (!actions) return
    onSelect(actions?.find((action) => action.uuid === value?.id))
  }

  return (
    <BaseInputWrapper label={t('actions.select_an_action', 'Select an action')}>
      <SearchSelect
        onChange={handleChange}
        placeholder={t('actions.select_an_action', 'Select an action')}
        options={actionTypeOptions}
        currentSelection={actionTypeOptions.find((option) => option.id === selected?.uuid)}
        {...selectorProps}
      />
    </BaseInputWrapper>
  )
}

export default WorkflowActionField
