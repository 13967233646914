import { Draggable } from 'react-beautiful-dnd'
import { MdDragIndicator, MdInfoOutline } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import EditDeleteOptions from '../../../../../../shared/components/organisms/menus/EditDeleteMenu'
import Tooltip from '../../../../../../shared/components/organisms/tooltip/Tooltip'
import { FormSectionCategoryType, FormSectionType } from '../../../../../../types/Forms/formTypes'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateForm from '../../../hooks/useUpdateForm'
import AddQuestionButton from '../../buttons/AddQuestionButton/AddQuestionButton'
import QuestionsTable from './QuestionsTable'
import TitleInput from './TitleInput'

interface IFormQuestionGroup {
  section: FormSectionType
  sectionIndex: number
}

const FormQuestionGroup: React.FC<IFormQuestionGroup> = ({ section, sectionIndex }) => {
  const { t } = useTranslation()
  const { updateForm } = useUpdateForm()
  const { data: formData } = useGetFormData()

  const getSectionTypeDisplayName = (type: FormSectionCategoryType) => {
    switch (type) {
      case 'PRODUCT':
        return t('section_type.product', 'Product')
      case 'OFFER':
        return t('section_type.offer', 'Offer')
      case 'FINAL':
        return t('section_type.final', 'Final')
      case 'GENERAL_INFO':
        return t('section_type.general_info', 'General Info')
      case 'GENERAL':
        return t('section_type.general', 'General')
      default:
        return t('section_type.general', 'General')
    }
  }

  const deleteQuestionGroup = () => {
    if (!formData) return

    updateForm({
      template: {
        sections: formData.template.sections.filter((templateSection) => templateSection.__uuid !== section.__uuid),
      },
    })
  }

  return (
    <Draggable draggableId={section.__uuid} key={section.__uuid} index={sectionIndex}>
      {(provided) => (
        <div
          className="bg-white p-4 rounded-xl border border-border-gray gap-y-6 flex flex-col"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="flex gap-x-4 w-full">
            <span {...provided.dragHandleProps}>
              <MdDragIndicator />
            </span>
            <div className="flex flex-col gap-y-2 w-full">
              <div className="flex gap-x-2 items-center text-secondary-gray text-xs font-medium">
                <p className="uppercase">{getSectionTypeDisplayName(section.type)}</p>
                <Tooltip tooltip="Enter questions to get more details about the product." showCondition>
                  <MdInfoOutline />
                </Tooltip>
                {section.loop?.__looped && <p className="text-vibrant-blue">LOOP</p>}
              </div>
              <div className="flex justify-between">
                <TitleInput section={section} index={sectionIndex} />
                {section.actions.remove && <EditDeleteOptions onDelete={deleteQuestionGroup} />}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-3">
            <QuestionsTable section={section} sectionIndex={sectionIndex} />
            <AddQuestionButton section={section} />
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default FormQuestionGroup
