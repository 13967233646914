import { WorkflowActionAttribute } from '../../../../../../types/Workflow/WorkflowAction'
import FormField from '../../../../../Forms-v2/FormField'
import BaseInputWrapper from '../../../../../../shared/components/atoms/Input/BaseInputWrapper'
import { CompanyUserType } from '../../../../../../types/shared/RequisitionDataTypes'

type WorkflowActionAttributeFieldProps = {
  attribute: WorkflowActionAttribute
  onUpdate: (updated: WorkflowActionAttribute) => void
  error?: string
}

const attributeValueToFormFieldValue = (attribute: WorkflowActionAttribute) => {
  const wrapInputValue = (value: number | string | undefined | boolean) => {
    if (attribute.input.type === 'MULTI_USER') {
      return { id: value } as CompanyUserType
    }
    return value ?? ''
  }

  const wrapFormFieldValue = (value: number | string | undefined | boolean) => ({
    value: wrapInputValue(value),
    __uuid: value?.toString() || '',
  })

  if (Array.isArray(attribute.value)) {
    return attribute.value.map(wrapFormFieldValue)
  }

  return wrapFormFieldValue(attribute.value)
}
export const WorkflowActionAttributeField = ({ attribute, error, onUpdate }: WorkflowActionAttributeFieldProps) => (
  <BaseInputWrapper label={attribute.name}>
    <FormField
      field={{
        id: attribute.id.toString(10),
        input: attribute.input,
        value: attributeValueToFormFieldValue(attribute),
        media: null,
        disabled: attribute.__disabled,
        error,
      }}
      updateField={({ value }) => {
        onUpdate({
          ...attribute,
          value: (Array.isArray(value)
            ? value.map(({ value }) => value.id || value)
            : value?.value) as unknown as string,
        })
      }}
    />
  </BaseInputWrapper>
)

export default WorkflowActionAttributeField
