import { useTranslation } from 'react-i18next'
import { MdOpenInNew, MdStar } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { FC } from 'react'
import { useUtils } from '../../../core/providers/UtilsProvider'
import TabsNavigation from '../../../shared/components/atoms/Tabs/TabsNavigation'
import { useSuppliersProvider } from '../../Suppliers/providers/SuppliersProvider'
import useGetSupplierBranch from '../hooks/useGetSupplierBranch'
import useGetSupplierDetailsTabs from '../hooks/useGetSupplierDetailsTabs'
import useIsSupplierDetailsLoading from '../hooks/useIsSupplierDetailsLoading'

const SupplierDetailsModalHeader: FC<{ supplierBranchId: number }> = ({ supplierBranchId }) => {
  const { modal } = useUtils()
  const { data: supplierBranchData } = useGetSupplierBranch()
  const {
    activeSupplierModalTab: [activeSupplierModalTab],
  } = useSuppliersProvider()

  const { t } = useTranslation()

  const tabs = useGetSupplierDetailsTabs(supplierBranchId, true)

  const isLoading = useIsSupplierDetailsLoading()

  if (isLoading) return null

  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-x-4 items-start text-2xl leading-none">
        <MdStar className={supplierBranchData?.preferred ? 'text-sky-blue' : 'text-secondary-gray'} />
        <div className="flex flex-col gap-y-2">
          <p className="">{supplierBranchData?.name}</p>
          <Link
            to={`/supplier/${supplierBranchId}/${activeSupplierModalTab}`}
            onClick={() => modal.set({ isOpen: false })}
            className="flex gap-x-2 items-center text-sm"
          >
            <p className="underline">{t('supplier.view_details')}</p>
            <MdOpenInNew className="text-primary-gray" />
          </Link>
        </div>
      </div>
      <div className="pt-6">
        <div className="border-b border-lhotse-gray w-full">
          <TabsNavigation tabs={tabs} currentTab={activeSupplierModalTab} />
        </div>
      </div>
    </div>
  )
}

export default SupplierDetailsModalHeader
