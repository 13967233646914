import { apiDelete, apiPost, apiPut } from '../../../core/utils/api/generic'
import {
  WorkflowBlock,
  WorkflowBlockApprover,
  WorkflowBlockTrigger,
  WorkflowStep,
} from '../../../types/Workflow/WorkflowBlock'

export type CreateWorkflowStepRequestBody = {
  position: WorkflowStep['position']
  blocks: Partial<WorkflowBlock>[]
}

const assureApproverIsBackendCompatible = (approver: Partial<WorkflowBlockApprover>) => ({
  ...approver,
  type: approver.type,
  value: approver.value,
})

const assureTriggerIsBackendCompatible = (trigger: Partial<WorkflowBlockTrigger>) => ({
  ...trigger,
  input_uuid: trigger.input_reference,
  input_type: trigger.input_type,
})

const assureBlockIsBackendCompatible = (block: Partial<WorkflowBlock>) => ({
  ...block,
  triggers: block.triggers?.map(assureTriggerIsBackendCompatible),
  approvers: block.approvers?.map(assureApproverIsBackendCompatible),
})

const assureStepIsBackendCompatible = (step: Partial<CreateWorkflowStepRequestBody>) => ({
  ...step,
  blocks: step.blocks?.map(assureBlockIsBackendCompatible),
})

export const createWorkflowStep = (
  workflowUuid: string,
  workflowVersion: number,
  workflowStepData: CreateWorkflowStepRequestBody
) =>
  apiPost(
    `/workflows/${workflowUuid}/version/${workflowVersion}/steps`,
    assureStepIsBackendCompatible(workflowStepData)
  )

export const createWorkflowBlock = (
  workflowUuid: string,
  workflowVersion: number,
  workflowStepUuid: string,
  workflowBlocksData: Partial<WorkflowBlock>[]
) =>
  apiPost(
    `/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks`,
    assureStepIsBackendCompatible({ blocks: workflowBlocksData })
  )

export const updateWorkflowBlock = (
  workflowUuid: string,
  workflowVersion: number,
  workflowStepUuid: string,
  workflowBlockUuid: string,
  workflowBlocksData: Partial<WorkflowBlock>
) =>
  apiPut(
    `/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks/${workflowBlockUuid}`,
    assureBlockIsBackendCompatible(workflowBlocksData)
  )

export const deleteWorkflowBlock = (
  workflowUuid: string,
  workflowVersion: number,
  workflowStepUuid: string,
  workflowBlockUuid: string
) =>
  apiDelete(
    `/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks/${workflowBlockUuid}`
  )
