import SearchSelect from '../../../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionType } from '../../../../../../../../../types/Forms/formTypes'
import { InputSearchSelectItemType } from '../../../../../../../../../types/shared/InputSearchSelectTypes'
import useGetSearchSelectOptionsFromFile from '../../../../../../../../../shared/hooks/useGetSearchSelectOptionsFromFile'

interface ILogicComparatorDropdown {
  question?: FormQuestionType
  value: string | number | undefined | null
  onChange: (value: InputSearchSelectItemType) => void
}

const LogicComparatorDropdown: React.FC<ILogicComparatorDropdown> = ({ value, onChange, question }) => {
  const options =
    question?.options?.map((option, index) => ({
      id: option.__uuid || index,
      label: option.value,
    })) || []

  // Attempts to load search select options from file, returns nothing if it's not relevant
  const { options: inputOptions, visibleColumns } = useGetSearchSelectOptionsFromFile(
    question?.__uuid,
    question?.inputId
  )

  const optionsToUse: InputSearchSelectItemType[] = inputOptions && inputOptions.length > 0 ? inputOptions : options
  const selected = optionsToUse.find((option) => option.id === value)

  return (
    <SearchSelect
      className="text-sm"
      options={optionsToUse}
      onChange={onChange}
      currentSelection={selected}
      visibleColumns={visibleColumns}
    />
  )
}

export default LogicComparatorDropdown
