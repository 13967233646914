import { FormDataType } from '../../../types/Forms'
import useGetCompanyFormsWithTypes from '../../Forms/FormBuilder/hooks/useGetCompanyFormsWithTypes'
import useUrlParams from './useUrlParams'

export default function useFilteredForms() {
  const { getParam: getFilter } = useUrlParams()

  const { data, isLoading } = useGetCompanyFormsWithTypes(true)

  const filterForms = (form: FormDataType) =>
    getFilter('query') ? form.name.toLowerCase().includes(getFilter('query').toLowerCase()) : true

  const filteredForms = data?.filter(filterForms)

  return {
    filteredForms,
    data,
    isLoading,
  }
}
