import { useQuery } from 'react-query'
import { TagCategoryType } from '../../../shared/components/atoms/tags/type'
import { apiGetSupplierBranchTag } from '../../Suppliers/services/SuppliersService'
import useGetSupplierBranchId from './useGetSupplierBranchId'

export default (type: TagCategoryType) => {
  const supplierBranchId = useGetSupplierBranchId()

  return useQuery(['tags', type, supplierBranchId], () => apiGetSupplierBranchTag(supplierBranchId, type))
}
