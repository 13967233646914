import { useMutation } from 'react-query'
import { apiGet, apiGetData, apiPostData } from '../../../../../../core/utils/api/generic'

export interface PreviewImage {
  url: string
}

export interface AvailableBucket {
  bucketId: string
  value: string
  count: number
}

export interface SelectedBucket {
  bucketId: string
  value: string
}

export interface ManufacturerFacet {
  name: string
  availableBuckets: AvailableBucket[]
  selectedBuckets: SelectedBucket[]
}

export interface DynamicFacet {
  facetId: string
  name: string
  availableBuckets: AvailableBucket[]
  selectedBuckets: SelectedBucket[]
}

export interface ClassificationIdFacet {
  name: string
  availableBuckets: AvailableBucket[]
  selectedBuckets: SelectedBucket[]
}

export interface PriceRange {
  availableMinimumPrice: number
  availableMaximumPrice: number
  currency: string
  selectedMinimumPrice: number
  selectedMaximumPrice: number
}

export interface UniteCatalogSearchItemType {
  id: string
  name: string
  manufacturer: string
  manufacturerArticleId: string
  gtin: string
  previewImage: PreviewImage
  orderUnit: string
  minimumOrderQuantity: number
  quantityInterval: number
  price: number
  currency: string
  shippingCosts: number
  deliveryTime: number
  classificationId: string
  inStock: boolean
}

export interface UniteCatalogSearchResponseType {
  items: UniteCatalogSearchItemType[]
  limit: number
  offset: number
  total: number
  sortField: string
  sortDirection: string
  classificationIdFacet: ClassificationIdFacet
  manufacturerFacet: ManufacturerFacet
  dynamicFacets: DynamicFacet[]
  priceRange: PriceRange
  showInStockOnly: boolean
}

export interface UniteCatalogSearchRequestFilterType {
  bucketIds: string[]
}

export interface UniteCatalogSearchRequestType {
  query: string
  manufacturerFilter?: UniteCatalogSearchRequestFilterType
  classficationFilter?: UniteCatalogSearchRequestFilterType
  dynamicFacetFilters?: (UniteCatalogSearchRequestFilterType & { facetId: string })[]
  offset?: number
  limit?: number
}

export const apiGetSearchCatalogItems = async (
  body: UniteCatalogSearchRequestType
): Promise<UniteCatalogSearchResponseType> => apiPostData('/unite', body)

export const getUniteCatalogItem = (id: string) => apiGetData(`/unite/items/${id}`)

export default function useCatalogSearchService() {
  const search = useMutation((body: UniteCatalogSearchRequestType) => apiGetSearchCatalogItems(body))

  return { search }
}
