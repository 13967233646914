import classNames from 'classnames'
import { FC } from 'react'
import { FormQuestionResponseType, FormQuestionValue } from '../../../../types/Forms'

interface IFormSelectButton {
  isSelected: boolean
  isDisabled: boolean
  error?: string | null | undefined
  inputErrorClassNames?: string
  inputErrorTextClassNames?: string
  isSingleSelect: boolean
  option: FormQuestionResponseType
  handleOnClick: () => void
}

const FormSelectButton: FC<IFormSelectButton> = ({
  isSelected,
  isDisabled,
  error,
  inputErrorClassNames,
  inputErrorTextClassNames,
  isSingleSelect,
  option,
  handleOnClick,
}) => (
  <button
    type="button"
    className="flex gap-x-4 items-center w-full bg-white"
    onClick={handleOnClick}
    disabled={isDisabled}
  >
    <div
      className={classNames('flex gap-x-3 items-center py-2 px-3 border rounded-md w-full text-left base-form-input ', {
        'bg-gray-100': isDisabled,
        [inputErrorClassNames || '']: !!error,
        'm-px text-nds-secondary-gray': !!error,
        'border-sky-blue border ': !error && isSelected,
        'm-px  border-white shadow': !error && !isSelected,
      })}
    >
      <div className="w-fit">
        <div
          className={` ${error ? `${inputErrorClassNames} ${inputErrorTextClassNames}` : ''} p-0.5  border  shadow 
      ${
        isSelected ? 'border border-sky-blue bg-sky-blue bg-opacity-10' : 'bg-gray-50'
      } w-fit flex items-center justify-center 
        ${isSingleSelect ? 'rounded-full' : 'rounded-sm'}`}
        >
          <p
            className={`w-2 h-2  ${!isSelected ? 'bg-white' : 'bg-sky-blue '} ${
              isSingleSelect ? 'rounded-full' : 'rounded-sm'
            }`}
          />
        </div>
      </div>
      <p className="leading-6">{option.display_name || option.value}</p>
    </div>
  </button>
)

export default FormSelectButton
