import { useMutation, useQueryClient } from 'react-query'
import useGetSupplierBranchId from '../../../../../modules/SupplierDetails/hooks/useGetSupplierBranchId'
import { apiDeleteSupplierBranchTag } from '../../../../../modules/Suppliers/services/SuppliersService'
import { TagType } from '../../../../../types/shared/GeneralTypes'
import toastr from '../../../organisms/toastr/toastr'
import { TagCategoryType } from '../type'

export default (category: TagCategoryType, onSuccess?: (data: any) => any) => {
  const supplierBranchId = useGetSupplierBranchId()

  const cache = useQueryClient()

  return useMutation((tag: Omit<TagType, 'type'>) => apiDeleteSupplierBranchTag(supplierBranchId, tag.id), {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data)
      }

      cache.setQueryData(['tags', category, supplierBranchId], () => data?.data)
      toastr.success('success.item_deleted', 'tagsDeleted')
    },
  })
}
