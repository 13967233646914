import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import Input from '../../../../shared/components/atoms/Input/Input'
import useUrlParams from '../../hooks/useUrlParams'
import cn from '../../../../lib/util'

interface ILhotseHomeCreateSearch {
  activeSearch: string
  setActiveSearch: (active: 'all' | 'forms') => void
}

const LhotseHomeSearch: FC<ILhotseHomeCreateSearch> = ({ activeSearch, setActiveSearch }) => {
  const { t } = useTranslation()

  const { getParam, setParam } = useUrlParams()

  return (
    <div className="space-y-6">
      <Input
        placeholder={`${t('generic.search', 'Search')}...`}
        defaultValue={getParam('query')}
        className="pl-8 py-3 transition-colors hover:border-sky-blue cursor-text shadow-sm"
        onChange={(e) => {
          setParam({ query: e.currentTarget.value })
        }}
        lead={
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 mr-10">
            <MagnifyingGlassIcon className="w-4 opacity-70" />
          </div>
        }
      />

      <div className="flex space-x-2">
        <button
          type="button"
          className={cn(
            'px-4 py-1 rounded-md text-sm font-medium text-gray-600 transition-colors hover:bg-gray-200',
            activeSearch === 'all' ? 'bg-gray-100' : 'bg-transparent'
          )}
          onClick={() => setActiveSearch('all')}
        >
          {t('generic.all', 'All')}
        </button>
        <button
          type="button"
          className={cn(
            'px-4 py-1 rounded-md text-sm font-medium text-gray-600 transition-colors hover:bg-gray-200',
            activeSearch === 'forms' ? 'bg-gray-100' : 'bg-transparent'
          )}
          onClick={() => setActiveSearch('forms')}
        >
          {t('generic.forms', 'Forms')}
        </button>
      </div>
    </div>
  )
}

export default LhotseHomeSearch
