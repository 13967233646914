import { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import CoreLoadingIcon from '../../../../../../core/components/CoreLoadingIcon'
import fillAddress from '../../../../utils/fillAddress'
import { CompanyAddressType } from '../../../../../../types/Company/AddressTypes'

interface IPlacesAutocompleteComponent {
  selectAddress: (v: CompanyAddressType) => void
}

const PlacesAutocompleteComponent: React.FC<IPlacesAutocompleteComponent> = ({ selectAddress }) => {
  const { t } = useTranslation()
  const [address, setAddress] = useState('')

  const handleSelect = async (value: string) => {
    if (value) {
      setAddress(value)
      const results = await geocodeByAddress(value)
      selectAddress(fillAddress(results[0].address_components))
      setAddress('')
    }
  }
  return (
    <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="mb-3">
          <div className="border-b-2 border-gray-200 border-dashed">
            <input
              name="name"
              className="
              w-full
              mr-6 border-none
              focus:bg-white
              text-gray-900
              placeholder-gray-300
              pb-1 px-0 focus:ring-0
              disabled:border-b
              disabled:border-dashed disabled:bg-white
              disabled:opacity-60 disabled:border-red-500 rounded-sm"
              {...getInputProps({
                placeholder: `${t('company.search_for_address')}...`,
                autoComplete: 'off',
              })}
            />
          </div>

          <div className="autocomplete-dropdown-container flex flex-col gap-1">
            {loading && (
              <div className="p-2">
                <CoreLoadingIcon />
              </div>
            )}
            {suggestions.map((suggestion) => (
              <div
                className={classnames('rounded-sm px-1 cursor-pointer', {
                  'bg-sky-blue text-white': suggestion.active,
                  'bg-white': !suggestion.active,
                })}
                {...getSuggestionItemProps(suggestion)}
                key={suggestion.placeId}
              >
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default PlacesAutocompleteComponent
