import { useTranslation } from 'react-i18next'
import { IFloatingElements } from './FloatingElements'

interface ICancelButton extends Pick<IFloatingElements, 'onCancel' | 'setIsInputActive'> {}

const CancelButton: React.FC<ICancelButton> = ({ setIsInputActive, onCancel }) => {
  const { t } = useTranslation()

  const onCancelHandler = () => {
    setIsInputActive?.(false)
    onCancel()
  }

  return (
    <button type="button" onClick={onCancelHandler} className="font-medium text-sm hover:brightness-80">
      <p>{t('generic.cancel')}</p>
    </button>
  )
}

export default CancelButton
