import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { CgSpinner } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from '../../../../../lib/util'
import PasswordValidationInfo from './PasswordValidationInfo'
import ErrorMessage from '../../../../../shared/components/atoms/ErrorMessage'
import { FormFieldType } from '../Types'
import Button from '../../../../../shared/components/atoms/buttons/Button'

type AuthFormFieldProps = {
  fields: FormFieldType[]
  onSubmit: (data: any) => void
  onError: (data: any) => void
  isSubmitting?: boolean
  btnText: string
}

export const emailValidator = z.string().email()
export const firstNameValidator = z.string().min(3)
export const lastNameValidator = z.string().min(3)
export const passwordValidator = z.string().min(7)
export const passwordCreationValidator = z
  .string()
  .min(7)
  .refine(
    (value) => {
      const lowerCaseRegex = /[a-z]/
      const upperCaseRegex = /[A-Z]/
      const numberRegex = /[0-9]/
      const symbolRegex = /[^a-zA-Z0-9]/

      if (!lowerCaseRegex.test(value)) {
        return false
      }

      if (!upperCaseRegex.test(value)) {
        return false
      }

      if (!numberRegex.test(value)) {
        return false
      }

      if (!symbolRegex.test(value)) {
        return false
      }

      return value.length >= 7
    },
    {
      message: 'generic.password_validation',
    }
  )
export const passwordConfirmationValidator = z.string().min(7)

const AuthForm = ({ fields, onSubmit, isSubmitting, onError, btnText }: AuthFormFieldProps) => {
  const { t } = useTranslation()
  let dynamicSchema: any = z.object({})

  fields.forEach((field) => {
    dynamicSchema = dynamicSchema.and(z.object({ [field.attribute]: field.validator }))
  })

  dynamicSchema = dynamicSchema.refine(
    (data: any) =>
      'password' in data && 'password_confirmation' in data ? data.password === data.password_confirmation : true, // if password_confirmation doesn't exist, we skip the validation
    {
      message: 'Passwords do not match',
      path: ['password_confirmation'],
    }
  )

  type ValidationSchemaType = z.infer<typeof dynamicSchema>
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ValidationSchemaType>({
    resolver: zodResolver(dynamicSchema),
    mode: 'onChange',
  })
  const hasPasswordConfirmation = fields.find((item) => item.attribute === 'password_confirmation')

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-6">
      {fields.map((field) => (
        <div key={field.attribute}>
          <div className="flex justify-between mb-2">
            <label htmlFor={field.attribute} className="font-semibold">
              {field.name}
            </label>
            {field.attribute === 'password' && !hasPasswordConfirmation && (
              <Link to="forgot-password" type="button" className="text-black-light-2 text-xs hover:text-sky-blue pt-1">
                {t('general.forgot_password', 'Forgot password?')}
              </Link>
            )}
          </div>
          <Controller
            control={control}
            defaultValue={field.value}
            name={field.attribute}
            render={({ field: controlField, formState }) => (
              <>
                <input
                  name={field.attribute}
                  className={cn(
                    'shadow-sm border border-gray-200 leading-6 rounded-md text-sm placeholder:text-black-light ' +
                      'placeholder:opacity-50 w-full disabled:bg-gray-100',
                    `${errors[field.attribute] ? ' border border-red-500' : ''}`
                  )}
                  type={field.type}
                  placeholder={field.placeholder ?? t('generic.type_your_response')}
                  onChange={(value) => controlField.onChange(value.target.value)}
                  defaultValue={field.value}
                />
                {formState.errors[controlField.name] && (
                  <div className="mt-3">
                    {field.attribute === 'password' && hasPasswordConfirmation ? (
                      <PasswordValidationInfo
                        password={controlField.value}
                        showErrors={field.attribute === 'password'}
                      />
                    ) : (
                      <ErrorMessage error={(errors[field.attribute]?.message as string) ?? ''} />
                    )}
                  </div>
                )}
              </>
            )}
          />
        </div>
      ))}
      <Button
        disabled={!isValid || isSubmitting}
        className="bg-sky-blue text-white py-3 font-semibold "
        onClick={handleSubmit(onSubmit, onError)}
      >
        {btnText}
        {isSubmitting && <CgSpinner className="animate-spin text-xl" />}
      </Button>
    </form>
  )
}
export default AuthForm
