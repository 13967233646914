import { Switch as HeadlessSwitch } from '@headlessui/react'
import { motion } from 'framer-motion'

export interface ISwitch {
  onChange: () => void
  checked?: boolean
  dataCy?: string
  disabled?: boolean
}

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
}

const Switch: React.FC<ISwitch> = ({ checked = false, disabled, onChange, dataCy }) => (
  <HeadlessSwitch
    disabled={disabled}
    checked={checked}
    onChange={onChange}
    data-cy={dataCy}
    className={`${
      checked ? 'bg-vibrant-blue justify-end' : 'bg-gray-300 justify-start'
    } relative flex items-center h-6 rounded-full w-11 px-1`}
  >
    <motion.div
      layout
      transition={spring}
      className="inline-block w-4 h-4 bg-white rounded-full"
      data-cy="switch-inner"
    />
  </HeadlessSwitch>
)

export default Switch
