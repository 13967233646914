import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputWithSaveCancelButtons from '../../../organisms/InputWithSaveCancelButtons/InputWithSaveCancelButtons'
import { TagCategoryType } from '../type'
import useHandleTagInputKeyDown from './useHandleTagInputKeyDown'
import usePostNewSupplierTag from './usePostNewSupplierTag'

interface ITagInput {
  category: TagCategoryType
  handleHideInput: () => void
}

const TagInput: React.FC<ITagInput> = ({ category, handleHideInput }) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const handleKeyDown = useHandleTagInputKeyDown(inputValue, setInputValue, category)
  const postNewTag = usePostNewSupplierTag(inputValue, setInputValue, category)

  return (
    <div className="flex justify-start">
      <InputWithSaveCancelButtons
        className="text-xs max-w-md"
        inputClassName="text-xs"
        placeholder={t('supplier_details.input_new_tag')}
        onKeyDown={handleKeyDown}
        title={inputValue}
        setTitle={setInputValue}
        onCancel={handleHideInput}
        onSave={postNewTag}
        charLimit={50}
        charMin={2}
        focusOnLoad
      />
    </div>
  )
}

export default TagInput
