import { HiCodeBracket, HiOutlineLink } from 'react-icons/hi2'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import VerticalMenuTabsItem, {
  VerticalTabItemType,
} from '../../../../shared/components/layout/VerticalMenuTabs/VerticalMenuTabsItem'
import VerticalMenuTabs, {
  TabsSectionType,
} from '../../../../shared/components/layout/VerticalMenuTabs/VerticalMenuTabs'
import useGetCurrentUser from '../../../../core/hooks/useGetCurrentUser'

// accepts a useState setter
interface IExternalDataEntitySettingsTabbar {
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
}

const ExternalDataEntitySettingsTabbar: FC<IExternalDataEntitySettingsTabbar> = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation()

  const currentUserRole = useGetCurrentUser()?.data?.role
  const adminSuperAdmin = ['admin', 'super_admin'].includes(currentUserRole || '')

  const tabs: TabsSectionType[] = [
    {
      name: t('account.data_settings', 'Data settings'),
      basePath: 'external_data_settings',
      visible: adminSuperAdmin,
      tabs: [
        {
          title: t('external_data.relationships', 'Relationships'),
          path: 'relationships',
          icon: <HiOutlineLink />,
          visible: adminSuperAdmin,
          active: activeTab === 'relationships',
        },
        {
          title: t('external_data.embeddings', 'Embeddings'),
          path: 'embeddings',
          icon: <HiCodeBracket />,
          visible: adminSuperAdmin,
          active: activeTab === 'embeddings',
        },
      ],
    },
  ]

  const handleTabClick = (tab: VerticalTabItemType) => {
    if (!tab.path) {
      return
    }
    setActiveTab(tab.path)
  }

  return (
    <VerticalMenuTabs sections={tabs}>
      {(tab: VerticalTabItemType) => (
        <VerticalMenuTabsItem tab={tab} key={`tab-index-${tab.title}`} onClick={handleTabClick} />
      )}
    </VerticalMenuTabs>
  )
}

export default ExternalDataEntitySettingsTabbar
