import Button, { IButton } from '../../../../shared/components/atoms/buttons/Button'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'

export type WorkflowActionButtonProps = IButton & {
  isLoading?: boolean
}

export const WorkflowActionButton = ({ children, isLoading, ...props }: WorkflowActionButtonProps) => (
  <Button
    {...{ variant: 'solid', className: 'bg-sky-blue text-white', ...props }}
    disabled={props.disabled || isLoading}
  >
    {isLoading && <CoreLoadingIcon />}
    {children}
  </Button>
)

export default WorkflowActionButton
