import { FC } from 'react'
import { FormQuestionContentType, FormQuestionValue } from '../../../types/Forms'
import { useIntakeFlow } from '../../Intake/IntakeOverview/providers/IntakeFlowProvider'
import { useFormField } from '../providers/FormFieldProvider'

interface IFormFieldDisplayDefault {}

const FormFieldDisplayDefault: FC<IFormFieldDisplayDefault> = () => {
  const { intake } = useIntakeFlow()
  const { field } = useFormField()

  const inputKey = field?.input?.key

  const userDefault = (inputKey && intake?.creator?.defaults?.[inputKey]) ?? '-'

  return <div>{userDefault.toString()}</div>
}

export default FormFieldDisplayDefault
