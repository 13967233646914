import { useMutation, useQueryClient } from 'react-query'
import useGetSupplierBranchId from '../../../../../modules/SupplierDetails/hooks/useGetSupplierBranchId'
import { apiPostSupplierBranchTag } from '../../../../../modules/Suppliers/services/SuppliersService'
import toastr from '../../../organisms/toastr/toastr'
import { TagCategoryType } from '../type'

type SupplierTagType = {
  category: string
  name: string
}

export default (category: TagCategoryType, onSuccess?: (data: any) => void) => {
  const supplierBranchId = useGetSupplierBranchId()

  const cache = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      tag,
      currentSupplierId,
      currentCategory,
    }: {
      tag: string
      currentSupplierId: number
      currentCategory: TagCategoryType
    }) => apiPostSupplierBranchTag(currentSupplierId, [tag], currentCategory),
    {
      onSuccess: (data, inputData) => {
        if (onSuccess) {
          onSuccess(data)
        }

        cache.setQueryData(['tags', inputData.currentCategory, inputData.currentSupplierId], () => data)
      },
    }
  )

  const currentTags: SupplierTagType[] | undefined = cache.getQueryData(['tags', category, supplierBranchId])

  return {
    mutate: (tag: string) => {
      if (currentTags?.find((existingTag) => tag === existingTag.name)) {
        toastr.error('supplier_details.tag_already_added')
      } else if (supplierBranchId) {
        mutate({ tag, currentSupplierId: supplierBranchId, currentCategory: category })
      }
    },
    isLoading,
  }
}
