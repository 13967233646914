import { useLocation } from 'react-router'
import { useLocalStorage } from '../../../../providers/LocalStorageProvider'
import CoreLoadingIcon from '../../../CoreLoadingIcon'
import CoreSidebarProfile from './CoreSidebarProfile'

interface ICoreSidebarProfileButton {
  minified: boolean
}

const CoreSidebarProfileButton: React.FC<ICoreSidebarProfileButton> = ({ minified }) => {
  const [getLocalStorage] = useLocalStorage()
  const { pathname } = useLocation()
  const splitLocation = pathname.split('/')
  const data = getLocalStorage('lhotseAuth')

  return !data ? (
    <CoreLoadingIcon />
  ) : (
    <CoreSidebarProfile
      activeUrl={`/${splitLocation[1]}`}
      href="/settings/profile"
      key="profile"
      minified={minified}
      data={data}
    />
  )
}

export default CoreSidebarProfileButton
