import { useWorkflow } from '../providers/WorkflowProvider'
import useIsLatestWorkflowVersion from './useIsLatestWorkflowVersion'

export const useIsLatestPublishedWorkflowVersion = (version: number) => {
  const { workflow } = useWorkflow()
  const isLatestVersion = useIsLatestWorkflowVersion(version)

  return (
    (isLatestVersion && workflow.versions[0].published_at !== null) ||
    (workflow.versions[0].published_at === null &&
      workflow.versions.length > 1 &&
      workflow.versions[1].version === version)
  )
}

export default useIsLatestPublishedWorkflowVersion
