import { getTargetValueAndQuestions } from '../helpers/getTargetValueAndQuestions'
import { getMissingTargetValueError } from '../helpers/getMissingTargetValueError'

export const min = function (requestedValue) {
  return this.test('min', 'has to be bigger than', (value, schema) => {
    if (value === undefined) return true

    const { targetValue, targetQuestion } = getTargetValueAndQuestions(requestedValue, schema)

    if (targetValue === undefined && value !== undefined) {
      return getMissingTargetValueError(targetQuestion, schema)
    }

    return value < targetValue
      ? true
      : schema.createError({
          message: `should be higher than ${targetValue}`,
          path: schema.path,
        })
  })
}

export default min
