import { MdClose } from 'react-icons/md'
import { MediaType } from '../../../../types/shared/MediaTypes'
import { RemoveUploadedFileFunctionType } from './SharedFileUploadPillTypes'

interface ISharedFileUploadPillDeleteButton {
  removeFile: RemoveUploadedFileFunctionType | undefined
  file: MediaType
}

const SharedFileUploadPillDeleteButton: React.FC<ISharedFileUploadPillDeleteButton> = ({ removeFile, file }) =>
  removeFile ? (
    <button
      onClick={(e) => {
        removeFile(e, file)
      }}
      type="button"
      data-cy="file-upload-delete-button"
      className="p-0.5 text-gray-400 hover:text-gray-500 min-w-[10px] cursor-pointer flex z-50 group"
    >
      <MdClose size={10} className="group-hover:stroke-1" />
    </button>
  ) : null

export default SharedFileUploadPillDeleteButton
