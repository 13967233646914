import { useTranslation } from 'react-i18next'
import LhotseHomeStat from './LhotseHomeStat'
import useUrlParams from '../../../hooks/useUrlParams'
import useGetCurrentUser from '../../../../../core/hooks/useGetCurrentUser'
import useSearchSubmissions from '../../../../Search/Hooks/Submissions/useSearchSubmissions'

const LhotseHomeStats = () => {
  const { t } = useTranslation()

  const { getParam: getFilter, setParam: setFilter, clearFilters } = useUrlParams()

  const { data, isLoading } = useGetCurrentUser()

  const currentActionsFilterQuery = `workflow.active_blocks.approvers.approver_id:=${data?.id}`

  const { data: activeRequests } = useSearchSubmissions({ query: '', filter_by: 'status:=[ongoing, draft]' })
  const { data: pastRequests } = useSearchSubmissions({ query: '', filter_by: 'status:=completed' })
  const { data: approval } = useSearchSubmissions({
    query: '',
    filter_by: isLoading ? undefined : currentActionsFilterQuery,
  })

  const isFilteringByActiveRequests = getFilter('filter_by') === 'status:=[ongoing, draft]'
  const isFilteringByPastRequests = getFilter('filter_by') === 'status:=completed'
  const isFilteringByActionRequired = getFilter('filter_by') === currentActionsFilterQuery

  if (isLoading) return null

  return (
    <div className="grid grid-cols-3 gap-4">
      <LhotseHomeStat
        name={t('home.active_requests', 'Active requests')}
        value={activeRequests?.total || 0}
        filterAction={
          isFilteringByActiveRequests
            ? clearFilters
            : () =>
                setFilter({
                  query: '',
                  filter_by: 'status:=[ongoing, draft]',
                  page: undefined,
                })
        }
        filterActive={isFilteringByActiveRequests}
        filterString={
          isFilteringByActiveRequests ? t('generic.clear_filter', 'Clear filter') : t('generic.filter', 'Filter')
        }
      />
      <LhotseHomeStat
        name={t('home.past_requests', 'Past requests')}
        value={pastRequests?.total || 0}
        filterAction={
          isFilteringByPastRequests
            ? clearFilters
            : () =>
                setFilter({
                  query: '',
                  filter_by: 'status:=completed',
                  page: undefined,
                })
        }
        filterActive={isFilteringByPastRequests}
        filterString={
          isFilteringByPastRequests ? t('generic.clear_filter', 'Clear filter') : t('generic.filter', 'Filter')
        }
      />

      <LhotseHomeStat
        key="required-4"
        name={t('generic.action-required', 'Action required')}
        value={approval?.data.found || 0}
        filterAction={
          isFilteringByActionRequired
            ? clearFilters
            : () =>
                setFilter({
                  filter_by:
                    getFilter('filter_by') === currentActionsFilterQuery ? undefined : currentActionsFilterQuery,
                  query: '',
                })
        }
        filterActive={isFilteringByActionRequired}
        filterString={
          isFilteringByActionRequired ? t('generic.clear_filter', 'Clear filter') : t('generic.filter', 'Filter')
        }
      />
    </div>
  )
}

export default LhotseHomeStats
