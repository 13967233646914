import { FunnelIcon } from '@heroicons/react/24/outline'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cn from '../../../../../lib/util'

interface ILhotseHomeStat {
  name: string
  value: string | number
  filterAction?: () => void
  filterActive?: boolean
  filterString: string
}

const LhotseHomeStat: FC<ILhotseHomeStat> = ({ name, value, filterAction, filterActive, filterString }) => {
  const { t } = useTranslation()

  return (
    <div>
      <dl>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow-sm sm:p-6 space-y-4">
          <dt className="truncate text-sm font-medium text-gray-400">{name}</dt>
          <dd className="mt-1 flex justify-between items-end">
            <div className="text-2xl font-semibold tracking-tight text-gray-600">{value}</div>
            {filterAction && (
              <div
                tabIndex={-1}
                role="button"
                className={cn(
                  'text-sm font-medium float-right flex gap-1 px-2.5 py-0.5 text-sky-blue rounded-full',
                  'hover:bg-gray-100 hover:text-sky-blue transition-colors',
                  filterActive && 'bg-sky-blue text-white'
                )}
                onClick={filterAction}
              >
                <FunnelIcon className="w-4" />
                {filterString}
              </div>
            )}
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default LhotseHomeStat
