import { Form, Formik } from 'formik'
import { useMutation, useQuery } from 'react-query'
import * as Yup from 'yup'
import { userRoles } from '../../../../../config/ability'
import FormikSelect from '../../../../../shared/components/organisms/formik/FormikSelect'
import { toastr } from '../../../../../shared/components/organisms/toastr'
import dropdownUserTypes from '../../../../../types/shared/DropdownUserTypes'
import validation from '../../../../../shared/constants/validationErrors'
import { UserType } from '../../../../../types/shared/AssignmentTypes'
import settingsQueryKeys from '../../Profile/constants/settingsQueryKeys'
import { apiGetUser } from '../../Profile/services/SettingsService'
import { apiUpdateUserRole } from '../../../services/CompanyService'

interface IUserTypeSelect {
  userId: number
  userRole: string | undefined
  currentUser: UserType | undefined
}

const UserTypeSelect: React.FC<IUserTypeSelect> = ({ userId, userRole, currentUser }) => {
  const initialValues = { role: userRole || '' }
  const { refetch } = useQuery([settingsQueryKeys.USER_PROFILE], apiGetUser)

  const visibleOptions = (): { value: string; label: string }[] => {
    if (currentUser?.internal_user && userId === currentUser.id) {
      return dropdownUserTypes.filter((role) => role.value === userRoles.SUPER_ADMIN)
    }

    return dropdownUserTypes.filter((role) => role.value !== userRoles.SUPER_ADMIN)
  }

  const { mutate: updateUserRole } = useMutation((body: { role: string }) => apiUpdateUserRole(body, userId), {
    onSuccess: () => {
      refetch()
      toastr.success('success.user_role_updated', 'successRoleUpdate')
      // eslint-disable-next-line no-restricted-globals
      // location.reload()
    },
  })

  const validationSchema = Yup.object().shape({
    token: Yup.string(),
    email: Yup.string().email(),
    password: Yup.string()
      .required(validation.required)
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        validation.passwordWeak
      ),
    first_name: Yup.string().required(validation.required),
    last_name: Yup.string().required(validation.required),
  })

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {({ values, handleChange }) => (
        <Form className="flex w-full">
          <FormikSelect
            noPlaceholder
            name="role"
            handleChange={(v) => {
              handleChange(v)
              updateUserRole({ role: v.target.value })
            }}
            values={values}
            className="w-full"
            options={visibleOptions()}
          />
        </Form>
      )}
    </Formik>
  )
}

export default UserTypeSelect
