import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import useAbsenceService from '../AbsenceModal/hooks/useAbsenceService'
import CoreLoading from '../../../../../../core/components/CoreLoading'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../../../core/components/Table/Table'
import { dateFormat } from '../../../../../../shared/constants/dateFormat'
import Menu from '../../../../../../shared/components/organisms/menus/Menu'
import StatusIndicator from '../../../../../../shared/components/atoms/StatusIndicator'

const AbsenceTable = () => {
  const {
    getAbsences: { data: absences, isLoading: isLoadingAbsences },
  } = useAbsenceService()
  const {
    deleteAbsence: { mutateAsync: deleteAbsence },
  } = useAbsenceService()

  const { t } = useTranslation()

  if (isLoadingAbsences) <CoreLoading />

  const getStatus = (dates: string[]) => {
    if (dayjs().isAfter(dayjs(dates[1]))) {
      return (
        <StatusIndicator
          className="bg-gray-400"
          status={t('generic.expire', 'Expired')}
          textClassName="text-gray-400"
        />
      )
    }
    if (dayjs().isAfter(dayjs(dates[0]))) {
      return (
        <StatusIndicator
          className="bg-green-600"
          status={t('generic.active', 'Active')}
          textClassName="text-green-600"
        />
      )
    }
    return (
      <StatusIndicator
        className="bg-green-600"
        status={t('generic.upcoming', 'Upcoming')}
        textClassName="text-green-600"
      />
    )
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('generic.dates', 'Dates')}</TableHead>
          <TableHead>{t('generic.substitute', 'Substitute')}</TableHead>
          <TableHead>{t('generic.comments', 'Comments')}</TableHead>
          <TableHead>{t('generic.status', 'Status')}</TableHead>
          <TableHead />
        </TableRow>
      </TableHeader>
      <TableBody>
        {absences?.map((absence) => (
          <TableRow key={absence.uuid}>
            <TableCell>
              {dayjs(absence.from).format(dateFormat)}
              {' - '}
              {dayjs(absence.to).format(dateFormat)}
            </TableCell>
            <TableCell>{absence.substitute_user.first_name}</TableCell>
            <TableCell>
              <div dangerouslySetInnerHTML={{ __html: absence.reason || 'no reason' }} />
            </TableCell>
            <TableCell>{getStatus([absence.from, absence.to])}</TableCell>
            <TableCell>
              <Menu
                menuItems={[
                  {
                    name: t('generic.delete', 'Delete'),
                    onClick: () => deleteAbsence(absence.uuid),
                  },
                ]}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
export default AbsenceTable
