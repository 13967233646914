import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntakeFlow } from '../providers/IntakeFlowProvider'
import IntakeSidepanelSummary from './IntakeSidepanel/IntakeSidepanelSummary'
import { StickySidePanel } from '../../../../shared/components/layout/StickySidePanel'
import useTabs from '../../../../shared/hooks/useTabs'
import useIntakeSidebar from '../hooks/useIntakeSidebar'
import IntakeSidepanelChangelog from './IntakeSidepanel/IntakeSidepanelChangeLog'
import IntakeSidepanelComments from './IntakeSidepanel/IntakeSidepanelComments'
import IntakeSidePanelActiveStep from './IntakeSidepanel/IntakeSidePanelActiveStep/IntakeSidePanelActiveStep'
import apiClient from '../../../../core/utils/apiClient'
import Menu, { MenuItemType } from '../../../../shared/components/organisms/menus/Menu'

export const IntakeFlowOverviewPageSidePanel = () => {
  const { activeWorkflow, intake } = useIntakeFlow()
  const { t } = useTranslation()

  const tabsData = useMemo(
    () => [
      {
        id: 'summary',
        title: t('intake.sidepanel_summary', 'Summary'),
        current: true,
        TabComponent: IntakeSidepanelSummary,
      },
      {
        title: t('intake.sidepanel_comments', 'Comments'),
        current: false,
        TabComponent: IntakeSidepanelComments,
      },
      {
        title: t('intake.sidepanel_changelog', 'Change log'),
        current: false,
        TabComponent: IntakeSidepanelChangelog,
      },
    ],
    []
  )

  async function exportSummary() {
    if (!intake) return
    const { data } = await apiClient.get(`/intake-flows/${intake.uuid}/export-summary`, { responseType: 'blob' })
    const fileUlr = URL.createObjectURL(data)
    window.open(fileUlr, '_blank')
  }

  const { navItems, changeTab, current: selectedTab, updateTabs } = useTabs(tabsData)

  useEffect(() => {
    updateTabs(tabsData)
  }, [tabsData])

  const TabsBody = selectedTab?.TabComponent

  const { toggleMinified, minified } = useIntakeSidebar()

  const OpenPopoverModal = (): MenuItemType[] => [
    { name: t('intake.actions.download_summary', 'Download summary'), onClick: () => exportSummary() },
    {
      name: t('intake.actions.close_side_panel', 'Close panel'),
      onClick: () => (toggleMinified ? toggleMinified() : undefined),
    },
  ]

  return (
    <StickySidePanel
      open={!minified}
      tabs={navItems}
      onTabChange={changeTab}
      title={t(activeWorkflow?.status)}
      header={
        <div className="flex flex-row">
          <Menu menuItems={OpenPopoverModal()} />
        </div>
      }
      footer={selectedTab?.id === 'summary' && <IntakeSidePanelActiveStep />}
    >
      <div className="flex-col flex justify-between h-full flex-auto">
        <div className=" flex  h-full">{TabsBody && <TabsBody />}</div>
      </div>
    </StickySidePanel>
  )
}

export default IntakeFlowOverviewPageSidePanel
