import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import { INodeChildren } from '../../../types/shared/GeneralTypes'

type activeSupplierModalTabType = 'general' | 'tags' | 'offers'

interface ISupplierContext {
  supplierBranchId: [number | undefined, Dispatch<SetStateAction<number | undefined>>]
  activeSupplierModalTab: [activeSupplierModalTabType, Dispatch<SetStateAction<activeSupplierModalTabType>>]
  searchQuery: [string, Dispatch<SetStateAction<string>>]
  scrollState: [number | undefined, Dispatch<SetStateAction<number | undefined>>]
  origin: [string, Dispatch<SetStateAction<string>>]
}

const suppliersContext = createContext<ISupplierContext>({} as ISupplierContext)

const SuppliersProvider: React.FC<INodeChildren> = ({ children }) => {
  const supplierBranchId = useState<number>()
  const activeSupplierModalTab = useState<activeSupplierModalTabType>('general')
  const searchQuery = useState<string>('')
  const origin = useState<string>('')
  const scrollState = useState<number>()

  const values: ISupplierContext = useMemo(
    () => ({
      supplierBranchId,
      activeSupplierModalTab,
      searchQuery,
      scrollState,
      origin,
    }),
    [supplierBranchId, activeSupplierModalTab, searchQuery, scrollState, origin]
  )

  return <suppliersContext.Provider value={values}>{children}</suppliersContext.Provider>
}

export default SuppliersProvider

export const useSuppliersProvider = () => useContext(suppliersContext)
