import classnames from 'classnames'

type QuestionSectionProps = {
  title: string
} & React.HTMLAttributes<HTMLDivElement>

export const QuestionSection: React.FC<React.PropsWithChildren<QuestionSectionProps>> = ({
  title,
  children,
  className,
  ...divProps
}) => (
  <div
    className={classnames('bg-white px-6 py-4 rounded-md flex flex-col gap-y-6 w-full text-primary-gray', className)}
    {...divProps}
  >
    <p className="font-medium">{title}</p>
    {children}
  </div>
)

export default QuestionSection
