import { LanguageType } from '../../../../../types/shared/CoreTypes'
import { apiGetData, apiPutData } from '../../../../../core/utils/api/generic'
import { UserType } from '../../../../../types/shared/AssignmentTypes'
import CompanyType from '../../../../../types/Company/CompanyType'
import { UserProfileType } from '../../../../../types/shared'

export const apiGetUser = () => apiGetData<UserType>('/user')
export const apiGetUserById = (userId: number) => apiGetData<UserProfileType>(`/user/${userId}`)
export const apiUpdateUser = ({ userId, data }: { userId: number; data: any }) => apiPutData(`/users/${userId}`, data)
export const apiUpdateUserLanguage = (body: { language: LanguageType }): Promise<UserType> =>
  apiPutData('user/update-language', body)
export const getCompanyInfo = () => apiGetData<CompanyType>('/company')
