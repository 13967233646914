import { Position } from 'reactflow'
import { useCallback } from 'react'
import WorkflowChartNodeHandle from './components/WorkflowChartNodeHandle'
import { WorkflowChartBlockNodeType, WorkflowChartNodeHelperProps } from '../../../../types/Workflow/WorkflowChart'
import WorkflowChartAddGroupButton from './components/WorkflowChartAddGroupButton'
import { ADD_BUTTON_SIZE } from '../../../Intake/IntakeOverview/helpers'
import { useWorkflowChart } from '../../providers/WorkflowChartProvider'

export const WorkflowChartNodeHelper = ({ id, data }: WorkflowChartNodeHelperProps) => {
  const { addToStep, addStep } = useWorkflowChart()

  const handleClick = useCallback(() => {
    const targetUuid = data?.target && ((data.target as WorkflowChartBlockNodeType).stepUuid || data.target.uuid)

    if (targetUuid) {
      addToStep(targetUuid)
      return
    }

    addStep(data.position)
  }, [data])

  return (
    <div className={`w-[${ADD_BUTTON_SIZE}px] h-[${ADD_BUTTON_SIZE}px]`}>
      <WorkflowChartAddGroupButton onClick={handleClick} />
      <WorkflowChartNodeHandle id={id} type="target" alias="helper" position={Position.Top} />
      <WorkflowChartNodeHandle id={id} type="target" position={Position.Left} />
      <WorkflowChartNodeHandle id={id} type="source" position={Position.Right} />
    </div>
  )
}

export default WorkflowChartNodeHelper
