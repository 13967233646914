import { useTranslation } from 'react-i18next'
import TableCell from '../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../shared/components/atoms/SharedTable/TableRow'
import Menu, { MenuItemType } from '../../../../shared/components/organisms/menus/Menu'
import { ExternalDataColumn } from '../../../../types/ExternalDataManager/ExternalDataManagerTypes'
import useExternalDataRelationshipModal from '../ExternalDataRelationshipModal/hooks/useExternalDataRelationshipModal'
import useExternalDataRelationshipMutations from '../../hooks/useExternalDataRelationshipMutations'
import useCurrentExternalDataSourceUuid from '../../hooks/useCurrentExternalDataSourceUuid'

type ExternalDataTableRowProps = {
  relationship: ExternalDataColumn
}

const ExternalDataRelationshipsTableRow: React.FC<ExternalDataTableRowProps> = ({ relationship }) => {
  const { t } = useTranslation()

  const openEditModal = useExternalDataRelationshipModal(relationship)
  const { remove } = useExternalDataRelationshipMutations()
  const sourceUuid = useCurrentExternalDataSourceUuid()

  const relationshipActions: MenuItemType[] = [
    { name: t('general.edit'), onClick: openEditModal },
    {
      name: t('general.delete'),
      onClick: () => remove({ source_uuid: sourceUuid, source_column: relationship.column_name }),
    },
  ]

  return (
    <TableRow onClick={openEditModal}>
      <TableCell>{relationship.column_name}</TableCell>
      <TableCell>{relationship.foreign_name}</TableCell>
      <TableCell>{relationship.foreign_column_name}</TableCell>
      <TableCell>
        <Menu menuItems={relationshipActions} />
      </TableCell>
    </TableRow>
  )
}

export default ExternalDataRelationshipsTableRow
