import { useEffect, useLayoutEffect } from 'react'
import IntakeFormNavHeader from './components/IntakeFormNavigation/IntakeFormNavHeader'
import useIntakeFormSections from './hooks/useIntakeFormSections'
import IntakeFormSections from './components/IntakeFormSections/IntakeFormSections'
import SectionNavigationButtons from './components/IntakeFormNavigation/SectionNavigationButtons'
import useIntakeFormStore from './stores/useIntakeFormStore'
import { useIntakeFormMutations } from './hooks/useIntakeFormMutations'
import { IntakeSectionType } from '../../../types/Intake'
import validate from './validation/formValidation'
import useGetCurrentIntake from '../IntakeOverview/hooks/useGetCurrentIntake'
import { ProductsWithErrorsType } from '../../../types/Intake/form/IntakeSubmission'

const IntakeFormResponsePage = () => {
  // Initialise the store with the intake data
  const { data: intakeData } = useGetCurrentIntake()

  const setIntake = useIntakeFormStore((state) => state.setIntake)
  const resetStore = useIntakeFormStore((state) => state.reset)

  // Reset the store when the page loads, useLayoutEffect prevents "flashing" on reload
  useLayoutEffect(() => {
    resetStore()
  }, [])

  // Initialise the store when the intake uuid data changes, this means we only load the data into the store once
  useEffect(() => {
    if (!intakeData?.uuid) return

    resetStore()
    setIntake(intakeData)
  }, [intakeData?.uuid])

  const intakeSections = useIntakeFormStore((state) => state.intake?.sections)
  const intakeSettings = useIntakeFormStore((state) => state.intake?.settings)
  const offerings = useIntakeFormStore((state) => state.offerings)
  const formDirty = useIntakeFormStore((state) => state.formDirty)
  const setFormDirty = useIntakeFormStore((state) => state.setFormDirty)
  const setErrors = useIntakeFormStore((state) => state.setErrors)
  const setOffersErrors = useIntakeFormStore((state) => state.setOffersErrors)

  const intakeFormSections = useIntakeFormSections()
  const {
    update: { mutate: updateIntake },
  } = useIntakeFormMutations()

  // every 5 seconds, if the user has made changes, update the intake
  useEffect(() => {
    const interval = setInterval(() => {
      if (formDirty) {
        updateIntake()
        setFormDirty(false)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [formDirty])

  // check for errors
  useEffect(() => {
    const intakeFields = intakeSections?.flatMap((section: IntakeSectionType) => section.fields)
    const offeringsFields = offerings?.flatMap((offering) => offering.fields)
    const allFields = intakeFields?.concat(offeringsFields || [])

    const visibleFields = allFields?.filter((field) => field.visible)

    const errors = validate(visibleFields)
    setErrors(errors)

    // If form settings has offers set to required, validate that there is at least one offer
    if (intakeSettings?.offersEnabled && intakeSettings?.offerRequired) {
      const productsWithErrors: ProductsWithErrorsType =
        offerings?.reduce((acc, offering) => {
          const hasOffers = offering.offers.length > 0
          const hasOfferQuantities = offering.offers.every((offer) => offer.quantity > 0)
          const hasOfferPrices = offering.offers.every((offer) => offer.total_net_price > 0)

          // If all checks are true, return the accumulator as is
          if (hasOffers && hasOfferQuantities && hasOfferPrices) return acc

          // Otherwise, add the errors for this offering to the accumulator
          return {
            ...acc,
            [offering.uuid]: {
              hasOffers,
              hasOfferQuantities,
              hasOfferPrices,
            },
          }
        }, {}) || {} // Initialize the accumulator as an empty object

      if (productsWithErrors) {
        setOffersErrors(productsWithErrors)
      }
    }
  }, [intakeSections, offerings])

  return (
    <div className="flex flex-col w-full">
      <div>
        <IntakeFormNavHeader formSections={intakeFormSections} />
      </div>
      <div className="flex flex-row w-full lg:w-2/3 lg:mx-auto mb-24 gap-4 mt-10">
        <div className="min-w-full">
          <IntakeFormSections />
        </div>
      </div>
      <SectionNavigationButtons />
    </div>
  )
}

export default IntakeFormResponsePage
