import useEvaluatedOfferings from './hooks/useEvaluatedOfferings'
import { Offering } from '../../../../types/Offering'

const useApplyFormOfferingsLogic = (offeringsToEvaluate: Offering[] | undefined | null) => {
  // Evaluate the offerings using logic
  const evaluatedOfferings = useEvaluatedOfferings(offeringsToEvaluate)

  // Mark disabled fields in the section as visible: false, else make visible: true
  offeringsToEvaluate?.forEach((offering) => {
    const evaluatedSection = evaluatedOfferings?.find(
      (enabledQuestionsPerSection) => enabledQuestionsPerSection.offeringUuid === offering.uuid
    )

    offering?.fields.forEach((field) => {
      const localField = field
      localField.visible = !(
        localField.external_reference &&
        !evaluatedSection?.logicAllowedQuestions.includes(localField.external_reference)
      )

      return localField
    })
  })

  return offeringsToEvaluate
}

export default useApplyFormOfferingsLogic
