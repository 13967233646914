import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import PageTopbar from '../../shared/components/layout/PageTopBar'
import ExternalDataListPage from '../ExternalDataManager/pages/ExternalDataListPage'
import FormsListTab from './Views/Forms/FormsListTab'
import CompanyGeneralTab from './Views/General/CompanyGeneralTab'
import InputsTab from './Views/Inputs/InputsTab'
import UserProfileTab from './Views/Profile/UserProfileTab'
import UsersTab from './Views/Users/UsersTab'
import WorkflowListTab from './Views/Workflows/WorkflowListTab'
import CompanyTabbar from './components/CompanyTabbar'
import Integrations from './Views/Integrations/Integrations'
import SchemaTab from './Views/Schemas/SchemaTab'

const tabs: { [key: string]: JSX.Element } = {
  profile: <UserProfileTab />,
  general: <CompanyGeneralTab />,
  users: <UsersTab />,
  workflows: <WorkflowListTab />,
  forms: <FormsListTab />,
  inputs: <InputsTab />,
  'data-manager': <ExternalDataListPage />,
  schemas: <SchemaTab />,
  integrations: <Integrations />,
}

const CompanySettingsTab = ({ path }: { path: string }) => tabs[path]

const CompanyPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const urlParam = useParams<{ tab: string }>()
  const activeTab = (): string => {
    const exists = tabs[urlParam.tab] ?? undefined

    if (!exists) {
      history.push('/settings/profile')
    }
    return urlParam.tab
  }

  return (
    <div className="w-full h-screen flex flex-col">
      <PageTopbar title={t('account.company_settings')} />
      <div className="flex flex-row w-full h-full pl-32 overflow-hidden">
        <div className="mt-12">
          <CompanyTabbar />
        </div>
        <div className="w-full flex justify-center pb-10 overflow-y-auto">
          <div className="flex flex-col w-full 3xl:w-2/3 2xl:w-5/6 px-14 space-y-10 mb-20 mt-12">
            <CompanySettingsTab path={activeTab()} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyPage
