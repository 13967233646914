import { ReactNode } from 'react'
import classnames from 'classnames'
import { INodeChildren } from '../../../types/shared/GeneralTypes'

interface ICorePageContainerLayout extends INodeChildren {
  className?: string
  secondaryTopbar?: ReactNode
}
const CorePageContainerLayout: React.FC<ICorePageContainerLayout> = ({ children, className }) => (
  <div className="w-full h-auto">
    <div data-cy="pageContainer" className={classnames('w-full h-full p-8', className)} id="scrollableDiv">
      {children}
    </div>
  </div>
)

export default CorePageContainerLayout
