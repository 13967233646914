import { useMutation, useQueryClient } from 'react-query'
import { useCallback } from 'react'
import { deleteWorkflow, postWorkflow, publishWorkflow, renameWorkflow } from '../../services'
import { Workflow } from '../../../../types/Workflow/Workflow'

export const useWorkflowMutations = (disableAutoInvalidation?: boolean) => {
  const queryClient = useQueryClient()

  const invalidate = useCallback(
    (data?: any, errors?: any, variables?: unknown) => {
      if (disableAutoInvalidation) return

      queryClient.invalidateQueries(['workflows'])

      const { uuid, version } = (variables || {}) as { uuid: string; version: number }

      const updatedWorkflow = data?.data?.uuid ? (data.data as Workflow) : null

      if (updatedWorkflow) {
        queryClient.setQueryData(
          ['workflow', uuid, version].filter((key) => !!key),
          updatedWorkflow
        )
        queryClient.setQueryData(['workflow', uuid, 'draft'], updatedWorkflow)
      } else {
        queryClient.invalidateQueries(['workflow', uuid, version].filter((key) => !!key))
        if (uuid) queryClient.invalidateQueries(['workflow', uuid, 'draft'])
      }
    },
    [disableAutoInvalidation, queryClient]
  )

  const create = useMutation(
    ['createWorkflow'],
    (data: Partial<Workflow> & Pick<Workflow, 'name'>) => postWorkflow(data),
    {
      onSettled: invalidate,
    }
  )

  const remove = useMutation(
    ['deleteWorkflow'],
    ({ uuid, version }: Partial<Workflow> & Pick<Workflow, 'uuid' | 'version'>) => deleteWorkflow(uuid, version),
    {
      onSettled: invalidate,
    }
  )

  const rename = useMutation(
    ['updateWorkflow'],
    (data: Partial<Workflow> & Pick<Workflow, 'uuid' | 'version'>) => renameWorkflow(data.uuid, data.version, data),
    {
      onSettled: invalidate,
    }
  )

  const publish = useMutation(
    ['publishWorkflow'],
    ({ uuid, version }: Partial<Workflow> & Pick<Workflow, 'uuid' | 'version'>) => publishWorkflow(uuid, version),
    {
      onSettled: invalidate,
    }
  )

  return {
    create: create.mutateAsync,
    remove: remove.mutateAsync,
    rename: rename.mutateAsync,
    publish: publish.mutateAsync,
    isMutating: publish.isLoading || create.isLoading || remove.isLoading || rename.isLoading,
    invalidate,
  }
}

export default useWorkflowMutations
