import { useTranslation } from 'react-i18next'
import React from 'react'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../../core/components/Table/Table'
import useAddAddressModal from './useAddAddressModal'
import BaseSettingsSection from '../../../components/BaseSettingsSection'
import useGetCompanyAddresses from './hooks/useGetCompanyAddresses'
import Tooltip from '../../../../../shared/components/organisms/tooltip/Tooltip'
import Badge from '../../../../../shared/components/atoms/tags/Badge'
import AddressListRowActions from './AddressList/AddressListRowActions'

const CompanyAddresses = () => {
  const { t } = useTranslation()
  const triggerAddAddressModal = useAddAddressModal()
  const { data: addresses } = useGetCompanyAddresses()
  return (
    <div className="flex w-full flex-col ">
      <BaseSettingsSection
        title={t('generic.address_plural')}
        description={t('addresses.description', 'All public addresses that can be used across Lhotse.')}
        action={
          <div className="flex flex-row gap-2">
            <Button className="text-sm bg-sky-blue text-white" variant="solid" onClick={triggerAddAddressModal}>
              {t('company.add_address')}
            </Button>
          </div>
        }
      >
        <Table className="bg-white shadow-rock-gray-light ">
          <TableHeader>
            <TableRow>
              <TableHead className="w-48 font-bold first-letter:uppercase">{t('generic.name', 'Name')}</TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('account.company_street', 'Street')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('account.company_place', 'Place')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('account.company_country', 'Country')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('generic.company_address_visibility', 'Visibility')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase text-right">
                {t('generic.main', 'Main')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase text-right">
                {t('generic.actions', 'Actions')}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!!addresses?.length &&
              addresses?.map((address) => (
                <TableRow
                  key={`schema-${address.id}`}
                  className="h-16 hover:bg-gray-200/25 text-black-light hover:cursor-pointer"
                >
                  <TableCell className="first-letter:uppercase">{address?.name}</TableCell>
                  <TableCell>
                    <Tooltip tooltip={address?.street} showCondition={address?.street?.length > 25}>
                      <div className="truncate">{address?.street}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="first-letter:uppercase">
                    <Tooltip
                      tooltip={`${address?.zip} ${address?.city}`}
                      showCondition={`${address?.zip} ${address?.city}`.length > 25}
                    >
                      <div className="truncate max-w-2xs">
                        {address?.zip} {address?.city}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="first-letter:uppercase">
                    <Tooltip tooltip={address?.country?.name!} showCondition={address?.country?.name!?.length > 25}>
                      <div className="line-clamp-1">{address?.country?.name!}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="first-letter:uppercase">
                    {address?.public_address ? t('address.public') : t('address.private')}
                  </TableCell>
                  <TableCell>
                    {address?.main && (
                      <Badge>
                        <div className="whitespace-nowrap">{t('suppliers.company_address_tag', 'Tags')}</div>
                      </Badge>
                    )}
                  </TableCell>
                  <TableCell>
                    <AddressListRowActions address={address} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </BaseSettingsSection>
    </div>
  )
}

export default CompanyAddresses
