import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'

export type ToggleProps<T = string> = {
  selected: T
  options: [T, T]
  onSelect?: (option: T) => void
  render: (option: T) => React.ReactNode
  generateKey?: (option: T) => string
  disabled?: boolean
}

// eslint-disable-next-line comma-spacing
export const Toggle = <T,>({ selected, options, onSelect, render, generateKey, disabled }: ToggleProps<T>) => {
  const [selectedOption, setSelectedOption] = useState(selected || options[0])

  useEffect(() => {
    setSelectedOption(isNil(selected) ? options[0] : selected)
  }, [selected])

  const handleSelect = (option: T) => {
    setSelectedOption(option)
    onSelect && onSelect(option)
  }

  const getKey = (option: T) =>
    generateKey
      ? generateKey(option)
      : `toggle-option-${
          (option as InputSearchSelectItemType).id ||
          (option as { uuid: string }).uuid ||
          (option as { __uuid: string }) ||
          option
        }`

  return (
    <div className="bg-white border border-rock-gray-light px-3 py-2 rounded transition-all duration-200 w-fit">
      {options.map((option) => (
        <button
          disabled={disabled}
          type="button"
          key={getKey(option)}
          onClick={() => handleSelect(option)}
          className={classnames(
            'px-2 py-1 rounded cursor-pointer transition-all duration-200 capitalize min-w-[4rem]',
            {
              'bg-ice-blue text-sky-blue-light': selectedOption === option,
              'text-black-light-2': selectedOption !== option,
            }
          )}
        >
          {render(option)}
        </button>
      ))}
    </div>
  )
}

export default Toggle

export const ToggleText = (props: Omit<ToggleProps, 'render'>) => (
  <Toggle<string> {...props} render={(option) => option} />
)

export const ToggleBoolean = (props: Omit<ToggleProps<boolean>, 'render' | 'options'>) => {
  const { t } = useTranslation()

  return (
    <Toggle<boolean>
      {...props}
      render={(value) => (value ? t('general.true', 'True') : t('general.false', 'False'))}
      options={[true, false]}
    />
  )
}
