import { CSSProperties } from 'react'

const getPopoverStyles = (popoverButton: HTMLButtonElement | null, modal: HTMLElement | null) => {
  const popoverPanelBaseStyle = {
    top: (popoverButton?.getBoundingClientRect().top || 0) + window.scrollY,
    zIndex: 1000,
  }

  const popoverPanelStyle: CSSProperties = {
    ...popoverPanelBaseStyle,
  }

  const popoverLeft = popoverButton?.getBoundingClientRect().left || 0
  const modalWidth = modal?.getBoundingClientRect().width || 0

  const shouldBeLeft = popoverLeft > modalWidth
  if (!shouldBeLeft) {
    popoverPanelStyle.left = popoverLeft
  } else {
    popoverPanelStyle.left = popoverLeft - modalWidth
  }

  return popoverPanelStyle
}

export default getPopoverStyles
