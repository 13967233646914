import {
  BanknotesIcon,
  CalendarIcon,
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import TabbedNav from '../../../../../shared/components/layout/TabbedNav/TabbedNav'
import { priceToInternationalPriceString } from '../../../../../shared/constants/currencyFormat'
import { dateFormat } from '../../../../../shared/constants/dateFormat'
import generateUserName from '../../../../../shared/utils/generateUserName'
import { TabbedNavItemType } from '../../../../../types/shared/TabbedNavItemType'
import useIntakeSidebar from '../../hooks/useIntakeSidebar'
import { useIntakeFlow } from '../../providers/IntakeFlowProvider'
import AbortRequestButton from '../IntakeSidepanel/components/AbortRequestButton'
import IntakeFlowOverviewPageHeaderTransmission from './IntakeFlowOverviewPageHeaderTransmission'

type IntakeFlowOverviewPageHeaderProps = {
  tabs: TabbedNavItemType[]
  onTabChange: (tabIndex: number) => void
}

export const IntakeFlowOverviewPageHeader = forwardRef<HTMLDivElement, IntakeFlowOverviewPageHeaderProps>(
  ({ tabs, onTabChange }: IntakeFlowOverviewPageHeaderProps, ref) => {
    const { intake, activeWorkflow } = useIntakeFlow()
    const { t } = useTranslation()
    const history = useHistory()
    const { minified, toggleMinified } = useIntakeSidebar()

    return (
      <>
        <div className="flex  flex-col items-start bg-background z-10 w-full px-12 pt-4" ref={ref}>
          <div className="mb-[10px]">
            <Button className="text-black-light-2 !p-0 text-sm" onClick={() => history.goBack()}>
              <ChevronLeftIcon className="h-[10px]" />
              {t('generic.back', 'Back')}
            </Button>
          </div>
          <div className="flex gap-2 mb-5 min-h-[2.25rem] justify-between w-full items-center">
            <div>
              <div className="text-2xl font-bold ">{intake?.name || intake?.uuid}</div>
              <div className="mt-1 text-secondary-gray text-sm flex gap-x-4  items-center">
                <div className="flex space-x-2">
                  <UserIcon className="w-4" />
                  <span>{generateUserName(intake?.creator)}</span>
                </div>
                <div className="flex space-x-2  items-center">
                  <CalendarIcon className="w-4" />
                  <span>{dayjs(activeWorkflow?.entity?.created_at).format(dateFormat)}</span>
                </div>
                <div className="flex space-x-2  items-center">
                  <BanknotesIcon className="w-4" />
                  <span>{priceToInternationalPriceString(intake?.total_net_price || 0, 'EUR').replace('€', '')}</span>
                </div>
                <IntakeFlowOverviewPageHeaderTransmission />
              </div>
            </div>
            <div className="flex gap-2">
              <AbortRequestButton />
              <div>
                {minified && (
                  <Button onClick={toggleMinified} className="bg-white h-full p-2 base-form-input w-fit">
                    <ChevronDoubleLeftIcon className="w-4" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" sticky top-0 px-10 bg-background w-full z-50">
          <TabbedNav itemClassName="min-w-[180px] flex-auto" items={tabs} onTabChange={onTabChange} />
        </div>
      </>
    )
  }
)

IntakeFlowOverviewPageHeader.displayName = 'IntakeFlowOverviewPageHeader'

export default IntakeFlowOverviewPageHeader
