import useIntakeFormStore from '../../stores/useIntakeFormStore'

interface IFormSectionNavigationButton {
  sectionIndex: number
  title: string
  active: boolean
  enabled: boolean
}

const IntakeFormNavHeaderButton: React.FC<IFormSectionNavigationButton> = ({
  sectionIndex,
  title,
  active,
  enabled,
}) => {
  const setActiveSectionIndex = useIntakeFormStore((state) => state.setActiveSectionIndex)

  return (
    <div className="w-full border-r text-center flex justify-center">
      <button
        key={sectionIndex}
        type="button"
        onClick={() => {
          setActiveSectionIndex(sectionIndex)
        }}
        className={`${active ? 'text-sky-blue-light ' : ''}
        disabled:opacity-25  py-2 px-6 w-full block
        text-sm font-medium hover:text-sky-blue-light hover:border-sky-blue-light transition-colors`}
        disabled={!enabled}
      >
        <p className="whitespace-nowrap">{title}</p>
      </button>
    </div>
  )
}

export default IntakeFormNavHeaderButton
