import { FC } from 'react'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'
import { HiPlus } from 'react-icons/hi2'
import { useTranslation } from 'react-i18next'
import { FormDataType } from '../../../../../types/Forms'
import { apiCreateIntake } from '../../../services/LhotseHomeService'
import lhotseMountainImage from '../../../../../assets/img/lhotse-mountain-image-500x333.jpg'
import LhotseHomeResultTransition from './LhotseHomeResultTransition'

interface ILhotseHomeFormResult {
  form: FormDataType
}

const LhotseHomeFormResult: FC<ILhotseHomeFormResult> = ({ form }) => {
  const history = useHistory()
  const createIntakeMutation = useMutation(apiCreateIntake)
  const { t } = useTranslation()

  const handleCreateIntakeAndNavigate = async () => {
    const { data } = await createIntakeMutation.mutateAsync(form.__uuid)
    if (data) {
      history.push(`/requests/${data.uuid}`)
    }
  }

  return (
    <LhotseHomeResultTransition>
      <div
        className="h-full relative group rounded-md overflow-hidden shadow-sm transform transition duration-500 transition-colors"
        onClick={handleCreateIntakeAndNavigate}
        tabIndex={-1}
        role="button"
      >
        <div className="h-36">
          <img
            src={form.thumbnail.url || lhotseMountainImage}
            alt={form.thumbnail.name || 'Lhotse Mountain'}
            className="w-full h-full bg-transparent object-cover"
          />
        </div>

        {/* Overlay that appears on hover */}
        <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-500">
          <button type="button" className="text-white font-semibold p-2 flex flex-row items-center justify-center">
            <HiPlus className="h-4 w-4 mr-1" />
            {t('home.start_new_request', 'Start new request')}
          </button>
        </div>
        <div className="relative bottom-0 w-full h-full p-4 bg-white bg-opacity-75">
          <div className="text-sm font-medium mb-2 line-clamp-2 text-gray-600">{form.name}</div>
        </div>
      </div>
    </LhotseHomeResultTransition>
  )
}

export default LhotseHomeFormResult
