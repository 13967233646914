import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { toastr } from '../../../../shared/components/organisms/toastr'
import { apiPostForgotPassword } from '../../services/loginService'

const usePostForgotPassword = () => {
  const history = useHistory()

  return useMutation(apiPostForgotPassword, {
    onError: () => {
      toastr.error('error.password_reset', 'password-reset-failed')
    },
    onSuccess: () => {
      toastr.success('success.password_reset', 'password-reset-success')
      history.push('/login')
    },
  })
}

export default usePostForgotPassword
