import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import FormSelectButton from '../../../../../../../../../../shared/components/atoms/inputs/FormSelectButton'
import { OfferItem } from '../../../../../../../../../../types/Offering'

interface IIndicativeSwitch {
  handleValueChange: (key: string, value: string | number | Date | boolean | null) => void
  currentOffer: OfferItem | Partial<OfferItem> | undefined
}

const IndicativeSwitch: FC<IIndicativeSwitch> = ({ handleValueChange, currentOffer }) => {
  const { t } = useTranslation()

  return (
    <div className="grid grid-cols-3 gap-4 border-b pb-4">
      <FormSelectButton
        handleOnClick={() => handleValueChange('is_indicative', true)}
        isDisabled={false}
        isSelected={currentOffer?.is_indicative || false}
        isSingleSelect
        option={{
          label: t('generic.indicative', 'Indicative'),
          value: t('offer.have_an_indication', 'I have an indication'),
          __uuid: 'indicative',
        }}
      />
      <FormSelectButton
        handleOnClick={() => handleValueChange('is_indicative', false)}
        isDisabled={false}
        isSelected={!currentOffer?.is_indicative || false}
        isSingleSelect
        option={{ value: t('offer.already_have_offer', 'I already have an offer'), __uuid: 'indicative' }}
      />
    </div>
  )
}

export default IndicativeSwitch
