import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { duplicateIntake } from '../services'

const useDuplicateIntake = () => {
  const history = useHistory()
  return useMutation((intakeUuid: string) => duplicateIntake(intakeUuid), {
    onSuccess: (data) => history.push(`/requests/${data?.uuid}`),
  })
}

export default useDuplicateIntake
