import { FormQuestionType, FormSectionType, InputTemplate } from '../../../../../../types/Forms'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateForm from '../../../hooks/useUpdateForm'
import uuid from '../../../../../../core/utils/uuid'
import { getUpdatedByUuid, insertAt } from '../../../../../../shared/utils/arrayHelpers'
import {
  getDefaultLabelSettings,
  getDefaultQuestionValues,
  getDefaultAnswerSettings,
  getDefaultOptionsSettings,
  getDefaultMiscSettings,
} from '../../../constants/questions'

export const getQuestionDefaults = (partialQuestion: FormQuestionType) => ({
  __settings: {
    labels: getDefaultLabelSettings(partialQuestion),
    options: getDefaultOptionsSettings(partialQuestion),
    answers: getDefaultAnswerSettings(partialQuestion),
    misc: getDefaultMiscSettings(partialQuestion),
  },
  __active_for: 'REQUESTER',
})

export const questionFromTemplate = (input: InputTemplate): FormQuestionType => ({
  __uuid: uuid('form-question'),
  inputId: input.uuid,
  inputName: input.name,
  values: getDefaultQuestionValues(input),
  type: input.type,
  key: input.key ?? input.custom_key ?? null,
  label: null,
  options: input.options?.filter(({ uuid }) => !!uuid).map((rawOption) => ({ ...rawOption, __uuid: rawOption.uuid! })),
  validation: {
    required: { value: input.validation.required },
    min_characters: { value: input.validation.min },
    max_characters: { value: input.validation.max },
  },
  __settings: {
    labels: getDefaultLabelSettings(input),
    options: getDefaultOptionsSettings(input),
    answers: getDefaultAnswerSettings(input),
    misc: getDefaultMiscSettings(input),
  },
  actions: {
    rename: true,
    edit: true,
    remove: true,
  },
  __active_for: 'REQUESTER',
  isFormElement: input.category?.uuid === '__form-elements',
})

export default (section: FormSectionType) => {
  const { updateForm } = useUpdateForm()
  const { data: formData } = useGetFormData()

  return (input: InputTemplate) => {
    const newQuestion = questionFromTemplate(input)

    if (!formData) return

    const getUpdatedFields = () => {
      const isLoopedSection = section.loop.__looped
      const questionsLength = section.fields.length

      return insertAt(section.fields, questionsLength - (isLoopedSection ? 1 : 0), newQuestion)
    }

    updateForm({
      template: {
        sections: getUpdatedByUuid(formData.template.sections, [
          {
            __uuid: section.__uuid,
            fields: getUpdatedFields(),
          },
        ]),
      },
    })
  }
}
