import useEvaluatedSections from './hooks/useEvaluatedSections'
import { IntakeSectionType } from '../../../../types/Intake'

const useApplyFormSectionLogic = (sectionsToEvaluate: IntakeSectionType[] | undefined) => {
  // Evaluate the section using logic
  const { enabledQuestionsUuids } = useEvaluatedSections(sectionsToEvaluate)

  // Mark disabled fields in the section as visible: false, else make visible: true
  sectionsToEvaluate?.forEach((section) => {
    section?.fields.forEach((field) => {
      const localField = field
      localField.visible = !(
        localField.external_reference && !enabledQuestionsUuids.includes(localField.external_reference)
      )

      return localField
    })
  })

  return sectionsToEvaluate
}

export default useApplyFormSectionLogic
