import { IFloatingElements } from './FloatingElements'

interface ICharacterCount extends Pick<IFloatingElements, 'charLimit' | 'inputValue'> {
  charError: boolean
}

const CharacterCount: React.FC<ICharacterCount> = ({ charLimit, charError, inputValue }) => {
  if (!charLimit) return null

  return (
    <div className={`flex justify-end py-1 ${charError ? 'text-red-500' : 'text-secondary-gray'}`}>
      {`${inputValue.length}/${charLimit}`}
    </div>
  )
}

export default CharacterCount
