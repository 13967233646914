import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { toastr } from '../../../shared/components/organisms/toastr'
import { apiResetPassword } from '../services/loginService'

const useResetPassword = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return useMutation(apiResetPassword, {
    onSuccess: (res: string) => {
      toastr.success(t(res), 'password-reset-success')
      history.push('/login')
    },
    onError: (res) => {
      toastr.error(t(res), 'password-reset-failed')
    },
  })
}
export default useResetPassword
