import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { toastr } from '../../../../../shared/components/organisms/toastr'
import { apiUserJoin } from '../../../services/loginService'

const useSubmitUserData = () => {
  const history = useHistory()
  return useMutation(apiUserJoin, {
    onError: (errors: { token: string; email: string }) => {
      if (errors.token) toastr.error('error.user_creation_token', 'join-failed-token')
      if (errors.email) toastr.error('error.user_creation_email', 'join-failed-email')
      if (!errors.email && !errors.token) toastr.error('error.user_creation', 'join-failed')
    },
    onSuccess: (accessToken) => {
      toastr.success('generic.success', 'join-success')
      history.push(`/sso-login/${accessToken.data.token}`)
    },
  })
}

export default useSubmitUserData
