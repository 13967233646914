import { useMutation } from 'react-query'
import { OfferItem } from '../../../types/Offering'
import { putOfferItem } from '../../Offerings/services'
import useRefetchOfferQuery from './useRefetchQuery'

export const useUpdateOfferItem = () => {
  const refetchOfferQuery = useRefetchOfferQuery()

  return useMutation(
    (updatedOffer: OfferItem) =>
      putOfferItem({
        ...updatedOffer,
      }),
    {
      onSuccess: async ({ data: updatedOffer }) => {
        // Refetch the Offerings List based on the position of the current process (form or intake)
        ;(await refetchOfferQuery) && refetchOfferQuery(updatedOffer.reference_key)
      },
    }
  )
}

export default useUpdateOfferItem
