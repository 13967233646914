import { useTranslation } from 'react-i18next'
import { IoMdEye } from 'react-icons/io'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useNavigate from '../../../../../shared/hooks/useNavigate'
import useGetFormParams from '../../hooks/useGetFormParams'

const ViewFormButton: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formId } = useGetFormParams()

  return (
    <Button
      variant="solid"
      color="sky-blue"
      className="flex gap-x-2"
      onClick={(e) => navigate(`/forms/${formId}/view`, e)}
    >
      <IoMdEye />
      <p>{t('forms.view_form')}</p>
    </Button>
  )
}

export default ViewFormButton
