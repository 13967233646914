import { useFormContext } from '../../../../../core/providers/FormProvider'
import QuestionChoices from '../QuestionChoices/QuestionChoices'
import { FormQuestionContentType } from '../../../../../types/Forms'

const QuestionsWithOptions: FormQuestionContentType[] = ['MULTI_SELECT', 'SELECT', 'SEARCH_SELECT']

const QuestionOptionsSelector = () => {
  const {
    question: [question],
  } = useFormContext()

  if (
    !question ||
    !QuestionsWithOptions.includes(question?.type) ||
    (question?.type === 'SEARCH_SELECT' && question.searchType !== 'CUSTOM')
  )
    return null

  return <QuestionChoices />
}

export default QuestionOptionsSelector
