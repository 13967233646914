import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../../../../shared/components/atoms/buttons/Button'
import IntakeFlowchartActionDialog from './IntakeFlowchartActionDialog'
import { WorkflowBlock } from '../../../../../../../../types/Workflow/WorkflowBlock'

interface IIntakeFlowchartActionButton {
  block: WorkflowBlock
}

const IntakeFlowchartActionButton: FC<IIntakeFlowchartActionButton> = ({ block }) => {
  const { t } = useTranslation()
  const jiraActionObject = block.actions?.[0]?.slug === 'jira_issue_create' ? block.actions?.[0]?.object : null

  // If there is one action, and it is a Jira one, show Jira button
  if (block.actions?.length === 1 && jiraActionObject && jiraActionObject.self) {
    return (
      <Button
        className="flex-grow-1 btn-primary w-full text-white justify-center"
        onClick={() => window.open(jiraActionObject.self, '_blank')}
        disabled={false}
      >
        <div className="flex flex-row gap-2">
          {t('intake.flowchart.actionNodeContent.view_jira', 'View Jira ticket')}
        </div>
      </Button>
    )
  }

  // If there are actions, and they are all "Tasks" show "See more details" button
  const someBlockActionsAreTasks = block.actions?.some((action) => action.slug === 'task_action')
  const allBlockActionsHaveTaskObjects = block.actions?.every((action) => !Array.isArray(action.object))
  if (block.actions && block.actions?.length > 0 && someBlockActionsAreTasks && allBlockActionsHaveTaskObjects) {
    return <IntakeFlowchartActionDialog block={block} />
  }

  return null
}

export default IntakeFlowchartActionButton
