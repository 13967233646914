import type { PartialDeep } from 'type-fest'
import { FormQuestionContentType } from '../../../../../../types/Forms'
import {
  getMergedDeepOverwritingArrays,
  mergeDeepOverwritingArrays,
} from '../../../../../../shared/utils/objectHelpers'

export type ValidationPropertyType = 'NUMERIC' | 'TEXT' | 'BOOLEAN' | 'OTHER'

export type ValidationPropertyConfig = {
  enabled: boolean
  type: ValidationPropertyType
  allowsQuestionAsValue: boolean
  default: null | number | string | unknown
  scope: 'question' | 'field'
}

export type ValidationConfig = {
  required: ValidationPropertyConfig
  min_values?: ValidationPropertyConfig
  max_values?: ValidationPropertyConfig
  total?: ValidationPropertyConfig
  min?: ValidationPropertyConfig
  max?: ValidationPropertyConfig
  min_characters?: ValidationPropertyConfig
  max_characters?: ValidationPropertyConfig
  exact?: ValidationPropertyConfig
}

export type ValidationProperty = keyof ValidationConfig

const getDefaultValidationPropertyConfig = (
  overrides: Partial<ValidationPropertyConfig> = {}
): ValidationPropertyConfig =>
  mergeDeepOverwritingArrays<ValidationPropertyConfig>(
    {
      enabled: false,
      type: 'TEXT',
      default: null,
      allowsQuestionAsValue: false,
      scope: 'field',
    },
    overrides
  )

const getValidationConfig = (overrides: PartialDeep<ValidationConfig> = {}): ValidationConfig =>
  getMergedDeepOverwritingArrays<ValidationConfig>(
    {
      required: getDefaultValidationPropertyConfig({ enabled: true, scope: 'question' }),
      min_values: getDefaultValidationPropertyConfig({ type: 'NUMERIC', scope: 'question' }),
      max_values: getDefaultValidationPropertyConfig({ type: 'NUMERIC', scope: 'question' }),
      total: getDefaultValidationPropertyConfig({ type: 'NUMERIC', allowsQuestionAsValue: true, scope: 'question' }),

      min: getDefaultValidationPropertyConfig({ type: 'NUMERIC', allowsQuestionAsValue: true }),
      max: getDefaultValidationPropertyConfig({ type: 'NUMERIC', allowsQuestionAsValue: true, default: 1000 }),
      min_characters: getDefaultValidationPropertyConfig({ type: 'NUMERIC' }),
      max_characters: getDefaultValidationPropertyConfig({ type: 'NUMERIC', default: 280 }),
      exact: getDefaultValidationPropertyConfig({ type: 'NUMERIC', allowsQuestionAsValue: true }),
    },
    overrides
  )

const ValidationSchemas: Partial<Record<FormQuestionContentType, ValidationConfig>> = {
  KEY_VALUE: getValidationConfig({
    min_values: { enabled: true },
    max_values: { enabled: true },
  }),
  NUMBER: getValidationConfig({
    min: { enabled: true },
    max: { enabled: true },
    exact: { enabled: true },
    total: { enabled: true },
  }),
}

export const getValidationSchema = (questionType: FormQuestionContentType): ValidationConfig =>
  ValidationSchemas[questionType] || getValidationConfig()
