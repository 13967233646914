import { useTranslation } from 'react-i18next'

const ExternalDataModalHeader = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-y-2">
      <p>{t('external_data.upload_new_data')}</p>
      <p className="text-secondary-gray text-sm pb-6">{t('external_data.fill_info')}</p>
    </div>
  )
}

export default ExternalDataModalHeader
