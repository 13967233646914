import { useFormField } from '../providers/FormFieldProvider'
import FileField from './FileField'
import useIntakeFlowFileInputMutations from '../hooks/useIntakeFlowFileInputMutations'

export const FormFieldMedia = () => {
  const { field } = useFormField()
  const { disabled } = field
  const { remove, upload } = useIntakeFlowFileInputMutations()

  const uploadDisabled = disabled || false
  const canModify = true
  const mediaList = [...(field.media ?? [])]

  return (
    <FileField
      mediaList={mediaList}
      onDelete={({ uuid }) => remove(uuid)}
      disabled={uploadDisabled}
      onUpload={upload}
      canModify={canModify}
      allowedFileTypes={field.input.settings?.fileType?.allowedFileTypes}
      canAdd
      errorMessage={null}
    />
  )
}

export default FormFieldMedia
