import { ReactNode } from 'react'
import cn from '../../../../lib/util'
import { VerticalTabItemType } from './VerticalMenuTabsItem'

export type TabsSectionType = {
  name: string
  basePath: string
  tabs: VerticalTabItemType[]
  visible: boolean
}

type HorizontalTabsProps = {
  sections: TabsSectionType[]
  children: (tab: any) => ReactNode
  className?: string
}
const VerticalMenuTabs = ({ sections, children, className }: HorizontalTabsProps) => {
  const allTabs: TabsSectionType[] = sections
    .filter((item) => item.visible)
    .map((sectionItem) => ({
      ...sectionItem,
      tabs: sectionItem.tabs
        .filter((item) => item.visible)
        .map((tab) => ({
          ...tab,
          active: tab.active || window.location.href.toString().includes(`/${sectionItem.basePath}/${tab.path}`),
          fullPath: `/${sectionItem.basePath}/${tab.path}`,
        })),
    }))

  return (
    <nav className={cn('h-full w-56', className)}>
      <div className="divide-y divide-gray-200/75 grid grid-cols-1">
        {allTabs.map(
          (section: TabsSectionType, index): JSX.Element => (
            <div key={`section-${section.name}`} className="">
              <div
                className={`pb-2 text-left text-gray-600 font-semibold text-sm select-none ${
                  index === 0 ? '' : 'mt-4'
                }`}
              >
                {section.name}
              </div>
              <div className="space-y-1 mb-3">{section.tabs.map((tab: VerticalTabItemType) => children(tab))}</div>
            </div>
          )
        )}
      </div>
    </nav>
  )
}
export default VerticalMenuTabs
