import { useMutation, useQueryClient } from 'react-query'
import { apiUpdateExternalDataEntity } from '../services/ExternalDataServices'
import useCurrentExternalDataSourceUuid from './useCurrentExternalDataSourceUuid'

export const useExternalDataSourceMutations = () => {
  const externalDataUuid = useCurrentExternalDataSourceUuid()
  const cache = useQueryClient()

  const cacheKey = ['entities', externalDataUuid]

  const invalidate = async () => {
    await cache.invalidateQueries(['entities'])

    if (!externalDataUuid) return

    await cache.invalidateQueries(cacheKey)
  }

  const update = useMutation(apiUpdateExternalDataEntity, {
    mutationKey: cacheKey,
    onSettled: invalidate,
  })

  return {
    update: update.mutateAsync,
    isProcessing: cache.isMutating({ mutationKey: cacheKey }) > 0,
  }
}

export default useExternalDataSourceMutations
