import { useTranslation } from 'react-i18next'
import TableCell from '../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../shared/components/atoms/SharedTable/TableRow'
import Menu, { MenuItemType } from '../../../../shared/components/organisms/menus/Menu'
import { dateTimeFormatter } from '../../../../shared/constants/dateFormat'
import { ExternalDataListEntity } from '../../../../types/ExternalDataManager/ExternalDataManagerTypes'
import { useExternalDataEditModal } from '../ExternalDataModals'
import useGoToExternalDataEntry from '../../hooks/useGoToExternalDataEntry'

type ExternalDataTableRowProps = {
  externalData: ExternalDataListEntity
}

const ExternalDataSourcesTableRow: React.FC<ExternalDataTableRowProps> = ({ externalData }) => {
  const { t } = useTranslation()

  const openEditModal = useExternalDataEditModal(externalData)
  const goToDetails = useGoToExternalDataEntry(externalData.uuid)

  const externalDataActions: MenuItemType[] = [
    { name: t('general.edit'), onClick: openEditModal },
    // { name: t('general.Delete'), onClick: () => console.log('remove item') } // todo add once api supports source removal
  ]

  return (
    <TableRow onClick={goToDetails}>
      <TableCell>{externalData.name}</TableCell>
      <TableCell>{externalData.created_at && dateTimeFormatter(externalData.created_at, false)}</TableCell>
      <TableCell>{externalData.updated_at && dateTimeFormatter(externalData.updated_at, false)}</TableCell>
      <TableCell>
        <Menu menuItems={externalDataActions} />
      </TableCell>
    </TableRow>
  )
}

export default ExternalDataSourcesTableRow
