import { useTranslation } from 'react-i18next'
import OfferInline from './OfferModal/OfferInline'
import TableContainer from '../../../../../../../../shared/components/atoms/SharedTable/TableContainer'
import OffersTableHead from './OffersTable/OffersTableHead'
import Table from '../../../../../../../../shared/components/atoms/SharedTable/Table'
import OffersTableRow from './OffersTable/OffersTableRow'
import OffersAddButton from './OffersAddButton'
import { Offering, OfferItem } from '../../../../../../../../types/Offering'
import getOfferTemplate from '../../../../../../../Offerings/components/forms/constants/OfferTemplate'
import useCreateOfferItem from '../../../../../../../Forms/hooks/useCreateOfferItem'
import useUpdateOfferItem from '../../../../../../../Forms/hooks/useUpdateOfferItem'
import useIntakeFormStore from '../../../../../../IntakeFormResponse/stores/useIntakeFormStore'
import useGetIntakeOffers from '../../../../../hooks/useGetIntakeOffers'
import OfferModal from './OfferModal/OfferModal'
import { useUtils } from '../../../../../../../../core/providers/UtilsProvider'
import { offerMediaService } from '../../../../../../../../shared/utils/attachmentsService'

interface IOffersSection {
  offering: Offering
  editModeOn?: boolean
}
const OffersSection: React.FC<IOffersSection> = ({ offering, editModeOn }) => {
  const utils = useUtils()

  const intakeFormSettings = useIntakeFormStore((state) => state.intake?.settings)
  const isDraftIntake = useIntakeFormStore((state) => state.intake?.status === 'draft')
  const intakeUuid = useIntakeFormStore((state) => state.intake?.uuid)
  const { data: offers } = useGetIntakeOffers(intakeUuid)

  const { mutateAsync: createOfferItem, isLoading: isCreatingOfferItem } = useCreateOfferItem()
  const { mutateAsync: updateOfferItem } = useUpdateOfferItem()

  const { t } = useTranslation()

  const defaultValues = {
    offering_uuid: offering.uuid,
    quantity: (offering.fields.find(({ input }) => input.key === 'prod-quantity')?.value?.value || 1) as number,
  }

  const addOffer = async (data?: Partial<OfferItem>) => {
    const newOffer = getOfferTemplate(defaultValues || {})

    const { data: offerItem } = await createOfferItem({
      ...newOffer,
      ...data,
      reference_key: newOffer.reference_key || '-',
    })
    const filesToUpload = data?.offer?.files
    if (filesToUpload) {
      const { list, upload: uploadFiles } = offerMediaService(offerItem.offer?.uuid || '')
      if (filesToUpload) await uploadFiles(filesToUpload)
    }

    return offerItem as OfferItem
  }

  const updateOffer = async (offerItem: OfferItem) => {
    await updateOfferItem(offerItem)
    const filesToUpload = offerItem.offer?.files
    if (filesToUpload) {
      const { list, upload: uploadFiles } = offerMediaService(offerItem.offer?.uuid || '')

      // check that the file is not already uploaded
      const fileList = await list()
      const filesToUploadFiltered = filesToUpload.filter((file) => !fileList.find((f) => f.file_name === file.name))
      if (filesToUploadFiltered && filesToUploadFiltered.length > 0) await uploadFiles(filesToUploadFiltered)
    }
  }

  const onAddOfferButtonClick = async () => {
    const newOffer = getOfferTemplate(defaultValues || {})
    utils.modal.set({
      title: t('generic.add-offer', 'Add offer'),
      content: <OfferModal offer={newOffer} addOffer={addOffer} updateOffer={updateOffer} mode="create" />,
      isOpen: true,
    })
  }

  if (
    (isDraftIntake && !intakeFormSettings?.offersEnabled) ||
    (!isDraftIntake && !intakeFormSettings?.allowAddingOffersOnOverview)
  )
    return null

  const shouldShowAddOfferButton = isDraftIntake
    ? intakeFormSettings?.offersEnabled && intakeFormSettings?.allowMultipleOffers
    : !!intakeFormSettings?.allowAddingOffersOnOverview

  return (
    <div className="flex flex-col gap-2">
      <span className="font-semibold">{t('generic.offers', 'Offers')}</span>
      {(offering.offers.length || 0) <= 1 && isDraftIntake && (
        <OfferInline
          offerItem={offering.offers?.[0]}
          offers={offers}
          addOffer={addOffer}
          updateOffer={updateOffer}
          isCreating={isCreatingOfferItem}
        />
      )}
      {((offering.offers.length || 0) > 1 || !isDraftIntake) && (
        <TableContainer border={false}>
          {editModeOn || offering.offers.length > 0 ? <OffersTableHead /> : null}
          <Table>
            {offering.offers.map((offer) => (
              <OffersTableRow key={offer.uuid} offerItem={offer} />
            ))}
          </Table>
        </TableContainer>
      )}

      {shouldShowAddOfferButton && (
        <div className="mt-4">
          <OffersAddButton onAddOfferButtonClick={onAddOfferButtonClick} />
        </div>
      )}
    </div>
  )
}

export default OffersSection
