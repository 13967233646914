import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import useToggleableState from '../../../../../../../core/hooks/useToggleableState'
import Button from '../../../../../../../shared/components/atoms/buttons/Button'
import { WorkflowChartGroupNodeProps } from '../../../../../../../types/Workflow/WorkflowChart'
import { WorkflowChartBlockNodeRenderer, WorkflowChartNodeTemplate } from '../../../../../../WorkflowChart'
import WorkflowChartNodesGroup from '../../../../../../WorkflowChart/components/WorkflowChartNodes/WorkflowChartNodesGroup'
import IntakeFlowchartNodeIcon from './components/IntakeFlowchartNodeIcon'

export const BLOCKS_COLLAPSE_THRESHOLD = 3

const getPendingBlocks = (data: WorkflowChartGroupNodeProps['data']) =>
  data.blocks.filter((block) => block.status === 'pending')

export const IntakeFlowchartGroupNode = ({ data, id, ...props }: WorkflowChartGroupNodeProps) => {
  const { t } = useTranslation()
  const hasOverflowingBlocks = data.blocks.length > BLOCKS_COLLAPSE_THRESHOLD
  const [collapsed, toggle] = useToggleableState(hasOverflowingBlocks)

  const handleCollapse: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    toggle()
  }

  const pendingBlocks = getPendingBlocks(data)

  return (
    <WorkflowChartNodesGroup
      data={data}
      id={id}
      staticHeight
      {...props}
      header={
        hasOverflowingBlocks && !collapsed ? (
          <div className="flex flex-row-reverse w-full">
            <Button variant="outlined" color="tertiary-gray" className="text-sm px-2 py-1" onClick={handleCollapse}>
              {!collapsed && (
                <>
                  <ChevronUpIcon className="h-4" /> {t('general.collapse')}
                </>
              )}
            </Button>
          </div>
        ) : undefined
      }
    >
      {collapsed && (
        <WorkflowChartNodeTemplate
          node={{ ...props, data: { ...data.blocks[0], position: data.position, stepUuid: data.uuid }, id }}
        >
          <div className="w-full">
            <div className="flex flex-row gap-3 border-b border-b-ice-blue pb-2 mb-2 w-full items-center">
              <div className="flex-grow-1 font-semibold">
                {data.blocks.length} {t('workflowChart.collapsed_blocks', 'collapsed blocks')}
              </div>
            </div>
            <div className="w-full">
              <Button variant="outlined" color="tertiary-gray" className="text-sm px-2 py-1" onClick={handleCollapse}>
                <ChevronDownIcon className="h-4" /> {t('general.expand')}
              </Button>
            </div>
          </div>
          {pendingBlocks.length > 0 && (
            <div className="flex flex-row gap-2 items-center w-full">
              <IntakeFlowchartNodeIcon block={pendingBlocks[0]} />
              <div className="font-bold">
                {pendingBlocks.length} {t('workflowChart.pending_blocks', 'pending blocks')}
              </div>
            </div>
          )}
        </WorkflowChartNodeTemplate>
      )}
      {!collapsed &&
        data.blocks.map((block) => (
          <WorkflowChartBlockNodeRenderer
            key={`block-node-${block.uuid}`}
            id={block.uuid}
            data={{ ...block, position: data.position, stepUuid: data.uuid }}
            {...props}
          />
        ))}
    </WorkflowChartNodesGroup>
  )
}

export default IntakeFlowchartGroupNode
