import { useQuery } from 'react-query'
import useCurrentExternalDataSourceUuid from './useCurrentExternalDataSourceUuid'
import { apiGetExternalDataEntityRelationships } from '../services/ExternalDataServices'

const useGetExternalDataRelationshipList = () => {
  const externalDataUuid = useCurrentExternalDataSourceUuid()

  const { data, isLoading, error } = useQuery(
    ['entities', externalDataUuid, 'relationships'],
    () => apiGetExternalDataEntityRelationships(externalDataUuid),
    { enabled: !!externalDataUuid }
  )

  return {
    data,
    isLoading,
    error,
  }
}

export default useGetExternalDataRelationshipList
