import { FormSectionType, FormQuestionType } from '../../../../../../../../types/Forms/formTypes'
import FormLogicGoToLogic from './FormLogicGoToLogic'
import LoopTriggerLogic from './LoopTriggerLogic'

interface IFormLogicConditionInput {
  question: FormQuestionType
  section: FormSectionType
}

const FormLogicConditionInput: React.FC<IFormLogicConditionInput> = ({ question, section }) => {
  const questionHasLogic = !!question.__logic
  const questionIsLoopTrigger = !!question.__loop?.is_loop_trigger

  if (!questionHasLogic) return null

  if (questionIsLoopTrigger) {
    return <LoopTriggerLogic question={question} section={section} />
  }

  return <FormLogicGoToLogic question={question} section={section} />
}

export default FormLogicConditionInput
