import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import uuid from 'react-uuid'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../core/components/Table/Table'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { toastr } from '../../../../shared/components/organisms/toastr'
import { InputType } from '../../../../types/shared/InputType'
import FormField from '../../../Forms-v2/FormField'
import BaseSettingsSection from '../../components/BaseSettingsSection'
import { DataSchemaActions } from './Components/SchemaDetailComponents'
import { DataSchemaIndexResponseType, useSchemaServiceCreateMutation, useSchemaServiceIndex } from './useSchemaService'

const CreateSchemaModal = (): JSX.Element => {
  const { modal } = useUtils()
  const history = useHistory()
  const { t } = useTranslation()
  const create = useSchemaServiceCreateMutation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm()

  const defaultOption = {
    uuid: uuid(),
    display_name: 'Users',
    value: 'users',
  }

  const handleClose = () => {
    modal.close()
  }

  const onSubmit = async (data: any) => {
    try {
      await create
        .mutateAsync({
          entity: data.entity.value,
          name: data.name,
        })
        .then((response) => {
          handleClose()
          history.push(`/schemas/${response.data.uuid}`)
          toastr.success(t('schemas.create_schema_modal.success', 'Schema created successfully'))
        })
    } catch (error) {
      toastr.error(t('schemas.create_schema_modal.error', 'Error creating schema'))
    }
  }

  return (
    <div className="flex flex-col space-y-5 transition duration-150 ease-out">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-6">
        <Controller
          key="name"
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t('schemas.create_schema_modal.name_required'),
            },
          }}
          render={({ field }) => (
            <FormField
              label={t('schema.field_name', 'Name')}
              readonly={false}
              updateField={(e) => field.onChange(e.value?.value)}
              field={{
                disabled: false,
                id: field.name,
                input: {
                  type: 'TEXT',
                  name: field.name,
                  placeholder: 'Name',
                } as InputType,
                label: field.name,
                media: null,
                options: null,
                value: {
                  __uuid: uuid(),
                  value: field.value,
                },
                error: (errors[field.name]?.message as string) ?? '',
              }}
            />
          )}
        />
        <Controller
          key="entity"
          name="entity"
          control={control}
          defaultValue={{
            ...defaultOption,
            is_selected: true,
          }}
          rules={{
            required: {
              value: true,
              message: t('schemas.create_schema_modal.entity_required'),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormField
              label={t('schema.create_schema_modal.entity', 'Entity')}
              readonly={false}
              updateField={(e) => field.onChange(e.value)}
              field={{
                id: field.name,
                input: {
                  id: 'users',
                  type: 'SELECT',
                  name: field.name,
                  options: [field.value],
                } as InputType,
                label: field.name,
                value: field.value,
                error: (errors[field.name]?.message as string) ?? '',
              }}
            />
          )}
        />
        <div className="flex justify-end">
          <button
            disabled={!isValid}
            type="submit"
            className="bg-sky-blue flex items-center rounded-md px-4 py-2 disabled:hover:brightness-100
        disabled:!bg-gray-200 disabled:!text-gray-400 text-white focus:outline-none focus:ring focus:border-blue-300"
          >
            {t('schemas.create_schema_modal.create', 'Create')}
          </button>
        </div>
      </form>
    </div>
  )
}

const SchemaTab = () => {
  const history = useHistory()
  const { modal } = useUtils()
  const { t } = useTranslation()
  const { data } = useSchemaServiceIndex()

  const handleSchemaCreation = (): void => {
    modal.set({
      isOpen: true,
      title: <h2 className="text-black font-bold">{t('schemas.create_schema_modal_title', 'Create schema')}</h2>,
      content: <CreateSchemaModal />,
      baseClassName: 'w-[600px]',
    })
  }

  return (
    <BaseSettingsSection
      title={t('schema.title', 'Custom data schemas')}
      description={t('schema.create_schema_description', 'Add custom data schemas to your company.')}
      action={
        <div className="w-full ">
          <button
            type="button"
            className="py-2 px-5 bg-sky-blue rounded text-white disabled:hover:brightness-100
        disabled:!bg-gray-200 disabled:!text-gray-400"
            onClick={handleSchemaCreation}
          >
            {t('schema.create_schemas', 'Create schema')}
          </button>
        </div>
      }
    >
      <div className="overflow-hidden">
        <Table className="bg-white shadow-rock-gray-light">
          <TableHeader>
            <TableRow>
              <TableHead className="w-48 font-bold first-letter:uppercase">{t('schema.table_name', 'Name')}</TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('generic.table_entity', 'Entity')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('generic.table_fields', 'Fields')}
              </TableHead>
              <TableHead className="w-48 font-bold first-letter:uppercase">
                {t('generic.created_at', 'Created at')}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.data?.map((item: DataSchemaIndexResponseType) => (
              <TableRow
                key={`schema-${item.uuid}`}
                className="h-16 hover:bg-gray-200/25 text-black-light hover:cursor-pointer"
                onClick={() => history.push(`/schemas/${item.uuid}`)}
              >
                <TableCell className="first-letter:uppercase">{item.name}</TableCell>
                <TableCell className="first-letter:uppercase">{item.entity}</TableCell>
                <TableCell>{item.fields_count}</TableCell>
                <TableCell>{item.created_at}</TableCell>
                <TableCell>
                  <DataSchemaActions name={item.name} uuid={item.uuid} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </BaseSettingsSection>
  )
}
export default SchemaTab
