import { useTranslation } from 'react-i18next'
import TabsBar from '../../../shared/components/atoms/Tabs/TabsBar'

interface IFormCreationNavigation {}

const FormNavigationTabs: React.FC<IFormCreationNavigation> = () => {
  const { t } = useTranslation()

  const tabs = [
    {
      title: t('forms.form_content'),
      link: 'content',
      id: 'content',
    },
    {
      title: t('forms.form_settings'),
      link: 'settings',
      id: 'settings',
    },
    {
      title: 'Workflow',
      link: 'workflow',
      id: 'workflow',
    },
  ]

  return (
    <div className="mb-4">
      <TabsBar tabs={tabs} />
    </div>
  )
}

export default FormNavigationTabs
