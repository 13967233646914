import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cn from '../../../../lib/util'

interface ILhotseHomeResultList {
  title: string
  numItems?: number
  numColumns?: 1 | 2 | 3 | 4
  onShowMoreClick?: () => void
  children?: React.ReactNode
}

const columnClasses = {
  1: 'grid-cols-1 lg:grid-cols-1',
  2: 'grid-cols-1 lg:grid-cols-2',
  3: 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3',
  4: 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4',
}

const LhotseHomeResultList: FC<ILhotseHomeResultList> = ({
  title,
  numItems,
  numColumns = 4,
  onShowMoreClick,
  children,
}) => {
  const { t } = useTranslation()
  return (
    <div className="space-y-3.5">
      <div className="flex flex-row w-full justify-between">
        <div className="flex items-center space-x-2 text-sm font-medium">
          <h2 className="text-gray-600">{title}</h2>
          <span className="text-gray-400">{numItems || 0}</span>
        </div>
        {onShowMoreClick && (
          <button
            type="button"
            onClick={onShowMoreClick}
            className="text-sm font-medium text-gray-400 hover:text-gray-600 transition-colors"
          >
            {t('generic.show_more', 'Show more')}
          </button>
        )}
      </div>

      <div className={cn('grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4', columnClasses[numColumns] || '')}>
        {children}
      </div>
    </div>
  )
}

export default LhotseHomeResultList
